import {FileModel} from "@renta-apps/athenaeum-toolkit";

export default class PreSignedUrlItem {    
    public originalKey: string = "";

    public fileModel: FileModel | null = null;
    
    public url: string = "";
    
    public useLocalInsteadOfS3 : boolean = false;
}