import BaseListDevicesRequest from "./BaseListDevicesRequest";
import DeviceInfo from "../DeviceInfo";
import {AnnualInspectionDevicesType, AnnualInspectionStatusFilterType, DeviceStatus} from "../../Enums";

export default class ListAnnualInspectionDevicesRequest extends BaseListDevicesRequest {
    
    public deviceStatuses: DeviceStatus[] = [];

    public inspectionType: AnnualInspectionDevicesType | null = null;

    public annualInspectionStatusFilter: AnnualInspectionStatusFilterType | null = null;

    public pageNumber : number = 0;

    public pageSize : number = 0;
    
    public getLastAnnualInspectionDate(device: DeviceInfo): Date | null {
        return device.lastAnnualReportDate;
    }

    public getNextAnnualInspectionDate(device: DeviceInfo): Date | null {
        return device.nextAnnualReportDate;
    }

    public getRemarksDueDate(device: DeviceInfo): Date | null {
        return device.remarksDueDate;
    }

    public isEmpty(): boolean {
        return (this.deviceStatuses.length == 0) && (super.isEmpty());
    }

    public clear(): void {
        this.deviceStatuses = [];
        this.annualInspectionStatusFilter = null;
        super.clear();
    }

    public nextPage() : void{
        this.pageNumber++;
    }
}