import {IFaultCode} from "@/models/server/FaultCode";
import ErrorCodeModel from "@/models/ErrorCodeModel";

class FaultsCodesService{
    public createModelFromFaultCode(faultCodes: IFaultCode[]) : ErrorCodeModel[] {
        const sortedCodes = faultCodes.sort((a, b) => b.dateTime.getTime() - a.dateTime.getTime())
        
        const codes = sortedCodes.groupBy(p=>p.codeIdentifier);
        
        const codesModel : ErrorCodeModel[] = [];
        
        codes.forEach(groupedCodes=> 
            codesModel.push(new ErrorCodeModel(groupedCodes))
        )
        
        return codesModel;
    }
}

export default new FaultsCodesService();
