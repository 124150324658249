import React from "react";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, DateInput, Dropdown, Form, Inline, Modal, SelectListItem} from "@renta-apps/athenaeum-react-components";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import ListDevicePicturesRequest from "@/models/server/requests/ListDevicePicturesRequest";
import DevicePictureFiltersData from "@/models/server/DevicePictureFiltersData";
import EnumProvider from "../../../providers/EnumProvider";
import Localizer from "../../../localization/Localizer";

import styles from "./PicturesFiltersModal.module.scss";

interface IPicturesFiltersModalProps {
    request: ListDevicePicturesRequest;
    filtersData: DevicePictureFiltersData;
    title: string;

    onSubmit(sender: IBaseComponent, request: ListDevicePicturesRequest): Promise<void>;
}

interface IPicturesFiltersModalState {
    request: ListDevicePicturesRequest;
}

export default class PicturesFiltersModal extends BaseComponent<IPicturesFiltersModalProps, IPicturesFiltersModalState> {
    state: IPicturesFiltersModalState = {
        request: this.props.request
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _picturesTypesRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _fromRef: React.RefObject<DateInput> = React.createRef();
    private readonly _beforeRef: React.RefObject<DateInput> = React.createRef();
    private readonly _atRef: React.RefObject<DateInput> = React.createRef();

    private getDevicePicturesTypes(): SelectListItem[] {
        const items: SelectListItem[] = EnumProvider.getDevicePicturesFilterItems();

        if ((this.state.request as ListDevicePicturesRequest).types) {
            const values: string[] = (this.state.request as ListDevicePicturesRequest).types.map(type => type.toString());
            items.forEach(item => item.selected = values.includes(item.value));
        }

        return items;
    }

    private async clearAsync(): Promise<void> {
        this.state.request.clear();

        await this.setState({request: this.state.request});

        this._picturesTypesRef.current!.reRender();
    }

    private async clearAtAsync(): Promise<void> {
        this._atRef.current!.clear();
    }

    private async clearRangeAsync(): Promise<void> {
        this._fromRef.current!.clear();
        this._beforeRef.current!.clear();
    }

    private async submitAsync(data: Dictionary<string, any>): Promise<void> {
        this.copyTo(data, this.state.request);

        await this._modalRef.current!.closeAsync();

        await this.props.onSubmit(this, this.state.request);
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    public render(): React.ReactNode {

        return (
            <Modal id={this.id} title={this.props.title} ref={this._modalRef} className={styles.picturesFiltersModal}>

                <Form className={styles.form}
                      onSubmit={async (sender, data) => await this.submitAsync(data)}
                >

                    <Inline className={styles.inline}>

                        <Dropdown name="types" ref={this._picturesTypesRef} multiple
                                  label={Localizer.picturesPreviewPagePicturesFiltersModalPictureType}
                                  nothingSelectedText={"-"}
                                  items={this.getDevicePicturesTypes()}
                        />

                        <DateInput name="date" ref={this._atRef} popup
                                   label={Localizer.picturesPreviewPagePicturesFiltersModalTakenOn}
                                   value={this.props.request.date || undefined}
                                   onChange={async () => await this.clearRangeAsync()}
                        />

                    </Inline>

                    <Inline className={styles.inline}>
                        <DateInput name="from" ref={this._fromRef} popup
                                   label={Localizer.picturesPreviewPagePicturesFiltersModalTakenFrom}
                                   value={this.props.request.from || undefined}
                                   onChange={async () => await this.clearAtAsync()}
                        />

                        <DateInput name="to" ref={this._beforeRef} popup
                                   label={Localizer.picturesPreviewPagePicturesFiltersModalTakenBefore}
                                   value={this.props.request.to || undefined}
                                   onChange={async () => await this.clearAtAsync()}
                        />
                    </Inline>

                    <ButtonContainer className={this.css(styles.buttons, this.mobile && styles.mobile)}>

                        <Button submit
                                type={ButtonType.Orange}
                                label={Localizer.devicesFiltersModalButtonApply}
                        />

                        <Button type={ButtonType.Blue}
                                label={Localizer.devicesFiltersModalButtonClear}
                                onClick={async () => await this.clearAsync()}
                        />

                    </ButtonContainer>

                </Form>

            </Modal>
        );
    }
};