import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import SummaryPage from "@/tests/pages/SummaryPage";
import DashboardPage from "@/tests/pages/DashboardPage";
import DevicePage from "@/tests/pages/DevicePage";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";
import Localizer from "@/localization/Localizer";

describe("Question page tests", () => {
  //1050 characters text
  const longText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a nulla a arcu laoreet porta ac vel libero. Etiam lobortis nulla eu pharetra lobortis. Etiam sit amet porttitor ante. Pellentesque dictum lacus nec dui egestas dignissim. Nulla ullamcorper varius cursus. Ut quis hendrerit nisl. Aenean mollis, sem nec imperdiet iaculis, ante metus ultrices est, sed condimentum est lectus id velit. Praesent consectetur commodo mi vitae aliquam.\n" +
    "\n" +
    "Quisque sed magna efficitur, ultricies magna vel, vestibulum massa. Quisque in augue at urna sodales vestibulum. Nullam eget nulla at dolor tincidunt interdum. Fusce mollis mi eu orci venenatis tempor. Aliquam convallis ullamcorper vulputate. Pellentesque leo magna, euismod a porta non, finibus eu sem. Donec ullamcorper dui at turpis porttitor, in mollis risus fermentum. Ut risus leo, congue ac eros vel, finibus ultricies lacus. Fusce vel finibus mauris. Aliquam efficitur eget risus nec eleifend.\n" +
    "\n" +
    "Donec felis magna, bibendum eu lorem id, mattis tempus";

  beforeEach(() => {
    cy.login();
    cy.goToDashboard();
  });

  it("Test comments validation", () => {
    DashboardPage.openDevice("100405");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.elements.stepNeedRepair().click();

    ReturnInspectionPage.elements.nextButton().click();

    ReturnInspectionPage.elements
      .commentValidation()
      .should("be.visible")
      .contains(Localizer.componentArsenalQuestionInputCommentRequired);

    ReturnInspectionPage.elements.commentInput().type("ads").blur();

    ReturnInspectionPage.elements.commentValidation().should("not.exist");

    ReturnInspectionPage.elements.commentInput().type(longText);

    ReturnInspectionPage.elements
      .commentInput()
      .invoke("val")
      .should("have.length", 1000);

    ReturnInspectionPage.elements.nextButton().click();

    SummaryPage.saveInspection();
  });

  it("Test comments validation when speech recognition is enabled", () => {
    CypressFeatureFlagService.enable(RentaToolsConstants.speechRecognitionEnabled);

    cy.wait(500);

    cy.reload();

    DashboardPage.openDevice("922612");

    DevicePage.tryToResetReturnInspection();

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.elements.stepNeedRepair().click();

    ReturnInspectionPage.elements.nextButton().click();

    ReturnInspectionPage.elements
        .commentValidation()
        .should("be.visible")
        .contains(Localizer.componentArsenalQuestionInputCommentRequired);

    ReturnInspectionPage.elements.commentInput().type("Test input text validation").blur();

    ReturnInspectionPage.elements.commentValidation().should("not.exist");

    ReturnInspectionPage.elements.nextButton().click();

    ReturnInspectionPage.elements.stepTitle().should('contain', 'Question #2');

    cy.goToDashboard();

    DashboardPage.openDevice("922612");

    DevicePage.tryToStopReturnInspectionAsync();
  });
});