import {RouteData} from "@/helpers/CypressHelper";

class RecordObservationPage {
    public elements = {
        subtitle: () => cy.get("[id=subtitle]"),
        imageInput: () => cy.get("input[type=file]"),
        observationCommentInput: () => cy.get("[id=observation_comment]"),
        addFaultBtn: () => cy.get("[id=btn_add_fault]"),
        deviceContractInput: () => cy.get("[id=deviceContractId]"),
        observationDateInput: () => cy.get("[id=observationDate]"),
        faultCommentInput: (index: number) => cy.get(`[id=fault_comment_${index}]`),
        removeFaultBtn: (index: number) => cy.get(`[id=btn_remove_fault_${index}]`),
    };

    public routes = {
        saveReturnInspection: {
            path: "/api/devices/RecordObservation",
            method: "POST",
        } as RouteData,
        getDeviceContracts: {
            path: "api/device/getDeviceContracts",
            method: "POST",
        } as RouteData
    }
}

export default new RecordObservationPage();