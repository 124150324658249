import React, {useEffect, useRef, useState} from "react";
import {DeviceShortView} from "@/models/server/DeviceShortView";
import {ButtonType} from "@renta-apps/athenaeum-react-components";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import PageDefinitions from "@/providers/PageDefinitions";
import {DeviceListOrderType} from "@/models/Enums";
import {PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import NavigationMenu from "@/pages/EditOrderDevicePage/components/NavigationMenu/NavigationMenu";
import EditDeviceOrderMenu from "@/pages/EditOrderDevicePage/components/EditDeviceOrderMenu/EditDeviceOrderMenu";

import styles from './EditDeviceOrder.module.scss';
import {Utility} from "@renta-apps/athenaeum-toolkit";

export interface IEditDeviceOrderProps {
    devices : DeviceShortView[];
    depoId : string;
    reFetchDevicesAsync : () => Promise<void>;
    onSave: (depoId : string, devices: DeviceShortView[], fixedOrder : boolean) => Promise<void>;
    fixedOrder : boolean;
    orderType : DeviceListOrderType
}

const EditDeviceOrder = ({ devices, depoId, reFetchDevicesAsync, onSave, fixedOrder, orderType } : IEditDeviceOrderProps) => {

    useEffect(() => {
        setDevices(devices);
    }, [devices]);
    
    const [dragDisabled, setDragDisabled] = useState<boolean>(false);
    const [currentDevices, setDevices ] = useState(devices);
    const [focusedDeviceId, setFocusedDeviceId] = useState<string | null>(null);
    

    const draggableRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    
    const onDeviceFilter = (value : string) => {
        setFocusedDeviceId(null);
        const deviceElement = draggableRefs.current[value];
        
        if(deviceElement) {
            deviceElement.scrollIntoView({behavior: 'smooth', block: "center", inline: "nearest"});
            setFocusedDeviceId(value);
        }
    }
    
    const onFixedOrderChange = async (value : boolean) => {
        await onSave(depoId, [], value);
        await reFetchDevicesAsync();
    }
    const onDragEnd = (result : DropResult) => {
        if (!result.destination) return; // If dropped outside the list
        const { source, destination } = result;
        
        moveElement(source.index, destination.index);
    };

    const moveTo = (device : DeviceShortView, destination : number) => {
        
        const currentIndex = currentDevices.findIndex((d) => d.id === device.id);
        
        moveElement(currentIndex, destination);
    };
    
    const moveElement = (currentIndex : number, destination : number) => {
        const newItems = [...currentDevices];
        const [removed] = newItems.splice(currentIndex, 1);

        newItems.splice(destination, 0, removed);

        const updatedItems = newItems.map((item, index) => ({
            ...item,
            index: index + 1,
            updated: true,
        }));

        setDevices(updatedItems);
    }
    
    const onExit = async () => {
        let route: PageRoute;
        switch (orderType) {
            case DeviceListOrderType.ServiceDevices:
                route = PageDefinitions.servicedDevicesRoute;
                break;
            case DeviceListOrderType.ReturnedDevices:
                route = PageDefinitions.returnedDevicesRoute;
                break;
        }

        await PageRouteProvider.redirectAsync(route, true);
    }
    
    const save = async () => {
        await onSave(depoId, currentDevices, fixedOrder)
        await onExit();
    }
    
    
    return <div>
        <EditDeviceOrderMenu
            devices={currentDevices}
            fixedOrder={fixedOrder}
            onRentaIdChange={(value) => onDeviceFilter(value)}
            onFixedOrderChange={(value) => onFixedOrderChange(value)}
        />
            <DragDropContext onDragEnd={onDragEnd} >
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {currentDevices.map((device, index) => (
                                <Draggable key={device.id} draggableId={device.id} index={index} isDragDisabled={dragDisabled}>
                                    {(provided) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={(el) => {(draggableRefs.current[device.deviceExternalId] = el); return provided.innerRef(el);} }
                                            className={Utility.css(styles.wrapper, focusedDeviceId === device.deviceExternalId && styles.focused)} 
                                            key={device.id}
                                            data-cy="device-to-order" 
                                            id={`device_${device.deviceExternalId}`}
                                        >
                                            <div className={styles.textInformation}>
                                                <p className={styles.bold} data-cy={"device-external-id"}>{device.deviceExternalId}</p>
                                                {
                                                    (!!device.serialNumber) && (
                                                        <p> {device.serialNumber} </p>
                                                    )
                                                }
                                                <p>{device.name}</p>
                                            </div>

                                            <div className={styles.buttonContainer}>
                                                <ArsenalButton action
                                                               id={"up-button"}
                                                               type={ButtonType.Orange}
                                                               icon={{name: 'fas fa-arrow-to-top', className: styles.buttonIcon}}
                                                               onClick={async () => moveTo(device, 0)}
                                                />
                                                <ArsenalButton action
                                                               id={"down-button"}
                                                               type={ButtonType.Blue}
                                                               icon={{name: 'fas fa-arrow-to-bottom', className: styles.buttonIcon}}
                                                               onClick={async () => moveTo(device, currentDevices.length - 1)}
                                                />
                                            </div>
                                            
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <NavigationMenu saveAsync={async () => await save()} exitAsync={async () => await onExit()}/>
    </div>
}

export default EditDeviceOrder;