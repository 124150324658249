import {PredictionDatesType, PredictionMaintenanceReason} from "@/models/Enums";

export default class SaveCounterfactualForecastRequest {
    public deviceId: string = "";

    public forecastDate: Date = new Date();

    public servicePredictionDate: Date = new Date();

    public predictionDateType: PredictionDatesType = PredictionDatesType.LinearPredictionDate;

    public maintenanceReason: PredictionMaintenanceReason = PredictionMaintenanceReason.AnnualService;

    public comment: string | null = null;

    public sendNotification: boolean = false;
}