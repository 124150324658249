import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import ReportDefinitionsPage from "@/tests/pages/ReportDefinitionsPage";
import Localizer from "@/localization/Localizer";

// Report definition
export type ReportDefinitionSettings = {
    reportName: string;
    errorCodes?: boolean | null;
    steps: ReportDefinitionStepsSettings[];
}

export type ReportDefinitionStepsSettings = {
    icon?: string;
    name: string;
    title?: string;
    typeDropdown: ReportDefinitionStepType;
    manual?: string;
    telematics?: boolean | null;
    generateFault?: boolean | null;
    faultLevel?: ReportDefinitionStepFaultLevel | null;
    canAddAdditionalExpenses?: boolean;
    additionalExpenses?: string[] | null;
    stepTypeItems?: ReportStepCheckTypeItem[] | null;
}

// "Tarkastus kuvilla" | "Tarkastus" | "Resurssi" | "Resurssi kysymyksellä" | "Kuvat"    | "Mittaus"          | "Monitarkastus" | "ErrorsCodes" | "Edistynyt tarkastus";
// "Question Pictures" | "Question"  | "Resource" | "Question Resource"     | "Pictures" | "Measure Resource" | "Checks"        | "ErrorsCodes" | "Advanced Checks";
export type ReportDefinitionStepType = "Tarkastus kuvilla" | "Tarkastus" | "Resurssi" | "Resurssi kysymyksellä" | "Kuvat" | "Mittaus" | "Monitarkastus" | "ErrorsCodes" | "Edistynyt tarkastus";

// "Minor" | "Major"
// "Lievä" | "Vakava"
export type ReportDefinitionStepFaultLevel = "Lievä" | "Vakava";

type ReportStepCheckTypeItem = {
    checkStepName: string;
}

class ReportDefinitionPage {
    public elements = {
        reportDefinition: {
            reportDefinitionPageContainer: () => cy.get('#reportDefinitionPageContainer'),
            nameInput: () => cy.get('#input_name'),
            errorCodesSwitch: () => cy.get('#errorCodes .athenaeum-form-inputContainer'),
            saveButton: () => cy.get('#saveReportDefinition'),
            restoreButton: () => cy.get('#restoreReportDefinition'),
            deleteButton: () => cy.get('#deleteReportDefinition'),
            steps: {
                stepsContainer: () => cy.get("#reportDefinitionSteps"),
                icon: (index: number) => cy.get(`#input_icon_${index}`),
                name: (index: number) => cy.get(`#input_name_${index}`),
                title: (index: number) => cy.get(`#input_title_${index}`),
                typeDropdown: (index: number) => cy.get(`#type_${index}`),
                manual: (index: number) => cy.get(`#input_manual_${index}`),
                telematicsCheckbox: (index: number) => cy.get(`#telematics_${index} i`),
                generateFaultCheckbox: (index: number) => cy.get(`#fault_level_enabled_${index} i`),
                faultLevelDropdown: (index: number) => cy.get(`#fault_level_${index}`),
                navigation: {
                    addStep: (index: number) => cy.get(`#add_step_${index}`),
                    moveStepUp: (index: number) => cy.get(`#move_up_step_${index}`),
                    moveStepDown: (index: number) => cy.get(`#move_down_step_${index}`),
                    removeStep: (index: number) => cy.get(`#delete_step_${index}`),
                },
                checksStep: {
                    addNewCheck: (index: number) => cy.get(`#addCheckItemButton_${index}`),
                    checkItemNameInput: (index: number, checkItemIndex: number) => cy.get(`#input_checkItemName_${index}_${checkItemIndex}`),
                },
                additionalExpenses: {
                    checkboxCanAddAdditionalExpenses: (index: number) => cy.get(`#additionalExpensesCanBeAdded_${index} i`),
                    additionalExpensesDropdown: (index: number) => cy.get(`[id=additionalExpenses_${index}]`),
                },
            },

            saveReportDefinitionButton: () => cy.get('[id="saveReportDefinition"]'),
        },
        serviceDefinition: {
            totalRentalDaysLimit: {
                checkbox: () => cy.get('[id="totalRentalDaysLimit_enabled"]'),
                input: () => cy.get('#input_totalRentalDaysLimit')
            },
            totalOperatingHoursLimit: {
                checkbox: () => cy.get('[id="totalOperationHoursLimit_enabled"]'),
                input: () => cy.get('#input_totalOperationHoursLimit')
            },
            totalLifeMonthsLimit: {
                checkbox: () => cy.get('[id="totalMonthsLimit_enabled"]'),
                input: () => cy.get('#input_totalMonthsLimit')
            },
            currentRentalDaysLimit: {
                checkbox: () => cy.get('[id="currentRentalDaysLimit_enabled"]'),
                input: () => cy.get('#input_currentRentalDaysLimit')
            },
            currentOperatingHoursLimit: {
                checkbox: () => cy.get('[id="currentOperationHoursLimit_enabled"]'),
                input: () => cy.get('#input_currentOperationHoursLimit')
            },
            annualServiceMonthsLimit: {
                checkbox: () => cy.get('[id="annualServiceMonthsLimit_enabled"]'),
                input: () => cy.get('#input_annualServiceMonthsLimit')
            },
            saveServiceTypeButton: () => cy.get('[id="saveServiceType"]'),
        },
    }

    public routes = {
        getServiceActions: {
            path: "/api/action/getServiceActions",
            method: "POST",
        } as RouteData,
        saveServiceReportDefinition: {
            path: "/api/reportDefinition/saveServiceReportDefinition",
            method: "POST",
        } as RouteData,
        saveReportDefinitionSteps: {
            path: "/api/reportDefinition/saveReportDefinitionSteps",
            method: "POST",
        } as RouteData,
        getPhases: {
            path: "/api/Phase/GetPhases",
            method: "GET",
        } as RouteData,
    };

    public constants = {
        cypressTestProductGroupNameForRestoringReportDefinition: "Cypress Test restoring Report Definition",
        cypressTestDeviceExternalIdForRestoringReportDefinition: "CypressTestDeviceForRestoringReportDefinition"
    };

    public toggleTotalRentalDaysTrigger(): void {
        this.elements.serviceDefinition.totalRentalDaysLimit.checkbox().first().should('not.be.selected').realClick();
        this.elements.serviceDefinition.totalRentalDaysLimit.input().first().should('be.visible').and('have.value', 1);
    }

    public toggleTotalOperatingHoursTrigger(): void {
        this.elements.serviceDefinition.totalOperatingHoursLimit.checkbox().first().should('not.be.selected').realClick();
        this.elements.serviceDefinition.totalOperatingHoursLimit.input().first().should('be.visible').and('have.value', 1);
    }

    public toggleTotalLifeMonthsTrigger(): void {
        this.elements.serviceDefinition.totalLifeMonthsLimit.checkbox().first().should('not.be.selected').realClick();
        this.elements.serviceDefinition.totalLifeMonthsLimit.input().should('be.visible').and('have.value', 1);
    }

    public toggleCurrentRentalDaysTrigger(): void {
        this.elements.serviceDefinition.currentRentalDaysLimit.checkbox().first().should('not.be.selected').realClick();
        this.elements.serviceDefinition.currentRentalDaysLimit.input().first().should('be.visible').and('have.value', 1);
    }

    public toggleCurrentOperatingHoursTrigger(): void {
        this.elements.serviceDefinition.currentOperatingHoursLimit.checkbox().first().should('not.be.selected').realClick();
        this.elements.serviceDefinition.currentOperatingHoursLimit.input().first().should('be.visible').and('have.value', 1);
    }

    public toggleAnnualServiceMonthsTrigger(): void {
        this.elements.serviceDefinition.annualServiceMonthsLimit.checkbox().first().should('not.be.selected').realClick();
        this.elements.serviceDefinition.annualServiceMonthsLimit.input().first().should('be.visible').and('have.value', 1);
    }

    public saveService(): void {
        executeWithIntercept(() =>
                this.elements.serviceDefinition.saveServiceTypeButton().realClick(),
            [this.routes.saveServiceReportDefinition]);
    }

    public saveReport(): void {
        executeWithIntercept(() =>
                this.elements.reportDefinition.saveReportDefinitionButton().realClick(),
            [this.routes.saveReportDefinitionSteps]);
    }

    public createReportDefinition(name: string, steps: ReportDefinitionStepsSettings[], errorCodes: boolean = false): ReportDefinitionSettings {
        return {
            reportName: name,
            steps: steps,
            errorCodes: errorCodes
        };
    }

    public addReportDefinition(reportDefinitionSettings: ReportDefinitionSettings): void {
        cy.goToReportDefinitionsPage();
        ReportDefinitionsPage.chooseType(Localizer.enumReportDefinitionTypeReturnInspection);
        ReportDefinitionsPage.elements.reportDefinitionsList()
            .should('be.visible');

        executeWithIntercept(() => ReportDefinitionsPage.elements.add().click(),
            [this.routes.getPhases]);

        this.elements.reportDefinition.reportDefinitionPageContainer()
            .should('be.visible');

        cy.log(`Fill Report Definition`);

        this.elements.reportDefinition.reportDefinitionPageContainer().within(() => {
            describe("Fill Report Definition", () => {
                if (reportDefinitionSettings.reportName) {
                    this.elements.reportDefinition
                        .nameInput()
                        .focused()
                        .clear()
                        .type(reportDefinitionSettings.reportName);
                }

                if (reportDefinitionSettings.errorCodes != null) {
                    this.elements.reportDefinition.errorCodesSwitch()
                        .should('be.visible')
                        .then(($el) => {
                            if (reportDefinitionSettings.errorCodes === true && $el.find('input').not(':checked')) {
                                this.elements.reportDefinition.errorCodesSwitch().click();
                            } else if (reportDefinitionSettings.errorCodes === false && $el.find('input').is(':checked')) {
                                this.elements.reportDefinition.errorCodesSwitch().click();
                            }
                        });
                }

                if (reportDefinitionSettings.steps) {
                    reportDefinitionSettings.steps.forEach((step, index) => {
                        // ErrorCodes steps is added to the beginning of the array.
                        if (reportDefinitionSettings.errorCodes === true && step === reportDefinitionSettings.steps[0]) {
                            return;
                        }

                        if (step.icon) {
                            this.elements.reportDefinition.steps.icon(index)
                                .type(step.icon);
                        }

                        if (step.title) {
                            this.elements.reportDefinition.steps.title(index)
                                .type(step.title);
                        }

                        if (step.name) {
                            this.elements.reportDefinition.steps.name(index)
                                .type(step.name);
                        }

                        if (step.typeDropdown) {
                            this.elements.reportDefinition.steps.typeDropdown(index)
                                .selectDropdownValue(step.typeDropdown, true);
                        }

                        if (step.manual) {
                            this.elements.reportDefinition.steps.manual(index)
                                .type(step.manual);
                        }

                        if (step.telematics != null) {
                            this.elements.reportDefinition.steps.telematicsCheckbox(index)
                                .should('be.visible')
                                .then(($el) => {
                                    if (step.telematics === true && $el.find('input').not(':checked')) {
                                        this.elements.reportDefinition.steps.telematicsCheckbox(index).click();
                                    } else if (step.telematics === false && $el.find('input').is(':checked')) {
                                        this.elements.reportDefinition.steps.telematicsCheckbox(index).click();
                                    }
                                });
                        }

                        if (step.generateFault != null) {
                            this.elements.reportDefinition.steps.generateFaultCheckbox(index)
                                .should('be.visible')
                                .then(($el) => {
                                    if (step.generateFault === true && $el.find('input').not(':checked')) {
                                        this.elements.reportDefinition.steps.generateFaultCheckbox(index).click();
                                    } else if (step.generateFault === false && $el.find('input').is(':checked')) {
                                        this.elements.reportDefinition.steps.generateFaultCheckbox(index).click();
                                    }
                                });
                        }

                        if (step.faultLevel) {
                            this.elements.reportDefinition.steps.faultLevelDropdown(index)
                                .should('be.visible')
                                .selectDropdownValue(step.faultLevel);
                        }

                        // Checks
                        if (step.typeDropdown === "Monitarkastus") {
                            const checkItems = step.stepTypeItems as ReportStepCheckTypeItem[];
                            checkItems.forEach((checkItem, checkItemIndex) => {
                                this.elements.reportDefinition.steps.checksStep.addNewCheck(index).click();
                                this.elements.reportDefinition.steps.checksStep.checkItemNameInput(index, checkItemIndex)
                                    .should('be.visible');
                                this.elements.reportDefinition.steps.checksStep.checkItemNameInput(index, checkItemIndex)
                                    .type(checkItem.checkStepName);
                            });
                        }

                        if (step.canAddAdditionalExpenses) {
                            this.elements.reportDefinition.steps.additionalExpenses.checkboxCanAddAdditionalExpenses(index).click();

                            if (step.additionalExpenses) {
                                step.additionalExpenses.forEach(expense => {
                                    this.elements.reportDefinition.steps.additionalExpenses.additionalExpensesDropdown(index)
                                        .selectDropdownValue(expense);
                                });
                            }
                        }

                        if (reportDefinitionSettings.steps.length > index + 1) {
                            this.elements.reportDefinition.steps.navigation.addStep(index).click();
                        }
                    });
                }
            });

            this.saveReport();
            cy.contains(Localizer.reportDefinitionsPageDataSaved);
        })
    }

    public deleteReportDefinition(reportDefinitionName: string, markAsDeleted: boolean = false): void {
        this.navigateToReportDefinitionPage(reportDefinitionName);

        this.elements.reportDefinition.deleteButton().trigger('click');

        cy.get('.athenaeum-confirmation-dialog-opened button.athenaeum-button-color_orange')
            .first()
            .trigger('click', {force: true});

        if (markAsDeleted) {
            cy.goToReportDefinitionsPage();

            ReportDefinitionsPage.elements.showDeleted().click();

            ReportDefinitionsPage.elements.reportDefinitionsList()
                .find('.bg-deleted')
                .contains(reportDefinitionName)
                .should('exist');
        } else {
            ReportDefinitionsPage.elements.reportDefinitionsList()
                .children()
                .contains(reportDefinitionName)
                .should('not.exist');
        }
    }

    public restoreReportDefinition(reportDefinitionName: string): void {
        this.navigateToReportDefinitionPage(reportDefinitionName, true);

        this.elements.reportDefinition.restoreButton().click();

        // Assert
        cy.goToReportDefinitionsPage();
        ReportDefinitionsPage.elements.reportDefinitionsList()
            .children()
            .contains(reportDefinitionName)
            .should('exist');
    }

    public navigateToReportDefinitionPage(reportDefinitionName: string, showDeleted: boolean = false): void {
        cy.goToReportDefinitionsPage();
        ReportDefinitionsPage.chooseType(Localizer.enumReportDefinitionTypeReturnInspection);

        if (showDeleted) {
            ReportDefinitionsPage.elements.showDeleted().click();

            ReportDefinitionsPage.elements.reportDefinitionsList()
                .find('.bg-deleted')
                .contains(reportDefinitionName)
                .should('exist');
        }

        executeWithIntercept(() =>
                ReportDefinitionsPage.elements.reportDefinitionsList()
                    .children()
                    .contains(reportDefinitionName)
                    .click(),
            [this.routes.getPhases]);
    }

    public removeReportDefinitionLastStepTest(reportDefinitionSettings: ReportDefinitionSettings): void {
        this.navigateToReportDefinitionPage(reportDefinitionSettings.reportName);

        this.elements.reportDefinition.steps.stepsContainer()
            .find("tr")
            .should('have.length', reportDefinitionSettings.steps.length)

        this.elements.reportDefinition.steps.navigation.removeStep(reportDefinitionSettings.steps.length - 1).click();

        executeWithIntercept(() => this.elements.reportDefinition.saveButton().click(),
            [this.routes.saveReportDefinitionSteps]);

        // Assert
        this.navigateToReportDefinitionPage(reportDefinitionSettings.reportName);

        this.elements.reportDefinition.steps.stepsContainer()
            .find("tr")
            .should('have.length', reportDefinitionSettings.steps.length - 1);
    }

    public moveReportDefinitionStepTest(reportDefinitionSettings: ReportDefinitionSettings): void {
        // Test move 1st step on 2nd position
        this.navigateToReportDefinitionPage(reportDefinitionSettings.reportName);

        this.elements.reportDefinition.steps.navigation.moveStepDown(0).click();

        executeWithIntercept(() => this.elements.reportDefinition.saveButton().click(),
            [this.routes.saveReportDefinitionSteps]);

        // Assert
        this.elements.reportDefinition.steps.name(1)
            .should('be.visible')
            .and('have.value', reportDefinitionSettings.steps[0].name);

        // Test move step 3 on 2d (current step[0]) position
        this.elements.reportDefinition.steps.navigation.moveStepUp(2).click();

        executeWithIntercept(() => this.elements.reportDefinition.saveButton().click(),
            [this.routes.saveReportDefinitionSteps]);

        // Assert
        this.elements.reportDefinition.steps.name(1)
            .should('be.visible')
            .and('have.value', reportDefinitionSettings.steps[2].name);
    }

    public validateReportDefinition(reportDefinitionSettings: ReportDefinitionSettings): void {
        this.navigateToReportDefinitionPage(reportDefinitionSettings.reportName);

        this.elements.reportDefinition.reportDefinitionPageContainer().within(() => {
            describe("Validate Report Definition", () => {
                if (reportDefinitionSettings.reportName) {
                    this.elements.reportDefinition.nameInput()
                        .should('be.visible')
                        .and('have.value', reportDefinitionSettings.reportName);
                }

                if (reportDefinitionSettings.errorCodes != null) {
                    this.elements.reportDefinition.errorCodesSwitch()
                        .should('be.visible')
                        .within(() => {
                            if (reportDefinitionSettings.errorCodes === true) {
                                cy.get('div').first().should('have.class', 'athenaeum-switch-checked');
                            } else {
                                cy.get('div').first().should('not.have.class', 'athenaeum-switch-checked');
                            }
                        });
                }

                if (reportDefinitionSettings.steps) {
                    reportDefinitionSettings.steps.forEach((step, index) => {
                        // ErrorCodes steps is added to the beginning of the array.
                        if (reportDefinitionSettings.errorCodes === true && step === reportDefinitionSettings.steps[0]) {
                            return;
                        }

                        if (step.icon) {
                            this.elements.reportDefinition.steps.icon(index)
                                .should('be.visible')
                                .and('have.value', step.icon);
                        }

                        if (step.name) {
                            this.elements.reportDefinition.steps.name(index)
                                .should('be.visible')
                                .and('have.value', step.name);
                        }

                        if (step.title) {
                            this.elements.reportDefinition.steps.title(index)
                                .should('be.visible')
                                .and('have.value', step.title);
                        }

                        if (step.typeDropdown) {
                            this.elements.reportDefinition.steps.typeDropdown(index)
                                .should('be.visible')
                                .validateDropdownValueIsSelected(step.typeDropdown, true);
                        }

                        if (step.manual) {
                            this.elements.reportDefinition.steps.manual(index)
                                .should('be.visible')
                                .and('have.value', step.manual);
                        }

                        if (step.telematics != null) {
                            this.elements.reportDefinition.steps.telematicsCheckbox(index)
                                .should('be.visible')
                                .then(() => {
                                    if (step.telematics === true) {
                                        this.elements.reportDefinition.steps.telematicsCheckbox(index)
                                            .get('.athenaeum-checkbox-checkbox')
                                            .should('have.class', 'athenaeum-checkbox-checked');
                                    } else {
                                        this.elements.reportDefinition.steps.telematicsCheckbox(index)
                                            .get('.athenaeum-checkbox-checkbox')
                                            .should('have.class', 'athenaeum-checkbox-unchecked');
                                    }
                                });
                        }

                        if (step.generateFault != null) {
                            this.elements.reportDefinition.steps.generateFaultCheckbox(index)
                                .should('be.visible')
                                .then(() => {
                                    if (step.generateFault === true) {
                                        this.elements.reportDefinition.steps.generateFaultCheckbox(index)
                                            .get('.athenaeum-checkbox-checkbox')
                                            .should('have.class', 'athenaeum-checkbox-checked');
                                    } else {
                                        this.elements.reportDefinition.steps.generateFaultCheckbox(index)
                                            .get('.athenaeum-checkbox-checkbox')
                                            .should('have.class', 'athenaeum-checkbox-unchecked');
                                    }
                                });
                        }

                        if (step.faultLevel) {
                            this.elements.reportDefinition.steps.faultLevelDropdown(index)
                                .should('be.visible')
                                .validateDropdownValueIsSelected(step.faultLevel, true);
                        }

                        if (step.typeDropdown === "Monitarkastus") {
                            const checkItems = step.stepTypeItems as ReportStepCheckTypeItem[];
                            checkItems.forEach((checkItem, checkItemIndex) => {
                                this.elements.reportDefinition.steps.checksStep.checkItemNameInput(index, checkItemIndex)
                                    .should('be.visible')
                                    .and('have.value', checkItem.checkStepName);
                            });
                        }

                        if (step.canAddAdditionalExpenses) {
                            this.elements.reportDefinition.steps.additionalExpenses.checkboxCanAddAdditionalExpenses(index)
                                .should('be.visible')
                                .then(() => {
                                    if (step.canAddAdditionalExpenses === true) {
                                        this.elements.reportDefinition.steps.additionalExpenses.checkboxCanAddAdditionalExpenses(index)
                                            .get('.athenaeum-checkbox-checkbox')
                                            .should('have.class', 'athenaeum-checkbox-checked');
                                    } else {
                                        this.elements.reportDefinition.steps.additionalExpenses.checkboxCanAddAdditionalExpenses(index)
                                            .get('.athenaeum-checkbox-checkbox')
                                            .should('have.class', 'athenaeum-checkbox-unchecked');
                                    }
                                });
                        }

                        if (step.additionalExpenses) {
                            this.elements.reportDefinition.steps.additionalExpenses.additionalExpensesDropdown(index)
                                .should('be.visible');

                            step.additionalExpenses.forEach((additionalExpense) => {
                                this.elements.reportDefinition.steps.additionalExpenses.additionalExpensesDropdown(index)
                                    .validateDropdownValueIsSelected(additionalExpense);
                            });
                        }
                    });
                }
            });
        })
    }
}

export default new ReportDefinitionPage();