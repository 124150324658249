import {DayOfWeek, NotificationInterval, NotificationMediaType, NotificationType} from "@/models/Enums";

export default class BaseNotificationSetting {

    public notificationType: NotificationType = NotificationType.WrongServicePrediction;

    public mediaType: NotificationMediaType = NotificationMediaType.Email;

    public interval: NotificationInterval = NotificationInterval.OnDemand;

    public enabled: boolean = false;

    public sendTime: Date = new Date();

    public weeklySendOccurrence: DayOfWeek[] = [];

    public isBaseNotificationSetting: boolean = true;
}