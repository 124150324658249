import {ReportDefinitionType} from "@/models/Enums";

export default class BaseReportDefinition {
    
    public id: string = "";
    
    public name: string = "";
    
    public type: ReportDefinitionType = ReportDefinitionType.ReturnInspection;

    public generic: boolean = false;

    public restartServiceProgramAfterLastStep: boolean = false;

    public isDeleted: boolean = false;
}