import React from "react";
import {ButtonType, Dropdown, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import GetAnnualInspectionTypeMappingsResponse from "@/models/server/responses/GetAnnualInspectionTypeMappingsResponse";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import AnnualInspectionTypeMappings from "@/pages/Models/AnnualInspectionTypeMappings";
import {AnnualInspectionType} from "@/models/Enums";
import AddAnnualInspectionTypeMappingRequest from "@/models/server/requests/AddAnnualInspectionTypeMappingRequest";
import DeleteAnnualInspectionTypeMappingRequest
    from "@/models/server/requests/DeleteAnnualInspectionTypeMappingRequest";

import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";
import styles from "./AnnualInspectionTypesPage.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import ProductGroup from "@/models/server/ProductGroup";
import EnumProvider from "@/providers/EnumProvider";

interface IAnnualInspectionTypesProps {
}

interface IAnnualInspectionTypesState {
    annualInspectionTypeMappings: AnnualInspectionTypeMappings[];
    annualInspectionProductGroups: ProductGroup[];
}

export default class AnnualInspectionTypes extends AuthorizedPage<IAnnualInspectionTypesProps, IAnnualInspectionTypesState> {

    state: IAnnualInspectionTypesState = {
        annualInspectionTypeMappings: [],
        annualInspectionProductGroups: []
    };

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.loadDataAsync();
    }

    private async loadDataAsync(): Promise<void> {

        const annualInspectionTypeMappingsResponse: GetAnnualInspectionTypeMappingsResponse = await RentaToolsController.getAnnualInspectionTypeMappingsAsync();

        const annualInspectionTypeMappings = await this.validateAndAddInspectionTypes(annualInspectionTypeMappingsResponse?.annualInspectionTypeMappings);
        const annualInspectionProductGroups = annualInspectionTypeMappingsResponse?.productGroups;

        await this.setState({ annualInspectionTypeMappings, annualInspectionProductGroups });
    }

    private async validateAndAddInspectionTypes(annualInspectionTypeMappings: AnnualInspectionTypeMappings[]): Promise<AnnualInspectionTypeMappings[]> {

        for (let type in AnnualInspectionType) {

            if (isNaN(Number(type)))
                continue;

            let inspectionType: number = Number.parseInt(type);

            if (annualInspectionTypeMappings?.some(mapping => mapping?.annualInspectionType == inspectionType)) {
                continue;
            }

            const inspection = new AnnualInspectionTypeMappings();
            inspection.annualInspectionType = inspectionType as AnnualInspectionType;
            annualInspectionTypeMappings.push(inspection);
        }

        return annualInspectionTypeMappings;
    }

    private async addInspectionTypeMappingAsync(mapping: AnnualInspectionTypeMappings, productGroup: ProductGroup): Promise<void> {
        const request = new AddAnnualInspectionTypeMappingRequest();
        request.annualInspectionType = mapping.annualInspectionType;
        request.productGroupId = productGroup.productGroupId;

        await RentaToolsController.addAnnualInspectionTypeMapping(request);
        await this.loadDataAsync();
    }

    private async deleteInspectionTypeMappingAsync(mapping: AnnualInspectionTypeMappings, productGroup: ProductGroup): Promise<void> {
        const request = new DeleteAnnualInspectionTypeMappingRequest();
        request.annualInspectionType = mapping.annualInspectionType;
        request.productGroupId = productGroup.productGroupId;

        await RentaToolsController.deleteAnnualInspectionTypeMapping(request);
        await this.loadDataAsync();
    }

    private getProductGroupItemsExcludingAlreadyAdded(mapping: AnnualInspectionTypeMappings) {
        return this.state.annualInspectionProductGroups?.
        filter(productGroup => !mapping?.productGroups?.
            some(productGroupMap => productGroupMap?.productGroupId?.
                includes(productGroup?.productGroupId)
            )
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={styles.reportMappings}>
                <PageHeader title={Localizer.adminPageInspectionTypeMapping} subtitle={Localizer.mappingsPageSubtitle} />

                <div className="container">
                    {
                        this.state.annualInspectionTypeMappings.map((mapping) => (
                            <div id={`type_mapping_${mapping.annualInspectionType}`} className="col" key={mapping.annualInspectionType}>

                                <div className="row">
                                    <div id={`type_mapping_title_${mapping.annualInspectionType}`} className={this.css("col-8 bg-success", styles.reportDefinition)}>
                                        {`${ EnumProvider.getAnnualInspectionTypeText(mapping.annualInspectionType) }`}
                                    </div>
                                    <div className="col-4 text-right">
                                        <Dropdown nothingSelectedText="-"
                                                  id={`type_ddl_${mapping.annualInspectionType}`}
                                                  items={this.getProductGroupItemsExcludingAlreadyAdded(mapping)}
                                                  onChange={async (sender, item) => await this.addInspectionTypeMappingAsync(mapping, item!)}
                                        />
                                    </div>
                                </div>

                                <div className="col px-0">
                                    {
                                        (mapping?.productGroups?.length > 0)
                                            ?
                                            (
                                                mapping.productGroups.map((productGroup) => (
                                                    <div className="row align-items-center" key={productGroup?.productGroupId}>
                                                        <div className="col-1"/>
                                                        <div id={`product_group_mapping_${productGroup?.productGroupId}`}
                                                             className={this.css("col-9", styles.category)}>

                                                            { `${productGroup?.productGroupName} (${productGroup?.productGroupId})`}

                                                        </div>
                                                        <div className="col-2 text-right">
                                                            <ArsenalButton id="deleteInspectionTypeMapping"
                                                                           type={ButtonType.Blue}
                                                                           icon={{name: "far trash-alt"}}
                                                                           title={Localizer.mappingsPageButtonDeleteCategory}
                                                                           onClick={async () => await this.deleteInspectionTypeMappingAsync(mapping, productGroup)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                            :
                                            (
                                                <div className="row align-items-center">
                                                    <div className="col-1"/>
                                                    <div className={this.css("col-9", styles.category)}>
                                                        <span>{Localizer.mappingsPageTextNoCategories}</span>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>

            </PageContainer>
        );
    }
}