import React from "react";
import {PasswordForm, PasswordFormType, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";

import AuthorizedPage from "../../models/base/AuthorizedPage";
import ChangePasswordRequest from "../../models/server/requests/ChangePasswordRequest";
import Localizer from "../../localization/Localizer";

export default class ChangePasswordPage extends AuthorizedPage {

    private async changePasswordAsync(newPassword: string, oldPassword: string) {
        const request = new ChangePasswordRequest(newPassword, oldPassword);
        await this.postAsync("api/Application/ChangePassword", request);
    }

    public getTitle(): string {
        return Localizer.changePasswordPageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.changePasswordPageTitle}/>
                <PageRow>
                    <div className="col-lg-6">
                        <PasswordForm
                            onSubmit={(newPassword, oldPassword) => this.changePasswordAsync(newPassword, oldPassword)}
                            type={PasswordFormType.ChangePassword}/>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}