import React from "react";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import ErrorCodeModel from "@/models/ErrorCodeModel";
import { GetDeviceCodeFaultsRequest } from "@/models/server/requests/GetDeviceCodeFaultsRequest";
import { IErrorCodesPageState } from "@/pages/ErrorCodesPage/ErrorCodesPage";
import { ErrorCodesFilters } from "@/models/Enums";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import RentaToolsController from "@/pages/RentaToolsController";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import FaultsCodesService from "@/services/FaultsCodesService";
import UnleashHelper from "@/helpers/UnleashHelper";
import ErrorCodesList from "@/components/ErrorCodesList/ErrorCodesList";
import ErrorCodesTooltip from "@/components/ErrorCodesTooltip/ErrorCodesTooltip";

import styles from "./ReturnInspectionErrorCodesPage.module.scss";

interface IReturnInspectionErrorCodesPageProps {}

interface IReturnInspectionErrorCodesPageState
  extends IReturnInspectionWizardPageState {
  errorCodes: ErrorCodeModel[];
  isLoading: boolean;
}

class ReturnInspectionErrorCodesPage extends ReturnInspectionWizardPage<
  IReturnInspectionErrorCodesPageProps,
  IReturnInspectionErrorCodesPageState
> {
  state: IErrorCodesPageState = {
    errorCodes: [],
    isLoading: false,
  };

  private async loadFaultsAndSave() {
    if (this.preview) {
      const faultsModel = FaultsCodesService.createModelFromFaultCode(
        this.report.faultCodes,
      );
      this.setState({
        errorCodes: faultsModel,
      });
    } else {
      const faults =
        await ReturnInspectionController.getReturnInspectionFaultCode(
          RentaToolsController.device?.externalId!,
        );

      if (faults.faultCodes?.length && faults.faultCodes.length > 0) {
        const faultsModel = FaultsCodesService.createModelFromFaultCode(
          faults.faultCodes,
        );
        this.setState({
          errorCodes: faultsModel,
        });
      }

      this.setState({
        isLoading: false,
      });
    }
  }

  private async loadFaults() {
    if (this.preview) {
      this.setState({
        errorCodes: [],
      });
    } else {
      const request = new GetDeviceCodeFaultsRequest();
      request.deviceExternalId = RentaToolsController.device?.externalId;
      request.period = ErrorCodesFilters.RentalPeriodTillToday;

      const faults = await RentaToolsController.getDeviceFaultCode(request);

      if (faults.faultCodes?.length && faults.faultCodes.length > 0) {
        const faultsModel = FaultsCodesService.createModelFromFaultCode(
          faults.faultCodes,
        );
        this.setState({
          errorCodes: faultsModel,
        });
      }
    }
  }

  async initializeAsync(): Promise<void> {
    this.setState({
      isLoading: true,
    });

    if (UnleashHelper.isEnabled(RentaToolsConstants.featureSaveFaultCodeRi)) {
      await this.loadFaultsAndSave();
    } else {
      await this.loadFaults();
    }

    await super.initializeAsync();

    this.markStepAsDone();
  }

  private markStepAsDone() {
    this.getReportItem().completed = true;
    this.getReportItem().isTouched = true;

    RentaToolsController.saveContext();
  }

  renderContent(): React.ReactNode {
    return (
      <div>
        <div className={styles.tooltip}>
          <ErrorCodesTooltip />
        </div>
        <ErrorCodesList
          errorCodes={this.state.errorCodes}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

export default ReturnInspectionErrorCodesPage;
