export {}

describe('ServicePreview page tests', () => {
    before(() => {
    })

    beforeEach(() => {
        cy.login();
        cy.visit('Dashboard');
    })

    // describe('ServicePreview tests', () => {
    //
    //     it('ServiceEditInvoiceModal opens', () => {
    //         cy.goToDevicePage('96532');
    //
    //         cy.intercept('POST', '/api/report/listServiceReports').as('listServiceReports');
    //         cy.get('#device_page_previous_services_button').click();
    //         cy.waitSuccess('@listServiceReports');
    //
    //         cy.get('tr').first().find('td').eq(1).click();
    //
    //         cy.get('#open_edit_modal_button').trigger('click');
    //
    //         CheckModalContent();
    //     })
    // })


    function CheckModalContent() {
        cy.get('#editInvoiceModal').find('#invoiceNumber');
        cy.get('#editInvoiceModal').find('#cost');
        cy.get('#editInvoiceModal').find('#operatingHours');
        cy.get('#editInvoiceModal').find('#serviceExpense');
        cy.get('#editInvoiceModal').find('#depot');
        cy.get('#editInvoiceModal').find('#locationType');
        cy.get('#editInvoiceModal').find('#comment');
    }

})