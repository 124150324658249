import { IFeatureToggle } from "@/models/FeatureToggle";
import { IFeatureToggleResponse } from "@/models/server/responses/FeatureToggleResponse";
import HttpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";

export default class LocalFeatureToggleClient {
  private _toggles: IFeatureToggle[];
  private readonly _interval: number = 30000;
  private _timerRef?: NodeJS.Timer;

  constructor() {
    this._toggles = [];
  }

  private async fetchToggles() {
    const response: IFeatureToggleResponse = await HttpClient.getAsync<IFeatureToggleResponse>(
      EndpointPaths.LocalFeatureFlags.GetFeatureToggle,
    );
    this._toggles = response.flags;
  }

  public async start(): Promise<void> {
    await this.fetchToggles();

    if (this._interval > 0) {
      this._timerRef = setInterval(() => this.fetchToggles(), this._interval);
    }
  }

  public isEnabled(name: string) {
    if (this._toggles.length > 0) {
      const flag = this._toggles.find((p) => p.flagName === name.toUpperCase());
      if (flag) {
        return flag.enabled;
      }
    }
    return false;
  }
}