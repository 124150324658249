import DashboardPage from "@/tests/pages/DashboardPage";
import DeviceAnnualInspectionsPage from "@/tests/pages/DeviceAnnualInspectionsPage";
import DevicePage from "@/tests/pages/DevicePage";
import DeviceInfoPanel from "@/tests/pages/DeviceInfoPanel";
import {executeWithIntercept} from "@/helpers/CypressHelper";

describe('Device annual inspections page tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    })

    it('Validates buttons for device with open remarks', () => {

        DashboardPage.openDevice("90740");

        executeWithIntercept(() => DevicePage.elements.annualInspectionBtn().click(), [DeviceAnnualInspectionsPage.routes.inspectionHistory])


        DeviceAnnualInspectionsPage.elements.fixRemarksBtn();
        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn();

    })

    it('Validates buttons for device for accepting annual inspections', () => {

        DashboardPage.openDevice("90739");

        executeWithIntercept(() => {  DevicePage.elements.annualInspectionBtn().click();
            }, [DeviceAnnualInspectionsPage.routes.inspectionHistory])

            DeviceAnnualInspectionsPage.elements.acceptBtn();
            DeviceAnnualInspectionsPage.elements.withRemarksBtn();
            DeviceAnnualInspectionsPage.elements.previousInspectionsBtn();
    })

    it('DeviceInfoPanel works', () => {
        DashboardPage.openDevice("90740");

        executeWithIntercept(() => {
            DevicePage.elements.annualInspectionBtn().click();

        }, [DeviceAnnualInspectionsPage.routes.inspectionHistory])

        DeviceInfoPanel.elements.externalId();
        DeviceInfoPanel.elements.deviceType();
        DeviceInfoPanel.elements.productGroupName();

        DeviceInfoPanel.assertProductGroupNameMatch("TEST GROUP NAME 16811")
        DeviceInfoPanel.assertDeviceTypeMatch("TEST DEVICE TYPE")
        DeviceInfoPanel.assertExternalIdMatch("90740")
    });
});