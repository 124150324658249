import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import Device from "@/pages/Models/Device";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {
    Button,
    ButtonContainer,
    ButtonType,
    DateInput,
    Form,
    Inline,
    Modal,
    NumberInput,
    TextAreaInput
} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/DevicePage/SkipServiceModal/SkipServiceModal.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ToolsUtility from "@/helpers/ToolsUtility";
import HttpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";
import GetInitialServiceDetailsResponse from "@/models/server/responses/GetInitialServiceDetailsResponse";

export class SkipServiceResult {
    public lastServiceDate: Date | null = null;

    public lastServiceOperatingHours: number | null = null;

    public comment: string = "";
}

interface ISkipServiceModalProps {
}

interface ISkipServiceModalState {
}

export default class SkipServiceModal extends BaseComponent<ISkipServiceModalProps, ISkipServiceModalState> {
    state: ISkipServiceModalState = {};

    private readonly _modalRef: React.RefObject<Modal<Device>> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private readonly _result: SkipServiceResult = new SkipServiceResult();
    private _resolver: ((result: SkipServiceResult | null) => void) | null = null;

    private async invokeOnCloseAsync(confirm: boolean): Promise<void> {
        if (this._resolver) {

            let result: SkipServiceResult | null = null;
            if (confirm) {
                result = this._result;
                if (!this.hasServices) {
                    result.lastServiceDate = this.lastServiceDateFromErpInAttributes(this.device) ? ToolsUtility.createDateFromString(this.lastServiceDateFromErpInAttributes(this.device)!) :  null;
                    result.lastServiceOperatingHours = this.lastServiceOpHFromErpInAttributes(this.device) ?? null;
                }
            }

            this._resolver(result);
            this._resolver = null;
        }
    }

    private async onCloseAsync(): Promise<void> {
        await this.invokeOnCloseAsync(false);
        await this.modal.closeAsync();
    }

    private async onSubmitAsync(): Promise<void> {
        await this.invokeOnCloseAsync(true);
        await this.modal.closeAsync();
    }

    private async setDateAsync(value: Date): Promise<void> {
        this._result.lastServiceDate = value;
    }

    private async setOperatingHoursAsync(value: number): Promise<void> {
        this._result.lastServiceOperatingHours = value;
    }

    private async setCommentAsync(value: string): Promise<void> {
        this._result.comment = value;
    }

    private get hasData(): boolean {
        return ((this._modalRef.current != null) && (this.modal.data != null));
    }

    private get device(): Device {
        return this.modal.data!;
    }

    private get modal(): Modal<Device> {
        return this._modalRef.current!;
    }

    private get hasServices(): boolean {
        return (this.hasData) && (!this.device.hasServices);
    }

    private lastServiceDateFromErpInAttributes(device: Device): string | null {
        return device.attributes.find(att => att.name == RentaToolsConstants.LastServiceFromErpAttributeName)?.value ?? null;
    }

    private lastServiceOpHFromErpInAttributes(device: Device): number | null {
        return (device.attributes.find(att => att.name == RentaToolsConstants.LastServiceOperatingHoursFromErpAttributeName))
            ? Number(device.attributes.find(att => att.name == RentaToolsConstants.LastServiceOperatingHoursFromErpAttributeName)?.value)
            : null;
    }

    public async confirmAsync(device: Device): Promise<SkipServiceResult | null> {

        let date = device.lastServiceDate;
        let operatingHours = device.lastServiceOperatingHours ?? this.lastServiceOpHFromErpInAttributes(device);

        if (!date) {
            let dateFromErp = this.lastServiceDateFromErpInAttributes(device);
            if (dateFromErp) {
                date = ToolsUtility.createDateFromString(dateFromErp);
            }
        }

        if (!date && !ch.isFinland) {
            const erpDateResponse: GetInitialServiceDetailsResponse = await HttpClient.postAsyncWithoutErrorHandling(EndpointPaths.DevicePaths.GetInitialServiceDetails, device.externalId);
            const erpDate = erpDateResponse?.lastServiceDate;
            const hours = erpDateResponse?.lastServiceOperatingHours;

            if (erpDate) {
                date = erpDate;
            }

            if (hours) {
                operatingHours = hours;
            }
        }

        this._result.lastServiceOperatingHours = operatingHours;
        this._result.lastServiceDate = date ?? Utility.today();

        await this.modal.openAsync(device);

        await this.reRenderAsync();

        return new Promise<SkipServiceResult | null>((resolve) => {
            this._resolver = resolve;
        });
    }

    public static get modalId(): string {
        return "skipServiceModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal id={SkipServiceModal.modalId}
                   ref={this._modalRef}
                   title={Localizer.skipServiceModalTitle}
                   subtitle={Localizer.skipServiceModalSubtitle}
                   onClose={async () => await this.onCloseAsync()}
            >

                {
                    (this.hasData) &&
                    (
                        <div className="row">
                            <div className="col">

                                <Form ref={this._formRef}
                                      id="skipService"
                                      onSubmit={async () => await this.onSubmitAsync()}>

                                    <Inline>

                                        <DateInput popup
                                                   className={styles.date}
                                                   label={Localizer.skipServiceModalLastServiceDate}
                                                   maxDate={Utility.today()}
                                                   value={(this._result.lastServiceDate || undefined)}
                                                   readonly={!this.hasServices}
                                                   onChange={async (date) => await this.setDateAsync(date)}
                                        />

                                        <NumberInput required
                                                     label={Localizer.skipServiceModalLastServiceOperatingHours}
                                                     format={"0.0"}
                                                     step={0.5}
                                                     min={0}
                                                     max={999999}
                                                     readonly={!this.hasServices}
                                                     value={this._result.lastServiceOperatingHours || undefined}
                                                     onChange={async (sender, lastOperatingHours) => await this.setOperatingHoursAsync(lastOperatingHours)}
                                        />

                                    </Inline>

                                    <TextAreaInput required
                                                   id="comment"
                                                   rows={4}
                                                   minLength={3}
                                                   placeholder={Localizer.skipServiceModalPlaceholder}
                                                   value={this._result.comment || undefined}
                                                   onChange={async (sender, value) => await this.setCommentAsync(value)}
                                    />

                                    <ButtonContainer>

                                        <Button submit
                                                id={"skip_service_modal_confirm_button"}
                                                className={newStyles.button}
                                                type={ButtonType.Orange}
                                                label={Localizer.genericSave}
                                        />

                                        <Button className={newStyles.button}
                                                type={ButtonType.Blue}
                                                icon={{name: "far window-close"}}
                                                label={Localizer.genericCancel}
                                                onClick={async () => await this.onCloseAsync()}
                                        />

                                    </ButtonContainer>

                                </Form>

                            </div>
                        </div>
                    )
                }

            </Modal>
        )
    }
}