import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import ServicedDevicesPage from "@/tests/pages/ServicedDevicesPage";

class DeviceFiltersModal {
    public elements = {
        applyButton: () => cy.get('[id = applyButton]'),
        filterButton: () => cy.get('[id = filterButton]'),
        clearButton: () => cy.get('[id = clearButton]'),
        inputs: {
            deviceExternalId: () => cy.get(`[id = input_deviceExternalId]`),
            depoCostPools: () => cy.get('[id = depoCostPools]'),
            productGroupIds: () => cy.get('[id = productGroupIds]'),
            deviceTypes: () => cy.get('[id = deviceTypes]'),
            from: () => cy.get('[id = from]'),
            to: () => cy.get('[id = to]'),
            date: () => cy.get('[id = date]'),
            // not enabled in all countries, PR env does not like it for some reason..
            //rentalObjectGroups: () => cy.get('#rentalObjectGroups'),
            servicesStatus: () => cy.get('[id = servicesStatus]'),
            servicesTypes: () => cy.get('[id = servicesTypes]'),
        }
    };

    public routes = {
        listUnServicedDevices: {
            path: "/api/device/listUnServicedDevices",
            method: "POST",
        } as RouteData,
        listDevices: {
            path: "/api/ReturnInspection/ListDevices",
            method: "POST",
        } as RouteData,
    }

    public filterByDeviceId(deviceId: string) {
        cy.wait(100);
        this.elements.inputs.deviceExternalId().should("be.visible")
            .focus()
            .clear()
            .type(deviceId)
    }

    public clearAndOpenServiceFilters() {
        cy.wait(100);
        this.elements.filterButton().realClick();
        cy.wait(100);
        this.elements.clearButton().realClick();
        executeWithIntercept(() => this.applyServiceListFilters(), [ServicedDevicesPage.routes.getUnServicedDevicesCount]);
        cy.wait(100);
        this.elements.filterButton().realClick();
    }

    public apply() {
        executeWithIntercept(() => {
            this.elements.applyButton().should('be.visible').realClick();
            cy.wait(50);
        }, [this.routes.listDevices])
    }
    public applyServiceListFilters() {
        executeWithIntercept(() => {
            this.elements.applyButton().should('be.visible').realClick();
            cy.wait(50);
        }, [this.routes.listUnServicedDevices])
    }
}

export default new DeviceFiltersModal();