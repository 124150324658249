import {IFaultCode} from "@/models/server/FaultCode";
import {CodeSeverity} from "@/models/Enums";
export default class ErrorCodeModel{
    private readonly _serviceCritical : number = 50;
    
    public SPN : string;
    public FMI : string;
    public severity : CodeSeverity;
    public codeSource : string;
    public occurred: number;
    public latest : Date;
    public timestamps : Date[];
    public description: string;
    public errorCode : string;
    public errorTitle: string;
    public isRecurring : boolean;
    public fromRentalPeriod : boolean;
    public isActive : boolean;
    constructor(faultCodes : IFaultCode[]) {
        const faultCode = faultCodes[0];
        this.SPN = "";
        this.FMI = "";
        this.severity = CodeSeverity.Low;
        this.codeSource = faultCode.codeSource;
        this.occurred = faultCodes.length;
        this.description =  faultCode.codeDescription;
        this.errorCode = faultCode.codeIdentifier;
        this.latest = faultCode.dateTime;
        
        this.fromRentalPeriod = faultCodes.some(p=>p.fromRentingPeriod);
        
        const codeIdentifiers = faultCode.codeIdentifier.split("-");
        
        if(codeIdentifiers.length > 0){
            this.SPN = codeIdentifiers[0];
            this.FMI = codeIdentifiers[1];
        }
        
        const codeSeverity = Number.parseInt(faultCode.codeSeverity);
        
        if(codeSeverity > this._serviceCritical){
            this.severity = CodeSeverity.Critical;
        }
        
        this.timestamps = faultCodes.map(p=>p.dateTime);
        
        this.errorTitle = this.createTitle();
        
        this.isRecurring = faultCodes.length > 1;
        
        this.isActive = faultCode.isActive;
    }
    
    private createTitle(): string {
        if(!this.description) {
            return "";
        }
        
        const signs = [';','-', '.'];

        for (const sign of signs) {
            const index = this.description.indexOf(sign);
            if (index !== -1) {
                return this.description.substring(0, index);
            }
        }
        
        return this.description;
    }
}