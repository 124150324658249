import DashboardPage from "@/tests/pages/DashboardPage";
import DevicePage from "@/tests/pages/DevicePage";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import DeviceServicePage from "@/tests/pages/DeviceServicePage";

describe('Device service page tests', () => {
    before(() => {
        CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    });

    it('Service & repair duration inputs are visible when needed', () => {

        DashboardPage.openDevice("Duration");

        DevicePage.startServiceWithoutOngoingReport();

        DeviceServicePage.elements.serviceDurationInput().should('not.exist');
        
        DeviceServicePage.elements.serviceTypeDropdown().click();
        
        DeviceServicePage.elements.serviceTypeDropdown().contains('100 hours service').click();

        DeviceServicePage.elements.serviceDurationInput().should('be.visible');

        DeviceServicePage.elements.repairDurationInput().should('not.exist');
        
        DeviceServicePage.elements.repairCheckBox().click();

        DeviceServicePage.elements.repairDurationInput().should('be.visible');
    });

})