import {AuthType, UserRole} from "../../Enums";

export default class SaveUserRequest {
    public id: string | null = null;

    public authType: AuthType = AuthType.Email;

    public email: string = "";

    public phone: string = "";

    public firstName: string = "";

    public middleName: string = "";

    public lastName: string = "";

    public language: string = "";

    public role: UserRole | null = null;

    public depoId: string | null = null;

    public depoIds: string[] = [];

    public canInvoiceFuelingAndWashing: boolean = false;

    public canInvoiceAdditionalExpenses: boolean = false;
    
    public canEditOwnAnnualInspections: boolean = false;
    
    public canEditDeviceOrder: boolean = false;
}