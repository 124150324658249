class DepoSelectionPage {
    private elements = {
        depos : (costPool : string) => cy.get(`[id=depo_${costPool}]`),
    }
    
    public depoShouldBeVisible(costPool : string) {
        this.elements.depos(costPool)
            .should('be.visible');
            
    }
}

export default new DepoSelectionPage();