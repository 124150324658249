import {BaseTransformProvider, TFormat, Utility} from "@renta-apps/athenaeum-toolkit";
import {ch, PageRoute} from "@renta-apps/athenaeum-react-common";
import {ITitleModel, SelectListGroup, SelectListItem, StatusListItem} from "@renta-apps/athenaeum-react-components";
import Category from "../pages/Models/Category";
import User from "../models/server/User";
import Depo from "@/models/server/Depo";
import ProductGroup from "@/models/server/ProductGroup";
import ServiceType from "@/pages/Models/ServiceType";
import ServiceAction from "@/pages/Models/ServiceAction";
import ServiceReportType from "@/pages/Models/ServiceReportType";
import AdBlue from "@/models/server/AdBlue";
import FuelType from "@/models/server/FuelType";
import WashingType from "@/models/server/WashingType";
import ServiceExpense from "@/models/server/ServiceExpense";
import DeviceContract from "@/models/server/DeviceContract";
import RentalObjectGroup from "@/models/server/RentalObjectGroup";
import AdditionalExpense from "@/models/server/AdditionalExpense";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

class TransformProvider extends BaseTransformProvider {
    public constructor() {
        super();
    }

    protected createSelectListItem(value: string, text: string, subtext: string, groupName?: string | null, favorite?: boolean | null): SelectListItem {
        const listItem = new SelectListItem(value, text, subtext);
        listItem.favorite = (favorite == true);
        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }
        return listItem;
    }

    public toTitle(item: any): ITitleModel {

        let label: string | null = null;
        let description: string | null = null;

        if (item != null) {
            label = Utility.findStringValueByAccessor(item, ["label", "name"]);
            description = Utility.findStringValueByAccessor(item, ["description", "text"]);
        }

        return {
            description: description || "",
            label: label || "",
            icon: null
        };
    }

    public pageRouteToString(pageRoute: PageRoute | null): string {
        return (pageRoute != null)
            ? (pageRoute.index != null)
                ? `${pageRoute.name} ${pageRoute.index}`
                : pageRoute.name
            : "";
    }

    public categoryToListItem(category: Category): SelectListItem {
        const item = new SelectListItem();
        item.ref = category;
        item.text = category.name || "";
        item.subtext = category.productGroupId || "";
        item.group = SelectListGroup.create("ERP");
        return item;
    }

    public userToString(item: User, useEmailIfNoName: boolean = false): string {
        const firstName: string = item.firstName != null ? item.firstName : "";
        const lastName: string = item.lastName != null ? item.lastName : "";

        if (firstName || lastName) {
            return `${firstName} ${lastName}`.trim();
        }

        return (useEmailIfNoName)
            ? item.email
            : item.username;
    }

    public depoToString(item: Depo): string {
        return `${item.name}, ${item.costPool}`.trim();
    }

    public toString(item: any, format: TFormat | null | undefined = null): string {
        if (item == null) {
            return "";
        }

        if ((item.constructor.name === User.name) || (item.isUser === true)) {
            return this.userToString(item as User);
        }

        if ((item.constructor.name === Depo.name) || item.isDepo) {
            return this.depoToString(item as Depo);
        }

        if ((item.constructor.name === PageRoute.name) || (item.isPageRoute)) {
            return this.pageRouteToString(item as PageRoute);
        }

        return super.toString(item, format);
    }

    public toSelectListItem(item: any): SelectListItem {

        if ((item.constructor.name === SelectListItem.name) || (item.isSelectListItem === true)) {
            return item as SelectListItem;
        }

        if ((item.constructor.name === Category.name) || (item.isCategory)) {
            return this.categoryToListItem(item as Category);
        }

        if ((item.constructor.name === Depo.name) || (item.isDepo)) {
            return this.toDepoListItem(item as Depo);
        }

        if ((item instanceof User) || (item.isUser)) {
            return this.toUserListItem(item as User);
        }

        if ((item instanceof User) || (item.isUser === true)) {
            return this.toUserStatusListItem(item as User);
        }

        if ((item instanceof ProductGroup) || (item.isProductGroup === true)) {
            return this.toProductGroupListItem(item as ProductGroup);
        }

        if ((item instanceof ServiceType) || (item.isServiceType === true)) {
            return this.toServiceTypeListItem(item as ServiceType);
        }

        if ((item instanceof ServiceAction) || (item.isServiceAction === true)) {
            return this.toServiceActionListItem(item as ServiceAction);
        }

        if ((item instanceof ServiceReportType) || (item.isServiceReportType === true)) {
            return this.toServiceReportTypeListItem(item as ServiceReportType);
        }

        if ((item instanceof FuelType) || (item.isFuelType === true)) {
            return this.toFuelTypeListItem(item as FuelType);
        }

        if ((item instanceof AdBlue) || (item.isAdBlue === true)) {
            return this.toAdBlueListItem(item as AdBlue);
        }

        if ((item instanceof AdditionalExpense) || (item.isAdditionalExpense === true)) {
            return this.toAdditionalExpenseListItem(item as AdditionalExpense);
        }

        if ((item instanceof DeviceContract) || (item.isDeviceContract === true)) {
            return this.toDeviceContractListItem(item as DeviceContract);
        }

        if ((item instanceof WashingType) || (item.isWashingType === true)) {
            return this.toWashingTypeListItem(item as WashingType);
        }

        if ((item instanceof ServiceExpense) || (item.isServiceExpense === true)) {
            return this.toServiceExpenseListItem(item as ServiceExpense);
        }

        if ((item instanceof RentalObjectGroup) || (item.isRentalObjectGroup === true)) {
            return this.toRentalObjectGroupListItem(item as RentalObjectGroup);
        }

        if ((item instanceof AnnualInspectionVendor) || (item.isAnnualInspectionVendor === true)) {
            return this.toAnnualInspectionVendorListItem(item as AnnualInspectionVendor);
        }

        if (typeof item === "number") {
            const listItem = new SelectListItem();
            listItem.value = item.toString();
            listItem.text = item.toString();
            listItem.ref = item;
            return listItem;
        }

        if (typeof item === "string") {
            const listItem = new SelectListItem();
            listItem.value = item;
            listItem.text = item;
            listItem.ref = item;
            return listItem;
        }

        const id: string | null = Utility.findStringValueByAccessor(item, ["id", "value", "code"]);
        const name: string | null = Utility.findStringValueByAccessor(item, ["name", "text", "label"]);
        const subtext: string | null = Utility.findStringValueByAccessor(item, "subtext");
        const favorite: boolean = (Utility.findStringValueByAccessor(item, "favorite") === "true");
        const groupName: string | null = Utility.findStringValueByAccessor(item, ["group", "group.name"]);
        const deleted: any | null = Utility.findValueByAccessor(item, ["delete", "isDeleted"]);

        const listItem = (deleted == true)
            ? new StatusListItem(true, true)
            : new SelectListItem();

        listItem.value = (id)
            ? id
            : (name)
                ? name
                : ch.getId().toString();

        if (name) {
            listItem.text = name;
        }

        if (subtext) {
            listItem.subtext = subtext;
        }

        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }

        listItem.favorite = favorite;
        listItem.ref = item;

        return listItem;
    }

    public toRentalObjectGroupListItem(item: RentalObjectGroup): SelectListItem {
        const selectedItem = new StatusListItem();

        selectedItem.ref = item;
        selectedItem.value = item.groupCode;
        selectedItem.text = item.groupCode;

        return selectedItem;
    }

    public toAnnualInspectionVendorListItems(items: AnnualInspectionVendor[]): SelectListItem[] {
        let selectedItems: SelectListItem[] = [];

        items.forEach(item => {
            selectedItems.push(this.toAnnualInspectionVendorListItem(item));
        });

        return selectedItems;
    }

    public toAnnualInspectionVendorListItem(item: AnnualInspectionVendor): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name ?? "-";
        selectedItem.selected = false;
        return selectedItem;
    }

    public toUserListItem(item: User): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.toString(item);
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = !item.isLocked;

        return selectedItem;
    }

    public toUserStatusListItem(item: User, costPoolAsSubText: boolean = false): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.toString(item);
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = !item.isLocked;

        if ((costPoolAsSubText) && (item.depo)) {
            selectedItem.subtext = item.depo.costPool + `\t${EnumProvider.getUserRoleText(item.role)}` || "";
        }

        return selectedItem;
    }

    public toDepoListItem(item: Depo, costPoolAsValue: boolean = false): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = (costPoolAsValue) ? item.costPool! : item.id;
        selectedItem.text = this.toString(item);
        return selectedItem;
    }

    public toProductGroupListItem(item: ProductGroup): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.productGroupId;
        selectedItem.text = `${item.productGroupName} (${item.productGroupId})`;
        return selectedItem;
    }

    public toServiceTypeListItem(item: ServiceType): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        selectedItem.subtext = item.description || "";
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = true;
        return selectedItem;
    }

    public toServiceActionListItem(item: ServiceAction): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        selectedItem.subtext = item.description || "";
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = true;
        return selectedItem;
    }

    public toServiceReportTypeListItem(item: ServiceReportType): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        if (item.noActions) {
            selectedItem.subtext = Localizer.serviceReportTypeNoActionsLanguageItemName;
        }
        if (item.generic) {
            selectedItem.group = SelectListGroup.create(Localizer.serviceReportTypeGenericLanguageItemName);
        }
        return selectedItem;
    }

    public toWashingTypeListItem(item: WashingType): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = `${item.name} (${item.productExternalId})`;
        selectedItem.subtext = item.equipmentType;
        return selectedItem;
    }

    public toFuelTypeListItem(item: FuelType): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = `${item.name} (${item.productExternalId})`;
        return selectedItem;
    }

    public toDeviceContractListItem(item: DeviceContract): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.contractExternalId!;
        selectedItem.text = `${item.contractExternalId} ${item.customerName}`;
        selectedItem.subtext = `${item.constructionSiteName ?? Localizer.invoiceDetailsPageNoConstructionSiteErpName} ${item.constructionSiteExternalId ?? Localizer.invoiceDetailsPageNoConstructionSiteErpNumber}`;
        return selectedItem;
    }

    public toAdBlueListItem(item: AdBlue): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = `${item.name} (${item.productExternalId})`;
        return selectedItem;
    }

    public toAdditionalExpenseListItem(item: AdditionalExpense): SelectListItem {
        const itemText: string = (item.productExternalId.trim().length > 0)
            ? `${item.name} (${item.productExternalId})`
            : item.name;

        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = itemText;

        if (item.isGeneric) {
            selectedItem.group = SelectListGroup.create(Localizer.additionalExpenseTypeGeneric);
        }

        return selectedItem;
    }

    public toServiceExpenseListItem(item: ServiceExpense): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        //selectedItem.text = `${item.name} (${item.productExternalId})`;
        return selectedItem;
    }
}

export default new TransformProvider();