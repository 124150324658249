import AuthorizedPage from "@/models/base/AuthorizedPage";
import React from "react";
import {Dropdown, PageContainer, PageHeader, SelectListItem,} from "@renta-apps/athenaeum-react-components";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import Localizer from "@/localization/Localizer";
import ErrorCodesList from "@/components/ErrorCodesList/ErrorCodesList";
import RentaToolsController from "@/pages/RentaToolsController";
import {GetDeviceCodeFaultsRequest} from "@/models/server/requests/GetDeviceCodeFaultsRequest";
import FaultsCodesService from "@/services/FaultsCodesService";
import ErrorCodeModel from "@/models/ErrorCodeModel";
import EnumProvider from "@/providers/EnumProvider";
import {ErrorCodesFilters} from "@/models/Enums";
import styles from './ErrorCodesPage.module.scss';
import ErrorCodesTooltip from "@/components/ErrorCodesTooltip/ErrorCodesTooltip";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import DeviceShortInfo from "@/components/DeviceShortInfo/DeviceShortInfo";

export interface IErrorCodesPageProps{
    
}

export interface IErrorCodesPageState extends IReturnInspectionWizardPageState{
    errorCodes : ErrorCodeModel[];
    isLoading : boolean;
}

export default class ErrorCodesPage extends AuthorizedPage<IErrorCodesPageProps, IErrorCodesPageState> {
    
    state : IErrorCodesPageState = {
        errorCodes : [],
        isLoading: false,
    };
    
    private async requestErrorCodes(period : ErrorCodesFilters) : Promise<void>{
        await this.setState({
            isLoading: true
        })

        const request = new GetDeviceCodeFaultsRequest();
        request.deviceExternalId = RentaToolsController.device?.externalId;
        request.period = period;

        const faults = await RentaToolsController.getDeviceFaultCode(request);

        if(faults.faultCodes?.length && faults.faultCodes.length > 0){
            const faultsModel = FaultsCodesService.createModelFromFaultCode(faults.faultCodes);
            this.setState({
                errorCodes : faultsModel
            });
        }else {
            this.setState({
                errorCodes : []
            });
        }

        await this.setState({
            isLoading: false
        })
    }
    async initializeAsync(): Promise<void> {
        await this.requestErrorCodes(ErrorCodesFilters.RentalPeriod);
        
        return super.initializeAsync();
    }
    
    private async onItemChange(item : SelectListItem | null) : Promise<void> {
        if(item){
            
            const selectedItem = Number.parseInt(item.value);
            const period: ErrorCodesFilters = selectedItem as ErrorCodesFilters;
            await this.requestErrorCodes(period);
        }
    }


    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.errorCodePage)} scale>
                <DeviceShortInfo device={RentaToolsController.device}/>
                
                <div className={styles.tooltip}>
                   <ErrorCodesTooltip/>
                </div>
                <PageHeader title={Localizer.errorCodesErrorCodes}/>
                <div className={rentaToolsStyles.filterWrapper}>
                    <Dropdown 
                        disabled={this.state.isLoading}
                        items={EnumProvider.getErrorCodesFiltersItems()} 
                        selectedItem={ErrorCodesFilters.RentalPeriod} 
                        onChange={async (sender, item) => await this.onItemChange(item) }/>
                </div>
                <ErrorCodesList 
                    errorCodes={this.state.errorCodes}
                    isLoading={this.state.isLoading}
                />
            </PageContainer>
        );
    }
}
