import {ReturnInspectionStatus} from "@/models/Enums";
import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";

export interface IReturnedDeviceListElement {
    externalId: string;
}

class ReturnedDeviceList {
  public elements = {
    depoIds: () => cy.get(`[id = depo_costpool]`),
    device: (externalId: string) =>
        cy.get(`[id=device_list_element_${externalId}]`, {timeout: 10000}),
    filters: () => cy.get('[id=device_list_filter]'),
    deviceStatusBar: (externalId: string) => cy.get(`[data-cy=device_status_bar_${externalId}]`),
    expandableMenu: () => cy.get('[id=expandableMenuButton]'),
    editOrder: () => cy.get('[id=device_edit_order]'),
    devices: () => cy.get('[data-cy=device_list_element]'),
  };

  public routes = {
    findDevice: {
      path: "/api/device/findDevice",
      method: "POST",
    } as RouteData,
  }

  public openEditOrder(): void {
    cy.intercept('POST', '/api/depo/getUserDepos').as('depos');

    this.elements.expandableMenu()
        .click();

    cy.wait(100);

    this.elements.editOrder()
        .should('be.visible')
        .click();

    cy.wait('@depos');
  }

  public validateDepoId(depoId: string): void {
    this.elements.depoIds().each(($element) => {
      cy.wrap($element).should("contain", depoId);
    });
  }

  public validateDeviceStatus(externalId: string, status: string): void {
    this.elements
        .device(externalId)
        .contains(status);
  }

  public shouldContainText(externalId: string, status: string): void {
    this.elements
        .device(externalId)
        .contains(status);
  }

  public openDevice(externalId: string): void {
    executeWithIntercept(() => this.elements
            .device(externalId)
            .contains(externalId)
            .click(),
        [this.routes.findDevice]);
  }

  public openFilters() {
    this.elements.filters()
        .should('be.visible')
        .click();
  }

  public validateDeviceStateColor(externalId: string, status: ReturnInspectionStatus): void {
    this.elements.deviceStatusBar(externalId).should("satisfy", ($el) => {
      const classList = Array.from($el[0].classList).join("");
      let classToSearch = "";

      switch (status) {
        case ReturnInspectionStatus.Waiting: {
          classToSearch = "waitingForReturnInspection"
        }
          break;
        case ReturnInspectionStatus.InProgress: {
          classToSearch = "inProgress"
        }
          break;
        case ReturnInspectionStatus.PartlyCompleted: {
          classToSearch = "partlyCompleted"
        }
          break;
      }

      return classList.includes(classToSearch);
    });
  }

  public validateList(items: IReturnedDeviceListElement[]): void {
    this.elements.devices().should("have.length", items.length, { timeout: 5000 })

    items.forEach((item, index) => {
      this.elements.devices().eq(index).should("contain", item.externalId);
    });
  }
}

export default new ReturnedDeviceList();
