import React from "react";
import {
    ButtonType,
    Checkbox,
    DateInput,
    Dropdown,
    DropdownOrderBy,
    FileInput,
    Form,
    InlineType,
    NumberInput,
    NumberInputBehaviour,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListGroup,
    SelectListItem,
    TextAreaInput,
    TextInput
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {AnnualInspectionStatus, AnnualInspectionType} from "@/models/Enums";
import Device from "../Models/Device";
import PageDefinitions from "@/providers/PageDefinitions";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import SaveDeviceAnnualInspectionRequest from "@/models/server/requests/SaveDeviceAnnualInspectionRequest";
import SaveRemarksRepairedRequest from "@/models/server/requests/SaveRemarksRepairedRequest";
import Depo from "@/models/server/Depo";
import AnnualInspectionTypeModel from "@/models/server/AnnualInspectionTypeModel";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ToolsUtility from "@/helpers/ToolsUtility";
import DeviceInfo from "@/models/server/DeviceInfo";
import {CalculateFutureOperatingHoursService} from "@/services/CalculateFutureOperatingHoursService";
import OperatingHoursLimitRequest from "@/models/server/requests/OperatingHoursLimitRequest";
import ListAnnualInspectionVendorsRequest from "@/models/server/requests/ListAnnualInspectionVendorsRequest";
import GetAnnualInspectionTypeItemsResponse from "@/models/server/responses/GetAnnualInspectionTypeItemsResponse";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import RentaToolsController from "../RentaToolsController";
import AnnualInspectionController from "@/pages/AnnualInspectionController";
import fileHelper from "@/helpers/FileHelper";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./DeviceAnnualInspectionFormPage.module.scss";
import SaveDeviceAnnualInspectionResponse from "@/models/server/responses/SaveDeviceAnnualInspectionResponse";


export interface IDeviceAnnualInspectionFormParams {
    status: AnnualInspectionStatus,
    inspectionId?: string,
    isDeviceOnBanOfUse: boolean,
}

interface IDeviceAnnualInspectionFormPageProps {
    status: AnnualInspectionStatus,
    inspectionId?: string,
    isDeviceOnBanOfUse: boolean
}

interface IDeviceAnnualInspectionFormPageState {
    vendors: AnnualInspectionVendor[],
    depos: Depo[],
    inspectionTypes: AnnualInspectionTypeModel[],
    initialOperatingHours: number | null,
    minOperatingHours: number | null,
    maxOperatingHours: number | null,
    calculatedMaxValue: number | null,
    canSubmit: boolean,
    remarksText: string | null,
    invoiceNumber: string | null,
    cost: number,
    repairBeforeNextLease: boolean,
    selectedDepo: Depo | undefined,
    isUploadedFileInvalid: boolean
}

export default class DeviceAnnualInspectionFormPage extends AuthorizedPage<IDeviceAnnualInspectionFormPageProps, IDeviceAnnualInspectionFormPageState> {
    state: IDeviceAnnualInspectionFormPageState = {
        vendors: [],
        depos: [],
        inspectionTypes: [],
        initialOperatingHours: null,
        minOperatingHours: null,
        maxOperatingHours: null,
        calculatedMaxValue: null,
        canSubmit: false,
        remarksText: null,
        invoiceNumber: null,
        cost: 0,
        repairBeforeNextLease: false,
        selectedDepo: undefined,
        isUploadedFileInvalid: false
    };

    private readonly _remarksDateInputRef: React.RefObject<DateInput> = React.createRef();
    private readonly _inspectionDateInputRef: React.RefObject<DateInput> = React.createRef();
    private readonly _inspectionCostInputRef: React.RefObject<NumberInput> = React.createRef();
    private readonly _operatingHoursInputRef: React.RefObject<NumberInput> = React.createRef();
    private readonly _fileInputRef: React.RefObject<FileInput> = React.createRef();
    private readonly _inspectionInvoiceNumberInputRef: React.RefObject<TextInput> = React.createRef();
    private readonly _inspectionTypeInputRef: React.RefObject<Dropdown<AnnualInspectionTypeModel>> = React.createRef();
    private readonly _inspectionRemarksInputRef: React.RefObject<TextAreaInput> = React.createRef();
    private readonly _inspectionVendorIdInputRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _inspectionCheckboxInputRef: React.RefObject<Checkbox> = React.createRef();

    private get device(): Device | null {
        return RentaToolsController.device;
    }

    private get hasRemarks(): boolean {
        return (this.status == AnnualInspectionStatus.PassedWithRemarks);
    }

    private get remarksRepaired(): boolean {
        return (this.status == AnnualInspectionStatus.RemarksRepaired);
    }

    private get status(): AnnualInspectionStatus {
        return this.typedParameters?.status ?? AnnualInspectionStatus.Passed;
    }

    private get inspectionId(): string | null {
        return this.typedParameters?.inspectionId ?? null;
    }

    private get isUploadedFileInvalid(): boolean {
        return this.state.isUploadedFileInvalid;
    }

    private get inspectionDate(): Date {
        return this._inspectionDateInputRef.current?.value ?? new Date();
    }

    private get remarksRepairDueDate(): Date {
        return this._remarksDateInputRef.current?.value ?? new Date();
    }

    private get inspectionType(): string {
        return this._inspectionTypeInputRef.current?.value as string;
    }

    private get vendorId(): string {
        return this._inspectionVendorIdInputRef.current?.value as string;
    }

    private get inspectionCosts(): number {
        return this._inspectionCostInputRef.current?.value as number;
    }

    private get operatingHours(): number | null {
        return this._operatingHoursInputRef.current?.value ?? null;
    }

    private get inspectionCost(): number {
        return this.state.cost;
    }

    private get inspectionInvoiceNumber(): string | null {
        return this.state.invoiceNumber;
    }

    private get repairBeforeNextLease(): boolean {
        return this.state.repairBeforeNextLease;
    }

    private get selectedDepo(): Depo | undefined {
        return this.state.selectedDepo;
    }

    private get file(): FileModel {
        return this._fileInputRef.current?.value as FileModel;
    }

    private get invoiceNumber(): string {
        return this._inspectionInvoiceNumberInputRef.current?.value as string;
    }

    private get remarks(): string {
        return this._inspectionRemarksInputRef.current?.value as string;
    }

    private get deviceOnBan(): boolean {
        return this._inspectionCheckboxInputRef.current?.value as boolean;
    }

    private get subtitle(): string {
        return (this.remarksRepaired)
            ? Localizer.deviceAnnualInspectionPageRemarksRepaired
            : (this.hasRemarks)
                ? Localizer.deviceAnnualInspectionPageButtonAcceptWithRemarks
                : Localizer.deviceAnnualInspectionPageButtonAccept;
    }

    private get lastInspectionNotLongAgo(): boolean {
        const oneMonthAgo: Date = Utility.utcNow().date().addMonths(-1);

        return this.device?.lastAnnualReportDate ? this.device.lastAnnualReportDate >= oneMonthAgo : false;
    }

    private get canSubmit(): boolean {
        if (!this.isValid) {
            return false;
        }

        const selectionValid: boolean = !!this.inspectionType && !!this.vendorId && !!this.selectedDepo;
        if (!selectionValid) {
            return false;
        }

        if (this.isUploadedFileInvalid) {
            return false;
        }

        if (this.typedParameters?.status === AnnualInspectionStatus.Passed) {
            return true;
        }

        const textValue: string | undefined = this._inspectionRemarksInputRef?.current?.value;

        return !!textValue && textValue.trim().length > 0;
    }

    private async setSelectedDepoAsync(depo: Depo): Promise<void> {
        this.setState({selectedDepo: depo});
    }

    private async setRemarksTextAsync(value: string): Promise<void> {
        this.setState({remarksText: value});
    }

    private async setInvoiceNumber(value: string): Promise<void> {
        this.setState({invoiceNumber: value});
    }

    private async setCost(value: number): Promise<void> {
        this.setState({cost: value});
    }

    private async setRepairBeforeNextLease(value: boolean): Promise<void> {
        this.setState({repairBeforeNextLease: value});
    }

    private async handleSubmitAsync(): Promise<void> {
        if (this.lastInspectionNotLongAgo) {
            const confirmed: boolean = await ch.confirmAsync(Localizer.deviceAnnualInspectionFormPageInspectionDoneRecently);

            if (!confirmed) {
                return;
            }
        }

        let response: SaveDeviceAnnualInspectionResponse;
        if (this.remarksRepaired) {
            const request: SaveRemarksRepairedRequest = {
                remarksCompletedDate: this.inspectionDate,
                inspectionId: this.inspectionId,
                file: this.file,
                remarksCompletedById: this.getUser().id,
                status: AnnualInspectionStatus.RemarksRepaired
            }

            response = await AnnualInspectionController.saveRemarksRepairedAsync(request);

        } else {
            const inspectionType = this.inspectionType as keyof typeof AnnualInspectionType;
            const request: SaveDeviceAnnualInspectionRequest = {
                inspectionDate: this.inspectionDate,
                vendorId: this.vendorId,
                inspectionType: AnnualInspectionType[inspectionType],
                file: this.file,
                inspectionInvoiceNumber: this.invoiceNumber,
                inspectionCost: this.inspectionCosts,
                operatingHours: this.operatingHours,
                status: (this.hasRemarks && this.deviceOnBan) ? AnnualInspectionStatus.PassedWithRemarksAndDeviceOnBanOfUse : this.status,
                deviceId: this.device?.id ?? null,
                remarks: (this.hasRemarks) ? this.remarks : null,
                remarksDueDate: (this.hasRemarks) ? this.remarksRepairDueDate : null,
                depoId: (this.selectedDepo) ? this.selectedDepo.id : null,
                fixBeforeLease: this.deviceOnBan,
            }

            response = await AnnualInspectionController.saveInspectionAsync(request);

            await RentaToolsController.loadDeviceAsync(request.deviceId!);
        }

        if (!response.success)
        {
            const alertText = AnnualInspectionController.getErrorAlert(response);

            if (alertText) {
                await ch.alertErrorAsync(alertText);
            }
        }

        //Hack :( save inspection and save inspection with remarks will do redirect.
        // Call back here to remove form page from history.
        PageRouteProvider.back();
    }

    private renderTitle(): React.ReactNode {
        const deviceProductGroupName = this.device?.productGroupName;
        const deviceType = this.device?.type;
        const deviceExternalId = this.device?.externalId;
        const deviceGroupId = this.device?.productGroupId;

        return (
            <React.Fragment>

                <div>
                    <div id={"multiTitleDiv"} className={styles.multiTitle}>
                        <span id={"deviceProductGroup"}
                              className={styles.deviceProductGroup}>{deviceProductGroupName} {deviceGroupId}</span>
                        <span id={"deviceType"} className={styles.deviceType}>{deviceType}</span>
                        <span id={"device_externalId"}>{deviceExternalId}</span>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    private renderCustomDateInput(remarksDate?: boolean): React.ReactNode {

        const date: Date = remarksDate
            ? this.remarksRepairDueDate
            : this.inspectionDate;

        const label: string = ToolsUtility.toDateStringWithTime(date);

        return (
            <ArsenalButton block
                           className={this.css(styles.label)}
                           type={ButtonType.Default}
                           label={label}
                           icon={{name: "fal calendar-alt"}}
            />
        );
    }

    private async validateFileSizeAsync(file: FileModel): Promise<void> {
        const isUploadedFileInvalid: boolean = !fileHelper.isSupportedPdfFileSize(file);

        if (file && isUploadedFileInvalid) {
            await ch.flyoutErrorAsync(Localizer.genericFlyoutErrorMessageFileIsTooLarge);
        }

        await this.setState({isUploadedFileInvalid});
    }

    private get maxOperatingHours(): number | null {
        return this.state.maxOperatingHours ?? this.state.calculatedMaxValue ?? null;
    }

    private async getOperatingHoursLimitsAsync(newDate?: Date): Promise<any> {
        const request = new OperatingHoursLimitRequest();
        request.deviceId = this.device!.id;
        request.reportDate = newDate ?? new Date();
        const limits = await RentaToolsController.getOperatingHoursLimitsAsync(request);
        const minOperatingHours = limits.find(val => val.recordedDate <= request.reportDate)?.recordedOperatingHours ?? null;
        const maxOperatingHours = limits.find(val => val.recordedDate > request.reportDate)?.recordedOperatingHours ?? null;

        this.setState({
            minOperatingHours,
            maxOperatingHours
        });
    }

    private get vendorItems(): SelectListItem[] {
        const vendors = this.state.vendors;
        if (!vendors) {
            return [];
        }

        let items: SelectListItem[] = [];

        vendors.forEach(vendor => {
            const item: SelectListItem = new SelectListItem();

            item.value = vendor.id;
            item.text = vendor.name ?? "-";

            if (vendor.parentId) {
                const parent = vendors.firstOrDefault(o => o.id == vendor.parentId);
                if (parent) {
                    item.group = SelectListGroup.create(parent.name!);
                    item.group.order = 999;
                }
            }

            items.push(item);
        });

        return items;
    }

    private get isValid(): boolean {

        if (!this.device) {
            return false;
        }

        if (!this.device.supportsOperatingHours) {
            return true;
        } else if (this.operatingHours == null) {
            return false;
        } else {
            let valid = true;
            if (this.state.minOperatingHours != null && this.operatingHours < this.state.minOperatingHours) {
                valid = false;
            }
            if (this.maxOperatingHours != null && this.operatingHours > this.maxOperatingHours) {
                valid = false;
            }

            return valid;
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.device) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true);
        }

        const operatingHours: number | null = DeviceInfo.getTotalOperatingHours(RentaToolsController.device!);
        const calculatedMaxValue: number | undefined = CalculateFutureOperatingHoursService.calculateMaxOperatingHoursFromLastService(operatingHours, RentaToolsController.device!);

        const listVendorsRequest = {
            includeDeleted: false,
            includeChildren: true,
        } as ListAnnualInspectionVendorsRequest;

        const vendors: AnnualInspectionVendor[] = await AnnualInspectionController.listVendorsAsync(listVendorsRequest);

        const depos: Depo[] = await RentaToolsController.getUserDeposAsync();
        const response: GetAnnualInspectionTypeItemsResponse = await RentaToolsController.getAnnualInspectionTypeItemsByProductGroup(this.device!.productGroupId);

        await this._operatingHoursInputRef.current?.setAsync(operatingHours || 0);
        // Remove deployment inspection from available types if a deployment inspection has already been completed
        let inspectionTypes: AnnualInspectionTypeModel[] = response.annualInspectionTypes;
        if (!this.isAdmin || this.device!.isDeploymentInspectionDone) {
            let deploymentInspection = inspectionTypes.firstOrDefault(x => x.id === AnnualInspectionType.DeploymentInspection.toString());
            if (deploymentInspection) {
                inspectionTypes.remove(deploymentInspection);
            }
        }

        await this.getOperatingHoursLimitsAsync();

        this.setState({
            vendors,
            depos,
            inspectionTypes: inspectionTypes,
            initialOperatingHours: operatingHours,
            calculatedMaxValue: calculatedMaxValue ?? null
        });
    }

    public render(): React.ReactNode {
        const failedDeviceStyle: any = this.device?.activeFaults != null && this.device.activeFaults.length > 0 && styles.failedDevice;
        const extraInfoDeviceStyle: any = styles.extraInfoDevice;

        return (
            <PageContainer className={this.css(rentaToolsStyles.pageContainer, styles.device)}>

                <PageHeader subtitle={this.subtitle}
                            title={() => this.renderTitle()}
                            className={this.css(rentaToolsStyles.leftPageHeader, failedDeviceStyle, extraInfoDeviceStyle)}

                />

                <PageRow>

                    <div className="col">
                        {
                            (this.device && !this.remarksRepaired) &&
                            (
                                <Form id="annualInspectionForm" onSubmit={() => this.handleSubmitAsync()}>

                                    <DateInput showTime
                                               id={"inspectionDate"}
                                               maxDate={Utility.today()}
                                               value={this.inspectionDate}
                                               ref={this._inspectionDateInputRef}
                                               label={this.remarksRepaired ? Localizer.deviceAnnualInspectionFormFixingDate : Localizer.deviceServicePageGeneralLabelDate}
                                               customInput={() => this.renderCustomDateInput()}
                                               onChange={async (val) => {
                                                   await this.getOperatingHoursLimitsAsync(val)
                                               }}
                                    />

                                    <Dropdown required
                                              id={"annualInspectionVendor"}
                                              ref={this._inspectionVendorIdInputRef}
                                              items={this.vendorItems}
                                              label={Localizer.reportPreviewInspector}
                                    />

                                    {
                                        (this.state.inspectionTypes?.length == 0) &&
                                        <InlineTooltip className={this.css("ml-1", styles.inspectionTypeInfo)}
                                                       text={Localizer.deviceAnnualInspectionFormInspectionTypeNotFound}
                                        />
                                    }

                                    <Dropdown required
                                              orderBy={DropdownOrderBy.Value}
                                              id={"inspectionType"}
                                              ref={this._inspectionTypeInputRef}
                                              items={this.state.inspectionTypes || []}
                                              label={Localizer.deviceAnnualInspectionHistoryPageInspectionType}
                                    />

                                    <Dropdown required
                                              id={"depot"}
                                              items={this.state.depos}
                                              label={Localizer.reportPreviewDepot}
                                              value={this.selectedDepo?.id}
                                              onChange={async (sender, item) => await this.setSelectedDepoAsync(item!)}
                                    />

                                    {
                                        (this.device?.supportsOperatingHours) &&
                                        (
                                            <NumberInput id={"operatingHours"}
                                                         behaviour={NumberInputBehaviour.ValidationOnSave}
                                                         ref={this._operatingHoursInputRef}
                                                         label={Localizer.deviceServicePageLabelOperatingHours}
                                                         step={0.1}
                                                         value={this.operatingHours != null ? Utility.round(this.operatingHours, 1) : null}
                                                         min={Utility.round(this.state.minOperatingHours ?? 0, 1)}
                                                         max={this.maxOperatingHours ?? undefined}
                                                         onBlur={async () => {
                                                             await this.reRenderAsync()
                                                         }}
                                            />
                                        )
                                    }

                                    <FileInput dropZone
                                               id={"pdf_input"}
                                               ref={this._fileInputRef}
                                               fileTypes={["application/pdf"]}
                                               maxSize={RentaToolsConstants.fileMaxSize}
                                               maxTotalSize={RentaToolsConstants.fileMaxSize}
                                               label={Localizer.deviceAnnualInspectionFormInspectionDocument}
                                               value={this.file}
                                               onChange={(_, value: FileModel) => this.validateFileSizeAsync(value)}
                                               onRemove={async () => await this.setState({isUploadedFileInvalid: false})}
                                    />

                                    <TextInput id={"invoiceNumber"}
                                               value={this.inspectionInvoiceNumber}
                                               ref={this._inspectionInvoiceNumberInputRef}
                                               label={Localizer.deviceServicePageInvoiceLabelInvoiceNumber}
                                               onChange={async (_, value) => await this.setInvoiceNumber(value)}
                                    />

                                    <NumberInput id={"inspectionCost"}
                                                 value={this.inspectionCost}
                                                 ref={this._inspectionCostInputRef}
                                                 label={Localizer.annualInspectionFormInspectionCosts}
                                                 step={0.1}
                                                 onChange={async (_, value) => await this.setCost(value)}
                                    />

                                    {
                                        (this.hasRemarks) &&
                                        (
                                            <>
                                                <DateInput showTime
                                                           id={"remarksRepairedDate"}
                                                           ref={this._remarksDateInputRef}
                                                           value={this.remarksRepairDueDate}
                                                           label={Localizer.deviceAnnualInspectionFormRemarksRepairedLatest}
                                                           customInput={() => this.renderCustomDateInput(true)}
                                                />

                                                <TextAreaInput required
                                                               id={"remarks"}
                                                               value={this.state.remarksText}
                                                               label={Localizer.deviceAnnualInspectionFormRemarks}
                                                               ref={this._inspectionRemarksInputRef}
                                                               onChange={async (input, value) => await this.setRemarksTextAsync(value)}
                                                />

                                                {
                                                    <FeatureSwitch flagName={RentaToolsConstants.featureFlagBanOfUseEnabled}>
                                                        <Checkbox inline
                                                                  id={"ban_of_use_checkbox"}
                                                                  value={this.repairBeforeNextLease}
                                                                  className={styles.checkbox}
                                                                  inlineType={InlineType.Right}
                                                                  label={Localizer.deviceAnnualInspectionFormDeviceUseBan}
                                                                  ref={this._inspectionCheckboxInputRef}
                                                                  onChange={async (input, value) => await this.setRepairBeforeNextLease(value)}
                                                        />
                                                    </FeatureSwitch>
                                                }

                                            </>
                                        )
                                    }

                                    <ArsenalButton block big submit
                                                   className={styles.saveButton}
                                                   disabled={!this.canSubmit}
                                                   type={ButtonType.Orange}
                                                   label={Localizer.genericSave}
                                    />
                                </Form>
                            )
                            ||
                            (this.device && this.remarksRepaired) &&
                            (
                                <Form id="remarkssRepairedForm" onSubmit={async () => await this.handleSubmitAsync()}>

                                    <DateInput showTime
                                               id={"inspectionDate"}
                                               maxDate={Utility.today()}
                                               value={this.inspectionDate}
                                               ref={this._inspectionDateInputRef}
                                               label={this.remarksRepaired ? Localizer.deviceAnnualInspectionFormFixingDate : Localizer.deviceServicePageGeneralLabelDate}
                                               customInput={() => this.renderCustomDateInput()}
                                    />

                                    {
                                        <FeatureSwitch flagName={RentaToolsConstants.featureFlagRemarksRepairedPdfEnabled}>
                                            <FileInput dropZone
                                                       id={"pdf_input"}
                                                       ref={this._fileInputRef}
                                                       fileTypes={["application/pdf"]}
                                                       maxSize={RentaToolsConstants.fileMaxSize}
                                                       maxTotalSize={RentaToolsConstants.fileMaxSize}
                                                       label={Localizer.deviceAnnualInspectionFormInspectionDocument}
                                                       value={this.file}
                                                       onChange={(_, value: FileModel) => this.validateFileSizeAsync(value)}
                                                       onRemove={async () => await this.setState({isUploadedFileInvalid: false})}
                                            />
                                        </FeatureSwitch>
                                    }

                                    <ArsenalButton block big submit
                                                   type={ButtonType.Orange}
                                                   label={Localizer.genericSave}
                                    />

                                </Form>
                            )

                        }

                        <ArsenalButton block big
                                       className={styles.backButton}
                                       id={"back_button"}
                                       type={ButtonType.Orange}
                                       label={Localizer.genericReturnCaps}
                                       icon={{name: "arrow-left"}}
                                       onClick={async () => PageRouteProvider.back()}
                        />

                    </div>

                </PageRow>

            </PageContainer>
        )
    }
}