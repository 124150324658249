import GridHelper from "@/cypressHelpers/GridHelper";
import Localizer from "../../../localization/Localizer";

describe('Application settings page tests', () => {
    beforeEach(() => {
        cy.login();
    });

    it('Opens application settings page and checks elements exist on page', () => {
        cy.visit("Dashboard");

        cy.goToApplicationSettingsPage();

        // Check for a certain value in a certain place to exist.
        GridHelper.getGridCell('#table_settings', Localizer.knownSettingsAnnualInspectionProductGroupIds, 1)
            .contains(Localizer.knownSettingsAnnualInspectionProductGroupIds);
        GridHelper.getGridCell('#table_settings', Localizer.knownSettingsProductGroupIds, 1)
            .contains(Localizer.knownSettingsProductGroupIds);
        GridHelper.getGridCell('#table_numberValueSettings', Localizer.knownSettingsManualInvoiceFuelStep, 1)
            .contains(Localizer.knownSettingsManualInvoiceFuelStep);

        cy.get('#dateFormatDdl').click();
        cy.get('#dateFormatDdl')
            .find('.athenaeum-dropdown-item')
            .should('have.length', 9);
    });
});