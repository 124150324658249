import ReturnedDeviceList from "@/tests/pages/ReturnedDeviceList";
import DeviceOrderPage from "@/tests/pages/DeviceOrderPage";

describe("Edit order page tests", () => {

    beforeEach(() => {
        cy.loginAsDepoManagerOfDepo3();
        cy.goToDashboard();
        cy.goToReturnedDevicesPage();
    });

    it("User should be able to edit order of devices", () => {
        DeviceOrderPage.interceptRequest();

        ReturnedDeviceList.openEditOrder();

        DeviceOrderPage.waitForRequest();

        DeviceOrderPage.validateDeviceOrder();

        DeviceOrderPage.moveDeviceDown("DEPO3-4");

        DeviceOrderPage.save((deviceIds) => {
            const validateListProp = deviceIds.map((externalId) => ({externalId}));

            ReturnedDeviceList.validateList(validateListProp);

        });
    });

    it("User should be able to change order to fixed", () => {
        DeviceOrderPage.interceptRequest();

        ReturnedDeviceList.openEditOrder();

        DeviceOrderPage.waitForRequest();

        DeviceOrderPage.validateDeviceOrder();

        DeviceOrderPage.validateDeviceOrderLength(4);

        DeviceOrderPage.clickSwitch();

        DeviceOrderPage.validateDeviceOrderLength(5);

        DeviceOrderPage.clickSwitch();

        DeviceOrderPage.validateDeviceOrderLength(4);
    });
});