//Autogenerated

import {BaseEnumProvider, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {WebApplicationType, PasswordValidationError} from "@renta-apps/athenaeum-react-common";
import {SelectListItem} from "@renta-apps/athenaeum-react-components";
import {ReportDefinitionType, DeviceCounterType, DeviceAlertType, MaintenanceReason, FaultLevel, ReportItemType, ResourceItemType, LoginResultStatus, UserRole, MeasuringOperators, InvitationType, AuditTimestamp, AuthType, AnnualInspectionStatus, AnnualInspectionType, DocumentType, DeviceStatus, DeviceBanOfUse, SavePasswordResultStatus, InvoiceStatusFilter, ServiceLocationType, DevicePicturesFilter, DevicePictureType, AiJobType, AiDataType, AiMetadataType, AiJobStatus, ScheduleAction, AnnualInspectionDevicesType, AnnualInspectionStatusFilterType, ReturnInspectionStatus, ReturnInspectionDetailsTypes, ImageValidationResult, SearchItemType, ReturnInspectionResponseType, AnnualInspectionResponseType, SaveServiceResponseType, ErrorCodesFilters, ServiceAndRepairsStatuses, ServiceTypeFilter, RecordOperatingHoursSource, InvoicesTypes, LambdaLogicalOperation, FailedItemType, DeviceListOrderType, ExpenseUnit, OnletMaintenanceType, OnletMaintenanceStatus, PredictionTime, DeviceListOrder, PredictionDatesType, PredictionMaintenanceReason, ScheduledTaskStatus, NotificationStatus, NotificationType, NotificationMediaType, NotificationInterval, ObservationsFilter, ServiceDefinitionType, DayOfWeek} from "@/models/Enums";

class EnumProvider extends BaseEnumProvider<SelectListItem> {

    // #region Private
    
    private readonly _types: string[] = ["WebApplicationType", "ReportDefinitionType", "DeviceCounterType", "DeviceAlertType", "MaintenanceReason", "FaultLevel", "ReportItemType", "ResourceItemType", "LoginResultStatus", "UserRole", "MeasuringOperators", "InvitationType", "AuditTimestamp", "AuthType", "AnnualInspectionStatus", "AnnualInspectionType", "DocumentType", "DeviceStatus", "DeviceBanOfUse", "SavePasswordResultStatus", "SortDirection", "InvoiceStatusFilter", "ServiceLocationType", "DevicePicturesFilter", "DevicePictureType", "AiJobType", "AiDataType", "AiMetadataType", "AiJobStatus", "ScheduleAction", "AnnualInspectionDevicesType", "AnnualInspectionStatusFilterType", "ReturnInspectionStatus", "ReturnInspectionDetailsTypes", "ImageValidationResult", "SearchItemType", "ReturnInspectionResponseType", "AnnualInspectionResponseType", "SaveServiceResponseType", "ErrorCodesFilters", "ServiceAndRepairsStatuses", "ServiceTypeFilter", "RecordOperatingHoursSource", "InvoicesTypes", "LambdaLogicalOperation", "FailedItemType", "DeviceListOrderType", "ExpenseUnit", "OnletMaintenanceType", "OnletMaintenanceStatus", "PredictionTime", "DeviceListOrder", "PredictionDatesType", "PredictionMaintenanceReason", "ScheduledTaskStatus", "NotificationStatus", "NotificationType", "NotificationMediaType", "NotificationInterval", "ObservationsFilter", "ServiceDefinitionType", "DayOfWeek"];

    protected get types(): readonly string[] {
        return this._types;
    }

    protected createSelectListItem(value: string, text: string, subtext: string): SelectListItem {
        return new SelectListItem(value, text, subtext);
    }
    
    // #endregion
    
    constructor() {
        super();
    }

    // #region WebApplicationType

    public getWebApplicationTypeItem(value: WebApplicationType): SelectListItem {
        return this.transform(WebApplicationType, "WebApplicationType", value);
    }

    public getWebApplicationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WebApplicationType, "WebApplicationType", reverse);
    }

    public getWebApplicationTypeName(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).text;
    }

    public getWebApplicationTypeText(value: WebApplicationType): string {
        return this.localizer.get(this.getWebApplicationTypeName(value));
    }

    public getWebApplicationTypeDescription(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).subtext;
    }

    // #endregion

    // #region ReportDefinitionType

    public getReportDefinitionTypeItem(value: ReportDefinitionType): SelectListItem {
        return this.transform(ReportDefinitionType, "ReportDefinitionType", value);
    }

    public getReportDefinitionTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ReportDefinitionType, "ReportDefinitionType", reverse);
    }

    public getReportDefinitionTypeName(value: ReportDefinitionType): string {
        return this.getReportDefinitionTypeItem(value).text;
    }

    public getReportDefinitionTypeText(value: ReportDefinitionType): string {
        return this.localizer.get(this.getReportDefinitionTypeName(value));
    }

    public getReportDefinitionTypeDescription(value: ReportDefinitionType): string {
        return this.getReportDefinitionTypeItem(value).subtext;
    }

    // #endregion

    // #region DeviceCounterType

    public getDeviceCounterTypeItem(value: DeviceCounterType): SelectListItem {
        return this.transform(DeviceCounterType, "DeviceCounterType", value);
    }

    public getDeviceCounterTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DeviceCounterType, "DeviceCounterType", reverse);
    }

    public getDeviceCounterTypeName(value: DeviceCounterType): string {
        return this.getDeviceCounterTypeItem(value).text;
    }

    public getDeviceCounterTypeText(value: DeviceCounterType): string {
        return this.localizer.get(this.getDeviceCounterTypeName(value));
    }

    public getDeviceCounterTypeDescription(value: DeviceCounterType): string {
        return this.getDeviceCounterTypeItem(value).subtext;
    }

    // #endregion

    // #region DeviceAlertType

    public getDeviceAlertTypeItem(value: DeviceAlertType): SelectListItem {
        return this.transform(DeviceAlertType, "DeviceAlertType", value);
    }

    public getDeviceAlertTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DeviceAlertType, "DeviceAlertType", reverse);
    }

    public getDeviceAlertTypeName(value: DeviceAlertType): string {
        return this.getDeviceAlertTypeItem(value).text;
    }

    public getDeviceAlertTypeText(value: DeviceAlertType): string {
        return this.localizer.get(this.getDeviceAlertTypeName(value));
    }

    public getDeviceAlertTypeDescription(value: DeviceAlertType): string {
        return this.getDeviceAlertTypeItem(value).subtext;
    }

    // #endregion

    // #region MaintenanceReason

    public getMaintenanceReasonItem(value: MaintenanceReason): SelectListItem {
        return this.transform(MaintenanceReason, "MaintenanceReason", value);
    }

    public getMaintenanceReasonItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(MaintenanceReason, "MaintenanceReason", reverse);
    }

    public getMaintenanceReasonName(value: MaintenanceReason): string {
        return this.getMaintenanceReasonItem(value).text;
    }

    public getMaintenanceReasonText(value: MaintenanceReason): string {
        return this.localizer.get(this.getMaintenanceReasonName(value));
    }

    public getMaintenanceReasonDescription(value: MaintenanceReason): string {
        return this.getMaintenanceReasonItem(value).subtext;
    }

    // #endregion

    // #region FaultLevel

    public getFaultLevelItem(value: FaultLevel): SelectListItem {
        return this.transform(FaultLevel, "FaultLevel", value);
    }

    public getFaultLevelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FaultLevel, "FaultLevel", reverse);
    }

    public getFaultLevelName(value: FaultLevel): string {
        return this.getFaultLevelItem(value).text;
    }

    public getFaultLevelText(value: FaultLevel): string {
        return this.localizer.get(this.getFaultLevelName(value));
    }

    public getFaultLevelDescription(value: FaultLevel): string {
        return this.getFaultLevelItem(value).subtext;
    }

    // #endregion

    // #region ReportItemType

    public getReportItemTypeItem(value: ReportItemType): SelectListItem {
        return this.transform(ReportItemType, "ReportItemType", value);
    }

    public getReportItemTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ReportItemType, "ReportItemType", reverse);
    }

    public getReportItemTypeName(value: ReportItemType): string {
        return this.getReportItemTypeItem(value).text;
    }

    public getReportItemTypeText(value: ReportItemType): string {
        return this.localizer.get(this.getReportItemTypeName(value));
    }

    public getReportItemTypeDescription(value: ReportItemType): string {
        return this.getReportItemTypeItem(value).subtext;
    }

    // #endregion

    // #region ResourceItemType

    public getResourceItemTypeItem(value: ResourceItemType): SelectListItem {
        return this.transform(ResourceItemType, "ResourceItemType", value);
    }

    public getResourceItemTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ResourceItemType, "ResourceItemType", reverse);
    }

    public getResourceItemTypeName(value: ResourceItemType): string {
        return this.getResourceItemTypeItem(value).text;
    }

    public getResourceItemTypeText(value: ResourceItemType): string {
        return this.localizer.get(this.getResourceItemTypeName(value));
    }

    public getResourceItemTypeDescription(value: ResourceItemType): string {
        return this.getResourceItemTypeItem(value).subtext;
    }

    // #endregion

    // #region LoginResultStatus

    public getLoginResultStatusItem(value: LoginResultStatus): SelectListItem {
        return this.transform(LoginResultStatus, "LoginResultStatus", value);
    }

    public getLoginResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LoginResultStatus, "LoginResultStatus", reverse);
    }

    public getLoginResultStatusName(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).text;
    }

    public getLoginResultStatusText(value: LoginResultStatus): string {
        return this.localizer.get(this.getLoginResultStatusName(value));
    }

    public getLoginResultStatusDescription(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).subtext;
    }

    // #endregion

    // #region UserRole

    public getUserRoleItem(value: UserRole): SelectListItem {
        return this.transform(UserRole, "UserRole", value);
    }

    public getUserRoleItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(UserRole, "UserRole", reverse);
    }

    public getUserRoleName(value: UserRole): string {
        return this.getUserRoleItem(value).text;
    }

    public getUserRoleText(value: UserRole): string {
        return this.localizer.get(this.getUserRoleName(value));
    }

    public getUserRoleDescription(value: UserRole): string {
        return this.getUserRoleItem(value).subtext;
    }

    // #endregion

    // #region MeasuringOperators

    public getMeasuringOperatorsItem(value: MeasuringOperators): SelectListItem {
        return this.transform(MeasuringOperators, "MeasuringOperators", value);
    }

    public getMeasuringOperatorsItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(MeasuringOperators, "MeasuringOperators", reverse);
    }

    public getMeasuringOperatorsName(value: MeasuringOperators): string {
        return this.getMeasuringOperatorsItem(value).text;
    }

    public getMeasuringOperatorsText(value: MeasuringOperators): string {
        return this.localizer.get(this.getMeasuringOperatorsName(value));
    }

    public getMeasuringOperatorsDescription(value: MeasuringOperators): string {
        return this.getMeasuringOperatorsItem(value).subtext;
    }

    // #endregion

    // #region InvitationType

    public getInvitationTypeItem(value: InvitationType): SelectListItem {
        return this.transform(InvitationType, "InvitationType", value);
    }

    public getInvitationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationType, "InvitationType", reverse);
    }

    public getInvitationTypeName(value: InvitationType): string {
        return this.getInvitationTypeItem(value).text;
    }

    public getInvitationTypeText(value: InvitationType): string {
        return this.localizer.get(this.getInvitationTypeName(value));
    }

    public getInvitationTypeDescription(value: InvitationType): string {
        return this.getInvitationTypeItem(value).subtext;
    }

    // #endregion

    // #region AuditTimestamp

    public getAuditTimestampItem(value: AuditTimestamp): SelectListItem {
        return this.transform(AuditTimestamp, "AuditTimestamp", value);
    }

    public getAuditTimestampItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuditTimestamp, "AuditTimestamp", reverse);
    }

    public getAuditTimestampName(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).text;
    }

    public getAuditTimestampText(value: AuditTimestamp): string {
        return this.localizer.get(this.getAuditTimestampName(value));
    }

    public getAuditTimestampDescription(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).subtext;
    }

    // #endregion

    // #region AuthType

    public getAuthTypeItem(value: AuthType): SelectListItem {
        return this.transform(AuthType, "AuthType", value);
    }

    public getAuthTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuthType, "AuthType", reverse);
    }

    public getAuthTypeName(value: AuthType): string {
        return this.getAuthTypeItem(value).text;
    }

    public getAuthTypeText(value: AuthType): string {
        return this.localizer.get(this.getAuthTypeName(value));
    }

    public getAuthTypeDescription(value: AuthType): string {
        return this.getAuthTypeItem(value).subtext;
    }

    // #endregion

    // #region AnnualInspectionStatus

    public getAnnualInspectionStatusItem(value: AnnualInspectionStatus): SelectListItem {
        return this.transform(AnnualInspectionStatus, "AnnualInspectionStatus", value);
    }

    public getAnnualInspectionStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AnnualInspectionStatus, "AnnualInspectionStatus", reverse);
    }

    public getAnnualInspectionStatusName(value: AnnualInspectionStatus): string {
        return this.getAnnualInspectionStatusItem(value).text;
    }

    public getAnnualInspectionStatusText(value: AnnualInspectionStatus): string {
        return this.localizer.get(this.getAnnualInspectionStatusName(value));
    }

    public getAnnualInspectionStatusDescription(value: AnnualInspectionStatus): string {
        return this.getAnnualInspectionStatusItem(value).subtext;
    }

    // #endregion

    // #region AnnualInspectionType

    public getAnnualInspectionTypeItem(value: AnnualInspectionType): SelectListItem {
        return this.transform(AnnualInspectionType, "AnnualInspectionType", value);
    }

    public getAnnualInspectionTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AnnualInspectionType, "AnnualInspectionType", reverse);
    }

    public getAnnualInspectionTypeName(value: AnnualInspectionType): string {
        return this.getAnnualInspectionTypeItem(value).text;
    }

    public getAnnualInspectionTypeText(value: AnnualInspectionType): string {
        return this.localizer.get(this.getAnnualInspectionTypeName(value));
    }

    public getAnnualInspectionTypeDescription(value: AnnualInspectionType): string {
        return this.getAnnualInspectionTypeItem(value).subtext;
    }

    // #endregion

    // #region DocumentType

    public getDocumentTypeItem(value: DocumentType): SelectListItem {
        return this.transform(DocumentType, "DocumentType", value);
    }

    public getDocumentTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DocumentType, "DocumentType", reverse);
    }

    public getDocumentTypeName(value: DocumentType): string {
        return this.getDocumentTypeItem(value).text;
    }

    public getDocumentTypeText(value: DocumentType): string {
        return this.localizer.get(this.getDocumentTypeName(value));
    }

    public getDocumentTypeDescription(value: DocumentType): string {
        return this.getDocumentTypeItem(value).subtext;
    }

    // #endregion

    // #region DeviceStatus

    public getDeviceStatusItem(value: DeviceStatus): SelectListItem {
        return this.transform(DeviceStatus, "DeviceStatus", value);
    }

    public getDeviceStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DeviceStatus, "DeviceStatus", reverse);
    }

    public getDeviceStatusName(value: DeviceStatus): string {
        return this.getDeviceStatusItem(value).text;
    }

    public getDeviceStatusText(value: DeviceStatus): string {
        return this.localizer.get(this.getDeviceStatusName(value));
    }

    public getDeviceStatusDescription(value: DeviceStatus): string {
        return this.getDeviceStatusItem(value).subtext;
    }

    // #endregion

    // #region DeviceBanOfUse

    public getDeviceBanOfUseItem(value: DeviceBanOfUse): SelectListItem {
        return this.transform(DeviceBanOfUse, "DeviceBanOfUse", value);
    }

    public getDeviceBanOfUseItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DeviceBanOfUse, "DeviceBanOfUse", reverse);
    }

    public getDeviceBanOfUseName(value: DeviceBanOfUse): string {
        return this.getDeviceBanOfUseItem(value).text;
    }

    public getDeviceBanOfUseText(value: DeviceBanOfUse): string {
        return this.localizer.get(this.getDeviceBanOfUseName(value));
    }

    public getDeviceBanOfUseDescription(value: DeviceBanOfUse): string {
        return this.getDeviceBanOfUseItem(value).subtext;
    }

    // #endregion

    // #region SavePasswordResultStatus

    public getSavePasswordResultStatusItem(value: SavePasswordResultStatus): SelectListItem {
        return this.transform(SavePasswordResultStatus, "SavePasswordResultStatus", value);
    }

    public getSavePasswordResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SavePasswordResultStatus, "SavePasswordResultStatus", reverse);
    }

    public getSavePasswordResultStatusName(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).text;
    }

    public getSavePasswordResultStatusText(value: SavePasswordResultStatus): string {
        return this.localizer.get(this.getSavePasswordResultStatusName(value));
    }

    public getSavePasswordResultStatusDescription(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).subtext;
    }

    // #endregion

    // #region SortDirection

    public getSortDirectionItem(value: SortDirection): SelectListItem {
        return this.transform(SortDirection, "SortDirection", value);
    }

    public getSortDirectionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SortDirection, "SortDirection", reverse);
    }

    public getSortDirectionName(value: SortDirection): string {
        return this.getSortDirectionItem(value).text;
    }

    public getSortDirectionText(value: SortDirection): string {
        return this.localizer.get(this.getSortDirectionName(value));
    }

    public getSortDirectionDescription(value: SortDirection): string {
        return this.getSortDirectionItem(value).subtext;
    }

    // #endregion

    // #region InvoiceStatusFilter

    public getInvoiceStatusFilterItem(value: InvoiceStatusFilter): SelectListItem {
        return this.transform(InvoiceStatusFilter, "InvoiceStatusFilter", value);
    }

    public getInvoiceStatusFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvoiceStatusFilter, "InvoiceStatusFilter", reverse);
    }

    public getInvoiceStatusFilterName(value: InvoiceStatusFilter): string {
        return this.getInvoiceStatusFilterItem(value).text;
    }

    public getInvoiceStatusFilterText(value: InvoiceStatusFilter): string {
        return this.localizer.get(this.getInvoiceStatusFilterName(value));
    }

    public getInvoiceStatusFilterDescription(value: InvoiceStatusFilter): string {
        return this.getInvoiceStatusFilterItem(value).subtext;
    }

    // #endregion

    // #region ServiceLocationType

    public getServiceLocationTypeItem(value: ServiceLocationType): SelectListItem {
        return this.transform(ServiceLocationType, "ServiceLocationType", value);
    }

    public getServiceLocationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ServiceLocationType, "ServiceLocationType", reverse);
    }

    public getServiceLocationTypeName(value: ServiceLocationType): string {
        return this.getServiceLocationTypeItem(value).text;
    }

    public getServiceLocationTypeText(value: ServiceLocationType): string {
        return this.localizer.get(this.getServiceLocationTypeName(value));
    }

    public getServiceLocationTypeDescription(value: ServiceLocationType): string {
        return this.getServiceLocationTypeItem(value).subtext;
    }

    // #endregion

    // #region DevicePicturesFilter

    public getDevicePicturesFilterItem(value: DevicePicturesFilter): SelectListItem {
        return this.transform(DevicePicturesFilter, "DevicePicturesFilter", value);
    }

    public getDevicePicturesFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DevicePicturesFilter, "DevicePicturesFilter", reverse);
    }

    public getDevicePicturesFilterName(value: DevicePicturesFilter): string {
        return this.getDevicePicturesFilterItem(value).text;
    }

    public getDevicePicturesFilterText(value: DevicePicturesFilter): string {
        return this.localizer.get(this.getDevicePicturesFilterName(value));
    }

    public getDevicePicturesFilterDescription(value: DevicePicturesFilter): string {
        return this.getDevicePicturesFilterItem(value).subtext;
    }

    // #endregion

    // #region DevicePictureType

    public getDevicePictureTypeItem(value: DevicePictureType): SelectListItem {
        return this.transform(DevicePictureType, "DevicePictureType", value);
    }

    public getDevicePictureTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DevicePictureType, "DevicePictureType", reverse);
    }

    public getDevicePictureTypeName(value: DevicePictureType): string {
        return this.getDevicePictureTypeItem(value).text;
    }

    public getDevicePictureTypeText(value: DevicePictureType): string {
        return this.localizer.get(this.getDevicePictureTypeName(value));
    }

    public getDevicePictureTypeDescription(value: DevicePictureType): string {
        return this.getDevicePictureTypeItem(value).subtext;
    }

    // #endregion

    // #region AiJobType

    public getAiJobTypeItem(value: AiJobType): SelectListItem {
        return this.transform(AiJobType, "AiJobType", value);
    }

    public getAiJobTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AiJobType, "AiJobType", reverse);
    }

    public getAiJobTypeName(value: AiJobType): string {
        return this.getAiJobTypeItem(value).text;
    }

    public getAiJobTypeText(value: AiJobType): string {
        return this.localizer.get(this.getAiJobTypeName(value));
    }

    public getAiJobTypeDescription(value: AiJobType): string {
        return this.getAiJobTypeItem(value).subtext;
    }

    // #endregion

    // #region AiDataType

    public getAiDataTypeItem(value: AiDataType): SelectListItem {
        return this.transform(AiDataType, "AiDataType", value);
    }

    public getAiDataTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AiDataType, "AiDataType", reverse);
    }

    public getAiDataTypeName(value: AiDataType): string {
        return this.getAiDataTypeItem(value).text;
    }

    public getAiDataTypeText(value: AiDataType): string {
        return this.localizer.get(this.getAiDataTypeName(value));
    }

    public getAiDataTypeDescription(value: AiDataType): string {
        return this.getAiDataTypeItem(value).subtext;
    }

    // #endregion

    // #region AiMetadataType

    public getAiMetadataTypeItem(value: AiMetadataType): SelectListItem {
        return this.transform(AiMetadataType, "AiMetadataType", value);
    }

    public getAiMetadataTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AiMetadataType, "AiMetadataType", reverse);
    }

    public getAiMetadataTypeName(value: AiMetadataType): string {
        return this.getAiMetadataTypeItem(value).text;
    }

    public getAiMetadataTypeText(value: AiMetadataType): string {
        return this.localizer.get(this.getAiMetadataTypeName(value));
    }

    public getAiMetadataTypeDescription(value: AiMetadataType): string {
        return this.getAiMetadataTypeItem(value).subtext;
    }

    // #endregion

    // #region AiJobStatus

    public getAiJobStatusItem(value: AiJobStatus): SelectListItem {
        return this.transform(AiJobStatus, "AiJobStatus", value);
    }

    public getAiJobStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AiJobStatus, "AiJobStatus", reverse);
    }

    public getAiJobStatusName(value: AiJobStatus): string {
        return this.getAiJobStatusItem(value).text;
    }

    public getAiJobStatusText(value: AiJobStatus): string {
        return this.localizer.get(this.getAiJobStatusName(value));
    }

    public getAiJobStatusDescription(value: AiJobStatus): string {
        return this.getAiJobStatusItem(value).subtext;
    }

    // #endregion

    // #region ScheduleAction

    public getScheduleActionItem(value: ScheduleAction): SelectListItem {
        return this.transform(ScheduleAction, "ScheduleAction", value);
    }

    public getScheduleActionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ScheduleAction, "ScheduleAction", reverse);
    }

    public getScheduleActionName(value: ScheduleAction): string {
        return this.getScheduleActionItem(value).text;
    }

    public getScheduleActionText(value: ScheduleAction): string {
        return this.localizer.get(this.getScheduleActionName(value));
    }

    public getScheduleActionDescription(value: ScheduleAction): string {
        return this.getScheduleActionItem(value).subtext;
    }

    // #endregion

    // #region AnnualInspectionDevicesType

    public getAnnualInspectionDevicesTypeItem(value: AnnualInspectionDevicesType): SelectListItem {
        return this.transform(AnnualInspectionDevicesType, "AnnualInspectionDevicesType", value);
    }

    public getAnnualInspectionDevicesTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AnnualInspectionDevicesType, "AnnualInspectionDevicesType", reverse);
    }

    public getAnnualInspectionDevicesTypeName(value: AnnualInspectionDevicesType): string {
        return this.getAnnualInspectionDevicesTypeItem(value).text;
    }

    public getAnnualInspectionDevicesTypeText(value: AnnualInspectionDevicesType): string {
        return this.localizer.get(this.getAnnualInspectionDevicesTypeName(value));
    }

    public getAnnualInspectionDevicesTypeDescription(value: AnnualInspectionDevicesType): string {
        return this.getAnnualInspectionDevicesTypeItem(value).subtext;
    }

    // #endregion

    // #region AnnualInspectionStatusFilterType

    public getAnnualInspectionStatusFilterTypeItem(value: AnnualInspectionStatusFilterType): SelectListItem {
        return this.transform(AnnualInspectionStatusFilterType, "AnnualInspectionStatusFilterType", value);
    }

    public getAnnualInspectionStatusFilterTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AnnualInspectionStatusFilterType, "AnnualInspectionStatusFilterType", reverse);
    }

    public getAnnualInspectionStatusFilterTypeName(value: AnnualInspectionStatusFilterType): string {
        return this.getAnnualInspectionStatusFilterTypeItem(value).text;
    }

    public getAnnualInspectionStatusFilterTypeText(value: AnnualInspectionStatusFilterType): string {
        return this.localizer.get(this.getAnnualInspectionStatusFilterTypeName(value));
    }

    public getAnnualInspectionStatusFilterTypeDescription(value: AnnualInspectionStatusFilterType): string {
        return this.getAnnualInspectionStatusFilterTypeItem(value).subtext;
    }

    // #endregion

    // #region ReturnInspectionStatus

    public getReturnInspectionStatusItem(value: ReturnInspectionStatus): SelectListItem {
        return this.transform(ReturnInspectionStatus, "ReturnInspectionStatus", value);
    }

    public getReturnInspectionStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ReturnInspectionStatus, "ReturnInspectionStatus", reverse);
    }

    public getReturnInspectionStatusName(value: ReturnInspectionStatus): string {
        return this.getReturnInspectionStatusItem(value).text;
    }

    public getReturnInspectionStatusText(value: ReturnInspectionStatus): string {
        return this.localizer.get(this.getReturnInspectionStatusName(value));
    }

    public getReturnInspectionStatusDescription(value: ReturnInspectionStatus): string {
        return this.getReturnInspectionStatusItem(value).subtext;
    }

    // #endregion

    // #region ReturnInspectionDetailsTypes

    public getReturnInspectionDetailsTypesItem(value: ReturnInspectionDetailsTypes): SelectListItem {
        return this.transform(ReturnInspectionDetailsTypes, "ReturnInspectionDetailsTypes", value);
    }

    public getReturnInspectionDetailsTypesItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ReturnInspectionDetailsTypes, "ReturnInspectionDetailsTypes", reverse);
    }

    public getReturnInspectionDetailsTypesName(value: ReturnInspectionDetailsTypes): string {
        return this.getReturnInspectionDetailsTypesItem(value).text;
    }

    public getReturnInspectionDetailsTypesText(value: ReturnInspectionDetailsTypes): string {
        return this.localizer.get(this.getReturnInspectionDetailsTypesName(value));
    }

    public getReturnInspectionDetailsTypesDescription(value: ReturnInspectionDetailsTypes): string {
        return this.getReturnInspectionDetailsTypesItem(value).subtext;
    }

    // #endregion

    // #region ImageValidationResult

    public getImageValidationResultItem(value: ImageValidationResult): SelectListItem {
        return this.transform(ImageValidationResult, "ImageValidationResult", value);
    }

    public getImageValidationResultItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ImageValidationResult, "ImageValidationResult", reverse);
    }

    public getImageValidationResultName(value: ImageValidationResult): string {
        return this.getImageValidationResultItem(value).text;
    }

    public getImageValidationResultText(value: ImageValidationResult): string {
        return this.localizer.get(this.getImageValidationResultName(value));
    }

    public getImageValidationResultDescription(value: ImageValidationResult): string {
        return this.getImageValidationResultItem(value).subtext;
    }

    // #endregion

    // #region SearchItemType

    public getSearchItemTypeItem(value: SearchItemType): SelectListItem {
        return this.transform(SearchItemType, "SearchItemType", value);
    }

    public getSearchItemTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SearchItemType, "SearchItemType", reverse);
    }

    public getSearchItemTypeName(value: SearchItemType): string {
        return this.getSearchItemTypeItem(value).text;
    }

    public getSearchItemTypeText(value: SearchItemType): string {
        return this.localizer.get(this.getSearchItemTypeName(value));
    }

    public getSearchItemTypeDescription(value: SearchItemType): string {
        return this.getSearchItemTypeItem(value).subtext;
    }

    // #endregion

    // #region ReturnInspectionResponseType

    public getReturnInspectionResponseTypeItem(value: ReturnInspectionResponseType): SelectListItem {
        return this.transform(ReturnInspectionResponseType, "ReturnInspectionResponseType", value);
    }

    public getReturnInspectionResponseTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ReturnInspectionResponseType, "ReturnInspectionResponseType", reverse);
    }

    public getReturnInspectionResponseTypeName(value: ReturnInspectionResponseType): string {
        return this.getReturnInspectionResponseTypeItem(value).text;
    }

    public getReturnInspectionResponseTypeText(value: ReturnInspectionResponseType): string {
        return this.localizer.get(this.getReturnInspectionResponseTypeName(value));
    }

    public getReturnInspectionResponseTypeDescription(value: ReturnInspectionResponseType): string {
        return this.getReturnInspectionResponseTypeItem(value).subtext;
    }

    // #endregion

    // #region AnnualInspectionResponseType

    public getAnnualInspectionResponseTypeItem(value: AnnualInspectionResponseType): SelectListItem {
        return this.transform(AnnualInspectionResponseType, "AnnualInspectionResponseType", value);
    }

    public getAnnualInspectionResponseTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AnnualInspectionResponseType, "AnnualInspectionResponseType", reverse);
    }

    public getAnnualInspectionResponseTypeName(value: AnnualInspectionResponseType): string {
        return this.getAnnualInspectionResponseTypeItem(value).text;
    }

    public getAnnualInspectionResponseTypeText(value: AnnualInspectionResponseType): string {
        return this.localizer.get(this.getAnnualInspectionResponseTypeName(value));
    }

    public getAnnualInspectionResponseTypeDescription(value: AnnualInspectionResponseType): string {
        return this.getAnnualInspectionResponseTypeItem(value).subtext;
    }

    // #endregion

    // #region SaveServiceResponseType

    public getSaveServiceResponseTypeItem(value: SaveServiceResponseType): SelectListItem {
        return this.transform(SaveServiceResponseType, "SaveServiceResponseType", value);
    }

    public getSaveServiceResponseTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SaveServiceResponseType, "SaveServiceResponseType", reverse);
    }

    public getSaveServiceResponseTypeName(value: SaveServiceResponseType): string {
        return this.getSaveServiceResponseTypeItem(value).text;
    }

    public getSaveServiceResponseTypeText(value: SaveServiceResponseType): string {
        return this.localizer.get(this.getSaveServiceResponseTypeName(value));
    }

    public getSaveServiceResponseTypeDescription(value: SaveServiceResponseType): string {
        return this.getSaveServiceResponseTypeItem(value).subtext;
    }

    // #endregion

    // #region ErrorCodesFilters

    public getErrorCodesFiltersItem(value: ErrorCodesFilters): SelectListItem {
        return this.transform(ErrorCodesFilters, "ErrorCodesFilters", value);
    }

    public getErrorCodesFiltersItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ErrorCodesFilters, "ErrorCodesFilters", reverse);
    }

    public getErrorCodesFiltersName(value: ErrorCodesFilters): string {
        return this.getErrorCodesFiltersItem(value).text;
    }

    public getErrorCodesFiltersText(value: ErrorCodesFilters): string {
        return this.localizer.get(this.getErrorCodesFiltersName(value));
    }

    public getErrorCodesFiltersDescription(value: ErrorCodesFilters): string {
        return this.getErrorCodesFiltersItem(value).subtext;
    }

    // #endregion

    // #region ServiceAndRepairsStatuses

    public getServiceAndRepairsStatusesItem(value: ServiceAndRepairsStatuses): SelectListItem {
        return this.transform(ServiceAndRepairsStatuses, "ServiceAndRepairsStatuses", value);
    }

    public getServiceAndRepairsStatusesItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ServiceAndRepairsStatuses, "ServiceAndRepairsStatuses", reverse);
    }

    public getServiceAndRepairsStatusesName(value: ServiceAndRepairsStatuses): string {
        return this.getServiceAndRepairsStatusesItem(value).text;
    }

    public getServiceAndRepairsStatusesText(value: ServiceAndRepairsStatuses): string {
        return this.localizer.get(this.getServiceAndRepairsStatusesName(value));
    }

    public getServiceAndRepairsStatusesDescription(value: ServiceAndRepairsStatuses): string {
        return this.getServiceAndRepairsStatusesItem(value).subtext;
    }

    // #endregion

    // #region ServiceTypeFilter

    public getServiceTypeFilterItem(value: ServiceTypeFilter): SelectListItem {
        return this.transform(ServiceTypeFilter, "ServiceTypeFilter", value);
    }

    public getServiceTypeFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ServiceTypeFilter, "ServiceTypeFilter", reverse);
    }

    public getServiceTypeFilterName(value: ServiceTypeFilter): string {
        return this.getServiceTypeFilterItem(value).text;
    }

    public getServiceTypeFilterText(value: ServiceTypeFilter): string {
        return this.localizer.get(this.getServiceTypeFilterName(value));
    }

    public getServiceTypeFilterDescription(value: ServiceTypeFilter): string {
        return this.getServiceTypeFilterItem(value).subtext;
    }

    // #endregion

    // #region RecordOperatingHoursSource

    public getRecordOperatingHoursSourceItem(value: RecordOperatingHoursSource): SelectListItem {
        return this.transform(RecordOperatingHoursSource, "RecordOperatingHoursSource", value);
    }

    public getRecordOperatingHoursSourceItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(RecordOperatingHoursSource, "RecordOperatingHoursSource", reverse);
    }

    public getRecordOperatingHoursSourceName(value: RecordOperatingHoursSource): string {
        return this.getRecordOperatingHoursSourceItem(value).text;
    }

    public getRecordOperatingHoursSourceText(value: RecordOperatingHoursSource): string {
        return this.localizer.get(this.getRecordOperatingHoursSourceName(value));
    }

    public getRecordOperatingHoursSourceDescription(value: RecordOperatingHoursSource): string {
        return this.getRecordOperatingHoursSourceItem(value).subtext;
    }

    // #endregion

    // #region InvoicesTypes

    public getInvoicesTypesItem(value: InvoicesTypes): SelectListItem {
        return this.transform(InvoicesTypes, "InvoicesTypes", value);
    }

    public getInvoicesTypesItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvoicesTypes, "InvoicesTypes", reverse);
    }

    public getInvoicesTypesName(value: InvoicesTypes): string {
        return this.getInvoicesTypesItem(value).text;
    }

    public getInvoicesTypesText(value: InvoicesTypes): string {
        return this.localizer.get(this.getInvoicesTypesName(value));
    }

    public getInvoicesTypesDescription(value: InvoicesTypes): string {
        return this.getInvoicesTypesItem(value).subtext;
    }

    // #endregion

    // #region LambdaLogicalOperation

    public getLambdaLogicalOperationItem(value: LambdaLogicalOperation): SelectListItem {
        return this.transform(LambdaLogicalOperation, "LambdaLogicalOperation", value);
    }

    public getLambdaLogicalOperationItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LambdaLogicalOperation, "LambdaLogicalOperation", reverse);
    }

    public getLambdaLogicalOperationName(value: LambdaLogicalOperation): string {
        return this.getLambdaLogicalOperationItem(value).text;
    }

    public getLambdaLogicalOperationText(value: LambdaLogicalOperation): string {
        return this.localizer.get(this.getLambdaLogicalOperationName(value));
    }

    public getLambdaLogicalOperationDescription(value: LambdaLogicalOperation): string {
        return this.getLambdaLogicalOperationItem(value).subtext;
    }

    // #endregion

    // #region FailedItemType

    public getFailedItemTypeItem(value: FailedItemType): SelectListItem {
        return this.transform(FailedItemType, "FailedItemType", value);
    }

    public getFailedItemTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(FailedItemType, "FailedItemType", reverse);
    }

    public getFailedItemTypeName(value: FailedItemType): string {
        return this.getFailedItemTypeItem(value).text;
    }

    public getFailedItemTypeText(value: FailedItemType): string {
        return this.localizer.get(this.getFailedItemTypeName(value));
    }

    public getFailedItemTypeDescription(value: FailedItemType): string {
        return this.getFailedItemTypeItem(value).subtext;
    }

    // #endregion

    // #region DeviceListOrderType

    public getDeviceListOrderTypeItem(value: DeviceListOrderType): SelectListItem {
        return this.transform(DeviceListOrderType, "DeviceListOrderType", value);
    }

    public getDeviceListOrderTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DeviceListOrderType, "DeviceListOrderType", reverse);
    }

    public getDeviceListOrderTypeName(value: DeviceListOrderType): string {
        return this.getDeviceListOrderTypeItem(value).text;
    }

    public getDeviceListOrderTypeText(value: DeviceListOrderType): string {
        return this.localizer.get(this.getDeviceListOrderTypeName(value));
    }

    public getDeviceListOrderTypeDescription(value: DeviceListOrderType): string {
        return this.getDeviceListOrderTypeItem(value).subtext;
    }

    // #endregion

    // #region ExpenseUnit

    public getExpenseUnitItem(value: ExpenseUnit): SelectListItem {
        return this.transform(ExpenseUnit, "ExpenseUnit", value);
    }

    public getExpenseUnitItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ExpenseUnit, "ExpenseUnit", reverse);
    }

    public getExpenseUnitName(value: ExpenseUnit): string {
        return this.getExpenseUnitItem(value).text;
    }

    public getExpenseUnitText(value: ExpenseUnit): string {
        return this.localizer.get(this.getExpenseUnitName(value));
    }

    public getExpenseUnitDescription(value: ExpenseUnit): string {
        return this.getExpenseUnitItem(value).subtext;
    }

    // #endregion

    // #region OnletMaintenanceType

    public getOnletMaintenanceTypeItem(value: OnletMaintenanceType): SelectListItem {
        return this.transform(OnletMaintenanceType, "OnletMaintenanceType", value);
    }

    public getOnletMaintenanceTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OnletMaintenanceType, "OnletMaintenanceType", reverse);
    }

    public getOnletMaintenanceTypeName(value: OnletMaintenanceType): string {
        return this.getOnletMaintenanceTypeItem(value).text;
    }

    public getOnletMaintenanceTypeText(value: OnletMaintenanceType): string {
        return this.localizer.get(this.getOnletMaintenanceTypeName(value));
    }

    public getOnletMaintenanceTypeDescription(value: OnletMaintenanceType): string {
        return this.getOnletMaintenanceTypeItem(value).subtext;
    }

    // #endregion

    // #region OnletMaintenanceStatus

    public getOnletMaintenanceStatusItem(value: OnletMaintenanceStatus): SelectListItem {
        return this.transform(OnletMaintenanceStatus, "OnletMaintenanceStatus", value);
    }

    public getOnletMaintenanceStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OnletMaintenanceStatus, "OnletMaintenanceStatus", reverse);
    }

    public getOnletMaintenanceStatusName(value: OnletMaintenanceStatus): string {
        return this.getOnletMaintenanceStatusItem(value).text;
    }

    public getOnletMaintenanceStatusText(value: OnletMaintenanceStatus): string {
        return this.localizer.get(this.getOnletMaintenanceStatusName(value));
    }

    public getOnletMaintenanceStatusDescription(value: OnletMaintenanceStatus): string {
        return this.getOnletMaintenanceStatusItem(value).subtext;
    }

    // #endregion

    // #region PredictionTime

    public getPredictionTimeItem(value: PredictionTime): SelectListItem {
        return this.transform(PredictionTime, "PredictionTime", value);
    }

    public getPredictionTimeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PredictionTime, "PredictionTime", reverse);
    }

    public getPredictionTimeName(value: PredictionTime): string {
        return this.getPredictionTimeItem(value).text;
    }

    public getPredictionTimeText(value: PredictionTime): string {
        return this.localizer.get(this.getPredictionTimeName(value));
    }

    public getPredictionTimeDescription(value: PredictionTime): string {
        return this.getPredictionTimeItem(value).subtext;
    }

    // #endregion

    // #region DeviceListOrder

    public getDeviceListOrderItem(value: DeviceListOrder): SelectListItem {
        return this.transform(DeviceListOrder, "DeviceListOrder", value);
    }

    public getDeviceListOrderItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DeviceListOrder, "DeviceListOrder", reverse);
    }

    public getDeviceListOrderName(value: DeviceListOrder): string {
        return this.getDeviceListOrderItem(value).text;
    }

    public getDeviceListOrderText(value: DeviceListOrder): string {
        return this.localizer.get(this.getDeviceListOrderName(value));
    }

    public getDeviceListOrderDescription(value: DeviceListOrder): string {
        return this.getDeviceListOrderItem(value).subtext;
    }

    // #endregion

    // #region PredictionDatesType

    public getPredictionDatesTypeItem(value: PredictionDatesType): SelectListItem {
        return this.transform(PredictionDatesType, "PredictionDatesType", value);
    }

    public getPredictionDatesTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PredictionDatesType, "PredictionDatesType", reverse);
    }

    public getPredictionDatesTypeName(value: PredictionDatesType): string {
        return this.getPredictionDatesTypeItem(value).text;
    }

    public getPredictionDatesTypeText(value: PredictionDatesType): string {
        return this.localizer.get(this.getPredictionDatesTypeName(value));
    }

    public getPredictionDatesTypeDescription(value: PredictionDatesType): string {
        return this.getPredictionDatesTypeItem(value).subtext;
    }

    // #endregion

    // #region PredictionMaintenanceReason

    public getPredictionMaintenanceReasonItem(value: PredictionMaintenanceReason): SelectListItem {
        return this.transform(PredictionMaintenanceReason, "PredictionMaintenanceReason", value);
    }

    public getPredictionMaintenanceReasonItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PredictionMaintenanceReason, "PredictionMaintenanceReason", reverse);
    }

    public getPredictionMaintenanceReasonName(value: PredictionMaintenanceReason): string {
        return this.getPredictionMaintenanceReasonItem(value).text;
    }

    public getPredictionMaintenanceReasonText(value: PredictionMaintenanceReason): string {
        return this.localizer.get(this.getPredictionMaintenanceReasonName(value));
    }

    public getPredictionMaintenanceReasonDescription(value: PredictionMaintenanceReason): string {
        return this.getPredictionMaintenanceReasonItem(value).subtext;
    }

    // #endregion

    // #region ScheduledTaskStatus

    public getScheduledTaskStatusItem(value: ScheduledTaskStatus): SelectListItem {
        return this.transform(ScheduledTaskStatus, "ScheduledTaskStatus", value);
    }

    public getScheduledTaskStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ScheduledTaskStatus, "ScheduledTaskStatus", reverse);
    }

    public getScheduledTaskStatusName(value: ScheduledTaskStatus): string {
        return this.getScheduledTaskStatusItem(value).text;
    }

    public getScheduledTaskStatusText(value: ScheduledTaskStatus): string {
        return this.localizer.get(this.getScheduledTaskStatusName(value));
    }

    public getScheduledTaskStatusDescription(value: ScheduledTaskStatus): string {
        return this.getScheduledTaskStatusItem(value).subtext;
    }

    // #endregion

    // #region NotificationStatus

    public getNotificationStatusItem(value: NotificationStatus): SelectListItem {
        return this.transform(NotificationStatus, "NotificationStatus", value);
    }

    public getNotificationStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationStatus, "NotificationStatus", reverse);
    }

    public getNotificationStatusName(value: NotificationStatus): string {
        return this.getNotificationStatusItem(value).text;
    }

    public getNotificationStatusText(value: NotificationStatus): string {
        return this.localizer.get(this.getNotificationStatusName(value));
    }

    public getNotificationStatusDescription(value: NotificationStatus): string {
        return this.getNotificationStatusItem(value).subtext;
    }

    // #endregion

    // #region NotificationType

    public getNotificationTypeItem(value: NotificationType): SelectListItem {
        return this.transform(NotificationType, "NotificationType", value);
    }

    public getNotificationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationType, "NotificationType", reverse);
    }

    public getNotificationTypeName(value: NotificationType): string {
        return this.getNotificationTypeItem(value).text;
    }

    public getNotificationTypeText(value: NotificationType): string {
        return this.localizer.get(this.getNotificationTypeName(value));
    }

    public getNotificationTypeDescription(value: NotificationType): string {
        return this.getNotificationTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationMediaType

    public getNotificationMediaTypeItem(value: NotificationMediaType): SelectListItem {
        return this.transform(NotificationMediaType, "NotificationMediaType", value);
    }

    public getNotificationMediaTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationMediaType, "NotificationMediaType", reverse);
    }

    public getNotificationMediaTypeName(value: NotificationMediaType): string {
        return this.getNotificationMediaTypeItem(value).text;
    }

    public getNotificationMediaTypeText(value: NotificationMediaType): string {
        return this.localizer.get(this.getNotificationMediaTypeName(value));
    }

    public getNotificationMediaTypeDescription(value: NotificationMediaType): string {
        return this.getNotificationMediaTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationInterval

    public getNotificationIntervalItem(value: NotificationInterval): SelectListItem {
        return this.transform(NotificationInterval, "NotificationInterval", value);
    }

    public getNotificationIntervalItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationInterval, "NotificationInterval", reverse);
    }

    public getNotificationIntervalName(value: NotificationInterval): string {
        return this.getNotificationIntervalItem(value).text;
    }

    public getNotificationIntervalText(value: NotificationInterval): string {
        return this.localizer.get(this.getNotificationIntervalName(value));
    }

    public getNotificationIntervalDescription(value: NotificationInterval): string {
        return this.getNotificationIntervalItem(value).subtext;
    }

    // #endregion

    // #region ObservationsFilter

    public getObservationsFilterItem(value: ObservationsFilter): SelectListItem {
        return this.transform(ObservationsFilter, "ObservationsFilter", value);
    }

    public getObservationsFilterItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ObservationsFilter, "ObservationsFilter", reverse);
    }

    public getObservationsFilterName(value: ObservationsFilter): string {
        return this.getObservationsFilterItem(value).text;
    }

    public getObservationsFilterText(value: ObservationsFilter): string {
        return this.localizer.get(this.getObservationsFilterName(value));
    }

    public getObservationsFilterDescription(value: ObservationsFilter): string {
        return this.getObservationsFilterItem(value).subtext;
    }

    // #endregion

    // #region ServiceDefinitionType

    public getServiceDefinitionTypeItem(value: ServiceDefinitionType): SelectListItem {
        return this.transform(ServiceDefinitionType, "ServiceDefinitionType", value);
    }

    public getServiceDefinitionTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ServiceDefinitionType, "ServiceDefinitionType", reverse);
    }

    public getServiceDefinitionTypeName(value: ServiceDefinitionType): string {
        return this.getServiceDefinitionTypeItem(value).text;
    }

    public getServiceDefinitionTypeText(value: ServiceDefinitionType): string {
        return this.localizer.get(this.getServiceDefinitionTypeName(value));
    }

    public getServiceDefinitionTypeDescription(value: ServiceDefinitionType): string {
        return this.getServiceDefinitionTypeItem(value).subtext;
    }

    // #endregion

    // #region DayOfWeek

    public getDayOfWeekItem(value: DayOfWeek): SelectListItem {
        return this.transform(DayOfWeek, "DayOfWeek", value);
    }

    public getDayOfWeekItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DayOfWeek, "DayOfWeek", reverse);
    }

    public getDayOfWeekName(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).text;
    }

    public getDayOfWeekText(value: DayOfWeek): string {
        return this.localizer.get(this.getDayOfWeekName(value));
    }

    public getDayOfWeekDescription(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).subtext;
    }

    // #endregion
}

//Singleton
export default new EnumProvider();