import DeviceInfo from "@/models/server/DeviceInfo";

export default class DeviceContract {
    public id: string | null = null;

    public deviceId: string | null = null;

    public device: DeviceInfo | null = null;

    public contractExternalId: string | null = null;

    public customerName: string | null = null;

    public customerExternalId: string | null = null;

    public constructionSiteName: string | null = null;

    public constructionSiteExternalId: string | null = null;

    public startAt: Date | null = null;

    public endAt: Date | null = null;

    public lastSyncAt: Date | null = null;

    public isDeviceContract: boolean = true;
}