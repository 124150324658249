import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnActionType,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    RowModel, Spinner,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import {ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ActionType} from "@/models/Enums";
import VendorDetailsPanel from "@/components/VendorDetailsPanel/VendorDetailsPanel";
import EditVendorModal from "@/components/EditVendorModal/EditVendorModal";
import ListAnnualInspectionVendorsRequest from "@/models/server/requests/ListAnnualInspectionVendorsRequest";
import SaveAnnualInspectionVendorRequest from "@/models/server/requests/SaveAnnualInspectionVendorRequest";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";
import SaveAnnualInspectionVendorResponse from "@/models/server/responses/SaveAnnualInspectionVendorResponse";
import DeleteAnnualInspectionVendorResponse from "@/models/server/responses/DeleteAnnualInspectionVendorResponse";
import GetUsersRequest from "@/models/server/requests/GetUsersRequest";
import User from "@/models/server/User";
import AnnualInspectionController from "@/pages/AnnualInspectionController";
import Localizer from "../../localization/Localizer";

import styles from "./AnnualInspectionVendorsPage.module.scss";
import rentaToolsStyles from "../RentaTools.module.scss";

interface IAnnualInspectionVendorsPageProps {
}

interface IAnnualInspectionVendorsPageState {
    showDeleted: boolean;
    vendors: AnnualInspectionVendor[];
    users: User[];
    loading: boolean;
}

export default class AnnualInspectionVendorsPage extends AuthorizedPage<IAnnualInspectionVendorsPageProps, IAnnualInspectionVendorsPageState> {

    state: IAnnualInspectionVendorsPageState = {
        showDeleted: false,
        vendors: [],
        users: [],
        loading: false,
    };

    private readonly _annualInspectionVendorGridRef: React.RefObject<Grid<AnnualInspectionVendor>> = React.createRef();
    private readonly _editVendorModalRef: React.RefObject<EditVendorModal> = React.createRef();

    private readonly _vendorColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            name: "details",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            actions: [
                {
                    title: "Details",
                    type: ColumnActionType.Details,
                    right: false,
                    callback: async (cell) => await this.toggleDetailsAsync(cell)
                }
            ],
            init: (cell) => this.initDetailsCell(cell),
        },
        {
            header: Localizer.annualInspectionVendorsPageGridNameLanguageItemName,
            accessor: nameof.full<AnnualInspectionVendor>(w => w.name),
            minWidth: 150,
            type: ColumnType.Text,
            stretch: true,
            editable: true,
            reRenderRow: true,
            noWrap: true,
            settings: {
                required: true
            },
        },
        {
            name: "actions",
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initVendorOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.annualInspectionVendorsPageGridSaveLanguageItemName,
                    icon: "far save",
                    right: false,
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.annualInspectionVendorsPageGridCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.annualInspectionVendorsPageGridDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.annualInspectionVendorsPageGridRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "fad fa-sitemap",
                    type: ActionType.Secondary,
                    right: false,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                }
            ]
        }
    ];

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.setState({loading: true});

        const userRequest = {
            showDeleted: false,
            showExpired: false,
            depoIds: null,
            roles: null,
        } as GetUsersRequest;

        const users: User[] = await this.postAsync("api/UserManagement/GetUsers", userRequest);

        this.setState({users, loading: false});
    }

    private get annualInspectionVendorGrid(): GridModel<AnnualInspectionVendor> {
        return this._annualInspectionVendorGridRef.current!.model;
    }

    private get vendors(): AnnualInspectionVendor[] {
        return this.state.vendors;
    }

    private get loading(): boolean {
        return this.state.loading;
    }

    public isSpinning(): boolean {
        return (this.loading || super.isSpinning());
    }

    private async getOwnerLevelVendorDataAsync(): Promise<AnnualInspectionVendor[]> {
        await this.setState({loading: true});

        const request = {
            includeChildren: true,
            includeDeleted: this.state.showDeleted,
        } as ListAnnualInspectionVendorsRequest;

        let vendors: AnnualInspectionVendor[] = await AnnualInspectionController.listVendorsAsync(request);

        await this.setState({vendors, loading: false});

        return vendors.where(v => !v.parentId);
    }

    private get allUsers(): User[] {
        return this.state.users;
    }

    private async getUsersToDisplay(userId: string | null): Promise<User[]> {
        const assignedUsersIds: string[] = this.vendors
            .where(v => v.user != null)
            .map(v => v.user!.id)
            .distinct(id => id);

        return this.allUsers.where(u => userId === u.id || !assignedUsersIds.includes(u.id));
    }

    private initRow(row: RowModel<AnnualInspectionVendor>): void {
        const model: AnnualInspectionVendor = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = this.isValid(model);
        row.deleted = model.isDeleted;
        row.className = (!isValid)
            ? "bg-declined"
            : (isNew)
                ? "bg-approved"
                : "";
    }

    private initDetailsCell(cell: CellModel<AnnualInspectionVendor>): void {
        const isNew: boolean = (!cell.model.id);

        const showDetails: CellAction<AnnualInspectionVendor> = cell.actions[0];
        showDetails.visible = !isNew;
    }

    private isValid(vendor: AnnualInspectionVendor): boolean {
        return (!!vendor.name);
    }

    private async toggleDetailsAsync(cell: CellModel<AnnualInspectionVendor>): Promise<void> {
        const spannedRows: RowModel<AnnualInspectionVendor>[] = cell.spannedRows;
        const rowToExpand: RowModel<AnnualInspectionVendor> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }

    private async initVendorOperationsAsync(cell: CellModel<AnnualInspectionVendor>): Promise<void> {

        const model: AnnualInspectionVendor = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;
        const hasChild: boolean = !modified && this.vendors.some(item => item.parentId === model.id && !item.isDeleted);

        const saveAction: CellAction<AnnualInspectionVendor> = cell.actions[0];
        const cancelAction: CellAction<AnnualInspectionVendor> = cell.actions[1];
        const deleteAction: CellAction<AnnualInspectionVendor> = cell.actions[2];
        const restoreAction: CellAction<AnnualInspectionVendor> = cell.actions[3];
        const editAction: CellAction<AnnualInspectionVendor> = cell.actions[4];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
        editAction.visible = !deleted && !modified && !isNew && !hasChild;
    }

    private getDeleteConfirmation(cell: CellModel<AnnualInspectionVendor>): string {
        const model: AnnualInspectionVendor = cell.model;
        const isNew: boolean = !model.id;
        const hasActiveChildren: boolean = this.vendors.some(item => item.parentId === model.id && !item.isDeleted);

        return (isNew)
            // Grid types require returned value to be a string, but has been like this forever
            ? null as any
            : (hasActiveChildren)
                ? Utility.format(Localizer.annualInspectionVendorsPageConfirmationMessageRemoveVendor, `'${cell.model.name}'`)
                : Utility.format(Localizer.annualInspectionVendorsPageConfirmationButtonDelete, `'${cell.model.name}'`);
    }

    private async processVendorOperationAsync(cell: CellModel<AnnualInspectionVendor>, action: CellAction<AnnualInspectionVendor>): Promise<void> {

        await ch.hideAlertAsync();

        const model: AnnualInspectionVendor = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {

                const request = new SaveAnnualInspectionVendorRequest();
                request.name = model.name;

                const response: SaveAnnualInspectionVendorResponse = await AnnualInspectionController.addVendorAsync(request);

                if (response.annualInspectionVendorAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.annualInspectionVendorsPageAlertErrorAsyncVendorExists, model.name), true);
                    return;
                }

                cell.row.model = response.annualInspectionVendor!;

                await cell.row.bindAsync();

            } else {

                const request = new SaveAnnualInspectionVendorRequest();
                request.id = model.id;
                request.name = model.name;

                const response: SaveAnnualInspectionVendorResponse = await AnnualInspectionController.saveVendorAsync(request);

                if (response.annualInspectionVendorAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.annualInspectionVendorsPageAlertErrorAsyncVendorExists, model.name), true);
                    return;
                }

                cell.row.model = response.annualInspectionVendor!;

                await cell.row.bindAsync();
            }

            await cell.row.reRenderAsync(true);

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            model.isDeleted = true;

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {
                const response: DeleteAnnualInspectionVendorResponse = await AnnualInspectionController.deleteVendorAsync(model.id);

                if (response.success) {
                    if (response.markedAsDeletedVendors != null && response.markedAsDeletedVendors.length > 0) {
                        const vendors: string = response.markedAsDeletedVendors
                            .map(item => `\"${item}\"`)
                            .join(", ");

                        await ch.alertWarningAsync(Localizer.annualInspectionVendorsPageAlertWarningVendorsRemovedAndMarkedDeleted.format(vendors));
                    } else {
                        const hasChildren: boolean = this.vendors.some(item => item.parentId === model.id);
                        const message: string = (hasChildren)
                            ? Localizer.annualInspectionVendorsPageAlertMessageVendorAndInspectorsRemovedPermanently.format(model.name)
                            : Localizer.annualInspectionVendorsPageAlertMessageVendorRemovedPermanently.format(model.name);

                        await ch.alertMessageAsync(message);
                    }

                    await this.reloadAsync();
                }
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await AnnualInspectionController.restoreVendorAsync(model.id);

                model.isDeleted = false;
            }

            await cell.row.setDeletedAsync(false);
        } else if (action.action.name === "edit") {
            const vendor = cell.row.model;
            await this.editVendorAsync(vendor);
        }
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        this.setState({showDeleted});

        await this.reloadAsync();
    }

    private get newRowAlreadyExists(): boolean {
        return (this.annualInspectionVendorGrid.rows.some(row => !row.deleted && !row.model.id));
    }

    private async addAnnualInspectionVendorAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            let vendor = new AnnualInspectionVendor();

            const rows: RowModel<AnnualInspectionVendor>[] = await this.annualInspectionVendorGrid.insertAsync(0, vendor);

            const row: RowModel<AnnualInspectionVendor> = rows[0];
            const nameCell: CellModel<AnnualInspectionVendor> = row.get("name");
            await nameCell.editAsync(true);
        }
    }

    private async saveAnnualInspectionVendorAsync(request: SaveAnnualInspectionVendorRequest): Promise<void> {
        const response: SaveAnnualInspectionVendorResponse = await AnnualInspectionController.saveVendorAsync(request);

        if (response.annualInspectionVendorAlreadyExists) {
            await ch.alertErrorAsync(Utility.format(Localizer.annualInspectionVendorsPageAlertErrorAsyncVendorExists, request.name), true);
            return;
        }

        await this.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.setState({loading: true});
        await this.annualInspectionVendorGrid.reloadAsync(true);
        await this.setState({loading: false});
    }

    private async editVendorAsync(vendor: AnnualInspectionVendor): Promise<void> {
        await this._editVendorModalRef.current?.openAsync(vendor);
    }

    private async onChangeChildVendorAsync(childVendor: AnnualInspectionVendor, isPermanentlyRemoved: boolean = false): Promise<void> {
        const foundIndex: number = this.vendors.findIndex(item => item.id === childVendor.id);

        if (!isPermanentlyRemoved) {
            // Updates the vendor that modified or marked as deleted or restored.
            this.vendors[foundIndex] = childVendor;
        } else {
            this.vendors.splice(foundIndex, 1);
        }

        await this.setState({vendors: this.vendors});
    }

    private async onAddChildVendorAsync(childVendor: AnnualInspectionVendor): Promise<void> {
        this.vendors.push(childVendor);

        await this.setState({vendors: this.vendors});
    }

    private renderDetailsContent(row: RowModel<AnnualInspectionVendor>): React.ReactNode {
        const model: AnnualInspectionVendor = row.model;

        return (
            <VendorDetailsPanel key={`vendorDetailsPanel`}
                                vendor={model}
                                showDeleted={this.state.showDeleted}
                                getUsers={(userId: string | null) => this.getUsersToDisplay(userId)}
                                edit={(vendor: AnnualInspectionVendor) => this.editVendorAsync(vendor)}
                                onAddChildVendor={(childVendor: AnnualInspectionVendor) => this.onAddChildVendorAsync(childVendor)}
                                onChangeChildVendor={(childVendor: AnnualInspectionVendor, isPermanentlyRemoved: boolean) => this.onChangeChildVendorAsync(childVendor, isPermanentlyRemoved)}
            />
        );
    }

    public render(): React.ReactNode {
        return (

            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.vendors)}>

                {
                    (this.isSpinning()) && <Spinner global/>
                }

                <PageHeader title={Localizer.annualInspectionVendorsPageTitle} subtitle={Localizer.annualInspectionVendorsPageSubTitle}/>

                <PageRow>
                    <div className={styles.container}>

                        <ToolbarContainer className={styles.toolbar}>

                            <Inline justify={JustifyContent.End}>

                                <Checkbox inline
                                          label={Localizer.genericShowDeleted}
                                          value={this.state.showDeleted}
                                          onChange={(_, value) => this.setShowDeletedAsync(value)}
                                />

                                <Button title={Localizer.annualInspectionVendorsPageButtonReload}
                                        className="ml-1"
                                        icon={{name: "far history", size: IconSize.Large}}
                                        type={ButtonType.Info}
                                        onClick={() => this.reloadAsync()}
                                />

                                <Button icon={{name: "plus", size: IconSize.Large}}
                                        type={ButtonType.Orange}
                                        title={Localizer.annualInspectionVendorsPageButtonAddVendor}
                                        onClick={() => this.addAnnualInspectionVendorAsync()}
                                />

                            </Inline>

                        </ToolbarContainer>

                        <Grid id={"vendorsGrid"}
                              key={"vendorsGrid"}
                              ref={this._annualInspectionVendorGridRef}
                              className={styles.vendorsGrid}
                              minWidth={"auto"}
                              hovering={GridHoveringType.Row}
                              odd={GridOddType.None}
                              columns={this._vendorColumns}
                              initRow={(row) => this.initRow(row)}
                              fetchData={() => this.getOwnerLevelVendorDataAsync()}
                              renderDetails={(row) => this.renderDetailsContent(row)}
                        />

                    </div>
                </PageRow>

                <EditVendorModal ref={this._editVendorModalRef}
                                 vendors={this.vendors}
                                 onSubmit={(_, request) => this.saveAnnualInspectionVendorAsync(request)}
                />
            </PageContainer>
        );
    }
}