import ServiceReportType from "@/pages/Models/ServiceReportType";

export default class SaveServiceReportDefinitionRequest {

    public id: string = "";

    public name: string = "";

    public types: ServiceReportType[] = [];

    public generic: boolean = false;

    public restartServiceProgramAfterLastStep: boolean = false;
}