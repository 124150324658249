import styles from "./Banner.module.scss";
import React from "react";

interface IBannerProps {
    closeBanner?: () => Promise<void>;
    text: string;
    id?: string;
    dataCy?: string;
}

const Banner = ({ text, closeBanner, id, dataCy } : IBannerProps) => (
    <div className={styles.banner} id={id} data-cy={dataCy}>
        {
            closeBanner &&
            <span className={styles.closebtn}
                  onClick={async (event) => await closeBanner()}>&times;</span>
        }
        {text}
    </div>
)

export default Banner;