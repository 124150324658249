import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {AddressHelper} from "@renta-apps/athenaeum-react-components";
import {Layout} from "@renta-apps/athenaeum-react-components";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import RentaToolsController from "./pages/RentaToolsController";
import logo from "./renta-logo.png";
import FlagProvider from "@unleash/proxy-client-react";
import UnleashHelper from "@/helpers/UnleashHelper";
// import styles from "./pages/NewUI.module.scss";

class App extends BaseComponent {

    private get xmasLightsEnabled(): boolean {
        const date = new Date();
        return date.getMonth() == 11 && date.getDay() < 26;
    }

    render(): React.ReactNode {
        return (
            <FlagProvider unleashClient={UnleashHelper.client} startClient={false}>
                <Layout useRouting
                        christmasLightsEnabled={this.xmasLightsEnabled}
                        topNavLogo={logo}
                        footerLogo={logo}
                        footerLinks={() => RentaToolsController.footerLinks}
                        onLogoClick={RentaToolsController.onLogoClickAsync}
                        languages={() => RentaToolsController.languages}
                />
            </FlagProvider>
        );
    }
}

//Register Unleash
ch.registerInitializeCallback(async () => await UnleashHelper.initialize());
//Register initialize events
ch.registerInitializeCallback(async () => await AddressHelper.loadGoogleLibraryAsync());
ch.registerInitializeCallback(async () => await RentaToolsController.initializeAsync());
//Register authorize events
ch.registerAuthorizeCallback(async () => await UserInteractionDataStorage.onAuthorize());
ch.registerAuthorizeCallback(async () => await RentaToolsController.authorizeAsync());

export default App;