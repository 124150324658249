class DeviceInfoPanel {
    public elements = {
        productGroupName: () => cy.get("#deviceProductGroup"),
        deviceType: () => cy.get("#deviceType"),
        externalId: () => cy.get("#device_externalId")
    };

    assertProductGroupNameMatch(name: string) {
        this.elements.productGroupName().should('contain', name)
    }

    assertExternalIdMatch(id: string) {
        this.elements.externalId().should('contain', id)
    }

    assertDeviceTypeMatch(type: string) {
        this.elements.deviceType().should('contain', type)
    }
}

export default new DeviceInfoPanel();