import BaseClassifier from "@/models/server/BaseClassifier";

type NullableString = string | null | undefined;

export default class ResourceDefaultValueSelector {
    private readonly _defaultDeviceValueId: NullableString;
    private readonly _defaultDefinitionValueId: NullableString
    private readonly _valuesTypes: BaseClassifier[];

    private constructor(deviceDefaultValueId: NullableString, definitionDefaultValueId: NullableString, valuesTypes: BaseClassifier[]) {
        this._defaultDefinitionValueId = definitionDefaultValueId;
        this._defaultDeviceValueId = deviceDefaultValueId;
        this._valuesTypes = valuesTypes;
    }

    private isSupportedValue(valueId: NullableString): boolean {
        if (!valueId) {
            return false;
        }

        return this._valuesTypes.some(p => p.id === valueId);
    }

    private selectValue(): string {
        if (this.isSupportedValue(this._defaultDeviceValueId)) {
            return this._defaultDeviceValueId!;
        }

        if (this.isSupportedValue(this._defaultDefinitionValueId)) {
            return this._defaultDefinitionValueId!;
        }

        return "";
    }

    public static getResourceDefaultValue(deviceDefaultValueId: NullableString, definitionDefaultValueId: NullableString, valuesTypes: BaseClassifier[]): string {
        const selector = new ResourceDefaultValueSelector(deviceDefaultValueId, definitionDefaultValueId, valuesTypes);

        return selector.selectValue();
    }
}