import Depo from "@/models/server/Depo";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {ButtonType, TextInput} from "@renta-apps/athenaeum-react-components";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import PageDefinitions from "@/providers/PageDefinitions";
import React, {useState} from "react";
import styles from './DepoSelection.module.scss';
import {DeviceListOrderType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
export interface IDepoSelectionProps {
    depos : Depo[];
    orderType : DeviceListOrderType,
}
const DepoSelection = ({ depos, orderType } : IDepoSelectionProps) => {
    const [filterValue, setValue ] = useState<string>("");
    const [currentDepos, setDepos] = useState<Depo[]>(depos);
    
    const onChange = (value : string) => {
        setValue(value);
        setDepos(depos.filter(depo => depo.name?.toLowerCase().includes(value.toLowerCase()) || depo.costPool?.toLowerCase().includes(value.toLowerCase())));
    }
    
    return(
        <div>
                <div className={styles.textInput}>
                    <TextInput 
                        placeholder={Localizer.editDeviceOrderFilterDepo} 
                        onChange={async (sender, value) => onChange(value)} 
                        value={filterValue}
                    />
                </div>
                {currentDepos.map(depo =>
                    <ArsenalButton block big
                                   key={depo.costPool}
                                   id={"depo_" + depo.costPool}
                                   type={ButtonType.Orange}
                                   className={rentaToolsStyles.arsenalButton}
                                   label={depo.name ?? depo.costPool ?? ""}
                                   route={PageDefinitions.editOrderDeviceRoute(depo.id, orderType)}
                    />
                )}
        </div>
    );
}

export default DepoSelection;