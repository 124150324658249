import React, {RefObject, useEffect, useRef, useState} from "react";
import {ch, } from "@renta-apps/athenaeum-react-common";
import InvoicesListFilters from "@/pages/InvoicesPage/InvoicesListFilters/InvoicesListFilters";
import ListInvoicesRequest from "@/models/server/requests/ListInvoicesRequest";
import ReturnInspectionInvoiceFiltersData from "@/models/server/ReturnInspectionInvoiceFiltersData";
import {Button, ButtonType, Icon, IconSize, IconStyle, IIconProps, Modal} from "@renta-apps/athenaeum-react-components";
import Invoice from "@/models/server/Invoice";
import {InvoiceStatusFilter} from "@/models/Enums";
import ToolsUtility from "@/helpers/ToolsUtility";
import Localizer from "@/localization/Localizer";

import styles from "./InvoicesList.module.scss";
import {Utility} from "@renta-apps/athenaeum-toolkit";

interface IInvoicesListProps {
    modalTitle: string;
    noDataText: string;
    request: ListInvoicesRequest;
    filtersData: ReturnInspectionInvoiceFiltersData;
    isManager: boolean;
    fetchData(request?: ListInvoicesRequest): Promise<Invoice[]>;
    onInvoiceOpen(invoice: Invoice): Promise<void>;
}

const InvoicesList = (props: IInvoicesListProps) => {

    const _filtersModalRef = React.createRef<Modal>();
    const _lastElement: RefObject<HTMLDivElement> = React.createRef();

    const id = new Date().getTime();

    const [request, setRequest] = useState<ListInvoicesRequest>(props.request);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<Invoice[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>();
    const observerRef = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        if (isFetching) {
            return;
        }

        setIsFetching(true)

        fetchDataAsync().then(newData => {

            const currentState = data;

            currentState?.push(...newData)

            setData(currentState);

            setIsFetching(false)
        })

        return () => {};
    }, [request]);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(async (entries) => {

            if (entries[0].isIntersecting && !isFetching && haveMoreDataToLoad()) {

                props.request.pageNumber = props.request.pageNumber + 1;

                const req = getRequest();
                setRequest(req);
            }
        });
    }, []);

    useEffect(() => {
        if (!!observerRef.current && !!_lastElement.current) {
            observerRef.current.observe(_lastElement.current);

            return () => {
                observerRef.current!.disconnect();
            };
        }
    }, [_lastElement, [data]]);

    const haveMoreDataToLoad = (): boolean => {
        return (request.pageSize * (props.request.pageNumber + 1)) === data?.length!;
    }

    const getRequest = (): ListInvoicesRequest => {
        const req = new ListInvoicesRequest();
        req.pageNumber = props.request.pageNumber + 1;
        req.from = props.request.to;
        req.to = props.request.to;
        req.pageSize = props.request.pageSize;
        req.constructionSiteExternalId = props.request.constructionSiteExternalId;
        req.constructionSiteName = props.request.constructionSiteName;
        req.contractExternalId = props.request.contractExternalId;
        req.customerExternalId = props.request.customerExternalId;
        req.customerName = props.request.customerName;
        req.depotsIds = props.request.depotsIds;
        req.deviceExternalId = props.request.deviceExternalId;
        req.includeDeleted = props.request.includeDeleted;
        req.inspectorIds = props.request.inspectorIds;
        return req;
    }

    const openFiltersAsync = async (): Promise<void> => {
        await _filtersModalRef.current!.openAsync();
    }

    const submitFiltersAsync = async (newRequest: ListInvoicesRequest): Promise<void> => {
        setData([])

        const req = {
            ...newRequest,
            pageNumber: 0,
        } as ListInvoicesRequest;

        setRequest(req);
    }

    const onItemClickAsync = async (invoice: Invoice): Promise<void> => {
        await props.onInvoiceOpen(invoice);
    }

    const getStateIcon = (invoice: Invoice): IIconProps => {
        if (invoice.approved) {
            return {name: "far thumbs-up", className: "text-success", tooltip: Localizer.inspectionInvoicesListStateTooltipApproved};
        }
        if (invoice.declined) {
            return {name: "far thumbs-down", className: "text-danger", tooltip: Localizer.inspectionInvoicesListStateTooltipDeclined};
        }
        return {name: "fas hourglass-start", className: "orange", tooltip: Localizer.inspectionInvoicesListStateTooltipInProcess};
    }

    const getInvoiceRowDate = (invoice: Invoice): string => {
        if (props.request.status == InvoiceStatusFilter.Approved) {
            return ToolsUtility.toDateString(invoice.approvedAt);
        } else if (props.request.status == InvoiceStatusFilter.Declined) {
            return ToolsUtility.toDateString(invoice.declinedAt);
        }

        return ToolsUtility.toDateString(invoice.createdAt);
    }

    const fetchDataAsync = async (): Promise<Invoice[]> => {
        return props.fetchData(request);
    }

    const getIconName = (): string => {
        const isEmpty: boolean = ToolsUtility.isNullOrEmpty(request.customerName)
            && ToolsUtility.isNullOrEmpty(request.deviceExternalId)
            && ToolsUtility.isNullOrEmpty(request.constructionSiteName)
            && ToolsUtility.isNullOrEmpty(request.customerExternalId)
            && ToolsUtility.isNullOrEmpty(request.constructionSiteExternalId)
            && ToolsUtility.isNullOrEmpty(request.customerName)
            && (!request.depotsIds || request.depotsIds.length == 0)
            && (!request.inspectorIds || request.inspectorIds.length == 0)
            && request.includeDeleted == null
            && !request.from
            && !request.to;

        return isEmpty
            ? "far filter"
            : "fas filter";
    }

    const renderRow = (invoice: Invoice, index: number): React.ReactNode => {
        const customerName: string = (invoice.deviceContract)
            ? invoice.deviceContract.customerName || "-"
            : "-";

        const constructionSiteName: string = (invoice.deviceContract)
            ? invoice.deviceContract.constructionSiteName || "-"
            : "-";

        const customerExternalContractId: string = (invoice.deviceContract)
            ? invoice.deviceContract.contractExternalId || "-"
            : "-";

        const deviceExternalId: string = (invoice.device)
            ? invoice.device.externalId || "-"
            : "-";

        const deviceType: string = (invoice.device)
            ? invoice.device.type || "-"
            : "-";

        return (
            <div key={index}
                 id={`listItem_${deviceExternalId}_${index}`}
                 className={Utility.css(styles.listItem, "cursor-pointer", invoice.deleted && styles.deleted)}
                 ref={index === data?.length! - 1 ? _lastElement : undefined}
                 onClick={async () => await onItemClickAsync(invoice)}>

                <div className={styles.left}>
                    <div className={Utility.css(styles.multiLines, styles.topSpace)}>
                        <span>{deviceExternalId} {deviceType}</span>
                        <span>{customerName}</span>
                        <span>{constructionSiteName}</span>
                        <span>{customerExternalContractId}</span>
                    </div>
                </div>

                <div className={(!ch.mobile) ? styles.resourceTypes : ""}>

                    <span className={styles.value}>{(invoice.fuelAmount != null) ? "{0:0.0} l".format(invoice.fuelAmount) : "-"}</span>

                    <span className={styles.value}>{(invoice.washingTime != null) ? "{0:0.0} h".format(invoice.washingTime) : "-"}</span>

                    <span className={styles.value}>{(invoice.adBlueAmount != null) ? "{0:0.0} l".format(invoice.adBlueAmount) : "-"}</span>

                </div>

                {
                    (invoice.additionalExpensesCount > 0) &&
                    (
                        <div className={"text-center"}>

                            <span>{invoice.additionalExpensesCount}</span>

                            <Icon name={"fal fa-boxes"}
                                  style={IconStyle.Duotone}
                                  size={IconSize.Large}
                            />

                        </div>
                    )
                }

                <div className={styles.icons}>

                    <Icon {...getStateIcon(invoice)} size={IconSize.Large}/>

                </div>

                <span>{getInvoiceRowDate(invoice)}</span>

            </div>
        )
    }

    return (
        <div className={Utility.css(styles.returnInspectionInvoicesList)}>

            <div>
                <div className={Utility.css(styles.header)}>
                            <span>

                            </span>

                    <Button small
                            id={`invoices_list_filter_button_${id}`}
                            icon={{name: getIconName(), size: IconSize.X2}}
                            type={ButtonType.Blue}
                            className={Utility.css(styles.filter)}
                            onClick={async () => await openFiltersAsync()}
                    />

                </div>
            </div>

            {
                ((data == null) || (data.length == 0)) &&
                (
                    <div className={Utility.css(styles.listItem, styles.noItems)}>
                        {props.noDataText}
                    </div>
                )
            }

            {
                (data) &&
                (
                    data.map((item, index) => renderRow(item, index))
                )
            }

            <InvoicesListFilters modalRef={_filtersModalRef}
                                 isManager={props.isManager}
                                 title={props.modalTitle}
                                 request={request}
                                 filtersData={props.filtersData}
                                 onSubmit={async (request) => await submitFiltersAsync(request)}
            />

        </div>
    );
}

export default InvoicesList;