import User from "@/models/server/User";
import BaseNotificationSetting from "@/models/server/notification/BaseNotificationSetting";

export default class UserNotificationSetting extends BaseNotificationSetting {
    public id: string | null = null;

    public userId: string = "";

    public user: User = new User();

    public custom: boolean = false;

    public pausedFrom: Date | null = null;

    public pausedUntil: Date | null = null;

    public lastOccurrence: Date | null = null;

    public isUserNotificationSetting: boolean = true;
}