import React from "react";
import {AlertModel, AlertType, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Alert, ButtonType} from "@renta-apps/athenaeum-react-components";
import NewSummaryTable from "@/components/NewSummaryTable/NewSummaryTable";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import FuelType from "@/models/server/FuelType";
import WashingType from "@/models/server/WashingType";
import PageDefinitions from "@/providers/PageDefinitions";
import Report from "@/pages/Models/Report";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import RentaToolsController from "@/pages/RentaToolsController";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import newStyles from "../NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

interface ISummaryPageProps {
}

interface ISummaryPageState extends IReturnInspectionWizardPageState {
    fuelTypes: FuelType[],
    washingTypes: WashingType[]
}

export default class SummaryPage extends ReturnInspectionWizardPage<ISummaryPageProps, ISummaryPageState> {

    state: ISummaryPageState = {
        fuelTypes: [],
        washingTypes: []
    };

    protected override get showExitButton(): boolean {
        return UnleashHelper.isEnabled(RentaToolsConstants.featureReturnInspectionSaveButtonEnabled);
    }

    private async saveAsync(): Promise<void> {

        if (this.canSaveReport) {
            await ReturnInspectionController.saveReturnInspection();
        }
    }

    protected showNextButton(): boolean {
        return this.report.items.filter(p => !p.completed).length > 0;
    }

    public getManual(): string {
        return Localizer.summaryPageManual;
    }

    public async nextAsync(): Promise<void> {
        const result = this.report.items.filter(p => !p.completed);
        const index = this.report.items.indexOf(result[0]);

        await ReturnInspectionController.openStepAsync(index);
    }

    public override async initializeAsync(): Promise<void> {
        super._needToProcessOnLeave = false;

        await super.initializeAsync();

        let washingTypes: WashingType[] = await RentaToolsController.getWashingTypesAsync();
        let fuelTypes: FuelType[] = await RentaToolsController.getFuelTypesAsync();

        await this.setState({washingTypes, fuelTypes});

        if (this.preview) {
            await PageRouteProvider.redirectAsync(PageDefinitions.reportPreviewRoute);
        }
    }

    protected override async exit(): Promise<void> {
        await ReturnInspectionController.stopReturnInspectionAsync(this.deviceId, this.report.items);

        await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
    }

    public get canSaveReport(): boolean {
        return Report.isValid(this.report) &&
            this.report.items.filter(p => p.completed).length === this.report.items.length &&
            this.isAdditionalExpensesValid(this.report.items);
    }

    public get alert(): AlertModel {
        const stepsWithInvalidExpenses: string = this.report
            .items
            .where(item =>
                (item.additionalExpenseValues != null && item.additionalExpenseValues.length > 0) &&
                (!this.isAdditionalExpensesValid(item)))
            .selectMany(item => item.additionalExpenseValues)
            .where(item => item.returnInspectionStepNumber != null)
            .distinct(item => item.returnInspectionStepNumber)
            .map(item => `#${item.returnInspectionStepNumber! + 1}`)
            .join(', ');

        const message: string = (stepsWithInvalidExpenses.length > 0)
            ? Localizer.get(Localizer.summaryPageAlertModelMessageInvalidAdditionalExpensesWithSteps, stepsWithInvalidExpenses)
            : Localizer.summaryPageAlertModelMessageInvalidAdditionalExpenses

        const alertModel = new AlertModel();
        alertModel.message = message;
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Danger;

        return alertModel;
    }

    public renderContent(): React.ReactNode {

        return (
            <React.Fragment>

                <ArsenalPageRow className={newStyles.summaryPage}>

                    {
                        (!this.isAdditionalExpensesValid(this.report.items)) &&
                        (
                            <div className={this.css(newStyles.summaryAlert, this.desktop && newStyles.desktop)}>
                                <div>
                                    <Alert model={this.alert}/>
                                </div>
                            </div>
                        )
                    }

                    <NewSummaryTable noFooter
                                     className={newStyles.table}
                                     model={this.report}
                                     washingTypes={this.state.washingTypes}
                                     fuelTypes={this.state.fuelTypes}
                                     additionalExpensesValid={(item: ReportDefinitionItem) => this.isAdditionalExpensesValid(item)}
                    />

                </ArsenalPageRow>

                <div className={rentaToolsStyles.mb20}>
                    <div className={this.css(rentaToolsStyles.actionWrapper)}>
                        <div className={this.css(rentaToolsStyles.actionContainer)}>
                            <ArsenalButton fullWidth
                                           id={"save_return_inspection"}
                                           type={ButtonType.Orange}
                                           label={Localizer.summaryPageSaveReturnInspectionButton}
                                           disabled={!this.canSaveReport}
                                           onClick={async () => await this.saveAsync()}
                                           className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}/>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}