import BaseListDevicesRequest from "./BaseListDevicesRequest";
import DeviceInfo from "../DeviceInfo";
import { DeviceStatus } from "@/models/Enums";

export default class ListInspectedDevicesRequest extends BaseListDevicesRequest {

    public deviceStatuses: DeviceStatus[] = [];

    public getDate(device: DeviceInfo): Date | null {
        return device.lastReturnReportDate;
    }

    public isEmpty(): boolean {
        return (this.deviceStatuses.length == 0) && (super.isEmpty());
    }

    public clear(): void {
        this.deviceStatuses = [];
        super.clear();
    }
}