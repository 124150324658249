import React from "react";
import {
    PageContainer,
    PageHeader,
    PageRow, Tab, TabContainer, TabContainerHeaderStyleType, TabRenderType,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import AdBluesData from "@/pages/ExpensesPage/AdBluesData/AdBluesData";
import FuelTypesData from "@/pages/ExpensesPage/FuelTypesData/FuelTypesData";
import WashingTypesData from "@/pages/ExpensesPage/WashingTypesData/WashingTypesData";
import AdditionalExpensesData from "@/pages/ExpensesPage/AdditionalExpensesData/AdditionalExpensesData";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ExpensesPage.module.scss";

interface IExpensesPageProps {
}

interface IExpensesPageState {
}

export default class ExpensesPage extends AuthorizedPage<IExpensesPageProps, IExpensesPageState> {

    state: IExpensesPageState = {};

    private readonly _adBluesDataRef: React.RefObject<AdBluesData> = React.createRef();
    private readonly _fuelTypesDataRef: React.RefObject<FuelTypesData> = React.createRef();
    private readonly _washingTypesDataRef: React.RefObject<WashingTypesData> = React.createRef();
    private readonly _additionalExpensesDataRef: React.RefObject<AdditionalExpensesData> = React.createRef();

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={styles.expensesPage}>

                <PageHeader title={Localizer.additionalExpensesPageTitle}/>

                <PageRow>

                    <div className="col">

                        <TabContainer id={"expensesTabContainer"}
                                      renderType={TabRenderType.Once}
                                      headerStyleType={TabContainerHeaderStyleType.Underline}
                        >

                            <Tab id={"adBluesDataTab"}
                                 className={styles.expensesTab}
                                 title={Localizer.adBluesPageTitle}>

                                <AdBluesData ref={this._adBluesDataRef}/>

                            </Tab>

                            <Tab id={"fuelTypesDataTab"}
                                 className={styles.expensesTab}
                                 title={Localizer.fuelTypesPageTitle}>

                                <FuelTypesData ref={this._fuelTypesDataRef}/>

                            </Tab>

                            <Tab id={"washingTypesDataTab"}
                                 className={styles.expensesTab}
                                 title={Localizer.washingTypesPageTitle}>

                                <WashingTypesData ref={this._washingTypesDataRef}/>

                            </Tab>

                            <Tab id={"additionalExpensesDataTab"}
                                 className={styles.expensesTab}
                                 title={Localizer.expensesPageTabAdditionalExpenses}>

                                <AdditionalExpensesData ref={this._additionalExpensesDataRef}/>

                            </Tab>

                        </TabContainer>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}