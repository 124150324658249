import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

class DeviceServicePage {
    public elements = {
        pageContainer: () => cy.get("[id=save_page_container]"),
        actionCheckBox: () => cy.get('#action_checkBox_0'),
        saveServiceButton: () => cy.get('#saveServiceBtn'),
        backButton: () => cy.get('#backBtn'),
        faults: () => cy.get('[data-cy=faultCheck]'),
        operatingHoursInput: () => cy.get('#input_operating_hours'),
        serviceDateInput: () => cy.get('#serviceDateInput'),
        alert: () => cy.get('.athenaeum-alert-message'),
        dateSelection: () => cy.get('.react-datepicker__month'),
        serviceTypeDropdown: () => cy.get('[id=serviceTypeDropdown]'),
        serviceDurationInput: () => cy.get('[id=service_duration]'),
        repairDurationInput: () => cy.get('[id=repair_duration]'),
        repairCheckBox: () => cy.get('[id=repair_checkbox]').find('div').first(),
    };

    public routes = {
        saveService: {
            path: "/api/report/saveService",
            method: "POST",
        } as RouteData
    };

    public saveServiceWithAction() {
        this.elements.actionCheckBox().contains('Test service action 1').click();
        this.save();
    }

    public save() {
        this.elements.saveServiceButton().trigger('click');
        executeWithIntercept(() =>
                cy.contains(Localizer.yes).should('be.visible').trigger('click'),
            [this.routes.saveService]);
    }

    public saveRepairWithAllFaultsFixed() {
        this.chooseAllFaults();
        this.save();
    }

    public chooseAllFaults() {
        this.elements.faults().each(($el, index, $list) => {
            cy.wrap($el).click();
        })
    }

    public setOperatingHours(value: string) {
        this.elements.operatingHoursInput().type('{selectAll}').type(value);
    }

    public setServiceDate(day: string) {
        this.elements.serviceDateInput().trigger('click');
        this.elements.dateSelection().contains(day).realClick();
    }

    public alertIsShown() {
        this.elements.alert().should('be.visible');
    }

    public goBack() {
        this.elements.backButton().should('be.visible').realClick();
    }

    public selectServiceType(serviceType: string) {
        cy.get('[id=serviceTypeDropdown]').selectDropdownValue(serviceType, false);
    }
}

export default new DeviceServicePage();
