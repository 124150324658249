import React from "react";
import {Button, ButtonType, Dropdown, DropdownRequiredType, Form, Modal, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabContainerHeaderStyleType} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Mapping from "../Models/Mapping";
import Category from "../Models/Category";
import AddCategoryMappingRequest from "../../models/server/requests/AddCategoryMappingRequest";
import DeleteCategoryMappingRequest from "../../models/server/requests/DeleteCategoryMappingRequest";
import MappingsPanel from "@/pages/MappingsPage/MappingsPanel/MappingsPanel";
import GetMappingsResponse from "@/models/server/responses/GetMappingsResponse";
import {ReportDefinitionType} from "@/models/Enums";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./MappingsPage.module.scss";

interface IMappingsPageProps {
}

interface IMappingsPageState {
    categories: Category[];
    returnInspectionMappings: Mapping[],
    serviceMappings: Mapping[],
    deviceTypes: string[],
    selectedDeviceTypes: string[],
    request: AddCategoryMappingRequest | null,
    specificTypeMappingExistsInMapping: boolean,
    deviceTypesInGroup: string[],
    allTypesMapped: boolean,
    requireTypeSelection: boolean,
    groupIsNotMapped: boolean
}

export default class MappingsPage extends AuthorizedPage<IMappingsPageProps, IMappingsPageState> {

    state: IMappingsPageState = {
        returnInspectionMappings: [],
        serviceMappings: [],
        categories: [],
        deviceTypes: [],
        selectedDeviceTypes: [],
        request: null,
        specificTypeMappingExistsInMapping: false,
        deviceTypesInGroup: [],
        allTypesMapped: false,
        requireTypeSelection: false,
        groupIsNotMapped: false
    };

    private readonly _addMappingModal: React.RefObject<Modal> = React.createRef();
    private readonly _addMappingModalDropdown: React.RefObject<Dropdown<string>> = React.createRef();

    private async loadDataAsync(): Promise<void> {

        const response: GetMappingsResponse = await RentaToolsController.getMappingsAsync();

        const categories: Category[] = response.categories;

        const returnInspectionMappings: Mapping[] = response.returnInspectionMappings;

        const serviceMappings: Mapping[] = response.serviceMappings;

        await this.setState({
            categories,
            returnInspectionMappings,
            serviceMappings,
            selectedDeviceTypes: [],
            request: null
        });
    }

    private async deleteCategoryAsync(mapping: Mapping, category: Category): Promise<void> {
        const request = new DeleteCategoryMappingRequest();
        request.reportDefinitionId = mapping.reportDefinitionId!;
        request.productGroupId = category.productGroupId;
        request.productType = category.productType;
        await RentaToolsController.deleteCategoryMapping(request);
        await this.loadDataAsync();
    }

    private async saveChangesAsync(): Promise<void> {
        if (this.state.request) {
            this.state.request.productTypes = this.state.selectedDeviceTypes;
            await RentaToolsController.addCategoryMapping(this.state.request!);
        }

        if (this._addMappingModal.current) {
            await this._addMappingModal.current.closeAsync()
        }

        await this.loadDataAsync();
    }

    private async addCategoryAsync(mapping: Mapping, category: Category, reportDefinitionType: ReportDefinitionType): Promise<void> {
        const request = new AddCategoryMappingRequest();

        request.reportDefinitionId = mapping.reportDefinitionId!;

        request.productGroupId = category.productGroupId;

        const deviceTypesInGroup: string[] = await RentaToolsController.getDeviceTypesAsync(category.productGroupId);

        let mappingsToCheck: Mapping[] = (reportDefinitionType == ReportDefinitionType.ReturnInspection)
            ? this.state.returnInspectionMappings
            : this.state.serviceMappings

        const deviceTypes: string[] = deviceTypesInGroup.filter(type => !mappingsToCheck.some(item => item.categories.some(category => category.productType == type && category.productGroupId == request.productGroupId)))

        const wholeGroupMapped: boolean = mappingsToCheck.some(mapping => mapping.categories.some(category => category.productGroupId == request.productGroupId && category.productType == null));

        const specificTypeMappingExistsInMapping: boolean = mapping.categories.some(category => category.productGroupId == request.productGroupId && category.productType != null);

        const requireTypeSelection: boolean = wholeGroupMapped || specificTypeMappingExistsInMapping;

        const groupIsNotMapped: boolean = deviceTypesInGroup.length === 0 && !wholeGroupMapped;

        const allTypesMapped: boolean = !deviceTypes.length || (deviceTypesInGroup.length === 1 && wholeGroupMapped);

        await this.setState({
            deviceTypes,
            request,
            specificTypeMappingExistsInMapping,
            deviceTypesInGroup,
            requireTypeSelection,
            allTypesMapped,
            groupIsNotMapped,
        });

        if (this._addMappingModal.current) {
            await this._addMappingModal.current.openAsync();
        }

    }

    public getManual(): string {
        return Localizer.mappingsPageManual;
    }

    public get deviceTypes(): string[] {
        return this.state.deviceTypes;
    }

    private async setSelectedDeviceTypes(selectedDeviceTypes: string[]): Promise<void> {
        await this.setState({selectedDeviceTypes});
    }

    public get getConfirmTitle(): string {
        const mappings: Mapping[] = this.state.returnInspectionMappings
            .filter(a => a.reportDefinitionId == this.state.request?.reportDefinitionId);

        const serviceMappings: Mapping[] = this.state.serviceMappings
            .filter(a => a.reportDefinitionId == this.state.request?.reportDefinitionId);

        if (mappings.length && mappings[0].reportDefinitionName) {
            const reportDefinitionName: string = mappings[0].reportDefinitionName
            return Localizer.get(Localizer.mappingsPageAddConfirm, this.state.request?.productGroupId, reportDefinitionName.toUpperCase());
        }

        if (serviceMappings.length && serviceMappings[0].reportDefinitionName) {
            const reportDefinitionName: string = serviceMappings[0].reportDefinitionName
            return Localizer.get(Localizer.mappingsPageAddConfirm, this.state.request?.productGroupId, reportDefinitionName.toUpperCase());
        }

        return "";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.loadDataAsync();
    }

    private async selectAll() {
        if (!this._addMappingModalDropdown.current) {
            return;
        }

        await this.setSelectedDeviceTypes(this._addMappingModalDropdown.current.items);
        return;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullWidth
                           alertClassName={rentaToolsStyles.alert}
                           className={styles.reportMappings}
            >

                <PageHeader title={Localizer.mappingsPageTitle} subtitle={""}/>

                <PageRow>

                    <div className="col">

                        <TabContainer headerStyleType={TabContainerHeaderStyleType.Underline}>

                            <Tab id="returnInspections" title={Localizer.mappingsPageTabReturnInspections}>
                                <MappingsPanel inspections
                                               mappings={this.state.returnInspectionMappings}
                                               availableCategories={this.state.categories}
                                               addCategoryAsync={async (mapping: Mapping, category: Category) => await this.addCategoryAsync(mapping, category, ReportDefinitionType.ReturnInspection)}
                                               deleteCategoryAsync={async (mapping: Mapping, category: Category) => await this.deleteCategoryAsync(mapping, category)}
                                />

                            </Tab>

                            <Tab id="services" title={Localizer.mappingsPageTabServices}>
                                <MappingsPanel mappings={this.state.serviceMappings}
                                               availableCategories={this.state.categories}
                                               addCategoryAsync={async (mapping: Mapping, category: Category) => await this.addCategoryAsync(mapping, category, ReportDefinitionType.Service)}
                                               deleteCategoryAsync={async (mapping: Mapping, category: Category) => await this.deleteCategoryAsync(mapping, category)}
                                />
                            </Tab>

                            <Modal ref={this._addMappingModal}
                                   title={this.getConfirmTitle}
                                   subtitle={``}
                                   onClose={async () => await this.loadDataAsync()}
                            >

                                {
                                    (this.state.deviceTypesInGroup.length == 1) &&
                                    (
                                        <Form onSubmit={() => this.saveChangesAsync()}>
                                            <Button submit
                                                    id={"add_mapping_submit"}
                                                    disabled={this.state.allTypesMapped}
                                                    minWidth={90}
                                                    label={Localizer.genericSave}
                                                    type={ButtonType.Orange}
                                            />
                                        </Form>
                                    )
                                }

                                {
                                    (this.state.deviceTypesInGroup.length > 1 && !this.state.allTypesMapped) &&
                                    (
                                        <Form onSubmit={() => this.saveChangesAsync()}>
                                            <Dropdown multiple
                                                      id={"device_type_dropdown"}
                                                      ref={this._addMappingModalDropdown}
                                                      required={this.state.requireTypeSelection}
                                                      requiredType={(this.state.requireTypeSelection ? DropdownRequiredType.Restricted : undefined)}
                                                      label={(this.state.requireTypeSelection) ? Localizer.mappingsPageAddTypes : Localizer.mappingsPageAddOptionalTypes}
                                                      nothingSelectedText={"-"}
                                                      items={this.state.deviceTypes}
                                                      selectedItems={this.state.selectedDeviceTypes}
                                                      onChange={async (_) => {
                                                          await this.setSelectedDeviceTypes(_.selectedItems)
                                                      }}
                                            />

                                            <Button submit
                                                    id={"add_mapping_submit"}
                                                    minWidth={90}
                                                    label={Localizer.genericSave}
                                                    type={ButtonType.Orange}
                                            />

                                            <Button id={"select_all"}
                                                    minWidth={90}
                                                    label={Localizer.genericSelectAll}
                                                    onClick={async () => {
                                                        await this.selectAll()
                                                    }}
                                                    type={ButtonType.Blue}
                                            />
                                        </Form>
                                    )
                                }
                                {
                                    (this.state.deviceTypesInGroup.length === 0 && this.state.groupIsNotMapped) &&
                                    <Form onSubmit={() => this.saveChangesAsync()}>
                                        <Button submit
                                                id={"add_mapping_submit"}
                                                minWidth={90}
                                                label={Localizer.genericSave}
                                                type={ButtonType.Orange}
                                        />
                                    </Form>
                                }
                                {
                                    (this.state.allTypesMapped && !this.state.groupIsNotMapped) &&
                                    (
                                        <span>
                                            {Localizer.mappingsPageAllDeviceTypesMapped}
                                        </span>
                                    )
                                }

                            </Modal>
                        </TabContainer>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}