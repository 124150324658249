export default class AddDepoRequest {
    public name: string = "";

    public costPool: string = "";

    public formattedAddress: string | null = null;

    public phone: string | null = null;
    
    public email: string | null = null;
}