class SavePage {
    public elements = {
        pageContainer: () => cy.get("[data-cy=text_container]"),
        goToDevicePage: () => cy.get("[id=go_to_device_page]"),
        goToFrontPage: () => cy.get("[id=go_to_front_page]"),
    };
    
    public resultShouldBe(localizer : string){
        this.elements.pageContainer().contains(localizer);
    }
    
    public goToDevicePage(){
        this.elements.goToDevicePage().should("be.visible").realClick();
    }    
    
    public goToFrontPage(){
        this.elements.goToFrontPage().should("be.visible").realClick();
    }
}

export default new SavePage();
