import {ApplicationContext, IUserContext} from "@renta-apps/athenaeum-react-common";
import User from "./User";
import {UserRole} from "../Enums";
import ApplicationSettings from "@/models/server/ApplicationSettings";


export default class UserContext extends ApplicationContext<ApplicationSettings> implements IUserContext<ApplicationSettings, User> {
    public username: string = "";

    public email: string | null = null;

    public phone: string | null = null;

    public user: User | null = null;

    public Role: UserRole | null = null;

    public isAdmin: boolean = false;

    public isAreaManager: boolean = false;

    public isDepoManager: boolean = false;

    public isUserContext: true = true;

    public dateFormat: string | null = null;

    public IsAutomatedTestEnv : boolean  = false;

    public isLocalDevelopment : boolean = true;

    public static canEditServices(context: UserContext, depoId: string | null): boolean {
        return context.user != null && (
            (context.isAdmin || context.isDepoManager)
            || (context.isAreaManager && User.hasDepoAccess(context.user, depoId))
        );
    }

    public static canEditAnnualInspectionRecord(context: UserContext): boolean {
        return context.user != null && (context.isAdmin || context.isDepoManager);
    }

    public static canUserModifyAnnualInspectionRecords(context: UserContext): boolean {
        return context.user != null && (context.isAdmin || context.isDepoManager || !context.isAreaManager && context.user.canEditOwnAnnualInspections);
    }
}