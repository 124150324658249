import Localizer from "../../../localization/Localizer";
import InvoicesPage from "@/tests/pages/InvoicesPage";
import invoicesFilterModal from "@/tests/pages/InvoicesFilterModal";
import InvoicesFilterModal from "@/tests/pages/InvoicesFilterModal";

describe('Invoice page tests', () => {

    beforeEach(() => {
        cy.login();
        cy.visit('/')
        cy.goToDashboard();
        cy.goToInvoicesPage();
    });

    it('Assert invoices page content', () => {
        assertHeader();
        assertFilters();
    });


    function assertFilters() {

        InvoicesPage.elements.filterBtn().click();

        cy.get("h5")
            .should('contain', Localizer.invoicesPageModalTitleFilters)

        invoicesFilterModal.elements.inputDeviceExternalId().should('exist');

        InvoicesFilterModal.elements.inputStatus()
            .should('exist');

        InvoicesFilterModal.elements.inputDepo()
            .should('exist');

        InvoicesFilterModal.elements.inputContractId()
            .should('exist');

        InvoicesFilterModal.elements.inputInspectorId()
            .should('exist');

        InvoicesFilterModal.elements.inputCustomerId()
            .should('exist');

        InvoicesFilterModal.elements.inputCustomerName()
            .should('exist');

        InvoicesFilterModal.elements.inputConstructionSiteExternalId()
            .should('exist');

        InvoicesFilterModal.elements.inputConstructionSiteName()
            .should('exist');

        InvoicesFilterModal.elements.inputFrom()
            .should('exist');

        InvoicesFilterModal.elements.form().first().submit();
    }

    function assertHeader() {
        InvoicesPage.elements.title().should('contain', Localizer.invoicesPageHeaderTitle.toUpperCase());
    }
});