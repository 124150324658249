
export default class ServiceType {
    public id: string = "";

    public name: string = "";

    public description: string | null = null;

    public isDeleted: boolean = false;

    public isServiceType: boolean = true;
}