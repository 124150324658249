import {ExpenseUnit} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

export default class InvoiceAdditionalExpense {
    public id: string = "";

    public typeId: string = "";

    public invoiceId: string = "";

    public name: string = "";

    public externalId: string = "";

    public value: number = 0;

    public invoiced: boolean = false;

    public isCustom: boolean = false;

    public unit: ExpenseUnit = ExpenseUnit.Piece;

    public price: number | null = null;

    public returnInspectionStepNumber: number | null = null;

    public isInvoiceAdditionalExpense: true = true;

    public static isValid(invoiceExpense: InvoiceAdditionalExpense): boolean {
        return (!!invoiceExpense.typeId &&
            invoiceExpense.typeId != RentaToolsConstants.defaultGuid &&
            invoiceExpense.externalId.trim().length > 0 &&
            invoiceExpense.value > 0);
    }

    public static readyForApproval(invoiceExpense: InvoiceAdditionalExpense): boolean {
        return (this.isValid(invoiceExpense) &&
            invoiceExpense.invoiced);
    }
}