import {ServiceLocationType} from "@/models/Enums";

export default class EditServiceInvoiceDetailsRequest {

    public serviceId: string = "";

    public invoiceNumber: string = "";

    public cost: number = 0;

    public operatingHours: number | null = null;

    public depoId: string | null = null;

    public serviceExpenseId: string | null = null;

    public comment: string | null = null;

    public locationType: ServiceLocationType | null = null;

    public date: Date = new Date();

    public deviceId: string | null = null;
}