import React from "react";
import moment from "moment";
import {
    ButtonContainer,
    ButtonType,
    DateInput,
    Dropdown,
    Icon,
    IconSize,
    IconStyle,
    ImageInput,
    Modal,
    PageContainer,
    PageHeader,
    PageRow,
    TextAreaInput,
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import PageDefinitions from "@/providers/PageDefinitions";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {ch, PageRouteProvider,} from "@renta-apps/athenaeum-react-common";
import RecordObservationRequest from "@/models/server/requests/RecordObservationRequest";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import DeviceContract from "@/models/server/DeviceContract";
import DeviceInfo, {Fault} from "@/models/server/DeviceInfo";
import ToolsUtility from "@/helpers/ToolsUtility";
import {DeviceStatus} from "@/models/Enums";
import SpeechRecognitionTextInput from "@/components/SpeechRecognitionTextInput/SpeechRecognitionTextInput";
import RentaToolsController from "@/pages/RentaToolsController";
import FileService from "@/services/FileService";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "../../localization/Localizer";

import styles from "@/pages/RecordObservationPage/RecordObservationPage.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import RentaToolsStorage, {RentaToolsStorageTable,} from "@/pages/RentaToolsStorage";

interface IRecordObservationPageProps {}

interface IRecordObservationPageState {
    pictures: FileModel[];
    comment: string;
    faultSelected: boolean;
    observationDate: Date | null;
    deviceContracts: DeviceContract[];
    selectedDeviceContract: DeviceContract | null;
    latitude: number | null;
    longitude: number | null;
    faults: Fault[];
}

export default class RecordObservationPage extends AuthorizedPage<IRecordObservationPageProps, IRecordObservationPageState> {

    state: IRecordObservationPageState = {
        pictures: [],
        comment: "",
        faultSelected: false,
        observationDate: moment().toDate(),
        deviceContracts: [],
        selectedDeviceContract: null,
        latitude: null,
        longitude: null,
        faults: [],
    };

    private readonly _pageInfoModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _commentRef: React.RefObject<TextAreaInput> = React.createRef();

    private get pictures(): FileModel[] {
        return this.state.pictures;
    }

    private get comment(): string {
        return this.state.comment || "";
    }

    private get observationDate(): Date {
        if (this.state.observationDate) {
            return this.state.observationDate;
        }
        return moment().toDate();
    }

    private get canSave(): boolean {
        let faultsValid = false;
        if (this.state.faults.length > 0) {
            faultsValid = !this.state.faults.some(fault => ToolsUtility.isNullOrEmpty(fault.faultText));
        }
        return faultsValid || ((this.pictures != null && this.pictures.length > 0) || !ToolsUtility.isNullOrEmpty(this.comment));
    }

    private get pageInfoModal(): Modal {
        return this._pageInfoModalRef.current!;
    }

    protected get customInfoModal(): boolean {
        return true;
    }

    private get device(): DeviceInfo {
        return RentaToolsController.device!;
    }

    private get deviceContracts(): DeviceContract[] {
        return this.state.deviceContracts;
    }

    private get deviceContract(): DeviceContract | null {
        return this.state.selectedDeviceContract;
    }

    private get selectedDeviceContractIdForManualInvoice(): string | null {
        if (this.deviceContract) {
            return this.deviceContract.contractExternalId;
        }

        return null;
    }

    public getManual(): string {
        return Localizer.dashboardPageGetManual;
    }

    public get warnDeviceContract(): boolean {
        return this.device.status !== DeviceStatus.InRent || ToolsUtility.isNullOrEmpty(this.device.currentContractExternalId);
    }

    private get addFaultLabel(): string {
        return this.state.faults?.length > 0 ? Localizer.genericAddAnotherFault : Localizer.genericAddFault;
    }

    public manualPropsCallback(): Promise<void> {
        return this.pageInfoModal.openAsync();
    }

    private async setCommentAsync(comment: string): Promise<void> {
        this.setState({comment});

        await this.persistState();
    }

    private async setFaultCommentAsync(index: number, comment: string): Promise<void> {

        const faults: Fault[] = this.state.faults;
        if (index >= faults.length) {
            return;
        }

        const fault: Fault = faults[index];
        fault.faultText = comment;

        this.setState({faults});

        await this.persistState();
    }

    private async persistState(): Promise<void> {
        const deviceId: string = this.device.externalId;
        await RentaToolsStorage.setDataAsync(RentaToolsStorageTable.Observations, `observation_state_${deviceId}`, this.state);
    }

    private async setDateAsync(date: Date): Promise<void> {
        this.setState({observationDate: date});
        await this.persistState();
    }

    private async onClosePageInfoModalAsync(): Promise<void> {
        await this.pageInfoModal.closeAsync();
    }

    public async saveAsync(): Promise<void> {
        const request = new RecordObservationRequest();
        request.deviceId = RentaToolsController.context.device!.id;
        request.pictures = this.state.pictures;
        request.comment = this.state.comment;
        request.depoId = RentaToolsController.device!.depoId;
        request.latitude = this.state.latitude;
        request.longitude = this.state.longitude;

        const manualFaultEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagManualFaultsEnabled);
        if (this.state.faultSelected && manualFaultEnabled) {
            request.observationDate = this.state.observationDate;
            request.contractId = this.state.selectedDeviceContract?.id ?? "";
            request.faults = this.state.faults;
        }

        // No need to send the payload again
        request.pictures.forEach(picture => {
            picture.src = "";
        });

        await this.postAsync("api/device/recordObservation", request);

        await RentaToolsStorage.clearDataAsync(RentaToolsStorageTable.Observations);

        await RentaToolsController.navigateToDevicePageAsync(this.device.externalId);

        await ch.flyoutMessageAsync(Localizer.get(Localizer.takePicturesPageAlertMessagePicturesSaved, Localizer.picturesPreviewPageTitle));
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!RentaToolsController.device) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true);
        }

        const deviceId: string = this.device.externalId;
        const savedState = await RentaToolsStorage.getDataAsync<IRecordObservationPageState>(RentaToolsStorageTable.Observations, `observation_state_${deviceId}`);

        if (savedState) {
            this.setState(savedState);
        }

        const deviceContracts: DeviceContract[] = await RentaToolsController.getDeviceContractsAsync(RentaToolsController.device!.id, true);

        if (deviceContracts.length > 0) {
            const deviceInStock = RentaToolsController.device?.status === DeviceStatus.InStock;
            const lastContract = deviceContracts.firstOrDefault(contract => contract.contractExternalId === RentaToolsController.device?.lastContractExternalId);
            const currentContract = deviceContracts.firstOrDefault(contract => contract.contractExternalId === RentaToolsController.device?.currentContractExternalId);

            const contract = ToolsUtility.isNullOrEmpty(RentaToolsController.device?.currentContractExternalId)
                ? lastContract
                : currentContract;

            this.setState({
                deviceContracts: deviceContracts,
                selectedDeviceContract: deviceInStock ? null : contract,
            });
        }

        const location = await ToolsUtility.initializeGeolocationAsync();

        if (location) {
            this.setState({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
            })
        }
    }

    private async setContractIdAsync(contract: DeviceContract): Promise<void> {
        this.setState({
            selectedDeviceContract: contract,
        });

        this.persistState();

        await this.reRenderAsync();
    }

    private async onImageUploadAsync(file: FileModel): Promise<FileModel | null> {
        const response = await FileService.uploadFullImageAsync(file);
        return response?.fileModel ?? null;
    }

    private async addFaultAsync(): Promise<void> {
        const selected = true;

        let faults: Fault[] = [];

        if (this.state.faults.length > 0) {
            faults = this.state.faults;
        }

        faults.push({} as Fault);

        this.setState({faultSelected: selected, faults});

        this.persistState();

        await this.reRenderAsync();
    }

    private async removeFaultAsync(fault: Fault): Promise<void> {
        if (this.state.faults?.length == 0) {
            return;
        }

        const updatedFaults: Fault[] = this.state.faults;

        updatedFaults.remove(fault);

        this.setState({
            faults: updatedFaults
        });

        this.persistState();

        await this.reRenderAsync();
    }

    private renderCustomDateInput(): React.ReactNode {
        const whiteSpace = "\xa0\xa0\xa0";
        const prefix = `1 ${whiteSpace}`;

        let date: Date = this.observationDate;
        if (!ToolsUtility.isValidDateFormat(this.observationDate)) {
            date = moment(this.state.observationDate).toDate();
        }

        const label: string = ToolsUtility.toDateStringWithTime(date, prefix, "", "HH:mm");

        return (
            <ArsenalButton block
                           className={this.css(styles.label, newStyles.label)}
                           type={ButtonType.Default}
                           label={label}
                           icon={{name: "fal calendar-alt"}}
            />
        );
    }

    public render(): React.ReactNode {
        const dropDownCss: string = this.css(rentaToolsStyles.arsenalDropdown, (!this.mobile) && styles.desktopDropdownStyle, (!this.mobile) && styles.newInvoiceDesktopDropdownStyle);

        return (
            <PageContainer>

                <PageHeader title={Localizer.devicePageRecordObservation}/>

                <PageRow>

                    <div className="col">

                        <ArsenalPageRow className={this.css(styles.photoContainer)}>

                            <div className={this.css(styles.subtitle)}>
                                <label id="subtitle">{Localizer.devicePagePicture.toPascalCase()}</label>
                            </div>

                            <ImageInput multi noAutoSelect
                                        pictures={this.pictures}
                                        className="flex-1"
                                        fileTypes={[...RentaToolsConstants.supportedImageFileTypes]}
                                        onChange={async (sender: ImageInput, pictures: FileModel[]) => this.setState({pictures})}
                                        maxImageRequestSizeInBytes={RentaToolsConstants.originalImageMaxSize}
                                        convertImage={async (image: FileModel) => await this.onImageUploadAsync(image)}
                            />

                            <SpeechRecognitionTextInput showRemainingAmount
                                                        id="observation_comment"
                                                        textInputRef={this._commentRef}
                                                        className={this.css(styles.comment)}
                                                        label={Localizer.devicePageExtraDeviceInfoTextComment}
                                                        rows={4}
                                                        value={this.comment}
                                                        onLeave={(value) => this.setCommentAsync(value)}
                            />

                        </ArsenalPageRow>

                        {(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagManualFaultsEnabled)) && (
                            <>
                                {(this.state.faults.length > 0) &&

                                    <ArsenalPageRow className={this.css(styles.photoContainer)}>

                                        {
                                            (this.warnDeviceContract) && (
                                                <>
                                                    <Icon name="fal exclamation-triangle"
                                                          className={this.css(styles.exclamationMark)}
                                                          size={IconSize.Large}
                                                          style={IconStyle.Regular}
                                                    />
                                                    <span className={"danger"}>{Localizer.takePicturesPageContractWarning}</span>
                                                </>
                                            )
                                        }

                                        <Dropdown id={"deviceContractId"}
                                                  className={this.css(dropDownCss, styles.contractDropdown)}
                                                  label={Localizer.invoiceDetailsPageContractId}
                                                  items={this.deviceContracts}
                                                  selectedItem={this.selectedDeviceContractIdForManualInvoice}
                                                  onChange={async (sender, value) => await this.setContractIdAsync(value!)}
                                        />

                                        <br/>

                                        <DateInput required popup showTime
                                                   id={"observationDate"}
                                                   className={this.css(styles.dateInput)}
                                                   customInput={() => this.renderCustomDateInput()}
                                                   maxDate={Utility.today()}
                                                   value={this.observationDate}
                                                   onChange={async (date) => await this.setDateAsync(date)}

                                        />

                                        <br/>

                                        {
                                            this.state.faults.map((fault, index) => {
                                                return (
                                                    <>
                                                        <TextAreaInput showRemainingAmount
                                                                       id={`fault_comment_${index}`}
                                                                       className={this.css(styles.comment)}
                                                                       label={Localizer.genericFaultDescription}
                                                                       rows={4}
                                                                       value={fault.faultText}
                                                                       onChange={async (sender, value) => this.setFaultCommentAsync(index, value)}
                                                        />

                                                        <ArsenalButton block
                                                                       id={`btn_remove_fault_${index}`}
                                                                       type={ButtonType.Default}
                                                                       label={Localizer.genericRemoveFault}
                                                                       className={this.css(rentaToolsStyles.arsenalButton)}
                                                                       onClick={async () => await this.removeFaultAsync(fault)}
                                                        />
                                                    </>
                                                )
                                            })
                                        }


                                        <br/>
                                    </ArsenalPageRow>
                                }

                                <ArsenalPageRow>

                                    <ArsenalButton block
                                                   id="btn_add_fault"
                                                   type={ButtonType.Orange}
                                                   label={this.addFaultLabel}
                                                   className={this.css(rentaToolsStyles.arsenalButton)}
                                                   onClick={async () => await this.addFaultAsync()}
                                    />

                                </ArsenalPageRow>

                            </>)
                        }

                        <ButtonContainer>

                            <ArsenalButton block big
                                           className={newStyles.button}
                                           right={!this.mobile ?? true}
                                           type={ButtonType.Orange}
                                           label={Localizer.summarySave}
                                           disabled={!this.canSave}
                                           onClick={() => this.saveAsync()}
                            />

                            <ArsenalButton block big
                                           className={newStyles.button}
                                           type={ButtonType.Orange}
                                           label={Localizer.genericReturnCaps}
                                           onClick={async () => PageRouteProvider.back()}
                            />

                        </ButtonContainer>

                    </div>

                </PageRow>

                <Modal id={"take-pictures-page-info-modal"}
                       ref={this._pageInfoModalRef}
                       className={rentaToolsStyles.infoPageModal}
                       title={Localizer.takePicturesPageInfoHeader}
                       onClose={async () => await this.onClosePageInfoModalAsync()}
                >
                    {this.getPageInfo(Localizer.takePicturesPageInfoContent, "##")}

                </Modal>

            </PageContainer>
        );
    }
}