import Localizer from "@/localization/Localizer";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import SummaryPage from "@/tests/pages/SummaryPage";
import DashboardPage from "@/tests/pages/DashboardPage";
import SavePage from "@/tests/pages/SavePage";
import DevicePage from "@/tests/pages/DevicePage";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";

describe("Return Inspection tests without faults", () => {
  beforeEach(() => {
    CypressFeatureFlagService.disable(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
    cy.login();
    cy.visit("Dashboard");
  });

  it("Return Inspection - save page should show fail icon", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("NoFaults1");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.stepNeedRepair("Ulkopuoli", "Test");

    ReturnInspectionPage.completeQuestionStep("Lataus");

    SummaryPage.validateSummaryListCount(2);

    SummaryPage.validateSummary([
      {
        name: "Tarkista uudet vauriot, käyttöpaneelit, vesivaaka, renkaat, ruoste yms.",
        okay: false,
        userName: "juhani.sihvonen@weare.fi",
      },
      {
        name: "Aseta kone lataukseen, tarkista huoltotarve ja kirjaa käyttötunnit",
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
      },
    ]);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();
  });

  it("Return Inspection - save page should show success icon", () => {
    cy.goToDashboard();

    DashboardPage.openDevice("NoFaults");

    DevicePage.startReturnInspection();

    ReturnInspectionPage.startReturnInspection();

    ReturnInspectionPage.completeQuestionStep("Ulkopuoli");

    ReturnInspectionPage.completeQuestionStep("Lataus");

    SummaryPage.validateSummaryListCount(2);

    SummaryPage.validateSummary([
      {
        name: "Tarkista uudet vauriot, käyttöpaneelit, vesivaaka, renkaat, ruoste yms.",
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
      },
      {
        name: "Aseta kone lataukseen, tarkista huoltotarve ja kirjaa käyttötunnit",
        okay: true,
        userName: "juhani.sihvonen@weare.fi",
      },
    ]);

    SummaryPage.saveInspection();

    SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

    SavePage.goToDevicePage();
  });  
});
