import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import Localizer from "../../localization/Localizer";

class DeviceAnnualInspectionFormPage {
    public elements = {
        form: () => cy.get('form'),
        pdfInput: () => cy.get("#pdf_input"),
        fileInput: () => cy.get('input[type="file"]'),
        header: () => cy.get(".athenaeum-page-container-content"),
        submitButton: () => this.elements.form().find("button[type=submit]"),
        confirmationDialog: () => cy.get('[id^=confirmationContent-]'),
        confirmationDialogConfirmBtn: () => this.elements.confirmationDialog().first().find('button[id^=confirmation-dialog-confirm-]'),
        banOfUseCheckbox: () => cy.get("#ban_of_use_checkbox"),
        banOfUseInfo: () => cy.get("#ban_of_use_info"),
    }

    public routes = {
        findDeviceById: {
            path: "/api/device/findDeviceById",
            method: "POST",
        } as RouteData,
        saveRemarksRepaired: {
            path: "/api/annualInspection/SaveRemarksRepaired",
            method: "POST",
        } as RouteData,
        saveAnnualInspection: {
            path: "/api/annualInspection/SaveDeviceAnnualInspection",
            method: "POST",
        } as RouteData,
        listDeviceAnnualInspections: {
            path: "/api/AnnualInspection/ListDeviceAnnualInspections",
            method: "POST",
        } as RouteData,
        getUserDepos: {
            path: "/api/depo/getUserDepos",
            method: "POST",
        } as RouteData,
        listVendors: {
            path: "/api/annualInspection/ListAnnualInspectionVendors",
            method: "POST",
        } as RouteData,
        getAnnualInspectionTypeItemsByProductGroup: {
            path: "/api/mappings/getAnnualInspectionTypeItemsByProductGroup",
            method: "POST",
        } as RouteData,
        getOperatingHoursLimits: {
            path: "/api/report/getOperatingHoursLimits",
            method: "POST",
        } as RouteData
    };

    public assertHeader() {
        this.elements.header().should('contain.text', Localizer.deviceAnnualInspectionPageRemarksRepaired)
    }

    public submitForm() {
        this.elements.form().submit();
    }

    public attachFile(){
        this.elements.pdfInput();
        cy.fixture('returnInspectionReport.pdf').then(fileContent => {
            this.elements.fileInput().attachFile({
                fileContent: fileContent.toString(),
                fileName: 'returnInspectionReport.pdf',
                mimeType: 'application/pdf'
            });
        });
    }

    public submitFormWithConfirm() {
        executeWithIntercept(() => {
            cy.get('form').find('button[type=submit]').trigger('click');
            cy.get('[id^=confirmationContent-]').first().find('button[id^=confirmation-dialog-confirm-]').trigger('click');
        }, [this.routes.saveAnnualInspection])
    }

    public assertAndFillRemarks() {
        cy.get("#remarksRepairedDate").click();
        cy.get("#input_remarks").type("Remarks");
    }

    public assertAndFillForm() {
        cy.get("#input_inspectionDate").click();

        cy.get("#annualInspectionVendor").click();
        cy.get("#inspectionType");

        cy.get("#pdf_input");
        cy.fixture('returnInspectionReport.pdf').then(fileContent => {
            cy.get('input[type="file"]').attachFile({
                fileContent: fileContent.toString(),
                fileName: 'returnInspectionReport.pdf',
                mimeType: 'application/pdf'
            });
        });
        cy.get("#invoiceNumber").click().type("lasku 123");
        cy.get("#inspectionCost").click().type("1");
        cy.get("#depot").click();
    }

    public submitAnnualInspection(confirm: boolean = false): void {
        executeWithIntercept(() => {
                this.submitForm();

                if (confirm) {
                    this.elements.confirmationDialogConfirmBtn()
                        .click();
                }
            }, [
                this.routes.saveAnnualInspection,
                this.routes.findDeviceById,
                this.routes.listDeviceAnnualInspections
            ]
        );
    }
}

export default new DeviceAnnualInspectionFormPage();