import AuthorizedPage from "@/models/base/AuthorizedPage";
import React from "react";
import Loader from "@/components/Loader/Loader";
import {PageContainer, Switch} from "@renta-apps/athenaeum-react-components";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import pageStyles from "@/pages/DataManagementPage/DataManagementPage.module.scss";
import {DeviceShortView} from "@/models/server/DeviceShortView";
import DeviceService from "@/services/DeviceService";
import EditDeviceOrder from "@/pages/EditOrderDevicePage/components/EditDeviceOrder/EditDeviceOrder";
import Show from "@/components/Show/Show";
import OrderService from "@/services/OrderService";
import {DeviceListOrderType} from "@/models/Enums";
import {IDepoPageParameters} from "@/models/DepoPageParameters";
import Localizer from "@/localization/Localizer";
import localStyles from "@/pages/EditOrderDevicePage/components/EditDeviceOrderMenu/EditDeviceOrderMenu.module.scss";
import styles from './EditOrderDevicePage.module.scss';

interface IEditOrderDevicePageProps {}

interface IEditOrderDevicePageState {
    isLoading : boolean;
    devices : DeviceShortView[],
    fixedOrder : boolean;
    depoId: string;
    orderType : DeviceListOrderType;
}

export default class EditOrderDevicePage extends AuthorizedPage<
  IEditOrderDevicePageProps,
  IEditOrderDevicePageState
> {
  public async initializeAsync(): Promise<void> {
    await this.fetchDevicesAsync();

    return super.initializeAsync();
  }
  
  public async fetchDevicesAsync() {
      this.setState({
          isLoading: true,
      });

      const { depoId, orderType } = this.parameters as IDepoPageParameters;
      
      const response = await DeviceService.getDeviceShortInfo({
          depoId: depoId, 
          type : orderType
      });
      
      this.setState({
          isLoading: false,
          devices: response.devices,
          fixedOrder: response.depoFixedOrder,
          depoId : depoId,
          orderType: orderType,
      });
  }
  
  private async onSave(depoId : string, devices: DeviceShortView[], fixedOrder : boolean, deviceOrderType : DeviceListOrderType)
  {
      this.setState({
          isLoading: true,
      });
      
      await OrderService.saveOrderAsync(depoId, devices, fixedOrder, deviceOrderType);

      this.setState({
          isLoading: false,
          fixedOrder : fixedOrder,
      });
  }
  
  public render(): React.ReactNode {
    const { devices, isLoading, depoId, orderType } = this.state;
    
    return (
      <>
        <Loader isLoading={isLoading} />
        <PageContainer
          className={this.css(
            rentaToolsStyles.pageContainer,
            newStyles.pageContainer,
            pageStyles.searchResults,
          )}
        >
            <Show.When isTrue={devices?.length > 0}>
                <EditDeviceOrder 
                    devices={devices}
                    depoId={depoId}
                    reFetchDevicesAsync={async () => await this.fetchDevicesAsync()}
                    fixedOrder={this.state.fixedOrder}
                    onSave={async (depoId, devices, fixedOrder) => await this.onSave(depoId, devices, fixedOrder, orderType)}
                    orderType={orderType}
                /> 
            </Show.When>
            <Show.Else render={devices?.length > 0}>
                <div className={localStyles.deviceOrderMenu}>
                    <div className={localStyles.switchButton}>
                        <Switch id={"fixedOrder"} value={this.state.fixedOrder}
                                onChange={async (s, checked) => this.onSave(depoId, [], checked, orderType)}/>
                        <div>
                            <p className={localStyles.bold}>{Localizer.editDeviceOrderPageFixedOrder}</p>
                            <p>{Localizer.editDeviceOrderPageFixedOrderDescription}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.editOrderNoResults}>
                    {Localizer.editDeviceOrderNoResults}
                </div>
            </Show.Else>
        </PageContainer>
      </>
    );
  }
}