import React from "react";
import ReactDOM from "react-dom";
import {PwaHelper} from "@renta-apps/athenaeum-toolkit";
import App from "./App";

/* FA (Font Awesome Pro) */
import "./lib/fa/fa";
/* Bootstrap 4 */
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "bootstrap";
/* JQuery required by bootstrap */
import "jquery";
/* Popper.js required by bootstrap */
import "popper.js";
/* App styles */
import "./base.scss";

window.addEventListener("beforeinstallprompt", (e) => PwaHelper.onBeforeInstallPrompt(e));

ReactDOM.render(<App />, document.getElementById("root"));