import {FileModel} from "@renta-apps/athenaeum-toolkit";
import DeviceAttribute from "./DeviceAttribute";
import DeviceInfo from "@/models/server/DeviceInfo";
import DeviceFault from "@/pages/Models/DeviceFault";
import DeviceCounter from "@/pages/Models/DeviceCounter";
import FuelType from "@/models/server/FuelType";
import Depo from "@/models/server/Depo";
import AdBlue from "@/models/server/AdBlue";
import {ExtendedMaintenanceReason} from "@/models/ExtendedMaintenanceReason";

export default class Device extends DeviceInfo {
    public attributes: DeviceAttribute[] = [];

    public reportDefinitionId: string | null = null;

    public serviceReportDefinitionId: string | null = null;

    public reportDefinitionName: string | null = null;

    public serviceReportDefinitionName: string | null = null;

    public fuelType: FuelType | null = null;

    public adBlue: AdBlue | null = null;

    public picture: FileModel | null = null;

    public activeFaults: DeviceFault[] | null = null;

    public permanentFaults: DeviceFault[] | null = null;

    public counters: DeviceCounter[] | null = null;

    public hasServices: boolean = false;

    public supportsOperatingHours: boolean = false;

    public supportsAnnualInspections: boolean = false;
    
    public supportErrorCodes : boolean = false;

    public depo: Depo | null = null;

    public isDeploymentInspectionDone: boolean = false;
    
    public isDevice: boolean = true;
}