import BaseListDevicesRequest from "./BaseListDevicesRequest";
import DeviceInfo from "../DeviceInfo";
import {MaintenanceStatus, ReturnInspectionStatus} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ToolsUtility from "@/helpers/ToolsUtility";
import UnleashHelper from "@/helpers/UnleashHelper";

export default class ListUnInspectedDevicesRequest extends BaseListDevicesRequest {

    public returnInspectionDetails : string = "";

    public returnInspectionStatus : string[] = [];

    public pageNumber : number = 0;

    public pageSize : number = 0;

    public modified: boolean = false;

    public completedAtFrom : Date | null = null;

    public completedAtTo : Date | null = null;

    public getDate(device: DeviceInfo): Date | null {
        if(ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection)) {
            return device.lastReturnDate;
        }

        return device.lastReturnReportDate;
    }


    public override clear(): void {
        super.clear();

        this.returnInspectionDetails  = "";

        if(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser)){
            this.returnInspectionStatus = [
                ReturnInspectionStatus.Waiting.toString(),
                ReturnInspectionStatus.PartlyCompleted.toString(),
                ReturnInspectionStatus.InProgress.toString(),
            ]
        }else{
            this.returnInspectionStatus = [
                ReturnInspectionStatus.Waiting.toString(),
                ReturnInspectionStatus.InProgress.toString(),
            ]
        }


        this.completedAtFrom = null;
        this.completedAtTo = null;
    }

    public isEmpty(): boolean {
        return (this.returnInspectionStatus.length == 0) && this.returnInspectionDetails === "" && (super.isEmpty());
    }

    public nextPage() : void{
        this.pageNumber++;
    }

    public resetPage() : void{
        this.pageNumber = 0;
        this.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;
    }
}