import AdminPage from "../../../tests/pages/AdminPage";

describe('Admin page tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
        cy.goToAdminPage();
    });

    it('Tests menu items', () => {
        AdminPage.elements.settings();
        AdminPage.elements.userManagement();
        AdminPage.elements.reportDefinitions();
        AdminPage.elements.serviceActions();
        AdminPage.elements.mappings();
        AdminPage.elements.annualInspectionVendors();
        AdminPage.elements.depos();
        AdminPage.elements.annualInspectionTypeMappings();
        AdminPage.elements.returnInspectionExpenses();
        AdminPage.elements.expenses();
        AdminPage.elements.deviceManagement();
        AdminPage.elements.adminConsole();
        AdminPage.elements.dataManagement();
        AdminPage.elements.globalNotificationSettings();

    })
})