import { IReturnInspectionTestInfo } from "@/tests/models/ReturnInspectionTestInfo";
import { IAdvanceCheckInfo } from "@/tests/pages/AdvanceCheckPage";
import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";

class SummaryPage {
  public elements = {
    items: () => cy.get("[data-cy=summary_item]"),
    saveInspectionBtn: () => cy.get("[id=save_return_inspection]"),
    stepStatus: (index: number) => cy.get(`[data-cy=step_status_${index}]`),
    extendSummary: () => cy.get("[id=extend_status_advancedChecks]"),
    advancedCheckSummary: () => cy.get("[data-cy=advance_check_summary_item]"),
    checkTitle: () => cy.get("[data-cy=title]"),
    expendCheckItem: () => cy.get("[id=expand_check_comment]"),
    checkItemComment: () => cy.get("[id=description]"),
    additionalExpensesAmount: (index: number) => cy.get(`#additionalExpensesAmount_${index}`),
    additionalExpensesIconContainer: (index: number) => cy.get(`#additionalExpensesIconContainer_${index} i`),
  };

  public routes = {
    saveReturnInspection: {
      path: "/api/ReturnInspection/SaveReturnInspection",
      method: "POST",
    } as RouteData
  }

  public validateSummaryListCount(length: number) {
    this.elements.items().should("have.length", length);
  }

  public saveInspectionButtonShouldBeDisabled() {
    this.elements.saveInspectionBtn().should("be.disabled");
  }


  public saveInspection() {
    executeWithIntercept(() => {
      this.elements.saveInspectionBtn()
          .should("not.be.disabled")
          .click();
    },[this.routes.saveReturnInspection]);
  }

  public validateSummary(items: IReturnInspectionTestInfo[]) {
    this.elements.items().each(($element, index) => {
      const item = items[index];

      cy.wrap($element).contains(item.name, { matchCase: false });
      cy.wrap($element).contains(item.userName);

      if (item.additionalExpensesCount != null) {
        this.elements.additionalExpensesAmount(index)
            .get('span')
            .should('contain.text', item.additionalExpensesCount);

        this.elements.additionalExpensesIconContainer(index)
            .should('have.class', 'fa-boxes');
      }
    });

    items.forEach((value, index) => {
      this.elements.stepStatus(index).should("satisfy", ($el) => {
        const classList = Array.from($el[0].classList).join("");
        const classToSearch = value.okay ? "okay" : "failed";
        return classList.includes(classToSearch);
      });
    });
  }

  public validateAdvancedChecks(items: IAdvanceCheckInfo[]) {
    this.elements.extendSummary().should("be.visible").realClick();

    cy.wait(100);

    this.elements.advancedCheckSummary().each((element, index) => {
      const check = items[index];

      cy.wrap(element).within(() => {
        this.elements.checkTitle().should("contain.text", check.name);

        if (check.comment) {
          this.elements.expendCheckItem().click();

          cy.wait(100);

          this.elements
            .checkItemComment()
            .should("contain.text", check.comment);
        }
      });
    });
  }
}

export default new SummaryPage();