import {
  DeviceBanOfUse,
  DeviceCounterType,
  DeviceStatus,
  MaintenanceReason,
  MaintenanceReasons,
  MaintenanceStatus,
  ReturnInspectionStatus,
} from "../Enums";
import ProductGroup from "./ProductGroup";
import User from "./User";
import { Utility } from "@renta-apps/athenaeum-toolkit";
import Device from "@/pages/Models/Device";
import { ExtendedMaintenanceReason } from "@/models/ExtendedMaintenanceReason";
import ServicePredictionInfo from "@/models/server/forecast/ServicePredictionInfo";
import ToolsUtility from "@/helpers/ToolsUtility";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import RentaToolsController from "@/pages/RentaToolsController";
import EnumProvider from "@/providers/EnumProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/DevicePage/DevicePage.module.scss";

export interface ReturnInspectionSteps {
  steps: Step[];
}

export interface Step {
    phaseId: string
    phaseName: string
    stepId: string
    isCompleted: boolean
}

export interface ReturnInspectionDetails {
    washing: boolean
    washingDone: boolean
    addBlue: boolean
    addBlueDone: boolean
    fueling: boolean
    fuelingDone: boolean
    operating: boolean
    operatingDone: boolean
}

export interface Fault {
    faultText : string;
}

export default class DeviceInfo extends ProductGroup {
    public id: string = "";

    public externalId: string = "";

    public serialNumber: string = "";

    public depoCostPool: string = "";

    public depoName: string = "";

    public depoId: string | null = null;

    public name: string = "";

    public type: string = "";

    public lastReturnReportDate: Date | null = null;

    public lastReturnDate: Date | null = null;

    public lastServiceDate: Date | null = null;

    public lastServiceOperatingHours: number | null = null;

    public lastRepairDate: Date | null = null;

    public currentContractExternalId: string | null = null;

    public lastContractExternalId: string | null = null;

    public lastAnnualReportDate: Date | null = null;

    public nextAnnualReportDate: Date | null = null;

    public underInspection: boolean = false;

    public inspectionStartedAt: Date | null = null

    public inspectionStartedById: string | null = null;

    public inspectionStartedBy: User | null = null;

    public underService: boolean = false;

    public serviceStartedAt: Date | null = null

    public serviceStartedById: string | null = null;

    public serviceStartedBy: User | null = null;

    public currentInspectionUser: User | null = null;

    public modifiedBy: User | null = null;

    public hasFaults: boolean = false;

    public priority: number = 0;

    public deviceBanOfUse: DeviceBanOfUse = DeviceBanOfUse.None;

    public fuelTypeId: string | null = null;

    public adBlueId: string | null = null;

    public status: DeviceStatus = DeviceStatus.InStock;

    public statusChangedAt: Date = new Date();

    public maintenance: MaintenanceReason[] = [];

    public maintenanceReason: MaintenanceReasons = MaintenanceReasons.None;

    public previousStatus: DeviceStatus = DeviceStatus.InStock;

    public previousMaintenance: MaintenanceReason[] = [];

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedBy: User | null = null;

    public returnInspectionReportDefinitionName: string | null = null;

    public serviceDefinitionName: string | null = null;

    public lastOperatingHoursRecordTimestamp: Date | null = null;

    public isDeviceInfo: boolean = true;

    public hasAnnualInspectionRemarks: boolean = false;

    public annualInspectionRemarksOverdue: boolean = false;

    public remarksDueDate: Date | null = null;

    public returnInspectionSteps: ReturnInspectionSteps | null = null;

    public returnInspectionDetails: ReturnInspectionDetails | null = null;

    public returnInspectionStatus: ReturnInspectionStatus | null = null;

    public secondaryInformation: string = "";

    public faults: Fault[] = [];

    public maintenanceStatus: MaintenanceStatus = MaintenanceStatus.None;

    public endLifeTimeDate: Date | null = null;

    public nextServiceDate: Date | null = null;

    public closestPredictionDate: Date | null = null;

    public preparedForSale: boolean = false;

    public predictionInfo: ServicePredictionInfo[] = [];

    public extendedMaintenanceReasons : ExtendedMaintenanceReason[] = [];

    public static hasMaintenanceReason(value: number): boolean {
        const maintenanceReasons: MaintenanceReasons[] = [
            MaintenanceReasons.AnnualServiceMonthsLimit,
            MaintenanceReasons.TotalOperatingHoursLimit,
            MaintenanceReasons.CurrentOperatingHoursLimit,
            MaintenanceReasons.TotalRentalDaysLimit,
            MaintenanceReasons.CurrentRentalDaysLimit,
            MaintenanceReasons.TotalMonthsLimit
        ];

        return maintenanceReasons.some(m => (value & m) == m);
    }

    public static needService(device: DeviceInfo): boolean {
        return ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresService);
    }

    public static needRepair(device: DeviceInfo): boolean {
        return (ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresRepair) || ToolsUtility.hasFlag(device.maintenanceReason, MaintenanceReasons.Repair));
    }

    public static maintenanceReasons(device: DeviceInfo | null): string {
        if (!device) {
            return "";
        }

        const maintenance: MaintenanceReason[] = device.maintenance;
        const annualInspection: MaintenanceReason | null = maintenance.firstOrDefault(item => item == MaintenanceReason.AnnualInspection);
        const repair: MaintenanceReason | null = maintenance.firstOrDefault(item => item == MaintenanceReason.Repair)
        const serviceReasons: MaintenanceReason[] = maintenance.filter(item => item > MaintenanceReason.Service && item != annualInspection);

        let serviceReasonsText = "";
        
        if(device.extendedMaintenanceReasons.length === 0) {
            serviceReasonsText = serviceReasons.length > 0
                ? (` (${serviceReasons.map(item => EnumProvider.getMaintenanceReasonText(item).toLowerCase())})`)
                : "";
            
        } else {
            const extendedMaintenanceReasonText: string[] = [];

            for (let i = 0; i < device.extendedMaintenanceReasons.length; i++) {
                const extendedMaintenanceReason = device.extendedMaintenanceReasons[i].maintenanceReasons.filter(item => item > MaintenanceReason.Service);

                if(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagExtendedMaintenanceReason)) {
                    extendedMaintenanceReasonText.push(...extendedMaintenanceReason.map(item => `${EnumProvider.getMaintenanceReasonText(item).toLowerCase()} - ${device.extendedMaintenanceReasons[i].definitionTypeName}`));
                } else {
                    extendedMaintenanceReasonText.push(...extendedMaintenanceReason.map(item => device.extendedMaintenanceReasons[i].definitionTypeName));
                }
            }

            if (extendedMaintenanceReasonText.length > 0) {
                serviceReasonsText = ` (${extendedMaintenanceReasonText.join(", ")})`;
            }
        }
        
        const annualInspectionReasonText: string = (annualInspection != null)
            ? EnumProvider.getMaintenanceReasonText(annualInspection)
            : "";
        const repairText: string = (repair != null)
            ? EnumProvider.getMaintenanceReasonText(repair)
            : "";

        const reasonText: string = maintenance
            .filter(item => item <= MaintenanceReason.Service)
            .map(item => EnumProvider.getMaintenanceReasonText(item))
            .map((item, index) => (index == 0) ? item : item.toLowerCase())
            .join(", ");

        return DeviceInfo.maintenanceReasonIsAnnualInspection(device)
            ? [annualInspectionReasonText, repairText].filter(Boolean).join(",")
            : [annualInspectionReasonText, reasonText].filter(Boolean).join(", ") + serviceReasonsText;
    }

    public static hasFaults(device: DeviceInfo | null): boolean {
        if (!device) {
            return false;
        }

        return ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresRepair);
    }

    public static getMaintenanceStatus(device: DeviceInfo | null): string {
        if (!device) {
            return "";
        }

        const serviceAndRepairEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagServiceAndRepairEnabled);

        const status: MaintenanceStatus = (!serviceAndRepairEnabled)
            ? device.maintenanceStatus & MaintenanceStatus.RequiresReturnInspection
            : device.maintenanceStatus;

        let result = `${Localizer.genericPending}: `;

        const statuses: string[] = [];
        if (ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresRepair)) {
            statuses.push(Localizer.enumMaintenanceReasonRepair);
        }

        const annualInspectionsEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAnnualInspectionsEnabled);
        if (annualInspectionsEnabled && ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresAnnualInspection)) {
            statuses.push(Localizer.genericAnnualInspection);
        }

        if (ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresReturnInspection)) {
            statuses.push(Localizer.enumReportDefinitionTypeReturnInspection);
        }

        if (ToolsUtility.hasFlag(status, MaintenanceStatus.RequiresService)) {
            statuses.push(Localizer.enumMaintenanceReasonService);
        }

        result += statuses.join(", ");

        return result;
    }

    public static getRentalStatusAndCostPool(device: DeviceInfo | null): string {
        if (!device) {
            return "";
        }

        if (device.deleted) {
            return Localizer.devicePageDeleted;
        }

        const deviceStatus: DeviceStatus = device.status;
        const deviceCostPool: string = device.depoCostPool;

        if (deviceStatus == DeviceStatus.InRent || !ToolsUtility.isNullOrEmpty(device.currentContractExternalId)) {
            return "{0:DeviceStatus} ({1})".format(DeviceStatus.InRent, device.currentContractExternalId)
        }

        return "{0:DeviceStatus} ({1})".format(DeviceStatus.InStock, deviceCostPool);
    }

    public static getNextAnnualReportDateStyle(device: DeviceInfo | null): string | null {
        if (!device) {
            return "";
        }
        const date: Date | null = device.nextAnnualReportDate;
        if (date) {
            const now: Date = Utility.date();
            const daysIn12Months = 365;
            const daysIn9Months = 273;
            const difInDays: number = Utility.diff(now, date).days;
            if ((daysIn9Months <= difInDays) && (difInDays <= daysIn12Months)) {
                return "text-warning";
            }
            if (difInDays > daysIn12Months) {
                return styles.danger;
            }
        }
        return null;
    }

    public static maintenanceReasonIsAnnualInspection(device: DeviceInfo): boolean {
        return device?.maintenance.length === 1 && device?.maintenance.firstOrDefault() === MaintenanceReason.AnnualInspection
            || device?.maintenance.length === 2 && device?.maintenance.some(item => item == MaintenanceReason.AnnualInspection) && device?.maintenance.some(item => item == MaintenanceReason.Service);
    }

    public static fuelingDone(device: DeviceInfo): boolean {
        if (!ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection)) {
            return true;
        }
        
        if(device.returnInspectionStatus === ReturnInspectionStatus.NotCompleted) {
            return false;
        }

        if (!device.underInspection) {
            return false;
        }

        if (device.returnInspectionDetails) {
            return device.returnInspectionDetails.fuelingDone;
        }

        return true;
    }

    public static washingDone(device: DeviceInfo): boolean {
        if (!ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection)) {
            return true;
        }
        
        if(device.returnInspectionStatus === ReturnInspectionStatus.NotCompleted) {
            return false;
        }

        if (!device.underInspection) {
            return false;
        }

        if (device.returnInspectionDetails) {
            return device.returnInspectionDetails.washingDone;
        }

        return true;
    }

    public static getLatestOperatingHoursInput(device: DeviceInfo): Date | null {
        const dates: (Date | null)[] = [device.lastServiceDate, device.lastReturnReportDate, device.lastRepairDate, device.lastAnnualReportDate];

        if (dates.length === 0) {
            return null;
        }

        const filteredDates: Date[] = dates.filter(date => date !== null) as Date[];

        filteredDates.sort((a, b) => {
            return b.getTime() - a.getTime();
        });

        return filteredDates[0];
    }

    public static getTotalOperatingHours(device: Device): number | null {
        return (device!.supportsOperatingHours)
            ? RentaToolsController.getDeviceCounter(device!, DeviceCounterType.TotalOperatingHours) || 0
            : null;
    }

    public static getLatestPredictionInfo(device: DeviceInfo): ServicePredictionInfo | null {
        return device.predictionInfo?.firstOrDefault(p => p.date?.getUTCDate() === device.closestPredictionDate?.getUTCDate());
    }
}