import DeviceErrorFaultCode from "@/components/DeviceErrorFaultCode/DeviceErrorFaultCode";
import styles from './ErrorCodesList.module.scss';
import Localizer from "@/localization/Localizer";
import React from "react";
import ErrorCodeModel from "@/models/ErrorCodeModel";

export interface IErrorCodesListProps{
    errorCodes : ErrorCodeModel[];
    isLoading : boolean;
}

const ErrorCodesList = ({errorCodes, isLoading} : IErrorCodesListProps) =>{
    const noContent = () => {
        return !isLoading && errorCodes.length === 0
    }
    
    
    return (
        <div>
            {errorCodes.length > 0 && (
                <React.Fragment>
                    <div className={styles.containerList}>
                        {errorCodes.map((errorCode, index) => (
                            <DeviceErrorFaultCode key={`${errorCode.errorCode}${index}`} errorCode={errorCode}/>
                        ))}
                    </div>
                </React.Fragment>
            )}
            {noContent() && (
                <p className={styles.pageDescription}>{Localizer.errorCodesNoCodes}</p>
            )}
        </div>
    )
}

export default ErrorCodesList;