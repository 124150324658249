import DeviceInfo from "@/models/server/DeviceInfo";
import styles from "@/components/ServicedDevicesList/ServicedDevicesList.module.scss";
import React, {useEffect, useState} from "react";
import Localizer from "@/localization/Localizer";
import {MaintenanceStatus} from "@/models/Enums";
import ToolsUtility from "@/helpers/ToolsUtility";
interface IServicedDevicePageStatusProps{
    item : DeviceInfo;
}

const ServicedDevicePageStatus = ({ item } : IServicedDevicePageStatusProps) =>{
    const [statusText, setStatusText ] = useState("");

    const servicedStatus = [MaintenanceStatus.RequiresRepair, MaintenanceStatus.RequiresService];

    const needService = (device: DeviceInfo) => {
        return servicedStatus.some(p=> ToolsUtility.hasFlag(device.maintenanceStatus, p)) && !device.underService
    }

    useEffect(() =>{
            if(item.underService) {
                setStatusText(Localizer.servicedPageInProgress);

            } else if(needService(item)) {
                if (DeviceInfo.needService(item) && DeviceInfo.needRepair(item)) {
                    setStatusText(Localizer.servicedPageWaitingForServiceOrRepair);
                } else if (DeviceInfo.needService(item)) {
                    setStatusText(Localizer.servicedPageWaitingForService);
                } else if (DeviceInfo.needRepair(item)) {
                    setStatusText(Localizer.servicedPageWaitingForRepair);
                }
            } else {
                setStatusText(Localizer.servicedPageCompleted);
            }
    }, [item])


    return (
        <p className={styles.fontBold}>{statusText}</p>
    )
}

export default ServicedDevicePageStatus;