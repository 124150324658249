import React from "react";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import PageDefinitions from "../../providers/PageDefinitions";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import InspectionReportsList from "@/pages/InspectionReportsPage/InspectionReportsList/InspectionReportsList";
import Device from "@/pages/Models/Device";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "@/pages/DeviceAnnualInspectionHistoryPage/DeviceAnnualInspectionHistoryPage.module.scss";

interface IInspectionReportsPageProps {
}

interface IInspectionReportsPageState {
}

export default class InspectionReportsPage extends AuthorizedPage<IInspectionReportsPageProps, IInspectionReportsPageState> {

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private renderTitle(): React.ReactNode {
        const deviceProductGroupName = this.device.productGroupName;
        const deviceType = this.device.type;
        const deviceExternalId = this.device.externalId;
        const deviceGroupId = this.device.productGroupId;

        return (
            <React.Fragment>

                <div>
                    <div id={"multiTitleDiv"} className={styles.multiTitle}>
                        <span id={"deviceProductGroup"}
                              className={styles.deviceProductGroup}>{deviceProductGroupName} {deviceGroupId}</span>
                        <span id={"deviceType"} className={styles.deviceType}>{deviceType}</span>
                        <span id={"device_externalId"}>{deviceExternalId}</span>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (RentaToolsController.device == null) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(rentaToolsStyles.pageContainer)}>

                <PageHeader title={() => this.renderTitle()}
                            className={rentaToolsStyles.leftPageHeader}/>

                <ArsenalPageRow>

                    <InspectionReportsList device={this.device}/>

                </ArsenalPageRow>

                <ArsenalPageRow>

                    <ArsenalButton block
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   onClick={async () => PageRouteProvider.back()}/>

                </ArsenalPageRow>

            </PageContainer>
        );
    }
}