import styles from './DeviceShortInfo.module.scss';
import React from "react";
import Device from "@/pages/Models/Device";

export interface IDeviceShortInfoProps{
    device : Device | null;
}

const DeviceShortInfo = ({device} : IDeviceShortInfoProps) =>{
    const deviceProductGroupName = device?.productGroupName;
    const deviceType = device?.type;
    const deviceExternalId = device?.externalId;
    const deviceGroupId = device?.productGroupId;

    return (
        <React.Fragment>

            <div>
                <div id={"multiTitleDiv"} className={styles.multiTitle}>
                        <span id={"deviceProductGroup"}
                              className={styles.deviceProductGroup}>{deviceProductGroupName} {deviceGroupId}</span>
                    <span id={"deviceType"} className={styles.deviceType}>{deviceType}</span>
                    <span id={"device_externalId"}>{deviceExternalId}</span>
                </div>
            </div>

        </React.Fragment>
    );
}

export default DeviceShortInfo;