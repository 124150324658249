import User from "../../models/server/User";
import Depo from "@/models/server/Depo";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import ServiceAction from "@/pages/Models/ServiceAction";
import DeviceFault from "@/pages/Models/DeviceFault";
import ServiceReportType from "@/pages/Models/ServiceReportType";
import {MaintenanceReason, ServiceLocationType} from "@/models/Enums";
import ServiceExpense from "@/models/server/ServiceExpense";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

export default class ServiceReport {

    public id: string = "";

    public reportDefinitionId: string = "";

    public reportDefinition: ServiceReportDefinition | null = null;

    public deviceId: string = "";

    public deviceExternalId: string = "";

    public date: Date = new Date();

    public startedAt: Date = new Date();

    public completedAt: Date = new Date();

    public maintenance: MaintenanceReason[] = [];

    public serviceType: ServiceReportType | null = null;

    public serviceTypeId: string | null = null;

    public serviceTypeName: string | null = null;

    public servicedByRenta: boolean = false;

    public invoiceNumber: string = "";

    public cost: number = 0;

    public actions: ServiceAction[] = [];

    public user: User | null = null;

    public comment: string | null = null;

    public depo: Depo | null = null;

    public depoId: string | null = null;

    public invoiceFileId: string | null = null;

    public faults: DeviceFault[] | null = null;

    public operatingHours: number | null = null;

    public previousOperatingHours: number | null = null;

    public nextOperatingHours: number | null = null;

    public skipped: boolean = false;

    public locationType: ServiceLocationType | null = null;

    public serviceExpense: ServiceExpense | null = null;

    public isServiceReport: boolean = true;

    public serviceExternalCostId: number | null = null;

    public isRepairWithNoFaults: boolean = false;
    
    public serviceDuration: number | null = null;
    
    public repairDuration: number | null = null;

    public static getMaintenanceDescription(service: ServiceReport, includeServiceType: boolean = false, acronymsOnly: boolean = false): string {
        const maintenance: MaintenanceReason[] = service.maintenance;
        const noMaintenance: boolean = maintenance.length == 0;
        const serviceTypeName: string | null = service.serviceTypeName;

        if ((acronymsOnly) && ((noMaintenance) || (service.skipped))) {
            return "-";
        }

        if (noMaintenance) {
            return (service.skipped)
                ? Localizer.serviceReportSkipped.toLocaleUpperCase()
                : Localizer.serviceReportNoMaintenanceReasons.toLocaleUpperCase();
        }

        let serviceTitle: string = (maintenance.includes(MaintenanceReason.Service) || maintenance.includes(MaintenanceReason.Manual))
            ? (maintenance.includes(MaintenanceReason.Manual))
                ? EnumProvider.getMaintenanceReasonText(MaintenanceReason.Manual)
                : EnumProvider.getMaintenanceReasonText(MaintenanceReason.Service)
            : "";

        let repairTitle: string = (maintenance.includes(MaintenanceReason.Repair))
            ? EnumProvider.getMaintenanceReasonText(MaintenanceReason.Repair)
            : "";

        if (acronymsOnly) {
            serviceTitle = serviceTitle.charAt(0).toLocaleUpperCase();
            repairTitle = repairTitle.charAt(0).toLocaleUpperCase();
        }

        const separator: string = (acronymsOnly) ? "&" : " & ";
        const info: string = (serviceTitle && repairTitle)
            ? [serviceTitle, repairTitle].join(separator)
            : (serviceTitle)
                ? serviceTitle
                : repairTitle;

        let description: string = ((includeServiceType) && (serviceTypeName))
            ? `${info}, ${serviceTypeName}`
            : info;

        if (service.skipped) {
            description = `${description} (${Localizer.serviceReportSkipped.toLocaleUpperCase()})`;
        }

        return description;
    }
}