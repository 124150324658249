import React from "react";
import {BaseComponent, ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    ColumnDefinition,
    ColumnType,
    DropdownAlign,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    RowModel,
    Tab,
    TabContainer,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";
import ListVendorInspectorsRequest from "@/models/server/requests/ListVendorInspectorsRequest";
import {ActionType} from "@/models/Enums";
import {IPagedList, Utility} from "@renta-apps/athenaeum-toolkit";
import DeleteAnnualInspectionVendorResponse from "@/models/server/responses/DeleteAnnualInspectionVendorResponse";
import ToolsUtility from "@/helpers/ToolsUtility";
import VendorInspectionViewModel from "@/models/server/VendorInspectionViewModel";
import ListVendorInspectionsRequest from "@/models/server/requests/ListVendorInspectionsRequest";
import User from "@/models/server/User";
import SaveAnnualInspectionVendorRequest from "@/models/server/requests/SaveAnnualInspectionVendorRequest";
import SaveAnnualInspectionVendorResponse from "@/models/server/responses/SaveAnnualInspectionVendorResponse";
import RestoreAnnualInspectionVendorResponse from "@/models/server/responses/RestoreAnnualInspectionVendorResponse";
import AnnualInspectionController from "@/pages/AnnualInspectionController";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./VendorDetailsPanel.module.scss";

export interface IVendorDetailsProps {
    vendor: AnnualInspectionVendor;
    showDeleted: boolean;
    getUsers(userId: string | null): Promise<User[]>;
    edit?(vendor: AnnualInspectionVendor): Promise<void>;
    onAddChildVendor(vendor: AnnualInspectionVendor): Promise<void>;
    onChangeChildVendor(vendor: AnnualInspectionVendor, isPermanentlyRemoved: boolean): Promise<void>;
}

export interface IVendorDetailsState {
    selectedTabIndex: number,
}

export default class VendorDetailsPanel extends BaseComponent<IVendorDetailsProps, IVendorDetailsState> {

    state: IVendorDetailsState = {
        selectedTabIndex: 0,
    };

    private readonly _inspectorsGridRef: React.RefObject<Grid<AnnualInspectionVendor>> = React.createRef();
    private readonly _inspectionsGridRef: React.RefObject<Grid<VendorInspectionViewModel>> = React.createRef();

    private readonly _inspectorColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.genericNameLanguageItemName,
            accessor: nameof.full<AnnualInspectionVendor>(item => item.name),
            minWidth: 150,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.genericUserLanguageItemName,
            accessor: nameof.full<AnnualInspectionVendor>(o => o.user),
            transform: (cell: CellModel<AnnualInspectionVendor>) => TransformProvider.toString(cell.model.user),
            type: ColumnType.Dropdown,
            settings: {
                fetchItems: (cell: CellModel<AnnualInspectionVendor>) => this.props.getUsers(cell.model.user?.id ?? null),
                align: DropdownAlign.Left,
                nothingSelectedText: "-",
            },
            editable: true,
            minWidth: 150,
            maxWidth: 150,
        },
        {
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initVendorOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.annualInspectionVendorsPageGridSaveLanguageItemName,
                    icon: "far save",
                    right: false,
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.annualInspectionVendorsPageGridCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.annualInspectionVendorsPageGridDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.annualInspectionVendorsPageGridRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
                {
                    name: "edit",
                    title: Localizer.genericEdit,
                    icon: "fad fa-sitemap",
                    type: ActionType.Secondary,
                    right: false,
                    callback: async (cell, action) => await this.processVendorOperationAsync(cell, action)
                },
            ]
        }
    ];

    private readonly _inspectionsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.devicesFiltersModalLabelDeviceIdLanguageItemName,
            accessor: nameof.full<VendorInspectionViewModel>(item => item.deviceExternalId),
            minWidth: 50,
            noWrap: false
        },
        {
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionDateLanguageItemName,
            accessor: nameof.full<VendorInspectionViewModel>(item => item.inspectionDate),
            format: (inspectionDate) => this.formatDate(inspectionDate),
            minWidth: 50,
            noWrap: false
        },
        {
            header: Localizer.genericStatusLanguageItemName,
            accessor: nameof.full<VendorInspectionViewModel>(item => item.status),
            format: (status) => EnumProvider.getAnnualInspectionStatusText(status),
            minWidth: 50,
            noWrap: false
        },
        {
            header: Localizer.deviceManagementRemarksRepairDueDateLanguageItemName,
            accessor: nameof.full<VendorInspectionViewModel>(item => item.remarksDueDate),
            format: (date: Date | null) => date ? this.formatDate(date) : "-",
            minWidth: 50,
            noWrap: false
        },
        {
            header: Localizer.annualInspectionsPageTabRemarksLanguageItemName,
            accessor: nameof.full<VendorInspectionViewModel>(item => item.remarks),
            minWidth: 50,
            noWrap: false
        },
        {
            header: Localizer.deviceManagementRemarksFixedDateLanguageItemName,
            accessor: nameof.full<VendorInspectionViewModel>(item => item.remarksCompletedDate),
            format: (date) => date ? this.formatDate(date) : "-",
            minWidth: 50,
            noWrap: false
        },
        {
            header: Localizer.deviceManagementFixBeforeLeaseLanguageItemName,
            accessor: nameof.full<VendorInspectionViewModel>(item => item.fixBeforeLease),
            format: (fix) => fix ? Localizer.yes : Localizer.no,
            minWidth: 50,
            noWrap: false
        },
    ];

    private formatDate(date: Date): string {
        return ToolsUtility.toDateString(date);
    }

    private async initVendorOperationsAsync(cell: CellModel<AnnualInspectionVendor>): Promise<void> {
        const model: AnnualInspectionVendor = cell.row.model;
        const parent: AnnualInspectionVendor = this.vendor;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = !!model.name;
        const isNew: boolean = !model.id;

        const saveAction: CellAction<AnnualInspectionVendor> = cell.actions[0];
        const cancelAction: CellAction<AnnualInspectionVendor> = cell.actions[1];
        const deleteAction: CellAction<AnnualInspectionVendor> = cell.actions[2];
        const restoreAction: CellAction<AnnualInspectionVendor> = cell.actions[3];
        const editAction: CellAction<AnnualInspectionVendor> = cell.actions[4];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted) && (!parent.isDeleted);
        editAction.visible = !deleted && !modified;
    }

    private getDeleteConfirmation(cell: CellModel<AnnualInspectionVendor>): string {
        const model: AnnualInspectionVendor = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types require returned value to be a string, but has been like this forever
            ? null as any
            : Utility.format(Localizer.annualInspectionVendorsPageConfirmationButtonDelete, `'${cell.model.name}'`);
    }

    private async processVendorOperationAsync(cell: CellModel<AnnualInspectionVendor>, action: CellAction<AnnualInspectionVendor>): Promise<void> {

        await ch.hideAlertAsync();

        const model: AnnualInspectionVendor = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {
                const request = new SaveAnnualInspectionVendorRequest();
                request.name = model.name;
                request.userId = model.user?.id ?? null;
                request.parentId = model.parentId ?? this.vendor.id;

                const response: SaveAnnualInspectionVendorResponse = await AnnualInspectionController.addVendorAsync(request);

                if (response.annualInspectionVendorAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.annualInspectionVendorsPageAlertErrorAsyncVendorExists, model.name), true);
                    return;
                }

                cell.row.model = response.annualInspectionVendor!;

                await this.props.onAddChildVendor(response.annualInspectionVendor!);

            } else {
                const request = new SaveAnnualInspectionVendorRequest();
                request.id = model.id;
                request.name = model.name;
                request.userId = model.user?.id ?? null;
                request.parentId = model.parentId ?? this.vendor.id;

                const response: SaveAnnualInspectionVendorResponse = await AnnualInspectionController.saveVendorAsync(request);

                if (response.annualInspectionVendorAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.annualInspectionVendorsPageAlertErrorAsyncVendorExists, model.name), true);
                    return;
                }

                cell.row.model = response.annualInspectionVendor!;

                await this.props.onChangeChildVendor(response.annualInspectionVendor!, false);
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "edit" && this.props.edit) {
            const vendor = cell.row.model;

            await this.props.edit(vendor);

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            if (isNew) {
                model.isDeleted = true;
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                const response: DeleteAnnualInspectionVendorResponse = await AnnualInspectionController.deleteVendorAsync(model.id);

                if (response.success) {
                    const markedAsDeleted: boolean = (response.markedAsDeletedVendors != null && response.markedAsDeletedVendors.length > 0);

                    if (markedAsDeleted) {
                        // On child level only single vendor can be removed at a time, so if it's marked as deleted then updated model should be in response. 
                        cell.row.model = response.vendor!;

                        await cell.row.bindAsync();
                        await ch.alertWarningAsync(Localizer.vendorDetailsPanelAlertWarningInspectorMarkedAsDeleted.format(model.name));
                        await this.props.onChangeChildVendor(response.vendor!, false);
                    } else {
                        await cell.grid.deleteAsync(cell.row.index);
                        await ch.alertMessageAsync(Localizer.vendorDetailsPanelAlertWarningInspectorRemovedPermanently.format(model.name));
                        await this.props.onChangeChildVendor(model, true);
                    }
                }
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                const response: RestoreAnnualInspectionVendorResponse = await AnnualInspectionController.restoreVendorAsync(model.id);

                model.isDeleted = false;
                await cell.row.bindAsync();

                await this.props.onChangeChildVendor(response.vendor!, false);
            }
        }
    }

    private async onTabSelect(index: number): Promise<void> {
        if (this.state.selectedTabIndex != index) {
            await this.setState({selectedTabIndex: index});
        }
    }

    private get vendor(): AnnualInspectionVendor {
        return this.props.vendor;
    }

    private initRow(row: RowModel<AnnualInspectionVendor>): void {
        const model: AnnualInspectionVendor = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = !!model.name;
        row.deleted = model.isDeleted;
        row.className = (!isValid)
            ? "bg-declined"
            : (isNew)
                ? "bg-approved"
                : "";
    }

    private async fetchInspectorsAsync(): Promise<AnnualInspectionVendor[]> {
        const request = {
            vendorId: this.props.vendor.id,
            includeDeleted: this.props.showDeleted
        } as ListVendorInspectorsRequest;

        const inspectors: AnnualInspectionVendor[] = await AnnualInspectionController.getVendorInspectorsAsync(request);

        return inspectors ?? [];
    }

    private async fetchInspectionsAsync(pageNumber: number, pageSize: number): Promise<IPagedList<VendorInspectionViewModel>> {
        const vendor = this.props.vendor;
        const request = {
            vendorId: vendor.id,
            pageSize: pageSize,
            pageNumber: pageNumber,
        } as ListVendorInspectionsRequest;

        return await AnnualInspectionController.getVendorInspectionsAsync(request);
    }

    private get inspectorsGrid(): GridModel<AnnualInspectionVendor> | null {
        return this._inspectorsGridRef.current?.model ?? null;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.inspectorsGrid)
            ? (this.inspectorsGrid.rows.some(row => !row.deleted && !row.model.id))
            : false;
    }

    private async addAnnualInspectionInspectorVendorAsync(): Promise<void> {
        if (!this.newRowAlreadyExists && (this.inspectorsGrid)) {
            let vendor = new AnnualInspectionVendor();

            const rows: RowModel<AnnualInspectionVendor>[] = await this.inspectorsGrid.insertAsync(0, vendor);

            const row: RowModel<AnnualInspectionVendor> = rows[0];
            const nameCell: CellModel<AnnualInspectionVendor> = row.get("name");
            await nameCell.editAsync(true);
        }
    }

    public render(): React.ReactNode {
        return (
            <div className="col">

                <TabContainer id="vendorDetailsTabs"
                              key={`${this.vendor.id}_vendorDetailsTabs`}
                              onSelect={(tab) => this.onTabSelect(tab.index)}>

                    <Tab id="vendorInspectorsTab"
                         title={Localizer.genericInspectors}>

                        <div className={styles.container}>

                            {
                                (!this.vendor.isDeleted) &&
                                (
                                    <ToolbarContainer className={styles.toolbar}>

                                        <Inline justify={JustifyContent.End}>

                                            <Button id="addInspectorVendor"
                                                    icon={{name: "plus", size: IconSize.Normal}}
                                                    type={ButtonType.Orange}
                                                    title={Localizer.annualInspectionVendorsPageButtonAddVendor}
                                                    onClick={() => this.addAnnualInspectionInspectorVendorAsync()}
                                            />

                                        </Inline>

                                    </ToolbarContainer>
                                )
                            }

                            <Grid id={"inspectorGrid"}
                                  key={`${this.vendor.id}_inspectorGrid`}
                                  ref={this._inspectorsGridRef}
                                  minWidth={"100%"}
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  columns={this._inspectorColumns}
                                  noDataText={Localizer.genericNoData}
                                  initRow={this.initRow}
                                  fetchData={(_) => this.fetchInspectorsAsync()}
                            />
                        </div>

                    </Tab>


                    <Tab id="vendorInspectionsTab"
                         key={`${this.vendor.id}_vendorInspectionsTab`}
                         title={Localizer.genericInspections}>

                        <div className={styles.container}>
                            <Grid id={"inspectionsGrid"}
                                  key={`${this.vendor.id}_inspectionsGrid`}
                                  ref={this._inspectionsGridRef}
                                  minWidth={"100%"}
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  columns={this._inspectionsColumns}
                                  noDataText={Localizer.genericNoData}
                                  pagination={10}
                                  fetchData={(_, pageNumber, pageSize) => this.fetchInspectionsAsync(pageNumber, pageSize)}
                            />
                        </div>

                    </Tab>
                </TabContainer>
            </div>
        );
    }
}