import ReportDefinitionItem from "./ReportDefinitionItem";
import {MeasuringOperators} from "@/models/Enums";

export default class MeasuringReportItem extends ReportDefinitionItem {

    public value: number = 0;

    public ok: boolean | null = null;

    public static isValid(item: MeasuringReportItem): boolean {
        return true;
    }

    public static isOk(item: MeasuringReportItem): boolean {

        switch (item.passedValueOperator) {
            case MeasuringOperators.Less:
                item.ok = item.value < item.passedValue!;
                break;
            case MeasuringOperators.LessOrEqual:
                item.ok = item.value <= item.passedValue!;
                break;
            case MeasuringOperators.Bigger:
                item.ok = item.value > item.passedValue!;
                break;
            case MeasuringOperators.BiggerOrEqual:
                item.ok = item.value >= item.passedValue!;
                break;
            case MeasuringOperators.Equal:
                item.ok = item.value == item.passedValue!
                break;
            case MeasuringOperators.NotEqual:
                item.ok = item.value != item.passedValue!;
                break;
        }
        return item.ok!;
    }
}