import {
  Button,
  ButtonType,
  Inline,
  Modal,
  TextInput,
} from "@renta-apps/athenaeum-react-components";
import React from "react";
import { ICopyReportDefinitionRequest } from "@/models/server/requests/CopyReportDefinitionRequest";
import EndpointPaths from "@/common/EndpointPaths";
import { ch } from "@renta-apps/athenaeum-react-common";
import HttpClient from "@/common/HttpClient";
import Localizer from "@/localization/Localizer";

import styles from "./CopyReportDefinitionModal.module.scss";

export interface ICopyReportDefinitionModalData {
  reportDefinitionId: string;
  definitionName: string;
}

interface ICopyReportDefinitionModalProps {
  modalRef: React.RefObject<Modal<ICopyReportDefinitionModalData>>;
  loadData: () => Promise<void>;
}

const CopyReportDefinitionModal = ({
  modalRef, loadData,
}: ICopyReportDefinitionModalProps) => {
  const [reportDefinitionId, setDefinitionId] = React.useState<string>("");
  const [value, setValue] = React.useState<string>("");
  const [currentDefinitionName, setCurrentDefinitionName] =
    React.useState<string>("");
  const [isLoading, setLoading] = React.useState(false);
  const onOpen = async (
    modal: Modal<ICopyReportDefinitionModalData>,
  ): Promise<void> => {
    if (modal.data?.reportDefinitionId) {
      setDefinitionId(modal.data?.reportDefinitionId);
    }

    if (modal.data?.reportDefinitionId) {
      setCurrentDefinitionName(modal.data?.definitionName);
    }
  };

  const onSave = async () => {
    setLoading(true);
    const request: ICopyReportDefinitionRequest = {
      sourceDefinitionId: reportDefinitionId,
      targetName: value,
    };

    const response = await HttpClient.postAsyncWithoutErrorHandling<boolean>(
      EndpointPaths.ReportDefinition.Copy,
      request,
    );
    
    if (response) {
      await loadData();
      await modalRef.current?.closeAsync();
    }else {
      await ch.alertErrorAsync(Localizer.errorPageTitle);
    }
    
    setLoading(false);
  };

  const onChange = (value: string) => {
    setValue(value);
  };

  return (
    <Modal
      id={"devicesFiltersModal"}
      title={Localizer.copyReportDefinitionModalCopy}
      ref={modalRef}
      onOpen={onOpen}
      closeConfirm={false}
    >
      <Inline className={styles.w100}>
        <TextInput
          readonly
          className={styles.w100}
          id="definitionName"
          name="definitionName"
          label={Localizer.copyReportDefinitionModalSourceDefinitionName}
          value={currentDefinitionName}
        />
      </Inline>

      <Inline className={styles.w100}>
        <TextInput
          id="definitionName"
          name="definitionName"
          className={styles.w100}
          label={Localizer.copyReportDefinitionModalTargetDefinitionName}
          value={value}
          onChange={async (e, inputValue) => onChange(inputValue)}
        />
      </Inline>

      <Button
        id={"applyButton"}
        type={ButtonType.Orange}
        label={Localizer.copyReportDefinitionModalCopy}
        onClick={() => onSave()}
        disabled={isLoading}
      />
    </Modal>
  );
};

export default CopyReportDefinitionModal;
