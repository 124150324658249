import {RouteData} from "@/helpers/CypressHelper";
import EndpointPaths from "@/common/EndpointPaths";
import GridHelper from "@/cypressHelpers/GridHelper";

class AnnualInspectionVendorsPage {
    public elements = {
        vendorsGrid: () => cy.get("#table_vendorsGrid"),
        vendorsGridCell: (cellContent: string, columnNumber: number, exactMatch: boolean = true) => GridHelper.getGridCell(this.elements.vendorsGrid(), cellContent, columnNumber, exactMatch),
        vendorDetailsTab: () => cy.get("#tab_vendorDetailsTabs"),
        inspectorTab: () => cy.get("#tab_vendorInspectorsTab"),
        inspectionsTab: () => cy.get("#tab_vendorInspectionsTab"),
        inspectorGrid: () => cy.get("#table_inspectorGrid"),
        inspectionsGrid: () => cy.get("#table_inspectionsGrid"),
        vendorExpand: () => cy.get('.fa-info-square'),
    };

    public routes = {
        getUsers: {
            path: "api/UserManagement/GetUsers",
            method: "POST"
        } as RouteData,
        listVendors: {
            path: EndpointPaths.AnnualInspectionPaths.ListVendors,
            method: "POST"
        } as RouteData,
        listVendorInspections: {
            path: EndpointPaths.AnnualInspectionPaths.VendorInspections,
            method: "POST"
        } as RouteData,
        listVendorInspectors: {
            path: EndpointPaths.AnnualInspectionPaths.VendorInspectors,
            method: "POST"
        } as RouteData,
    };

    public assertVendors() {
        this.elements.vendorsGrid()
            .find('[class*=athenaeum-grid-gridRow]')
            .not('[class*=athenaeum-grid-details]') // CL Grid component has these "extra" rows between the actual rows;
            .should('have.length', 5);

        this.elements.vendorsGridCell("Renta Oy", 2, true);
    }

    public expandInspectors() {
        this.elements.vendorExpand()
            .eq(0)
            .click();
    }

    public expandVendor() {
        this.elements.vendorExpand()
            .eq(0)
            .click();
    }

    public assertInspectors() {
        this.elements.inspectorGrid()
            .find('[class*=athenaeum-grid-gridRow]')
            .not('[class*=athenaeum-grid-details]') // CL Grid component has these "extra" rows between the actual rows;
            .should('have.length', 2);
    }

    public assertInspections() {
        this.elements.inspectionsGrid()
            .find('[class*=athenaeum-grid-gridRow]')
            .not('[class*=athenaeum-grid-details]') // CL Grid component has these "extra" rows between the actual rows;
            .should('have.length', 7);
    }
}

export default new AnnualInspectionVendorsPage();