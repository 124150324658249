import { useEffect } from "react";

const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string
) => {
    useEffect(() => {
        if (textAreaRef) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            textAreaRef.style.height = "0px";
            const scrollHeight = textAreaRef.scrollHeight;
            
            if(scrollHeight > 100) {
                textAreaRef.style.height = '100px';
            }else {
                textAreaRef.style.height = scrollHeight + "px";
            }
        }
    }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
