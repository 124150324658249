import {RouteData} from "@/helpers/CypressHelper";

class ApplicationSettingsPage {
    public elements = {
        saveSettings: () => cy.get("#saveSettings")
    };

    public routes = {
        saveSettings: {
            path: "api/applicationSettings/saveSettings",
            method: "POST"
        } as RouteData,
    };
}

export default new ApplicationSettingsPage();