import DeviceInfo from "@/models/server/DeviceInfo";
import ServicePredictionInfo from "@/models/server/forecast/ServicePredictionInfo";
import ToolsUtility from "@/helpers/ToolsUtility";
import Localizer from "@/localization/Localizer";

export interface IServicedPredictionInfo {
  item: DeviceInfo;
  includeDate?: boolean;
}

const ServicedPredictionInfo = ({ item, includeDate } : IServicedPredictionInfo) => {
    const predictionInfo: ServicePredictionInfo | null = DeviceInfo.getLatestPredictionInfo(item);

    if (predictionInfo) {

        return (
            <>
                {
                    (includeDate) &&
                    (
                        <div>
                            <p>{Localizer.servicedPageExpectedServiceDate} {(predictionInfo) ? ToolsUtility.toDateString(predictionInfo.date) : "-"}</p>
                        </div>
                    )
                }

                <div>
                    <p>{Localizer.devicePageMaintenanceReasons} {"{0:PredictionMaintenanceReason}".format(predictionInfo.maintenanceReason)}</p>
                </div>
            </>
        )
    }

    return null;
}

export default ServicedPredictionInfo;