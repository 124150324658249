import Localizer from "../../../localization/Localizer";
import DashboardPage from "@/tests/pages/DashboardPage";
import DevicePage from "@/tests/pages/DevicePage";
import DeviceAnnualInspectionsPage from "@/tests/pages/DeviceAnnualInspectionsPage";
import DeviceAnnualInspectionFormPage from "@/tests/pages/DeviceAnnualInspectionFormPage";
import {executeWithIntercept} from "@/helpers/CypressHelper";
import AdminPage from "@/tests/pages/AdminPage";
import DataManagementPage from "@/tests/pages/DeviceManagementPage";
import DeviceManagementPage from "@/tests/pages/DeviceManagementPage";

describe('Device new annual inspection form tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    });

    it('Accept test, second time prompts confirm', () => {

        DashboardPage.openDevice("90011");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.openAccept();

        cy.wait(1500);

        DeviceAnnualInspectionFormPage.submitAnnualInspection();

        DeviceAnnualInspectionsPage.openAccept();

        cy.wait(1500);

        DeviceAnnualInspectionFormPage.submitAnnualInspection(true);
    });

    it('Accept with remarks test', () => {
        DashboardPage.openDevice("90011");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.openWithRemarks();

        DeviceAnnualInspectionFormPage.assertAndFillForm();
        DeviceAnnualInspectionFormPage.assertAndFillRemarks();
        DeviceAnnualInspectionFormPage.submitForm();
    });

    it('Accept with remarks, fixing remarks clears ban of use', () => {
        DashboardPage.openDevice("90011");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.openWithRemarks();

        DeviceAnnualInspectionFormPage.assertAndFillForm();
        DeviceAnnualInspectionFormPage.assertAndFillRemarks();

        DeviceAnnualInspectionFormPage.elements.banOfUseCheckbox().find('label').click();

        DeviceAnnualInspectionFormPage.submitAnnualInspection(true);

        DeviceAnnualInspectionFormPage.elements.banOfUseInfo().contains(`${Localizer.deviceAnnualInspectionPageDeviceUseBan}: ${Localizer.yes}`);

        cy.goToDashboard();
        cy.goToAdminPage();

        executeWithIntercept(() => {

            AdminPage.elements.deviceManagement().click();

        }, [DataManagementPage.routes.listDevices]);

        DeviceManagementPage.searchDevice("90011");

        cy.wait(500);

        DeviceManagementPage.elements.removeBanOfUse().trigger('click');

        DeviceManagementPage.elements.removeBanOfUseConfirmDialog()
            .should('be.visible')
            .find('[data-dismiss]')
            .eq(2)
            .trigger('click');

        cy.goToDashboard();

        DashboardPage.openDevice("90011");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.banOfUseInfo().contains(`${Localizer.deviceAnnualInspectionPageDeviceUseBan}: ${Localizer.no}`);
    });
});