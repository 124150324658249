import React from "react";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Checkbox, ImageInput, InlineType, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ArsenalPageRow from "../ArsenalPageRow/ArsenalPageRow";
import QuestionReportItem from "../../pages/Models/QuestionReportItem";
import ArsenalButton from "../ArsenalButton/ArsenalButton";
import {FaultLevel} from "@/models/Enums";
import ImageProvider from "@/providers/ImageProvider";
import {UploadFullImageResponse} from "@/models/UploadFullImageResponse";
import RentaToolsController from "../../pages/RentaToolsController";
import SpeechRecognitionTextInput from "@/components/SpeechRecognitionTextInput/SpeechRecognitionTextInput";
import Localizer from "../../localization/Localizer";

import styles from "./ArsenalQuestionInput.module.scss";

export interface IArsenalQuestionInputProps {
    className?: string;
    hideTitle?: boolean;
    model: QuestionReportItem;
    preview: boolean;
    noImageInput?: boolean;

    convertImage(file: FileModel): Promise<UploadFullImageResponse | null>;

    imageUploaded?(): Promise<void>;

    renderData?(sender: ArsenalQuestionInput): React.ReactNode;

    onStateChange?() : void;
}

interface IArsenalQuestionInputState {
    model: QuestionReportItem,
    commentHintDanger: boolean
}

export default class ArsenalQuestionInput extends BaseComponent<IArsenalQuestionInputProps, IArsenalQuestionInputState> {

    private readonly _commentRef: React.RefObject<TextAreaInput> = React.createRef();

    state: IArsenalQuestionInputState = {
        model: this.props.model,
        commentHintDanger: false,
    };

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    private onStateChange() {
        if (this.props.onStateChange) {
            this.props.onStateChange();
        }
    }

    private async setOk(ok: boolean): Promise<void> {
        this.model.ok = ok;

        const commented: boolean = !ok || !!this.model.comment;

        await this.setCommentedAsync(commented);

        RentaToolsController.saveContext();

        await this.setState({model: this.model, commentHintDanger: false});

        this.focusComment();

        this.onStateChange();
    }

    private async setComment(comment: string): Promise<void> {
        this.model.comment = comment.substring(0, RentaToolsConstants.maximumReturnInspectionCommentLength);

        RentaToolsController.saveContext();

        await this.setState({model: this.model});

        this.onStateChange();
    }

    private focusComment(): void {
        if ((this.model.commented) && (this._commentRef.current)) {
            if (this.model.commented) {
                this._commentRef.current!.focus();
            }
        }
    }

    private async setCommentedAsync(commented: boolean): Promise<void> {
        if (this.model.commented != commented) {
            this.model.commented = commented;

            if (!commented) {
                this.model.pictures = null;
                this.model.comment = "";
            }

            RentaToolsController.saveContext();

            await this.setState({model: this.model});

            this.focusComment();

            this.onStateChange();
        }
    }

    private async toggleCommented(): Promise<void> {

        if (this.model.ok != false) {
            const commented: boolean = !this.model.commented;
            await this.setCommentedAsync(commented);
        }

        this.focusComment();

        this.onStateChange();
    }

    private async onFileInputChangeHandlerAsync(files: FileModel[]): Promise<void> {
        this.model.pictures = (!files || files.length <= 0)
            ? null
            : files;

        RentaToolsController.saveContext()
        await this.reRenderAsync()

        await this.setState({model: this.model});

        if (this.props.imageUploaded) {
            await this.props.imageUploaded();
        }
    }

    private get isCommentNotOkay(): boolean {
        return ((this.state.commentHintDanger) && !this.model.comment?.length);
    }

    private get noImageInput(): boolean {
        return this.props.noImageInput == true;
    }

    private get pictures(): FileModel[] {

        const pictures: FileModel[] = [];

        //SUPPORT: This is obsolete approach, only for loading historical data.
        if (this.model.picture) {
            pictures.push(this.model.picture);
        }

        pictures.push(...(this.model.pictures || []));

        return pictures;
    }

    private get preview(): boolean {
        return this.props.preview;
    }

    public get model(): QuestionReportItem {
        return this.state.model;
    }

    public get isOk(): boolean {
        return (this.model.ok === true && !this.model.fixed);
    }

    public get isNotOk(): boolean {
        return (this.model.ok === false || this.model.fixed);
    }

    public async componentWillReceiveProps(nextProps: IArsenalQuestionInputProps): Promise<void> {
        await super.componentWillReceiveProps(nextProps);

        await this.setState({model: nextProps.model});
    }

    public async validateAsync(): Promise<void> {
        if (!this.model.commented || !this.model.comment) {
            await this.setState({commentHintDanger: true});
        }
    }

    public async setFaultLevelAsync(major: boolean): Promise<void> {
        this.model.faultLevel = (major) ? FaultLevel.Major : FaultLevel.Minor;
    }

    public async setPermanentFaultAsync(isChecked: boolean): Promise<void> {
        this.model.permanentFault = isChecked;
        await this.reRenderAsync();
    }

    public async setFaultFixedAsync(fixed: boolean): Promise<void> {
        this.model.fixed = fixed;
        this.model.ok = fixed;

        await this.reRenderAsync();
    }

    private async onUploadAsync(model: FileModel): Promise<FileModel | null> {
        if (!this.props.convertImage) {
            return null;
        }

        const response = await this.props.convertImage(model);

        if (!response) {
            return null;
        }

        return response.fileModel;
    }

    public render(): React.ReactNode {

        return (
            <ArsenalPageRow
                className={this.css(
                    styles.arsenalQuestionInput,
                    this.props.className,
                )}
            >
                {!this.props.hideTitle && (
                    <span className={styles.description}>{this.model.title}</span>
                )}

                <ArsenalButton
                    id={"step_ok"}
                    className={this.css(styles.arsenalButtonOk)}
                    big
                    label={Localizer.arsenalQuestionInputStepOk}
                    icon={{name: "fal fa-check-circle"}}
                    disabled={this.preview || this.model.fixed}
                    type={this.isOk ? ButtonType.Success : ButtonType.Unset}
                    onClick={async () => await this.setOk(true)}
                    whiteBg
                />

                <ArsenalButton
                    id={"step_need_repairs"}
                    className={styles.arsenalButtonNeedRepairs}
                    big
                    label={Localizer.arsenalQuestionInputStepNotOk}
                    icon={{name: "far fa-times-circle"}}
                    disabled={this.preview}
                    type={this.isNotOk ? ButtonType.Danger : ButtonType.Unset}
                    onClick={async () => await this.setOk(false)}
                    whiteBg
                />

                <ArsenalButton
                    id={"step_comment"}
                    className={styles.arsenalButtonComment}
                    big
                    label={Localizer.arsenalQuestionInputStepComment}
                    icon={{name: "far fa-comment-alt-dots"}}
                    disabled={this.preview || this.isNotOk}
                    type={this.model.commented ? ButtonType.Blue : ButtonType.Unset}
                    onClick={async () => await this.toggleCommented()}
                    whiteBg
                />

                <div className={styles.operatingHours}>
                    {this.props.renderData && this.props.renderData(this)}
                </div>

                {this.model.commented && (
                    <React.Fragment>
                        <p
                            className={this.css(
                                styles.newCommentLabel,
                                this.isCommentNotOkay && styles.danger,
                            )}
                        >
                            {Localizer.arsenalQuestionInputStepComment}*{" "}
                        </p>
                        <SpeechRecognitionTextInput
                            showRemainingAmount
                            id="comment"
                            textInputRef={this._commentRef}
                            className={this.css(
                                styles.comment,
                                this.isCommentNotOkay && styles.danger,
                            )}
                            rows={4}
                            readonly={this.preview}
                            value={this.model.comment}
                            onLeave={(value) =>
                                this.setComment(value)
                            }
                            maxLength={
                                RentaToolsConstants.maximumReturnInspectionCommentLength
                            }
                        />

                        {this.isCommentNotOkay && (
                            <div
                                id={"comment_validation_message"}
                                data-cy="comment_validation_message"
                                className={this.css(styles.validationError)}
                            >
                                {Localizer.componentArsenalQuestionInputCommentRequired}
                            </div>
                        )}

                        {
                            (!this.noImageInput) && (
                                <div className={styles.picture}>
                                    <div className={styles.pictureLabel}>
                                        {Localizer.returnInspectionAddPicture}
                                    </div>
                                    <ImageInput
                                        minimizeOnEmpty
                                        multi
                                        readonly={this.preview}
                                        pictures={this.pictures}
                                        onChange={async (
                                            sender: ImageInput,
                                            files: FileModel[],
                                        ) => await this.onFileInputChangeHandlerAsync(files)}
                                        maxImageRequestSizeInBytes={
                                            RentaToolsConstants.imageMaxSize
                                        }
                                        convertImage={async (file: FileModel) =>
                                            await this.onUploadAsync(file)
                                        }
                                        fileTypes={[
                                            ...RentaToolsConstants.supportedImageFileTypes,
                                        ]}
                                        imageUrl={(src) =>
                                            ImageProvider.getImageUrl(src)
                                        }
                                    />
                                </div>
                            )
                        }

                        {this.model.faultLevel != null && this.isNotOk && (
                            <div className={styles.checkboxes}>
                                {this.model.minFaultLevel == FaultLevel.Minor && (
                                    <div>
                                        <Checkbox inline
                                                  inlineType={InlineType.Right}
                                                  className={styles.arsenalCheckbox}
                                                  readonly={this.preview}
                                                  label={Localizer.arsenalQuestionInputPermanentFault}
                                                  value={this.model.permanentFault!}
                                                  onChange={async (sender, value) =>
                                                      await this.setPermanentFaultAsync(value)
                                                  }
                                        />
                                        <Checkbox inline
                                                  inlineType={InlineType.Right}
                                                  className={styles.arsenalCheckbox}
                                                  readonly={this.model.permanentFault || this.preview}
                                                  label={Localizer.arsenalQuestionInputFaultLevel}
                                                  value={this.model.faultLevel == FaultLevel.Major}
                                                  onChange={async (sender, value) =>
                                                      await this.setFaultLevelAsync(value)
                                                  }
                                        />
                                    </div>
                                )}

                                <Checkbox inline
                                          inlineType={InlineType.Right}
                                          className={styles.arsenalCheckbox}
                                          readonly={this.model.permanentFault || this.preview}
                                          label={Localizer.arsenalQuestionInputFaultFixed}
                                          value={this.model.fixed}
                                          onChange={async (sender, value) =>
                                              await this.setFaultFixedAsync(value)
                                          }
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </ArsenalPageRow>
        );
    }
}