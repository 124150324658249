import React, {useState} from "react";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import DeviceInfo from "@/models/server/DeviceInfo";
import {GoogleMap, Icon, IconSize, IconStyle, IGoogleMapMarker, Modal, PageHeader} from "@renta-apps/athenaeum-react-components";
import Device from "@/pages/Models/Device";
import ImageProvider from "@/providers/ImageProvider";
import DeviceAttribute from "@/pages/Models/DeviceAttribute";
import PageDefinitions from "@/providers/PageDefinitions";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import ToolsUtility from "@/helpers/ToolsUtility";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import FailedItemsModal from "@/components/DeviceInfoPanel/FailedItemsModal/FaileditemsModal";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import UnleashHelper from "@/helpers/UnleashHelper";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import styles from "./DeviceInfoPanel.module.scss"
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import {ExtendedOperatingHoursResponse} from "@/models/server/ExtendedOperatingHoursResponse";


export interface IDeviceInfoPanelProps {
    device: DeviceInfo;

    renderInfo(): React.ReactNode;

    deviceLocation?: google.maps.LatLngLiteral | null,

    lastReportId?: string | null,

    extendedOperatingHoursResponse? : ExtendedOperatingHoursResponse | null;
}



const DeviceInfoPanel = (props: IDeviceInfoPanelProps) => {

    const [info, setInfo] = useState(true);
    const [map, setMap] = useState(false);

    const _extraInfoModalRef = React.createRef<Modal>();

    const getDevice = (): Device | null => {
        return RentaToolsController.device;
    }

    const attributes = (): DeviceAttribute[] => {
        let attributes = RentaToolsController.device ? RentaToolsController.device.attributes : [];

        if(UnleashHelper.isEnabled(RentaToolsConstants.featureFlagExtendedOphInformation)) {
            attributes = attributes.filter(attribute => attribute.name !== "DataService.Attributes.LastService");
            attributes = attributes.filter(attribute => attribute.name !== "DataService.Attributes.LastServiceOperatingHours");
        }
        
        attributes.forEach(attribute => {
            if ((attribute.name.includes("Date") || RentaToolsConstants.dateAttributes.includes(attribute.name))) {
                attribute.displayValue = ToolsUtility.toDateString(attribute.value.trim());
            }

            if (attribute.value === "true") {
                attribute.displayValue = Localizer.yes;
            } else if (attribute.value === "false") {
                attribute.displayValue = Localizer.no;
            }
        });

        return attributes;
    }

    const localizeAttributeValue = (value: string): string => {
        return Localizer.get(value);
    }

    const openExtraInfoAsync = async (): Promise<void> => {
        if (_extraInfoModalRef.current) {
            await _extraInfoModalRef.current.openAsync();
        }
    }

    const switchTabs = (): void => {
        setInfo(!info);
    }

    const toggleMap = (): void => {
        setMap(!map);
    }

    const openErrorCodesAsync = async (): Promise<void> => {
        await PageRouteProvider.redirectAsync(PageDefinitions.errorCodeRouteRoute);
    }

    const openReportAsync = async (reportId: string): Promise<void> => {
        RentaToolsController.reportPreview = await ReturnInspectionController.getReportAsync(reportId);
        await PageRouteProvider.redirectAsync(PageDefinitions.reportPreviewRoute);
    }

    const renderTitle = (showExclamationMark: boolean = false): React.ReactNode => {
        const device: Device | null = getDevice();
        const deviceProductGroupName = device?.productGroupName;
        const deviceType = device?.type;
        const deviceExternalId = device?.externalId;
        const deviceGroupId = device?.productGroupId;

        return (
            <React.Fragment>

                <div>
                    <div id={"multiTitleDiv"}
                         className={styles.multiTitle}
                         onClick={async () => await openExtraInfoAsync()}>

                        <span id={"deviceProductGroup"} data-cy="product_group" className={styles.deviceProductGroup}>
                            {
                                (showExclamationMark) &&
                                (
                                    <Icon name="fal exclamation-triangle"
                                          className={Utility.css(styles.exclamationMark)}
                                          size={IconSize.Large}
                                          style={IconStyle.Regular}
                                    />
                                )
                            }
                            {deviceProductGroupName} {deviceGroupId}
                        </span>
                        <span id={"deviceType"} data-cy="deviceType" className={styles.deviceType}>{deviceType}</span>
                        <span id={"device_externalId"}>{deviceExternalId}</span>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    const getDeviceMarker = (): IGoogleMapMarker[] => {
        return [
            {
                position: {
                    lat: props.deviceLocation?.lat ?? 0,
                    lng: props.deviceLocation?.lng ?? 0
                }
            }
        ];
    }

    const getStartLatitude = (): number => {
        return RentaToolsConstants.defaultLatitude;
    }

    const getStartLongitude = (): number => {
        return RentaToolsConstants.defaultLongitude;
    }

    const getDeviceOrDefaultLatitude = (): number => {
        return props.deviceLocation?.lat ?? getStartLatitude();
    }

    const getDeviceOrDefaultLongitude = (): number => {
        return props.deviceLocation?.lng ?? getStartLongitude();
    }

    const previewReportAsync = async (reportId: string | null): Promise<void> => {

        if (!reportId) {
            return;
        }

        RentaToolsController.reportPreview = await ReturnInspectionController.getReportAsync(reportId);

        const route = PageDefinitions.reportPreviewRoute;

        await PageRouteProvider.redirectAsync(route);
    }

    const renderContent = (): React.ReactNode => {

        const device: Device | null = getDevice();

        if (UnleashHelper.isEnabled(RentaToolsConstants.featureFlagLocationEnabled) && map) {
            return (
                <GoogleMap height={500}
                           initialCenter={{lat: getDeviceOrDefaultLatitude(), lng: getDeviceOrDefaultLongitude()}}
                           initialZoom={5}
                           markers={getDeviceMarker()}
                />
            )
        }

        return (
            <div className={styles.container}>
                {
                    (info)
                        ?
                        (
                            <div id={"imageDiv"}>

                                {
                                    (device?.picture) &&
                                    (
                                        <div className={styles.picture}
                                             onClick={async () => await previewReportAsync(props.lastReportId ?? null)}
                                             style={ImageProvider.getImageStyle(device.picture)}
                                        />
                                    )
                                }

                                {
                                    (!device?.picture) &&
                                    (
                                        <Icon className={styles.image} name="far forklift"
                                              onClick={async () => await previewReportAsync(props.lastReportId ?? null)}
                                              size={IconSize.X10}
                                        />
                                    )
                                }

                            </div>
                        )
                        :
                        (
                            <div>

                                <table id={"infoTable"}
                                       className={Utility.css(styles.table, "table table-striped")}>
                                    <tbody>

                                    {
                                        attributes().map((attribute, index) => (
                                            <tr id={index.toString()} key={index.toString()}>
                                                <td>
                                                    {Localizer.get(attribute.name)}
                                                </td>
                                                <td>
                                                    {localizeAttributeValue(attribute.displayValue ? attribute.displayValue : attribute.value)}
                                                </td>
                                            </tr>
                                        ))
                                    }

                                    {(UnleashHelper.isEnabled(RentaToolsConstants.featureNextServiceDateEnabled) && device?.nextServiceDate) && (
                                        <tr id="nextServiceDate" key="nextServiceDate">
                                            <td>
                                                {Localizer.devicePageNextServiceDate}
                                            </td>
                                            <td>
                                                {ToolsUtility.toDateString(device?.nextServiceDate)}
                                            </td>
                                        </tr>
                                    )}

                                    {(UnleashHelper.isEnabled(RentaToolsConstants.featureNextServiceDateEnabled) && device?.endLifeTimeDate) && (
                                        <tr id="totalLifeTime" key="totalLifeTime">
                                            <td>
                                                {Localizer.devicePageEndLifeTimeDate}
                                            </td>
                                            <td>
                                                {ToolsUtility.toDateString(device?.endLifeTimeDate)}
                                            </td>
                                        </tr>
                                    )}

                                    {UnleashHelper.isEnabled(RentaToolsConstants.featureFlagExtendedOphInformation) && (
                                        <tr>
                                            <td>
                                                {Localizer.dataServiceAttributesLastService}
                                            </td>
                                            <td>
                                                {ToolsUtility.toDateString(props.extendedOperatingHoursResponse?.lastServiceDate)}
                                            </td>
                                        </tr>

                                    )}

                                    {UnleashHelper.isEnabled(RentaToolsConstants.featureFlagExtendedOphInformation) && (
                                        <tr>
                                            <td>
                                                {Localizer.dataServiceAttributesOperatingHoursSinceLubrication}
                                            </td>
                                            <td>
                                                {props.extendedOperatingHoursResponse?.operatingHoursSinceLastLubrication}
                                            </td>
                                        </tr>
                                    )}

                                    {UnleashHelper.isEnabled(RentaToolsConstants.featureFlagExtendedOphInformation) && (
                                        <tr>
                                            <td>
                                                {Localizer.dataServiceAttributesLastElectricalCheck}
                                            </td>
                                            
                                            <td>
                                                {ToolsUtility.toDateString(props.extendedOperatingHoursResponse?.lastElectricalCheck)}
                                            </td>
                                        </tr>
                                    )}

                                    {UnleashHelper.isEnabled(RentaToolsConstants.featureFlagExtendedOphInformation) && (
                                        <tr>
                                            <td>
                                                {Localizer.dataServiceAttributesNextServiceOperatingHours}
                                            </td>
                                            <td>
                                                {props.extendedOperatingHoursResponse?.nextServiceOperatingHours}
                                            </td>
                                        </tr>
                                    )}
                                    
                                    </tbody>

                                </table>

                            </div>
                        )
                }
            </div>
        );
    }

    const hasActiveFaults = (): boolean => {
        const device: Device | null = getDevice();

        return (device?.activeFaults != null && device.activeFaults.length > 0);
    }
    const failedDeviceStyle = (): string => (hasActiveFaults() ? styles.failedDevice : "");

    const extraInfoDeviceStyle: string = styles.extraInfoDevice;

    return (
        <div data-cy={"device_info_panel"} className={styles.deviceInfoPanel}>
            <PageHeader title={() => renderTitle(hasActiveFaults())}
                        className={Utility.css(rentaToolsStyles.leftPageHeader, failedDeviceStyle(), extraInfoDeviceStyle)}>

                <div className={styles.deviceActions}>

                    {(!map) &&
                        (
                            <ArsenalButton block secret
                                           id={"infobutton"}
                                           label={info
                                               ? Localizer.devicePageInfo
                                               : Localizer.devicePagePicture}
                                           className={Utility.css(rentaToolsStyles.arsenalButton, styles.infoButton)}
                                           onClick={async () => switchTabs()}
                            />
                        )
                    }

                    <FeatureSwitch flagName={RentaToolsConstants.featureFlagLocationEnabled}>
                        {
                            (props.deviceLocation) &&
                            (
                                <ArsenalButton block secret
                                               id={"locationButton"}
                                               label={map ? Localizer.deviceInfoPanelHideMap : Localizer.deviceInfoPanelShowMap}
                                               className={Utility.css(rentaToolsStyles.arsenalButton, styles.locationButton)}
                                               onClick={async () => toggleMap()}
                                />
                            )
                        }
                    </FeatureSwitch>

                    <FeatureSwitch flagName={RentaToolsConstants.featureErrorCodes}>
                        {getDevice()?.supportErrorCodes && (
                            <ArsenalButton block secret
                                           id={"errorCodes"}
                                           label={Localizer.errorCodesErrorCodes}
                                           className={Utility.css(rentaToolsStyles.arsenalButton, styles.locationButton)}
                                           onClick={async () => await openErrorCodesAsync()}
                            />
                        )}
                    </FeatureSwitch>
                </div>

                <FailedItemsModal
                    modalRef={_extraInfoModalRef}
                    openReportAsync={openReportAsync}
                    subTitle={`${getDevice()?.externalId} ${getDevice()?.type}`}
                    deviceExternalId={getDevice()?.externalId!}
                />

            </PageHeader>
            <ArsenalPageRow>
                <div className={Utility.css("col", rentaToolsStyles.noPadding)}>

                    {
                        renderContent()
                    }

                    {
                        props.renderInfo()
                    }
                </div>
            </ArsenalPageRow>
        </div>
    );
}

export default DeviceInfoPanel;