import {NotificationType} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import NotificationSettings from "@/tests/pages/NotificationSettings";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";

describe("Global notification settings", () => {

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
        cy.selectLanguage("English");

        CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagNotificationSettingsEnabled);

        cy.goToGlobalNotificationSettingsPage();
    });

    it('Update global "Wrong service prediction" notification settings', () => {
        NotificationSettings.modifyNotificationSettings(NotificationType.WrongServicePrediction, true);

        cy.get('.athenaeum-alert-flyout')
            .find('span.athenaeum-alert-message')
            .should('contain.text', "Global notification setting saved successfully.");
    });
});