import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import Chainable = Cypress.Chainable;
import DeviceFiltersModal from "@/tests/pages/DeviceFiltersModal";

export enum Chainer {
    CONTAIN = "contain",
    NOTCONTAIN = "not.contain",
    HAVELENGTH = "have.length",
    GTE = "have.length.greaterThan"
}
export type Shouldable = {
    chainer: Chainer;
    value: number | string
}
class ServicedDevicesPage {
    public elements = {
        deviceRows: () => cy.get('*[class^="ServicedDevicesList_deviceListItem"]'),
    }

    public  routes = {
        findServiceReportDefinition: {
            path: "/api/reportDefinition/findServiceReportDefinition",
            method: "POST",
        } as RouteData,
        listUnServicedDevices: {
            path: "/api/device/listUnServicedDevices",
            method: "POST",
        } as RouteData,
        getUnServicedDevicesCount: {
            path: "/api/device/GetUnServicedDeviceCount",
            method: "POST"
        } as RouteData,
        startService: {
            path: "/api/report/startService",
            method: "POST",
        } as RouteData,
        listServiceReports: {
            path: "/api/report/listServiceReports",
            method: "POST",
        } as RouteData,
        getServiceExpenses: {
            path: "/api/classifier/getServiceExpenses",
            method: "POST",
        } as RouteData,
        getUserDepos: {
            path: "/api/depo/getUserDepos",
            method: "POST",
        } as RouteData,
        getOngoingReport: {
            path: "/api/ReturnInspection/GetOngoingReport",
            method: "POST",
        } as RouteData,
        getServiceDevicesFilters: {
            path: "/api/device/getServiceDevicesFilters",
            method: "POST",
        } as RouteData
    };

    public constants = {
        testDeviceId: 'SRListDeviceId',
        testDeviceType: 'S&R LIST TEST DEVICE TYPE',
        testDeviceGroup: 'S&R LIST TEST GROUP',
        testDeviceDepo: '101',
        testRentalObjectGroup: '17'
    }

     public getDeviceRow(device: string) {
        return this.elements.deviceRows().contains('div',device).parent();
    }

     public useFilter(elem: () => Chainable<JQuery<HTMLElement>>, values: string[], where?: string){
        elem().realClick();
        cy.wait(150)
        // Either click options or type value
        values.forEach(val => {
            if (where){
                elem().find(where).contains(val).realClick();
            } else {
                elem().realType(val);
            }
        })

         elem().realClick();

        DeviceFiltersModal.applyServiceListFilters();
     }

     public assertList(items: Shouldable[]) {
        items.forEach(item => {
            this.elements.deviceRows().should(item.chainer, item.value);
        })
     }

     public assertOnly() {
         this.assertList([
                 {chainer: Chainer.CONTAIN, value: this.constants.testDeviceType},
                 {chainer: Chainer.HAVELENGTH, value: 1}
             ]
         );
     }

     public assertNotOnly() {
         this.assertList([
                 {chainer: Chainer.CONTAIN, value: this.constants.testDeviceType},
                 {chainer: Chainer.GTE, value: 1}
             ]
         );
     }
     public assertNotInList() {
         this.assertList([
                 {chainer: Chainer.NOTCONTAIN, value: this.constants.testDeviceType},
                 {chainer: Chainer.GTE, value: 1}
             ]
         );
     }
}

export default new ServicedDevicesPage();