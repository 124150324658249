/**
 * Importing code which is outside of the src folder cannot be done in code which is inside the src folder, so these helpers are here and not in the cypress folder,
 * as they are used in Cypress tests which are inside the src folder.
 */

/**
 * Data of an interceptable route.
 *
 * @see pageData
 * @see intercept
 */
export type RouteData = {
    /**
     * Path where the request is made.
     */
    path: string;

    /**
     * HTTP method the request is made with.
     */
    method: "GET" | "POST";
}

/**
 * @param route Route to intercept.
 * @return Alias of the interception, which can be waited with {@link cy.wait}.
 * @see pageData
 * @see executeWithIntercept
 */
export function intercept(route: RouteData): string {
    const alias: string = route.path + new Date().toISOString();
    cy.intercept(route.method, route.path).as(alias);

    cy.log(`Intercept route '${route.path}' as alias '${alias}'`);

    return `@${alias}`;
}

/**
 * Execute a function and wait for intercepted routes.
 *
 * @param func Function to execute.
 * @param routes Routes which interceptions to register before and wait after function execution.
 * @see intercept
 */
export function executeWithIntercept(func: () => any, routes: RouteData[]): void {
    const interceptions: string[] = routes.map(route => intercept(route));
    func();
    cy.wait(interceptions, {timeout: 25_000});
}

/**
 * Execute a function and wait for /api/Application/OnRedirect.
 * @param func Function to execute.
 * @see executeWithIntercept
 */
export function onRedirect(func: () => any): void {
    executeWithIntercept(
        func,
        [
            {
                path: "/api/Application/OnRedirect",
                method: "POST",
            }
        ]
    );
}
