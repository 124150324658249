 class AnnualInspectionTypesPage {
    public elements = {
        mappings: {
            inspection: {
                uiBlock: () => cy.get("#type_mapping_0"),
                title: () => this.elements.mappings.inspection.uiBlock().find('#type_mapping_title_0'),
                ddl: () => cy.get("#type_ddl_0"),   
                testMapping: () => cy.get("#product_group_mapping_16810"),
                testMapping2: () => cy.get("#product_group_mapping_16811"),
                listItem: () => this.elements.mappings.inspection.ddl().find(".athenaeum-dropdown-item").first(),
                deleteButtons: () => this.elements.mappings.inspection.uiBlock().find("#deleteInspectionTypeMapping")
                
            },
            calibration: {
                uiBlock: () => cy.get("#type_mapping_1"),
                title: () => this.elements.mappings.calibration.uiBlock().find('#type_mapping_title_1'),
                ddl: () => cy.get("#type_ddl_1"),
                listItem: () => this.elements.mappings.calibration.ddl().find(".athenaeum-dropdown-item").first()
            },
            tenYear: {
                uiBlock: () => cy.get("#type_mapping_2"),
                title: () => this.elements.mappings.tenYear.uiBlock().find('#type_mapping_title_2'),
                ddl: () => cy.get("#type_ddl_2"),
                testMapping: () => cy.get("#product_group_mapping_16710"),
                listItem: () => this.elements.mappings.tenYear.ddl().find(".athenaeum-dropdown-item").first()
            },
            deployment: {
                uiBlock: () => cy.get("#type_mapping_3"),
                title: () => this.elements.mappings.deployment.uiBlock().find('#type_mapping_title_3'),
                ddl: () => cy.get("#type_ddl_3"),
                listItem: () => this.elements.mappings.deployment.ddl().find(".athenaeum-dropdown-item").first()
            },
            IBC: {
                uiBlock: () => cy.get("#type_mapping_4"),
                title: () => this.elements.mappings.IBC.uiBlock().find('#type_mapping_title_4'),
                ddl: () => cy.get("#type_ddl_4"),
                listItem: () => this.elements.mappings.IBC.ddl().find(".athenaeum-dropdown-item").first()
            }
        }
    }
    
    
    public validateDropdowns(){
        this.elements.mappings.inspection.ddl()
            .find(".athenaeum-dropdown-item").should("have.length", 4);

        this.elements.mappings.calibration.ddl()
            .find(".athenaeum-dropdown-item").should("have.length", 5);

        this.elements.mappings.tenYear.ddl()
            .find(".athenaeum-dropdown-item").should("have.length", 5);

        this.elements.mappings.deployment.ddl()
            .find(".athenaeum-dropdown-item").should("have.length", 7);

        this.elements.mappings.IBC.ddl()
            .find(".athenaeum-dropdown-item").should("have.length", 7);
    }
    
    public validateMappings() {
        this.elements.mappings.inspection.testMapping()
            .should("contain.text", "MEGA LIFT (16810)");    
        
        this.elements.mappings.tenYear.testMapping()
            .should("contain.text", "HEAVY LIFT (16710)");
        
        this.elements.mappings.inspection.testMapping2()
            .should("contain.text", "TEST GROUP NAME (16811)");
    }

     public addMappings() {
         cy.intercept("POST", "api/mappings/addAnnualInspectionTypeMapping").as("addAnnualInspectionTypeMapping");
         
         this.elements.mappings.inspection.ddl().click();
         this.elements.mappings.inspection.listItem().click();
         cy.waitSuccess("@addAnnualInspectionTypeMapping");      
         
         this.elements.mappings.calibration.ddl().click();
         this.elements.mappings.calibration.listItem().click();
         cy.waitSuccess("@addAnnualInspectionTypeMapping");  
         
         this.elements.mappings.tenYear.ddl().click();
         this.elements.mappings.tenYear.listItem().click();
         cy.waitSuccess("@addAnnualInspectionTypeMapping");      
         
         this.elements.mappings.deployment.ddl().click();
         this.elements.mappings.deployment.listItem().click();
         cy.waitSuccess("@addAnnualInspectionTypeMapping");          
         
         this.elements.mappings.IBC.ddl().click();
         this.elements.mappings.IBC.listItem().click();
         cy.waitSuccess("@addAnnualInspectionTypeMapping");    
         
     }
}

export default new AnnualInspectionTypesPage();