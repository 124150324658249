import React from "react";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Checkbox, Dropdown, InlineType, PageContainer, PageHeader, TextAreaInput, TextInput} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ServiceEditInvoiceModal from "../ServicePreviewPage/ServiceEditInvoiceModal/ServiceEditInvoiceModal";
import EditServiceInvoiceDetailsRequest from "@/models/server/requests/EditServiceInvoiceDetailsRequest";
import Device from "../Models/Device";
import ServiceReport from "@/pages/Models/ServiceReport";
import FaultCheck from "@/pages/DeviceServicePage/FaultCheck/FaultCheck";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import UserContext from "@/models/server/UserContext";
import ServiceAction from "@/pages/Models/ServiceAction";
import ToolsUtility from "@/helpers/ToolsUtility";
import {DeviceCounterType} from "@/models/Enums";
import {CalculateFutureOperatingHoursService} from "@/services/CalculateFutureOperatingHoursService";
import SaveServiceResponse from "@/models/server/responses/SaveServiceResponse";
import DeviceFault from "@/pages/Models/DeviceFault";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import RentaToolsController from "../RentaToolsController";
import TransformProvider from "../../providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../localization/Localizer";

import styles from "./ServicePreviewPage.module.scss";
import rentaToolsStyles from "../RentaTools.module.scss";
import newStyles from "../NewUI.module.scss";

interface IServicePreviewPageProps {
}

interface IServicePreviewPageState {
    serviceReport: ServiceReport;
    maxOperatingHours: number | undefined;
}

export default class ServicePreviewPage extends AuthorizedPage<IServicePreviewPageProps, IServicePreviewPageState> {

    private readonly _editInvoiceModalRef: React.RefObject<ServiceEditInvoiceModal> = React.createRef();

    state: IServicePreviewPageState = {
        serviceReport: RentaToolsController.servicePreview!,
        maxOperatingHours: undefined,
    };

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get title(): string {
        // Old: dd.MM.yyyy
        const date: string = ToolsUtility.toDateString(this.state.serviceReport.date);
        const titleText: string = `{0}\n {1}\n {2} {3}`;
        return titleText.format(
            this.device.type,
            this.device.externalId,
            ServiceReport.getMaintenanceDescription(this.state.serviceReport),
            date
        );
    }

    public getManual(): string {
        return Localizer.reportPreviewGetManual;
    }

    private getFaultTitle(fault: DeviceFault) {
        if (fault.isManualFault) {
            return Localizer.genericManualFault;
        }

        return `${fault.stepNumber}. ${fault.step?.name || fault.step?.title}.`
    }

    private getDeviceOperatingHours(): number {
        return RentaToolsController.getDeviceCounter(this.device, DeviceCounterType.TotalOperatingHours) || 0
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const operatingHours: number | null = (this.device.supportsOperatingHours)
            ? this.getDeviceOperatingHours()
            : null;

        const maxOperatingHours: number | undefined = this.state.serviceReport.nextOperatingHours ?? CalculateFutureOperatingHoursService.calculateMaxOperatingHoursFromLastService(operatingHours, this.device);

        await this.setState({maxOperatingHours});
    }

    private async openEditInvoiceModalAsync(): Promise<void> {
        await this._editInvoiceModalRef.current!.openAsync(this.state.serviceReport, this.device);
    }

    private async updateDetailsAsync(request: EditServiceInvoiceDetailsRequest): Promise<boolean> {
        const response: SaveServiceResponse = await RentaToolsController.editServiceAsync(request);

        if (response.success) {
            await this.setState({serviceReport: response.report!});

            await this.alertMessageAsync(Localizer.servicePreviewPageInvoiceDetailsUpdatedAlert, true);

            return true;
        }

        return false;
    }

    public get canModify(): boolean {
        const context: UserContext = this.getContext();
        const depoId: string | null = this.state.serviceReport.depoId;
        return UserContext.canEditServices(context, depoId);
    }

    private isItemSelected(serviceActions: ServiceAction[], action: ServiceAction) {
        return serviceActions.find(a => a.id == action.id) != null;
    }

    private hasFaults(): boolean {
        return !!(this.state.serviceReport.faults && this.state.serviceReport.faults.length > 0);
    }

    private showRepairDuration(): boolean {
        return (this.hasFaults() || this.state.serviceReport.isRepairWithNoFaults);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.servicePreview, newStyles.pageContainer)}>

                <PageHeader className={this.css(rentaToolsStyles.leftPageHeader)} title={this.title}/>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <h5>{Localizer.deviceServicePageGeneralHeader}</h5>

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <TextInput label={Localizer.reportPreviewInspector}
                                       placeholder={"-"}
                                       value={TransformProvider.toString(this.state.serviceReport.user)}
                                       readonly={true}
                            />

                        </div>

                    </ArsenalPageRow>

                    {
                        (this.state.serviceReport.operatingHours != null) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <span>{Localizer.deviceServicePageLabelOperatingHours}: {this.state.serviceReport.operatingHours}</span>

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                </div>

                {
                    (this.hasFaults()) &&
                    (
                        <div className={styles.faultsContainer}>

                            <ArsenalPageRow className={this.css(styles.faultChecks, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <h5>{Localizer.deviceServicePageFaultsHeader}</h5>

                                </div>

                            </ArsenalPageRow>

                            {
                                this.state.serviceReport.faults!.map((fault) =>
                                    (
                                        <ArsenalPageRow key={fault.id} className={this.css(styles.faultChecks, newStyles.row)}>

                                            <div className={this.css("w-100", newStyles.col)}>

                                                <FaultCheck title={this.getFaultTitle(fault)}
                                                            comment={ReportDefinitionItem.getComment(fault) || undefined}
                                                            createdBy={fault.createdBy}
                                                            createdAt={fault.createdAt}
                                                            fixedBy={fault.fixedBy}
                                                            fixedAt={fault.fixedAt}
                                                            value={fault.fixed}
                                                            readonly={true}
                                                />

                                            </div>

                                        </ArsenalPageRow>
                                    )
                                )
                            }
                        </div>
                    )
                }

                {
                    (this.showRepairDuration() && this.state.serviceReport.repairDuration) &&
                    (
                        <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                            <div className={this.css("w-100", newStyles.col)}>

                                <span>{Localizer.deviceServicePageRepairDuration}: {this.state.serviceReport.repairDuration}</span>

                            </div>

                        </ArsenalPageRow>
                    )
                }

                <div className={styles.sectionContainer}>

                    {
                        (this.state.serviceReport.serviceTypeName) &&
                        (
                            <>

                                <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                    <div className={this.css("w-100", newStyles.col)}>

                                        <h5>{Localizer.deviceServicePageServiceActionsHeader}</h5>

                                    </div>

                                </ArsenalPageRow>
                                <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                    <div className={this.css("w-100", newStyles.col)}>

                                        <TextInput label={Localizer.reportsPageReportInformationServiceType}
                                                   value={this.state.serviceReport.serviceTypeName}
                                                   readonly={true}
                                        />

                                    </div>

                                </ArsenalPageRow>
                            </>
                        )
                    }

                    {
                        (this.state.serviceReport.actions && this.state.serviceReport.actions.length > 0) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                {
                                    this.state.serviceReport.actions.map((action) =>

                                        <div className={this.css("w-100", newStyles.col)}>

                                            <Checkbox inline
                                                      className={this.css(styles.checkbox, styles.checkbox)}
                                                      inlineType={InlineType.Right}
                                                      label={(action.description) ? `${action.name}: ${action.description}` : action.name}
                                                      value={this.isItemSelected(this.state.serviceReport.actions, action)}
                                                      readonly={true}
                                            />

                                        </div>
                                    )
                                }

                            </ArsenalPageRow>
                        )
                    }

                    {
                        (this.state.serviceReport.comment) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <TextAreaInput className={styles.comment}
                                                   value={this.state.serviceReport.comment}
                                                   readonly={true}
                                    />

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                    {
                        (this.state.serviceReport.serviceDuration) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <span>{Localizer.deviceServicePageServiceDuration}: {this.state.serviceReport.serviceDuration}</span>

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                </div>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <h5>{Localizer.deviceServicePageLocationHeader}</h5>

                        </div>

                    </ArsenalPageRow>

                    {
                        (this.state.serviceReport.depo) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <TextInput label={Localizer.deviceServicePageLocationLabelDepot}
                                               value={this.state.serviceReport.depo.name!}
                                               readonly={true}
                                    />

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                    {
                        (this.state.serviceReport.locationType != null) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <Dropdown
                                        className={this.css(newStyles.dropdown)}
                                        label={Localizer.deviceServicePageLocationLabelLocationType}
                                        items={[EnumProvider.getServiceLocationTypeItem(this.state.serviceReport.locationType)]}
                                        selectedItem={EnumProvider.getServiceLocationTypeItem(this.state.serviceReport.locationType)}
                                        disabled={true}
                                    />

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                </div>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <h5>{Localizer.deviceServicePageInvoiceHeader}</h5>

                        </div>

                    </ArsenalPageRow>

                    <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                        <div className={this.css("w-100", newStyles.col)}>

                            <div>
                                <Checkbox inline
                                          inlineType={InlineType.Right}
                                          label={Localizer.deviceServicePageInvoiceLabelServicedByRenta}
                                          value={this.state.serviceReport.servicedByRenta}
                                          readonly={true}
                                />
                            </div>

                        </div>

                    </ArsenalPageRow>

                    {
                        (this.state.serviceReport.serviceExpense) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <TextInput label={Localizer.deviceServicePageInvoiceLabelServiceExpense}
                                               value={this.state.serviceReport.serviceExpense.name}
                                               readonly={true}
                                    />

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                    {
                        (this.state.serviceReport.invoiceNumber) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <TextInput value={this.state.serviceReport.invoiceNumber}
                                               label={Localizer.deviceServicePageInvoiceLabelInvoiceNumber}
                                               readonly={true}
                                    />

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                    {
                        (this.state.serviceReport.cost > 0) &&
                        (
                            <ArsenalPageRow className={this.css(styles.serviceItem, newStyles.row)}>

                                <div className={this.css("w-100", newStyles.col)}>

                                    <TextInput value={this.state.serviceReport.cost.toString()}
                                               label={Localizer.deviceServicePageInvoiceLabelCost}
                                               readonly={true}
                                    />

                                </div>

                            </ArsenalPageRow>
                        )
                    }

                </div>

                <div className={styles.sectionContainer}>

                    <ArsenalPageRow>

                        {
                            (this.canModify) &&
                            (
                                <ArsenalButton block big
                                               id={"open_edit_modal_button"}
                                               type={ButtonType.Orange}
                                               label={Localizer.deviceServicePageLabelEditInvoice}
                                               onClick={async () => this.openEditInvoiceModalAsync()}/>
                            )
                        }

                        <ArsenalButton block big
                                       type={ButtonType.Orange}
                                       label={Localizer.genericReturnCaps}
                                       onClick={async () => PageRouteProvider.back()}/>

                    </ArsenalPageRow>

                    <ServiceEditInvoiceModal ref={this._editInvoiceModalRef}
                                             maxOperatingHours={this.state.maxOperatingHours}
                                             supportsOperatingHours={this.device.supportsOperatingHours}
                                             onSubmit={async (sender, request) => await this.updateDetailsAsync(request)}
                    />

                </div>

            </PageContainer>
        );
    }
}