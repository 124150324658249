import React from "react";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import {BaseAsyncComponent, IBaseAsyncComponentState, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import ServiceReport from "@/pages/Models/ServiceReport";
import Device from "@/pages/Models/Device";
import PageDefinitions from "@/providers/PageDefinitions";
import ArsenalPagination from "@/components/ArsenalPagination/ArsenalPagination";
import GetServiceReportsResponse from "@/models/server/responses/GetServiceReportsResponse";
import {Icon, IconSize, Modal} from "@renta-apps/athenaeum-react-components";
import ToolsUtility from "@/helpers/ToolsUtility";
import TransformProvider from "@/providers/TransformProvider";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import styles from "./ServiceReportsList.module.scss";

interface IServiceReportsListProps {
    device: Device
}

interface IServiceReportsListState extends IBaseAsyncComponentState<IPagedList<ServiceReport>> {
    selectedReport: ServiceReport | null;
    hasServices: boolean,
    hasServicesDuringPrevious30days: boolean;
    pageNumber: number;
}

export default class ServiceReportsList extends BaseAsyncComponent<IServiceReportsListProps, IServiceReportsListState, IPagedList<ServiceReport>> {

    state: IServiceReportsListState = {
        hasServicesDuringPrevious30days: true,
        hasServices: true,
        isLoading: false,
        data: null,
        selectedReport: null,
        pageNumber: 1,
    };

    private readonly _selectedServiceModalRef: React.RefObject<Modal> = React.createRef();

    protected async fetchDataAsync(): Promise<IPagedList<ServiceReport>> {

        const longTimeAgo = new Date(0);
        const response: GetServiceReportsResponse = await RentaToolsController.getServiceReportsAsync(this.props.device.externalId, longTimeAgo, this.state.pageNumber, 10);

        this.state.hasServices = response.hasServices;
        this.state.hasServicesDuringPrevious30days = response.hasServicesDuringPrevious30days;

        return response.serviceReports!;
    }

    private async changePageNumberAsync(pageNumber: number): Promise<void> {
        this.state.pageNumber = pageNumber;
        await this.reloadAsync();
    }

    private async openShortServiceReportInfoAsync(serviceReport: ServiceReport): Promise<void> {
        await this.setState({selectedReport: serviceReport});

        if (this._selectedServiceModalRef.current) {
            await this._selectedServiceModalRef.current.openAsync();
        }
    }

    private async previewServiceReportAsync(serviceReportId: string): Promise<void> {

        RentaToolsController.servicePreview = await RentaToolsController.getServiceReportAsync(serviceReportId);

        await PageRouteProvider.redirectAsync(PageDefinitions.servicePreviewRoute);
    }

    public getManual(): string {
        return Localizer.reportsPageGetManual;
    }

    private get hasServicesDuringPrevious30days(): boolean {
        return this.state.hasServicesDuringPrevious30days;
    }

    private get hasServices(): boolean {
        return this.state.hasServices;
    }

    protected getEndpoint(): string {
        return "";
    }

    public isAsync(): boolean {
        return true;
    }

    public render(): React.ReactNode {

        const serviceHistoryMessage: string = (!this.hasServices)
            ? Localizer.serviceReportsPageNoServices
            : (!this.hasServicesDuringPrevious30days)
                ? Localizer.serviceReportsPageNoServicesDuringPrevious30days
                : "";

        return (
            <div className={styles.serviceReportsList}>

                <div>{serviceHistoryMessage}</div>

                {
                    (this.state.data != null) &&
                    (
                        <React.Fragment>

                            <table className={styles.table}>
                                <tbody>
                                {
                                    this.state.data.items.map((item, index) =>
                                        (
                                            <tr key={index}>

                                                <td className={this.css(styles.icon, styles.minWidth)}>
                                                    <span title={ServiceReport.getMaintenanceDescription(item)}>
                                                        {ServiceReport.getMaintenanceDescription(item, false, true)}
                                                    </span>
                                                </td>

                                                <td className={styles.info}>
                                                    <span onClick={async () => await this.previewServiceReportAsync(item.id)}>
                                                        <div className={styles.report}>
                                                            {ToolsUtility.toDateString(item.date)} {(!this.mobile && item.serviceTypeName) && item.serviceTypeName}
                                                        </div>
                                                        {
                                                            (!this.mobile) &&
                                                            (
                                                                <div className={styles.user}>
                                                                    {(item.user) && (TransformProvider.userToString(item.user))}
                                                                </div>
                                                            )
                                                        }
                                                        <div className={styles.depo}>
                                                            {(item.depo) && (item.depo.costPool)}
                                                        </div>
                                                    </span>
                                                </td>

                                                {
                                                    (this.mobile) &&
                                                    (
                                                        <td className={styles.icon}>
                                                                <span onClick={async () => await this.openShortServiceReportInfoAsync(item)}>
                                                                    <Icon name="far info" size={IconSize.Large}/>
                                                                </span>
                                                        </td>
                                                    )
                                                }

                                            </tr>
                                        ))
                                }
                                </tbody>
                            </table>

                            <ArsenalPagination pageNumber={this.state.pageNumber}
                                               pageCount={this.state.data.pageCount}
                                               onChange={async (pageNumber) => await this.changePageNumberAsync(pageNumber)}
                            />

                        </React.Fragment>
                    )
                }

                <Modal ref={this._selectedServiceModalRef}
                       title={Localizer.serviceReportsPageServiceInformation}
                       subtitle={`${this.props.device.name}`}>
                    {
                        (this.state.selectedReport) &&
                        (
                            <div>

                                <p>{Localizer.reportsPageReportInformationServiceType}: {ServiceReport.getMaintenanceDescription(this.state.selectedReport, true)}</p>

                                {(this.state.selectedReport.comment) && (<p><i>{this.state.selectedReport.comment}</i></p>)}

                                <p>{Localizer.reportsPageReportInformationInspectedBy} "{TransformProvider.toString(this.state.selectedReport.user)}" {Localizer.reportsPageReportInformationAt} {ToolsUtility.toDateString(this.state.selectedReport.completedAt)}.</p>

                                {
                                    (this.state.selectedReport.depo) &&
                                    (
                                        <p>{Localizer.reportsPageReportInformationDepot}: "{TransformProvider.toString(this.state.selectedReport.depo.costPool)}"</p>
                                    )
                                }

                            </div>
                        )
                    }
                </Modal>

            </div>
        );
    }
}