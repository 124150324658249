import React, {RefObject, useEffect, useState} from "react";
import {
  Icon,
  ITextAreaInputProps,
  TextAreaInput,
} from "@renta-apps/athenaeum-react-components";
import { ch } from "@renta-apps/athenaeum-react-common";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import LanguageService from "@/services/LanguageService";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import styles from "./SpeechRecognitionTextInput.module.scss";

export interface ISpeechRecognitionTextInputProps extends ITextAreaInputProps {
  textInputRef?: RefObject<TextAreaInput>;
  onLeave : (text : string) => void;
}

const SpeechRecognitionTextInput = (
  props: ISpeechRecognitionTextInputProps,
) => {
  const [value, setValue] = useState("");

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();


  useEffect(()=>{
    if(props.value){
      setValue(props.value);
    }
  }, [props.value])

  const startListening = async () => {
    const language = LanguageService.getLanguageNameForSpeechRecognition(
      ch.language,
    );

    await SpeechRecognition.startListening({
      continuous: true,
      language: language,
    });
  };

  const stopListening = async () => {
    await SpeechRecognition.stopListening();

    setTimeout(() => {
      setValue(transcript);

      if (props.textInputRef?.current && !!transcript) {
        onChange(props.textInputRef?.current!, transcript);
        props.onLeave(transcript);
      }

      resetTranscript();
    }, 100); //100ms to make sure that transcription is fully processed
  };

  const onChange = async (sender: TextAreaInput, value: string) => {
    if (props.onChange) {
      await props.onChange(sender, value);
    }
    setValue(value);
  };

  const speechRecognitionSupported: boolean =
    browserSupportsSpeechRecognition &&
    LanguageService.isLanguageSupported(ch.language);

  const speechRecognitionEnabled: boolean = UnleashHelper.isEnabled(
    RentaToolsConstants.speechRecognitionEnabled,
  );

  return (
    <div className={styles.textInputWrapper}>
      {speechRecognitionEnabled && (
        <>
          {!listening && (
            <TextAreaInput
              {...props}
              ref={props.textInputRef}
              value={value}
              onChange={onChange}
              onBlur={async () => props.onLeave(value)}
            />
          )}
          {listening && (
            <TextAreaInput
              {...props}
              ref={props.textInputRef}
              value={transcript}
              placeholder={Localizer.speechRecognitionSpeakNow}
            />
          )}
          {speechRecognitionSupported && (
            <div className={styles.textInputTooltip}>
              {!listening && (
                <Icon
                  name="fas fa-microphone"
                  className={styles.microphoneButton}
                  onClick={startListening}
                />
              )}
              {listening && (
                <Icon
                  name="fas fa-stop-circle"
                  className={styles.stopButton}
                  onClick={stopListening}
                />
              )}
            </div>
          )}
        </>
      )}

      {!speechRecognitionEnabled && (
        <>
          <TextAreaInput
            {...props}
            ref={props.textInputRef}
            value={value}
            onChange={onChange}
            onBlur={async () => props.onLeave(value)}
          />
        </>
      )}
    </div>
  );
};

export default SpeechRecognitionTextInput;