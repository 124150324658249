import React from "react";
import {NumberWidget} from "@renta-apps/athenaeum-react-components";
import MeasuringReportItem from "@/pages/Models/MeasuringReportItem";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import RentaToolsController from "../RentaToolsController";

import rentaToolsStyles from "../RentaTools.module.scss";
import newStyles from "../NewUI.module.scss";

export interface IMeasuringResourcePageProps {
}

interface IMeasuringResourcePageState extends IReturnInspectionWizardPageState {
}

export default class MeasuringResourcePage extends ReturnInspectionWizardPage<IMeasuringResourcePageProps, IMeasuringResourcePageState> {

    state: IMeasuringResourcePageState = {};

    private async setValueAsync(value: number): Promise<void> {
        this.reportItem.value = value;
        RentaToolsController.saveContext();
    }

    private get reportItem(): MeasuringReportItem {
        return this.getReportItem<MeasuringReportItem>();
    }

    private get min(): number {
        return (this.reportItem.min == null) ? RentaToolsConstants.intMinValue : this.reportItem.min;
    }

    private get max(): number {
        return (this.reportItem.max == null) ? RentaToolsConstants.intMaxValue : this.reportItem.max;
    }

    public getWizardDescription(): string {
        return "";
    }

    public get reportItemValueOrDefault(): number {
        return (this.reportItem.value != null) ? this.reportItem.value! : this.reportItem.default!;
    }

    public async nextAsync(): Promise<void> {
        this.reportItem.ok = MeasuringReportItem.isOk(this.reportItem)
        RentaToolsController.saveContext();
        await super.nextAsync();
    }

    public renderContent(): React.ReactNode {

        return (
            <React.Fragment>

                <NumberWidget className={this.css(rentaToolsStyles.arsenalNumberWidget, newStyles.numberWidget)}
                              format={((this.reportItem.step != null) && (this.reportItem.step >= 1.0)) ? "0" : "0.0"}
                              step={this.reportItem.step || 0.0}
                              min={this.min}
                              max={this.max}
                              readonly={this.preview}
                              value={this.reportItemValueOrDefault}
                              onChange={async (sender, value) => await this.setValueAsync(value)}/>

            </React.Fragment>
        );
    }
}