import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {ButtonType} from "@renta-apps/athenaeum-react-components";
import React, {ReactNode} from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import NavigationModuleModal from "@/components/NavigationModule/NagivationModule";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ExpandableButtonContainer from "@/components/ExpandableButtonContainer/ExpandableButtonContainer";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import UnleashHelper from "@/helpers/UnleashHelper";

import rentaToolsStyles from "@/pages/RentaTools.module.scss";

export interface IReturnInspectionButtonsProps{
    continueAsync() : Promise<void>;
    backAsync() : Promise<void>;
    steps : ReportDefinitionItem[];
    showNextButton : boolean;
    backTitle : string;
    continueTitle : string;
    nextButtonDisabled: boolean;
    renderExitButton? : ReactNode;
}

export default class ReturnInspectionButtons extends BaseComponent<IReturnInspectionButtonsProps> {
    private readonly _navigationModule: React.RefObject<NavigationModuleModal> = React.createRef();

    private openNavigationModule = async () => {
        await this._navigationModule.current?.openAsync();
    }

    private get isNavigationModuleEnabled(): boolean {
        return UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiNavigationModuleEnabled);
    }

    private get isMobile(): boolean {
        return ch.mobile;
    }

    private get isFeatureFlagRiNavigationEnabled(): boolean {
        return UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiNavigationModuleEnabled);
    }

    render() {
        return (
            <div>
                {this.isMobile && (
                    <ExpandableButtonContainer expendedContent={this.props.renderExitButton}>
                        <div>
                            <div className={rentaToolsStyles.actionWrapper}>
                                <div className={this.css(rentaToolsStyles.actionContainer, this.isNavigationModuleEnabled && rentaToolsStyles.actionContainerGrid, !this.isNavigationModuleEnabled && rentaToolsStyles.actionContainerGridTwoButtons)}>
                                    <ArsenalButton action
                                                   id={"ri_nextBack"}
                                                   type={ButtonType.Light}
                                                   label={this.props.backTitle}
                                                   className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                                   icon={{name: 'fas fa-chevron-left', className: rentaToolsStyles.buttonIcon}}
                                                   onClick={this.props.backAsync}
                                    />
                                    <div className={rentaToolsStyles.navigationModule}>
                                        <FeatureSwitch flagName={RentaToolsConstants.featureFlagRiNavigationModuleEnabled}>
                                            <ArsenalButton action
                                                           id={"ri_nextNavigation"}
                                                           type={ButtonType.Orange}
                                                           icon={{name: "fas fa-tasks"}}
                                                           className={this.css(rentaToolsStyles.progressModalButton, rentaToolsStyles.bigNavButton, rentaToolsStyles.progressButton)}
                                                           onClick={this.openNavigationModule}
                                            />
                                        </FeatureSwitch>
                                    </div>
                                    {this.props.showNextButton && (
                                        <ArsenalButton action reverse
                                                       id={"ri_nextButton"}
                                                       className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                                       disabled={this.props.nextButtonDisabled}
                                                       type={ButtonType.Orange}
                                                       label={this.props.continueTitle}
                                                       icon={{name: "fas fa-chevron-right", className: rentaToolsStyles.buttonIconRight}}
                                                       onClick={this.props.continueAsync}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </ExpandableButtonContainer>
                )}

                {!this.isMobile && (
                    <div className={rentaToolsStyles.actionWrapper}>
                        <div className={this.css(rentaToolsStyles.actionContainer, rentaToolsStyles.actionContainerGrid)}>
                            <ArsenalButton action
                                           id={"ri_nextBack"}
                                           className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                           type={ButtonType.Light}
                                           label={this.props.backTitle}
                                           icon={{name: 'fas fa-chevron-left', className: rentaToolsStyles.buttonIcon}}
                                           onClick={this.props.backAsync}
                            />
                            {this.isFeatureFlagRiNavigationEnabled && (
                                <div className={rentaToolsStyles.navigationModule}>
                                    <FeatureSwitch flagName={RentaToolsConstants.featureFlagRiNavigationModuleEnabled}>
                                        <ArsenalButton action
                                                       id={"ri_nextNavigation"}
                                                       className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton, rentaToolsStyles.progressButton)}
                                                       type={ButtonType.Orange}
                                                       icon={{name: "fas fa-tasks"}}
                                                       onClick={this.openNavigationModule}
                                        />
                                    </FeatureSwitch>
                                </div>
                            )}
                            <div>
                                {this.props.renderExitButton}
                            </div>
                            {this.props.showNextButton && (
                                <ArsenalButton action reverse
                                               id={"ri_nextButton"}
                                               className={this.css(rentaToolsStyles.navigationButton, rentaToolsStyles.bigNavButton)}
                                               disabled={this.props.nextButtonDisabled}
                                               type={ButtonType.Orange}
                                               label={this.props.continueTitle}
                                               icon={{name: "fas fa-chevron-right", className: rentaToolsStyles.buttonIconRight}}
                                               onClick={this.props.continueAsync}
                                />
                            )}
                        </div>
                    </div>
                )}
                <NavigationModuleModal
                    ref={this._navigationModule}
                    steps={this.props.steps}/>
            </div>
        )
    }
}