import React, {useEffect, useState} from "react";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Icon, IconSize, Modal} from "@renta-apps/athenaeum-react-components";
import Report from "@/pages/Models/Report";
import Device from "@/pages/Models/Device";
import PageDefinitions from "@/providers/PageDefinitions";
import ToolsUtility from "@/helpers/ToolsUtility";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./InspectionReportsList.module.scss";

interface IInspectionReportsListProps {
    device: Device
}

const InspectionReportsList = (props: IInspectionReportsListProps) => {

    const _returnInspectionPerPage: number = 4;
    const _selectedInspectionModalRef: React.RefObject<Modal> = React.createRef();

    const [data, setData] = useState<Report[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedReport, setSelectedReport] = useState<Report | null>(null);

    useEffect(() => {
        fetchDataAsync().then(data => {
            setData(data)
        })

        return () => {};
    }, [props.device]);

    const showLoadMoreButton = (): boolean => {
        if (data) {
            return data.length === _returnInspectionPerPage * (pageNumber + 1);
        }

        return false;
    }

    const loadMoreData = async (): Promise<void> => {
        if (data) {
            setIsLoading(true)

            const newPageNumber = pageNumber + 1;
            const additionalData = await ReturnInspectionController.getReportsByDeviceExternalId(props.device.externalId, newPageNumber, _returnInspectionPerPage);

            const aggregatedData = [...data, ...additionalData];

            setData(aggregatedData)
            setPageNumber(newPageNumber);
            setIsLoading(false)
        }
    }

    const fetchDataAsync = async (): Promise<Report[]> => {
        return await ReturnInspectionController.getReportsByDeviceExternalId(props.device.externalId, 0, _returnInspectionPerPage);
    }

    const openShortReportInfoAsync = async (report: Report): Promise<void> => {
        setSelectedReport(report);

        if (_selectedInspectionModalRef.current) {
            await _selectedInspectionModalRef.current.openAsync();
        }
    }

    const previewReportAsync = async (report: Report): Promise<void> => {
        report = await ReturnInspectionController.getReportAsync(report.id);

        if (report.skipped) {
            await openShortReportInfoAsync(report);
            return;
        }

        RentaToolsController.reportPreview = report;

        const route = PageDefinitions.reportPreviewRoute;

        await PageRouteProvider.redirectAsync(route);
    }

    const getReportStatus = (report: Report): string => {
        return report.skipped
            ? Localizer.reportsPageReportInformationInspectionSkipped
            : report.passed
                ? Localizer.reportsPageReportInformationInspectionPassed
                : Localizer.reportsPageReportInformationInspectionFailed;
    }

    return (

        <div id={"inspectionReportsList"} className={styles.inspectionReportsList}>

            {
                (data != null && data.length > 0)
                    ? <div/>
                    : <div>{Localizer.reportsPageNoReports}</div>
            }

            {
                (data != null) &&
                (
                    <React.Fragment>

                        <table id={"inspectionReportsTable"} className={styles.table}>
                            <tbody>
                            {
                                data.map((item, index) => (
                                    <tr id={`inspectionReportRow_${index}`} key={index}>
                                        <td id={`inspectionReportStatusIcon_${index}`} className={styles.icon}>
                                                    <span>
                                                    {
                                                        (item.passed)
                                                            ? (<Icon name="far check" size={IconSize.Large} tooltip={Localizer.reportsPageInspectionPassed}/>)
                                                            : (item.skipped)
                                                                ? (<Icon name="fas minus" size={IconSize.Large} tooltip={Localizer.reportsPageInspectionSkipped}/>)
                                                                : (<Icon name="far ban" size={IconSize.Large} tooltip={Localizer.reportsPageInspectionFailed}/>)
                                                    }
                                                    </span>
                                        </td>
                                        <td id={`inspectionReportInfo_${index}`} className={styles.info}>
                                                    <span onClick={async () => await previewReportAsync(item)}>
                                                        <div className={styles.report}>
                                                            {(!ch.mobile) && Localizer.reportReport} {ToolsUtility.toDateString(item.completedAt)}
                                                        </div>
                                                        {
                                                            (!ch.mobile) &&
                                                            (
                                                                <div className={styles.user}>
                                                                    {(item.user) && (TransformProvider.userToString(item.user))}
                                                                </div>
                                                            )
                                                        }
                                                        <div className={styles.depo}>
                                                            {(item.depo) && (item.depo.costPool)}
                                                        </div>
                                                    </span>
                                        </td>
                                        {
                                            (ch.mobile) &&
                                            (
                                                <td className={styles.icon}>
                                                            <span onClick={async () => await openShortReportInfoAsync(item)}>
                                                                <Icon name="far info" size={IconSize.Large}/>
                                                            </span>
                                                </td>
                                            )
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>

                        {showLoadMoreButton() && (
                            <ArsenalButton block
                                           id={"loadMoreButton"}
                                           type={ButtonType.Blue}
                                           disabled={isLoading}
                                           label={Localizer.returnInspectionHistoryPageLoadMore}
                                           onClick={async () => await loadMoreData()}/>
                        )}


                    </React.Fragment>
                )
            }

            <Modal ref={_selectedInspectionModalRef}
                   title={Localizer.reportsPageReportInformationTitle}
                   subtitle={`${props.device.name}`}>
                {
                    (selectedReport) &&
                    (
                        <div>
                            {(selectedReport.comment) && (<p><i>{selectedReport.comment}</i></p>)}

                            <p>{Localizer.reportsPageReportInformationInspectedBy} "{TransformProvider.toString(selectedReport.user)}" {Localizer.reportsPageReportInformationAt} {ToolsUtility.toDateString(selectedReport.completedAt)}.</p>

                            {
                                (selectedReport.depo) &&
                                (
                                    <p>{Localizer.reportsPageReportInformationDepot}: "{TransformProvider.toString(selectedReport.depo.costPool)}"</p>
                                )
                            }

                            {
                                (selectedReport.externalContractId && selectedReport.customerName) &&
                                (
                                    <>
                                        <p>{Localizer.reportsPageReportInformationCustomerName}: "{selectedReport.customerName}"</p>
                                        <p>{Localizer.reportsPageReportInformationContractId}: "{selectedReport.externalContractId}"</p>
                                    </>
                                )
                            }


                            <p>{Localizer.reportsPageReportInformationInspectionResult}: "{getReportStatus(selectedReport)}"</p>

                            {
                                (selectedReport.operatingHours != null) &&
                                (
                                    <React.Fragment>
                                        <p>{Localizer.deviceServicePageLabelOperatingHours}: {selectedReport.operatingHours}</p>
                                        <p>TrackUnit {Localizer.deviceServicePageLabelOperatingHours}: {selectedReport.trackUnitOperatingHours}</p>
                                    </React.Fragment>
                                )
                            }

                        </div>
                    )
                }
            </Modal>

        </div>
    )
}

export default InspectionReportsList;