import React from "react";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {ImageInput} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import PicturesReportItem from "../Models/PicturesReportItem";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import styles from "./PicturesPage.module.scss";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import ImageProvider from "@/providers/ImageProvider";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import FileService from "@/services/FileService";

export interface IPicturesPageProps {
}

interface IPicturesPageState extends IReturnInspectionWizardPageState {
    isLoading: boolean;
}

export default class PicturesPage extends ReturnInspectionWizardPage<IPicturesPageProps, IPicturesPageState> {

    state: IPicturesPageState = {
        isLoading: false,
    };

    private get pictures(): FileModel[] {
        return this.reportItem.pictures || (this.reportItem.pictures = []);
    }

    private get reportItem(): PicturesReportItem {
        return this.getReportItem<PicturesReportItem>();
    }

    protected isNextButtonDisabled(): boolean {
        return this.state.isLoading;
    }

    public getWizardDescription(): string {
        return Localizer.picturePagePhotographDevice;
    }

    public async onImageEditorChange(sender: ImageInput, pictures: FileModel[]) {

        this.reportItem.pictures = (!pictures || pictures.length <= 0)
            ? null
            : pictures;

        RentaToolsController.saveContext()
        await this.reRenderAsync()

        this.setState({
            isLoading: false,
        })
    }

    public async nextAsync(): Promise<void> {
        if (PicturesReportItem.isValid(this.reportItem)) {
            await this.hideAlertAsync();
            await super.nextAsync();
            return;
        }

        await this.alertErrorAsync(Localizer.picturePageAlertUploadPicture, false, true);
    }

    public async startImageUpload(image: FileModel): Promise<FileModel | null> {
        this.setState({
            isLoading: true,
        });

        const response = await FileService.uploadFullImageAsync(image);

        if(response === null){
            return null;
        }

        this.setState({
            isLoading: false,
        });

        return response.fileModel;
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <ArsenalPageRow className={this.css(styles.photoContainer)}>
                    <div className={styles.picture}>
                        <div className={styles.pictureLabel}>
                            {Localizer.returnInspectionAddPictures}
                        </div>
                            <ImageInput multi
                                        noAutoSelect
                                        pictures={this.pictures}
                                        readonly={this.preview}
                                        className="flex-1"
                                        onChange={async (sender: ImageInput, pictures: FileModel[]) => this.onImageEditorChange(sender, pictures)}
                                        maxImageRequestSizeInBytes={RentaToolsConstants.originalImageMaxSize}
                                        convertImage={async (image: FileModel) => await this.startImageUpload(image)}
                                        fileTypes={[...RentaToolsConstants.supportedImageFileTypes]}
                                        imageUrl={image => ImageProvider.getImageUrl(image)}
                            />
                    </div>
                </ArsenalPageRow>

            </React.Fragment>
        );
    }
}