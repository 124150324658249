import NumbersHelper from "@/helpers/NumbersHelper";
import ExpensesPage from "@/tests/pages/ExpensesPage";
import Localizer from "@/localization/Localizer";

let testExpenseName: string = `New test Expense ${NumbersHelper.getRandomInteger(1, 999)}`;
const testExpenseExternalId: string = `${NumbersHelper.getRandomInteger(1, 9999)}`;
const testGenericExpenseName: string = `Generic test Expense ${NumbersHelper.getRandomInteger(1, 999)}`;

const seedDataExpenseToUpdate: string = "Broken side mirror";
const seedDataExpenseToDelete: string = "Unused Additional Expense";

describe("Admin resources and RI' expenses management tests", () => {

    beforeEach(() => {
        cy.viewport(1480, 768);
        cy.login();
        cy.goToDashboard();
        cy.goToAdminPage();
    });


    it('Add new RI additional expense test', () => {
        ExpensesPage.open();
        ExpensesPage.clickAdditionalExpensesTab();

        // Remove generic expense if exists before tests.
        ExpensesPage.unsetGenericAdditionalExpenseIfExists();

        ExpensesPage.elements.additionalExpenseButtons.add().click();

        const unit: string = Localizer.enumExpenseUnitMeter;
        const min: number = 0.5;
        const max: number = 999;
        const defaultValue: number = 1;
        const step: number = 0.5;

        // Fill expense
        ExpensesPage.updateExpense(
            true,
            testExpenseName,
            testExpenseExternalId,
            unit,
            min,
            max,
            defaultValue,
            step,
            true,
            true);

        // Actions (Save)
        ExpensesPage.saveExpense(testExpenseName, true);

        // Assert
        ExpensesPage.assertExpense(
            testExpenseName,
            testExpenseExternalId,
            unit,
            min,
            max,
            defaultValue,
            step,
            "checked",
            "checked");
    });

    it('Update RI additional expense test', () => {
        ExpensesPage.open();
        ExpensesPage.clickAdditionalExpensesTab();

        // Remove generic expense if exists before tests.
        ExpensesPage.unsetGenericAdditionalExpenseIfExists();

        const unit: string = Localizer.enumExpenseUnitPiece;
        const min: number = 10;
        const max: number = 1000;
        const defaultValue: number = 10;
        const step: number = 1;

        // Fill expense
        ExpensesPage.updateExpense(
            false,
            seedDataExpenseToUpdate,
            testExpenseExternalId,
            unit,
            min,
            max,
            defaultValue,
            step,
            true,
            null);

        // Actions (Save)
        ExpensesPage.saveExpense(seedDataExpenseToUpdate, false);

        // Assert
        ExpensesPage.assertExpense(
            seedDataExpenseToUpdate,
            testExpenseExternalId,
            unit,
            min,
            max,
            defaultValue,
            step,
            "unchecked",
            "unchecked");
    });

    it('Delete RI additional expense test', () => {
        ExpensesPage.open();
        ExpensesPage.clickAdditionalExpensesTab();

        // Remove generic expense if exists before tests.
        ExpensesPage.unsetGenericAdditionalExpenseIfExists();

        // Actions (Delete)
        ExpensesPage.deleteExpense(seedDataExpenseToDelete);
        ExpensesPage.elements.additionalExpenseButtons.reload().click();
        ExpensesPage.elements.additionalExpenseButtons.showDeleted().click();

        // Assert
        ExpensesPage.assertDeletedOrRestored(true, seedDataExpenseToDelete);
    });

    it('Restore RI additional expense test', () => {
        ExpensesPage.open();
        ExpensesPage.clickAdditionalExpensesTab();

        // Remove generic expense if exists before tests.
        ExpensesPage.unsetGenericAdditionalExpenseIfExists();

        ExpensesPage.elements.additionalExpenseButtons.showDeleted().click();

        // Actions (Delete)
        ExpensesPage.restoreExpense(seedDataExpenseToDelete);
        ExpensesPage.elements.additionalExpenseButtons.showDeleted().click();
        ExpensesPage.elements.additionalExpenseButtons.reload().click();

        // Assert
        ExpensesPage.assertDeletedOrRestored(false, seedDataExpenseToDelete);
    });

    it('Only single generic RI additional expense can exist test', () => {
        ExpensesPage.open();
        ExpensesPage.clickAdditionalExpensesTab();

        // Remove generic expense if exists before tests.
        ExpensesPage.unsetGenericAdditionalExpenseIfExists();

        ExpensesPage.elements.additionalExpenseButtons.add().click();

        // Fill expense
        ExpensesPage.updateExpense(
            true,
            testGenericExpenseName,
            `${NumbersHelper.getRandomInteger(1, 9999)}`,
            null,
            null,
            null,
            null,
            null,
            true,
            true);

        // Actions (Save)
        ExpensesPage.saveExpense(testGenericExpenseName, true);

        // Assert
        ExpensesPage.assertExpense(
            testGenericExpenseName,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "checked",
            "checked");

        // Create another generic expense should not be possible
        ExpensesPage.elements.additionalExpenseButtons.add().click();

        // Fill expense
        ExpensesPage.updateExpense(
            true,
            testGenericExpenseName + " 2nd",
            `${NumbersHelper.getRandomInteger(1, 9999)}`,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            false);
    });
});