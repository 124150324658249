import ReturnedDeviceList from "@/tests/pages/ReturnedDeviceList";

describe("Return Inspection list tests", () => {
  describe("Technican user should be able to see only his depo devices", () => {
    it("Technican user should be able to see only his depo devices", () => {
      cy.loginAsTechnician();
      cy.visit("Dashboard");
      cy.goToReturnedDevicesPage();

      cy.wait(1000);

      ReturnedDeviceList.validateDepoId("101");
    });
  });
});
