import {Utility} from "@renta-apps/athenaeum-toolkit";

export default class NumbersHelper {
    public static less(x: number, y: number, digits: number = 1): boolean {
        x = Utility.round(x, digits);
        y = Utility.round(y, digits);
        return (x < y);
    }

    public static greater(x: number, y: number, digits: number = 1): boolean {
        x = Utility.round(x, digits);
        y = Utility.round(y, digits);
        return (x > y);
    }

    public static lessOrEqual(x: number, y: number, digits: number = 1): boolean {
        x = Utility.round(x, digits);
        y = Utility.round(y, digits);
        return (x <= y);
    }

    public static greaterOrEqual(x: number, y: number, digits: number = 1): boolean {
        x = Utility.round(x, digits);
        y = Utility.round(y, digits);
        return (x >= y);
    }

    public static getRandomInteger(minValue: number | null = null, maxValue: number | null = null): number {
        if (minValue === null && maxValue === null) {
            // Default range is from 0 to 9999999
            minValue = 0;
            maxValue = 9999999;
        }

        if ((minValue != null && maxValue != null) && (minValue > maxValue)) {
            throw new Error(`A 'minValue' cannot be greater than 'maxValue'. minValue=${minValue}, maxValue=${maxValue}.`);
        }

        if (maxValue != null) {
            return Math.floor(Math.random() * (maxValue + 1));
        }

        if (minValue != null) {
            return Math.floor(Math.random() * (minValue + 1));
        }

        return Math.floor(Math.random() * (maxValue! - minValue! + 1)) + minValue!;
    }

    public static getRandomPhoneNumberFi(): string {
        return `+358${this.getRandomInteger(111111111, 999999999)}`;
    }
}