import styles from "./AdvanceCheck.module.scss";
import CheckButton from "@/components/CheckButton/CheckButton";
import React from "react";
import ExpandableTextInput from "@/components/ExpendableTextInput/ExpandableTextInput";
import { CheckStatus } from "@/models/Enums";
import { Utility } from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";

export interface IAdvanceCheckProps {
  id: string;
  status: CheckStatus;
  canBeSkipped: boolean;
  onChange: (id: string, status: CheckStatus) => void;
  isValid: boolean;
  validationText?: string;
  text: string;
  commentText: string;
  onCommentChange: (id: string, comment: string) => void;
  readonly: boolean;
}

const AdvanceCheck = React.forwardRef<HTMLDivElement, IAdvanceCheckProps>(
  (props, ref) => {
    const buttonClickHandler = (status: CheckStatus) => {
      props.onChange(props.id, status);
    };

    const onCommentChange = (value: string) => {
      props.onCommentChange(props.id, value);
    };

    return (
      <div
        className={Utility.css(
          styles.checkWrapper,
          props.isValid && styles.error,
        )}
        id={props.id}
        ref={ref}
        data-cy="advanced_check"
      >
        <p data-cy={"title"}>{props.text}</p>
        <div className={styles.buttonWrapper}>
          <CheckButton
            id={"yes"}  
            icon={"fal fa-check-circle"}
            buttonType={CheckStatus.Yes}
            buttonText={Localizer.advancedCheckYes}
            selected={props.status === CheckStatus.Yes}
            onClick={buttonClickHandler}
            disabled={props.readonly}
          />

          <CheckButton
            id={"no"}  
            icon={"far fa-times-circle"}
            buttonType={CheckStatus.No}
            buttonText={Localizer.advancedCheckNo}
            selected={props.status === CheckStatus.No}
            onClick={buttonClickHandler}
            disabled={props.readonly}
          />

          <CheckButton
            id={"skip"}  
            icon={"fas fa-arrow-to-right"}
            buttonType={CheckStatus.Skip}
            buttonText={Localizer.skip}
            selected={props.status === CheckStatus.Skip}
            onClick={buttonClickHandler}
            disabled={!props.canBeSkipped || props.readonly}
          />
        </div>

        {[CheckStatus.No, CheckStatus.Skip].includes(props.status) && (
          <ExpandableTextInput
            inputName={"description"}
            label={Localizer.description}
            validationError={
              props.status === CheckStatus.No ? props.isValid : false
            }
            validationText={props.validationText}
            onChange={onCommentChange}
            value={props.commentText}
            disabled={props.readonly}
          />
        )}
      </div>
    );
  },
);

export default AdvanceCheck;