import {ch} from "@renta-apps/athenaeum-react-common";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import UserContext from "@/models/server/UserContext";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import moment from "moment";

export default class ToolsUtility {
    public static toDateStringWithTime(date: Date | string | null | undefined, prefix: string = "", postfix: string = "", format: string = "HH:mm:SS") {
        if (!date || date === '-') {
            return "-";
        }

        if (typeof date === 'string') {
            date = ToolsUtility.createDateFromString(date);
        }

        const dateString: string = ToolsUtility.toDateString(date);

        return `${dateString} ${moment(date).format(format)}`

    }

    public static toDateString(date: Date | string | null | undefined, prefix: string = "", postfix: string = ""): string {
        if (!date || date === '-' || date === '') {
            return "-";
        }

        if (typeof date === 'string') {
            date = ToolsUtility.createDateFromString(date);
        }

        const userContext = ch.getContext() as UserContext;

        let dateFormat: string = "dd-MM-yyyy";
        if (userContext && userContext.dateFormat) {
            dateFormat = userContext.dateFormat;
        }

        let dateString: string = "";
        switch (dateFormat) {
            case RentaToolsConstants.dotYearMonthDay:
                dateString = `${date.getFullYear()}.${Utility.pad(date.getMonth() + 1)}.${Utility.pad(date.getDate())}`;
                break;
            case RentaToolsConstants.dashYearMonthDay:
                dateString = `${date.getFullYear()}-${Utility.pad(date.getMonth() + 1)}-${Utility.pad(date.getDate())}`;
                break;
            case RentaToolsConstants.slashYearMonthDay:
                dateString = `${date.getFullYear()}/${Utility.pad(date.getMonth() + 1)}/${Utility.pad(date.getDate())}`;
                break;

            case RentaToolsConstants.dotMonthDayYear:
                dateString = `${Utility.pad(date.getMonth() + 1)}.${Utility.pad(date.getDate())}.${date.getFullYear()}`;
                break;
            case RentaToolsConstants.dashMonthDayYear:
                dateString = `${Utility.pad(date.getMonth() + 1)}-${Utility.pad(date.getDate())}-${date.getFullYear()}`;
                break;
            case RentaToolsConstants.slashMonthDayYear:
                dateString = `${Utility.pad(date.getMonth() + 1)}/${Utility.pad(date.getDate())}/${date.getFullYear()}`;
                break;

            case RentaToolsConstants.dotDayMonthYear:
                dateString = `${Utility.pad(date.getDate())}.${Utility.pad(date.getMonth() + 1)}.${date.getFullYear()}`;
                break;
            case RentaToolsConstants.dashDayMonthYear:
                dateString = `${Utility.pad(date.getDate())}-${Utility.pad(date.getMonth() + 1)}-${date.getFullYear()}`;
                break;
            case RentaToolsConstants.slashDayMonthYear:
                dateString = `${Utility.pad(date.getDate())}/${Utility.pad(date.getMonth() + 1)}/${date.getFullYear()}`;
                break;
        }

        if (prefix) {
            dateString = `${prefix}${dateString}`;
        }

        if (postfix) {
            dateString = `${dateString}${postfix}`;
        }

        return dateString;
    }

    public static isValidDateFormat(date: Date | string | null | undefined): boolean {
        if (!date || date === '-' || date === '') {
            return false;
        }

        const userContext = ch.getContext() as UserContext;

        let dateFormat: string = "dd-MM-yyyy";
        if (userContext && userContext.dateFormat) {
            dateFormat = userContext.dateFormat;
        }

        return moment(date, dateFormat).isValid();
    }

    public static hasFlag(value: number, flag: number): boolean {
        return (value & flag) == flag;
    }

    public static calculateDaysTillToday(x: Date | string | undefined | null): number {
        x = (typeof x === "string") ? new Date(x) : x;

        const date = moment(x, 'DD-MM-YYYY').startOf('day');
        const today = moment();
        return today.startOf('day').diff(date, 'days');
    }

    public static async initializeGeolocationAsync(): Promise<GeolocationPosition | null> {
        if (navigator.geolocation && navigator.permissions) {
            const result: PermissionStatus = await navigator.permissions.query({name: 'geolocation'});
            const options: PositionOptions = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };

            if (result.state === 'granted' || result.state === 'prompt') {
                // Use a Promise to wait for the geolocation callback
                return new Promise<GeolocationPosition | null>((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(
                        (pos: GeolocationPosition) => {
                            resolve(pos);
                        },
                        (error: GeolocationPositionError) => {
                            reject(error);
                        },
                        options
                    );
                }).catch((error) => {
                    console.error(error);
                    return null;
                });
            } else if (result.state === "denied") {
                console.warn('Location permission denied');
            }
        } else {
            console.warn('Geolocation is not supported by this browser');
        }

        return null;
    }

    public static isNullOrEmpty(value: string | undefined | null): boolean {
        return value === undefined || value === null || value.trim() === "";
    }

    public static createDateFromString(dateString: string): Date {
        const regex: RegExp = new RegExp("([-.])", "g");
        const value: string = dateString.replaceAll(regex, '/');

        let date: Date = new Date(value);

        // Some dates have format of dd.MM.yyyy that Javascript does not accept as date :(
        if (isNaN(date.valueOf())) {
            const parts: string[] = value.split("/");
            const reorderedPartsString = `${parts[1]}/${parts[0]}/${parts[2]}`;
            date = new Date(reorderedPartsString);
        }

        return date;
    }
}