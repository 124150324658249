import User from "../../models/server/User";
import ReportDefinition from "./ReportDefinition";
import Depo from "@/models/server/Depo";
import ReportDefinitionItem from "./ReportDefinitionItem";
import PicturesReportItem from "./PicturesReportItem";
import ResourceReportItem from "./ResourceReportItem";
import QuestionReportItem from "./QuestionReportItem";
import QuestionResourceReportItem from "./QuestionResourceReportItem";
import QuestionPicturesReportItem from "./QuestionPicturesReportItem";
import MeasuringReportItem from "@/pages/Models/MeasuringReportItem";
import ChecksReportItem from "@/pages/Models/ChecksReportItem";
import {ReportItemType} from "@/models/Enums";
import {StepFinishedInfo} from "@/models/server/StepFinishedInfo";
import AdvancedChecksReportItem from "@/pages/Models/AdvancedChecksReportItem";
import {IFaultCode} from "@/models/server/FaultCode";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

export default class Report extends ReportDefinition {

    public reportDefinitionId: string = "";

    public startedAt: Date = new Date();

    public completedAt: Date | null = null;

    public user: User | null = null;

    public passed: boolean = false;

    public deviceExternalId: string = "";

    public skipped: boolean = false;

    public comment: string | null = null;

    public operatingHours: number | null = null;

    public trackUnitOperatingHours: number | null = null;

    public depo: Depo | null = null;

    public isReport: boolean = true;

    public externalContractId: string = "";

    public customerName: string = "";

    public stepFinishedInfo: StepFinishedInfo[] = [];

    public faultCodes: IFaultCode[] = [];

    public static isItemValid(item: ReportDefinitionItem | ReportDefinitionItem[] | null | undefined): boolean {

        if (item) {

            if (Array.isArray(item)) {
                return item.every(item => Report.isItemValid(item));
            }

            switch (item.type) {
                case ReportItemType.Pictures:
                    return PicturesReportItem.isValid(item as PicturesReportItem);

                case ReportItemType.QuestionPictures:
                    return QuestionPicturesReportItem.isValid(item as QuestionPicturesReportItem);

                case ReportItemType.Resource:
                    return ResourceReportItem.isValid(item as ResourceReportItem);

                case ReportItemType.Question:
                    return QuestionReportItem.isValid(item as QuestionReportItem);

                case ReportItemType.QuestionResource:
                    return QuestionResourceReportItem.isValid(item as QuestionResourceReportItem);

                case ReportItemType.MeasuringResource:
                    return MeasuringReportItem.isValid(item as MeasuringReportItem);

                case ReportItemType.Checks:
                    return ChecksReportItem.isValid(item as ChecksReportItem);

                case ReportItemType.AdvancedChecks:
                    return AdvancedChecksReportItem.isValid(item as AdvancedChecksReportItem);

                case ReportItemType.ErrorsCodes:
                    return true;
            }

        }

        return false;
    }

    public static isValid(report: Report | null | undefined): boolean {
        return (report != null) && (report.items != null) && (Report.isItemValid(report.items));
    }

    public static getQuestionPicturesReportItemByFileId(fileId: string, report: Report): QuestionPicturesReportItem {
        const pictureItems: QuestionPicturesReportItem[] = report.items
            .where(item => RentaToolsConstants.imageStepTypes.includes(item.type)) as QuestionPicturesReportItem[];

        return pictureItems
            .where(item =>
                (item.pictures != null && item.pictures.some(picture => picture.id == fileId))
            )[0];
    }

    public static getQuestionPicturesReportItemByStepId(stepId: string, report: Report): QuestionPicturesReportItem {
        const pictureItems: QuestionPicturesReportItem[] = report.items
            .where(item => RentaToolsConstants.imageStepTypes.includes(item.type)) as QuestionPicturesReportItem[];

        return pictureItems.where(item => item.id == stepId)[0];
    }
}