import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import ServicedDevicesPage from "@/tests/pages/ServicedDevicesPage";

class DashboardPage {
  public elements = {
    findDevice: () => cy.get("[id=input_deviceId]", { timeout: 10000 }),
    findDeviceButton: () => cy.get("[id=findDeviceButton]"),
    stopServiceButton: () => cy.get("[id=stopServiceBtn]"),
    form: () => cy.get("[class=athenaeum-form-form]"),
  };

  public routes = {
    findDevice: {
      path: "/api/device/findDevice",
      method: "POST",
    } as RouteData,
    getOngoingReport: {
      path: "api/ReturnInspection/GetOngoingReport",
      method: "POST"
    } as RouteData,
    getDeviceLocation: {
      path: "api/device/getDeviceLocation",
      method: "POST",
    } as RouteData,
    stopService: {
      path: "api/report/stopService",
      method: "POST",
    } as RouteData,
  };

  public openDevice(deviceId: string) {
    cy.wait(100);

      this.elements.findDevice().should("be.visible").wait(500).type(deviceId);

      this.elements.findDevice().invoke('val').as('inputDeviceId');

      cy.wait(500);

      cy.get('@inputDeviceId').then(($inputDeviceId) => {

        cy.wait(200);

        //this $inputDeviceId is string, using method from JQuery wrapper cause errors!!!
        if($inputDeviceId as unknown as string !== deviceId){
          this.elements.findDevice().should("be.visible").clear().type(deviceId);
        }

      })
    executeWithIntercept(() => {
        this.elements.findDeviceButton().click();
    }, [this.routes.findDevice, this.routes.getOngoingReport, this.routes.getDeviceLocation])
  }

  public tryToStopService(): void {
    this.elements.form()
        .then(($el) => {
          if ($el.find("[id=stopServiceBtn]").length > 0) {
            executeWithIntercept(() => {
                  this.elements.stopServiceButton().trigger('click');
                  cy.get('.athenaeum-confirmation-dialog-opened button.athenaeum-button-color_orange')
                      .first()
                      .trigger('click', {force: true});
                },
                [this.routes.stopService]);
          }
        })
  }

  public navigateToServices(): void {
    executeWithIntercept(() => cy.get('#service_devices_dashboard_button').trigger('click'),
        [
          ServicedDevicesPage.routes.getServiceDevicesFilters,
          ServicedDevicesPage.routes.getUnServicedDevicesCount,
          ServicedDevicesPage.routes.listUnServicedDevices
        ]);
  }
}

export default new DashboardPage();