import React from "react";
import {BaseComponent, ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType,
    GridModel,
    IconSize,
    Inline,
    JustifyContent,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import ServiceAction from "@/pages/Models/ServiceAction";
import {ActionType} from "@/models/Enums";
import SaveServiceActionRequest from "@/models/server/requests/SaveServiceActionRequest";
import SaveServiceActionResponse from "@/models/server/responses/SaveServiceActionResponse";
import AddServiceActionRequest from "@/models/server/requests/AddServiceActionRequest";
import GetServiceActionsRequest from "@/models/server/requests/GetServiceActionsRequest";
import GetServiceActionsResponse from "@/models/server/responses/GetServiceActionsResponse";
import DeleteServiceActionResponse from "@/models/server/responses/DeleteServiceActionResponse";
import Localizer from "@/localization/Localizer";

interface IActionPanelProps {
}

interface IActionPanelState {
    showDeleted: boolean;
}

export default class ActionsPanel extends BaseComponent<IActionPanelProps, IActionPanelState> {

    state: IActionPanelState = {
        showDeleted: false
    }

    private readonly _actionsGridRef: React.RefObject<Grid<ServiceAction>> = React.createRef();

    private readonly _actionsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.serviceActionsAndTypesPageActionsPanelGridNameLanguageItemName,
            accessor: "name",
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            },
            minWidth: 195
        },
        {
            header: Localizer.serviceActionsAndTypesPageActionsPanelGridDescriptionLanguageItemName,
            accessor: "description",
            type: ColumnType.Text,
            minWidth: 195
        },
        {
            init: (cell: CellModel<ServiceAction>) => this.initActionsOperations(cell),
            minWidth: 100,
            actions: [
                {
                    name: "save",
                    title: Localizer.serviceActionsAndTypesPageActionsPanelGridSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell: CellModel<ServiceAction>, action: CellAction<ServiceAction>) => await this.processActionsOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.serviceActionsAndTypesPageActionsPanelGridCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell: CellModel<ServiceAction>, action: CellAction<ServiceAction>) => await this.processActionsOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.serviceActionsAndTypesPageActionsPanelGridDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<ServiceAction>) => this.getDeleteConfirmation(cell),
                    callback: async (cell: CellModel<ServiceAction>, action: CellAction<ServiceAction>) => await this.processActionsOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.serviceActionsAndTypesPageActionsPanelGridRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell: CellModel<ServiceAction>, action: CellAction<ServiceAction>) => await this.processActionsOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get serviceActionsGrid(): Grid<ServiceAction> {
        return this._actionsGridRef.current!;
    }

    private get serviceActionsGridModel(): GridModel<ServiceAction> {
        return this.serviceActionsGrid.model;
    }

    private async getServiceActionsAsync(): Promise<ServiceAction[]> {
        const request = new GetServiceActionsRequest();
        request.showDeleted = this.state.showDeleted;

        const response: GetServiceActionsResponse = await this.serviceActionsGridModel.postAsync("api/action/getServiceActions", request);

        return response.serviceActions;
    }

    private initRow(row: RowModel<ServiceAction>): void {
        const model: ServiceAction = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = this.isValid(model);
        row.deleted = model.isDeleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private initActionsOperations(cell: CellModel<ServiceAction>): void {
        const model: ServiceAction = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<ServiceAction> = cell.actions[0];
        const cancelAction: CellAction<ServiceAction> = cell.actions[1];
        const deleteAction: CellAction<ServiceAction> = cell.actions[2];
        const restoreAction: CellAction<ServiceAction> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    private async processActionsOperationAsync(cell: CellModel<ServiceAction>, action: CellAction<ServiceAction>): Promise<void> {
        await ch.hideAlertAsync();

        const model: ServiceAction = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {
                const request = new AddServiceActionRequest();
                request.name = model.name;
                request.description = (model.description) ? model.description : null;

                const response: SaveServiceActionResponse = await cell.grid.postAsync("api/action/addServiceAction", request);

                if (response.serviceActionAlreadyExists) {
                    return await ch.flyoutErrorAsync(Localizer.serviceActionsAndTypesPageActionsPanelGridAlreadyExists);
                }

                cell.row.model = response.serviceAction!;
            } else {
                const request = new SaveServiceActionRequest();
                request.serviceActionId = model.id;
                request.name = model.name;
                request.description = (model.description) ? model.description : null;

                const response: SaveServiceActionResponse = await cell.grid.postAsync("api/action/SaveServiceAction", request);

                if (response.serviceActionAlreadyExists) {
                    return await ch.flyoutErrorAsync(Localizer.serviceActionsAndTypesPageActionsPanelGridAlreadyExists);
                }

                cell.row.model = response.serviceAction!;
            }

            await cell.row.bindAsync();

            await ch.flyoutMessageAsync(Localizer.serviceActionsAndTypesPageActionsPanelGridSaved);

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);

                await ch.flyoutMessageAsync(Localizer.serviceActionsAndTypesPageActionsPanelGridDeletedPermanently);
            } else {

                const response: DeleteServiceActionResponse = await cell.grid.postAsync("api/action/deleteServiceAction", model.id);

                if (response.removedPermanently) {
                    await cell.grid.deleteAsync(cell.row.index);

                    await ch.flyoutMessageAsync(Localizer.serviceActionsAndTypesPageActionsPanelGridDeletedPermanently);
                } else {
                    await cell.row.setDeletedAsync(true);

                    await ch.flyoutMessageAsync(Localizer.serviceActionsAndTypesPageActionsPanelGridDeleted);
                }

                await this.serviceActionsGrid.reloadAsync();
            }
        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await cell.grid.postAsync("api/action/restoreServiceAction", model.id);

                model.isDeleted = false;
            }

            await cell.row.setDeletedAsync(false);
        }
    }

    private getDeleteConfirmation(cell: CellModel<ServiceAction>): string {
        const model: ServiceAction = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types require returned value to be a string, but has been like this forever
            ? null as any
            : Localizer.serviceActionsAndTypesPageActionsPanelGridConfirmation;
    }

    private isValid(depo: ServiceAction): boolean {
        return !!depo.name;
    }

    private async addServiceActionAsync(): Promise<void> {
        const serviceAction: ServiceAction = new ServiceAction();

        const newRows: RowModel<ServiceAction>[] = await this.serviceActionsGridModel.insertAsync(0, serviceAction);

        const newRow: RowModel<ServiceAction> = newRows[0];
        const nameCell: CellModel<ServiceAction> = newRow.get("name");

        await nameCell.editAsync(true);
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.serviceActionsGridModel.reloadAsync();
    }

    render(): React.ReactNode {
        return (
            <div>

                <div className="d-flex justify-content-end">

                    <ToolbarContainer>

                        <Inline justify={JustifyContent.End}>

                            <Checkbox inline
                                      label={Localizer.serviceActionsAndTypesPageActionsPanelShowDeleted}
                                      value={this.state.showDeleted}
                                      onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                            />

                            <Button title={Localizer.serviceActionsAndTypesPageActionsPanelReload} className="ml-1"
                                    icon={{name: "far history", size: IconSize.Large}}
                                    type={ButtonType.Info}
                                    onClick={async () => await this.reloadAsync()}
                            />

                            <Button icon={{name: "plus", size: IconSize.Large}}
                                    type={ButtonType.Orange}
                                    title={Localizer.serviceActionsAndTypesPageActionsPanelAdd}
                                    onClick={async () => await this.addServiceActionAsync()}
                            />

                        </Inline>

                    </ToolbarContainer>

                </div>

                <Grid responsive
                      id={`serviceActionsGrid_${this.id}`}
                      ref={this._actionsGridRef}
                      hovering={GridHoveringType.EditableCell}
                      columns={this._actionsColumns}
                      initRow={(row: RowModel<ServiceAction>) => this.initRow(row)}
                      fetchData={async () => await this.getServiceActionsAsync()}
                />

            </div>
        );
    }
}