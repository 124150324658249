import BaseListDevicesRequest from "./BaseListDevicesRequest";
import DeviceInfo from "../DeviceInfo";
import {DeviceStatus, PredictionTime, ServiceAndRepairsStatuses, ServiceTypeFilter} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

export default class ListUnServicedDevicesRequest extends BaseListDevicesRequest {

    public deviceStatuses: DeviceStatus[] = [];
    
    public pageNumber : number = 0;

    public pageSize : number = 0;

    public serviceStatus : string[] = [];
    
    public servicesTypes : string[] = [];

    public modified : boolean = false;
    
    public predictionTime : PredictionTime = PredictionTime.OneMonth;
    
    public getDate(device: DeviceInfo): Date {
        return device.statusChangedAt;
    }
    
    constructor() {
        super();
        this.serviceStatus = [
            ServiceAndRepairsStatuses.Waiting.toString(),
            ServiceAndRepairsStatuses.InProgress.toString()
        ];
        
        this.servicesTypes = [
            ServiceTypeFilter.Service.toString(),
            ServiceTypeFilter.Repair.toString(),
        ]
    }

    public clear(): void {
        this.deviceStatuses = [];
        this.serviceStatus = [
            ServiceAndRepairsStatuses.Waiting.toString(),
            ServiceAndRepairsStatuses.InProgress.toString()
        ];

        this.servicesTypes = [
            ServiceTypeFilter.Service.toString(),
            ServiceTypeFilter.Repair.toString(),
        ]
        super.clear();
    }

    public nextPage() : void{
        this.pageNumber++;
    }

    public resetPage() : void{
        this.pageNumber = 0;
        this.pageSize = RentaToolsConstants.itemsPerReturnInspectionPage;
    }
}