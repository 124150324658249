import {Utility} from "@renta-apps/athenaeum-toolkit";
import MappingsPage from "@/tests/pages/MappingsPage";
import Localizer from "@/localization/Localizer";


export {}

describe('Mappings page tests', () => {

    beforeEach(() => {
        cy.login();
        cy.visit('/');
    })

    describe('Return inspection mapping tests', () => {

        beforeEach(() => {
            cy.goToMappingsPage();
        })

        it('Opens mappings page and loads data', () => {
            MappingsPage.elements.navTabs().children().should('have.length', 2);
            MappingsPage.elements.navTabs().children().first().find("span").should('have.text', Localizer.mappingsPageTabReturnInspections);
            MappingsPage.elements.navTabs().children().last().find("span").should('have.text', Localizer.mappingsPageTabServices);
        })

        it('Can add new mapping for specific product type', () => {

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("contain.text", Localizer.mappingsPageTextNoCategories);

            MappingsPage.selectCategoryToAdd(MappingsPage.constants.mappingReportDefinitionId1, MappingsPage.constants.categoryHeavy);

            MappingsPage.assertDeviceTypesCount(2);

            MappingsPage.selectFirstDeviceTypeAndSave();

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("not.contain.text", Localizer.mappingsPageTextNoCategories);

            MappingsPage.selectCategoryToAdd(MappingsPage.constants.mappingReportDefinitionId1, MappingsPage.constants.categoryHeavy);

            MappingsPage.assertDeviceTypesCount(1);

            MappingsPage.closeModal();

            MappingsPage.removeFirstCategoryFrom(MappingsPage.constants.mappingReportDefinitionId1);

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("contain.text", Localizer.mappingsPageTextNoCategories);
        })

        it('Can add new mapping for specific product type to different category', () => {

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("contain.text", Localizer.mappingsPageTextNoCategories);

            MappingsPage.selectCategoryToAdd(MappingsPage.constants.mappingReportDefinitionId1, MappingsPage.constants.categoryHeavy);

            MappingsPage.assertDeviceTypesCount(2);

            MappingsPage.selectFirstDeviceTypeAndSave();

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId2).should("not.contain.text", Localizer.mappingsPageTextNoCategories);

            MappingsPage.selectCategoryToAdd(MappingsPage.constants.mappingReportDefinitionId2, MappingsPage.constants.categoryHeavy);

            MappingsPage.assertDeviceTypesCount(1);

            MappingsPage.closeModal();

            MappingsPage.removeFirstCategoryFrom(MappingsPage.constants.mappingReportDefinitionId1);

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("contain.text", Localizer.mappingsPageTextNoCategories);
        })

        it('Select all-button is visible and works', () => {

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("contain.text", Localizer.mappingsPageTextNoCategories);

            MappingsPage.selectCategoryToAdd(MappingsPage.constants.mappingReportDefinitionId1, MappingsPage.constants.categoryHeavy, false, false);

            MappingsPage.elements.deviceTypeDD().find('.athenaeum-form-inputContainer').should('contain.text', '-');

            MappingsPage.elements.selectAllBtn().should('be.visible').click();

            MappingsPage.elements.deviceTypeDD().should('contain.text', Utility.format(Localizer.componentDropdownMultipleSelected, 2));

            MappingsPage.saveSelection();

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("contain.text", MappingsPage.constants.dinolift100TL);

            MappingsPage.elements.mappingContainer(MappingsPage.constants.mappingReportDefinitionId1)
                .should("contain.text", MappingsPage.constants.dinolift105L);
        })
    })
})