import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import DeviceAnnualInspectionFormPage from "@/tests/pages/DeviceAnnualInspectionFormPage";
import Localizer from "@/localization/Localizer";

class DeviceAnnualInspectionsPage {
    public elements = {
        previousInspectionsBtn: () => cy.get("#previousInspectionsButton"),
        fixRemarksBtn: () => cy.get("#fixRemarksButton"),
        acceptBtn: () => cy.get("#acceptButton"),
        withRemarksBtn: () => cy.get("#acceptWithRemarksButton"),
        banOfUseBanner: () => cy.get("#device_ban_of_use_banner"),
        banOfUseInfo: () => cy.get("#ban_of_use_info"),
        infoContainer: () => cy.get("#infoContainer"),
    }

    public routes = {
        inspectionHistory: {
            path: "/api/AnnualInspection/ListDeviceAnnualInspections",
            method: "POST",
        } as RouteData,
    };

    public assertNoBan(): void {
        this.elements.infoContainer()
            .children()
            .should('contain', `${Localizer.deviceAnnualInspectionPageDeviceUseBan}: ${Localizer.no}`);
    }

    validateBanOfUseBanner(bannerText: string): void {
        this.elements
            .banOfUseBanner()
            .should('contain', bannerText);
    }

    public openAccept(): void {
        executeWithIntercept(() => {

            this.elements.acceptBtn().click();

        }, [
            DeviceAnnualInspectionFormPage.routes.listVendors,
            DeviceAnnualInspectionFormPage.routes.getUserDepos,
            DeviceAnnualInspectionFormPage.routes.getAnnualInspectionTypeItemsByProductGroup,
            DeviceAnnualInspectionFormPage.routes.getOperatingHoursLimits
        ]);
    }

    public openWithRemarks(): void {
        executeWithIntercept(() => {

            this.elements.withRemarksBtn().click();

        }, [
            DeviceAnnualInspectionFormPage.routes.getUserDepos,
            DeviceAnnualInspectionFormPage.routes.listVendors,
            DeviceAnnualInspectionFormPage.routes.getAnnualInspectionTypeItemsByProductGroup,
            DeviceAnnualInspectionFormPage.routes.getOperatingHoursLimits
        ]);
    }

    public openFixRemarks(): void {
        executeWithIntercept(() => {

            this.elements.fixRemarksBtn().click();

        }, [
            DeviceAnnualInspectionFormPage.routes.getUserDepos,
            DeviceAnnualInspectionFormPage.routes.listVendors,
            DeviceAnnualInspectionFormPage.routes.getAnnualInspectionTypeItemsByProductGroup,
            DeviceAnnualInspectionFormPage.routes.getOperatingHoursLimits
        ]);
    }
}

export default new DeviceAnnualInspectionsPage();