import React, {ReactNode, useState} from "react";
import styles from "@/components/ExpandableButtonContainer/ExpandableButtonContainer.module.scss";
export interface ButtonContainerProps {
    children : React.ReactNode;
    expendedContent? : ReactNode;
}

const ExpandableButtonContainer = ({ children, expendedContent } : ButtonContainerProps ) => {
    const [isExpended, setExpanded] = useState(false);
    
    const handleExpendedClick = () =>{
        setExpanded(!isExpended);
    }
    
    const hasExpandableContent = () : boolean => {
        return expendedContent !== null;
    }
    
    return (
        <div className={styles.container}>
            {hasExpandableContent() && (
                <>
                    <div className={styles.expandableButtonContainer}>
                        <div className={styles.childrenContainer}>
                         {children}
                        </div>
                        <div>
                            <div>
                                <div className={styles.buttonIcon} onClick={handleExpendedClick}>
                                    {isExpended && (
                                        <i className="fas fa-chevron-up"></i>
                                    )}
                                    {!isExpended && (
                                        <i className="fas fa-chevron-down"></i>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
           
                    {isExpended && (
                        <div className={styles.expandableContent}>
                            {expendedContent}
                        </div>
                    )}
                </>
            )}
            
            <div className={styles.w100}>
            {!hasExpandableContent() && (
                children
            )}
            </div>
            
        </div>
        
    )
}


export default ExpandableButtonContainer;