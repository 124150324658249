import Show from "../Show/Show";
import styles from './Loader.module.scss';

export interface ILoaderProps {
    isLoading : boolean;
}

const Loader = ({ isLoading } : ILoaderProps) => (
    <Show.When isTrue={isLoading}>
        <div className={styles.loaderWrapper}>
            <span className={styles.loader}></span>
        </div>
    </Show.When>
)

export default Loader;