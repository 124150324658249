import React from "react";
import {ch, DataStorageType, PageRouteProvider, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import PageDefinitions from "../../providers/PageDefinitions";
import ServiceReport from "@/pages/Models/ServiceReport";
import UserContext from "@/models/server/UserContext";
import User from "@/models/server/User";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import {
    ButtonType,
    FileInput,
    Form,
    Icon,
    IconSize,
    IconStyle,
    Modal,
    PageContainer,
    QrInput,
    TextInput
} from "@renta-apps/athenaeum-react-components";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import RentaToolsController from "../RentaToolsController";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import { FeatureSwitch } from "@/providers/FeatureSwitch";
import Localizer from "../../localization/Localizer";

import styles from "./DashboardPage.module.scss";
import rentaToolsStyles from "../RentaTools.module.scss";
import newStyles from "../NewUI.module.scss";

interface IDashboardPageProps {
}

interface IDashboardPageState {
    sending: boolean
    ongoingReportDeviceExternalId: string | null
}

export default class DashboardPage extends AuthorizedPage<IDashboardPageProps, IDashboardPageState> {

    public state: IDashboardPageState = {
        sending: false,
        ongoingReportDeviceExternalId: null,
    }

    private readonly _fileInputRef: React.RefObject<FileInput> = React.createRef();
    private readonly _pageInfoModalRef: React.RefObject<Modal> = React.createRef();

    public async initializeAsync(): Promise<void> {
        window.addEventListener("visibilitychange", () => {
            // Save timestamp when the page was hidden
            if (document.visibilityState == "hidden") {
                UserInteractionDataStorage.set(RentaToolsConstants.pageHiddenTimestamp, Utility.utcNow(), DataStorageType.Page);
            }

            // When opening the page again, reload if necessary
            if (document.visibilityState == "visible") {
                const previousTimeStamp: Date = UserInteractionDataStorage.get(RentaToolsConstants.pageHiddenTimestamp);
                const now: Date = Utility.utcNow();
                const diff: number = Utility.diff(now, previousTimeStamp).minutes;

                if (diff > RentaToolsConstants.pageReloadThresholdMinutes) {
                    window.location.reload();
                }
            }
        });

        await super.initializeAsync();

        const ongoingReportDeviceExternalId: string | null = await RentaToolsController.loadOngoingReportDeviceExternalId();

        await this.setState({
            ongoingReportDeviceExternalId
        });

        await this.reRenderAsync();
    }

    private get pageInfoModal(): Modal {
        return this._pageInfoModalRef.current!;
    }

    private async onClosePageInfoModalAsync(): Promise<void> {
        await this.pageInfoModal.closeAsync();
    }

    private async searchDeviceAsync(data: Dictionary<string, any>): Promise<void> {
        const deviceExternalId: string = data.getValue("deviceId") || "";

        await RentaToolsController.searchDeviceAsync(deviceExternalId);
    }

    private async onQrAsync(qrCode: string): Promise<void> {
        if (this.state.sending) {
            return;
        }

        this.setState({sending: true});

        await RentaToolsController.searchDeviceAsync(qrCode);

        this.setState({sending: false});
    }

    private async continueInspectionAsync(): Promise<void> {
        const externalId = await RentaToolsController.loadOngoingReportDeviceExternalId();

        await ReturnInspectionController.continueInspectionAsync(externalId);

    }

    private async findDeviceByImageAsync(picture: FileModel): Promise<void> {
        await RentaToolsController.searchDeviceByImageAsync(picture);
    }

    private async openCameraAsync(): Promise<void> {
        if (this._fileInputRef.current) {
            await this._fileInputRef.current.openAsync();
        }
    }

    private async cancelInspectionAsync(): Promise<void> {
        const externalId = await RentaToolsController.loadOngoingReportDeviceExternalId();

        await ReturnInspectionController.cancelReturnInspectionAsync(externalId);

        await this.setState({
            ongoingReportDeviceExternalId: null,
        })

        await this.reRenderAsync();
    }

    private async continueServiceAsync(): Promise<void> {
        await RentaToolsController.continueServiceAsync();
    }

    private async stopServiceAsync(): Promise<void> {
        await RentaToolsController.stopServiceAsync();
        await this.reRenderAsync();
    }

    private get hasFuelingAndWashingAccess(): boolean {
        const context: UserContext = this.getContext();
        return ((context.user != null) && (User.hasFuelingAndWashingAccess(context.user)));
    }

    private get ongoingReportDeviceExternalId(): string | null {
        return this.state.ongoingReportDeviceExternalId;
    }

    private get ongoingService(): ServiceReport | null {
        return RentaToolsController.ongoingService;
    }

    protected get customInfoModal(): boolean {
        return true;
    }

    public getManual(): string {
        return Localizer.dashboardPageGetManual;
    }

    public manualPropsCallback(): Promise<void> {
        return this.pageInfoModal.openAsync();
    }

    private async redirectToChartsPageAsync() {
        await PageRouteProvider.redirectAsync(PageDefinitions.chartsRoute);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(styles.dashboard, rentaToolsStyles.pageContainer, newStyles.pageContainer)}>

                <ArsenalPageRow>
                    <div className="col">
                        <div
                            className={this.css(newStyles.pageHeader, "d-flex align-items-center justify-content-between")}>

                            <h4>{Localizer.dashboardPageMainMenu}</h4>

                            {
                                !ch.isFinland &&
                                <Icon
                                    className="cursor-pointer"
                                    style={IconStyle.Regular}
                                    size={IconSize.X2}
                                    name={"tachometer-alt-slow"}
                                    onClick={async () => await this.redirectToChartsPageAsync()}
                                />
                            }
                        </div>
                    </div>
                </ArsenalPageRow>

                <ArsenalPageRow className={newStyles.row}>

                    <div className="col">

                        <Form onSubmit={async (_: Form, data) => await this.searchDeviceAsync(data)}>

                            <TextInput required
                                       id="deviceId"
                                       className={newStyles.input}
                                       onValidationError={() => Localizer.dashboardPageDeviceIdRequired}
                                       placeholder={Localizer.dashboardInsertRentaID}
                            />

                            <div className={styles.searchButtons}>

                                <ArsenalButton block submit big
                                               id={"findDeviceButton"}
                                               type={ButtonType.Orange}
                                               className={newStyles.button}
                                               label={Localizer.dashboardFindDevice}
                                               icon={{name: "fa-search"}}
                                />

                                {
                                    (this.isSweden) &&
                                    (
                                        <QrInput autoClose closeOnQr
                                                 buttonType={ButtonType.Orange}
                                                 onQr={async (code) => await this.onQrAsync(code)}
                                        />
                                    )
                                }

                                {

                                    <FeatureSwitch flagName={RentaToolsConstants.featureFlagRecognitionEnabled}>

                                        <FileInput dropZone hidden
                                                   id={`_picture_${this.id}`}
                                                   ref={this._fileInputRef}
                                                   onChange={(sender, value: FileModel) => this.findDeviceByImageAsync(value as FileModel)}
                                        />

                                        <ArsenalButton block big
                                                       type={ButtonType.Orange}
                                                       className={newStyles.button}
                                                       label={Localizer.dashboardFindDeviceByPicture}
                                                       icon={{name: "far fa-camera"}}
                                                       onClick={() => this.openCameraAsync()}
                                        />

                                    </FeatureSwitch>

                                }

                            </div>

                            {
                                (this.ongoingReportDeviceExternalId != null) &&
                                (
                                    <ArsenalButton block big
                                                   id={"continueInspectionBtn"}
                                                   className={this.css(rentaToolsStyles.inspectionActionButtonStyles, rentaToolsStyles.reverseContent)}
                                                   type={ButtonType.Light}
                                                   icon={{name: "arrow-circle-right", style: IconStyle.Regular, className: rentaToolsStyles.paddingLeft}}
                                                   label={Localizer.get(Localizer.dashboardContinueInspection, this.ongoingReportDeviceExternalId)}
                                                   onClick={() => this.continueInspectionAsync()}
                                    />
                                )
                            }

                            {
                                (this.ongoingReportDeviceExternalId != null) &&
                                (
                                    <ArsenalButton block big
                                                   id={"stopInspectionBtn"}
                                                   className={this.css(rentaToolsStyles.inspectionActionButtonStyles, rentaToolsStyles.reverseContent)}
                                                   type={ButtonType.Light}
                                                   icon={{name: "times-circle", style: IconStyle.Regular, className: rentaToolsStyles.paddingLeft}}
                                                   label={Localizer.get(Localizer.dashboardCancelInspection, this.ongoingReportDeviceExternalId)}
                                                   confirm={Localizer.get(Localizer.dashboardCancelInspectionConfirmation, this.ongoingReportDeviceExternalId)}
                                                   onClick={() => this.cancelInspectionAsync()}
                                    />
                                )
                            }

                            <FeatureSwitch flagName={RentaToolsConstants.featureFlagServiceAndRepairEnabled}>
                                {
                                    (this.ongoingService != null) &&
                                    (
                                        <>
                                            <ArsenalButton block big
                                                           id={"continueServiceBtn"}
                                                           className={newStyles.button}
                                                           type={ButtonType.Success}
                                                           icon={{name: "far arrow-square-right"}}
                                                           label={Localizer.get(Localizer.dashboardContinueService, this.ongoingService.deviceExternalId)}
                                                           onClick={() => this.continueServiceAsync()}
                                            />

                                            <ArsenalButton block big
                                                           id={"stopServiceBtn"}
                                                           className={newStyles.button}
                                                           type={ButtonType.Warning}
                                                           icon={{name: "far window-close"}}
                                                           label={Localizer.get(Localizer.dashboardStopService, this.ongoingService.deviceExternalId)}
                                                           confirm={Localizer.get(Localizer.dashboardStopServiceConfirmation, this.ongoingService.deviceExternalId)}
                                                           onClick={() => this.stopServiceAsync()}
                                            />
                                        </>
                                    )
                                }
                            </FeatureSwitch>

                            <ArsenalButton block submit big
                                           id={"returned_devices_dashboard_button"}
                                           className={newStyles.button}
                                           type={ButtonType.Orange}
                                           label={Localizer.returnedDevicesPageTitle}
                                           icon={{name: "fal list-alt"}}
                                           route={PageDefinitions.returnedDevicesRoute}
                            />

                            {
                                <FeatureSwitch flagName={RentaToolsConstants.featureFlagServiceAndRepairEnabled}>
                                    <ArsenalButton block submit big
                                                   id={"service_devices_dashboard_button"}
                                                   className={newStyles.button}
                                                   type={ButtonType.Orange}
                                                   label={Localizer.dashboardPageButtonServicedDevices}
                                                   icon={{name: "fal list-alt"}}
                                                   route={PageDefinitions.servicedDevicesRoute}
                                    />
                                </FeatureSwitch>
                            }

                            {
                                <FeatureSwitch flagName={RentaToolsConstants.featureFlagAnnualInspectionsEnabled}>
                                    <ArsenalButton block submit big
                                                   id="device_annual_inspection_dashboard_button"
                                                   className={newStyles.button}
                                                   type={ButtonType.Orange}
                                                   label={Localizer.annualInspectionsPageHeader}
                                                   icon={{name: "fal list-alt"}}
                                                   route={PageDefinitions.annualInspectionsRoute}
                                    />
                                </FeatureSwitch>
                            }

                            {
                                (this.hasFuelingAndWashingAccess) &&
                                (
                                    <FeatureSwitch flagName={RentaToolsConstants.featureFlagFuelingAndWashingEnabled}>
                                        <ArsenalButton block submit big
                                                       id={"invoice_page_link"}
                                                       className={newStyles.button}
                                                       type={ButtonType.Orange}
                                                       label={Localizer.dashboardPageButtonInvoices}
                                                       icon={{name: "fal list-alt"}}
                                                       route={PageDefinitions.invoicesRoute}
                                        />
                                    </FeatureSwitch>
                                )
                            }
                        </Form>

                    </div>

                </ArsenalPageRow>

                <Modal id={"dashboard-info-modal"}
                       ref={this._pageInfoModalRef}
                       className={rentaToolsStyles.infoPageModal}
                       title={Localizer.dashboardPageInfoHeader}
                       onClose={async () => await this.onClosePageInfoModalAsync()}
                >
                    {this.getPageInfo(Localizer.dashboardPageInfoContent)}
                </Modal>

            </PageContainer>
        );
    }
}