import React from "react";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import Device from "../Models/Device";
import Report from "../Models/Report";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import DownloadReportPdfResponse from "../../models/server/responses/DownloadReportPdfResponse";
import WashingType from "@/models/server/WashingType";
import FuelType from "@/models/server/FuelType";
import AdBlue from "@/models/server/AdBlue";
import NewSummaryTable from "@/components/NewSummaryTable/NewSummaryTable";
import ToolsUtility from "@/helpers/ToolsUtility";
import TransformProvider from "../../providers/TransformProvider";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ReportPreviewPage.module.scss";

interface IReportPreviewPageProps {
}

interface IReportPreviewPageState {
    fuelTypes: FuelType[],
    washingTypes: WashingType[],
    adBlues: AdBlue[]

}

export default class ReportPreviewPage extends AuthorizedPage<IReportPreviewPageProps, IReportPreviewPageState> {

    state: IReportPreviewPageState = {
        fuelTypes: [],
        washingTypes: [],
        adBlues: []
    };

    private async downloadReportPdfAsync(): Promise<void> {
        const reportId: string = this.report.id;
        const response: DownloadReportPdfResponse = await RentaToolsController.downloadReportPdfAsync(reportId);

        if (response.reportIsTooBig) {
            await ch.alertErrorAsync(Localizer.reportPreviewPageAlertErrorReportIsTooBig);
        } else {
            ch.download(response.pdf!);
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const washingTypes: WashingType[] = await RentaToolsController.getWashingTypesAsync();
        const adBlues: AdBlue[] = await RentaToolsController.getAdBluesAsync();
        const fuelTypes: FuelType[] = await RentaToolsController.getFuelTypesAsync();

        await this.setState({washingTypes, fuelTypes, adBlues});
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get report(): Report {
        return RentaToolsController.reportPreview!;
    }

    private get title(): string {
        return `${this.device.externalId} ${this.device.type}\n${Localizer.reportReport} ${ToolsUtility.toDateString(this.report.completedAt)}`;
    }

    public getManual(): string {
        return Localizer.reportPreviewGetManual;
    }

    private get inspector() {
        return `${Localizer.reportPreviewInspector}: ${TransformProvider.toString(this.report.user)}`;
    }

    private get depot() {
        return `${Localizer.reportPreviewDepot}: ${TransformProvider.toString(this.report.depo)}`
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(rentaToolsStyles.pageContainer, styles.reportPreview)}>

                <PageHeader title={this.title}
                            className={rentaToolsStyles.leftPageHeader} />

                <ArsenalPageRow className={styles.help} >

                    <span className={styles.clickStepText}>
                        {this.toMultiLines(Localizer.reportPreviewPageInspectionInfo)}
                    </span>

                    <section className={styles.inspectionInfo}>

                        {this.inspector}

                        <br/>

                        {this.depot}

                        <ArsenalButton big
                                       id={"share_button"}
                                       type={ButtonType.Orange}
                                       label={Localizer.savePageShareReport}
                                       onClick={async () => await this.downloadReportPdfAsync()} />
                    </section>

                </ArsenalPageRow>

                <ArsenalPageRow>

                    <NewSummaryTable noFooter viewOnly
                                     model={this.report}
                                     fuelTypes={this.state.fuelTypes}
                                     washingTypes={this.state.washingTypes} />

                </ArsenalPageRow>

                <ArsenalPageRow>

                    <ArsenalButton className={rentaToolsStyles.navigationButton}
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   onClick={async () => PageRouteProvider.back()}/>

                </ArsenalPageRow>

            </PageContainer>
        );
    }
}