export default class ProductGroup
{
    public productGroupId: string = "";

    public productType: string | null  = null;

    public productGroupName: string = "";

    public parentProductGroupId: string  = "";

    public isProductGroup: boolean = true;
}