import React from "react";
import {
    Button,
    ButtonContainer,
    ButtonType,
    CellModel,
    ColumnDefinition,
    ColumnType,
    Dropdown,
    DropdownType,
    Form,
    Grid,
    Inline,
    PageContainer,
    PageHeader,
    PageRow,
    SelectListItem
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import UserContext from "@/models/server/UserContext";
import SettingModel from "@/models/server/SettingModel";
import Localizer from "../../localization/Localizer";

import styles from "./ApplicationSettingsPage.module.scss";
import SettingsContainer from "@/models/server/SettingsContainer";
import NumericSettingModel from "@/models/server/NumericSettingModel";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import DateFormatSelectionModel from "@/models/DateFormatSelectionModel";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";

interface IApplicationSettingsPageProps {
}

interface IApplicationSettingsPageState {
    settingsModified: boolean;
    selectedDateFormat: string | null;
    dateFormatItems: DateFormatSelectionModel[] | null;
    settings: SettingModel[];
    numericalSettings: SettingModel[];
}

export default class ApplicationSettingsPage extends AuthorizedPage<IApplicationSettingsPageProps, IApplicationSettingsPageState> {

    state: IApplicationSettingsPageState = {
        settingsModified: false,
        selectedDateFormat: null,
        settings: [],
        numericalSettings: [],
        dateFormatItems: [],
    };

    private readonly _settingsGridRef: React.RefObject<Grid<SettingModel>> = React.createRef();
    private readonly _numberSettingsGridRef: React.RefObject<Grid<NumericSettingModel>> = React.createRef();
    private readonly _dateFormatDdlRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.adminSettingsKeyLanguageItemName,
            accessor: "key",
            transform: (cell): string => this.transformKeyCell(cell),
            minWidth: 300,
            maxWidth: 300,
        },
        {
            header: Localizer.adminSettingsValueLanguageItemName,
            accessor: "value",
            editable: true,
            type: ColumnType.Text,
            minWidth: 300,
            maxWidth: 300,
            maxHeight: "16px",
            settings: {
                maxLength: 100000
            },
            wordBreak: true,
            stretch: false,
            callback: async (cell) => await this.onChangeSettingsAsync(cell)
        }
    ];

     private readonly _numberGridColumns: ColumnDefinition[] = [
            {
                header: Localizer.adminSettingsKeyLanguageItemName,
                accessor: "key",
                transform: (cell): string => this.transformKeyCell(cell),
                minWidth: 300,
                maxWidth: 300,
            },
            {
                header: Localizer.adminSettingsValueLanguageItemName,
                accessor: "value",
                editable: true,
                type: ColumnType.Number,
                minWidth: 300,
                maxWidth: 300,
                maxHeight: "16px",
                settings: {
                    maxLength: 100000,
                    step: 0.1
                },
                wordBreak: true,
                stretch: false,
                callback: async (cell) => await this.onChangeSettingsAsync(cell)
            }
        ];

    async initializeAsync(): Promise<void> {
        const settings: SettingModel[] = await this.postAsync("api/applicationSettings/listSettings", false);
        const numericalSettings: SettingModel[] = await this.postAsync("api/applicationSettings/listSettings", true);

        const dateFormat = settings.firstOrDefault(s => s.key === "DateFormat");

        this.setState({
            settings,
            numericalSettings,
            selectedDateFormat: dateFormat?.value ?? null,
            dateFormatItems: RentaToolsConstants.supportedDateFormats,
        })

        await this._settingsGridRef.current?.reloadAsync()
        await this._numberSettingsGridRef.current?.reloadAsync();
    }

    private get settings(): SettingsContainer  {
        return {
            stringValueSettings: this.settingsGrid.model.items,
            numberValueSettings: this.numericalSettingsGrid.model.items,
        };
    }

    private get hasSettingsAccess(): boolean {
        const context: UserContext = this.getContext();
        return context.isAdmin;
    }

    private transformKeyCell(cell: CellModel<SettingModel>): string {
        const setting: SettingModel = cell.model;

        const key: string = setting.key;

        return Localizer.get(`KnownSettings.${key}`);
    }

    private async handleSettingsSubmitAsync(): Promise<void> {
        const container: SettingsContainer = this.settings;

        const fromNumeric: SettingModel[] | null = (container.numberValueSettings?.some(s => !Number.isNaN(s.value)))
        ? container.numberValueSettings?.where(s => !Number.isNaN(s.value)).map(setting => ({...setting, isNumeric: true, value: setting.value?.toString() ?? ""}))
        : null;

        let settings: SettingModel[] = container.stringValueSettings!;
        if (fromNumeric) {
            settings = settings.concat(fromNumeric);
        }

        let dateFormatSetting: SettingModel | null | undefined = container.stringValueSettings?.firstOrDefault(setting => setting.key == "DateFormat");
        if (!dateFormatSetting) {
            dateFormatSetting = {
                key: "DateFormat",
                value: this.state.selectedDateFormat,
                isNumeric: false,
            } as SettingModel;

            settings.push(dateFormatSetting);
        }
        else {
            dateFormatSetting.value = this.state.selectedDateFormat!;
        }

        await this.postAsync("api/applicationSettings/saveSettings", settings);

        this.setState({settingsModified: false});

        await this.alertMessageAsync(Localizer.adminSettingsSaved, true);
    }

    private async getSettingsAsync(grid: Grid<SettingModel>): Promise<SettingModel[]> {
        return this.state.settings.where(x => x.key !== "DateFormat");
    }

    private async getNumberValueSettingsAsync(grid: Grid<NumericSettingModel>): Promise<NumericSettingModel[]> {
        if (!this.state.numericalSettings) {
            return [];
        }

        return this.state.numericalSettings.map(setting => ({...setting, value: parseFloat(setting.value)}));
    }

    private async onChangeSettingsAsync(cell: CellModel<unknown>): Promise<void> {
        this.setState({settingsModified: cell.grid.modified});
    }

    private async dateFormatSelectedAsync(item: SelectListItem | null): Promise<void> {
        if (!item) {
            this.setState({
                selectedDateFormat: null,
            })

            return;
        }

        this.setState({
            selectedDateFormat: item?.value ?? null,
            settingsModified: true,
        })
    }

    private get settingsGrid(): Grid<SettingModel> {
        return this._settingsGridRef.current!;
    }

    private get numericalSettingsGrid(): Grid<NumericSettingModel> {
        return this._numberSettingsGridRef.current!;
    }

    private get selectedDateFormat(): string | null {
        return this.state.selectedDateFormat;
    }

    public get dateFormatListItems(): SelectListItem[] {

        if (this.state.dateFormatItems === null || this.state.dateFormatItems === undefined) {
            return [];
        }

        const items: DateFormatSelectionModel[] = this.state.dateFormatItems;

        return items.map(x => {
            const item: SelectListItem = new SelectListItem();
            item.value = x.value;
            item.text = x.text;

            return item;
        });
    }

    public getTitle(): string {
        return Localizer.topNavAdmin;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.applicationSettingsPage}>

                <PageHeader title={Localizer.applicationSettingsPageTitle} />

                <PageRow>
                    <div className="col">

                        <Form id="form" onSubmit={async () => await this.handleSettingsSubmitAsync()}>

                            <div className={"mb-3"}>

                                <h6>{Localizer.applicationSettingsPageSubTitle}</h6>
                                <Grid responsive
                                      id="settings"
                                      ref={this._settingsGridRef}
                                      minWidth={"100%"}
                                      readonly={!this.hasSettingsAccess}
                                      columns={this._columns}
                                      fetchData={async (sender) => await this.getSettingsAsync(sender)}
                                />

                            </div>

                            <div className={"mb-3"}>
                                <h6>{Localizer.applicationSettingsNumberValues}</h6>
                                <Grid responsive
                                      id="numberValueSettings"
                                      ref={this._numberSettingsGridRef}
                                      minWidth={"100%"}
                                      readonly={!this.hasSettingsAccess}
                                      columns={this._numberGridColumns}
                                      fetchData={async (sender) => await this.getNumberValueSettingsAsync(sender)}
                                />

                            </div>

                            <div className={"mb-3"}>

                                <Inline>
                                    <h6>{Localizer.applicationSettingsVisualSettings}</h6>

                                    <InlineTooltip text={Localizer.applicationSettingsDateFormatInfo} />
                                </Inline>


                                <div className={this.css("col-3", styles.dateContainer)}>

                                    <Dropdown small noWrap
                                              id={"dateFormatDdl"}
                                              ref={this._dateFormatDdlRef}
                                              className={styles.dropdown}
                                              type={DropdownType.Dropdown}
                                              items={this.dateFormatListItems}
                                              selectedItem={this.selectedDateFormat}
                                              onItemClick={async (sender, item) => await this.dateFormatSelectedAsync(item)}
                                    />


                                </div>

                            </div>

                            <ButtonContainer>

                                {
                                    (this.hasSettingsAccess) &&
                                    (
                                        <Button submit
                                                id={"saveSettings"}
                                                data-cy={"save_settings"}
                                                type={ButtonType.Orange}
                                                icon={{name: "far save"}}
                                                label={Localizer.genericSave}
                                                disabled={!this.state.settingsModified}
                                        />
                                    )
                                }

                            </ButtonContainer>

                        </Form>

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}