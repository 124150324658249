import DeviceInfo from "../DeviceInfo";
import { DeviceListOrder } from "@/models/Enums";

export default class BaseListDevicesRequest {
  public depoCostPools: string[] = [];

  public productGroupIds: string[] = [];

  public rentalObjectGroups: string[] = [];

  public deviceExternalId: string = "";

  public deviceTypes: string[] = [];

  public date: Date | null = null;

  public from: Date | null = null;

  public to: Date | null = null;

  public showDeleted: string = "active";

  public orderBy: DeviceListOrder = DeviceListOrder.ByPriority;
  
  public isEmpty(): boolean {
    return (
      !this.deviceExternalId &&
      (this.depoCostPools == null || this.depoCostPools.length == 0) &&
      (this.deviceTypes == null || this.deviceTypes.length == 0) &&
      (this.productGroupIds == null || this.productGroupIds.length == 0) &&
      !this.date &&
      !this.from &&
      !this.to
    );
  }

  public clear(): void {
    this.depoCostPools = [];
    this.productGroupIds = [];
    this.deviceExternalId = "";
    this.deviceTypes = [];
    this.date = null;
    this.from = null;
    this.to = null;
    this.rentalObjectGroups = [];
    this.orderBy = DeviceListOrder.ByPriority;
  }

  public getDate(device: DeviceInfo): Date | null {
    return null;
  }

  public getLastAnnualInspectionDate(device: DeviceInfo): Date | null {
    return null;
  }

  public getNextAnnualInspectionDate(device: DeviceInfo): Date | null {
    return null;
  }

  public getRemarksDueDate(device: DeviceInfo): Date | null {
    return null;
  }
}