import FuelType from "@/models/server/FuelType";
import WashingType from "@/models/server/WashingType";
import React from "react";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import NewSummaryTable from "@/components/NewSummaryTable/NewSummaryTable";
import ReturnInspectionButtons from "@/components/ReturnInspectionButtons/ReturnInspectionButtons";
import Device from "@/pages/Models/Device";
import {DialogResult, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {DeviceBanOfUse} from "@/models/Enums";
import UserContext from "@/models/server/UserContext";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import PageDefinitions from "@/providers/PageDefinitions";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import newStyles from "@/pages/NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

interface IReturnInspectionPreviewPageProps {
}

interface IReturnInspectionPreviewPageState extends IReturnInspectionWizardPageState {
    fuelTypes: FuelType[],
    washingTypes: WashingType[]
}

export default class ReturnInspectionPreviewPage extends ReturnInspectionWizardPage<IReturnInspectionPreviewPageProps, IReturnInspectionPreviewPageState> {

    state: IReturnInspectionPreviewPageState = {
        fuelTypes: [],
        washingTypes: []
    };

    async initializeAsync(): Promise<void> {
        super._needToProcessOnLeave = false;

        return super.initializeAsync();
    }

    protected get device(): Device | null {
        return RentaToolsController.device;
    }

    private get isBanOfUseEnabled(): boolean {
        const userContext: UserContext = this.getContext();
        return userContext.settings.isAnnualInspectionsBanOfUseEnabled;
    }

    private get annualInspectionOrRemarksOverDue(): boolean {
        if (!this.device) {
            return false;
        }

        return ((this.device?.nextAnnualReportDate != null
            && this.device?.nextAnnualReportDate < Utility.now()) || (this.device?.annualInspectionRemarksOverdue));
    }

    private get deviceAboutToBeLocked(): boolean {
        return (
            this.isBanOfUseEnabled
            &&
            (
                (this.device?.deviceBanOfUse == DeviceBanOfUse.NeedBan)
                || (this.annualInspectionOrRemarksOverDue && this.device?.supportsAnnualInspections == true)
            ) //Check these in case DeviceBanOfUse has not been updated yet
        );
    }

    private async startAction(): Promise<boolean> {
        if (this.deviceAboutToBeLocked) {
            const result = await this.messageBoxAsync(
                {
                    title: Localizer.devicePageDeviceWillBeLocked,
                    okButton: Localizer.genericContinue,
                    cancelButton: Localizer.genericCancel
                }
            );

            if (result == DialogResult.Cancel) {
                return false;
            }
        }

        return true;
    }

    private async startAsync(): Promise<void> {
        const result = await this.startAction();
        if (result) {
            await ReturnInspectionController.startReturnInspection(this.device?.externalId!);

            await ReturnInspectionController.openStepAsync(0);
        }
    }

    private async backToDevicePage(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.deviceRoute);
    }

    renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <ArsenalPageRow className={newStyles.summaryPage}>

                    <NewSummaryTable noFooter
                                     previewReturnInspection
                                     className={newStyles.table}
                                     model={this.report}
                                     washingTypes={this.state.washingTypes}
                                     fuelTypes={this.state.fuelTypes}
                                     additionalExpensesValid={(item: ReportDefinitionItem) => this.isAdditionalExpensesValid(item)}
                    />

                </ArsenalPageRow>

                <div className={rentaToolsStyles.buttonContainer}>
                    <ReturnInspectionButtons
                        backAsync={async () => await this.backToDevicePage()}
                        continueAsync={async () => await this.startAsync()}
                        steps={this.report.items}
                        showNextButton={this.showNextButton()}
                        continueTitle={Localizer.genericStart.toUpperCase()}
                        backTitle={Localizer.genericCancel.toUpperCase()}
                        nextButtonDisabled={this.isNextButtonDisabled()}
                        renderExitButton={null}
                    />
                </div>

            </React.Fragment>
        )
    }
}