import React from "react";
import { INumberInputProps, NumberInput } from "@renta-apps/athenaeum-react-components";
import { BaseComponent } from "@renta-apps/athenaeum-react-common";

interface IStatefulNumberInputState{

    value: number;
    min: number;
    max: number;
}

export default class StatefulNumberInput extends BaseComponent<INumberInputProps, IStatefulNumberInputState> {

    state : IStatefulNumberInputState = {
        value: this.props.value ?? 0,
        min: this.props.min ?? 0,
        max: this.props.max ?? 0
    };

    render(): React.ReactNode {
        return(
            <NumberInput {...this.props} 
                         value={this.state.value} 
                         min={this.state.min} 
                         max={this.state.max} />
        )
    }

}