import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {PageContainer, PageHeader, PageRow, Spinner} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import {NotificationType} from "@/models/Enums";
import GetNotificationAvailableReceiversRequest from "@/models/server/requests/GetNotificationAvailableReceiversRequest";
import NotificationSettings from "@/components/NotificationSettings/NotificationSettings";
import Localizer from "@/localization/Localizer";

import styles from "./GlobalNotificationSettingsPage.module.scss";

interface IGlobalNotificationSettingsPageProps {
}

interface IGlobalNotificationSettingsPageState {
    availableReceivers: User[];
    isLoading: boolean;
}

export default class GlobalNotificationSettingsPage extends AuthorizedPage<IGlobalNotificationSettingsPageProps, IGlobalNotificationSettingsPageState> {

    state: IGlobalNotificationSettingsPageState = {
        availableReceivers: [],
        isLoading: false,
    };

    public get user(): User {
        return this.getUser();
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    private get availableReceivers(): User[] {
        return this.state.availableReceivers || [];
    }

    private async fetchAvailableReceiversAsync(notificationType: NotificationType): Promise<void> {
        const request = {
            notificationType: notificationType,
            excludeUserIds: []
        } as GetNotificationAvailableReceiversRequest;

        const availableReceivers: User[] = await this.postAsync("api/notification/getNotificationAvailableReceivers", request);

        await this.setState({availableReceivers});
    }

    public isSpinning(): boolean {
        return (this.isLoading || super.isSpinning());
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.setState({isLoading: true});
        await this.fetchAvailableReceiversAsync(NotificationType.WrongServicePrediction);
        await this.setState({isLoading: false});
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.globalNotificationSettingsPage}>

                {
                    (this.isSpinning()) && <Spinner/>
                }

                <PageHeader title={Localizer.globalNotificationSettingsPageTitle}/>
                <PageRow>

                    {
                        (!this.isLoading) &&
                        (
                            <NotificationSettings isGlobal={true}
                                                  availableNotificationTypes={[NotificationType.WrongServicePrediction]}
                                                  availableReceivers={this.availableReceivers}
                                                  fetchAvailableReceivers={(notificationType) => this.fetchAvailableReceiversAsync(notificationType)}/>

                        )
                    }

                </PageRow>

            </PageContainer>
        );
    }
}