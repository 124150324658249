import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";

class DeviceOrderPage {
    private elements = {
        deviceOrder : () => cy.get('[data-cy=device-to-order]'),
        upButton: () => cy.get('[id=up-button]'),
        downButton: () => cy.get('[id=down-button]'),
        deviceOrderById: (deviceExternalId: string) => cy.get(`#device_${deviceExternalId}`),
        saveButton : () => cy.get('[id=saveButton]'),
        deviceExternalIds : () => cy.get('[data-cy=device-external-id]'),
        fixedOrderSwitch : () => cy.get('[id=fixedOrder]'),
    }

    public routes = {
        saveDeviceOrder: {
            path: "/api/DeviceOrder/SaveDeviceOrder",
            method: "POST",
        } as RouteData,
        getMaintenanceDevicesFilters: {
            path: "/api/device/getMaintenanceDevicesFilters",
            method: "POST",
        } as RouteData,
        listDevices: {
            path: "/api/ReturnInspection/ListDevices",
            method: "POST",
        } as RouteData,
    }

    public interceptRequest() {
        cy.intercept("POST", "/api/device/GetDevicesInfoToOrder").as("getDevicesInfoToOrder");
    }

    public waitForRequest() {
        cy.wait("@getDevicesInfoToOrder");
    }
    
    public validateDeviceOrder() {
        this.elements.deviceOrder()
            .should('be.visible');
    }

    public validateDeviceOrderLength(length: number) {
        this.elements.deviceOrder()
            .should('have.length', length);
    }
    
    public moveDeviceDown(deviceExternalId: string) {
        this.elements.deviceOrderById(deviceExternalId)
            .find('[id=down-button]')
            .click();
        
        cy.wait(200);
    }

    public save(onSave?: (deviceIds : string[]) => void) {
        const deviceIds : string[] = [];

        this.elements.deviceExternalIds().then(($list) => {
            const totalElements = $list.length;
            let processedElements = 0;

            this.elements.deviceExternalIds().each(($element) => {
                    deviceIds.push($element.text());
                    processedElements++;

                    if (processedElements === totalElements) {
                        executeWithIntercept(() => {
                            this.elements.saveButton().click();
                        }, [this.routes.saveDeviceOrder, this.routes.getMaintenanceDevicesFilters, this.routes.listDevices]);
                        
                        onSave && onSave(deviceIds);
                    }
                });
            });
    }
    
    public clickSwitch() {
        cy.intercept("POST", "/api/DeviceOrder/SaveDeviceOrder").as("saveOrder");
        this.elements.fixedOrderSwitch().click();
        cy.wait("@saveOrder");
    }
}

export default new DeviceOrderPage;
