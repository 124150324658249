import {FileModel} from "@renta-apps/athenaeum-toolkit";

export default class ImageProvider {
    
    public static getImageUrl(image: FileModel): string {
        return (image.src)
            ? image.src
            : `/files/images/${image.id}`;
    }

    public static getImageStyle(image: FileModel): object {
        const url: string = ImageProvider.getImageUrl(image);
        return { background: `url(${url})` };
    }
}