import React, {useState} from "react";
import {BaseComponent, ch, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, DateInput, Dropdown, Form, Inline, Modal, SelectListItem} from "@renta-apps/athenaeum-react-components";
import EnumProvider from "@/providers/EnumProvider";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import styles from "@/pages/PictureEventsPage/PicturesFiltersModal/PicturesFiltersModal.module.scss";
import Localizer from "@/localization/Localizer";
import ListDeviceObservationsRequest from "@/models/server/requests/ListDeviceObservationsRequest";
import ObservationsFiltersData from "@/models/server/ObservationsFiltersData";
import {Utility} from "@renta-apps/athenaeum-toolkit";

interface IObservationsFiltersModalProps {
    request: ListDeviceObservationsRequest;
    filtersData: ObservationsFiltersData;
    title: string;
    modalRef: React.RefObject<Modal>;

    onSubmit(request: ListDeviceObservationsRequest): Promise<void>;
}

const ObservationsFiltersModal = (props: IObservationsFiltersModalProps) => {

    const [request, setRequest] = useState<ListDeviceObservationsRequest>(props.request);

    const _faultTypesRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    const _fromRef: React.RefObject<DateInput> = React.createRef();
    const _beforeRef: React.RefObject<DateInput> = React.createRef();
    const _atRef: React.RefObject<DateInput> = React.createRef();

    const getObservationTypes = (): SelectListItem[] => {
        return EnumProvider.getObservationsFilterItems();
    }

    const clearAsync = async (): Promise<void> => {
        await clearAtAsync();
        await clearRangeAsync();

        _faultTypesRef.current?.reRender();

        const req = new ListDeviceObservationsRequest();
        req.pageNumber = 0;
        req.date = null;
        req.from = null;
        req.to = null;
        req.type = null;
        req.deviceId = request.deviceId;
        req.pageSize = request.pageSize;

        setRequest(req);
    }

    const clearAtAsync = async (): Promise<void> => {
        _atRef.current!.clear();
    }

    const clearRangeAsync = async (): Promise<void> => {
        _fromRef.current!.clear();
        _beforeRef.current!.clear();
    }

    const submitAsync = async (data: Dictionary<string, any>): Promise<void> => {
        const req = new ListDeviceObservationsRequest();
        Utility.copyTo(data, req);

        req.deviceId = request.deviceId;
        req.pageSize = request.pageSize;
        req.pageNumber = 0;

        setRequest(req);

        await props.modalRef.current!.closeAsync();

        await props.onSubmit(req);
    }

    return (
        <Modal id={`observation_filter_modal`} title={props.title} ref={props.modalRef} className={styles.picturesFiltersModal}>

            <Form className={styles.form}
                  onSubmit={async (sender, data) => await submitAsync(data)}
            >

                <Inline className={styles.inline}>

                    <Dropdown name="type"
                              ref={_faultTypesRef}
                              label={Localizer.genericType}
                              nothingSelectedText={"-"}
                              items={getObservationTypes()}
                    />

                    <DateInput popup clearButton
                               name="date"
                               ref={_atRef}
                               label={Localizer.picturesPreviewPagePicturesFiltersModalTakenOn}
                               value={request.date || undefined}
                               onChange={async () => await clearRangeAsync()}
                    />

                </Inline>

                <Inline className={styles.inline}>
                    <DateInput popup clearButton
                               name="from"
                               ref={_fromRef}
                               label={Localizer.picturesPreviewPagePicturesFiltersModalTakenFrom}
                               value={request.from || undefined}
                               onChange={async () => await clearAtAsync()}
                    />

                    <DateInput popup clearButton
                               name="to"
                               ref={_beforeRef}
                               label={Localizer.picturesPreviewPagePicturesFiltersModalTakenBefore}
                               value={request.to || undefined}
                               onChange={async () => await clearAtAsync()}
                    />
                </Inline>

                <ButtonContainer className={Utility.css(styles.buttons, ch.mobile && styles.mobile)}>

                    <Button submit
                            type={ButtonType.Orange}
                            label={Localizer.devicesFiltersModalButtonApply}
                    />

                    <Button type={ButtonType.Blue}
                            label={Localizer.devicesFiltersModalButtonClear}
                            onClick={async () => await clearAsync()}
                    />

                </ButtonContainer>

            </Form>

        </Modal>
    );
}

export default ObservationsFiltersModal;