import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";
import AdminPage from "@/tests/pages/AdminPage";

class DeviceManagementPage {
    public elements = {
        search: () => cy.get("#input_searchTerm"),
        form: () => cy.get('form'),
        removeBanOfUse: () => cy.get('.fa-lock-open'),
        removeBanOfUseConfirmDialog: () => cy.get('.athenaeum-confirmation-dialog-confirmDialog', {timeout: 10000}),
        deviceExpand: () => cy.get('.fa-info-square'),
        tabs: () => cy.get('.tabContainer'),
        deviceFaultsTab: () => this.elements.tabs().find('#tab_deviceFaults'),
        deviceFaultsTable: () => cy.get('#table_deviceFaultsTable'),

    }

    public routes = {
        listDevices: {
            path: "/api/device/listdevices",
            method: "POST",
        } as RouteData,
        fuelTypes: {
            path: "/api/classifier/GetFuelTypes",
            method: "POST",
        } as RouteData,
        adBlueTypes: {
            path: "/api/classifier/GetAdBlues",
            method: "POST",
        } as RouteData,
        getDeviceFaults: {
            path: "/api/device/getDeviceFaults",
            method: "POST",
        } as RouteData,
    }

    open() {
        executeWithIntercept(() => {
            AdminPage.elements.deviceManagement().click();
        }, [this.routes.listDevices]);
    }

    searchDevice(id: string) {
        executeWithIntercept(() => {
            this.elements.search()
                .type(id)
                .type('{enter}');
        }, [this.routes.listDevices])
    }

    assertFaults() {
        executeWithIntercept(() => {

            this.elements.deviceExpand()
                .eq(0)
                .click();

            this.elements.deviceFaultsTab().click();

            cy.wait(200);

            this.elements.deviceFaultsTable()
                .find('[class*=athenaeum-grid-gridRow]')
                .not('[class*=athenaeum-grid-details]') // CL Grid component has these "extra" rows between the actual rows;
                .should('have.length', 1)

            this.elements.deviceFaultsTable()
                .find('[class*=athenaeum-grid-gridRow]')
                .not('[class*=athenaeum-grid-details]')
                .find('span')
                .eq(6)
                .should('contain', Localizer.no);

            this.elements.deviceFaultsTable()
                .find('[class*=athenaeum-grid-gridRow]')
                .not('[class*=athenaeum-grid-details]')
                .find('span')
                .eq(7)
                .should('contain', 'Akusto');

        }, [this.routes.getDeviceFaults])
    }

    assertFuelTypes() {
        cy.get('.fa-info-square')
            .eq(1)
            .click()
            .get('#tab_fuelTypes')
            .click();

        cy.get('#fuelTypesDD')
            .click();

        cy.get('#fuelTypesDD').find(".athenaeum-dropdown-itemsList")
            .first()
            .should("contain", "Diesel (321)");

        cy.get('#fuelTypesDD')
            .should("contain", Localizer.fuelingAndWashingFuelType);
    }

    assertAdBlueTypes() {
        cy.get('#adBlueDD')
            .click();

        cy.get('#adBlueDD').find(".athenaeum-dropdown-itemsList")
            .first()
            .should("contain", "AdBlue 10l (123)");

        cy.get('#adBlueDD')
            .should("contain", Localizer.adBluesPageTitle);
    }

    assertAnnualInspections() {
        cy.get('.fa-info-square')
            .eq(1)
            .click();

        cy.get('.tabContainer')
            .find('#tab_annualInspections')
            .click();

        cy.get("#table_annualInspectionTable")
            .should("have.length", 1);
    }
}

export default new DeviceManagementPage();