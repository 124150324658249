import BaseReportDefinition from "@/pages/Models/BaseReportDefinition";
import ServiceAction from "@/pages/Models/ServiceAction";
import ServiceReportType from "@/pages/Models/ServiceReportType";
import {ReportDefinitionType} from "@/models/Enums";

export default class ServiceReportDefinition extends BaseReportDefinition {
    constructor() {
        super();
        this.type = ReportDefinitionType.Service;
    }

    public types: ServiceReportType[] = [];
}