import React, {useEffect, useState} from "react";
import {Button, ButtonType, IButtonProps, IIconProps} from "@renta-apps/athenaeum-react-components";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import {Utility} from "@renta-apps/athenaeum-toolkit";

import styles from "./ArsenalButton.module.scss";

interface IArsenalButtonProps extends IButtonProps {
    big?: boolean;
    secret?: boolean;
    noPointerEvents?: boolean;
    ellipsis?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    whiteBg? : boolean;
    fullWidth?: boolean;
    reverse?: boolean;
    action? : boolean;
}

const ArsenalButton = (props: IArsenalButtonProps)  => {

    const [buttonStyles, setButtonStyles] = useState<Dictionary<ButtonType, string>>();
    const [clicked, setClicked] = useState(false);
    const [icon, setIcon] = useState<IIconProps | undefined>(props.icon);

    useEffect(() => {
        const buttonStyles = new Dictionary<ButtonType, string>();
        buttonStyles.setValue(ButtonType.Success, styles.btnSuccess);
        buttonStyles.setValue(ButtonType.Danger, styles.btnDanger);
        buttonStyles.setValue(ButtonType.Blue, styles.btnBlue);
        setButtonStyles(buttonStyles);
    }, []);

    const getButtonStyles = (): string => {
        if (props.type) {
            return buttonStyles?.getValue(props.type) ?? "";
        }

        return ""
    }

    const handleClick = async (sender: Button, data: string | null): Promise<void> => {
        if (props.onClick) {
            await props.onClick(sender, data);

            setClicked(!clicked);
        }
    }

    const getBgStyle = () => {
        return props.whiteBg ? {background: "white"} : undefined;
    }

    const getClassName = () => {
        const bigStyle: string = props.big ? styles.arsenalButton : "";
        const secretStyle: string = props.secret ? styles.arsenalSecretButton : "";
        const noPointerEventsStyle: string = props.noPointerEvents ? styles.arsenalNoPointerEventsButton : "";
        const ellipsisLabelStyle: string = props.ellipsis ? styles.arsenalEllipsisLabelButton : "";
        const fullWidthStyles = props.fullWidth ? styles.fullWidth : "";
        const reverse = props.reverse ? styles.flexReverse : "";
        const actionButton = props.action ? styles.actionButton : "";

        return Utility.css(
            bigStyle,
            secretStyle,
            noPointerEventsStyle,
            ellipsisLabelStyle,
            props.className,
            fullWidthStyles,
            reverse,
            actionButton,
            getButtonStyles());
    }

    return (

        <Button {...props}
                style={getBgStyle()}
                className={getClassName()}
                icon={icon}
                onClick={(sender, data) => handleClick(sender, data)}
        >
            {props.children}
        </Button>
    )
};

export default ArsenalButton;