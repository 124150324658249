import BaseController from "@/pages/BaseController";
import EndpointPaths from "@/common/EndpointPaths";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";
import ListVendorInspectorsRequest from "@/models/server/requests/ListVendorInspectorsRequest";
import ListAnnualInspectionVendorsRequest from "@/models/server/requests/ListAnnualInspectionVendorsRequest";
import SaveAnnualInspectionVendorRequest from "@/models/server/requests/SaveAnnualInspectionVendorRequest";
import SaveAnnualInspectionVendorResponse from "@/models/server/responses/SaveAnnualInspectionVendorResponse";
import DeleteAnnualInspectionVendorResponse from "@/models/server/responses/DeleteAnnualInspectionVendorResponse";
import EditDeviceAnnualInspectionRequest from "@/models/server/requests/EditDeviceAnnualInspectionRequest";
import SaveDeviceAnnualInspectionResponse from "@/models/server/responses/SaveDeviceAnnualInspectionResponse";
import SaveRemarksRepairedRequest from "@/models/server/requests/SaveRemarksRepairedRequest";
import {FileModel, IPagedList} from "@renta-apps/athenaeum-toolkit";
import SaveDeviceAnnualInspectionRequest from "@/models/server/requests/SaveDeviceAnnualInspectionRequest";
import VendorInspectionViewModel from "@/models/server/VendorInspectionViewModel";
import ListVendorInspectionsRequest from "@/models/server/requests/ListVendorInspectionsRequest";
import {AnnualInspectionResponseType} from "@/models/Enums";
import ToolsUtility from "@/helpers/ToolsUtility";
import RestoreAnnualInspectionVendorResponse from "@/models/server/responses/RestoreAnnualInspectionVendorResponse";
import Localizer from "@/localization/Localizer";

class AnnualInspectionController extends BaseController {

    public async getVendorInspectionsAsync(request: ListVendorInspectionsRequest): Promise<IPagedList<VendorInspectionViewModel>> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.VendorInspections, request);
    }

    public async getVendorInspectorsAsync(request: ListVendorInspectorsRequest): Promise<AnnualInspectionVendor[]> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.VendorInspectors, request);
    }

    public async listVendorsAsync(request: ListAnnualInspectionVendorsRequest): Promise<AnnualInspectionVendor[]> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.ListVendors, request);
    }

    public async saveVendorAsync(request: SaveAnnualInspectionVendorRequest): Promise<SaveAnnualInspectionVendorResponse> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.SaveVendor, request);
    }

    public async addVendorAsync(request: SaveAnnualInspectionVendorRequest): Promise<SaveAnnualInspectionVendorResponse> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.AddVendor, request);
    }

    public async deleteVendorAsync(id: string): Promise<DeleteAnnualInspectionVendorResponse> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.DeleteVendor, id);
    }

    public async restoreVendorAsync(id: string): Promise<RestoreAnnualInspectionVendorResponse> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.RestoreVendor, id);
    }

    public async editDeviceAnnualInspectionAsync(request: EditDeviceAnnualInspectionRequest): Promise<SaveDeviceAnnualInspectionResponse> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.EditInspection, request);
    }

    public async saveRemarksRepairedAsync(request: SaveRemarksRepairedRequest): Promise<SaveDeviceAnnualInspectionResponse> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.SaveRemarks, request);
    }

    public async downloadDeviceAnnualInspectionPdfAsync(inspectionId: string): Promise<FileModel> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.DownloadPdf, inspectionId);
    }

    public async removeDeviceBanOfUseAsync(deviceId: string) : Promise<void> {
        await this.postAsync(EndpointPaths.AnnualInspectionPaths.RemoveBanOfUse, deviceId);
    }

    public async saveInspectionAsync(request: SaveDeviceAnnualInspectionRequest): Promise<SaveDeviceAnnualInspectionResponse> {
        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.SaveInspection, request);
    }

    public getErrorAlert(response: SaveDeviceAnnualInspectionResponse) {
        switch (response.responseType) {
            case AnnualInspectionResponseType.InvalidOperatingHours:
                if(response.operatingHoursBefore && response.operatingHoursAfter) {
                    return Localizer.servicePageOperatingHoursRangeResponse.format(response.operatingHoursBefore, ToolsUtility.toDateString(response.before), response.operatingHoursAfter, ToolsUtility.toDateString(response.after) )
                } else{
                    return Localizer.operatingHoursCannotBeLesserThan.format(response.operatingHoursAfter, ToolsUtility.toDateString(response.after));
                }
            case AnnualInspectionResponseType.InvalidSameDayOperatingHours:
                return Localizer.operatingHoursCannotBeDifferentInTheSameDay.format(response.operatingHoursBefore);
            default:
                return null;
        }
    }
}

export default new AnnualInspectionController();