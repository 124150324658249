import React from "react";
import {PwaHelper} from "@renta-apps/athenaeum-toolkit";
import {ApplicationContext, WebApplicationType} from "@renta-apps/athenaeum-react-common";
import {IconStyle, LinkWidget, PageContainer, RouteWidget, WidgetContainer} from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import Localizer from "../../localization/Localizer";

import styles from "./HomePage.module.scss";

interface IHomePageProps {
    name: string | null;
}

interface IHomePageState {
    pwaInstall: boolean;
}

export default class HomePage extends AnonymousPage<IHomePageProps, IHomePageState> {

    state: IHomePageState = {
        pwaInstall: false
    };

    private get pwaInstall(): boolean {
        return this.state.pwaInstall;
    }

    private async onPwaHelperInitialized(): Promise<void> {
        const context: ApplicationContext = this.getContext();
        const applicationType: WebApplicationType = context.applicationType;
        const pwaInstall: boolean = (PwaHelper.canBeInstalled) && ((applicationType == WebApplicationType.MobileBrowser) || ((context.isDevelopment) && (applicationType == WebApplicationType.DesktopBrowser)));
        await this.setState({pwaInstall});
    }

    private async installPwaAsync(): Promise<void> {
        await PwaHelper.installAsync();
        await this.reRenderAsync();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await PwaHelper.subscribe(async () => await this.onPwaHelperInitialized());
    }

    public get portalLink(): string {
        const country: string = this.getContext().country;
        switch (country) {
            case "se":
            case "sv":
                return "https://www.renta.se";
            case "no":
            case "nor":
                return "https://www.renta.no";
            default:
                return "https://www.renta.fi";
        }
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent className={styles.home} alertClassName={styles.alert}>

                <WidgetContainer>

                    {
                        (this.pwaInstall)
                            ? <RouteWidget icon={{name: "fas tablet-alt"}} label={Localizer.widgetPwaInstall} description={Localizer.widgetPwaDescription} onClick={() => this.installPwaAsync()}/>
                            : <LinkWidget icon={{name: "android", style: IconStyle.Brands}}
                                          label={Localizer.widgetRentaLinkLabel}
                                          description={Localizer.widgetRentaLinkDescription}
                                          url={this.portalLink}
                            />
                    }

                    <RouteWidget id="home_login_button" icon={{name: "sign-in"}} route={PageDefinitions.loginRoute} label={Localizer.topNavLogin}/>

                </WidgetContainer>

            </PageContainer>
        );
    }
}