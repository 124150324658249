import AuthorizedPage from "@/models/base/AuthorizedPage";
import React from "react";
import {ch, PageRouteProvider, ReactUtility} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {Button, ButtonType, PageContainer} from "@renta-apps/athenaeum-react-components";
import Device from "@/pages/Models/Device";
import DeviceInfoPanel from "@/components/DeviceInfoPanel/DeviceInfoPanel";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {FileModel, SortDirection} from "@renta-apps/athenaeum-toolkit";
import DeviceInfo from "@/models/server/DeviceInfo";
import {AnnualInspectionStatus, MaintenanceStatus} from "@/models/Enums";
import DeviceAnnualInspection from "@/models/server/DeviceAnnualInspection";
import ListDeviceAnnualInspectionsRequest from "@/models/server/requests/ListDeviceAnnualInspectionsRequest";
import FindDeviceResponse from "@/models/server/responses/FindDeviceResponse";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ToolsUtility from "@/helpers/ToolsUtility";
import {FeatureSwitch} from "@/providers/FeatureSwitch";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/DevicePage/DevicePage.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import AnnualInspectionController from "@/pages/AnnualInspectionController";

interface IDeviceAnnualInspectionPageProps {
}

interface IDeviceAnnualInspectionPageState {
    inspections: DeviceAnnualInspection[],
    loading: boolean,
    hideBanOfUseBanner: boolean
}

export default class DeviceAnnualInspectionPage extends AuthorizedPage<IDeviceAnnualInspectionPageProps, IDeviceAnnualInspectionPageState> {

    state: IDeviceAnnualInspectionPageState = {
        inspections: [],
        loading: true,
        hideBanOfUseBanner: false,
    };

    private get hasAnnualInspectionDate(): boolean {
        return (this.device.nextAnnualReportDate != null);
    }

    private get hasPreviousAnnualInspectionDate(): boolean {
        return (this.device.lastAnnualReportDate != null);
    }

    private get hasInspections(): boolean {
        return (this.inspections.length > 0);
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get inspections(): DeviceAnnualInspection[] {
        return this.state.inspections;
    }

    private get inspectionsWithFile(): DeviceAnnualInspection[] {
        return this.inspections.filter((inspection) => inspection.file != null);
    }

    private get lastInspection(): DeviceAnnualInspection {
        return this.inspections[0];
    }

    private get inspectionWithOpenRemarks(): DeviceAnnualInspection | null {
        return this.inspections.firstOrDefault(inspection => inspection.remarksDueDate !== null && inspection.remarksCompletedDate === null);
    }

    private get hasOpenRemarks(): boolean {
        const openRemarks = this.inspections.where(inspection => inspection.remarksDueDate !== null && inspection.remarksCompletedDate === null);

        return openRemarks.length > 0;
    }

    private get inspectionWithOpenRemarksBanOfUse() : boolean {
        return this.inspectionWithOpenRemarks?.status === AnnualInspectionStatus.PassedWithRemarksAndDeviceOnBanOfUse;
    }

    private get isDeviceOnBanOfUse(): boolean {

        if (this.isDeviceInBanOfUse) {
            return true;
        }

        const lastInspection = this.lastInspection;

        if (!lastInspection) {
            return false;
        }

        return ((lastInspection.status === AnnualInspectionStatus.PassedWithRemarksAndDeviceOnBanOfUse))
    }

    private get isDeviceInBanOfUse(): boolean {
        return RentaToolsController.isDeviceInBanOfUse();
    }

    private get banOfUseAlertText(): string {
        return this.device?.annualInspectionRemarksOverdue
            ? Localizer.bannerDeviceUseBanRemarks
            : Localizer.bannerDeviceUseBan;
    }

    public async initializeAsync(): Promise<void> {
        if (!RentaToolsController.device) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true);
        }

        //Reload device to make sure we have up to date information about inspections
        const response: FindDeviceResponse = await RentaToolsController.findDeviceByIdAsync(this.device.id);

        if (response.device) {
            response.device.picture = RentaToolsController.device?.picture ?? null;
            RentaToolsController.device = response.device;
        }
        const request: ListDeviceAnnualInspectionsRequest = {
            deviceId: this.device.id,
            sortDirection: SortDirection.Desc
        }

        const inspections: DeviceAnnualInspection[] = await this.postAsync("api/AnnualInspection/ListDeviceAnnualInspections", request);

        await this.setState({inspections: inspections, loading: false})
    }

    private async returnBackAsync(): Promise<void> {
        await PageRouteProvider.back();
    }

    private async openPreviousInspectionsPageAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.devicePreviousAnnualInspectionsRoute);
    }

    private async downloadLastAnnualInspectionPdfAsync() {
        const lastInspectionId: string = this.inspectionsWithFile[0].id;
        const file: FileModel = await AnnualInspectionController.downloadDeviceAnnualInspectionPdfAsync(lastInspectionId);

        if (!file) {
            await ch.alertErrorAsync(Localizer.genericFileNotFound);
        } else {
            ch.download(file);
        }
    }

    private async hideDeviceBanOfUseBannerAsync(): Promise<void> {
        await this.setState({hideBanOfUseBanner: true});

        await this.reRenderAsync();
    }

    private async toDevicePage(): Promise<void> {
        await RentaToolsController.searchDeviceAsync(this.device.externalId)
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(rentaToolsStyles.pageContainer, styles.device)}>

                {
                    <FeatureSwitch flagName={RentaToolsConstants.featureFlagBanOfUseEnabled}>
                        {
                            (this.isDeviceInBanOfUse)
                            && !this.state.hideBanOfUseBanner && (

                                <div className={styles.alert} id={"device_ban_of_use_banner"} data-cy="ban_of_use_banner">
                                    {
                                        <span className={styles.closebtn} onClick={async () => await this.hideDeviceBanOfUseBannerAsync()}>&times;</span>
                                    }
                                    {
                                        this.banOfUseAlertText
                                    }
                                </div>
                            )
                        }
                    </FeatureSwitch>
                }


                <DeviceInfoPanel device={this.device}
                                 renderInfo={() => this.renderInfo()}
                />

                <ArsenalPageRow>
                    <div className="col">
                        {
                            (this.hasOpenRemarks) &&
                            (
                                <ArsenalButton block big
                                               type={ButtonType.Orange}
                                               id={"fixRemarksButton"}
                                               className={rentaToolsStyles.arsenalButton}
                                               label={Localizer.deviceAnnualInspectionPageRemarksRepaired}
                                               onClick={async () => {
                                                   await PageRouteProvider.redirectAsync(PageDefinitions.deviceAnnualInspectionFormRoute({
                                                       status: AnnualInspectionStatus.RemarksRepaired,
                                                       inspectionId: this.inspectionWithOpenRemarks!.id,
                                                       isDeviceOnBanOfUse: this.inspectionWithOpenRemarksBanOfUse
                                                   }))
                                               }}
                                />
                            )
                        }

                        {
                            (!this.state.loading && !this.hasOpenRemarks) &&
                            (
                                <>
                                    <ArsenalButton block big
                                                   type={ButtonType.Orange}
                                                   id={"acceptButton"}
                                                   className={rentaToolsStyles.arsenalButton}
                                                   label={Localizer.deviceAnnualInspectionPageButtonAccept}
                                                   onClick={async () => {
                                                       await PageRouteProvider.redirectAsync(PageDefinitions.deviceAnnualInspectionFormRoute({
                                                           status: AnnualInspectionStatus.Passed,
                                                           isDeviceOnBanOfUse: false}))
                                                   }}
                                    />
                                    <ArsenalButton block big
                                                   type={ButtonType.Orange}
                                                   id={"acceptWithRemarksButton"}
                                                   className={rentaToolsStyles.arsenalButton}
                                                   label={Localizer.deviceAnnualInspectionPageButtonAcceptWithRemarks}
                                                   onClick={async () => {
                                                       await PageRouteProvider.redirectAsync(PageDefinitions.deviceAnnualInspectionFormRoute({
                                                           status: AnnualInspectionStatus.PassedWithRemarks,
                                                           isDeviceOnBanOfUse: this.isDeviceOnBanOfUse}))
                                                   }}
                                    />
                                </>
                            )
                        }

                        <br/>
                        <br/>

                        <ArsenalButton block big
                                       type={ButtonType.Orange}
                                       id={"previousInspectionsButton"}
                                       className={rentaToolsStyles.arsenalButton}
                                       label={Localizer.deviceAnnualInspectionPageButtonPreviousInspections}
                                       onClick={async () => await this.openPreviousInspectionsPageAsync()}
                        />

                        <br/>
                        <br/>

                        <ArsenalButton block big
                                       className={rentaToolsStyles.arsenalButton}
                                       type={ButtonType.Orange}
                                       label={Localizer.devicePageToDevicePage}
                                       onClick={async () => await this.toDevicePage()}/>

                        <br/>
                        <br/>

                        <ArsenalButton block big
                                       type={ButtonType.Orange}
                                       id={"backButton"}
                                       className={rentaToolsStyles.arsenalButton}
                                       icon={{name: "arrow-left"}}
                                       label={Localizer.devicePageReturnBack}
                                       onClick={async () => await this.returnBackAsync()}
                        />
                    </div>
                </ArsenalPageRow>
            </PageContainer>


        )
    }


    private renderInfo(): React.ReactNode {
        return (
            <div id={"infoContainer"} className={styles.info}>


                {
                    (this.hasAnnualInspectionDate) &&
                    (
                        <p>
                            {Localizer.devicePageNextAnnualInspectionDate}
                            {
                                <span className={this.css(DeviceInfo.getNextAnnualReportDateStyle(this.device))}>
                                                {
                                                    ToolsUtility.toDateString(this.device.nextAnnualReportDate)
                                                }
                                 </span>
                            }
                        </p>
                    )
                }

                {
                    (this.hasPreviousAnnualInspectionDate) &&
                    (
                        <p>
                            {Localizer.devicePagePreviousAnnualInspectionDate}
                            {
                                <span>
                                                {
                                                    ToolsUtility.toDateString(this.device.lastAnnualReportDate)
                                                }
                                 </span>
                            }
                        </p>
                    )
                }

                <p>
                    {Localizer.devicePageMaintenanceStatus}
                    {
                        ReactUtility.toMarks(DeviceInfo.getRentalStatusAndCostPool(this.device))
                    }
                </p>

                {
                    ((this.device && this.device?.maintenanceStatus > MaintenanceStatus.None) &&
                        <p>
                            {
                                ReactUtility.toMarks(DeviceInfo.getMaintenanceStatus(this.device))
                            }
                        </p>
                    )
                }

                {
                    (this.hasOpenRemarks) && (this.lastInspection.remarks) &&
                    (
                        <p>
                            {Localizer.deviceAnnualInspectionPageRemarks}
                            {
                                this.lastInspection.remarks + " " + ToolsUtility.toDateString(this.lastInspection.remarksDueDate)
                            }
                        </p>
                    )
                }

                {
                    <FeatureSwitch flagName={RentaToolsConstants.featureFlagBanOfUseEnabled}>
                        <p id={"ban_of_use_info"}>
                            {
                                `${Localizer.deviceAnnualInspectionPageDeviceUseBan}: ${this.isDeviceInBanOfUse ? Localizer.yes : Localizer.no}`
                            }
                        </p>
                    </FeatureSwitch>
                }

                {
                    (this.hasInspections && this.inspectionsWithFile.length > 0) &&
                    (
                        <p>
                            {Localizer.deviceAnnualInspectionPageLastReport}
                            {
                                <span>
                                    {
                                        <Button id={"download_pdf_button"}
                                                type={ButtonType.Orange}
                                                label={Localizer.deviceAnnualInspectionPageDownloadPdf}
                                                onClick={async () => await this.downloadLastAnnualInspectionPdfAsync()}
                                        />
                                    }
                                </span>
                            }
                        </p>
                    )
                }
            </div>

        )
    }
}