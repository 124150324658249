import React, {useEffect, useState} from "react";
import {Icon, IconSize} from "@renta-apps/athenaeum-react-components";

import styles from "./ArsenalPagination.module.scss";
import {Utility} from "@renta-apps/athenaeum-toolkit";

export interface IArsenalPaginationProps {
    className?: string;
    pageNumber: number;
    pageCount: number;
    loop?: boolean;

    onChange?(pageNumber: number): Promise<void>;
}


const ArsenalPagination = (props: IArsenalPaginationProps) => {

    const [pageNumber, setPageNumber] = useState<number>(props.pageNumber);

    useEffect(() => {
        setPageNumber(props.pageNumber);
    }, [props.pageNumber]);

    const changePageAsync = async (increase: boolean): Promise<void> => {

        let newPageNumber = (increase)
            ? (pageNumber < props.pageCount)
                ? pageNumber + 1
                : (props.loop)
                    ? 1
                    : pageNumber
            : (pageNumber > 1)
                ? pageNumber - 1
                : (props.loop)
                    ? props.pageCount
                    : pageNumber;

        if (newPageNumber !== pageNumber) {

            setPageNumber(newPageNumber);

            if (props.onChange) {
                await props.onChange(newPageNumber);
            }
        }
    }

    return (
        <div className={Utility.css(props.className, styles.arsenalPagination)}>

            <Icon
                name="fas angle-left"
                size={IconSize.X3}
                className={props.pageCount > 1
                    ? styles.cursor
                    : ""}
                onClick={async () => await changePageAsync(false)}
            />

            <div>
                <span>{pageNumber} / {props.pageCount}</span>
            </div>

            <Icon
                name="fas angle-right"
                size={IconSize.X3}
                className={props.pageCount > 1
                    ? styles.cursor
                    : ""}
                onClick={async () => await changePageAsync(true)}
            />

        </div>
    );

};

export default ArsenalPagination;