import HttpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";
import {DeviceShortView} from "@/models/server/DeviceShortView";
import {ISaveDeviceOrderResponse} from "@/models/server/responses/SaveDeviceOrderResponse";
import {IDeviceOrder, ISaveDeviceListOrderRequest} from "@/models/server/requests/SaveDeviceListOrderRequest";
import {DeviceListOrderType} from "@/models/Enums";

class OrderService {
    public async saveOrderAsync(depoId : string, devices: DeviceShortView[], fixedOrder : boolean, deviceOrderType : DeviceListOrderType): Promise<ISaveDeviceOrderResponse> {
    
        const deviceOrder = devices.filter(p=>p.updated)
            .map(p => ({
                deviceId: p.id,
                order: p.index,
            } as IDeviceOrder));
        
        const request : ISaveDeviceListOrderRequest = {
            deviceOrders: deviceOrder,
            depoId: depoId,
            fixedOrder: fixedOrder,
            type: deviceOrderType,
        };
        
        return await HttpClient.postAsync<ISaveDeviceOrderResponse>(EndpointPaths.OrderPaths.SaveDeviceOrder, request);
    }
}

export default new OrderService();