import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import CheckItem from "@/pages/Models/CheckItem";

export default class AdvancedChecksReportItem extends ReportDefinitionItem {
    public ok: boolean | null = null;

    public static isValid(item: AdvancedChecksReportItem): boolean {
        return (item.checks != null) && (item.checks.length > 0) && (item.checks.every(checkItem => checkItem.ok != null || checkItem.isSkipped));
    }

    public static isSkipped(item: AdvancedChecksReportItem): boolean {
        return item.completed === true && ((item.checks != null) && (item.checks.length > 0) && (item.checks.every(checkItem => checkItem.isSkipped)));
    }

    public static isCompleted(item: AdvancedChecksReportItem): boolean {
        return this.isOkay(item) || this.isFailed(item);
    }

    public static isNotCompleted(item: AdvancedChecksReportItem): boolean {
        return !item.completed;
    }

    public static isOkay(item: AdvancedChecksReportItem): boolean {
        if (item.completed !== true || item.checks == null || item.checks.length === 0) {
            return false;
        }

        let allSkipped = true;
        const allOkOrSkipped = item.checks.reduce((previousValue: boolean, currentValue: CheckItem): boolean => {
            if (!currentValue.isSkipped) {
                allSkipped = false;
            }

            return previousValue && (currentValue.ok === true || currentValue.isSkipped === true);
        }, true);

        return allOkOrSkipped && !allSkipped;
    }

    public static isFailed(item: AdvancedChecksReportItem): boolean {
        return item.completed === true && ((item.checks != null) && (item.checks.length > 0) && (item.checks.filter(checkItem => checkItem.ok == false)).length > 0);
    }

    public static isOk(item: AdvancedChecksReportItem): boolean | null {
        return ((item.checks != null) && (item.checks.length > 0) && (item.checks.every(checkItem => checkItem.ok == true || checkItem.isSkipped)));
    }
}