import {executeWithIntercept} from "@/helpers/CypressHelper";
import ReportDefinitionPage from "@/tests/pages/ReportDefinitionPage";

class ReportDefinitionsPage {
    public elements = {
        reportDefinitionsList: () => cy.get('[id="reportDefinitionsList"]'),
        definitionTypeDropdown: () => cy.get('[id="reportDefinitionTypeDD"]'),
        showDeleted: () => cy.get('[id="checkBoxShowDeleted"]'),
        add: () => cy.get('[id="addReportDefinition"]'),
    }

    public routes = {
        getReportDefinitions: {
            path: "/api/reportDefinition/getReportDefinitions",
            method: "POST",
        }
    };

    public constants = {
        triggerDefinitionName: "Service report definition for trigger tests"
    }

    public chooseType(value: string): void {
        this.elements.definitionTypeDropdown().click();
        cy.wait(150)
        this.elements.definitionTypeDropdown().contains(value).click();
        this.elements.definitionTypeDropdown().click();
    }

    public goToTriggerDefinition(): void {
        executeWithIntercept(() =>
                cy.contains(this.constants.triggerDefinitionName).realClick(),
            [ReportDefinitionPage.routes.getServiceActions])
    }
}

export default new ReportDefinitionsPage();