import {executeWithIntercept} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";
import AnnualInspectionsPage from "@/tests/pages/AnnualInspectionsPage";

describe('Annual inspection devices list tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
        executeWithIntercept(() => {
            cy.get('#device_annual_inspection_dashboard_button')
                .click();
        }, [AnnualInspectionsPage.routes.filters, AnnualInspectionsPage.routes.listAnnualInspectionDevices])

    })

    it('Annual inspection devices should load on all three tabs', () => {
        AnnualInspectionsPage.validateTabs();
    });

    it('Validates legend', () => {
        AnnualInspectionsPage.elements.legend('blue').should('contain.text', Localizer.annualInspectionsPageInspectionInOneMonth);
        AnnualInspectionsPage.elements.legend('red').should('contain.text', Localizer.annualInspectionsPageInspectionLate);
        AnnualInspectionsPage.elements.legend('orange').should('contain.text', Localizer.annualInspectionsPageInspectionUpcomingSoon);
    });

    it('Tests upcoming tab', () => {
        AnnualInspectionsPage.elements.upcomingTab().click();

        AnnualInspectionsPage.validateDeviceType("upcoming");
    });

    it('Tests past tab has devices with date in the past', () => {
        AnnualInspectionsPage.elements.pastTab().click();

        AnnualInspectionsPage.validateDeviceType("past");
    });

    it('Tests remarks tab', () => {
        AnnualInspectionsPage.elements.remarksTab().click();

        AnnualInspectionsPage.validateDeviceType("remarks");
    });
});