import {FileModel} from "@renta-apps/athenaeum-toolkit";
import QuestionReportItem from "./QuestionReportItem";

export default class QuestionPicturesReportItem extends QuestionReportItem {

    public pictures: FileModel[] | null = null;

    public static hasPicture(item: QuestionPicturesReportItem): boolean {
        return (item.pictures != null) && (item.pictures.length > 0) && (item.pictures.some(item => item != null));
    }

    public static isValid(item: QuestionPicturesReportItem): boolean {
        return QuestionReportItem.isValid(item) && QuestionPicturesReportItem.hasPicture(item);
    }
}