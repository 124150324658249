import Depo from "@/models/server/Depo";
import ProductGroup from "@/models/server/ProductGroup";
import { DeviceStatus } from "@/models/Enums";
import RentalObjectGroup from "@/models/server/RentalObjectGroup";

export default class FiltersData
{
    public depos: Depo[] = [];

    public productGroups: ProductGroup[] = [];

    public deviceTypes: string[] = [];

    public deviceStatuses: DeviceStatus[] = [];

    public rentalObjectGroups : RentalObjectGroup[] = [];

    public showDeleted?: boolean | null = false;

    public returnInspectionDetails : string = "";

    public returnInspectionStatus : string[] = [];
}