import DeviceContract from "@/models/server/DeviceContract";
import Depo from "@/models/server/Depo";
import User from "@/models/server/User";

export default class ReturnInspectionInvoiceFiltersData {
    public deviceContracts: DeviceContract[] = [];

    public depots: Depo[] = [];
    
    public users: User[] = [];

    public isReturnInspectionInvoiceFiltersData: boolean = true;
}