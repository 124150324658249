import Report from "@/pages/Models/Report";
import Invoice from "@/models/server/Invoice";

export default class ReturnInspectionInvoice extends Invoice {

    public returnInspectionReportId: string | null = null;

    public returnInspectionReport: Report = new Report();

    public isReturnInspectionInvoice: boolean = true;
}