import {FileModel} from "@renta-apps/athenaeum-toolkit";

export class UploadFullImageResponse{
    public fileModel : FileModel;
    public shrunkSrc : string;

    constructor(fileModel : FileModel, shrunkSrc : string) {
        this.fileModel = fileModel;
        this.shrunkSrc = shrunkSrc;
    }

    public static Create(response : FileModel, originalSrc : string, shrunkSrc : string ) : UploadFullImageResponse{
        const newModel : FileModel = {...response, src: originalSrc};

        return new UploadFullImageResponse(newModel, shrunkSrc);
    }
}