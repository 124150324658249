import React from "react";
import {ActionType, BaseComponent, ch, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, CellAction, CellModel, ColumnDefinition, ColumnType, Dropdown, Form, Grid, GridHoveringType, GridOddType, IconStyle, Tab, TabContainer} from "@renta-apps/athenaeum-react-components";
import DeviceInfo from "@/models/server/DeviceInfo";
import DeviceCounter from "@/pages/Models/DeviceCounter";
import Report from "@/pages/Models/Report";
import ServiceReport from "@/pages/Models/ServiceReport";
import { SortDirection } from "@renta-apps/athenaeum-toolkit";
import AdBlue from "@/models/server/AdBlue";
import FuelType from "@/models/server/FuelType";
import SaveDeviceRequest from "@/models/server/requests/SaveDeviceRequest";
import styles from "./DeviceDetailsPanel.module.scss";
import RentaToolsController from "@/pages/RentaToolsController";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";
import DeviceAnnualInspection from "@/models/server/DeviceAnnualInspection";
import {DeviceBanOfUse, FaultLevel} from "@/models/Enums";
import DeviceFault from "@/pages/Models/DeviceFault";
import User from "@/models/server/User";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import GetDeviceFaultsResponse from "@/models/server/responses/GetDeviceFaultsResponse";
import UnmarkPermanentFaultRequest from "@/models/server/requests/UnmarkPermanentFaultRequest";
import ToolsUtility from "@/helpers/ToolsUtility";
import ListDeviceAnnualInspectionsRequest from "@/models/server/requests/ListDeviceAnnualInspectionsRequest";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";

interface IDeviceDetailsPanelProps {
    device: DeviceInfo;
    adBlueTypes: AdBlue[];
    fuelTypes: FuelType[];
}

interface IDeviceDetailsPanelState {
    selectedTabIndex: number,
    showDeleted: boolean,
    deviceAdBlueId: string | null,
    deviceFuelId: string | null
}

export default class DeviceDetailsPanel extends BaseComponent<IDeviceDetailsPanelProps, IDeviceDetailsPanelState> {

    state: IDeviceDetailsPanelState = {
        selectedTabIndex: 0,
        showDeleted: false,
        deviceAdBlueId: this.device.adBlueId,
        deviceFuelId: this.device.fuelTypeId
    };

    private readonly _devicePreviousInspections: React.RefObject<Grid<Report>> = React.createRef();
    private readonly _devicePreviousServices: React.RefObject<Grid<ServiceReport>> = React.createRef();
    private readonly _deviceCountersGridRef: React.RefObject<Grid<DeviceCounter>> = React.createRef();
    private readonly _deviceAnnualInspectionGridRef: React.RefObject<Grid<DeviceAnnualInspection>> = React.createRef();
    private readonly _deviceFaultsGridRef: React.RefObject<Grid<DeviceFault>> = React.createRef();

    private readonly _deviceCountersColumns: ColumnDefinition[] = [
        {
            header: Localizer.deviceDetailsPanelCounterLanguageItemName,
            accessor: nameof<DeviceCounter>(item => item.type),
            format:  "DeviceCounterType",
            minWidth: 150,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelValueLanguageItemName,
            accessor: nameof<DeviceCounter>(item => item.value),
            minWidth: 150,
            format: "0.00",
            noWrap: true,
        },
        {
            header: Localizer.deviceDetailsPanelDateLanguageItemName,
            accessor: nameof<DeviceCounter>(item => item.modifiedAt),
            minWidth: 150,
            format: (value: Date) => (value.getFullYear() > 1) ? this.formatDate(value) : "-",
            noWrap: true
        }
    ];
    private readonly _devicePreviousServiceColumns: ColumnDefinition[] = [
        {
            header: Localizer.deviceDetailsPanelInspectionsStartedAt,
            accessor: nameof<ServiceReport>(d => d.startedAt),
            type: ColumnType.Date,
            format: (value: Date) => this.formatDate(value),
            minWidth: 150,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsCompletedAtLanguageItemName,
            accessor: nameof<ServiceReport>(d => d.completedAt),
            minWidth: 150,
            type: ColumnType.Date,
            format: (value: Date) => this.formatDate(value),
            editable: true,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsDateLanguageItemName,
            accessor: nameof<ServiceReport>(d => d.date),
            minWidth: 150,
            type: ColumnType.Date,
            format: (value: Date) => this.formatDate(value),
            editable: true,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsUserLanguageItemName,
            accessor: "user",
            minWidth: 150,
            type: ColumnType.Text,
            transform: (cell, value) => TransformProvider.toString(value),

            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsDepoLanguageItemName,
            accessor: "depo.name",
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsSkippedLanguageItemName,
            accessor: nameof<ServiceReport>(d => d.skipped),
            transform: (cell, value) => value ? "✓" : "",
            minWidth: 150,
            type: ColumnType.Boolean,
            noWrap: true
        },
        {
            header: Localizer.deviceServicePageInvoiceLabelServicedByRentaLanguageItemName,
            accessor: nameof<ServiceReport>(d => d.servicedByRenta),
            transform: (cell, value) => value ? "✓" : "",
            minWidth: 150,
            type: ColumnType.Boolean,
            noWrap: true
        },
        {
            header: Localizer.deviceServicePageInvoiceLabelInvoiceNumberLanguageItemName,
            accessor: nameof<ServiceReport>(d => d.invoiceNumber),
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelServiceCommentLanguageItemName,
            accessor: nameof<ServiceReport>(d => d.comment),
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true
        },
        {
            header: Localizer.deviceServicePageLabelOperatingHours,
            accessor: nameof<ServiceReport>(sr => sr.operatingHours),
            minWidth: 90,
            noWrap: true
        },
    ];

    private readonly _devicePreviousInspectionsColumns: ColumnDefinition[] = [
        {
            header: Localizer.deviceDetailsPanelInspectionsStartedAtLanguageItemName,
            accessor: nameof<Report>(d => d.startedAt),
            type: ColumnType.Date,
            format: (value: Date) => this.formatDate(value),
            minWidth: 150,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsCompletedAtLanguageItemName,
            accessor: nameof<Report>(d => d.completedAt),
            minWidth: 150,
            type: ColumnType.Date,
            format: (value: Date) => this.formatDate(value),
            editable: true,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsUserLanguageItemName,
            accessor: "user",
            minWidth: 150,
            type: ColumnType.Text,
            transform: (cell, value) => TransformProvider.toString(value),

            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsDepoLanguageItemName,
            accessor: "depo.name",
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsPassedLanguageItemName,
            accessor: nameof<Report>(d => d.passed),
            minWidth: 150,
            type: ColumnType.Boolean,
            transform: (cell, value) => value ? "✓" : "",
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsSkipped,
            accessor: nameof<Report>(d => d.skipped),
            transform: (cell, value) => value ? "✓" : "",
            minWidth: 150,
            type: ColumnType.Boolean,
            noWrap: true
        },
        {
            header: Localizer.deviceDetailsPanelInspectionsCommentLanguageItemName,
            accessor: nameof<Report>(d => d.comment),
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true
        },
        {
            header: Localizer.deviceServicePageLabelOperatingHours,
            accessor: nameof<Report>(r => r.operatingHours),
            minWidth: 90,
            noWrap: true
        },
    ];

    private readonly _deviceAnnualInspectionColumns: ColumnDefinition[] = [
        {
            header: Localizer.deviceManagementPreviousAnnualInspectionDate,
            accessor: nameof<DeviceAnnualInspection>(item => item.previousAnnualInspectionDate),
            format: (value: Date) => (value.getFullYear() > 1) ? this.formatDate(value) : "-",
            minWidth: 150,
            noWrap: true
        },
        {
            header: Localizer.deviceManagementNextAnnualInspectionDate,
            accessor: nameof<DeviceAnnualInspection>(item => item.nextAnnualInspectionDate),
            minWidth: 150,
            format: (value: Date) => (value.getFullYear() > 1) ? this.formatDate(value) : "-",
            noWrap: true,
        },
        {
            header: Localizer.deviceManagementRemarksRepairDueDate,
            accessor: nameof<DeviceAnnualInspection>(item => item.remarksDueDate),
            minWidth: 150,
            format: (value: Date) => (value.getFullYear() > 1) ? this.formatDate(value) : "-",
            noWrap: true
        },
        {
            header: Localizer.deviceManagementRemarksFixedDate,
            accessor: nameof<DeviceAnnualInspection>(item => item.remarksCompletedDate),
            minWidth: 150,
            format: (value: Date) => (value.getFullYear() > 1) ? this.formatDate(value) : "-",
            noWrap: true,
        },
        {
            header: Localizer.deviceManagementFixBeforeLease,
            minWidth: 150,
            accessor: nameof<DeviceAnnualInspection>(item => item.fixBeforeLease),
            format: (value: boolean) => value ? Localizer.yes : Localizer.no,
            noWrap: true,
        },
        {
            header: Localizer.deviceManagementInspectedBy,
            minWidth: 150,
            accessor: nameof<DeviceAnnualInspection>(item => item.createdBy),
            format: (value: User | null) => value?.username ?? "",
            noWrap: true,
        },
        {
            header: Localizer.deviceManagementRemarksFixedBy,
            minWidth: 150,
            accessor: nameof<DeviceAnnualInspection>(item => item.remarksCompletedBy),
            format: (value: User | null) => value?.username ?? "",
            noWrap: true,
        },
        {
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionVendor,
            minWidth: 150,
            accessor: nameof<DeviceAnnualInspection>(item => item.vendor),
            format: (value: AnnualInspectionVendor | null) => value?.name ?? "",
            noWrap: true,
        },
        {
            header: Localizer.deviceAnnualInspectionPageDeviceUseBan,
            accessor: nameof<DeviceAnnualInspection>(item => (item.deviceBanOfUseText)),
            minWidth: 150,
            noWrap: true,
        },
        {
            header: Localizer.deviceServicePageLabelOperatingHours,
            accessor: nameof<DeviceAnnualInspection>(dai => dai.operatingHours),
            minWidth: 90,
            noWrap: true
        },
    ];

    private readonly _deviceFaultsColumns: ColumnDefinition[] = [
        {
            header: Localizer.userManagementPageGridCreatedAt,
            accessor: nameof<DeviceFault>(item => item.createdAt),
            format: (value: Date) => (value.getFullYear() > 1) ? this.formatDate(value) : "-",
            minWidth: 150,
            noWrap: true
        },
        {
            header: Localizer.genericFixedAt,
            accessor: nameof<DeviceFault>(item => item.fixedAt),
            minWidth: 150,
            format: (value: Date) => (value.getFullYear() > 1) ? this.formatDate(value) : "-",
            noWrap: true
        },
        {
            header: Localizer.userManagementPageGridCreatedBy,
            accessor: nameof<DeviceFault>(item => item.createdBy),
            minWidth: 150,
            format: (value: User) => (value) ? value.username : "-",
            noWrap: true,
        },
        {
            header: Localizer.reportDefinitionPageDropdownLabelFaultLevel,
            accessor: nameof<DeviceFault>(item => item.level),
            format: (value: FaultLevel) => (value != null) ? value > 0 ? Localizer.enumFaultLevelMajor : Localizer.enumFaultLevelMinor : "-",
            minWidth: 150,
            noWrap: true,
        },
        {
            header: Localizer.arsenalQuestionInputPermanentFault,
            accessor: nameof<DeviceFault>(item => item.permanent),
            format: (value: boolean) => (value) ? Localizer.yes : Localizer.no,
            minWidth: 150,
            noWrap: true,
            init: (cell) => this.initDeviceFaultOperationsAsync(cell),
            actions: [
                {
                    name: "delete",
                    title: Localizer.componentFormDelete,
                    icon: "far trash",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processDeviceFaultOperationAsync(cell, action)
                }
            ],
        },
        {
            header: Localizer.arsenalQuestionInputStepComment,
            accessor: nameof<DeviceFault>(item => item.comment),
            minWidth: 150,
            noWrap: true,
        },
        {
            header: Localizer.genericManualFault,
            accessor: nameof<DeviceFault>(item => (item.isManualFault)),
            format: (isManualFault) => isManualFault ? Localizer.yes : Localizer.no,
            minWidth: 50,
            noWrap: true,
        },
        {
            header: Localizer.reportDefinitionPageNumberInputLabelStep,
            accessor: nameof<DeviceFault>(item => (item.step)),
            format: (value: ReportDefinitionItem) => (value) ? value.name! : "-",
            minWidth: 150,
            noWrap: true,
        },
    ];

    private get device(): DeviceInfo {
        return this.props.device;
    }

    private formatDate(date: Date): string {
        return ToolsUtility.toDateString(date);
    }

    private async onTabSelect(index: number): Promise<void> {
        if (this.state.selectedTabIndex != index) {
            await this.setState({selectedTabIndex: index});
        }
    }

    private async getAnnualInspectionsAsync(sender: IBaseComponent): Promise<DeviceAnnualInspection[]> {

        const request: ListDeviceAnnualInspectionsRequest = new ListDeviceAnnualInspectionsRequest();
        request.deviceId = this.props.device.id;
        request.sortDirection = SortDirection.Desc;

        const annualInspections: DeviceAnnualInspection[] = await sender.postAsync("/api/annualInspection/ListDeviceAnnualInspections", request);

        let inspection: DeviceAnnualInspection | null = annualInspections.firstOrDefault();
        if (inspection) {
            inspection.nextAnnualInspectionDate = this.device.nextAnnualReportDate;
            inspection.previousAnnualInspectionDate = this.device.lastAnnualReportDate;
            inspection.deviceBanOfUseText = this.device.deviceBanOfUse == DeviceBanOfUse.Banned || this.device.deviceBanOfUse == DeviceBanOfUse.NeedBan
                ? Localizer.yes
                : Localizer.no;
        }

        return annualInspections;
    }

    private async getDeviceFaults(sender: IBaseComponent): Promise<DeviceFault[]> {
        const response : GetDeviceFaultsResponse = await sender.postAsync("/api/device/getDeviceFaults", this.props.device.id);

        if (response) {
            return response.faults;
        }

        return [];
    }

    private async fetchCountersAsync(sender: IBaseComponent): Promise<DeviceCounter[]> {
        return await sender.postAsync("/api/device/getDeviceCounters", this.props.device.id);
    }

    private async fetchPreviousInspectionsAsync(sender: IBaseComponent): Promise<Report[]> {
        let data =  await RentaToolsController.getLastReportsAsync(this.props.device.externalId)
        return data.reports;
    }

    private async fetchPreviousServicesAsync(sender: IBaseComponent): Promise<ServiceReport[]> {
        let data =  await RentaToolsController.getServiceReportsAsync(this.props.device.externalId, null, 1, 10);
        return data.serviceReports!.items;
    }

    private async initDeviceFaultOperationsAsync(cell: CellModel<DeviceFault>): Promise<void> {
        const removeAction: CellAction<DeviceFault> = cell.actions[0];

        let hasPermanentFault: boolean = false;
        if (cell.model && cell.model.step) {
            hasPermanentFault = cell.model.step?.permanentFault == true;
        }

        removeAction.visible = hasPermanentFault;
    }

    private async processDeviceFaultOperationAsync(cell: CellModel<DeviceFault>, action: CellAction<DeviceFault>): Promise<void> {
        const fault: DeviceFault = cell.model;
        const device: DeviceInfo = this.props.device;
        if (action.action.name === "delete") {
            const confirmed: boolean = await ch.confirmAsync(Localizer.deviceDetailsPanelDeviceFaultsConfirmDelete);
            if (!confirmed) {
                return;
            }

            const request = new UnmarkPermanentFaultRequest();

            request.deviceId = device.id;
            request.faultId = fault.id;

            await cell.grid.postAsync("/api/device/UnmarkPermanentDeviceFault", request);

            await cell.grid.reloadAsync();
        }
    }

    private async setFuelTypeToDeviceAsync(fuelType: FuelType | null): Promise<void> {
        this.setState({deviceFuelId: fuelType?.id!});

        await this.reRenderAsync();
    }

    private async setAdBlueTypeToDeviceAsync(adBlueType: AdBlue | null): Promise<void> {
        this.setState({deviceAdBlueId: adBlueType?.id!});

        await this.reRenderAsync();
    }

    private async saveFuelTypeToDevice(): Promise<void> {

        const request: SaveDeviceRequest = {
            deviceId: this.device.id,
            deviceStatus: this.device.status,
            fuelTypeId: this.state.deviceFuelId,
            adBlueId: this.state.deviceAdBlueId
        }

        await this.postAsync("api/device/saveDevice", request);

        await ch.flyoutMessageAsync(Localizer.deviceDetailsPanelFuelTypesSaveSuccessful);
    }

    public render(): React.ReactNode {
        return (
            <div className="col">
                <TabContainer id="DeviceDetailsTabs" onSelect={async (tab) => await this.onTabSelect(tab.index)}>

                    <Tab id="counters" title={Localizer.deviceDetailsPanelCounters}>
                        <div className={styles.container}>
                            <Grid ref={this._deviceCountersGridRef}
                                  columns={this._deviceCountersColumns}
                                  minWidth="auto"
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  fetchData={async (sender) => await this.fetchCountersAsync(sender)}
                            />
                        </div>
                    </Tab>

                    <Tab id="previousInspections" title={Localizer.devicePagePreviousInspections}>
                        <div className={styles.container}>
                            <Grid readonly
                                  ref={this._devicePreviousInspections}
                                  columns={this._devicePreviousInspectionsColumns}
                                  minWidth="auto"
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  fetchData={async (sender) => await this.fetchPreviousInspectionsAsync(sender)}
                            />
                        </div>
                    </Tab>

                    <Tab id="previousServices" title={Localizer.devicePagePreviousServices}>
                        <div className={styles.container}>
                            <Grid readonly
                                  ref={this._devicePreviousServices}
                                  columns={this._devicePreviousServiceColumns}
                                  minWidth="auto"
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  fetchData={async (sender) => await this.fetchPreviousServicesAsync(sender)}
                            />
                        </div>
                    </Tab>

                    <Tab id="annualInspections" title={Localizer.annualInspectionsPageHeader}>
                        <div className={styles.container}>
                            <Grid id={"annualInspectionTable"}
                                  ref={this._deviceAnnualInspectionGridRef}
                                  columns={this._deviceAnnualInspectionColumns}
                                  minWidth="auto"
                                  hovering={GridHoveringType.Row}
                                  fetchData={async (sender) => await this.getAnnualInspectionsAsync(sender)}
                            />
                        </div>
                    </Tab>

                    <Tab id="fuelTypes" title={Localizer.adminPageButtonFuelTypes}>
                        <div>
                            <Form inline
                                  onSubmit={async (_, data) => await this.saveFuelTypeToDevice()}
                            >
                                <Dropdown inline
                                          id={"fuelTypesDD"}
                                          label={Localizer.deviceDetailsPanelDropdownFuelType}
                                          nothingSelectedText={"-"}
                                          toggleIcon={{name: "caret-circle-down", style: IconStyle.Regular}}
                                          minWidth={350}
                                          items={this.props.fuelTypes}
                                          selectedItem={this.state.deviceFuelId}
                                          onChange={async (_, value) => await this.setFuelTypeToDeviceAsync(value)}
                                />
                                <Dropdown inline
                                          id={"adBlueDD"}
                                          label={Localizer.adBluesPageTitle}
                                          nothingSelectedText={"-"}
                                          toggleIcon={{name: "caret-circle-down", style: IconStyle.Regular}}
                                          minWidth={350}
                                          items={this.props.adBlueTypes}
                                          selectedItem={this.state.deviceAdBlueId}
                                          onChange={async (_, value) => await this.setAdBlueTypeToDeviceAsync(value)}
                                />
                                <Button small submit
                                        label={Localizer.genericSave}
                                        icon={{name: "fas save"}}
                                        type={ButtonType.Orange}
                                />
                            </Form>
                        </div>
                    </Tab>

                    <Tab id="deviceFaults" title={Localizer.deviceServicePageFaultsHeader}>
                        <div className={styles.container}>
                            <Grid id={"deviceFaultsTable"}
                                  ref={this._deviceFaultsGridRef}
                                  columns={this._deviceFaultsColumns}
                                  minWidth="auto"
                                  hovering={GridHoveringType.Row}
                                  fetchData={async (sender) => await this.getDeviceFaults(sender)}
                            />
                        </div>
                    </Tab>

                </TabContainer>

            </div>

        );
    }
}