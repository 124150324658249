export default abstract class BaseClassifier {

    public id: string = "";

    public name: string = "";

    public productExternalId: string = "";

    public isDeleted: boolean = false;

    public isInvoiceable: boolean = false;
}