import BaseController from "@/pages/BaseController";
import {IPagedList} from "@renta-apps/athenaeum-toolkit";
import EndpointPaths from "@/common/EndpointPaths";
import ListDeviceObservationsRequest from "@/models/server/requests/ListDeviceObservationsRequest";
import DeviceObservation from "@/models/server/DeviceObservation";

class DeviceController extends BaseController {
    public async listDeviceObservationsAsync(request: ListDeviceObservationsRequest): Promise<IPagedList<DeviceObservation>> {
        return await this.postAsync(EndpointPaths.DevicePaths.ListDeviceObservations, request);
    }
}

export default new DeviceController();