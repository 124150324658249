import React from "react";
import {PageContainer, PageHeader, PageRow, PasswordForm, PasswordFormType} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import SetPasswordRequest from "../../models/server/requests/SetPasswordRequest";
import Localizer from "../../localization/Localizer";

export default class ResetPasswordPage extends AuthorizedPage {

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.resetPasswordPageTitle} />
                <PageRow>
                    <div className="col-lg-6">
                        <PasswordForm type={PasswordFormType.SetPassword}
                                      onSubmit={(password) => this.changePassword(password)}
                        />
                    </div>
                </PageRow>
            </PageContainer>
        );
    }

    private async changePassword(password: string) {
        const request = new SetPasswordRequest(password);
        await this.postAsync("api/Application/SetPassword", request);
    }
}