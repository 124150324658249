import {RouteData} from "@/helpers/CypressHelper";

class InspectionReportsPage {
    public elements = {
        inspectionReportsList: () => cy.get("[id=inspectionReportsList]"),
        inspectionReportsTable: () => cy.get("[id=inspectionReportsTable]"),
        inspectionReportRow: (index: number) => cy.get(`#inspectionReportRow_${index}`),
        inspectionReportStatusIcon: (index: number) => cy.get(`#inspectionReportStatusIcon_${index}`),
        inspectionReportInfo: (index: number) => cy.get(`#inspectionReportInfo_${index}`),
        loadMoreButton: () => cy.get("[id=loadMoreButton]"),
    };

    public routes = {
        getReportsByDeviceExternalId: {
            path: "/api/ReturnInspection/GetReportsByDeviceExternalId",
            method: "GET",
        } as RouteData,
        listDevicePictures: {
            path: "/api/device/listDevicePictures",
            method: "POST",
        } as RouteData,
    };
}

export default new InspectionReportsPage();