import {FaultLevel} from "@/models/Enums";

export default class CheckItem {
    public name: string = "";
    
    public ok: boolean | null = null;
    
    public isRequired : boolean | null = null;

    public isCheckItem: boolean = true;
    
    public comment: string  | null = "";
    
    public isSkipped : boolean | null = null;
    
    public id: string = "";
    
    public faultLevel : FaultLevel | null = null;
}