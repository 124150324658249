import {ReturnInspectionStatus} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import SummaryPage from "@/tests/pages/SummaryPage";
import DashboardPage from "@/tests/pages/DashboardPage";
import SavePage from "@/tests/pages/SavePage";
import ReturnedDeviceList from "@/tests/pages/ReturnedDeviceList";
import DeviceFiltersModal from "@/tests/pages/DeviceFiltersModal";
import DevicePage from "@/tests/pages/DevicePage";
import CypressFeatureFlagService from "@/tests/CypressFeatureFlagService";
import Localizer from "@/localization/Localizer";

describe("Return Inspection partly completed tests", () => {
    beforeEach(() => {
        CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagServiceAndRepairEnabled);
        cy.login();
        cy.visit("Dashboard");
    });

    it("Same user should be able to partly complete inspection and continue", () => {
        DashboardPage.openDevice("922611");

        DevicePage.startReturnInspection();

        ReturnInspectionPage.startReturnInspection();

        ReturnInspectionPage.completeQuestionStep("Question #1");

        ReturnInspectionPage.markStepAsDone("Question #2");

        ReturnInspectionPage.partlyCompleteReturnInspection();

        DashboardPage.openDevice("922611");

        DevicePage.checkInspectionStartedBy('juhani.sihvonen@weare.fi')

        DevicePage.continueReturnInspection();

        ReturnInspectionPage.continueReturnInspection([ReturnInspectionPage.routes.getFuelTypes]);

        ReturnInspectionPage.completeQuestionStep("Question Resource #3");

        ReturnInspectionPage.completeQuestionStep("Question #4");

        SummaryPage.validateSummaryListCount(4);

        SummaryPage.validateSummary([
            {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
            {name: "Question #2", okay: true, userName: "juhani.sihvonen@weare.fi"},
            {
                name: "Question Resource #3",
                okay: true,
                userName: "juhani.sihvonen@weare.fi",
            },
            {name: "Test 123", okay: true, userName: "juhani.sihvonen@weare.fi"},
        ]);

        SummaryPage.saveInspection();

        SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

        SavePage.goToDevicePage();
    });

    it("First user should be able to partly complete inspection and second user should be able to continue", () => {
        cy.goToDashboard();

        DashboardPage.openDevice("922612");

        DevicePage.startReturnInspection();

        ReturnInspectionPage.startReturnInspection();

        ReturnInspectionPage.completeQuestionStep("Question #1");

        ReturnInspectionPage.markStepAsDone("Question #2");

        ReturnInspectionPage.partlyCompleteReturnInspection();

        cy.loginAsTechnician();

        cy.visit("Dashboard");

        DashboardPage.openDevice("922612");

        DevicePage.checkInspectionStartedBy('juhani.sihvonen@weare.fi')

        DevicePage.continueReturnInspection();

        ReturnInspectionPage.continueReturnInspection([ReturnInspectionPage.routes.getFuelTypes]);

        ReturnInspectionPage.completeQuestionStep("Question Resource #3");

        ReturnInspectionPage.completeQuestionStep("Question #4");

        SummaryPage.validateSummaryListCount(4);

        SummaryPage.validateSummary([
            {name: "Question #1", okay: true, userName: "juhani.sihvonen@weare.fi"},
            {name: "Question #2", okay: true, userName: "juhani.sihvonen@weare.fi"},
            {
                name: "Question Resource #3",
                okay: true,
                userName: "test.technician@weare.fi",
            },
            {name: "Test 123", okay: true, userName: "test.technician@weare.fi"},
        ]);

        SummaryPage.saveInspection();

        SavePage.resultShouldBe(Localizer.summaryPageReturnInspectionSaved);

        SavePage.goToDevicePage();
    });

    it("Partly completed: Device row", () => {
        const testDeviceExternalId = "922613";

        cy.goToDashboard();

        DashboardPage.openDevice(testDeviceExternalId);

        DevicePage.rollbackToReturnInspectionNeeded();

        DevicePage.startReturnInspection();

        ReturnInspectionPage.startReturnInspection();

        ReturnInspectionPage.completeQuestionStep("Question #1");

        ReturnInspectionPage.markStepAsDone("Question #2");

        ReturnInspectionPage.partlyCompleteReturnInspection();

        cy.goToReturnedDevicesPage();

        ReturnedDeviceList.openFilters();

        DeviceFiltersModal.filterByDeviceId(testDeviceExternalId);

        DeviceFiltersModal.apply();

        ReturnedDeviceList.validateDeviceStatus(
            testDeviceExternalId,
            Localizer.returnInspectionPartlyCompleted,
        );
        ReturnedDeviceList.validateDeviceStateColor(
            testDeviceExternalId,
            ReturnInspectionStatus.PartlyCompleted,
        );

        validateStepText(testDeviceExternalId, 2, 4);

        ReturnedDeviceList.shouldContainText(testDeviceExternalId, "juhani.sihvonen@weare.fi")

        ReturnedDeviceList.openDevice(testDeviceExternalId);
    });

    function validateStepText(testDeviceExternalId: string, stepNumber: number, stepCount: number): void {
        ReturnedDeviceList.shouldContainText(testDeviceExternalId, Localizer.returnInspectionSteps);
        ReturnedDeviceList.shouldContainText(testDeviceExternalId, stepNumber.toString());
        ReturnedDeviceList.shouldContainText(testDeviceExternalId, "/");
        ReturnedDeviceList.shouldContainText(testDeviceExternalId, stepCount.toString());
    }
});