import React from "react";
import {ApiProvider, ch} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Form, IconStyle, IStringInputModel, PageContainer, PageHeader, PageRow, PasswordInput, TextInput} from "@renta-apps/athenaeum-react-components";
import LoginRequest from "../../models/server/requests/LoginRequest";
import {LoginResultStatus} from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import UnleashHelper from "@/helpers/UnleashHelper";
import EnumProvider from "../../providers/EnumProvider";
import ReactGA from "react-ga4";
import Localizer from "../../localization/Localizer";

import styles from "./LoginPage.module.scss";

export default class LoginPage extends AnonymousPage {

    private _usernameInput: IStringInputModel = {value: ""};
    private _passwordInput: IStringInputModel = {value: ""};
    private _formRef: React.RefObject<Form> = React.createRef();

    private async loginAsync(username: string, password: string): Promise<void> {

        let login = new LoginRequest(username, password);
        let loginResult: LoginResultStatus = await this.postAsync<LoginResultStatus>("api/Application/Login", login);

        if (loginResult !== LoginResultStatus.Success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                let validationError: string = EnumProvider.getLoginResultStatusName(loginResult);
                await form.setValidationErrorsAsync(validationError);
            }
        }

        try {
            if (loginResult === LoginResultStatus.Success) {
                await UnleashHelper.setUserNameAsUserId(username);
            }
        } catch {
            // Not worth throwing exception for this
        }

        ReactGA.set({userId: ch.getUserId()})
    }

    public async handleSubmitAsync(): Promise<void> {
        await this.loginAsync(this._usernameInput.value, this._passwordInput.value);
    }

    private async onAzureClickAsync(): Promise<void> {

        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync<string>(async () => {
            return await this.getAsync<string>("api/Application/GetAzureSsoLogin");
        });
    }

    public getTitle(): string {
        return Localizer.topNavLogin;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.loginPageTitle} subtitle={Localizer.loginPageSubtitle} className={styles.header}/>

                <PageRow>
                    <div className="col-lg-6">
                        <Form id="form" onSubmit={async () => await this.handleSubmitAsync()} ref={this._formRef}>

                            <TextInput id="username" label={Localizer.loginPageEmailInput} model={this._usernameInput} required/>

                            <PasswordInput id="password" label={Localizer.loginPagePasswordInput} model={this._passwordInput} required/>

                            <ButtonContainer>

                                <Button id="login_button" type={ButtonType.Orange} label={Localizer.loginPageLoginButton} submit/>

                                {
                                    (!this.mobileApp) &&
                                    (
                                        <Button type={ButtonType.Blue}
                                                icon={{name: "windows", style: IconStyle.Brands}}
                                                label={Localizer.loginPageSsoButton}
                                                onClick={async () => await this.onAzureClickAsync()}
                                        />
                                    )
                                }

                                <Button route={PageDefinitions.forgotPasswordRoute} label={Localizer.loginPageForgotPasswordButton}/>

                            </ButtonContainer>

                        </Form>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}