import EndpointPaths from "@/common/EndpointPaths";

class CypressFeatureFlagService {
    public enable(featureFlag: string): void {
        cy.request("POST", EndpointPaths.LocalFeatureFlags.UpdateFeatureFlags, {
            featureFlagName: featureFlag,
            status: true,
        }).then(() => {
            return cy.wait(500);
        }).then(() => {
            cy.log(`Enable ${featureFlag} request completed`);
        });
    }

    public disable(featureFlag: string): void {
        cy.request("POST", EndpointPaths.LocalFeatureFlags.UpdateFeatureFlags, {
            featureFlagName: featureFlag,
            status: false,
        }).then(() => {
            return cy.wait(500);
        }).then(() => {
            cy.log(`Disable ${featureFlag} request completed`);
        });
    }
}

export default new CypressFeatureFlagService();