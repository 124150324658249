import React, { useState } from 'react';
import {Button, ButtonContainer, ButtonType, Form, Modal, ModalSize, OneColumn, Spinner, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import SaveCounterfactualForecastRequest from "@/models/server/requests/SaveCounterfactualForecastRequest";
import DeviceInfo from "@/models/server/DeviceInfo";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import ServicedPredictionInfo from "@/components/ServicedDevicesList/components/ServicedPredictionInfo";
import Localizer from "@/localization/Localizer";

import styles from "./ReportWrongForecastModal.module.scss";

export interface IReportWrongForecastModalData {
    deviceInfo: DeviceInfo;
}

interface IReportWrongForecastModalProps {
    modalRef: React.RefObject<Modal<IReportWrongForecastModalData>>;
    onReportWrongForecast: (report: SaveCounterfactualForecastRequest) => Promise<void>;
}

const ReportWrongForecastModal: React.FC<IReportWrongForecastModalProps> = ({ modalRef, onReportWrongForecast }) => {
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(new DeviceInfo());
    const [reportWrongForecastRequest, setReportWrongForecastRequest] = useState<SaveCounterfactualForecastRequest>(new SaveCounterfactualForecastRequest());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onChangeCommentAsync = async (value: string): Promise<void> => {
        const updatedWrongForecastRequest = {
            ...reportWrongForecastRequest,
            comment: value
        };

        setReportWrongForecastRequest(updatedWrongForecastRequest);
    };

    const onSubmitAsync = async (): Promise<void> => {
        setIsLoading(true);

        await onReportWrongForecast(reportWrongForecastRequest);

        setIsLoading(false);

        await onCloseAsync();
    };

    const onCloseAsync = async (): Promise<void> => {
        await clearAsync();

        modalRef.current?.closeAsync();
    };

    const clearAsync = async (): Promise<void> => {
        setDeviceInfo(new DeviceInfo());
        setReportWrongForecastRequest(new SaveCounterfactualForecastRequest());
    };

    const onBeforeOpen = async (modal: Modal<IReportWrongForecastModalData>): Promise<void> => {
        if (modal.data?.deviceInfo) {
            setDeviceInfo(modal.data.deviceInfo);
        }
    };

    const onOpen = async (): Promise<void> => {
        if (deviceInfo) {
            const wrongForecastRequest: SaveCounterfactualForecastRequest = {
                ...reportWrongForecastRequest,
                deviceId: deviceInfo.id,
                servicePredictionDate: deviceInfo.closestPredictionDate!,
                predictionDateType: DeviceInfo.getLatestPredictionInfo(deviceInfo)!.type,
                maintenanceReason: DeviceInfo.getLatestPredictionInfo(deviceInfo)!.maintenanceReason,
                forecastDate: DeviceInfo.getLatestPredictionInfo(deviceInfo)!.predictionCreatedAt!,
                sendNotification: true
            };

            setReportWrongForecastRequest(wrongForecastRequest);
        }
    };

    return (
        <Modal ref={modalRef}
               size={ModalSize.Default}
               title={Localizer.get(Localizer.reportWrongForecastModalTitle, deviceInfo.name, deviceInfo.externalId)}
               onBeforeOpen={onBeforeOpen}
               onOpen={onOpen}
               onClose={() => clearAsync()}
        >

            {
                (isLoading) && <Spinner/>
            }


            <Form onSubmit={() => onSubmitAsync()}>

                <ServicedPredictionInfo includeDate
                                        item={deviceInfo}
                />

                <OneColumn>

                    <TextAreaInput showRemainingAmount
                                   id={"comment"}
                                   label={Localizer.genericComment}
                                   readonly={isLoading}
                                   value={reportWrongForecastRequest.comment}
                                   maxLength={RentaToolsConstants.maximumCommentLength}
                                   onChange={(_, value) => onChangeCommentAsync(value)}
                    />

                </OneColumn>

                <ButtonContainer className={Utility.css(styles.buttons)}>
                    <Button id={"closeButton"}
                            disabled={isLoading}
                            type={ButtonType.Blue}
                            label={Localizer.componentModalClose}
                            onClick={async () => await onCloseAsync()}
                    />

                    <Button submit
                            id={"sendButton"}
                            disabled={isLoading}
                            type={ButtonType.Orange}
                            label={Localizer.componentFormSend}
                    />
                </ButtonContainer>
            </Form>

        </Modal>
    );
}

export default ReportWrongForecastModal;