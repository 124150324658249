import {ApplicationContext, BasePage, BasePageParameters} from "@renta-apps/athenaeum-react-common";
import UnleashHelper from "@/helpers/UnleashHelper";

export default abstract class AnonymousPage<TParams extends BasePageParameters = {}, TState = {}>
    extends BasePage<TParams, TState, ApplicationContext> {


    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        // Update userId on anonymous page load to clear the userId after the user has logged out.
        UnleashHelper.updateClientUserId();
    }
}