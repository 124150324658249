import HttpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";
import {
    GetManyOperatingHourRecordsByDeviceIdRequest
} from "@/models/server/requests/GetManyOperatingHourRecordsByDeviceIdRequest";
import DeviceOperatingHoursRecordResponse from "@/models/server/DeviceOperatingHourRecordsResponse";

class OperatingHourRecordsService{
    
    public async getOperatingHoursRecordsByDeviceId(id : string) : Promise<DeviceOperatingHoursRecordResponse>{
        const request = new GetManyOperatingHourRecordsByDeviceIdRequest();
        request.deviceId = id;
        return await HttpClient.postAsyncWithoutErrorHandling<DeviceOperatingHoursRecordResponse>(EndpointPaths.OperatingHours.GetOperatingHoursByDeviceId, request);
    }
}

export default new OperatingHourRecordsService();