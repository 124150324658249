import {RouteData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";
import ReportDefinitionsPage from "@/tests/pages/ReportDefinitionsPage";

class MappingsPage {
    public elements = {
        navTabs: () => cy.get(".nav-tabs"),
        mappingContainer: (mappingReportDefinitionId: string) => cy.get(`[data-cy="container_${mappingReportDefinitionId}"]`),
        containerInputDD: (container: string) => this.elements.mappingContainer(container).find(".athenaeum-form-inputGroup"),
        deviceTypeDD: () => cy.get("#device_type_dropdown"),
        submitBtn: () => cy.get("[id=add_mapping_submit]"),
        selectAllBtn: () => cy.get("[id=select_all]"),
        removeCategoryButton: () => cy.get('#removeCategory'),
        categoryRemoveBtnsInContainer: (container: string) => this.elements.mappingContainer(container).find("button"),
    };

    public routes = {
        getMappings: {
            path: "api/mappings/getMappings",
            method: "POST",
        } as RouteData
    }

    public constants = {
        mappingReportDefinitionId1: "7cc2c505-c868-4d5f-87f4-36914a7c995a",
        mappingReportDefinitionId2: "7c345a56-b74c-437f-a814-7e5a6638232f",
        categoryHeavy: 'HEAVY',
        dinolift105L: 'DINOLIFT 105TL',
        dinolift100TL: 'DINOLIFT 100TL',
    }

    public selectCategoryToAdd(mappingReportDefinitionId: string, text: string, exactMatch: boolean = false, openDeviceTypeDropdown: boolean = true) {
        this.elements.containerInputDD(mappingReportDefinitionId).selectDropdownValue(text, exactMatch, true);

        if (openDeviceTypeDropdown) {
            this.elements.deviceTypeDD()
                .should('be.visible')
                .click();
        }
    }

    public assertDeviceTypesCount(count: number) {
        this.elements.deviceTypeDD()
            .find(".athenaeum-dropdown-item")
            .should("have.length", count);
    }

    public selectFirstDeviceTypeAndSave() {
        this.elements.deviceTypeDD().find(".athenaeum-dropdown-item").first().click();

        this.saveSelection();
    }

    public closeModal() {
        cy.get(".modal-content").each(($el) => {
            if ($el.is(":visible"))
                cy.wrap($el).type('{esc}');
        })
    }

    public removeFirstCategoryFrom(container: string) {
        this.elements.categoryRemoveBtnsInContainer(container).first().click();
    }

    public saveSelection() {
        this.elements.submitBtn().should('exist').click({force: true});
    }

    public mapProductGroupToReportDefinition(reportName: string, productGroupName: string) {
        cy.goToReportDefinitionsPage();
        ReportDefinitionsPage.chooseType(Localizer.enumReportDefinitionTypeReturnInspection);
        ReportDefinitionsPage.elements.reportDefinitionsList()
            .children()
            .contains(reportName)
            .parents('span')
            .last()
            .then(($el) => {
                const reportDefinitionId: string = $el.attr('data-cy')!;

                cy.goToMappingsPage();
                // Wait a bit till the content loaded is required. :(
                cy.wait(1000);

                // Unmap the category if it was mapped earlier.
                cy.get('#returnInspections')
                    .each(($el) => {
                        if ($el.has(`[data-cy="${productGroupName}"]`).length > 0) {
                            this.unMapCategory(productGroupName);
                        }
                    });

                this.elements.mappingContainer(reportDefinitionId)
                    .should("contain.text", Localizer.mappingsPageTextNoCategories);

                this.selectCategoryToAdd(reportDefinitionId, productGroupName, true, false);
                this.elements.submitBtn().click();
            });
    }

    public unMapCategory(categoryName: string) {
        cy.get(`[data-cy="${categoryName}"]`)
            .within(() => {
                this.elements.removeCategoryButton().click();
            });
    }
}

export default new MappingsPage();