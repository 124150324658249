
export default class ServiceAction {
    public id: string = "";
    
    public name: string = "";
    
    public description: string | null = null;
    
    public isDeleted: boolean = false;
    
    public isServiceAction: boolean = true;
}