import React from "react";
import {Button, ButtonContainer, ButtonType, Dropdown, Form, Modal, SelectListItem} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";
import SaveAnnualInspectionVendorRequest from "@/models/server/requests/SaveAnnualInspectionVendorRequest";
import TransformProvider from "@/providers/TransformProvider";

import Localizer from "@/localization/Localizer";
import styles from "@/components/EditVendorModal/EditVendorModal.module.scss";


export interface IEditVendorProps {
    vendors: AnnualInspectionVendor[];
    onSubmit(sender: IBaseComponent, request: SaveAnnualInspectionVendorRequest): Promise<void>;
}

export interface IEditVendorState {
    editVendor: AnnualInspectionVendor;
    selectedVendor: AnnualInspectionVendor | null;
}

export default class EditVendorModal extends BaseComponent<IEditVendorProps, IEditVendorState> {

    state: IEditVendorState = {
        editVendor: new AnnualInspectionVendor(),
        selectedVendor: null,
    }

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get editVendor(): AnnualInspectionVendor {
        return this.state.editVendor;
    }

    private get selectedOwner(): SelectListItem | null {
        if (this.state.selectedVendor) {
            return this.vendors.firstOrDefault(item => item.value == this.state.selectedVendor?.id);
        }

        const vendor = this.editVendor;
        const vendors = this.props.vendors;
        const matchingOwner = vendors.firstOrDefault(v => v.id == vendor.parentId);

        return this.vendors.firstOrDefault(item => item.value == matchingOwner?.id);
    }

    private get hasChild(): boolean {
        return this.props.vendors.some(item => item.parentId == this.editVendor.id && !item.isDeleted);
    }

    public get vendors(): SelectListItem[] {
        if (this.props.vendors) {
            return TransformProvider.toAnnualInspectionVendorListItems(
                this.props.vendors
                    .where(item => item.id != this.editVendor.id && item.parentId == null && !item.isDeleted)
            );
        }

        return [];
    }

    public async openAsync(editVendor: AnnualInspectionVendor): Promise<void> {
        await this.setState({editVendor});

        await this.modal.openAsync();
    }

    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    private async onSubmitAsync(): Promise<void> {
        const request = {
            id: this.editVendor.id,
            name: this.editVendor.name,
            userId: this.editVendor.userId,
            parentId: this.state.selectedVendor?.id ?? null,
        } as SaveAnnualInspectionVendorRequest;

        await this.props.onSubmit(this, request);

        await this.onCloseAsync();
    }

    private async onChangeVendorAsync(item: SelectListItem | AnnualInspectionVendor | null): Promise<void> {
        const selectedVendor: AnnualInspectionVendor | null = this.props.vendors.firstOrDefault(v => v.id == (item as AnnualInspectionVendor)?.id);

        await this.setState({selectedVendor});
    }

    private async clearStateAsync(): Promise<void> {
        await this.setState({editVendor: new AnnualInspectionVendor(), selectedVendor: null});
    }

    private async onCloseAsync(): Promise<void> {
        await this.clearStateAsync();
        await this.closeAsync();
    }

    private static get modalId(): string {
        return "editVendorModal";
    }

    render(): React.ReactNode {
        return (
            <Modal id={EditVendorModal.modalId}
                   ref={this._modalRef}
                   className={styles.editVendorModal}
                   title={Localizer.annualInspectionVendorsPageSaveVendorDetails}
                   onClose={() => this.onCloseAsync()}
            >

                <Form className={styles.form}>

                    {
                        (!this.hasChild) &&
                        (
                            <Dropdown id="vendorOwner"
                                      label={Localizer.genericOwner}
                                      items={this.vendors}
                                      selectedItem={this.selectedOwner}
                                      onChange={(_, item) => this.onChangeVendorAsync(item)}/>
                        )
                    }

                    <ButtonContainer>
                        <Button type={ButtonType.Light} label={Localizer.genericCancel} onClick={() => this.closeAsync()}/>
                        <Button type={ButtonType.Orange} label={Localizer.genericSave} onClick={() => this.onSubmitAsync()}/>
                    </ButtonContainer>
                </Form>

            </Modal>
        )
    }
}