import {IDictionaryPair} from "typescript-collections/dist/lib/Dictionary";

class LanguageService {
    
    //Danish is not supported
    
    private supportedLanguage : IDictionaryPair<string, string>[] = [
        { key: 'fi', value: 'fi'},
        { key: 'sv', value: 'sv-SE'},
        { key: 'pl', value: 'pl'},
        { key: 'en', value: 'en-GB'},
        { key: 'nb', value: 'no-NO'},
    ]
    
    public isLanguageSupported(language : string) : boolean {
        return this.supportedLanguage.some(p=>p.key === language);
    }
    
    public getLanguageNameForSpeechRecognition = (language : string) : string => {
        if(this.isLanguageSupported(language)){
            const lang =  this.supportedLanguage.firstOrDefault(p=>p.key === language);
            return lang!.value;
        }
        
        throw Error("Unsupported language");
    }
}

export default new LanguageService();