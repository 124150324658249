
export default class ListServiceReportsRequest {

    public deviceExternalId: string = "";

    public startDate: Date | null = new Date();

    public pageNumber: number = 1;

    public pageSize: number = Number.MAX_VALUE;
}