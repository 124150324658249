import React from "react";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, EmailInput, Form, IStringInputModel, PageContainer, PageHeader, PageRow, TextAreaInput, TextInput} from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import User from "../../models/server/User";
import Localizer from "../../localization/Localizer";

export interface IContactSupportPageParameters extends BasePageParameters {
    requestId: string | null;
}

export default class ContactSupportPage extends AnonymousPage<IContactSupportPageParameters> {

    public email: IStringInputModel = { value: "" };
    public phone: IStringInputModel = { value: "" };
    public firstName: IStringInputModel = { value: "" };
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public formRef: React.RefObject<any> = React.createRef();

    public async initializeAsync(): Promise<void> {
        const user: User | null = this.findUser();
        if (user) {
            this.email.value = user.email || "";
            this.phone.value = user.phone || "";
            this.firstName.value = user.firstName || "";
            this.lastname.value = user.lastName || "";
        }
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        let contactSupportRequest = {} as any;
        data.keys().map(key => {
            return contactSupportRequest[key] = data.getValue(key);
        });

        await this.postAsync("api/contactSupport/contactSupport", contactSupportRequest);
    }

    public get requestId(): string | null {
        return (this.props.parameters != null) ? this.props.parameters.requestId : null;
    }

    public getTitle(): string {
        return Localizer.topNavContactSupport;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.contactSupportPageTitle} />
                <PageRow>
                    <div className="col-lg-12">
                        <Form id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(data)} ref={this.formRef}>
                            <EmailInput id="email" label={Localizer.componentFormEmail} model={this.email} required readonly={!!this.email.value} />
                            <TextInput id="phone" label={Localizer.componentFormPhone} model={this.phone} />
                            <TextInput id="firstName" label={Localizer.componentFormFirstName} model={this.firstName} />
                            <TextInput id="lastname" label={Localizer.componentFormLastname} model={this.lastname} />
                            <TextAreaInput id="message" label={Localizer.componentFormMessage} model={this.message} required rows={6} />

                            <ButtonContainer>
                                <Button type={ButtonType.Orange} label={Localizer.componentFormSend} submit />
                            </ButtonContainer>
                        </Form>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}