import ReportDefinitionItem from "./ReportDefinitionItem";
import {ResourceItemType} from "@/models/Enums";
import NumbersHelper from "@/helpers/NumbersHelper";

export default class ResourceReportItem extends ReportDefinitionItem {

    public value: number = 0;

    public invoiced: boolean | null = null;

    public static isValid(item: ResourceReportItem): boolean {

        if (item.resourceType === ResourceItemType.Operating) {
            const minValueOk: boolean = item.min == null || NumbersHelper.greaterOrEqual(item.value, item.min);
            const maxValueOk: boolean = item.max == null || NumbersHelper.lessOrEqual(item.value, item.max);
            return minValueOk && maxValueOk;
        } else {
            if (item.value === 0) {
                return true;
            }

            return !!item.valueTypeId && (!item.min || item.min <= item.value);
        }
    }
}