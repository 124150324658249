import React from "react";
import ArsenalQuestionInput from "../../components/ArsenalQuestionInput/ArsenalQuestionInput";
import QuestionReportItem from "../Models/QuestionReportItem";
import Localizer from "@/localization/Localizer";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import {UploadFullImageResponse} from "@/models/UploadFullImageResponse";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import FileService from "@/services/FileService";

export interface IQuestionPageProps {
}

interface IQuestionPageState extends IReturnInspectionWizardPageState {
    isLoading: boolean;
}

export default class QuestionPage extends ReturnInspectionWizardPage<IQuestionPageProps, IQuestionPageState> {

    state: IQuestionPageState = {
        isLoading: false,
    };

    private readonly _questionInputRef: React.RefObject<ArsenalQuestionInput> = React.createRef();

    private get reportItem(): QuestionReportItem {
        return this.getReportItem<QuestionReportItem>();
    }

    protected isNextButtonDisabled(): boolean {
        return this.state.isLoading;
    }

    public getWizardDescription(): string {
        return "";
    }

    public async startImageUpload(image: FileModel): Promise<UploadFullImageResponse | null> {
        this.setState({
            isLoading: true,
        });

        return await FileService.uploadFullImageAsync(image);
    }

    public async imageUploaded(): Promise<void> {
        this.setState({
            isLoading: false,
        });
    }

    public async nextAsync(): Promise<void> {
        await this._questionInputRef.current!.validateAsync();

        if (QuestionReportItem.isValid(this.reportItem)) {
            await this.hideAlertAsync();
            await super.nextAsync();
            return;
        }

        if (this.reportItem.ok == null) {
            await this.alertErrorAsync(Localizer.questionPageAlertSelectPassOrFail, false, true);
        }
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <ArsenalQuestionInput ref={this._questionInputRef}
                                      model={this.reportItem}
                                      preview={this.preview}
                                      convertImage={async (file) => await this.startImageUpload(file)}
                                      imageUploaded={async () => await this.imageUploaded()}
                />

            </React.Fragment>
        );
    }
}