export default class IO {
    public input1: Input = new Input;
    public input2: Input = new Input;
}

export class Input {
    public active: boolean = false;
    public voltage: number = 0;
    public isInput: boolean = false;
}

