import User from "@/models/server/User";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import {FaultLevel} from "@/models/Enums";

export default class DeviceFault {

    public id: string = "";

    public deviceId: string = "";

    public reportId: string | null = null;

    public stepNumber: number = 0;

    public stepId: string | null = "";

    public step: ReportDefinitionItem | null = null;

    public level: FaultLevel = FaultLevel.Minor;

    public fixed: boolean = false;

    public createdAt: Date | null = null;

    public createdById: string | null = null;

    public createdBy: User | null = null;

    public fixedAt: Date | null = null;

    public fixedById: string | null = null;

    public fixedBy: User | null = null;

    public serviceReportId: string | null = null;

    public isDeviceFault: boolean = true;

    public comment : string | null = null;

    public permanent: boolean = false;

    public observationId: string | null = null;

    public isManualFault: boolean = false;
}