import {AnnualInspectionType} from "@/models/Enums";
import ProductGroup from "@/models/server/ProductGroup";

export default class AnnualInspectionTypeMappings {

    public annualInspectionType: AnnualInspectionType = AnnualInspectionType.Inspection;

    public productGroups: ProductGroup[] = [];

    public isMapping: boolean = true;
}