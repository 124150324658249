import {ObservationsFilter} from "@/models/Enums";

export default class ListDeviceObservationsRequest {
    public pageSize: number = 5;

    public pageNumber: number = 1;

    public deviceId: string | null = null;

    public type: ObservationsFilter | null = null;

    public date: Date | null = null;

    public from: Date | null = null;

    public to: Date | null = null;

    public resetPage() {
        this.pageNumber = 1;
    }

    public clear() {
        this.type = null;
        this.date = null;
        this.from = null;
        this.to = null;
    }

    public isEmpty(): boolean {
        return ((this.type == null))
            && (!this.date)
            && (!this.from)
            && (!this.to);
    }
}