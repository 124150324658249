import DashboardPage from "@/tests/pages/DashboardPage";
import DeviceAnnualInspectionsPage from "@/tests/pages/DeviceAnnualInspectionsPage";
import DeviceAnnualInspectionFormPage from "@/tests/pages/DeviceAnnualInspectionFormPage";
import {executeWithIntercept} from "@/helpers/CypressHelper";
import DevicePage from "@/tests/pages/DevicePage";
import Localizer from "@/localization/Localizer";

describe('Device existing annual inspection form tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    });

    // Device has been inspected with remarks. There should be a functioning option for marking remarks as repaired.
    it('Remarks fixed test', () => {
        DashboardPage.openDevice("9001");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.fixRemarksBtn().click();

        cy.wait(100);

        DeviceAnnualInspectionFormPage.assertHeader();

        cy.wait(100);


        executeWithIntercept(() => {
            DeviceAnnualInspectionFormPage.attachFile();
            DeviceAnnualInspectionFormPage.submitForm();

        }, [DeviceAnnualInspectionFormPage.routes.saveRemarksRepaired])
    });

    // Device has been inspected with remarks & banned from use. Marking remarks as repaired should clear ban of use.
    it('Remarks repaired - ban removed', () => {
        DashboardPage.openDevice("86972");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.validateBanOfUseBanner(Localizer.bannerDeviceUseBanRemarks);

        DeviceAnnualInspectionsPage.elements.fixRemarksBtn().click();
 
        DeviceAnnualInspectionFormPage.assertHeader();

        executeWithIntercept(() => {
            DeviceAnnualInspectionFormPage.attachFile();
            DeviceAnnualInspectionFormPage.submitForm();

        }, [DeviceAnnualInspectionFormPage.routes.saveRemarksRepaired])

        DeviceAnnualInspectionsPage.assertNoBan();
    });

    // Device has been banned from use due overdue inspection, accepting should clear ban.
    it('Accept - ban removed', () => {
        DashboardPage.openDevice("86973");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.validateBanOfUseBanner(Localizer.bannerDeviceUseBan);

        executeWithIntercept(() => {

            DeviceAnnualInspectionsPage.elements.acceptBtn().click();

        }, [
            DeviceAnnualInspectionFormPage.routes.getUserDepos,
            DeviceAnnualInspectionFormPage.routes.listVendors,
            DeviceAnnualInspectionFormPage.routes.getAnnualInspectionTypeItemsByProductGroup
        ])

        DeviceAnnualInspectionFormPage.attachFile();

        executeWithIntercept(() => {
            DeviceAnnualInspectionFormPage.elements.submitButton().click();
        }, [DeviceAnnualInspectionFormPage.routes.saveAnnualInspection])

        DeviceAnnualInspectionsPage.assertNoBan();
    });
});