import {DeviceCounterType} from "@/models/Enums";

export default class DeviceCounter {
    public type: DeviceCounterType = DeviceCounterType.CurrentOperatingHours;
    
    public value: number = 0;
    
    public modifiedAt: Date = new Date();

    public isDeviceCounter: boolean = true;
}