import React from "react";
import {ILanguage, TimeSpan, Utility} from "@renta-apps/athenaeum-toolkit";
import {AlertModel, AlertType, ch, PageRouteProvider, TextAlign, VerticalAlign} from "@renta-apps/athenaeum-react-common";
import {
    Alert,
    Button,
    ButtonContainer,
    ButtonType,
    CellModel,
    Checkbox,
    ColumnDefinition,
    ColumnType,
    Dropdown,
    DropdownOrderBy,
    DropdownSubtextType,
    EmailInput,
    Form,
    Grid,
    IconSize,
    Inline,
    List,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    SelectListItem,
    Tab,
    TabContainer,
    TextInput,
    ToolbarButton,
    ToolbarContainer,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import {AuthType, LoginResultStatus, UserRole} from "@/models/Enums";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import Depo from "@/models/server/Depo";
import SaveUserResponse from "../../models/server/responses/SaveUserResponse";
import UserInvitation from "../../models/server/UserInvitation";
import GetUsersRequest from "../../models/server/requests/GetUsersRequest";
import DeleteUserResponse from "../../models/server/responses/DeleteUserResponse";
import PageDefinitions from "@/providers/PageDefinitions";
import ToolsUtility from "@/helpers/ToolsUtility";
import EnumProvider from "../../providers/EnumProvider";
import TransformProvider from "../../providers/TransformProvider";
import Localizer from "../../localization/Localizer";

import styles from "./UserManagementPage.module.scss"

interface IUserManagementPageProps {
}

interface IUserManagementPageState {
    showDeleted: boolean;
    showExpired: boolean;
    user: User | null;
    prevUser: User | null;
    showAddButton: boolean;
    depos: Depo[] | null;
    filterRoles: UserRole[] | null;
    filterDepoIds: string[] | null;
    isModified: boolean;
}

export default class UserManagementPage extends AuthorizedPage<IUserManagementPageProps, IUserManagementPageState> {

    state: IUserManagementPageState = {
        showDeleted: false,
        showExpired: false,
        user: null,
        prevUser: null,
        showAddButton: true,
        depos: null,
        filterRoles: null,
        filterDepoIds: null,
        isModified: false
    };

    private _listRef: React.RefObject<List<User>> = React.createRef();
    private _emailRef: React.RefObject<EmailInput> = React.createRef();
    private _depoIdsRef: React.RefObject<Dropdown<Depo>> = React.createRef();
    private _originalUser: User | null = null;

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.userManagementPageGridCreatedByLanguageItemName,
            accessor: nameof<UserInvitation>(invitation => invitation.createdBy),
            minWidth: 100
        },
        {
            header: Localizer.userManagementPageGridTypeLanguageItemName,
            accessor: nameof<UserInvitation>(invitation => invitation.type),
            type: ColumnType.Custom,
            format: "InvitationType",
            minWidth: 90
        },
        {
            header: Localizer.userManagementPageGridAuthTypeLanguageItemName,
            accessor: nameof<UserInvitation>(invitation => invitation.authType),
            type: ColumnType.Custom,
            format: "AuthType",
            minWidth: 90,
        },
        {
            header: Localizer.userManagementPageGridCreatedAtLanguageItemName,
            accessor: nameof<UserInvitation>(invitation => invitation.createdAt),
            format: (value: Date) => this.formatDate(value),
            textAlign: TextAlign.Center,
            minWidth: 90,
            verticalAlign: VerticalAlign.Middle
        },
        {
            header: Localizer.userManagementPageGridExpiresAtLanguageItemName,
            accessor: nameof<UserInvitation>(invitation => invitation.validTill),
            format: (value: Date) => this.formatDate(value),
            textAlign: TextAlign.Center,
            minWidth: 90,
            init: (cell) => this.initValidTill(cell)
        },
        {
            header: Localizer.userManagementPageGridProcessedAtLanguageItemName,
            accessor: nameof<UserInvitation>(invitation => invitation.processedAt),
            format: (value: Date) => this.formatDate(value),
            textAlign: TextAlign.Center,
            minWidth: 90
        },
        {
            header: Localizer.userManagementPageGridReusableLanguageItemName,
            accessor: (model: UserInvitation) => model.reusable ? "✓" : "",
            textAlign: TextAlign.Center,
            minWidth: 90
        }
    ];

    private formatDate(date: Date): string {
        return ToolsUtility.toDateString(date);
    }

    private transformUserDepo(depo: Depo): SelectListItem {
        const item: SelectListItem = TransformProvider.toSelectListItem(depo);
        if (this.user && this.user.depos) {
            const userDepos: Depo[] = this.user.depos;
            item.selected = userDepos.some(depo => depo.id === item.value);
        }
        return item;
    }

    private initValidTill(cell: CellModel<UserInvitation>): void {
        const model: UserInvitation = cell.row.model;
        const diff: TimeSpan = Utility.diff(model.validTill, Utility.utcNow());
        const expired: boolean = (diff.totalMilliseconds < 0);
        if (expired) {
            cell.className = "danger";
        }
    }

    private async addUserAsync() {
        const prevUser: User | null = this.user;

        const user = new User();
        user.language = Localizer.language;
        user.role = UserRole.Technician;
        user.depo = ((this.state.depos) && (this.state.depos.length > 0)) ? this.state.depos[0] : null;
        user.depoId = (user.depo) ? user.depo.id : "";

        await this.setState({user, prevUser, showAddButton: false});

        if (this._emailRef.current) {
            this._emailRef.current.focus();
        }
    }

    private async deleteUserAsync(): Promise<void> {
        const user: User = this.user!;

        const response: DeleteUserResponse = await this.postAsync("api/userManagement/deleteUser", user.id);

        const message: string = (response.removedPermanently)
            ? Utility.format(Localizer.userManagementPageAlertMessageAsyncDeleteUserPermanently, this.userFullName)
            : Utility.format(Localizer.userManagementPageAlertMessageAsyncDeleteUser, this.userFullName);

        await this.alertMessageAsync(message, true);

        if (!this.showDeleted || response.removedPermanently) {
            await this.setState({user: null});

            await this.list.reloadAsync();

            this.list.scrollToSelected();
        } else {
            user.isDeleted = true;

            await this.list.reRenderAsync();

            await this.reRenderAsync();
        }
    }

    private async restoreUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("api/userManagement/restoreUser", user.id);

        user.isDeleted = false;

        const message: string = Utility.format(Localizer.userManagementPageAlertMessageAsyncRestoreUser, this.userFullName);

        await this.alertMessageAsync(message, true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async unlockUserAsync(): Promise<void> {
        const user: User = this.user!;

        await this.postAsync("api/userManagement/unlockUser", user.id);

        user.isLocked = false;

        const message: string = Utility.format(Localizer.userManagementPageAlertMessageAsyncUnlockUser, this.userFullName);

        await this.alertMessageAsync(message, true);

        await this.list.reRenderAsync();

        await this.reRenderAsync();
    }

    private async cancelAddUserAsync(): Promise<void> {
        await this.setState({user: this.state.prevUser, prevUser: null, showAddButton: true});
    }

    private async cancelModifyingAsync(): Promise<void> {
        Utility.copyTo(this._originalUser, this.user);
        await this.setIsModifiedAsync(false);
    }

    private async setIsModifiedAsync(isModified = true): Promise<void> {
        if (isModified !== this.state.isModified && this.state.showAddButton) {
            await this.setState({isModified})
        }
    }

    private get isModified(): boolean {
        return this.state.isModified;
    }

    private get canDelete(): boolean {
        return ch.getUser().id !== this.user!.id;
    }

    private async getUsersAsync(sender: List<User>): Promise<User[]> {
        const request = new GetUsersRequest();
        request.showDeleted = this.showDeleted;
        request.showExpired = this.showExpired;
        request.depoIds = this.state.filterDepoIds?.length ?? 0 > 0
            ? this.state.filterDepoIds
            : null;
        request.roles = (this.filterRoles != null && this.filterRoles.length > 0) ? this.filterRoles : null;
        return await sender.postAsync("api/userManagement/getUsers", request);
    }

    private async setSelectedUserAsync(selectedUser: User | null): Promise<void> {
        if (this.user !== selectedUser) {
            if (this.state.showAddButton) {
                await this.setState({user: selectedUser, prevUser: null, showAddButton: true});
            }

            this._originalUser = Utility.clone(selectedUser);
        }
        await this.reloadUserDepoIds();
    }

    private async setEmailAsync(value: string): Promise<void> {
        this.user!.email = value;
        await this.setIsModifiedAsync();
    }

    private async setDepoAsync(value: Depo, userInteraction: boolean): Promise<void> {

        if (userInteraction) {
            await this.setIsModifiedAsync();
        }

        this.user!.depo = value;
        this.user!.depoId = value.id;

        await this.reRenderAsync();
    }

    private async setDeposAsync(sender: Dropdown<Depo>, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            await this.setIsModifiedAsync();
        }

        this.user!.depos = sender.selectedItems;

        await this.reRenderAsync();
    }

    private async setUserRoleAsync(item: SelectListItem, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            await this.setIsModifiedAsync();
        }

        const user: User = this.user!;
        const prevUserRole: UserRole = user.role;
        const userRole: UserRole = parseInt(item.value);
        user.role = userRole;
        if (userRole == UserRole.Admin) {
            user.depos = this.state.depos || [];
        } else if (prevUserRole == UserRole.Admin) {
            user.depos = [];
        }

        await this.reRenderAsync();

        await this.reloadUserDepoIds();
    }

    private async setAuthTypeAsync(item: SelectListItem, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            await this.setIsModifiedAsync();
        }

        const user: User = this.user!;
        user.authType = parseInt(item.value);
        await this.reRenderAsync();
    }

    private async setFirstNameAsync(value: string): Promise<void> {
        this.user!.firstName = value;
        await this.setIsModifiedAsync();
    }

    private async setLastNameAsync(value: string): Promise<void> {
        this.user!.lastName = value;
        await this.setIsModifiedAsync();
    }

    private async setMiddleNameAsync(value: string): Promise<void> {
        this.user!.middleName = value;
        await this.setIsModifiedAsync();
    }

    private async setPhoneAsync(value: string): Promise<void> {
        this.user!.phone = value;
        await this.setIsModifiedAsync();
    }

    private async setLanguageAsync(language: ILanguage, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            await this.setIsModifiedAsync();
        }

        this.user!.language = language.code;
    }

    private async setCanInvoiceFuelingAndWashingAsync(canInvoiceFuelingAndWashing: boolean): Promise<void> {
        this.user!.canInvoiceFuelingAndWashing = canInvoiceFuelingAndWashing;
        await this.setIsModifiedAsync();
    }

    private async setCanInvoiceAdditionalExpensesAsync(canInvoiceAdditionalExpenses: boolean): Promise<void> {
        this.user!.canInvoiceAdditionalExpenses = canInvoiceAdditionalExpenses;
        await this.setIsModifiedAsync();
    }
    
    private async setCanEditOwnAnnualInspectionsAsync(canEditOwnAnnualInspections: boolean): Promise<void> {
        this.user!.canEditOwnAnnualInspections = canEditOwnAnnualInspections;
        await this.setIsModifiedAsync();
    }

    private async setCanEditDeviceOrderAsync(canEditDeviceOrder: boolean): Promise<void> {
        this.user!.canEditDeviceOrder = canEditDeviceOrder;
        await this.setIsModifiedAsync();
    }

    private async saveAsync(): Promise<void> {

        let user: User = this.user!;
        const newUser: boolean = (!user.id);

        const request = new SaveUserRequest();
        request.id = user.id;
        request.authType = user.authType;
        request.email = user.email;
        request.phone = user.phone;
        request.firstName = user.firstName;
        request.lastName = user.lastName;
        request.middleName = user.middleName;
        request.role = user.role;
        request.depoId = user.depoId;
        request.depoIds = (user.depos || []).map(depo => depo.id);
        request.language = user.language;
        request.canInvoiceFuelingAndWashing = (user.role == UserRole.Technician) ? user.canInvoiceFuelingAndWashing : true;
        request.canInvoiceAdditionalExpenses = (user.role == UserRole.Technician) ? user.canInvoiceAdditionalExpenses : true;
        request.canEditOwnAnnualInspections = (user.role == UserRole.Technician) ? user.canEditOwnAnnualInspections : true;
        request.canEditDeviceOrder = (user.role == UserRole.Technician) ? user.canEditDeviceOrder : true;

        const caller: User = ch.getUser();
        let callerRoleDecreased: boolean = false;
        if (request.id == caller.id && caller.role == UserRole.Admin) {
            if (request.role < caller.role) {
                callerRoleDecreased = true;
            }
        }

        const response: SaveUserResponse = await this.postAsync("api/userManagement/saveUser", request);

        if (callerRoleDecreased) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
            const message: string = Utility.format(Localizer.dashboardPageAlertMessageRightsReduced, this.userFullName);
            await ch.alertErrorAsync(message);
        } else {
            if (response.userAlreadyExists) {
                const message: string = Utility.format(Localizer.userManagementPageAlertErrorAsyncAccountExist, this.userFullName);
                await this.alertErrorAsync(message, true);
                return;
            }

            if (response.invitationSentFailed) {
                const message: string = Utility.format(Localizer.userManagementPageAlertErrorAsyncPhoneNumberInvalid, this.userFullName);
                await this.alertErrorAsync(message, true);
                return;
            }

            if(response.invalidPhoneNumber){
                await this.alertErrorAsync(Localizer.accountPageAlertErrorInvalidPhoneNumber, true);
                return;
            }

            const message: string = Utility.format(Localizer.userManagementPageAlertMessageAsyncAccountSaved, this.userFullName);
            await this.alertMessageAsync(message, true);

            if (newUser) {

                await this.list.reloadAsync();

                await this.setState({user: response.user!, showAddButton: true});

                this.list.scrollToSelected();

            } else {
                await this.list.reRenderAsync();
            }

            this._originalUser = response.user!;

            await this.setIsModifiedAsync(false);
        }
    }

    private async resetPasswordAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync<LoginResultStatus>("api/userManagement/ResetPassword", userId);
        await this.list.reloadAsync();
        await this.list.reRenderAsync();
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.list.reloadAsync();
    }

    private async setShowExpiredAsync(showExpired: boolean): Promise<void> {
        await this.setState({showExpired});
        await this.list.reloadAsync();
    }

    private async reloadUserDepoIds(): Promise<void> {
        //Multi-dropdown does not support SelectedListItems, should be reloaded manually
        if (this._depoIdsRef.current) {
            await this._depoIdsRef.current.reRenderAsync();
        }
    }

    private async setFilterRolesAsync(items: SelectListItem[]): Promise<void> {
        const filterRoles: UserRole[] = items.map(item => parseInt(item.value));
        await this.setState({filterRoles});
        await this.list.reloadAsync();
    }

    private async setFilterDeposAsync(items: Depo[]): Promise<void> {
        const filterDepoIds: string[] = items.map(item => item.id);
        await this.setState({filterDepoIds});
        await this.list.reloadAsync();
    }

    private get user(): User | null {
        return this.state.user;
    }

    private get userFullName(): string {
        return (this.user) ? TransformProvider.userToString(this.user) : "";
    }

    private get multipleDepos(): boolean {
        return ((this.user != null) && (this.user!.role != UserRole.Admin));
    }

    private get multipleDeposText(): string {
        return (!this.multipleDepos)
            ? Localizer.userManagementPageMultipleDeposText
            : "";
    }

    private get list(): List<User> {
        return this._listRef.current!;
    }

    private get showDeleted(): boolean {
        return this.state.showDeleted;
    }

    private get showExpired(): boolean {
        return this.showDeleted && this.state.showExpired;
    }

    private get filterRoles(): UserRole[] | null {
        return this.state.filterRoles;
    }

    private async resendInvitationAsync(): Promise<void> {
        const userId: string = this.user!.id;
        await this.postAsync("api/userManagement/resendInvitation", userId);
        await this.list.reloadAsync();
        await this.list.reRenderAsync();
    }

    public get alert(): AlertModel {
        const alertModel = new AlertModel();
        alertModel.message = Utility.format(Localizer.userManagementPageAlertUserPasswordInfo, this.userFullName);
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Info;
        return alertModel;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        if (this.state.depos == null) {
            const depos: Depo[] = await this.getAsync("api/userManagement/getDepos");
            await this.setState({depos});
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.userManagement} fullHeight>

                <PageHeader title={Localizer.userManagementPageTitle} subtitle={Localizer.userManagementPageSubtitle} />

                <PageRow>
                    <div className="col">

                        <ToolbarContainer>

                            <div className={"container"}>

                                <div className={"row"}>

                                    <div className={"col-lg-4 col-md-6 col-xs-12 py-2 py-md-0"}>

                                        <Dropdown id="rolesFilter"
                                                  inline noWrap multiple
                                                  minWidth={210}
                                                  nothingSelectedText={Localizer.userManagementPageDropdownChooseRole}
                                                  label={Localizer.userManagementPageLabelRole}
                                                  orderBy={DropdownOrderBy.None}
                                                  items={EnumProvider.getUserRoleItems()}
                                                  onChange={async (sender) => await this.setFilterRolesAsync(sender.selectedItems)}
                                        />

                                    </div>

                                    <div className={"col-lg-4 col-md-6 col-xs-12 py-2 py-md-0"}>

                                        <Dropdown id="depoFilter"
                                                  inline noWrap multiple
                                                  minWidth={210}
                                                  nothingSelectedText={Localizer.userManagementPageDropdownChooseDepot}
                                                  label={Localizer.userManagementPageLabelLocation}
                                                  orderBy={DropdownOrderBy.None}
                                                  items={this.state.depos || []}
                                                  onChange={async (sender) => await this.setFilterDeposAsync(sender.selectedItems)}
                                        />

                                    </div>

                                    <div className={"col-lg-4 col-md-12 col-xs-12 py-lg-0 py-2"}>

                                        <Inline>

                                            <Checkbox id={"CheckBox_showDeleted"}
                                                      inline
                                                      label={Localizer.userManagementPageCheckboxShowDeleted}
                                                      value={this.showDeleted}
                                                      onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                                            />

                                            {
                                                (this.showDeleted) &&
                                                (
                                                    <Checkbox id={"CheckBox_ShowExpired"}
                                                              inline
                                                              label={Localizer.userManagementPageCheckboxShowExpired}
                                                              value={this.showExpired}
                                                              onChange={async (sender, value) => await this.setShowExpiredAsync(value)}
                                                    />
                                                )
                                            }

                                        </Inline>


                                    </div>


                                </div>

                            </div>

                            <Inline>

                                <ToolbarButton label={Localizer.toolbarButtonAddUser}
                                               icon={{name: "plus", size: IconSize.Large}}
                                               type={ButtonType.Orange}
                                               disabled={!this.state.showAddButton || this.isModified}
                                               onClick={async () => await this.addUserAsync()}
                                />

                            </Inline>

                        </ToolbarContainer>

                        <div className="row">

                            <div className="col-md-4">

                                <List required
                                      id={"UserList"}
                                      ref={this._listRef}
                                      disabled={!this.state.showAddButton || this.isModified}
                                      className={styles.usersList}
                                      orderBy={DropdownOrderBy.Name}
                                      filterMinLength={10}
                                      maxHeight={"60vh"}
                                      fetchItems={async (sender) => await this.getUsersAsync(sender)}
                                      transform={item => TransformProvider.toUserStatusListItem(item, true)}
                                      subtextType={DropdownSubtextType.Row}
                                      selectedItem={this.user || undefined}
                                      onChange={async (_, item) => await this.setSelectedUserAsync(item)}
                                />

                            </div>

                            <div className="col-md-8">
                                {
                                    (this.user) &&
                                    (
                                        <TabContainer>

                                            <Tab id="account" title={Localizer.userManagementPageTabAccount}>

                                                <Form id="form" onSubmit={async () => await this.saveAsync()}>

                                                    <TwoColumns>

                                                        <Dropdown id="authType" required noSubtext
                                                                  label={Localizer.userManagementPageLabelAuthenticationType}
                                                                  disabled={!!this.user.id}
                                                                  orderBy={DropdownOrderBy.None}
                                                                  items={EnumProvider.getAuthTypeItems()}
                                                                  selectedItem={EnumProvider.getAuthTypeItem(this.user.authType)}
                                                                  onChange={async (sender, item, userInteraction) => await this.setAuthTypeAsync(item!, userInteraction)}
                                                        />

                                                        <Dropdown id="role" required noSubtext
                                                                  label={Localizer.userManagementPageLabelRole}
                                                                  orderBy={DropdownOrderBy.None}
                                                                  items={EnumProvider.getUserRoleItems()}
                                                                  selectedItem={EnumProvider.getUserRoleItem(this.user.role)}
                                                                  onChange={async (sender, item, userInteraction) => await this.setUserRoleAsync(item!, userInteraction)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <EmailInput id="email" ref={this._emailRef}
                                                                    label={Localizer.componentFormEmail}
                                                                    required={(this.user.authType == AuthType.Email)}
                                                                    value={this.user.email}
                                                                    readonly={(this.user.authType == AuthType.Email) && (!!this.user.id)}
                                                                    onChange={async (sender, value) => await this.setEmailAsync(value)}
                                                        />

                                                        <PhoneInput id="phone"
                                                                    label={Localizer.componentFormPhone}
                                                                    required={(this.user.authType == AuthType.Phone)}
                                                                    value={this.user.phone}
                                                                    readonly={(this.user.authType == AuthType.Phone) && (!!this.user.id)}
                                                                    onChange={async (sender, value) => await this.setPhoneAsync(value)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <Dropdown required noSubtext
                                                                  id="depoId"
                                                                  label={Localizer.userManagementPageLabelLocation}
                                                                  items={this.state.depos || []}
                                                                  selectedItem={this.user.depo || undefined}
                                                                  onChange={async (sender, item, userInteraction) => await this.setDepoAsync(item!, userInteraction)}
                                                        />

                                                        <Dropdown required noSubtext multiple
                                                                  id="depoIds"
                                                                  ref={this._depoIdsRef}
                                                                  label={Localizer.userManagementPageLabelDepots}
                                                                  disabled={!this.multipleDepos}
                                                                  items={this.state.depos || []}
                                                                  transform={(depo) => this.transformUserDepo(depo)}
                                                                  selectedItems={this.user.depos || []}
                                                                  selectedTextTransform={() => this.multipleDeposText}
                                                                  onChange={async (sender, item, userInteraction) => await this.setDeposAsync(sender, userInteraction)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <TextInput required trim
                                                                   id="firstName"
                                                                   label={Localizer.componentFormFirstName}
                                                                   value={this.user.firstName}
                                                                   onChange={async (sender, value) => await this.setFirstNameAsync(value)}
                                                        />

                                                        <TextInput required trim
                                                                   id="lastName"
                                                                   label={Localizer.componentFormLastname}
                                                                   value={this.user.lastName}
                                                                   onChange={async (sender, value) => await this.setLastNameAsync(value)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <TextInput trim
                                                                   id="middleName"
                                                                   label={Localizer.componentFormMiddleName}
                                                                   value={this.user.middleName}
                                                                   onChange={async (sender, value) => await this.setMiddleNameAsync(value)}
                                                        />

                                                        <Dropdown id="language" required
                                                                  label={Localizer.componentFormLanguage}
                                                                  items={Localizer.supportedLanguages}
                                                                  selectedItem={Localizer.findLanguage(this.user.language)}
                                                                  onChange={async (sender, item, userInteraction) => await this.setLanguageAsync(item!, userInteraction)}
                                                        />

                                                    </TwoColumns>

                                                    {
                                                        (this.user.role == UserRole.Technician) &&
                                                        (
                                                            <>
                                                                <OneColumn className="mb-3">
                                                                    <Checkbox inline
                                                                              label={Localizer.userManagementPageCheckboxCanInvoiceFuelingAndWashing}
                                                                              value={this.user.canInvoiceFuelingAndWashing || false}
                                                                              onChange={async (sender, value) => await this.setCanInvoiceFuelingAndWashingAsync(value)}
                                                                    />
                                                                </OneColumn>

                                                                <OneColumn className="mb-3">
                                                                    <Checkbox inline
                                                                              label={Localizer.userManagementPageCheckboxCanInvoiceExpenses}
                                                                              value={this.user.canInvoiceAdditionalExpenses || false}
                                                                              onChange={async (sender, value) => await this.setCanInvoiceAdditionalExpensesAsync(value)}
                                                                    />
                                                                </OneColumn>
                                                                
                                                                <OneColumn className="mb-3">
                                                                    <Checkbox inline
                                                                              label={Localizer.userManagementPageCheckboxCanEditOwnAnnualInspections}
                                                                              value={this.user.canEditOwnAnnualInspections || false}
                                                                              onChange={async (sender, value) => await this.setCanEditOwnAnnualInspectionsAsync(value)}
                                                                    />
                                                                </OneColumn>

                                                                <OneColumn className="mb-3">
                                                                    <Checkbox inline
                                                                              label={Localizer.userManagementCheckboxCanEditDeviceOrder}
                                                                              value={this.user.canEditDeviceOrder || false}
                                                                              onChange={async (sender, value) => await this.setCanEditDeviceOrderAsync(value)}
                                                                    />
                                                                </OneColumn>
                                                            </>
                                                        )
                                                    }

                                                    <ButtonContainer>

                                                        {
                                                            (!this.state.showAddButton) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.userManagementPageButtonCancel}
                                                                        type={ButtonType.Primary}
                                                                        icon={{name: "far ban", size: IconSize.Large}}
                                                                        onClick={async () => await this.cancelAddUserAsync()}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            (this.isModified) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.userManagementPageButtonCancel}
                                                                        type={ButtonType.Primary}
                                                                        icon={{name: "far ban", size: IconSize.Large}}
                                                                        confirm={Localizer.userManagementPageConfirmationButtonRollback}
                                                                        onClick={async () => await this.cancelModifyingAsync()}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.state.showAddButton) && (!this.user.isDeleted)) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.componentFormDelete}
                                                                        icon={{name: "trash-alt", size: IconSize.Large}}
                                                                        type={ButtonType.Primary}
                                                                        disabled={!this.canDelete}
                                                                        onClick={async () => await this.deleteUserAsync()}
                                                                        confirm={Utility.format(Localizer.userManagementPageConfirmationButtonDeleteUser, this.userFullName)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.state.showAddButton) && (this.user.isDeleted)) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.userManagementPageButtonRestore}
                                                                        icon={{
                                                                            name: "trash-restore",
                                                                            size: IconSize.Large
                                                                        }}
                                                                        type={ButtonType.Primary}
                                                                        onClick={async () => await this.restoreUserAsync()}
                                                                        confirm={Utility.format(Localizer.userManagementPageConfirmationButtonRestoreUser, this.userFullName)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            ((this.state.showAddButton) && (!this.user.isDeleted) && (this.user.isLocked)) &&
                                                            (
                                                                <Button small
                                                                        minWidth={90}
                                                                        label={Localizer.userManagementPageButtonUnlock}
                                                                        icon={{name: "unlock", size: IconSize.Large}}
                                                                        type={ButtonType.Success}
                                                                        onClick={async () => await this.unlockUserAsync()}
                                                                        confirm={Utility.format(Localizer.userManagementPageConfirmationButtonUnlockUser, this.userFullName)}
                                                                />
                                                            )
                                                        }

                                                        <Button submit
                                                                type={ButtonType.Orange}
                                                                icon={{name: "far save"}}
                                                                label={Localizer.componentFormSave}
                                                        />

                                                    </ButtonContainer>

                                                </Form>

                                            </Tab>

                                            {
                                                (this.state.showAddButton) &&
                                                (

                                                    <Tab id="invitations"
                                                         title={Localizer.userManagementPageTabInvitations}>

                                                        <div className={styles.passwordInfo}>
                                                            {
                                                                (!this.user.hasPassword) &&
                                                                (
                                                                    <Alert model={this.alert}/>
                                                                )
                                                            }
                                                        </div>

                                                        <div className={styles.invitations}>

                                                            <Grid columns={this._columns}
                                                                  data={this.user.invitations}
                                                                  noDataText={Localizer.userManagementPageGridNoInvitationsText}
                                                            />

                                                        </div>

                                                        <ButtonContainer>

                                                            <Button small
                                                                    minWidth={90}
                                                                    label={Localizer.userManagementPageButtonResendInvitation}
                                                                    icon={{name: "envelope", size: IconSize.Large}}
                                                                    disabled={this.user.isDeleted || this.user.isLocked || this.user.hasPassword}
                                                                    type={ButtonType.Primary}
                                                                    onClick={async () => await this.resendInvitationAsync()}
                                                                    confirm={Utility.format(Localizer.userManagementPageConfirmationButtonResendInvitation, this.userFullName)}
                                                            />

                                                            <Button small
                                                                    minWidth={90}
                                                                    label={Localizer.userManagementPageButtonResetPassword}
                                                                    icon={{name: "repeat", size: IconSize.Large}}
                                                                    disabled={this.user.isDeleted || this.user.isLocked || !this.user.hasPassword}
                                                                    type={ButtonType.Success}
                                                                    onClick={async () => await this.resetPasswordAsync()}
                                                                    confirm={Utility.format(Localizer.userManagementPageConfirmationButtonResetPassword, this.userFullName)}
                                                            />

                                                        </ButtonContainer>

                                                    </Tab>
                                                )
                                            }

                                        </TabContainer>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </PageRow>

            </PageContainer>
        );
    }

}