import Chainable = Cypress.Chainable;

export default class GridHelper {

    /**
     * @param grid A Grid or Grid identifier.
     * @param cellContent Unique content of the Grid Cell which Row to get.
     * @param exactMatch Should the {@link cellContent} completely match to an expected value.
     * @returns The Grid Row which contains the {@link cellContent}.
     */
    public static getGridRowByCellContent(grid: Chainable<JQuery> | string, cellContent: string, exactMatch: boolean = true): Chainable<JQuery<HTMLTableRowElement>> {
        if (grid instanceof String || typeof grid === 'string') {
            return (exactMatch)
                ? cy.get(`${grid}`).contains(new RegExp("^" + cellContent + "$", "g")).parents('tr')
                : cy.get(`${grid}`).contains(cellContent).parents('tr');
        } else {
            return (exactMatch)
                ? grid.contains(new RegExp("^" + cellContent + "$", "g")).parents('tr')
                : grid.contains(cellContent).parents('tr');
        }
    }

    /**
     * @param grid The Grid or Grid identifier. A certain grid where a cell will be searched by {@link cellContent} and {@link column}.
     * @param cellContent Unique content of a Grid Cell to get.
     * @param columnNumber is a column where content is expected.
     * @param exactMatch Should the {@link cellContent} completely match to an expected value.
     * @returns The Grid Cell of the given Grid {@link column} of the Grid Row which contains the {@link cellContent}.
     */
    public static getGridCell(
        grid: Chainable<JQuery> | string,
        cellContent: string,
        columnNumber: number,
        exactMatch: boolean = true,
    ): Chainable<JQuery<HTMLTableCellElement>> {
        return this.getGridRowByCellContent(grid, cellContent, exactMatch).find(`> :nth-child(${columnNumber})`);
    }

    public static getGridVisibleRows(grid: Chainable<JQuery> | string): Chainable<JQuery> {
        return (grid instanceof String || typeof grid === 'string')
            ? cy.get(`${grid}`).find('tbody:first-of-type').find('tr:visible')
            : grid.find('tbody:first-of-type').find('tr:visible');
    }

    /**
     * If grid do not fit to a container then columns that didn't fit are shown in inner container.
     * To open the inner container the 'fa-plus-circle' icon should be clicked in the row.
     * Method goes through the all visible rows in a grid and opens inner container for each row.
     */
    public static expandAllGridVisibleRows(grid: Chainable<JQuery>): void {
        this.getGridVisibleRows(grid)
            .each(($tr) => {
                cy.wrap($tr)
                    .find('td:eq(0)')
                    .should('have.class', 'athenaeum-grid-responsive')
                    .within(($el) => {
                        if ($el.find('.fa-plus-circle').length === 1) {
                            cy.get('.fa-plus-circle').click();
                        }
                    });
            });
    }

    /**
     * If grid do not fit to a container then columns that didn't fit are shown in inner container.
     * To open the inner container the 'fa-plus-circle' icon should be clicked in the row.
     * Method opens a specific row inner container.
     */
    public static expandSpecificGridRow(
        grid: Chainable<JQuery> | string,
        cellContent: string,
        exactMatch: boolean = true): void {
        this.getGridCell(grid, cellContent, 1, exactMatch)
            .should('have.class', 'athenaeum-grid-responsive')
            .then(($el) => {
                if ($el.find('.fa-plus-circle').length === 1) {
                    cy.wrap($el)
                        .find('.fa-plus-circle')
                        .trigger('click');
                }
            });
    }

    public static isGridResponsive(grid: JQuery<HTMLElement>): boolean {
        return grid.find('tr:eq(1)')
            .find('td:eq(0)')
            .hasClass('athenaeum-grid-responsive');
    }

    public static getGridCollapsedRowValueCell(
        grid: Chainable<JQuery> | string,
        mainRowCellContent: string,
        collapsedRowColumnName: string,
        exactMatch: boolean = true,
    ): Chainable<JQuery<HTMLTableCellElement>> {
        return this.getGridRowByCellContent(grid, mainRowCellContent, exactMatch)
            .nextAll('.athenaeum-grid-collapsedRow').eq(0)
            .find('tr')
            .contains(collapsedRowColumnName)
            .parents('tr')
            .find(`> :nth-child(2)`);
    }

    public static saveGridCollapsedRow(
        grid: Chainable<JQuery> | string,
        mainRowCellContent: string,
        exactMatch: boolean = true,
    ): void {
        this.getGridRowByCellContent(grid, mainRowCellContent, exactMatch)
            .nextAll('.athenaeum-grid-collapsedRow').eq(0)
            .find('.athenaeum-grid-actions')
            .find(".fa-save")
            .click();
    }

    public static deleteGridCollapsedRow(
        grid: Chainable<JQuery> | string,
        mainRowCellContent: string,
        exactMatch: boolean = true,
    ): void {
        this.getGridRowByCellContent(grid, mainRowCellContent, exactMatch)
            .nextAll('.athenaeum-grid-collapsedRow').eq(0)
            .find('.athenaeum-grid-actions')
            .find(".fa-trash-alt")
            .trigger('click');
    }

    public static restoreGridCollapsedRow(
        grid: Chainable<JQuery> | string,
        mainRowCellContent: string,
        exactMatch: boolean = true,
    ): void {
        this.getGridRowByCellContent(grid, mainRowCellContent, exactMatch)
            .nextAll('.athenaeum-grid-collapsedRow').eq(0)
            .find('.athenaeum-grid-actions')
            .find(".fa-undo-alt")
            .trigger('click');
    }
}