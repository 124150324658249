import React, {useEffect, useState} from 'react';
import {Alert, Button, ButtonContainer, ButtonType, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, Form, FourColumns, SelectListItem, SwitchNullable, TwoColumns} from "@renta-apps/athenaeum-react-components";
import {AlertModel, AlertType, ApiProvider, ch} from "@renta-apps/athenaeum-react-common";
import UserNotificationSetting from "@/models/server/notification/UserNotificationSetting";
import User from "@/models/server/User";
import GetUserNotificationSettingRequest from "@/models/server/requests/GetUserNotificationSettingRequest";
import GlobalNotificationSetting from "@/models/server/notification/GlobalNotificationSetting";
import {NotificationInterval, NotificationType} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import GetUserNotificationSettingResponse from "@/models/server/responses/GetUserNotificationSettingResponse";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./NotificationSettings.module.scss";

interface INotificationSettingsProps {
    isGlobal: boolean;
    availableNotificationTypes: NotificationType[];
    availableReceivers?: User[];
    fetchAvailableReceivers?(notificationType: NotificationType): Promise<void>;
}

const NotificationSettings: React.FC<INotificationSettingsProps> = (props: INotificationSettingsProps) => {

    const [notificationSetting, setNotificationSetting] = useState<UserNotificationSetting | GlobalNotificationSetting>(
        props.isGlobal ? new GlobalNotificationSetting() : new UserNotificationSetting()
    );

    const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.WrongServicePrediction);
    const [isGlobalSettingEnabled, setIsGlobalSettingEnabled] = useState<boolean>(false);
    const [canEditNotificationSetting, setCanEditNotificationSetting] = useState<boolean>(false);

    const availableNotificationTypes: NotificationType[] = props.availableNotificationTypes || [];
    const availableReceivers: User[] = props.availableReceivers || [];
    const isGlobal: boolean = props.isGlobal;

    const sendTime: Date | null = notificationSetting.interval !== NotificationInterval.OnDemand
        ? notificationSetting.sendTime
        : null;

    const notificationIntervalTypes = () => {
        switch (notificationType) {
            case NotificationType.WrongServicePrediction:
                return EnumProvider.getNotificationIntervalItems();
            default:
                throw new TypeError(`Invalid notification type: ${notificationType}`);
        }
    };

    const notificationSettingTypeDescription = () => {
        switch (notificationType) {
            case NotificationType.WrongServicePrediction:
                return Localizer.notificationSettingsNotificationTypeWrongServicePredictionDescription;
            default:
                throw new Error(`Unsupported notification type ${notificationType}`);
        }
    };

    const intervalSettingsVisible = () => {
        return !isGlobal || notificationSetting.notificationType === NotificationType.WrongServicePrediction;
    };

    const alert = () => {
        const alertModel = new AlertModel();
        alertModel.message = Utility.format(
            Localizer.notificationSettingsAlertMessageGlobalSettingsDisabled,
            EnumProvider.getNotificationTypeText(notificationSetting.notificationType)
        );
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Info;

        return alertModel;
    };

    const forbiddenForEditingAlert = () => {
        const alertModel = new AlertModel();
        alertModel.message = Utility.format(
            Localizer.notificationSettingsAlertInfoNoPermissions,
            EnumProvider.getNotificationTypeText(notificationSetting.notificationType)
        );
        alertModel.dismissible = false;
        alertModel.alertType = AlertType.Info;

        return alertModel;
    };

    const onChangeNotificationTypeAsync = async (item: SelectListItem): Promise<void> => {
        const notificationType: number = Number.parseInt(item.value);

        setNotificationType(notificationType);

        await getNotificationSettingAsync();
    };

    const onChangeNotificationsEnabledAsync = async (enabled: boolean): Promise<void> => {
        const updatedNotificationSetting = {...notificationSetting, enabled};

        setNotificationSetting(updatedNotificationSetting);
    };

    const onChangeNotificationPausedFromAsync = async (time: Date): Promise<void> => {
        const updatedNotificationSetting = {...notificationSetting, pausedFrom: time};

        setNotificationSetting(updatedNotificationSetting);
    };

    const onChangeNotificationPausedToAsync = async (time: Date): Promise<void> => {
        const updatedNotificationSetting = {...notificationSetting, pausedUntil: time};

        setNotificationSetting(updatedNotificationSetting);
    };

    const onChangeNotificationReceiversAsync = async (sender: Dropdown<User>): Promise<void> => {
        if (isGlobal) {
            const updatedNotificationSetting = {...notificationSetting, receivers: sender.selectedItems};

            setNotificationSetting(updatedNotificationSetting);
        }
    };

    const onChangeNotificationMediaTypeAsync = async (item: SelectListItem): Promise<void> => {
        const updatedNotificationSetting = {...notificationSetting, mediaType: Number.parseInt(item.value)};

        setNotificationSetting(updatedNotificationSetting);
    };

    const onChangeNotificationIntervalAsync = async (item: SelectListItem): Promise<void> => {
        const updatedNotificationSetting = {...notificationSetting, interval: Number.parseInt(item.value)};

        setNotificationSetting(updatedNotificationSetting);
    };

    const onChangeNotificationSendTimeAsync = async (time: Date): Promise<void> => {
        const updatedNotificationSetting = {...notificationSetting, sendTime: time};

        setNotificationSetting(updatedNotificationSetting);
    };

    const onChangeNotificationWeeklyOccurrenceAsync = async (sender: Dropdown<SelectListItem>): Promise<void> => {
        const updatedNotificationSetting = {
            ...notificationSetting,
            weeklySendOccurrence: sender.selectedItems.map((item) => parseInt(item.value)),
        };

        setNotificationSetting(updatedNotificationSetting);
    };

    const saveNotificationSettingAsync = async (): Promise<void> => {
        if (isGlobal) {
            await ApiProvider.postAsync("/api/notification/saveGlobalNotificationSetting", notificationSetting);
            await ch.flyoutMessageAsync(Localizer.notificationSettingsFlyoutMessageGlobalSettingSaved);
        } else {
            if (canEditNotificationSetting) {
                const updatedUserNotificationSetting = {
                    ...notificationSetting,
                    custom: true,
                };

                const response = await ApiProvider.postAsync("/api/account/saveUserNotificationSetting", updatedUserNotificationSetting);

                setNotificationSetting(response as UserNotificationSetting);

                await ch.flyoutMessageAsync(Localizer.notificationSettingsFlyoutMessageUserSettingSaved);
            } else {
                throw new Error(`Editing "${notificationType}" notification type is not allowed.`);
            }
        }
    };

    const getNotificationSettingAsync = async (): Promise<void> => {
        if (isGlobal) {
            await getGlobalNotificationSettingAsync();

            if (notificationType == NotificationType.WrongServicePrediction && props.fetchAvailableReceivers) {
                await props.fetchAvailableReceivers(notificationType);
            }
        } else {
            await getUserNotificationSettingAsync();
        }
    };

    const getGlobalNotificationSettingAsync = async (): Promise<void> => {
        const globalNotificationSetting = await ApiProvider.postAsync("/api/notification/getGlobalNotificationSetting", notificationType);

        setNotificationSetting(globalNotificationSetting as GlobalNotificationSetting);
        setNotificationType((globalNotificationSetting as GlobalNotificationSetting).notificationType);
    };

    const getUserNotificationSettingAsync = async (): Promise<void> => {
        const request = new GetUserNotificationSettingRequest();
        request.notificationType = notificationType;

        const response: GetUserNotificationSettingResponse = await ApiProvider.postAsync("/api/account/getUserNotificationSetting", request);

        setNotificationSetting(response.userNotificationSetting);
        setNotificationType(response.userNotificationSetting.notificationType);
        setIsGlobalSettingEnabled(response.isGlobalSettingEnabled);
        setCanEditNotificationSetting(response.isReceiver);
    };

    useEffect(() => {
        const initializeAsync = async (): Promise<void> => {
            await getNotificationSettingAsync();
        };

        initializeAsync();
    }, []);

    return (
        <div className={styles.notificationSettings}>
            {
                (!isGlobal && !isGlobalSettingEnabled) &&
                (
                    <div>
                        <Alert model={alert()}/>
                    </div>
                )
            }

            <div className={Utility.css(styles.mainSettings, (ch.mobile) && styles.mobile)}>
                {
                    (availableNotificationTypes.length > 0) &&
                    (
                        <Dropdown required noWrap noFilter
                                  id={"notificationType"}
                                  minWidth={"15rem"}
                                  className={"mb-4"}
                                  align={DropdownAlign.Left}
                                  label={Localizer.notificationSettingsDropdownType}
                                  items={availableNotificationTypes.map(i => EnumProvider.getNotificationTypeItem(i))}
                                  selectedItem={EnumProvider.getNotificationTypeItem(notificationType)}
                                  onChange={(_, item) => onChangeNotificationTypeAsync(item!)}
                        />
                    )
                }

                <SwitchNullable excludeNull
                                id={"notificationEnabled"}
                                className={Utility.css(ch.mobile && styles.enableSwitch)}
                                readonly={(!isGlobal && (!isGlobalSettingEnabled || !canEditNotificationSetting))}
                                value={(!isGlobal && !isGlobalSettingEnabled) ? false : notificationSetting.enabled}
                                leftLabel={"Off"}
                                rightLabel={"On"}
                                onChange={async (_, checked) => await onChangeNotificationsEnabledAsync(checked!)}
                />
            </div>

            {
                (isGlobal || canEditNotificationSetting) ?
                    (
                        <>
                            <div className={"w-100"}>
                                <span className={"d-block"}>{Localizer.notificationSettingsTextDescription}</span>
                                <span>{notificationSettingTypeDescription()}</span>

                                <hr/>

                            </div>

                            {
                                (notificationSetting) &&
                                (

                                    <Form id="notificationSettingsForm"
                                          onSubmit={() => saveNotificationSettingAsync()}
                                    >

                                        <TwoColumns>
                                            {
                                                (isGlobal && notificationType == NotificationType.WrongServicePrediction && availableReceivers.length > 0) &&
                                                (
                                                    <Dropdown noWrap multiple
                                                              id={"notificationReceivers"}
                                                              minWidth={"15rem"}
                                                              className={"mb-4"}
                                                              align={DropdownAlign.Left}
                                                              label={Localizer.notificationSettingsDropdownReceivers}
                                                              items={availableReceivers || []}
                                                              selectedItems={(notificationSetting as GlobalNotificationSetting).receivers || []}
                                                              onChange={(sender) => onChangeNotificationReceiversAsync(sender)}
                                                    />
                                                )
                                            }

                                            <Dropdown required noFilter
                                                      id={"notificationMediaType"}
                                                      label={Localizer.notificationSettingsDropdownMediaType}
                                                      items={EnumProvider.getNotificationMediaTypeItems()}
                                                      selectedItem={EnumProvider.getNotificationMediaTypeItem(notificationSetting.mediaType)}
                                                      onChange={async (_, item) => await onChangeNotificationMediaTypeAsync(item!)}
                                            />

                                        </TwoColumns>

                                        {
                                            (intervalSettingsVisible()) &&
                                            (
                                                <TwoColumns>

                                                    {
                                                        (notificationIntervalTypes().length > 1) &&
                                                        (
                                                            <Dropdown required noFilter
                                                                      id={"notificationInterval"}
                                                                      label={Localizer.notificationSettingsDropdownInterval}
                                                                      items={notificationIntervalTypes()}
                                                                      selectedItem={EnumProvider.getNotificationIntervalItem(notificationSetting.interval)}
                                                                      onChange={async (_, item) => await onChangeNotificationIntervalAsync(item!)}
                                                            />
                                                        )
                                                    }

                                                    {
                                                        (notificationSetting.interval != NotificationInterval.OnDemand) &&
                                                        (
                                                            <DateInput showTime showOnlyTime rentaStyle
                                                                       id={"notificationSendTime"}
                                                                       className={styles.timePicker}
                                                                       label={Localizer.notificationSettingsDateInputSendTime}
                                                                       value={sendTime}
                                                                       onChange={async (date) => await onChangeNotificationSendTimeAsync(date)}
                                                            />
                                                        )
                                                    }

                                                </TwoColumns>
                                            )
                                        }

                                        <TwoColumns>

                                            {
                                                (notificationSetting.interval == NotificationInterval.Weekly) &&
                                                (
                                                    <Dropdown required multiple noFilter
                                                              id={"notificationWeeklySendOccurrence"}
                                                              label={Localizer.notificationSettingsDropdownWeeklySendOccurrence}
                                                              orderBy={DropdownOrderBy.None}
                                                              filterMinLength={10}
                                                              disabled={notificationSetting.interval !== NotificationInterval.Weekly}
                                                              items={EnumProvider.getDayOfWeekItems()}
                                                              selectedItems={notificationSetting.weeklySendOccurrence}
                                                              onChange={async (sender) => await onChangeNotificationWeeklyOccurrenceAsync(sender)}
                                                    />
                                                )
                                            }

                                        </TwoColumns>

                                        {
                                            (!isGlobal) &&
                                            (
                                                <FourColumns>

                                                    <DateInput rentaStyle
                                                               id={'notificationPausedFrom'}
                                                               className={styles.timePicker}
                                                               label={Localizer.notificationSettingsDateInputPausedFrom}
                                                               maxDate={(notificationSetting as UserNotificationSetting).pausedUntil}
                                                               value={(notificationSetting as UserNotificationSetting).pausedFrom}
                                                               onChange={async (date) => await onChangeNotificationPausedFromAsync(date)}
                                                    />

                                                    <DateInput rentaStyle
                                                               id={"notificationPausedUntil"}
                                                               className={styles.timePicker}
                                                               label={Localizer.notificationSettingsDateInputPausedTo}
                                                               minDate={(notificationSetting as UserNotificationSetting).pausedFrom}
                                                               value={(notificationSetting as UserNotificationSetting).pausedUntil}
                                                               onChange={async (date) => await onChangeNotificationPausedToAsync(date)}
                                                    />

                                                </FourColumns>
                                            )
                                        }

                                        <ButtonContainer className={"mt-3"}>

                                            {
                                                (isGlobal) &&
                                                (

                                                    <Button id={"back"}
                                                            type={ButtonType.Blue}
                                                            route={PageDefinitions.adminRoute}
                                                            label={Localizer.genericBack}
                                                    />
                                                )
                                            }

                                            <Button submit
                                                    id={"save"}
                                                    type={ButtonType.Orange}
                                                    disabled={!canEditNotificationSetting && !isGlobal}
                                                    label={Localizer.genericSave}
                                                    icon={{name: "far save"}}
                                            />
                                        </ButtonContainer>

                                    </Form>
                                )
                            }

                        </>
                    ) :
                    <>
                        {
                            (!isGlobal) &&
                            (
                                <div>
                                    <Alert model={forbiddenForEditingAlert()}/>
                                </div>
                            )
                        }
                    </>
            }
        </div>
    );
}

export default NotificationSettings;