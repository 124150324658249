import Localizer from "@/localization/Localizer";

export enum AdvanceCheckStatus{
    Yes = 0,
    No = 1,
    Skip = 2
}

export interface IAdvanceCheckInfo
{
    name : string;
    status : AdvanceCheckStatus;
    comment? : string;
    
}

class AdvanceCheckPage {
  public elements = {
    advanceCheck: () => cy.get(`[data-cy=advanced_check]`, { timeout: 10000 }),
    title: () => cy.get("[data-cy=title]"),
    description: () => cy.get("[id=description]"),
    errorDescription: () => cy.get("[data-cy=error_description]"),
  };

  public completeChecks(checks: IAdvanceCheckInfo[]) {
    this.elements.advanceCheck().each((element, index) => {
      let selector = "";

      const check = checks[index];

      if(check) {

        switch (check.status) {
          case AdvanceCheckStatus.Yes: {
            selector = "[id=yes]";
          }
            break;
          case AdvanceCheckStatus.No: {
            selector = "[id=no]";
          }
            break;
          case AdvanceCheckStatus.Skip: {
            selector = "[id=skip]";
          }
            break;
        }

        cy.wrap(element).within(() => {
          this.elements.title().should("contain.text", check.name);

          cy.get(selector).should("be.visible").click();

          cy.wait(100);

          if (check.status === AdvanceCheckStatus.No && check.comment) {
            this.elements
                .description()
                .should("be.visible")
                .type(check.comment);
          }
        });
      }
    });
  }

  public validateErrorMessage() {
    this.elements
      .errorDescription()
      .should("be.visible")
      .should(
        "contain.text",
        Localizer.advancedChecksPageCommentValidationText,
      );
  }
  
  public validateErrors(errorIndexes : number[]) {
    this.elements.advanceCheck().each((element, index) => {
      
      cy.wrap(element).should('satisfy', ($el) => {
        if(errorIndexes.includes(index)) {
          const classList = Array.from($el[0].classList).join("");
          return classList.includes('error');
        }
        return true;
      });
    });
  }
}
export default new AdvanceCheckPage();