import BaseListDevicesRequest from "./BaseListDevicesRequest";
import DeviceInfo from "../DeviceInfo";
import { DeviceStatus } from "@/models/Enums";

export default class ListServicedDevicesRequest extends BaseListDevicesRequest {
    
    public deviceStatuses: DeviceStatus[] = [];

    public pageNumber : number = 0;

    public pageSize : number = 0;

    public getDate(device: DeviceInfo): Date | null {
        return device.lastServiceDate || device.lastRepairDate;
    }

    public isEmpty(): boolean {
        return (this.deviceStatuses.length == 0) && (super.isEmpty());
    }

    public clear(): void {
        this.deviceStatuses = [];
        super.clear();
    }
    
    public nextPage() : void{
        this.pageNumber++;
    }
}