import React from "react";
import {PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ActionsPanel from "@/pages/ServiceActionsPage/ActionsPanel/ActionsPanel";
import Localizer from "@/localization/Localizer";

import styles from "./ServiceActionsPage.module.scss";

interface IServiceActionsPageProps {
}

interface IServiceActionsPageState {
}

export default class ServiceActionsPage extends AuthorizedPage<IServiceActionsPageProps, IServiceActionsPageState> {

    public getTitle(): string {
        return Localizer.serviceActionsAndTypesPageTitle;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.serviceActions}>

                <PageHeader title={Localizer.serviceActionsAndTypesPageTitle}
                            subtitle={Localizer.serviceActionsAndTypesPageSubtitle}
                />

                <PageRow>

                    <div className="col">

                        <ActionsPanel/>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}