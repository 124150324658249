import Power, {External, Internal} from "@/pages/Models/Power";
import Connectivity, {GPS, GSM, Transmissions} from "@/pages/Models/Connectivity";
import IO, {Input} from "@/pages/Models/IO";
import {CumulativeOperatingHours} from "@/pages/Models/CumulativeOperatingHours";

export default class TrackUnitDeviceTelemetry {
    public power: Power = new Power();

    public connectivity: Connectivity = new Connectivity();

    public io: IO = new IO();

    public cumulativeOperatingHours: CumulativeOperatingHours | null = null;

    public telematicsDeviceSerial: string = "";

    public telematicsDeviceType: string = "";

    public static flat(data: TrackUnitDeviceTelemetry): TrackUnitDeviceTelemetryFlat {
        return new TrackUnitDeviceTelemetryFlat(data);
    }
}

export class TrackUnitDeviceTelemetryFlat {
    public telematicsDeviceSerial: string;
    public telematicsDeviceType: string;
    public external: External;
    public internal: Internal;
    public satellites: number;
    public gpsSignalQuality: number;
    public latestFixTime: Date;
    public gsmSignalQuality: number = 0;
    public networkTechnology: string = "";
    public transmissions: Transmissions;
    public input1: Input;
    public input2: Input;
    public cumulativeOperatingHours: CumulativeOperatingHours | null;

    constructor(data: TrackUnitDeviceTelemetry) {
        this.telematicsDeviceSerial = data?.telematicsDeviceSerial ?? "";
        this.telematicsDeviceType = data?.telematicsDeviceType ?? "";
        this.external = data.power.external;
        this.internal = data.power.internal;
        this.satellites = data.connectivity.gps.satellites;
        this.gpsSignalQuality = data.connectivity.gps.signalQuality;
        this.latestFixTime = data.connectivity.gps.latestFixTime;
        this.gsmSignalQuality = data.connectivity.gsm.signalQuality;
        this.networkTechnology = data.connectivity.gsm.networkTechnology;
        this.transmissions = data.connectivity.transmissions
        this.input1 = data.io.input1;
        this.input2 = data.io.input2;
        this.cumulativeOperatingHours = data.cumulativeOperatingHours;
    }
}
