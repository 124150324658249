import NumbersHelper from "@/helpers/NumbersHelper";
import GridHelper from "@/cypressHelpers/GridHelper";
import {executeWithIntercept} from "@/helpers/CypressHelper";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import ApplicationSettingsPage from "@/tests/pages/ApplicationSettingsPage";
import InvoicesPage from "@/tests/pages/InvoicesPage";
import InvoiceDetailsPage from "@/tests/pages/InvoiceDetailsPage";
import DevicePage from "@/tests/pages/DevicePage";
import Localizer from "@/localization/Localizer";

export {}

let incrementor: number;

describe('InvoiceDetails page tests', () => {

    before(() => {
    })

    beforeEach(() => {
        cy.login();
        cy.visit('Dashboard');

        incrementor = NumbersHelper.getRandomInteger(1, 99);
    })

    describe('Page works', () => {
        it('Data shown on the page is correct', () => {
            cy.goToDevicePage('90011');
            DevicePage.elements.fuelingAndWashingBtn().click();

            CheckDeviceFuelAndCleanTableContent();
            CheckInputs();
        });

        it('The fueling changes correctly based on the application settings specified value', () => {
            cy.goToApplicationSettingsPage();

            GridHelper.getGridCell('#table_numberValueSettings', Localizer.knownSettingsManualInvoiceFuelStep, 2)
                .trigger('click')
                .type(incrementor + "{enter}");

            ApplicationSettingsPage.elements.saveSettings().click();

            cy.should('be.visible', Localizer.adminSettingsSaved);

            cy.goToDevicePage('90011');
            DevicePage.elements.fuelingAndWashingBtn().click();

            CheckDeviceFuelAndCleanTableContent();
            CheckInputs(true);
        });
    });

    describe('Invoice approval', () => {
        it('Approve invoice works', () => {
            runInvoiceApprovalTest(true);
            CheckInvoiceDetailsContent(true);
        });

        it('Decline invoice works', () => {
            runInvoiceApprovalTest(false);
            CheckInvoiceDetailsContent(false, true);
        });
    });
})

function runInvoiceApprovalTest(isApprove: boolean): void {
    const deviceExternalId: string = (isApprove)
        ? "922599"
        : "922600";

    const filterType: string = (isApprove)
        ? Localizer.enumInvoiceStatusFilterApproved
        : Localizer.enumInvoiceStatusFilterDeclined;

    cy.goToInvoicesPage();

    InvoicesPage.elements.invoiceTab().click();

    executeWithIntercept(() => {
        InvoicesPage.elements.listItem(deviceExternalId).within(() => {
            cy.contains(deviceExternalId);
            cy.contains("JLG 1230ES");
            cy.contains("Customer 2");
            cy.contains("site name 2");
            cy.contains("3487384");
            cy.get('i').should('have.class', 'fa-hourglass-start');
        }).click();
    }, [ReturnInspectionPage.routes.getFuelTypes, ReturnInspectionPage.routes.getWashingTypes, ReturnInspectionPage.routes.getAdBlues, ReturnInspectionPage.routes.getAdditionalExpenses]);

    InvoiceDetailsPage.elements.header().within(() => {
        cy.contains("MEGA LIFT");
        cy.contains("JLG 1230ES");
        cy.contains(deviceExternalId);
    });

    CheckInvoiceDetailsContent();

    InvoiceDetailsPage.elements.statusInfo().should('contain', Localizer.invoiceDetailsPageInvoiceStatusPending);

    if (isApprove) {
        InvoiceDetailsPage.elements.approve().trigger('click');
    } else {
        InvoiceDetailsPage.elements.decline().trigger('click');
    }

    InvoiceDetailsPage.elements.confirmationDialog().within(() => {
        if (!isApprove) {
            cy.get('textarea')
                .type("Decline because I can!");
        }
    }).find('[data-dismiss]').eq(0).trigger('click');

    cy.goToInvoicesPage();

    InvoicesPage.elements.filterBtn().click();
    InvoicesPage.elements.filterModal().first().within(() => {
        cy.get('[id^=invoices_filter_deviceExternalId_]')
            .click()
            .type(deviceExternalId);

        cy.get('[id^=invoices_filter_status_]').selectDropdownValue(filterType);
        cy.get('[id^=invoices_filter_status_]').validateDropdownValueIsSelected(filterType);

        cy.get('button[type=submit]').click();
    });

    InvoicesPage.elements.listItem(deviceExternalId).within(() => {
        cy.contains(deviceExternalId);
        cy.contains("JLG 1230ES");
        cy.contains("Customer 2");
        cy.contains("site name 2");
        cy.contains("3487384");

        if (isApprove) {
            cy.get('i').should('have.class', 'fa-thumbs-up');
        } else {
            cy.get('i').should('have.class', 'fa-thumbs-down');
        }
    }).click();
}

function CheckInvoiceDetailsContent(isApproved: boolean = false, isDeclined: boolean = false): void {

    cy.get('table').find('tr').as('rows');

    const rowHeaders: string[] = [
        Localizer.invoiceDetailsPageCreatedAt,
        Localizer.invoiceDetailsPageInspectedAt,
        Localizer.invoiceDetailsPageInspectedBy,
        Localizer.invoiceDetailsPageDepo,
        Localizer.invoiceDetailsPageInspectionResult,
        Localizer.invoiceDetailsPageCustomerName,
        Localizer.invoiceDetailsPageConstructionSite,
        Localizer.invoiceDetailsPageContractId,
        Localizer.invoiceDetailsPageConstructionSiteNumber,
    ];

    if (isApproved) {
        rowHeaders.push(Localizer.invoiceDetailsPageApprovedAt, Localizer.invoiceDetailsPageApprovedBy);
    }

    if (isDeclined) {
        rowHeaders.push(Localizer.invoiceDetailsPageDeclinedAt, Localizer.invoiceDetailsPageDeclinedBy);
    }

    for (let i = 0; i < rowHeaders.length; i++) {
        InvoiceDetailsPage.elements.rows()
            .eq(i)
            .find('td').first()
            .should('contain', rowHeaders[i]);

        InvoiceDetailsPage.elements.rows()
            .eq(i)
            .find('td').last()
            .should('not.be.empty');
    }

    if (isApproved || isDeclined) {
        InvoiceDetailsPage.elements.fuelTypesDropdown().should('have.class', 'athenaeum-dropdown-disabled');
        InvoiceDetailsPage.elements.washingTypesDropdown().should('have.class', 'athenaeum-dropdown-disabled');
        InvoiceDetailsPage.elements.approve().should('be.disabled');
        InvoiceDetailsPage.elements.decline().should('be.disabled');
    }

    if (isApproved) {
        InvoiceDetailsPage.elements.additionalExpense(0)
            .should('be.visible')
            .find('.athenaeum-checkbox-checkbox')
            .should('have.class', 'athenaeum-checkbox-checked')
            .and('have.class', 'athenaeum-checkbox-readonly');
    }
    else if (isDeclined) {
        InvoiceDetailsPage.elements.additionalExpense(0)
            .should('be.visible')
            .find('.athenaeum-checkbox-checkbox')
            .should('have.class', 'athenaeum-checkbox-readonly');
    } else {
        InvoiceDetailsPage.elements.additionalExpense(0)
            .should('be.visible')
            .find('.athenaeum-checkbox-checkbox')
            .dblclick();
    }
}

function CheckDeviceFuelAndCleanTableContent(): void {

    cy.get('table').find('tr').as('rows');

    cy.get('@rows').eq(1)
        .find('td').last()
        .should('contain', "Customer 1");

    cy.get('@rows').eq(2)
        .find('td').last()
        .should('contain', "site name 1");

    cy.get('@rows').eq(3)
        .find('td').last()
        .should('contain', "2287873");

    cy.get('@rows').last()
        .find('td').last()
        .should('contain', "2");
}

/**
 * @param checkSettingsBasedIncrement Check fueling increments based on the application settings specified value.
 */
function CheckInputs(checkSettingsBasedIncrement: boolean = false): void {
    cy.get('#deviceContractId');

    if (!checkSettingsBasedIncrement) {
        cy.get('#setFueling').find('.fa-plus-circle').click();
    } else {
        cy.get('#setFueling').find('.fa-plus-circle').click();
        cy.get('#setFueling').within(() => {
            cy.get('#number-widget-body_setFueling')
                .find('span')
                .contains(incrementor);
        })

        cy.get('#setFueling').find('.fa-plus-circle').click();
        cy.get('#setFueling').within(() => {
            cy.get('#number-widget-body_setFueling')
                .find('span')
                .contains(incrementor * 2);
        })
    }

    cy.get('#invoiceOtherResourcesCheckbox').find('label').click();

    cy.get('#setAdBlue').find('span').eq(1).trigger('click');
    cy.get('#setAdBlue').find('input').type("1");
    cy.get('#setAdBlue').find('.fa-minus-circle').click();

    cy.get('#setWashing').find('.fa-plus-circle').click();
}