import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {Button, ButtonType, Checkbox, Dropdown, DropdownOrderBy, DropdownRequiredType, Form, IconSize, InlineType, NumberWidget, SelectListItem, TextInput} from "@renta-apps/athenaeum-react-components";
import AdditionalExpenseValue from "@/models/server/AdditionalExpenseValue";
import AdditionalExpense from "@/models/server/AdditionalExpense";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import UserContext from "@/models/server/UserContext";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "@/components/AdditionalExpensesPanel/AdditionalExpensesPanel.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

interface IAdditionalExpensesPanelProps {
    additionalExpenseTypes: AdditionalExpense[];
    additionalExpenseValues: AdditionalExpenseValue[];
    additionalExpensesCanBeAdded?: boolean;
    returnInspectionStepNumber?: number;
    readonly?: boolean;
    onChange?(): Promise<void>;
}

interface IAdditionalExpensesPanelState {
}

export default class AdditionalExpensesPanel extends BaseComponent<IAdditionalExpensesPanelProps, IAdditionalExpensesPanelState> {

    state: IAdditionalExpensesPanelState = {
        additionalExpenses: [],
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private readonly _additionalExpenseNameRef: React.RefObject<TextInput> = React.createRef();
    private readonly _additionalExpenseTypesRef: React.RefObject<Dropdown<AdditionalExpense>> = React.createRef();

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private get readonly(): boolean {
        return this.props.readonly ?? false;
    }

    private get additionalExpensesCanBeAdded(): boolean {
        return this.props.additionalExpensesCanBeAdded ?? false;
    }

    private get additionalExpenseTypes(): AdditionalExpense[] {
        return this.props.additionalExpenseTypes;
    }

    private get additionalExpenseValues(): AdditionalExpenseValue[] {
        return this.props.additionalExpenseValues;
    }

    private get returnInspectionStepNumber(): number | null {
        return this.props.returnInspectionStepNumber ?? null;
    }

    private get hasAdditionalExpenseValues(): boolean {
        return (this.additionalExpenseValues != null && this.additionalExpenseValues.length > 0);
    }

    private get canAddAdditionalExpenseValue(): boolean {
        let canAdd: boolean = (this.additionalExpensesCanBeAdded &&
            (
                (!this.hasAdditionalExpenseValues) ||
                (
                    this.additionalExpenseValues
                        .every(item =>
                            this.additionalExpenseValid(item) &&
                            !this.hasDuplicatedNames(item.name))
                )
            )
        );

        const customApplicable: boolean = this.additionalExpenseTypes.some(item => item.isGeneric);

        if (!customApplicable) {
            canAdd = canAdd && this.additionalExpenseValues.length < this.additionalExpenseTypes.length;
        }

        return canAdd;
    }

    private get userHasRightsToInvoiceAdditionalExpenses(): boolean {
        if (ch.isNorway) {
            return false;
        }

        return (ReportDefinitionItem.userHasRightsToInvoiceAdditionalExpenses(this.userContext));
    }

    private getNameValidationError(expenseName: string): string | null {
        if (expenseName.trim().length == 0) {
            return Localizer.additionalExpensesPanelNameValidationErrorNameEmpty;
        } else if (this.hasDuplicatedNames(expenseName)) {
            return Localizer.additionalExpensesPanelNameValidationErrorDuplicateExpenseExist;
        }

        return null;
    }

    private hasDuplicatedNames(addedExpenseName: string): boolean {
        const duplicates: AdditionalExpenseValue[] = this
            .additionalExpenseValues
            .where(expense => expense.name.toLowerCase().trim() == addedExpenseName.toLowerCase().trim());

        return duplicates.length > 1;
    }

    private additionalExpenseValid(addedExpense: AdditionalExpenseValue): boolean {
        return AdditionalExpenseValue.isValid(addedExpense);
    }

    private getAvailableAdditionalExpenseTypes(selectedTypeId: string | null): AdditionalExpense[] {
        let options: AdditionalExpense[] = this.additionalExpenseTypes;

        const selectedExpensesIds: (string | null)[] = this
            .additionalExpenseValues
            .map(item => item.typeId);

        return options
            .where(
                item => (item.isGeneric) ||
                    (selectedTypeId == item.id) ||
                    (!selectedExpensesIds.includes(item.id))
            );
    }

    private getFormat(step: number): string {
        return (step % 1 == 0)
            ? "0"
            : "0.00";
    }

    private getStep(expenseType: AdditionalExpense | null, expenseValue: AdditionalExpenseValue): number {
        return (expenseType != null && !expenseType.isGeneric)
            ? expenseType.step
            : AdditionalExpense.getSuggestedStep(expenseValue.unit);
    }

    private async updateAdditionalExpenseValues(): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange();
        }
    }

    private async autoUpdateInvoiced(expenseValue: AdditionalExpenseValue, expenseType: AdditionalExpense | null): Promise<void> {
        const valid: boolean = this.additionalExpenseValid(expenseValue) && !this.hasDuplicatedNames(expenseValue.name);

        if (expenseType?.isInvoiceable) {
            if (!this.userHasRightsToInvoiceAdditionalExpenses) {
                expenseValue.invoiced = false;
            } else if (!ch.isNorway && valid && expenseValue.invoiced == null) {
                expenseValue.invoiced = true;
            } else if (!!expenseValue.invoiced && !valid) {
                expenseValue.invoiced = false;
            }
        }
    }

    private async removeAdditionalExpenseItemAsync(expenseValue: AdditionalExpenseValue): Promise<void> {
        this.additionalExpenseValues.remove(expenseValue);

        await this.updateAdditionalExpenseValues();
    }

    private async onAddNewExpenseAsync(): Promise<void> {
        const newExpenseValue: AdditionalExpenseValue = new AdditionalExpenseValue();
        newExpenseValue.returnInspectionStepNumber = this.returnInspectionStepNumber;

        this.additionalExpenseValues.push(newExpenseValue);

        await this.updateAdditionalExpenseValues();
    }

    private async onChangeAdditionalExpenseAsync(selectedExpenseTypeItem: AdditionalExpense, expenseValue: AdditionalExpenseValue, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            const additionalExpenseType: AdditionalExpense = selectedExpenseTypeItem;
            const isCustom: boolean = selectedExpenseTypeItem.isGeneric;

            expenseValue.isCustom = isCustom;
            expenseValue.typeId = additionalExpenseType.id;
            expenseValue.name = (!selectedExpenseTypeItem.isGeneric)
                ? additionalExpenseType.name
                : "";
            expenseValue.externalId = additionalExpenseType.productExternalId;
            expenseValue.value = additionalExpenseType.defaultValue || additionalExpenseType.min;
            expenseValue.unit = additionalExpenseType.unit;
            expenseValue.price = (isCustom) ? 0 : null;

            await this.autoUpdateInvoiced(expenseValue, selectedExpenseTypeItem);
            await this.updateAdditionalExpenseValues();
        }
    }

    private async onChangeAdditionalExpenseAmountAsync(value: number, expenseValue: AdditionalExpenseValue, expenseType: AdditionalExpense | null): Promise<void> {
        expenseValue.value = value;

        await this.autoUpdateInvoiced(expenseValue, expenseType);
        await this.updateAdditionalExpenseValues();
    }

    private async onInvoiceAdditionalExpenseAsync(value: boolean, expenseValue: AdditionalExpenseValue): Promise<void> {
        expenseValue.invoiced = value;

        await this.updateAdditionalExpenseValues();
    }

    private async onChangeCustomAdditionalExpenseUnitAsync(selectedItem: SelectListItem, expenseValue: AdditionalExpenseValue): Promise<void> {
        expenseValue.unit = Number(selectedItem.value);

        const needRound: boolean = (this.getFormat(AdditionalExpense.getSuggestedStep(expenseValue.unit)) == "0");

        if (needRound) {
            expenseValue.value = Math.round(expenseValue.value);
        }

        await this.updateAdditionalExpenseValues();
    }

    private async onChangeCustomAdditionalExpenseNameAsync(sender: TextInput, value: string, expenseValue: AdditionalExpenseValue, expenseType: AdditionalExpense | null): Promise<void> {
        expenseValue.name = value;

        await sender!.validateAsync();

        await this.autoUpdateInvoiced(expenseValue, expenseType);
        await this.updateAdditionalExpenseValues();
    }

    private async onChangeCustomAdditionalExpensePriceAsync(value: number, expenseValue: AdditionalExpenseValue): Promise<void> {
        expenseValue.price = value;

        await this.updateAdditionalExpenseValues();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (this.hasAdditionalExpenseValues) {
            if (this._formRef.current) {
                await this._formRef.current.validateAsync();
            }

            if (this._additionalExpenseNameRef.current) {
                await this._additionalExpenseNameRef.current!.validateAsync();
            }

            if (this._additionalExpenseTypesRef) {
                await this._additionalExpenseTypesRef.current!.validateAsync();
            }
        }
    }

    public renderAdditionalExpense(expenseValue: AdditionalExpenseValue, index: number): React.ReactNode {
        const selectedTypeId: string = expenseValue.typeId;
        const expenseType: AdditionalExpense | null = this.additionalExpenseTypes
            .firstOrDefault(item => (item.id == expenseValue.typeId));
        const step: number = this.getStep(expenseType, expenseValue);

        return (
            <Form id={`additionalExpenseForm_${index}`}
                  key={index}
                  ref={this._formRef}
                  readonly={this.readonly}
                  className={this.css(
                      styles.additionalExpenseItem,
                      (!this.userHasRightsToInvoiceAdditionalExpenses && expenseType?.isInvoiceable) && styles.bottomSpace,
                      (this.desktop) && styles.desktop,
                      (!this.additionalExpenseValid(expenseValue) || this.hasDuplicatedNames(expenseValue.name)) && styles.invalid
                  )}
            >

                {
                    (!this.readonly) &&
                    (
                        <div className={this.css("d-flex", "justify-content-between")}>
                            <span className={styles.itemIndex}>#{index + 1}</span>

                            <Button id={`removeAdditionalExpenses_${index}`}
                                    className={styles.removeButton}
                                    title={Localizer.genericRemove}
                                    icon={{name: "trash", size: IconSize.Large}}
                                    type={ButtonType.Blue}
                                    disabled={this.readonly}
                                    onClick={() => this.removeAdditionalExpenseItemAsync(expenseValue)}
                            />
                        </div>
                    )
                }

                <Dropdown required
                          requiredType={DropdownRequiredType.Restricted}
                          id={`additionalExpenses_${index}`}
                          ref={this._additionalExpenseTypesRef}
                          className={this.css(rentaToolsStyles.arsenalDropdown, (this.readonly) && "pt-2")}
                          label={Localizer.additionalExpensesPanelDropdownAdditionalExpenses}
                          disabled={this.readonly}
                          orderBy={DropdownOrderBy.None}
                          items={this.getAvailableAdditionalExpenseTypes(selectedTypeId)}
                          selectedItem={selectedTypeId}
                          onChange={(_, item, userInteraction) => this.onChangeAdditionalExpenseAsync(item!, expenseValue, userInteraction)}
                />

                <NumberWidget wide
                              className={this.css(rentaToolsStyles.arsenalNumberWidget, (!expenseType?.isInvoiceable && !expenseValue.isCustom) && styles.bottomIndent)}
                              id={`additionalExpenseAmount_${index}`}
                              readonly={this.readonly}
                              minimized={!expenseValue.typeId || expenseValue.typeId == RentaToolsConstants.defaultGuid}
                              label={Localizer.additionalExpensesPanelNumberWidgetAmount}
                              min={expenseType?.min}
                              max={expenseType?.max}
                              format={this.getFormat(expenseValue.value)}
                              step={step}
                              value={expenseValue.value ?? expenseType?.defaultValue ?? expenseType?.min}
                              onChange={(_, value) => this.onChangeAdditionalExpenseAmountAsync(value, expenseValue, expenseType)}
                />

                {
                    (expenseValue.isCustom) &&
                    (
                        <>
                            <TextInput required
                                       id={`additionalExpenseName_${index}`}
                                       key={index}
                                       ref={this._additionalExpenseNameRef}
                                       className={this.css(styles.textInput, (this.desktop) && styles.desktop)}
                                       readonly={this.readonly}
                                       label={Localizer.additionalExpensesPanelTextInputProductName}
                                       value={expenseValue.name}
                                       validators={[() => this.getNameValidationError(expenseValue.name)]}
                                       onChange={(sender, value) => this.onChangeCustomAdditionalExpenseNameAsync(sender, value, expenseValue, expenseType)}
                            />
                            <Dropdown required
                                      requiredType={DropdownRequiredType.Restricted}
                                      id={`additionalExpenseUnit_${index}`}
                                      className={this.css(rentaToolsStyles.arsenalDropdown)}
                                      label={Localizer.additionalExpensesPanelDropdownUnit}
                                      disabled={this.readonly}
                                      orderBy={DropdownOrderBy.None}
                                      items={EnumProvider.getExpenseUnitItems()}
                                      selectedItem={expenseValue.unit}
                                      onChange={(_, item) => this.onChangeCustomAdditionalExpenseUnitAsync(item!, expenseValue)}
                            />

                            <NumberWidget wide
                                          className={this.css(rentaToolsStyles.arsenalNumberWidget, (!expenseType?.isInvoiceable) && styles.bottomIndent)}
                                          id={`additionalExpensePrice_${index}`}
                                          readonly={this.readonly}
                                          label={Localizer.additionalExpensesPanelNumberWidgetPrice}
                                          format={"C"}
                                          step={0.1}
                                          value={expenseValue.price ?? 0}
                                          onChange={(_, value) => this.onChangeCustomAdditionalExpensePriceAsync(value, expenseValue)}
                            />
                        </>
                    )
                }

                {
                    ((this.userHasRightsToInvoiceAdditionalExpenses) && (expenseType?.isInvoiceable)) &&
                    (
                        <div className={rentaToolsStyles.arsenalCheckboxes}>
                            <Checkbox inline
                                      id={`additionalExpenseInvoiced_${index}`}
                                      readonly={(this.readonly) || (!this.additionalExpenseValid(expenseValue))}
                                      label={Localizer.additionalExpensesPanelCheckboxInvoice}
                                      inlineType={InlineType.Right}
                                      value={expenseValue.invoiced}
                                      onChange={(sender, value) => this.onInvoiceAdditionalExpenseAsync(value, expenseValue)}
                            />
                        </div>
                    )
                }

            </Form>
        )
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.additionalExpensePanel}>
                {
                    (
                        (this.hasAdditionalExpenseValues) &&
                        (
                            <div id={"additionalExpensesContainer"} className={this.css(styles.additionalExpenseItemsContainer, (this.desktop) && styles.desktop)}>
                                {this.additionalExpenseValues.map((item, index) => this.renderAdditionalExpense(item, index))}
                            </div>
                        )
                    )
                }

                {
                    (this.additionalExpensesCanBeAdded && !this.readonly) &&
                    (
                        <div className={this.css(this.desktop && styles.addAdditionalExpenseButtonContainer)}>
                            <ArsenalButton fullWidth big
                                           id={"ri_add_additional_expense_button"}
                                           className={styles.addAdditionalExpenseButton}
                                           disabled={!this.canAddAdditionalExpenseValue}
                                           label={Localizer.additionalExpensesPanelButtonAdd}
                                           type={ButtonType.Orange}
                                           onClick={() => this.onAddNewExpenseAsync()}
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}