import EndpointPaths from "@/common/EndpointPaths";
import {GetDevicesInfoToOrderRequest} from "@/models/server/requests/GetDevicesInfoToOrderRequest";
import {GetDevicesToOrderResponse} from "@/models/server/responses/GetDevicesToOrderResponse";
import HttpClient from "@/common/HttpClient";

class DeviceService {
    public async getDeviceShortInfo(request: GetDevicesInfoToOrderRequest): Promise<GetDevicesToOrderResponse> {
        return await HttpClient.postAsync<GetDevicesToOrderResponse>(EndpointPaths.DevicePaths.GetDevicesInfoToOrder, request);
    }
}

export default new DeviceService();