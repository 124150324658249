class AccountPage {

    public elements = {
        tabContainer: () => cy.get('#accountTabs'),
        tabs:{
            tabContainer: {
                personalTab: () => cy.get('#tab_personal'),
                notificationsSettingsTab: () => cy.get('#tab_userNotificationsSettings'),
            },
        },
    };

    public routes = {};
}

export default new AccountPage();
