import React from "react";
import {ButtonType, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import Device from "@/pages/Models/Device";
import PageDefinitions from "@/providers/PageDefinitions";
import AnnualInspectionsReportsList from "@/pages/DeviceAnnualInspectionHistoryPage/AnnualInspectionReportsList/AnnualInspectionReportsList";
import UserContext from "@/models/server/UserContext";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import styles from "@/pages/DeviceAnnualInspectionHistoryPage/DeviceAnnualInspectionHistoryPage.module.scss";

interface IDeviceAnnualInspectionHistoryPageProps {
}

interface IDeviceAnnualInspectionHistoryPageState {
    editMode: boolean;
}

export default class DeviceAnnualInspectionHistoryPage extends AuthorizedPage<IDeviceAnnualInspectionHistoryPageProps, IDeviceAnnualInspectionHistoryPageState> {
    state: IDeviceAnnualInspectionHistoryPageState = {
        editMode: false
    };

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get title(): string {
        return this.device.name;
    }

    private get canModify(): boolean {
        const context: UserContext = this.getContext();
        return UserContext.canUserModifyAnnualInspectionRecords(context);
    }

    private get buttonLabel(): string {
        return (this.state.editMode) ? Localizer.genericCancel : Localizer.annualInspectionHistoryPageEditAnnualInspectionRecords;
    }

    private async toggleEditModeAsync(): Promise<void> {
        this.setState({editMode: !this.state.editMode})
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (RentaToolsController.device == null) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
        }
    }

    private renderTitle(): React.ReactNode {
        const deviceProductGroupName = this.device.productGroupName;
        const deviceType = this.device.type;
        const deviceExternalId = this.device.externalId;
        const deviceGroupId = this.device.productGroupId;

        return (
            <React.Fragment>

                <div>
                    <div id={"multiTitleDiv"} className={styles.multiTitle}>
                        <span id={"deviceProductGroup"}
                              className={styles.deviceProductGroup}>{deviceProductGroupName} {deviceGroupId}</span>
                        <span id={"deviceType"} className={styles.deviceType}>{deviceType}</span>
                        <span id={"device_externalId"}>{deviceExternalId}</span>
                    </div>
                </div>

            </React.Fragment>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer id={"pageContainer"}
                           alertClassName={rentaToolsStyles.alert}
                           className={this.css(rentaToolsStyles.pageContainer)}>

                <PageHeader title={() => this.renderTitle()}
                            subtitle={Localizer.deviceAnnualInspectionPageButtonPreviousInspections}
                            className={rentaToolsStyles.leftPageHeader}
                />

                <ArsenalPageRow>

                    <AnnualInspectionsReportsList device={this.device}
                                                  editMode={this.state.editMode}
                                                  onSaveEdit={async () => this.setState({editMode: false})}
                    />

                </ArsenalPageRow>

                <ArsenalPageRow>

                    {
                        (this.canModify && !this.isSweden) &&
                        (
                            <ArsenalButton block big
                                           id={"toggleEditModeButton"}
                                           type={ButtonType.Light}
                                           label={this.buttonLabel}
                                           onClick={async () => this.toggleEditModeAsync()}
                            />
                        )
                    }

                    <ArsenalButton block
                                   type={ButtonType.Orange}
                                   id={"backButton"}
                                   label={Localizer.devicePageReturnBack}
                                   icon={{name: "arrow-left"}}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </ArsenalPageRow>

            </PageContainer>
        )
    }
}