import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Dropdown, Form, IconSize, JustifyContent, Spinner, TextInput, ToolbarContainer, ToolbarRow} from "@renta-apps/athenaeum-react-components";
import ToolbarModel from "@/models/server/ToolbarModel";
import ReportDefinition from "@/pages/Models/ReportDefinition";
import ServiceReportDefinition from "@/pages/Models/ServiceReportDefinition";
import {ReportDefinitionType} from "@/models/Enums";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "../../../localization/Localizer";

import styles from "./Toolbar.module.scss";

interface IToolbarProps {
    model?: ToolbarModel;

    onChange?(model: ToolbarModel): Promise<void>;
}

interface IToolbarState {
    model: ToolbarModel,
    reportDefinitions: ReportDefinition[],
    serviceDefinitions: ServiceReportDefinition[]

}

export default class Toolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    state: IToolbarState = {
        model: this.props.model || new ToolbarModel(),
        reportDefinitions: [],
        serviceDefinitions: []
    };

    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    private async setSearchTermAsync(customer: string): Promise<void> {
        this.state.model.searchTerm = customer;
        await this.processOnChange();
    }

    private async setReportDefinitionAsync(reportDefinition: ReportDefinition | null): Promise<void> {
        this.state.model.reportDefinitionId = reportDefinition == null ? null : reportDefinition.id;
        await this.processOnChange();
    }

    private async setServiceDefinitionAsync(serviceReportDefinition: ServiceReportDefinition | null): Promise<void> {
        this.state.model.serviceReportDefinitionId = serviceReportDefinition == null ? null : serviceReportDefinition.id;
        await this.processOnChange();
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new ToolbarModel();
        await this.processOnChange(true);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.loadDataAsync();
    }

    private async loadDataAsync(): Promise<void> {

        const allDefinitions: ReportDefinition[] = await RentaToolsController.getReportDefinitionsAsync();
        const allServiceDefinitions: ServiceReportDefinition[] = await RentaToolsController.getServiceReportDefinitionsAsync();

        let reportDefinitions: ReportDefinition[] = allDefinitions.filter(a => a.type == ReportDefinitionType.ReturnInspection);
        let serviceDefinitions: ServiceReportDefinition[] = allServiceDefinitions.filter(a => a.type == ReportDefinitionType.Service);

        await this.setState({reportDefinitions, serviceDefinitions});
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbar}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Form inline onSubmit={async () => await this.processOnChange(true)}>

                        <TextInput id="searchTerm" inline small
                                   placeholder={Localizer.devicesManagementPageToolBarSearchTerm}
                                   value={this.state.model.searchTerm}
                                   width="11rem"
                                   onChange={async (sender, value) => await this.setSearchTermAsync(value)}
                        />

                        <Dropdown inline noValidate
                                  label={Localizer.mappingsPageTabReturnInspections}
                                  minWidth={350}
                                  items={this.state.reportDefinitions}
                                  selectedItem={this.state.model.reportDefinitionId}

                                  onChange={async (sender, value: ReportDefinition | null) => await this.setReportDefinitionAsync(value)}
                        />

                        <Dropdown inline noValidate
                                  label={Localizer.mappingsPageTabServices}
                                  minWidth={350}
                                  items={this.state.serviceDefinitions}
                                  selectedItem={this.state.model.serviceReportDefinitionId}
                                  onChange={async (sender, value: ServiceReportDefinition | null) => await this.setServiceDefinitionAsync(value)}
                        />

                        <Button small submit
                                label={Localizer.devicesManagementPageToolBarSearch}
                                icon={{name: "fas search"}}
                                type={ButtonType.Orange}
                        />

                        <Button small title={Localizer.devicesManagementPageToolBarClear}
                                icon={{name: "far history", size: IconSize.Large}}
                                type={ButtonType.Info}
                                onClick={async () => await this.clearAsync()}
                        />

                    </Form>

                </ToolbarRow>

                {
                    (this.isSpinning()) && <Spinner/>
                }

            </ToolbarContainer>
        );
    }
};