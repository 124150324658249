import AuthorizedPage from "@/models/base/AuthorizedPage";
import RentaToolsStorage, {RentaToolsStorageTable} from "@/pages/RentaToolsStorage";
import httpClient from "@/common/HttpClient";
import EndpointPaths from "@/common/EndpointPaths";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import ListUnInspectedDevicesRequest from "@/models/server/requests/ListUnInspectedDevicesRequest";

interface ILogoutPageProps {}

interface ILogoutPageState {}

export default class AppLogoutPage extends AuthorizedPage<ILogoutPageProps, ILogoutPageState> {
  async initializeAsync(): Promise<void> {
      await super.initializeAsync();
      
      await httpClient.postAsync(EndpointPaths.ApplicationPaths.Logout);

      const persistentData = this.getPersistentData();
      
      sessionStorage.clear();
      localStorage.clear();
      
      this.restorePersistentData(persistentData);

      await RentaToolsStorage.clearDataAsync(RentaToolsStorageTable.Files);
      await RentaToolsStorage.clearDataAsync(RentaToolsStorageTable.DataStorage);
      
      await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute, true, true);
  }

  public render(): React.ReactNode {
    return null;
  }
  
  private getPersistentData(): [string, any][] {
      const persistentData: [string, any][] = [];
      Object.keys(localStorage).forEach((key) => {
          if (key.includes(".persistent.userInteractionDataStorage")) {
              let request = new ListUnInspectedDevicesRequest();
              persistentData.push([key, localStorage.getItem(key)]);
          }
      })
      
      return persistentData;
  }
  
  private restorePersistentData(data: [string, any][]): void {
      data.forEach(item => {
          localStorage.setItem(item[0], item[1])
      })
  }
}