import {ExpenseUnit} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

export default class AddAdditionalExpenseRequest {
    public name: string = "";

    public productExternalId: string = "";

    public unit: ExpenseUnit = ExpenseUnit.Piece;

    public min: number = 0;

    public max: number = RentaToolsConstants.maxResourceValue;

    public defaultValue: number = 0;

    public step: number = 0;

    public isGeneric: boolean = false;

    public isInvoiceable: boolean = false;
}