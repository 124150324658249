import {FileModel} from "@renta-apps/athenaeum-toolkit";
import ReportDefinitionItem from "./ReportDefinitionItem";

export default class PicturesReportItem extends ReportDefinitionItem {
    
    public pictures: FileModel[] | null = null;

    public static hasPicture(item: PicturesReportItem): boolean {
        return (item.pictures != null) && (item.pictures.length > 0) && (item.pictures.some(item => item != null));
    }

    public static isValid(item: PicturesReportItem): boolean {
        return PicturesReportItem.hasPicture(item);
    }
}