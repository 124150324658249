import React from "react";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import {PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";

import Localizer from "../../localization/Localizer";

export interface IOfflinePagePageParameters extends BasePageParameters {
    requestId: string | null;
}

export default class OfflinePage extends AnonymousPage<IOfflinePagePageParameters> {

    public getTitle(): string {
        return Localizer.offlineTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.offlineTitle}/>
                <PageRow>
                    <div className="col">
                        <p>{Localizer.offlineSubtitle}</p>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}