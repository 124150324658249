import React from "react";
import {BaseAsyncComponent, ch, IBaseAsyncComponentState, PageRouteProvider, TextAlign} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    ColumnDefinition,
    ColumnType,
    DateInput,
    Dropdown,
    DropdownOrderBy,
    FileInput,
    Form,
    Grid,
    Icon,
    Modal,
    NumberInput,
    NumberInputBehaviour,
    SelectListGroup,
    SelectListItem,
    TextInput
} from "@renta-apps/athenaeum-react-components";
import Device from "@/pages/Models/Device";
import DeviceAnnualInspection from "@/models/server/DeviceAnnualInspection";
import {FileModel, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import ListDeviceAnnualInspectionsRequest from "@/models/server/requests/ListDeviceAnnualInspectionsRequest";
import {Dictionary} from "typescript-collections";
import EditDeviceAnnualInspectionRequest from "@/models/server/requests/EditDeviceAnnualInspectionRequest";
import UserContext from "@/models/server/UserContext";
import ToolsUtility from "@/helpers/ToolsUtility";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import AnnualInspectionVendor from "@/models/server/AnnualInspectionVendor";
import Depo from "@/models/server/Depo";
import OperatingHoursLimitRequest from "@/models/server/requests/OperatingHoursLimitRequest";
import DeviceInfo from "@/models/server/DeviceInfo";
import {AnnualInspectionType} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import ListAnnualInspectionVendorsRequest from "@/models/server/requests/ListAnnualInspectionVendorsRequest";
import AnnualInspectionTypeModel from "@/models/server/AnnualInspectionTypeModel";
import EndpointPaths from "@/common/EndpointPaths";
import TransformProvider from "@/providers/TransformProvider";
import AnnualInspectionController from "@/pages/AnnualInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import UnleashHelper from "@/helpers/UnleashHelper";
import fileHelper from "@/helpers/FileHelper";
import Localizer from "@/localization/Localizer";

import styles from "./AnnualInspectionReportsList.module.scss";

interface IAnnualInspectionsReportsListProps {
    device: Device,
    editMode: boolean,

    onSaveEdit?(): Promise<void>;
}

interface IAnnualInspectionsReportsListState extends IBaseAsyncComponentState<DeviceAnnualInspection[]> {
    inspectionToEdit: DeviceAnnualInspection | null;
    vendors: AnnualInspectionVendor[];
    depos: Depo[];
    inspectionTypes: AnnualInspectionTypeModel[];
    selectedDepoId: string | null;
    minOperatingHours: number | null;
    maxOperatingHours: number | null,
    selectedVendor: string | null;
    selectedType: AnnualInspectionType | null;
    selectedDate: Date | null,
    isUploadedFileInvalid: boolean
}

export default class AnnualInspectionsReportsList extends BaseAsyncComponent<IAnnualInspectionsReportsListProps, IAnnualInspectionsReportsListState, DeviceAnnualInspection[]> {

    state: IAnnualInspectionsReportsListState = {
        isLoading: false,
        data: null,
        inspectionToEdit: null,
        vendors: [],
        depos: [],
        inspectionTypes: [],
        selectedDepoId: null,
        minOperatingHours: null,
        maxOperatingHours: null,
        selectedType: null,
        selectedVendor: null,
        selectedDate: null,
        isUploadedFileInvalid: false
    };

    private readonly _annualInspectionToEditModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _fileInputRef: React.RefObject<FileInput> = React.createRef();
    private readonly _inspectionDateInputRef: React.RefObject<DateInput> = React.createRef();
    private readonly _inspectionVendorIdInputRef: React.RefObject<Dropdown<SelectListItem>> = React.createRef();
    private readonly _inspectionTypeInputRef: React.RefObject<Dropdown<AnnualInspectionTypeModel>> = React.createRef();

    private readonly _annualInspectionsColumns: ColumnDefinition[] = [
        {
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionDateLanguageItemName,
            accessor: nameof.full<DeviceAnnualInspection>(a => a.inspectionDate),
            type: ColumnType.Date,
            format: (value: Date) => this.formatDate(value),
            editable: false,
            init: (cell) => this.initInspectionCell(cell),
        },
        {
            minWidth: 99,
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionAttachmentLanguageItemName,
            actions: [
                {
                    render: (cell: CellModel<DeviceAnnualInspection>, action: CellAction<DeviceAnnualInspection>) => this.renderIcons(cell, action),
                }
            ]
        },
        {
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionVendorLanguageItemName,
            accessor: nameof.full<DeviceAnnualInspection>(o => o.vendor!.name),
            type: ColumnType.Text,
            init: (cell) => this.initInspectionCell(cell),
        },
        {
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionInvoiceNumberLanguageItemName,
            accessor: nameof.full<DeviceAnnualInspection>(a => a.inspectionInvoiceNumber),
            type: ColumnType.Text,
            init: (cell) => this.initInspectionCell(cell),
        },
        {
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionCostLanguageItemName,
            accessor: nameof.full<DeviceAnnualInspection>(a => a.inspectionCost),
            type: ColumnType.Text,
            init: (cell) => this.initInspectionCell(cell),
        },
        {
            header: Localizer.deviceAnnualInspectionHistoryPageInspectionTypeLanguageItemName,
            accessor: nameof.full<DeviceAnnualInspection>(a => a.inspectionType),
            type: ColumnType.Enum,
            format: "AnnualInspectionType",
            init: (cell) => this.initInspectionCell(cell),
        },
        {
            header: Localizer.annualInspectionsListTitleDeleteLanguageItemName,
            visible: this.canDelete,
            textAlign: TextAlign.Center,
            actions: [
                {
                    render: (cell: CellModel<DeviceAnnualInspection>) => this.renderBinIcon(cell),
                }
            ]
        },
        {
            header: Localizer.annualInspectionsListTitleDepo,
            textAlign: TextAlign.Center,
            accessor: nameof.full<DeviceAnnualInspection>(o => o.depo!.name),
            init: (cell) => this.initInspectionCell(cell),
            type: ColumnType.Text,
        },
        {
            header: Localizer.annualInspectionsListTitleUserName,
            accessor: nameof.full<DeviceAnnualInspection>(o => o.createdBy),
            transform: (cell: CellModel<DeviceAnnualInspection>) => TransformProvider.toString(cell.model.createdBy),
            textAlign: TextAlign.Center,
            type: ColumnType.Text,
            init: (cell) => this.initInspectionCell(cell),
        },
        {
            header: Localizer.deviceServicePageLabelOperatingHoursLanguageItemName,
            accessor: (model: DeviceAnnualInspection) => (model.operatingHours != null) ? model.operatingHours.format("0.00") : "-",
            init: (cell) => this.initInspectionCell(cell),
            type: ColumnType.Number,
            textAlign: TextAlign.Center,
        },
    ];

    private renderBinIcon = (cell: CellModel<DeviceAnnualInspection>) => (
        <div className={styles.binIcon}>
            <Icon name={"fa-trash"}
                  className={"text-primary"}
                  id={'delete-button'}
                  confirm={Localizer.annualInspectionHistoryPageConfirmationDeleteInspection}
                  onClick={async () => await this.deleteAnnualInspection(cell)}
            />
        </div>
    )

    private formatDate(date: Date): string {
        return ToolsUtility.toDateString(date);
    }

    private get inspectionDate(): Date {
        return this.state.selectedDate ?? new Date();
    }

    private get canDelete(): boolean {
        const featureEnabled = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagAdminAiDeleteEnabled);

        const context: UserContext = ch.getContext() as UserContext;
        return context.isAdmin && featureEnabled;
    }

    private canEdit(inspection: DeviceAnnualInspection): boolean {
        const context: UserContext = ch.getContext() as UserContext;

        return UserContext.canEditAnnualInspectionRecord(context)
            || UserContext.canUserModifyAnnualInspectionRecords(context) && (inspection.createdBy?.id === context.user?.id && inspection.remarksCompletedDate === null);
    }

    private get canEditDocument(): boolean {
        const context: UserContext = ch.getContext() as UserContext;

        // Only admins can edit PDF when remarks have been repaired
        return (this.inspectionToEdit ? this.inspectionToEdit.remarksCompletedDate === null || context.isAdmin : false);
    }

    private initInspectionCell(cell: CellModel<DeviceAnnualInspection>): void {
        cell.readonly = true;
    }

    private renderIcons(cell: CellModel<DeviceAnnualInspection>, action: CellAction<DeviceAnnualInspection>) {
        const icons: React.ReactNode[] = [];

        if (cell.model.file != null) {
            icons.push(<Icon name={"far paperclip"} className={"text-primary"} onClick={async () => await this.processAttachmentDownloadAsync(cell, action)}/>);
        }

        if (this.props.editMode && this.canEdit(cell.model)) {
            icons.push(<Icon name={"fa-pen"} className={"text-primary"} onClick={async () => await this.openEditAnnualInspectionModal(cell)}/>);
        }

        return icons;
    }

    private async deleteAnnualInspection(cell: CellModel<DeviceAnnualInspection>): Promise<void> {
        await this.postAsync(EndpointPaths.AnnualInspectionPaths.DeleteInspection, cell.model.id);
        await this.reloadAsync();
    }

    private validateEditRequest(request: EditDeviceAnnualInspectionRequest): boolean {
        const somethingWasChanged = (
            !!request.file
            || (!!request.inspectionInvoiceNumber && (request.inspectionInvoiceNumber != this.inspectionToEdit?.inspectionInvoiceNumber))
            || (!!request.inspectionCost && (request.inspectionCost != this.inspectionToEdit?.inspectionCost))
            || (!!request.operatingHours && (request.operatingHours != this.inspectionToEdit?.operatingHours))
            || (!!request.inspectorId && (request.inspectorId != this.inspectionToEdit?.vendor?.id))
            || (!!request.date && (request.date != this.inspectionToEdit?.inspectionDate))
            || (!!request.depoId && (request.depoId != this.inspectionToEdit?.depo?.id))
            || (!!request.type && (request.type != this.inspectionToEdit?.inspectionType))

        );

        return (!!request.id && somethingWasChanged);
    }

    private get file(): FileModel {
        return this._fileInputRef.current?.value as FileModel;
    }

    private get inspectionToEdit(): DeviceAnnualInspection | null {
        return this.state.inspectionToEdit;
    }

    private get selectedDepoId(): string | null {
        return this.state.selectedDepoId;
    }

    private get selectedType(): AnnualInspectionType | null {
        return this.state.selectedType;
    }

    private get selectedVendor(): string | null {
        return this.state.selectedVendor;
    }

    private get canEditOtherDetails(): boolean {
        const ctx = ch.getContext() as UserContext;
        return ctx.isAdmin;
    }

    private get vendorItems(): SelectListItem[] {
        const vendors = this.state.vendors;
        if (!vendors) {
            return [];
        }

        let items: SelectListItem[] = [];

        vendors.forEach(vendor => {
            const item: SelectListItem = new SelectListItem();

            item.value = vendor.id;
            item.text = vendor.name ?? "-";

            if (vendor.parentId) {
                const parent = vendors.firstOrDefault(o => o.id == vendor.parentId);
                if (parent) {
                    item.group = SelectListGroup.create(parent.name!);
                    item.group.order = 999;
                }
            }

            items.push(item);
        });

        return items;
    }

    private get isAdmin(): boolean {
        const ctx = ch.getContext() as UserContext;
        return ctx.isAdmin;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!RentaToolsController.device) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true);
        }

        const listVendorsRequest = {
            includeDeleted: false,
            includeChildren: true,
        } as ListAnnualInspectionVendorsRequest;

        const vendors = await AnnualInspectionController.listVendorsAsync(listVendorsRequest);

        const depos = await RentaToolsController.getUserDeposAsync();
        const response = await RentaToolsController.getAnnualInspectionTypeItemsByProductGroup(this.device.productGroupId);

        // Remove deployment inspection from available types if a deployment inspection has already been completed
        let inspectionTypes = response.annualInspectionTypes;

        if (!this.isAdmin || RentaToolsController.device?.isDeploymentInspectionDone) {
            let deploymentInspection = inspectionTypes.firstOrDefault(x => x.id === AnnualInspectionType.DeploymentInspection.toString());
            if (deploymentInspection) {
                inspectionTypes.remove(deploymentInspection);
            }
        }

        await this.getOperatingHoursLimitsAsync();

        this.setState({
            vendors,
            depos,
            inspectionTypes: inspectionTypes,
        });
    }

    private async setSelectedTypeAsync(type: AnnualInspectionTypeModel | null): Promise<void> {
        const typeNumber = type?.id as number | undefined;
        const annualInspectionType: AnnualInspectionType = !!typeNumber ? typeNumber as AnnualInspectionType : AnnualInspectionType.Inspection;

        this.setState({ selectedType: annualInspectionType });
    }

    private async setSelectedVendor(vendorId: string, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            this.setState({selectedVendor: vendorId});
        }
    }

    private async setSelectedDepoAsync(depoId: string, userInteraction: boolean): Promise<void> {
        if (userInteraction) {
            this.setState({selectedDepoId: depoId});
        }
    }

    private async processAttachmentDownloadAsync(cell: CellModel<DeviceAnnualInspection>, action: CellAction<DeviceAnnualInspection>): Promise<void> {
        const inspectionId: string = cell.model.id;

        const file: FileModel = await AnnualInspectionController.downloadDeviceAnnualInspectionPdfAsync(inspectionId);

        if (!file) {
            await ch.alertErrorAsync(Localizer.genericFileNotFound);
        } else {
            ch.download(file);
        }
    }

    private async openEditAnnualInspectionModal(cell: CellModel<DeviceAnnualInspection>): Promise<void> {
        const inspection: DeviceAnnualInspection = cell.model;

        this.setState({
            inspectionToEdit: inspection,
            selectedVendor: inspection.vendor?.id ?? null,
            selectedType: inspection.inspectionType,
            selectedDepoId: inspection.depo?.id ?? null,
            selectedDate: inspection.inspectionDate,
        });

        if (this._annualInspectionToEditModalRef.current) {
            await this._annualInspectionToEditModalRef.current.openAsync();
        }
    }

    private async closeEditAnnualInspectionModal(): Promise<void> {
        if (this._annualInspectionToEditModalRef.current) {
            await this._annualInspectionToEditModalRef.current.closeAsync();
        }
    }

    private async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const request: EditDeviceAnnualInspectionRequest = {
            id: this.inspectionToEdit?.id ?? null,
            file: this.file,
            inspectionInvoiceNumber: data.getValue("inspectionInvoiceNumber"),
            inspectionCost: data.getValue("inspectionCost"),
            operatingHours: data.getValue("operating_hours"),
            depoId: this.selectedDepoId ?? null,
            type: this.selectedType,
            date: this._inspectionDateInputRef.current?.value ?? null,
            inspectorId: this.selectedVendor,
        }

        const canSend: boolean = this.validateEditRequest(request);

        if (canSend) {
            const response = await AnnualInspectionController.editDeviceAnnualInspectionAsync(request);

            if (!response.success)
            {
                const alertText = AnnualInspectionController.getErrorAlert(response);

                if (alertText) {
                    await ch.alertErrorAsync(alertText);
                }

                await this.closeEditAnnualInspectionModal();

                return;
            }

            await this.closeEditAnnualInspectionModal();

            if (this.props.onSaveEdit) {
                await this.props.onSaveEdit();
            }

            await this.reloadAsync();
        } else {
            await ch.alertErrorAsync(Localizer.annualInspectionHistoryPageInspectionNotModified, true);
            await this.closeEditAnnualInspectionModal();
        }
    }

    protected async fetchDataAsync(): Promise<DeviceAnnualInspection[]> {
        const request: ListDeviceAnnualInspectionsRequest = {
            deviceId: this.props.device.id,
            sortDirection: SortDirection.Desc
        }

        return await this.postAsync(EndpointPaths.AnnualInspectionPaths.ListDeviceInspections, request)
    }

    private get device(): DeviceInfo {
        return RentaToolsController.device!;
    }

    private async getOperatingHoursLimitsAsync(newDate?: Date): Promise<any> {
        const request = new OperatingHoursLimitRequest();
        request.deviceId = this.device.id;
        request.reportDate = newDate ?? new Date();
        const limits = await RentaToolsController.getOperatingHoursLimitsAsync(request);
        const minOperatingHours = limits.find(val => val.recordedDate <= request.reportDate)?.recordedOperatingHours ?? null;
        const maxOperatingHours = limits.find(val => val.recordedDate > request.reportDate)?.recordedOperatingHours ?? null;

        this.setState({
            minOperatingHours,
            maxOperatingHours,
            selectedDate: newDate ?? null,
        });
    }

    private async validateFileSizeAsync(file: FileModel): Promise<void> {
        const isUploadedFileInvalid: boolean = !fileHelper.isSupportedPdfFileSize(file);
        if (file && isUploadedFileInvalid) {
            await ch.flyoutErrorAsync(Localizer.genericFlyoutErrorMessageFileIsTooLarge);
        }

        this.setState({isUploadedFileInvalid});
    }

    private renderCustomDateInput(): React.ReactNode {

        const date = this.inspectionDate;

        const label = ToolsUtility.toDateStringWithTime(date);

        return (
            <ArsenalButton block
                           className={this.css(styles.label)}
                           type={ButtonType.Default}
                           label={label}
                           icon={{name: "fal calendar-alt"}}
            />
        );
    }

    public getManual(): string {
        return "";
    }

    protected getEndpoint(): string {
        return "";
    }

    public isAsync(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <>
                {
                    (this.state.data != null && this.state.data.length > 0)
                        ? <div/>
                        : <div>{Localizer.deviceAnnualInspectionHistoryPageNoData}</div>
                }

                {
                    (this.state.data != null) &&
                    (
                        <Grid responsive
                              id={"annualInspectionsReportsGrid"}
                              key={"annualInspectionsReportsGrid"}
                              columns={this._annualInspectionsColumns}
                              className={styles.noBordersGrid}
                              data={this.state.data}
                        />
                    )
                }

                <Modal ref={this._annualInspectionToEditModalRef}
                       id={"_annualInspectionToEditModal"}
                       title={Localizer.annualInspectionHistoryPageEditInvoiceDetailsOrAttachment}
                       subtitle={`${this.props.device.name}`}
                       onClose={async () => this.setState({inspectionToEdit: null})}
                >
                    {
                        (this.state.inspectionToEdit) &&
                        (
                            <Form onSubmit={async (_, data) => {
                                await this.handleSubmitAsync(data)
                            }}>

                                <h6>{Localizer.generalInvoiceDetails}</h6>
                                <TextInput id={"inspectionInvoiceNumber"}
                                           label={Localizer.deviceAnnualInspectionHistoryPageInspectionInvoiceNumber}
                                           value={this.state.inspectionToEdit.inspectionInvoiceNumber}
                                />

                                <NumberInput id={"inspectionCost"}
                                             label={Localizer.deviceAnnualInspectionHistoryPageInspectionCost}
                                             value={this.state.inspectionToEdit.inspectionCost}
                                             step={0.01}
                                />

                                {
                                    (this.canEditOtherDetails) && (
                                        <>
                                            <DateInput showTime
                                                       id={"inspectionDate"}
                                                       maxDate={Utility.today()}
                                                       value={this.inspectionDate}
                                                       ref={this._inspectionDateInputRef}
                                                       label={Localizer.deviceServicePageGeneralLabelDate}
                                                       customInput={() => this.renderCustomDateInput()}
                                                       onChange={async (val) => {await this.getOperatingHoursLimitsAsync(val)} }
                                            />

                                            <Dropdown required
                                                      orderBy={DropdownOrderBy.Value}
                                                      id={"inspectionType"}
                                                      ref={this._inspectionTypeInputRef}
                                                      label={Localizer.deviceAnnualInspectionHistoryPageInspectionType}
                                                      items={this.state.inspectionTypes || []}
                                                      selectedItem={this.selectedType}
                                                      onChange={async (_, item) => await this.setSelectedTypeAsync(item!)}
                                            />

                                            <Dropdown required
                                                      id={"depot"}
                                                      items={this.state.depos}
                                                      label={Localizer.reportPreviewDepot}
                                                      selectedItem={this.selectedDepoId}
                                                      onChange={async (_, item, userInteraction) => await this.setSelectedDepoAsync(item!.id, userInteraction)}
                                            />

                                            <Dropdown required
                                                      id={"annualInspectionVendor"}
                                                      ref={this._inspectionVendorIdInputRef}
                                                      items={this.vendorItems}
                                                      selectedItem={this.selectedVendor}
                                                      label={Localizer.reportPreviewInspector}
                                                      onChange={async (_, item, userInteraction) => await this.setSelectedVendor(item!.value, userInteraction)}
                                            />
                                        </>
                                    )
                                }

                                {
                                    (this.props.device.supportsOperatingHours) &&
                                    (
                                        <NumberInput id={"operating_hours"}
                                                     label={Localizer.deviceServicePageLabelOperatingHours}
                                                     behaviour={NumberInputBehaviour.ValidationOnChange}
                                                     format={"0.00"}
                                                     step={0.01}
                                                     value={this.state.inspectionToEdit.operatingHours || 0}
                                        />
                                    )
                                }

                                <h6>{Localizer.deviceAnnualInspectionInspectionDocumentDetails}</h6>
                                <FileInput dropZone
                                           id={"pdf_input"}
                                           ref={this._fileInputRef}
                                           disabled={!this.canEditDocument}
                                           fileTypes={["application/pdf"]}
                                           maxSize={RentaToolsConstants.fileMaxSize}
                                           maxTotalSize={RentaToolsConstants.fileMaxSize}
                                           label={Localizer.deviceAnnualInspectionFormInspectionDocument}
                                           value={this.file}
                                           onChange={(_, value: FileModel) => this.validateFileSizeAsync(value)}
                                           onRemove={async() => this.setState({isUploadedFileInvalid: false})}
                                />

                                <Button submit
                                        disabled={this.state.isUploadedFileInvalid}
                                        type={ButtonType.Orange}
                                        label={Localizer.componentFormSave}
                                />

                            </Form>
                        )
                    }
                </Modal>
            </>
        );
    }
}