import React from "react";
import {BaseComponent, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Dropdown, Icon, IconSize} from "@renta-apps/athenaeum-react-components";
import ArsenalButton from "../../../components/ArsenalButton/ArsenalButton";
import Mapping from "../../Models/Mapping";
import Category from "../../Models/Category";
import ReportDefinition from "@/pages/Models/ReportDefinition";
import {ReportDefinitionType} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "../../../localization/Localizer";

import styles from "../MappingsPage.module.scss";

interface IMappingsPanelProps {

    inspections?: boolean;

    mappings: Mapping[];
    availableCategories: Category[];

    addCategoryAsync(mapping: Mapping, category: Category): Promise<void>;

    deleteCategoryAsync(mapping: Mapping, category: Category): Promise<void>;
}

interface IMappingsPanelState {
}

export default class MappingsPanel extends BaseComponent<IMappingsPanelProps, IMappingsPanelState> {

    state: IMappingsPanelState = {};

    private getAvailableCategories(mapping: Mapping): Category[] {
        return this.props.availableCategories.filter(category => !mapping.categories.some(a => a.productGroupId == category.productGroupId && a.productType == null));
    }

    private async openDefinitionAsync(reportDefinitionId: string): Promise<void> {

        let allDefinitions: ReportDefinition[] = await RentaToolsController.getReportDefinitionsAsync();

        let definition: ReportDefinition = allDefinitions.filter(a => a.id == reportDefinitionId)[0];
        RentaToolsController.reportDefinition = definition;

        const route: PageRoute = (definition.type == ReportDefinitionType.ReturnInspection)
            ? PageDefinitions.reportDefinitionRoute
            : PageDefinitions.serviceReportDefinitionRoute;

        await PageRouteProvider.redirectAsync(route);
    }

    private getItemsLength(steps: number): string {
        return (steps > 0)
            ? Localizer.get(Localizer.mappingsPageReportDefinitionTitle, "", steps).replace(",", "").trim()//hack
            : ``
    }

    public render(): React.ReactNode {
        return (
            <div className="container-xxl">
                {
                    this.props.mappings.map((mapping) => (
                        <div key={mapping.reportDefinitionId}
                             className={this.css("col", styles.reportDefinitionContainer)}
                             data-cy={`container_${mapping.reportDefinitionId}`}>

                            <div className={this.css("row", styles.reportDefinitionRow)}>
                                <div className={styles.rowHeader}>
                                    {
                                        Localizer.mappingsPageInspectionCategory
                                    }
                                    <div>
                                        <h5>{mapping.reportDefinitionName}</h5>
                                    </div>
                                </div>

                                <div className={styles.rowHeader}>
                                    {
                                        this.props.inspections && (
                                            <>

                                                <div>
                                                    {
                                                        Localizer.mappingsPageSteps
                                                    }
                                                </div>
                                                <div>
                                                    <h5>{this.getItemsLength(mapping.reportDefinitionStepAmount)}</h5>
                                                </div>
                                            </>

                                        )
                                    }
                                </div>

                                <div className={styles.rowHeader}>
                                    {
                                        Localizer.mappingsPageViewReport
                                    }
                                    <div>
                                        <Icon name={"eye"}
                                              className={styles.removeMappingIcon}
                                              size={IconSize.X3}
                                              onClick={async () => await this.openDefinitionAsync(mapping.reportDefinitionId!)}

                                        />
                                    </div>
                                </div>
                                <div className={styles.rowHeader}>
                                    <Dropdown className={styles.productGroupDropdown}
                                              nothingSelectedText={Localizer.mappingsPageSelectCategories}
                                              items={this.getAvailableCategories(mapping)}
                                              onChange={async (sender, item) => await this.props.addCategoryAsync(mapping, item!)}
                                    />
                                </div>
                            </div>

                            <div className="col px-0">
                                {
                                    (mapping.categories.length > 0)
                                        ?
                                        (
                                            mapping.categories.map((category, index) => (
                                                <div className="row"
                                                     key={`${category.name}_${index}`}
                                                     data-cy={`${category.name}`}>
                                                    <div className={this.css("col-12", styles.category)}>
                                                        <div className={styles.categoryText}>
                                                            <div>
                                                                ERP:
                                                                <b>
                                                                    {category.productGroupId}
                                                                </b>

                                                                {
                                                                    (category.productType) && (
                                                                        <>
                                                                            <br/>
                                                                            {
                                                                                `Type: ${category.productType}`
                                                                            }
                                                                        </>

                                                                    )

                                                                }
                                                                <br/>
                                                                {category.name}
                                                            </div>
                                                        </div>
                                                        <div className={styles.categoryRemove}>
                                                            <ArsenalButton id={'removeCategory'}
                                                                           type={ButtonType.Orange}
                                                                           icon={{name: "far trash-alt"}}
                                                                           title={Localizer.mappingsPageButtonDeleteCategory}
                                                                           onClick={async () => await this.props.deleteCategoryAsync(mapping, category)}
                                                            />
                                                        </div>

                                                    </div>

                                                </div>
                                            ))
                                        )
                                        :
                                        (
                                            <div className="row align-items-center">
                                                <div className={this.css("col-12", styles.emptyCategory)}>
                                                    <span className={styles.categoryText}> {Localizer.mappingsPageTextNoCategories} </span>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>

                        </div>

                    ))
                }
            </div>
        );
    }
}