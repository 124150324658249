export default class Connectivity {
    public gps: GPS = new GPS;
    public gsm: GSM = new GSM;
    public transmissions: Transmissions = new Transmissions;
}

export class GPS {
    public satellites: number = 0;
    public signalQuality: number = 0;
    public latestFixTime: Date = new Date();
    public isGps: boolean = false;
}

export class GSM {
    public signalQuality: number = 0;
    public networkTechnology: string = "";
    public isGsm: boolean = false;
}

export class Transmissions {
    public latestReceptionTime: Date = new Date();
    public isTransmissions: boolean = false;
}