import {UserRole} from "@/models/Enums";

export default class GetUsersRequest {

    public showDeleted: boolean = false;

    public showExpired: boolean = false;

    public roles: UserRole[] | null = null;
    
    public depoIds: string[] | null = null;
}