import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import GridHelper from "@/cypressHelpers/GridHelper";
import AdminPage from "@/tests/pages/AdminPage";
import Localizer from "@/localization/Localizer";

type Checked = 'checked' | 'unchecked';

class ExpensesPage {
    public elements = {
        tabs: {
            adBluesDataTab: () => cy.get('#tab_adBluesDataTab'),
            fuelTypesDataTab: () => cy.get('#tab_fuelTypesDataTab'),
            washingTypesDataTab: () => cy.get('#tab_washingTypesDataTab'),
            additionalExpensesDataTab: () => cy.get('#tab_additionalExpensesDataTab'),
        },
        adBluesGrid: () => cy.get("#table_adBluesGrid"),
        adBluesGridCell: (cellContent: string, columnNumber: number, exactMatch: boolean = true) => GridHelper.getGridCell(this.elements.adBluesGrid(), cellContent, columnNumber, exactMatch),
        fuelTypesGrid: () => cy.get("#table_fuelTypesGrid"),
        fuelTypesGridCell: (cellContent: string, columnNumber: number, exactMatch: boolean = true) => GridHelper.getGridCell(this.elements.fuelTypesGrid(), cellContent, columnNumber, exactMatch),
        washingTypesGrid: () => cy.get("#table_washingTypesGrid"),
        washingTypesGridCell: (cellContent: string, columnNumber: number, exactMatch: boolean = true) => GridHelper.getGridCell(this.elements.washingTypesGrid(), cellContent, columnNumber, exactMatch),
        additionalExpensesGrid: () => cy.get("#table_additionalExpensesGrid"),
        additionalExpensesGridRow: (cellContent: string, exactMatch: boolean = true) => GridHelper.getGridRowByCellContent(this.elements.additionalExpensesGrid(), cellContent, exactMatch),
        additionalExpensesGridCell: (cellContent: string, columnNumber: number, exactMatch: boolean = true) => GridHelper.getGridCell(this.elements.additionalExpensesGrid(), cellContent, columnNumber, exactMatch),
        getGridCollapsedRowValueCell: (mainRowCellContent: string, collapsedRowColumnName: string, exactMatch: boolean = true) => GridHelper.getGridCollapsedRowValueCell(this.elements.additionalExpensesGrid(), mainRowCellContent, collapsedRowColumnName, exactMatch),
        adBlueButtons: {
            add: () => cy.get('#addAdBlue'),
            reload: () => cy.get('#reloadAdBlues'),
            showDeleted: () => cy.get('#showDeletedAdBlues'),
        },
        fuelTypeButtons: {
            add: () => cy.get('#addFuelType'),
            reload: () => cy.get('#reloadFuelTypes'),
            showDeleted: () => cy.get('#showDeletedFuelTypes'),
        },
        washingTypeButtons: {
            add: () => cy.get('#addWashingType'),
            reload: () => cy.get('#reloadWashingTypes'),
            showDeleted: () => cy.get('#showDeletedWashingTypes'),
        },
        additionalExpenseButtons: {
            add: () => cy.get('#addAdditionalExpense'),
            reload: () => cy.get('#reloadAdditionalExpenses'),
            showDeleted: () => cy.get('#showDeletedAdditionalExpenses'),
        },
    }

    public routes = {
        getAdBlues: {
            path: "/api/classifier/getAdBlues",
            method: "POST",
        } as RouteData,
        getFuelTypes: {
            path: "/api/classifier/getFuelTypes",
            method: "POST",
        } as RouteData,
        getWashingTypes: {
            path: "/api/classifier/getWashingTypes",
            method: "POST",
        } as RouteData,
        getAdditionalExpenses: {
            path: "/api/classifier/getAdditionalExpenses",
            method: "POST",
        } as RouteData,
        addAdditionalExpense: {
            path: "/api/classifier/addAdditionalExpense",
            method: "POST",
        } as RouteData,
        saveAdditionalExpense: {
            path: "/api/classifier/saveAdditionalExpense",
            method: "POST",
        } as RouteData,
        deleteAdditionalExpense: {
            path: "/api/classifier/deleteAdditionalExpense",
            method: "POST",
        } as RouteData,
    }

    open(): void {
        executeWithIntercept(() => {
            AdminPage.elements.returnInspectionExpenses().click();
        }, [this.routes.getAdBlues]);
    }

    clickAdditionalExpensesTab(): void {
        executeWithIntercept(() => {
            this.elements.tabs.additionalExpensesDataTab().click();
        }, [this.routes.getAdditionalExpenses]);

        cy.wait(50);
    }

    unsetGenericAdditionalExpenseIfExists(): void {
        let expenseNamesToSetNotGeneric: string[] = [];

        this.elements.additionalExpensesGrid()
            .then(($grid) => {
                const isResponsive: boolean = GridHelper.isGridResponsive($grid);

                if (isResponsive) {
                    GridHelper.expandAllGridVisibleRows(this.elements.additionalExpensesGrid());

                    this.elements.additionalExpensesGrid()
                        .find('.athenaeum-grid-collapsedRow')
                        .each(($collapsedRow) => {
                            let isMarkedAsGeneric: boolean;

                            const expenseName: string = $collapsedRow.prevAll('tr').eq(1)
                                .find('td:eq(2)')
                                .text()
                                .trim();

                            cy.wrap($collapsedRow)
                                .contains(Localizer.additionalExpenseTypeGeneric)
                                .parents('tr')
                                .find(`> :nth-child(2)`)
                                .then(($genericColumnCell) => {
                                    isMarkedAsGeneric = ($genericColumnCell.find('input').length > 0 &&
                                        $genericColumnCell.find('input').is(':checked'));

                                    if (isMarkedAsGeneric) {
                                        expenseNamesToSetNotGeneric.push(expenseName);
                                    }
                                });
                        });
                } else {
                    this.elements.additionalExpensesGrid()
                        .within(() => {
                            cy.get('td:nth-child(10)')
                                .each(($genericColumnCell) => {

                                    const isMarkedAsGeneric: boolean = ($genericColumnCell.find('input').length > 0 &&
                                        $genericColumnCell.find('input').is(':checked'));

                                    if (isMarkedAsGeneric) {
                                        const secondCellValue: string = $genericColumnCell
                                            .closest('tr')
                                            .find('td:eq(1)')
                                            .text()
                                            .trim();

                                        expenseNamesToSetNotGeneric.push(secondCellValue);
                                    }
                                });
                        });
                }

                if (expenseNamesToSetNotGeneric.length > 1) {
                    throw new Error("Critical error. Only one generic additional expanse may exist!");
                }

                cy.wrap(expenseNamesToSetNotGeneric).then((expenseNamesToSetNotGeneric) => {
                    cy.log(`Un-set generic for: ${expenseNamesToSetNotGeneric.join(', ')}`);
                    expenseNamesToSetNotGeneric.forEach((name) => {
                        this.unsetGenericAndSave(name, isResponsive);
                    });
                });
            });
    }

    unsetGenericAndSave(name: string, isResponsive: boolean): void {
        if (!isResponsive) {
            this.elements.additionalExpensesGridCell(name, 10)
                .within(() => {
                    cy.get('input[type=checkbox]')
                        .click();
                });
        } else {
            this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpenseTypeGeneric).click();
        }

        this.saveExpense(name, false);
    }

    saveExpense(name: string, isAddNew: boolean): void {
        this.elements.additionalExpensesGrid()
            .then(($grid) => {
                const isResponsive: boolean = GridHelper.isGridResponsive($grid);

                executeWithIntercept(() => {
                    if (!isResponsive) {
                        this.elements.additionalExpensesGridCell(name, 11)
                            .within(() => {
                                cy.get("[title=Save]").click();
                            });
                    } else {
                        GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);
                        GridHelper.saveGridCollapsedRow(this.elements.additionalExpensesGrid(), name);
                    }
                }, (isAddNew)
                    ? [this.routes.addAdditionalExpense]
                    : [this.routes.saveAdditionalExpense]);
            });
    }

    deleteExpense(name: string): void {
        this.elements.additionalExpensesGrid()
            .then(($grid) => {
                const isResponsive: boolean = GridHelper.isGridResponsive($grid);

                if (!isResponsive) {
                    this.elements.additionalExpensesGridCell(name, 11).within(() => {
                        cy.get("[title=Deleted]").trigger('click');
                    });
                } else {
                    GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);
                    GridHelper.deleteGridCollapsedRow(this.elements.additionalExpensesGrid(), name);
                }
            });

        executeWithIntercept(() => {
            cy.get('div.athenaeum-confirmation-dialog-opened')
                .find('[data-dismiss]')
                .eq(0)
                .trigger('click');
        }, [this.routes.deleteAdditionalExpense]);
    }

    restoreExpense(name: string): void {
        this.elements.additionalExpensesGrid()
            .then(($grid) => {
                const isResponsive: boolean = GridHelper.isGridResponsive($grid);

                if (!isResponsive) {
                    this.elements.additionalExpensesGridCell(name, 11).within(() => {
                        cy.get("[title=Restore]").trigger('click');
                    });
                } else {
                    GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);
                    GridHelper.restoreGridCollapsedRow(this.elements.additionalExpensesGrid(), name);
                }
            });
    }

    updateExpense(
        isNew: boolean,
        name: string,
        productNumber?: string | null,
        unit?: string | null,
        min?: number | null,
        max?: number | null,
        defaultValue?: number | null,
        step?: number | null,
        clickInvoiceable?: true | null,
        canMarkGeneric?: boolean | null,
    ): void {
        // If it is a new expense then the 1st row in a grid in the first column (#) has value "1"
        // otherwise search desired row by name.
        const cellContent: string = isNew
            ? "1"
            : name;

        this.elements.additionalExpensesGrid()
            .then((grid) => {
                const isResponsive: boolean = GridHelper.isGridResponsive(grid);

                // Name
                this.elements.additionalExpensesGridCell(cellContent, (!isResponsive) ? 2 : 3).trigger('click')
                    .focused()
                    .clear()
                    .type(`${name}` + "{enter}");

                // Product number
                if (productNumber) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 3 : 4).trigger('click')
                        .focused()
                        .clear()
                        .type(`${productNumber}` + "{enter}");
                }

                // Unit
                if (unit) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 4 : 5).selectDropdownValue(unit, true);
                }

                // Min
                if (min) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 5 : 6).trigger('click')
                        .focused()
                        .clear()
                        .type(min + "{enter}");
                }

                // Max
                if (max) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 6 : 7).trigger('click')
                        .focused()
                        .clear()
                        .type(max + "{enter}");
                }

                // Default
                if (defaultValue) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 7 : 8).trigger('click')
                        .focused()
                        .clear()
                        .type(defaultValue + "{enter}");
                }

                // Step
                if (step) {
                    if (isResponsive) {
                        GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                        this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpensesPageGridStep)
                            .trigger('click')
                            .focused()
                            .clear()
                            .type(step + "{enter}");
                    } else {
                        this.elements.additionalExpensesGridCell(name, 8).trigger('click')
                            .focused()
                            .clear()
                            .type(step + "{enter}");
                    }
                }

                // Invoiceable
                if (clickInvoiceable) {
                    if (isResponsive) {
                        GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                        this.elements.getGridCollapsedRowValueCell(name, Localizer.resourceTypeIsInvoiceable).click();
                    } else {
                        this.elements.additionalExpensesGridCell(name, 9).within(() => {
                            cy.get('input[type=checkbox]')
                                .click();
                        });
                    }
                }

                // Generic
                if (canMarkGeneric != null) {
                    if (canMarkGeneric) {
                        if (isResponsive) {
                            GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                            this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpenseTypeGeneric).click();
                        } else {
                            this.elements.additionalExpensesGridCell(name, 10).within(() => {
                                cy.get('input[type=checkbox]')
                                    .click();
                            });
                        }
                    } else {
                        if (isResponsive) {
                            GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                            this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpenseTypeGeneric)
                                .find('input[type=checkbox]').should('not.exist');
                        } else {
                            this.elements.additionalExpensesGridCell(name, 10).within(() => {
                                cy.get('input[type=checkbox]').should('not.exist');
                            });
                        }
                    }
                }
            });
    }

    assertExpense(
        name: string,
        productNumber?: string | null,
        unit?: string | null,
        min?: number | null,
        max?: number | null,
        defaultValue?: number | null,
        step?: number | null,
        invoiceable?: Checked | null,
        isGeneric?: Checked | null
    ): void {
        cy.log(`Assert Expense ${name}`);

        this.elements.additionalExpensesGrid()
            .then((grid) => {
                const isResponsive: boolean = GridHelper.isGridResponsive(grid);

                this.elements.additionalExpenseButtons.reload().click();
                this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 2 : 3).contains(name);

                if (productNumber) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 3 : 4).contains(productNumber);
                }

                if (unit) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 4 : 5).contains(unit);
                }

                if (min) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 5 : 6).contains(min);
                }

                if (max) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 6 : 7).contains(max);
                }

                if (defaultValue) {
                    this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 7 : 8).contains(defaultValue);
                }

                if (step) {
                    if (isResponsive) {
                        GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                        this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpensesPageGridStep)
                            .contains(step);
                    } else {
                        this.elements.additionalExpensesGridCell(name, 8).contains(step);
                    }
                }

                if (invoiceable) {
                    const condition: string = invoiceable === "checked"
                        ? 'have.attr'
                        : 'not.have.attr';

                    if (isResponsive) {
                        GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                        this.elements.getGridCollapsedRowValueCell(name, Localizer.resourceTypeIsInvoiceable)
                            .within(() => {
                                cy.get('input')
                                    .should(condition, invoiceable);
                            });
                    } else {
                        this.elements.additionalExpensesGridCell(name, 9).within(() => {
                            cy.get("input")
                                .should(condition, invoiceable);
                        });
                    }
                }

                if (isGeneric) {
                    const condition: string = isGeneric === "checked"
                        ? 'have.attr'
                        : 'not.have.attr';

                    if (isResponsive) {
                        GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                        this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpenseTypeGeneric)
                            .within(() => {
                                cy.get('input')
                                    .should(condition, invoiceable);
                            });
                    } else {
                        this.elements.additionalExpensesGridCell(name, 10).within(() => {
                            cy.get("input")
                                .should(condition, isGeneric);
                        });
                    }
                }
            });
    }

    assertDeletedOrRestored(assertDeleted: boolean, name: string): void {
        cy.log(`Assert ${assertDeleted ? "deleted" : "restored"} ${name}`);

        const condition: string = assertDeleted
            ? 'have.class'
            : 'not.have.class';

        this.elements.additionalExpensesGrid()
            .then((grid) => {
                const isResponsive: boolean = GridHelper.isGridResponsive(grid);

                this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 2 : 3).should(condition, 'athenaeum-grid-deleted');
                this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 3 : 4).should(condition, 'athenaeum-grid-deleted');
                this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 4 : 5).should(condition, 'athenaeum-grid-deleted');
                this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 5 : 6).should(condition, 'athenaeum-grid-deleted');
                this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 6 : 7).should(condition, 'athenaeum-grid-deleted');
                this.elements.additionalExpensesGridCell(name, (!isResponsive) ? 7 : 8).should(condition, 'athenaeum-grid-deleted');

                if (!isResponsive) {
                    this.elements.additionalExpensesGridCell(name, 8).should(condition, 'athenaeum-grid-deleted');
                    this.elements.additionalExpensesGridCell(name, 9).should(condition, 'athenaeum-grid-deleted');
                    this.elements.additionalExpensesGridCell(name, 10).should(condition, 'athenaeum-grid-deleted');
                    this.elements.additionalExpensesGridCell(name, 11).should('not.have.class', 'athenaeum-grid-deleted');
                } else {
                    GridHelper.expandSpecificGridRow(this.elements.additionalExpensesGrid(), name);

                    this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpensesPageGridStep)
                        .should(condition, 'athenaeum-grid-deleted');
                    this.elements.getGridCollapsedRowValueCell(name, Localizer.resourceTypeIsInvoiceable)
                        .should(condition, 'athenaeum-grid-deleted');
                    this.elements.getGridCollapsedRowValueCell(name, Localizer.additionalExpenseTypeGeneric)
                        .should(condition, 'athenaeum-grid-deleted');
                    this.elements.additionalExpensesGridRow(name)
                        .nextAll('.athenaeum-grid-collapsedRow').eq(0)
                        .find('tr')
                        .last()
                        .should('not.have.class', 'athenaeum-grid-deleted');
                }

            });
    }
}

export default new ExpensesPage();