import React from "react";
import {PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import InspectionPassThroughChart from "@/models/server/InspectionPassThroughChart";
import DevicesInMaintenanceChart from "@/models/server/DevicesInMaintenanceChart";
import {Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ChartsPage.module.scss";
import newStyles from "@/pages/NewUI.module.scss";

interface IChartsPageProps {
}

interface IChartsPageState {
    returnInspectionsGraph: InspectionPassThroughChart | null,
    devicesInMaintenanceChart: DevicesInMaintenanceChart | null
}

export default class ChartsPage extends AuthorizedPage<IChartsPageProps, IChartsPageState> {

    state: IChartsPageState = {
        returnInspectionsGraph: null,
        devicesInMaintenanceChart: null
    };

    private async loadDataAsync(): Promise<void> {
        const returnInspectionsGraph: InspectionPassThroughChart = await RentaToolsController.getReturnInspectionsChartAsync();
        const devicesInMaintenanceChart: DevicesInMaintenanceChart = await RentaToolsController.getDevicesInMaintenanceChartAsync();
        await this.setState({returnInspectionsGraph, devicesInMaintenanceChart});
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.loadDataAsync();
    }

    private get returnInspectionsGraph(): InspectionPassThroughChart {
        return this.state!.returnInspectionsGraph!;
    }

    private get devicesInMaintenanceChart(): DevicesInMaintenanceChart {
        return this.state!.devicesInMaintenanceChart!;
    }

    private getGridData(): object[] {
        return this.returnInspectionsGraph.items.map(function (v) {
            const overWeekTitle = Localizer.reportingPageOverWeek;
            const lessThan3Days = Localizer.reportingPageLessThan3Days;
            const moreThan3Days = Localizer.reportingPageMoreThan3Days;
            const week = Localizer.reportingPageWeek;
            return {
                name: week + " " + v.weekNumber,
                [lessThan3Days]: v.passedInLessThanThreeDays,
                [moreThan3Days]: v.passedInOverThreeDays,
                [overWeekTitle]: v.passedInOverWeek
            }
        });
    }

    private getPieData(): object[] {
        return [
            {name: Localizer.chartsPagePieDataInMaintenance, value: this.devicesInMaintenanceChart.devicesInMaintenance},
            {name: "100%", value: 100 - this.devicesInMaintenanceChart.devicesInMaintenance}
        ];
    }

    public render(): React.ReactNode {

        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.reportDefinitions, rentaToolsStyles.pageContainer, newStyles.pageContainer)}>

                <PageHeader title={Localizer.reportingPagePageTitle} subtitle="" />

                {
                    (this.returnInspectionsGraph) &&
                    (
                        <ArsenalPageRow>

                            <h5>{Localizer.reportingPageInspectionPassThroughTitle}</h5>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                    data={this.getGridData()}
                                    margin={{
                                        top: 20, right: 0, left: -40, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Bar dataKey={Localizer.reportingPageLessThan3Days} stackId="a" fill="lightblue"/>
                                    <Bar dataKey={Localizer.reportingPageMoreThan3Days} stackId="a" fill="orange"/>
                                    <Bar dataKey={Localizer.reportingPageOverWeek} stackId="a" fill="red"/>
                                </BarChart>
                            </ResponsiveContainer>

                        </ArsenalPageRow>
                    )
                }
                {
                    (this.returnInspectionsGraph) &&
                    (
                        <ArsenalPageRow>

                            <h5>{Localizer.reportingPageMachineryInInspectionTitle}</h5>
                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <text
                                        x="50%"
                                        y="50%"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                    >
                                        {
                                            this.devicesInMaintenanceChart.devicesInMaintenance.toFixed(2)
                                        }
                                        %
                                    </text>
                                    <Pie
                                        startAngle={180}
                                        endAngle={0}
                                        innerRadius="56%"
                                        data={this.getPieData()}
                                        dataKey="value"
                                        blendStroke
                                        isAnimationActive={false}
                                    >
                                        <Cell fill="lightblue"/>
                                        <Cell fill="#eaeaea"/>

                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>

                        </ArsenalPageRow>
                    )
                }

            </PageContainer>
        );
    }
}