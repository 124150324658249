import ServiceAction from "@/pages/Models/ServiceAction";
import {ServiceDefinitionType} from "@/models/Enums";

export default class ServiceReportType {

    public id: string = "";

    public name: string = "";

    public generic: boolean = false;

    public actions: ServiceAction[] = [];

    public totalOperationHoursLimit: number | null = null;

    public currentOperationHoursLimit: number | null = null;

    public totalRentalDaysLimit: number | null = null;

    public currentRentalDaysLimit: number | null = null;

    public annualServiceMonthsLimit: number | null = null;

    public totalMonthsLimit: number | null = null;

    public isManual: boolean = false;

    public noActions: boolean = false;

    public dontSendServiceDate: boolean = false;

    public isServiceReportType: true = true;
    
    public serviceDefinitionType : ServiceDefinitionType | null = null;
}