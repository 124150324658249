import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";

export default class ChecksReportItem extends ReportDefinitionItem {
    public ok: boolean | null = null;

    public static isValid(item: ChecksReportItem): boolean {
        return (item.checks != null) && (item.checks.length > 0) && (item.checks.every(checkItem => checkItem.ok != null));
    }

    public static isOk(item: ChecksReportItem): boolean | null {
        return ((item.checks != null) && (item.checks.length > 0) && (item.checks.every(checkItem => checkItem.ok != null)))
            ? (item.checks.every(checkItem => checkItem.ok == true))
            : null;
    }
}