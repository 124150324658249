import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import {NotificationType} from "@/models/Enums";
import {Utility} from "@renta-apps/athenaeum-toolkit";

class NotificationSettings {
    public elements = {
        form: () => cy.get('#notificationSettingsForm'),
        notificationSwitcher: () => cy.get('#notificationEnabled'),
        notificationReceiversDropdown: () => cy.get('#notificationReceivers'),
        notificationTypeDropdown: () => cy.get('#notificationType'),
        mediaTypeDropdown: () => cy.get('#notificationMediaType'),
        intervalDropdown: () => cy.get('#notificationInterval'),
        weeklySendOccurrenceDropdown: () => cy.get('#notificationWeeklySendOccurrence'),
        sendTimeDateInput: () => cy.get('#input_notificationSendTime'),
        pausedFromDateInput: () => cy.get('#input_notificationPausedFrom'),
        pausedUntilDateInput: () => cy.get('#input_notificationPausedUntil'),
        backButton: () => cy.get('#back'),
        saveButton: () => cy.get('#save'),
    };

    public routes = {
        getGlobalNotificationSetting: {
            path: "api/notification/getGlobalNotificationSetting",
            method: "POST",
        } as RouteData,
        saveGlobalNotificationSetting: {
            path: "api/notification/saveGlobalNotificationSetting",
            method: "POST",
        } as RouteData,
        getUserNotificationSetting: {
            path: "api/account/getUserNotificationSetting",
            method: "POST",
        } as RouteData,
        saveUserNotificationSetting: {
            path: "api/account/saveUserNotificationSetting",
            method: "POST",
        } as RouteData,
    };

    public modifyNotificationSettings(notificationType: NotificationType, global: boolean): void {
        switch (notificationType) {
            case NotificationType.WrongServicePrediction: {
                const notificationTypeText: string = "Wrong service prediction";

                this.elements.notificationTypeDropdown().selectDropdownValue(notificationTypeText);

                cy.log(`Update "${notificationTypeText}" notification type settings.`);

                this.elements.notificationSwitcher().dblclick();

                this.elements.mediaTypeDropdown().selectDropdownValue("SMS");
                this.elements.mediaTypeDropdown().validateDropdownSelectedValue("SMS");
                this.elements.mediaTypeDropdown().selectDropdownValue("Email");
                this.elements.mediaTypeDropdown().validateDropdownSelectedValue("Email");
                this.elements.mediaTypeDropdown().selectDropdownValue("Email and SMS");
                this.elements.mediaTypeDropdown().validateDropdownSelectedValue("Email and SMS");

                if (!global) {
                    this.elements.pausedFromDateInput().should("exist");
                    this.elements.pausedFromDateInput().selectDatePickerDate(Utility.utcNow().addDays(1));

                    this.elements.pausedUntilDateInput().should("exist");
                    this.elements.pausedUntilDateInput().selectDatePickerDate(Utility.utcNow().addDays(7));
                } else {
                    const adminReceiver: string = "juhani.sihvonen@weare.fi";
                    this.elements.notificationReceiversDropdown().selectDropdownValue(adminReceiver);
                    this.elements.notificationReceiversDropdown().click();
                    this.elements.notificationReceiversDropdown().validateDropdownSelectedValue(adminReceiver);
                }

                this.elements.intervalDropdown().dropdownShouldContainValue("Daily");
                this.elements.intervalDropdown().dropdownShouldContainValue("On-demand");
                this.elements.intervalDropdown().dropdownShouldContainValue("Weekly");

                this.elements.intervalDropdown().selectDropdownValue("On-demand");
                // Check that 'Occurrence Time' dateInput is not displayed when 'Interval.OnDemand' selected.
                this.elements.sendTimeDateInput().should("not.exist");
                // Check that 'Weekly Occurrence' dropdown is not displayed when 'Interval.OnDemand' selected.
                this.elements.weeklySendOccurrenceDropdown().should("not.exist");

                this.elements.intervalDropdown().selectDropdownValue("Daily");
                // Check that 'Occurrence Time' dateInput is displayed when 'Interval.Daily' selected.
                this.elements.sendTimeDateInput().should("exist");
                // Check that 'Weekly Occurrence' dropdown is not displayed when 'Interval.Daily' selected.
                this.elements.weeklySendOccurrenceDropdown().should("not.exist");

                this.elements.intervalDropdown().selectDropdownValue("Weekly");
                // Check that 'Occurrence Time' dateInput is displayed when 'Interval.Weekly' selected.
                this.elements.sendTimeDateInput().should("exist");
                // Check that 'Weekly Occurrence' dropdown is displayed when 'Interval.Weekly' selected.
                this.elements.weeklySendOccurrenceDropdown().should("exist");
                this.elements.weeklySendOccurrenceDropdown().selectMultiselectDropdownValue(["Sunday", "Monday", "Friday"]);

                const dateTime: Date = new Date('July 14, 2024 23:30');
                this.elements.sendTimeDateInput().selectDatePickerTimeOnly(dateTime);

                executeWithIntercept(() => {
                        this.elements.saveButton().click();
                    },
                    [(global) ? this.routes.saveGlobalNotificationSetting : this.routes.saveUserNotificationSetting]);

                break;
            }
            default:
                throw new Error(`Unsupported notification type ${notificationType}`);
        }
    }
}

export default new NotificationSettings();
