import {FileModel} from "@renta-apps/athenaeum-toolkit";
import ReportDefinitionItem from "./ReportDefinitionItem";
import {FaultLevel} from "@/models/Enums";

export default class QuestionReportItem extends ReportDefinitionItem {

    public ok: boolean | null = null;

    public commented: boolean = false;

    public comment: string = "";
    
    //OBSOLUTE: DO NOT SET THIS VALUE
    public picture : FileModel | null = null;

    public pictures: FileModel[] | null = null;

    public fixed: boolean = false;

    public invoiced: boolean | null = null;

    public faultLevel: FaultLevel | null = null;
    
    public static isValid(item: QuestionReportItem): boolean {
        return (item.ok != null) && ((!item.commented) || (!!item.comment));
    }
}