import DashboardPage from "@/tests/pages/DashboardPage";
import DeviceInfoPanel from "@/tests/pages/DeviceInfoPanel";
import ReturnInspectionPage from "@/tests/pages/ReturnInspectionPage";
import DevicePage from "@/tests/pages/DevicePage";

export {}

describe('Device page tests', () => {
    before(() => {
    })

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    })

    it('Device out of annual inspection scope should not have AA button', () => {
        DashboardPage.openDevice("92253");
        DevicePage.assertAnnualInspectionBtnNotExists();
    });

    it('Device in annual inspection scope should have AA button', () => {
        DashboardPage.openDevice("900112");
        DevicePage.assertAnnualInspectionBtnExists();

    });

    // InStock Tests
    it('In Rent - Displays correct buttons', () => {
        DashboardPage.openDevice("90740");
        DevicePage.assertStandardButtonsVisible();
    })

    // InRent
    it('Displays correct buttons', () => {
        DashboardPage.openDevice("90740");
        DevicePage.assertStandardButtonsVisible();
        DevicePage.assertCommonButtonsVisible();
    })

    // Need Return Inspection
    it('Displays correct buttons', () => {
        DashboardPage.openDevice("90739");

        DevicePage.assertStartInspectionBtnVisible();

        DevicePage.assertSkipServiceBtnVisible();

        DevicePage.assertStandardButtonsVisible();
    })

    it('Displays product group name', () => {
        DashboardPage.openDevice("90739");
        DeviceInfoPanel.assertProductGroupNameMatch("MEGA LIFT");
    })

    it('Displays product group number', () => {
        DashboardPage.openDevice("90739");
        DeviceInfoPanel.assertProductGroupNameMatch("16810");
    })

    it('"Device will be locked" modal is shown when starting return inspection if the annual inspection is overdue', () => {
        DashboardPage.openDevice("9002");
        DevicePage.startReturnInspection();
        ReturnInspectionPage.elements.nextButton().realClick();
        DevicePage.assertActionModalVisible();
    })

    // Need service
    it('Displays correct buttons', () => {
        DashboardPage.openDevice("96532");
        DevicePage.assertStartServiceBtnVisible();
        DevicePage.assertSkipServiceBtnVisible();
        DevicePage.assertCommonButtonsVisible()
    })

    it('Can skip service tests', () => {
        DashboardPage.openDevice("96532");
        DevicePage.skipService("testing");
    })

    it('"Device will be locked" modal will be shown when starting service if device will be locked', () => {
        DashboardPage.openDevice("9001");
        DevicePage.startServiceDeviceLocked();
        DevicePage.assertActionModalVisible()
    })

    it('Clicking device picture redirects to latest return inspection preview', () => {
        DashboardPage.openDevice("ThisIsId");
        DevicePage.clickDevicePicture();
        ReturnInspectionPage.elements.shareButton().should('be.visible');
    })
})