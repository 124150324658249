import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import DeviceOperatingHoursRecordResponse, {IOperatingHoursRecord} from "@/models/server/DeviceOperatingHourRecordsResponse";
import {Button, ButtonType, PageContainer} from "@renta-apps/athenaeum-react-components";
import {RecordOperatingHoursSource} from "@/models/Enums";
import moment from "moment/moment";
import OperatingHourRecordsTable from "@/components/OperatingHourRecordsTable/OperatingHourRecordsTable";
import OperatingHourRecordsService from "@/services/OperatingHourRecordsService";
import RentaToolsController from "@/pages/RentaToolsController";
import CsvService from "@/services/CsvService";
import Localizer from "@/localization/Localizer";

import styles from './OperatingHourRecordsPage.module.scss';
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

export interface IOperatingHourRecordsPageProps{
}

export interface IOperatingHourRecordsPageState{
    operatingHours : IOperatingHoursRecord[];
    isLoading : boolean;
}

export default class OperatingHourRecordsPage extends AuthorizedPage<IOperatingHourRecordsPageProps, IOperatingHourRecordsPageState> {

    state: IOperatingHourRecordsPageState = {
        operatingHours: [],
        isLoading: false,
    }

    async initializeAsync(): Promise<void> {
        const deviceId = RentaToolsController.device?.id!;

        this.setState({
            isLoading: true
        });

        const response: DeviceOperatingHoursRecordResponse = await OperatingHourRecordsService.getOperatingHoursRecordsByDeviceId(deviceId);

        if (response.records.length > 0) {
            this.setState({
                operatingHours: response.records
            });
        }

        this.setState({
            isLoading: false
        });

        return super.initializeAsync();
    }

    private getSourceType(sourceType: RecordOperatingHoursSource) {

        switch (sourceType) {
            case RecordOperatingHoursSource.Service:
                return Localizer.operatingHoursHistoryPageSourceTypeService;
            case RecordOperatingHoursSource.ReturnInspection:
                return Localizer.operatingHoursHistoryPageSourceTypeRI
            case RecordOperatingHoursSource.AnnualInspection:
                return Localizer.operatingHoursHistoryPageSourceTypeAnnualInspection;
            default:
                throw new Error("Unknown type of operating hours source");
        }

    }

    public downloadCsvFile() {
        this.setState({isLoading: true});

        const dataArray = this.state.operatingHours.map((hours) => [moment(hours.recordDate).format("YYYY-MM-DD HH:MM:SS"), hours.operatingHours, this.getSourceType(hours.sourceType)]);

        dataArray.unshift(["CreatedAt", "OperatingHours", "Source"])

        //TODO Add header to the PoeEditor.
        //Reason: Maria need this format for her predictions algorithm. It cannot changed for now based on language.

        CsvService.createCsvFileAndDownload(dataArray, RentaToolsController.device?.externalId!);

        this.setState({isLoading: false});
    }

    render() {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(styles.adBlues)}>
                <div className={styles.pageHeader}>
                    <Button
                        onClick={async () => this.downloadCsvFile()}
                        disabled={this.state.isLoading}
                        icon={{name: "fas fa-download"}}
                        type={ButtonType.Orange}/>
                </div>
                <div>
                    {!this.state.isLoading && (
                        <OperatingHourRecordsTable records={this.state.operatingHours}/>
                    )}
                </div>
            </PageContainer>
        )
    }
}