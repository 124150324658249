import React from "react";
import {Button, ButtonContainer, ButtonType, Form, IStringInputModel, PageContainer, PageHeader, PageRow, TextInput} from "@renta-apps/athenaeum-react-components";
import {LoginResultStatus} from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import ForgotPasswordRequest from "../../models/server/requests/ForgotPasswordRequest";
import Localizer from "../../localization/Localizer";

import styles from "./ForgotPasswordPage.module.scss";

export default class ForgotPasswordPage extends AnonymousPage {

    private _usernameInput: IStringInputModel = {value: ""};
    private _formRef: React.RefObject<Form> = React.createRef();

    private async handleSubmitAsync(): Promise<void> {
        const request = new ForgotPasswordRequest(this._usernameInput.value);
        await this.postAsync<LoginResultStatus>("api/Application/ForgotPassword", request);
    }

    public getTitle(): string {
        return Localizer.forgotPasswordPagePageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>
                <PageHeader title={Localizer.forgotPasswordPagePageTitle} subtitle={Localizer.forgotPasswordPageHelpText} className={styles.header}/>

                <PageRow>
                    <div className="col-lg-6">

                        <Form id="form" onSubmit={async () => await this.handleSubmitAsync()} ref={this._formRef}>
                            <TextInput id="username" label={Localizer.forgotPasswordPageUsernameInput} model={this._usernameInput} required/>

                            <ButtonContainer>
                                <Button type={ButtonType.Orange} label={Localizer.forgotPasswordPageResetButton} submit/>
                                <Button type={ButtonType.Default} label={Localizer.forgotPasswordPageLoginButton} route={PageDefinitions.loginRoute}/>
                            </ButtonContainer>

                        </Form>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}