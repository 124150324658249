import React from "react";
import {BaseComponent, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Modal} from "@renta-apps/athenaeum-react-components";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaToolsController from "@/pages/RentaToolsController";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import Localizer from "@/localization/Localizer";

import styles from "./NavigationModule.module.scss";

interface INavigationModuleModalProps {
    steps : ReportDefinitionItem[];
}

interface INavigationModuleModalState {
    phases : ReportDefinitionItem[][];
}

export default class NavigationModuleModal extends BaseComponent<INavigationModuleModalProps, INavigationModuleModalState> {
    private readonly _modalRef: React.RefObject<Modal> = React.createRef();

    public async initializeAsync(): Promise<void> {
        const phases = this.props.steps.groupBy(p => p.phase?.id);
        await this.setState({
            phases: phases
        })
    }

    public async openAsync(): Promise<void> {
        await this._modalRef.current!.openAsync();
    }

    private async openStepAsync(step: ReportDefinitionItem): Promise<void> {
        if (!this.preview) {
            const index = this.props.steps.indexOf(step);
            await ReturnInspectionController.openStepAsync(index);
        }
    }

    protected get preview(): boolean {
        return RentaToolsController?.preview === true;
    }

    private async openSummaryAsync(): Promise<void> {
        if (!this.preview) {
            const route = PageDefinitions.summaryRoute;
            await PageRouteProvider.redirectAsync(route);
        }
    }

    public get phases(): ReportDefinitionItem[][] {
        return this.state.phases;
    }

    public isFirstPhaseItem(phase: ReportDefinitionItem[], item: ReportDefinitionItem): boolean {
        return phase.indexOf(item) === 0;
    }

    public render(): React.ReactNode {

        return (
            <Modal id={this.id} title={Localizer.navigationModalTitle} ref={this._modalRef} info={true}>
                <div className={styles.progressWrapper}>
                    <div className={styles.progressLine}/>
                    {this.phases.map((phase) => (
                        phase.map((p) => (
                            <div className={styles.progressInfo} onClick={async () => await this.openStepAsync(p)} key={p.id}>
                                <div className={this.css(styles.progressCircle, p.completed && styles.stepCompleted)}/>
                                <div className={styles.progressText}>
                                    {this.isFirstPhaseItem(phase, p) && (<p className={styles.progressPhase}>{p.phase?.name}</p>)}
                                    <p>{p.name}</p>
                                </div>
                            </div>
                        ))))}
                    <div className={styles.progressInfo} onClick={async () => await this.openSummaryAsync()}>
                        <div className={styles.progressCircle}/>
                        <div className={this.css(styles.progressText, styles.bold)}>
                            <p>{Localizer.navigationModuleSummary}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
};