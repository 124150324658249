class CsvService
{
    private readonly _contentType = "text/csv;charset=utf-8;";
    
    //This method changes was requsted by Maria for her algorithm to predictate next service
    private createCsvContent(data : (any)[][]){
        return data.map(row => (
                row.map(z => {
                    if(["CreatedAt", "OperatingHours", "Source"].includes(z)){
                        return z; 
                    }
                    
                    if(z instanceof String || typeof z === 'string'){
                        console.log(z);
                        const str = z.replaceAll('"', '""');
                        return `"${str}"`;
                    }
                    
                    return z;
                }).join(',')
            )
        ).join('\r\n');  
    }
    
    public createCsvFileAndDownload(data :(any)[][], fileName : string) {
        const content = this.createCsvContent(data);

        const blob = new Blob([content], {type: this._contentType});

        const link = document.createElement('a');

        const linkHref = URL.createObjectURL(blob);
        link.href = linkHref;
        link.style.visibility = 'hidden';
        link.download = `${fileName}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(linkHref);
    }
}

export default new CsvService();