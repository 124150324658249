import React from "react";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

import styles from "./ToolsPageHeader.module.scss";
import {Utility} from "@renta-apps/athenaeum-toolkit";

export interface IToolsPageHeaderProps{
    title: string;
    stickyHeader? : boolean;
    id?: string;
}

const ToolsPageHeader = (props: IToolsPageHeaderProps) => {
    return(
        <div id={props.id ?? "page_header"} className={Utility.css(styles.headerStyles, props.stickyHeader && rentaToolsStyles.stickyHeader)}>
            {props.title}
        </div>
    )
}

export default ToolsPageHeader;