import Category from "./Category";

export default class Mapping {

    public reportDefinitionId: string | null = "";

    public reportDefinitionName: string = "";

    public reportDefinitionStepAmount: number = 0;

    public isDeleted: boolean = false;

    public categories: Category[] = [];

    public isMapping: boolean = true;
}