import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonType, CellAction, CellModel, Checkbox, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, GridOddType, IconSize, Inline, JustifyContent, RowModel, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import {BaseComponent, ch, TextAlign} from "@renta-apps/athenaeum-react-common";
import {ActionType} from "@/models/Enums";
import AdditionalExpense from "@/models/server/AdditionalExpense";
import AddAdditionalExpenseRequest from "@/models/server/requests/AddAdditionalExpenseRequest";
import SaveAdditionalExpenseRequest from "@/models/server/requests/SaveAdditionalExpenseRequest";
import SaveAdditionalExpenseResponse from "@/models/server/responses/SaveAdditionalExpenseResponse";
import DeleteResourceTypeResponse from "@/models/server/responses/DeleteResourceTypeResponse";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import Localizer from "@/localization/Localizer";

import styles from "./AdditionalExpensesData.module.scss";

interface IAdditionalExpensesDataProps {
}

interface IAdditionalExpensesDataState {
    genericAdditionalExpenseExist: boolean;
    showDeleted: boolean;
}

export default class AdditionalExpensesData extends BaseComponent<IAdditionalExpensesDataProps, IAdditionalExpensesDataState> {

    state: IAdditionalExpensesDataState = {
        genericAdditionalExpenseExist: false,
        showDeleted: false
    };

    private readonly _additionalExpensesGridRef: React.RefObject<Grid<AdditionalExpense>> = React.createRef();

    private readonly _additionalExpensesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 40,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpensesPageGridNameLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.name),
            minWidth: 180,
            maxWidth: 180,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            },
            reRenderRow: true,
            init: (cell) => this.initNameCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpensesPageGridProductExternalIdLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.productExternalId),
            minWidth: 100,
            maxWidth: 100,
            type: ColumnType.Text,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            },
            reRenderRow: true,
            init: (cell) => this.initProductExternalIdCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpensesPageGridUnitLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.unit),
            format: "ExpenseUnit",
            type: ColumnType.Enum,
            minWidth: 145,
            settings: {
                noFilter: true,
            },
            init: (cell) => this.initUnitCell(cell),
            callback: async (cell) => await this.onChangeUnitAsync(cell)
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpensesPageGridMinLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.min),
            type: ColumnType.Number,
            format: "0.00",
            editable: true,
            minWidth: 80,
            maxWidth: 80,
            settings: {
                min: 0.01,
                max: RentaToolsConstants.maxResourceValue - 1,
                step: 0.01,
                arrows: true,
            },
            init: (cell) => this.initMinCell(cell)
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpensesPageGridMaxLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.max),
            type: ColumnType.Number,
            format: "0.00",
            editable: true,
            minWidth: 80,
            maxWidth: 80,
            settings: {
                max: RentaToolsConstants.maxResourceValue,
                step: 0.01,
                arrows: true,
            },
            reRenderRow: true,
            init: (cell) => this.initMaxCell(cell),
            callback: async (cell) => await this.onMaxChange(cell),
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpensesPageGridDefaultValueLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.defaultValue),
            type: ColumnType.Number,
            format: "0.00",
            editable: true,
            minWidth: 80,
            maxWidth: 80,
            settings: {
                min: 0,
                max: RentaToolsConstants.maxResourceValue,
                step: 0.01,
                arrows: true,
            },
            reRenderRow: true,
            init: (cell) => this.initDefaultValueCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpensesPageGridStepLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.step),
            type: ColumnType.Number,
            format: "0.00",
            editable: true,
            minWidth: 70,
            maxWidth: 70,
            settings: {
                step: 0.01,
                arrows: true,
            },
            init: (cell) => this.initStepCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.resourceTypeIsInvoiceableLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.isInvoiceable),
            transform: (_, value) => value ? "✓" : "",
            minWidth: 100,
            maxWidth: 150,
            type: ColumnType.Boolean,
            textAlign: TextAlign.Center,
            editable: true,
            noWrap: true,
            settings: {
                required: true
            },
            init: (cell) => this.initInvoiceableCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.additionalExpenseTypeGenericLanguageItemName,
            accessor: nameof.full<AdditionalExpense>(w => w.isGeneric),
            transform: (_, value) => value ? "✓" : "",
            minWidth: 50,
            maxWidth: 100,
            type: ColumnType.Boolean,
            textAlign: TextAlign.Center,
            editable: true,
            noWrap: true,
            reRenderRow: true,
            init: (cell) => this.initGenericCell(cell),
        } as ColumnDefinition,
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initAdditionalExpenseOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processAdditionalExpenseOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.genericCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processAdditionalExpenseOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.genericDeletedLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processAdditionalExpenseOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.genericRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processAdditionalExpenseOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get additionalExpensesGrid(): GridModel<AdditionalExpense> {
        return this._additionalExpensesGridRef.current!.model;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.additionalExpensesGrid.rows.some(row => !row.deleted && !row.model.id));
    }

    private hasGenericAdditionalExpense(additionalExpenses: AdditionalExpense[]): boolean {
        return (additionalExpenses.some(item => item.isGeneric && !item.isDeleted));
    }

    private initRow(row: RowModel<AdditionalExpense>): void {
        const model: AdditionalExpense = row.model;
        const isNew: boolean = (!model.id);
        const isValid: boolean = this.isValid(model);
        row.deleted = model.isDeleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private initNameCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleNameLanguageItemName);
    }

    private initProductExternalIdCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleExternalIdLanguageItemName);
    }

    private initUnitCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleUnitLanguageItemName);
    }

    private initMinCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleMinLanguageItemName, cell.model.unit);
    }

    private initMaxCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleMaxLanguageItemName, cell.model.unit);

        const minCell: CellModel<AdditionalExpense> = cell.row.get("min");
        cell.column.settings.min = minCell.column.settings.min;
    }

    private initDefaultValueCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleDefaultValueLanguageItemName);
    }

    private initStepCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleStepLanguageItemName);
    }

    private initInvoiceableCell(cell: CellModel<AdditionalExpense>): void {
        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleInvoiceableLanguageItemName);
    }

    private initGenericCell(cell: CellModel<AdditionalExpense>): void {
        const model: AdditionalExpense = cell.row.model;

        cell.title = Localizer.get(Localizer.additionalExpensesPageGridTitleGenericLanguageItemName);
        cell.visible = model.isGeneric || !this.state.genericAdditionalExpenseExist;
    }

    private isValid(additionalExpense: AdditionalExpense): boolean {
        return (!!additionalExpense.name.trim()) &&
            (!!additionalExpense.productExternalId.trim()) &&
            (additionalExpense.unit != null) &&
            (additionalExpense.step > 0 && additionalExpense.step <= additionalExpense.max) &&
            (additionalExpense.min > 0) &&
            (additionalExpense.max > additionalExpense.min) &&
            (additionalExpense.defaultValue >= additionalExpense.min && additionalExpense.max >= additionalExpense.defaultValue);
    }

    private getDeleteConfirmation(cell: CellModel<AdditionalExpense>): string {
        const model: AdditionalExpense = cell.model;
        const isNew: boolean = !model.id;

        return (isNew)
            // Grid types require returned value to be a string, but has been like this forever
            ? null as any
            : Utility.format(Localizer.additionalExpensesPageConfirmationButtonDelete, `'${cell.model.name} (${cell.model.productExternalId})'`);
    }

    private async getAdditionalExpensesAsync(): Promise<AdditionalExpense[]> {
        let additionalExpenses: AdditionalExpense[] = await this.additionalExpensesGrid.postAsync("api/classifier/getAdditionalExpenses", null);

        if (!this.state.showDeleted) {
            additionalExpenses = additionalExpenses.filter(item => !item.isDeleted)
        }

        const genericAdditionalExpenseExist: boolean = additionalExpenses.some(item => item.isGeneric && !item.isDeleted);

        await this.setState({genericAdditionalExpenseExist});

        return additionalExpenses;
    }

    private async addAdditionalExpenseAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const additionalExpense = new AdditionalExpense();

            const newRows: RowModel<AdditionalExpense>[] = await this.additionalExpensesGrid.insertAsync(0, additionalExpense);

            const newRow: RowModel<AdditionalExpense> = newRows[0];
            const nameCell: CellModel<AdditionalExpense> = newRow.get("name");

            await nameCell.editAsync(true);
        }
    }

    private async onMaxChange(cell: CellModel<AdditionalExpense>): Promise<void> {
        const stepCell: CellModel<AdditionalExpense> = cell.row.get("step");
        stepCell.column.settings.max = cell.model.max;
    }

    private async onChangeUnitAsync(cell: CellModel<AdditionalExpense>): Promise<void> {
        const model: AdditionalExpense = cell.row.model;
        const suggestedStepValue: number = AdditionalExpense.getSuggestedStep(model.unit);

        if (!model.id) {
            model.step = suggestedStepValue;

            if (model.min < model.step) {
                model.min = suggestedStepValue;
            }
        }
    }

    private async initAdditionalExpenseOperationsAsync(cell: CellModel<AdditionalExpense>): Promise<void> {

        const model: AdditionalExpense = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<AdditionalExpense> = cell.actions[0];
        const cancelAction: CellAction<AdditionalExpense> = cell.actions[1];
        const deleteAction: CellAction<AdditionalExpense> = cell.actions[2];
        const restoreAction: CellAction<AdditionalExpense> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    private async processAdditionalExpenseOperationAsync(cell: CellModel<AdditionalExpense>, action: CellAction<AdditionalExpense>): Promise<void> {

        await ch.hideAlertAsync();

        const model: AdditionalExpense = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {
                const request = new AddAdditionalExpenseRequest();
                request.name = model.name;
                request.productExternalId = model.productExternalId;
                request.unit = model.unit;
                request.min = model.min;
                request.max = model.max;
                request.defaultValue = model.defaultValue;
                request.step = model.step;
                request.isGeneric = model.isGeneric;
                request.isInvoiceable = model.isInvoiceable;

                const response: SaveAdditionalExpenseResponse = await cell.grid.postAsync("api/classifier/addAdditionalExpense", request);

                if (response.additionalExpenseAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.additionalExpensesPageAlertErrorAsyncExpenseExist, model.name, model.productExternalId), true);
                    return;
                }

                if (response.genericAdditionalExpenseAlreadyExists) {
                    await ch.alertErrorAsync(Localizer.additionalExpensesDataAlertErrorGenericAlreadyExists, true);
                    return;
                }

                cell.row.model = response.additionalExpense!;

                await cell.row.saveAsync();

            } else {
                const request = new SaveAdditionalExpenseRequest();
                request.id = model.id;
                request.name = model.name;
                request.unit = model.unit;
                request.min = model.min;
                request.max = model.max;
                request.defaultValue = model.defaultValue;
                request.step = model.step;
                request.productExternalId = model.productExternalId;
                request.isGeneric = model.isGeneric;
                request.isInvoiceable = model.isInvoiceable;

                const response: SaveAdditionalExpenseResponse = await cell.grid.postAsync("api/classifier/saveAdditionalExpense", request);

                if (response.additionalExpenseAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.additionalExpensesPageAlertErrorAsyncExpenseExist, model.name, model.productExternalId), true);
                    return;
                }

                if (response.genericAdditionalExpenseAlreadyExists) {
                    await ch.alertErrorAsync(Localizer.additionalExpensesDataAlertErrorGenericAlreadyExists, true);
                    return;
                }

                cell.row.model = response.additionalExpense!;
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {
                const response: DeleteResourceTypeResponse = await cell.grid.postAsync("api/classifier/deleteAdditionalExpense", model.id);

                if (response.success) {
                    model.isDeleted = true;

                    await cell.row.setDeletedAsync(true);
                } else {
                    await ch.alertErrorAsync(Localizer.alertMessageResourceTypeCannotDelete.format(model.name, response.reportDefinitionsContainingResource.join(', ')));
                }
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                const restored: boolean = await cell.grid.postAsync("api/classifier/restoreAdditionalExpense", model.id);

                if (restored) {
                    model.isDeleted = false;

                    await cell.row.setDeletedAsync(false);
                } else {
                    await ch.alertErrorAsync(Localizer.additionalExpensesDataAlertErrorRestoreFailed, true);
                }
            }
        }

        const genericAdditionalExpenseExist: boolean = this.hasGenericAdditionalExpense(this.additionalExpensesGrid.rows.map(row => row.model));

        await this.setState({genericAdditionalExpenseExist});
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.additionalExpensesGrid.reloadAsync();
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.container}>

                <ToolbarContainer className={styles.toolbar}>


                    <Inline justify={JustifyContent.End}>

                        <Checkbox inline
                                  id={'showDeletedAdditionalExpenses'}
                                  label={Localizer.genericShowDeleted}
                                  value={this.state.showDeleted}
                                  onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                        />

                        <Button id={'reloadAdditionalExpenses'}
                                title={Localizer.genericReload}
                                className="ml-1"
                                icon={{name: "far history", size: IconSize.Large}}
                                type={ButtonType.Info}
                                onClick={async () => await this.reloadAsync()}
                        />

                        <Button id={'addAdditionalExpense'}
                                icon={{name: "plus", size: IconSize.Large}}
                                type={ButtonType.Orange}
                                title={Localizer.additionalExpensesPageButtonAddExpense}
                                onClick={async () => await this.addAdditionalExpenseAsync()}
                        />

                    </Inline>

                </ToolbarContainer>

                <Grid responsive
                      id={'additionalExpensesGrid'}
                      className={styles.additionalExpensesGrid}
                      ref={this._additionalExpensesGridRef}
                      hovering={GridHoveringType.EditableCell}
                      odd={GridOddType.None}
                      minWidth="auto"
                      noDataText={Localizer.genericNoData}
                      columns={this._additionalExpensesColumns}
                      initRow={(row) => this.initRow(row)}
                      fetchData={async (_) => await this.getAdditionalExpensesAsync()}
                />

            </div>
        );
    }
}