export default class AddWashingTypeRequest {
    public name: string = "";

    public productExternalId: string = "";

    public equipmentType: string = "";

    public hourlyInvoiced: boolean = false;

    public isInvoiceable: boolean = false;
}