export default class Power {
    public external: External = new External();
    public internal: Internal = new Internal();
}

export class External {
    public supply: number = 0;
    public isExternalPower: boolean = false;
}

export class Internal {
    public supply: number = 0;
    public percentage: number = 0;
    public charging: boolean = false;
    public isInternalPower: boolean = false;
}