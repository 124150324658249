import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import User from "./User";

export default class Depo
{
    public id: string = "";

    public name: string | null = null;

    public costPool: string | null = null;

    public location: GeoLocation | null = null;

    public phone: string | null = null;

    public email: string | null = null;

    public deleted: boolean = false;

    public deletedAt: Date | null = null;

    public deletedBy: User | null = null;

    public isDepo: boolean = true;
}