import React from "react";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {ch, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, GoogleMap, IGoogleMapMarker, PageContainer, PageHeader, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Report from "../Models/Report";
import QuestionPicturesReportItem from "../Models/QuestionPicturesReportItem";
import ArsenalPicturesCarousel from "../../components/ArsenalPicturesCarousel/ArsenalPicturesCarousel";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Device from "../Models/Device";
import DevicePicture from "@/models/server/DevicePicture";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./ObservationPreviewPage.module.scss";
import ToolsUtility from "@/helpers/ToolsUtility";
import DeviceContract from "@/models/server/DeviceContract";
import User from "@/models/server/User";
import DeviceFault from "@/pages/Models/DeviceFault";
import EndpointPaths from "@/common/EndpointPaths";
import PageDefinitions from "@/providers/PageDefinitions";
import ObservationDetailsResponse from "@/models/server/responses/ObservationDetailsResponse";
import GetObservationDetailsRequest from "@/models/server/requests/GetObservationDetailsRequest";

export interface IObservationPreviewPageParams {
    observationId: string | null;
    deviceId: string | null;
}

interface IObservationPreviewPageProps {
    observationId: string | null;
    deviceId: string | null;
}

interface IObservationPreviewPageState {
    latitude: number | null;
    longitude: number | null;
    contract: DeviceContract | null;
    faults: DeviceFault[];
    recordDate: Date | null;
    comment: string;
    user: User | null;
    report: Report | null;
    picture: DevicePicture | null;
    reportItem: QuestionPicturesReportItem | null;
    pictures: FileModel[],
}

export default class ObservationPreviewPage extends AuthorizedPage<IObservationPreviewPageProps, IObservationPreviewPageState> {
    state: IObservationPreviewPageState = {
        latitude: null,
        longitude: null,
        contract: null,
        faults: [],
        recordDate: null,
        comment: "",
        user: null,
        picture: null,
        reportItem: null,
        report: null,
        pictures: [],
    };


    private get title(): string {
        return `${this.device.externalId} ${this.device.name}\n
        ${(this.report != null)
            ? "{0} {1}".format(Localizer.reportReport, ToolsUtility.toDateString(this.report.completedAt))
            : (this.devicePictureItem != null)
                ? "{0} {1}".format(Localizer.enumDevicePictureTypeManual, ToolsUtility.toDateString(this.devicePictureItem.createdAt))
                : ""}`;
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get report(): Report | null {
        return this.state.report;
    }

    private get devicePictureItem(): DevicePicture | null {
        return this.state.picture;
    }

    private get reportItem(): QuestionPicturesReportItem | null {
        return this.state.reportItem;
    }

    private get pictures(): FileModel[] {
        return this.state.pictures;
    }

    private get pictureSubtitle(): string {
        return ((this.report != null) && (this.report.name != null))
            ? Localizer.get(Localizer.picturesPreviewPageArsenalPicturesCarouselSubtitle, this.report.name)
            : Localizer.enumDevicePictureTypeManual;
    }

    private get hasLocation(): boolean {
        return this.state.latitude != null && this.state.longitude != null;
    }

    private get location(): IGoogleMapMarker[] {
        return [
            {
                position: {
                    lat: this.latitude ?? 0,
                    lng: this.longitude ?? 0
                }
            }
        ];
    }

    private get user(): User | null {
        return this.state.user;
    }

    private get userName(): string {
        if (!this.state.user) {
            return "-";
        }

        if (this.state.user.firstName && this.state.user.lastName) {
            return `${this.state.user.firstName} ${this.state.user.lastName}`;
        }

        if (this.state.user.username) {
            return this.state.user.username;
        }

        return "-";
    }

    private get observationDate(): Date | null {
        return this.state.recordDate;
    }

    private get contract(): DeviceContract | null {
        return this.state.contract;
    }

    private get faults(): DeviceFault[] {
        return this.state.faults;
    }

    private get latitude(): number {
        return this.state.latitude!;
    }

    private get longitude(): number {
        return this.state.longitude!;
    }

    private get hasFaults(): boolean {
        return this.state.faults ? this.state.faults.length > 0 : false;
    }

    private get hasComment(): boolean {
        return !!this.state.comment;
    }

    private get comment(): string {
        return this.state.comment;
    }

    public getManual(): string {
        return Localizer.picturesPreviewPageManual;
    }

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (ToolsUtility.isNullOrEmpty(this.typedParameters?.observationId)) {
            return
        }

        let observationDetails: ObservationDetailsResponse = await this.postAsync(EndpointPaths.DevicePaths.GetObservationDetails, {
            observationId: this.typedParameters?.observationId,
            deviceId: this.typedParameters?.deviceId,
        } as GetObservationDetailsRequest);

        if (!observationDetails) {
            await ch.alertErrorAsync(Localizer.genericFileNotFound);
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute);
        }

        const report: Report | null = observationDetails!.returnInspectionReport;
        let reportItem: QuestionPicturesReportItem | null = null;

        if (report != null && observationDetails.returnInspectionStepId != null) {
            reportItem = Report.getQuestionPicturesReportItemByStepId(observationDetails.returnInspectionStepId, report);

            if (reportItem == null) {
                throw Error(Localizer.picturesPreviewPageErrorPicturesCannotBeFound);
            }

            observationDetails.comment ??= reportItem.comment;
        }
        else if (report != null && observationDetails.pictures.length > 0) {

            const picture = observationDetails.pictures[0];

            reportItem = Report.getQuestionPicturesReportItemByFileId(picture.id, report);

            observationDetails.comment ??= reportItem.comment;
        }

        this.setState({
            faults: observationDetails.faults,
            contract: observationDetails.contract,
            latitude: observationDetails.latitude,
            longitude: observationDetails.longitude,
            recordDate: observationDetails.recordDate,
            comment: observationDetails.comment ?? "",
            user: observationDetails.createdBy,
            reportItem: reportItem,
            report: report,
            pictures: observationDetails.pictures,
        })
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.picturesPreview)}>

                <PageHeader title={this.title} className={rentaToolsStyles.leftPageHeader}>

                    <span className={rentaToolsStyles.rightPageHeader}/>

                </PageHeader>

                {
                    (this.reportItem) && (
                        <span className={rentaToolsStyles.wizardDescription}>{this.reportItem.name}</span>
                    )
                }

                <ArsenalPageRow className={styles.pictureContainer}>

                    {
                        (this.pictures && this.pictures.length > 0) && (
                            <ArsenalPicturesCarousel subtitle={this.pictureSubtitle}
                                                     items={this.pictures}
                            />
                        )
                    }


                    {
                        (this.reportItem) && (this.reportItem.commented) &&
                        (
                            <ArsenalPageRow>
                                <span className={styles.comment}>{this.reportItem.comment}</span>
                            </ArsenalPageRow>
                        )
                    }

                    {
                        (this.hasComment) &&
                        (
                            <>
                                <br/>
                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.invoiceDetailsPageComment} </span>

                                <TextAreaInput readonly
                                               className={styles.observationComment}
                                               value={this.comment}/>

                            </>
                        )
                    }

                    {
                        (this.hasLocation) && (
                            <div className={this.css("col-md-12")}>
                                <br/>

                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.deviceServicePageLocationHeader} </span>

                                <GoogleMap height={500}
                                           initialCenter={{lat: this.latitude, lng: this.longitude}}
                                           initialZoom={10}
                                           markers={this.location}
                                />
                            </div>
                        )
                    }

                    {
                        (this.contract) && (
                            <>
                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.genericContractDetails} </span>

                                <table className={this.css(styles.table, "table table-striped")}>
                                    <tbody>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageCustomerName}</td>
                                        <td>{"{0}".format(this.contract.customerName || "-")}</td>
                                    </tr>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageConstructionSite}</td>
                                        <td>{"{0}".format(this.contract.constructionSiteName || "-")}</td>
                                    </tr>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageContractId}</td>
                                        <td>{"{0}".format(this.contract.contractExternalId)}</td>
                                    </tr>
                                    <tr>
                                        <td>{Localizer.invoiceDetailsPageConstructionSiteNumber}</td>
                                        <td>{this.contract.constructionSiteExternalId ? "{0}".format(this.contract.constructionSiteExternalId) : "-"}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </>
                        )
                    }

                    {
                        (this.hasFaults) && (
                            <>
                                <span className={rentaToolsStyles.wizardDescription}> {Localizer.deviceServicePageFaultsHeader} </span>

                                <table className={this.css(styles.table, "table table-striped")}>
                                    <tbody>

                                    {
                                        (this.observationDate) && (
                                            <tr>
                                                <td>{Localizer.genericObservationDate}</td>
                                                <td>{ToolsUtility.toDateStringWithTime(this.observationDate, "", "", "HH:mm")}</td>
                                            </tr>
                                        )
                                    }

                                    {
                                        (this.user) && (
                                            <tr>
                                                <td>{Localizer.userManagementPageGridCreatedBy}</td>
                                                <td>{this.userName}</td>
                                            </tr>
                                        )
                                    }

                                    {
                                        this.faults.map((fault, index) => {
                                            return (
                                                <tr>
                                                    <td>{Localizer.genericManualFault}</td>
                                                    <td>{fault.comment}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </>
                        )
                    }

                </ArsenalPageRow>


                <ArsenalPageRow>
                    <ArsenalButton big block
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   icon={{name: "fas chevron-left"}}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                </ArsenalPageRow>

            </PageContainer>
        );
    }
}