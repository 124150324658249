import {ExpenseUnit} from "@/models/Enums";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

export default class AdditionalExpenseValue {
    public typeId: string = RentaToolsConstants.defaultGuid;

    public name: string = "";

    public externalId: string = "";

    public value: number = 0;

    public invoiced: boolean | null = null;

    public isCustom: boolean = false;

    public unit: ExpenseUnit = ExpenseUnit.Piece;

    public price: number | null = null;

    public returnInspectionStepNumber: number | null = null;

    public isAdditionalExpenseValue: true = true;

    public static isValid(expenseValue: AdditionalExpenseValue): boolean {
        return (!!expenseValue.typeId &&
            expenseValue.typeId != RentaToolsConstants.defaultGuid &&
            expenseValue.name.trim().length > 0 &&
            expenseValue.externalId.trim().length > 0 &&
            expenseValue.value > 0 &&
            expenseValue.unit != null);
    }
}