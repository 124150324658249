import DashboardPage from "@/tests/pages/DashboardPage";
import DevicePage from "@/tests/pages/DevicePage";
import Localizer from "@/localization/Localizer";
import {executeWithIntercept} from "@/helpers/CypressHelper";
import RecordObservationPage from "@/tests/pages/RecordObservationPage";

export {}

describe('Record observation page tests', () => {
    before(() => {
    })

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    })

    it("Validates UI elements", () => {
        DashboardPage.openDevice("86973");
        executeWithIntercept(() => {
            DevicePage.elements.recordObservationBtn().click();
        }, [RecordObservationPage.routes.getDeviceContracts])

        RecordObservationPage.elements.subtitle().should('contain.text', Localizer.devicePagePicture.toPascalCase());

        RecordObservationPage.elements.imageInput();
        RecordObservationPage.elements.observationCommentInput();

        RecordObservationPage.elements.addFaultBtn().realClick();

        cy.wait(1000);

        RecordObservationPage.elements.deviceContractInput();
        RecordObservationPage.elements.observationDateInput();
        RecordObservationPage.elements.faultCommentInput(0);
        RecordObservationPage.elements.removeFaultBtn(0);
    })

    it("Confirms many faults can be added", () => {
        DashboardPage.openDevice("90739");

        DevicePage.elements.recordObservationBtn().trigger('click');

        RecordObservationPage.elements.addFaultBtn().realClick();
        cy.wait(300);
        RecordObservationPage.elements.addFaultBtn().click();
        cy.wait(300);
        RecordObservationPage.elements.addFaultBtn().click();
        cy.wait(300);

        RecordObservationPage.elements.faultCommentInput(0);
        RecordObservationPage.elements.faultCommentInput(1);
        RecordObservationPage.elements.faultCommentInput(2);

        RecordObservationPage.elements.removeFaultBtn(0);
        RecordObservationPage.elements.removeFaultBtn(1);
        RecordObservationPage.elements.removeFaultBtn(2);
    })

    it("Accepts images", () => {
        DashboardPage.openDevice("90739");

        DevicePage.elements.recordObservationBtn().trigger('click');

        RecordObservationPage.elements.imageInput()
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_small.png',
                fileName: 'test_picture_small.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, {force: true});

    })
})