import {Form, Switch, TextInput} from "@renta-apps/athenaeum-react-components";
import localStyles from './EditDeviceOrderMenu.module.scss';
import React, {useState} from "react";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";
import {DeviceShortView} from "@/models/server/DeviceShortView";

interface IEditDeviceOrderMenuProps {
    devices : DeviceShortView[];
    fixedOrder: boolean;
    onRentaIdChange: (rentaId: string) => void;
    onFixedOrderChange: (fixedOrder: boolean) => void;
}

const EditDeviceOrderMenu = ({onRentaIdChange, onFixedOrderChange, fixedOrder, devices } : IEditDeviceOrderMenuProps) => {
    const [rentaId, setRentaId] = useState<string>("");
    const [currentDevices, setDevices] = useState<DeviceShortView[]>([]);
    const getRentaIdValue = () => {
        onRentaIdChange(rentaId);
        setDevices([]);
    }
    
    const setRentaIdValue = (value : string) => {
        setRentaId(value);
        
        if(value.length > 0) {
            const filteredDevices = devices.filter(d => d.deviceExternalId.toLowerCase().includes(value.toLowerCase()));
            setDevices(filteredDevices);
        } else {
            setDevices([]);
        }
    }
    
    const selectDevice = (value : string) => {
        setRentaId(value);
        onRentaIdChange(value);
        setDevices([]);
    }
    
    return (
        <div className={localStyles.deviceOrderMenu}>
            <Form onSubmit={async () => getRentaIdValue()} className={localStyles.textInputWrapper}>
                <TextInput required
                    placeholder={Localizer.dashboardInsertRentaID}
                    id="deviceId" 
                    value={rentaId}
                    onChange={async (sender, value ) => setRentaIdValue(value)}
                />
                {currentDevices.length > 0 && (
                    <div className={localStyles.autoComplete}>
                        {currentDevices.map((device, index) => {
                            return (
                                <div key={index} onClick={() => selectDevice(device.deviceExternalId)}>
                                    {device.deviceExternalId}
                                </div>
                            )
                        })}
                </div>
                )}
            </Form>
            <div className={localStyles.switchButton}>
                <Switch id={"fixedOrder"} value={fixedOrder} onChange={async (s, checked) => onFixedOrderChange(checked)}/>
                <div>
                    <p className={localStyles.bold}>{Localizer.editDeviceOrderPageFixedOrder}</p>
                    <p>{Localizer.editDeviceOrderPageFixedOrderDescription}</p>
                </div>
            </div>
        </div>
    )
}
export default EditDeviceOrderMenu;