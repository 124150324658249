import CheckItem from "@/pages/Models/CheckItem";
import { Icon, IconSize } from "@renta-apps/athenaeum-react-components";
import React, { useState } from "react";
import styles from "./AdvanceCheckSummary.module.scss";
import ExpandableTextInput from "@/components/ExpendableTextInput/ExpandableTextInput";

export interface IAdvanceCheckSummaryProps {
  check: CheckItem;
}

const AdvanceCheckSummary = ({ check }: IAdvanceCheckSummaryProps) => {
  const [expanded, setExpanded] = useState(false);

  const expand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.checkWrapper} data-cy="advance_check_summary_item">
      <div className={styles.titleWrapper}>
        <div className={styles.checkIcon}>
          {check.ok && (
            <Icon
              id={"status_icon"}
              name="fa-check"
              size={IconSize.Large}
              className={styles.ok}
            />
          )}
          {check.ok === false && !check.isSkipped && (
            <Icon
              id={"status_icon"}
              name="fa-times"
              size={IconSize.Large}
              className={styles.notOk}
            />
          )}
          {check.isSkipped && (
            <Icon
              id={"status_icon"}
              name="fas fa-arrow-to-right"
              size={IconSize.Large}
            />
          )}
          {check.ok === null && check.isSkipped == null && (
            <Icon
              id={"status_icon"}
              name="fa-solid fa-hourglass-start"
              size={IconSize.Large}
              className={styles.notCompleted}
            />
          )}
        </div>
        <div data-cy="title">{check.name}</div>
        {check.comment && (
          <div className={styles.extendedIcon}>
            <Icon
              id="expand_check_comment"
              name={expanded ? "fa-chevron-up" : "fa-chevron-down"}
              size={IconSize.X2}
              onClick={async (sender) => expand()}
            />
          </div>
        )}
      </div>
      <div>
        {expanded && (
          <ExpandableTextInput
            inputName={"description"}
            disabled
            value={check.comment as string}
          />
        )}
      </div>
    </div>
  );
};

export default AdvanceCheckSummary;
