import React, {useEffect, useState} from 'react';
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import {ColumnDefinition, ColumnType, Grid, GridHoveringType, GridOddType} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";

import styles from "@/components/TelematicsDataPanel/TelematicsDataPanel.module.scss";
import TrackUnitDeviceTelemetry, {TrackUnitDeviceTelemetryFlat} from "@/pages/Models/TrackUnitDeviceTelemetry";
import {External, Internal} from "@/pages/Models/Power";
import {Input} from "@/pages/Models/IO";
import {CumulativeOperatingHours} from "@/pages/Models/CumulativeOperatingHours";
import {Transmissions} from "@/pages/Models/Connectivity";
import ToolsUtility from "@/helpers/ToolsUtility";
import {Utility} from "@renta-apps/athenaeum-toolkit";

interface TelematicsDataPanelProps {
    telematicsData: TrackUnitDeviceTelemetry;
}

enum TelematicsDataType {
    Voltage,
    Percentage,
    Count,
    Distance
}

type GridData = {
    name: string,
    value: TelemetryProperties,
}

type TelemetryProperties = TrackUnitDeviceTelemetryFlat[keyof TrackUnitDeviceTelemetryFlat];

type Progressdata = Internal;

const TelematicsDataPanel = ({telematicsData, ...props}: TelematicsDataPanelProps) => {

    const [progressData, setProgressData] = useState<[string, Progressdata][] | []>();
    const [gridData, setGridData] = useState<GridData[]>();

    useEffect(() => {
        setProgressData(getProgressData())
        setGridData(getGridDataModel(telematicsData));
    }, [telematicsData]);

    const _telematicsGridRef: React.RefObject<Grid<GridData>> = React.createRef();

    const _telematicsColumns: ColumnDefinition[] = [

        {
            header: "",
            accessor: "name",
            minWidth: 150,
            type: ColumnType.Text,
            noWrap: true,
            settings: {
                required: true
            },
            editable: false
        },
        {
            header: "",
            accessor: (model: GridData) => getAccessor(model.value),
            type: ColumnType.Date | ColumnType.Text | ColumnType.Number | ColumnType.Boolean,
            minWidth: 70,
            noWrap: true,
            settings: {
                required: true,
            },
            className: styles.valueColumn
        }
    ];


    const getAccessor = (item: TelemetryProperties) => {

        if ((item as Input)?.isInput) {
            return (item as Input).active ? "On" : "Off";
        }

        if ((item as External)?.isExternalPower) {
            return ((item as External).supply / 1000).format("0.0") + " V";
        }

        if ((item as CumulativeOperatingHours)?.isCumulativeOperatingHours) {
            return (item as CumulativeOperatingHours).hour.format("n");
        }

        if ((item as Transmissions)?.isTransmissions) {
            return ToolsUtility.toDateStringWithTime((item as Transmissions).latestReceptionTime)
        }

        if (Utility.isDateType(item)) {
            return ToolsUtility.toDateStringWithTime(item as Date);
        }
        
        if (typeof item == "string" || typeof item == 'number') {
            return item;
        }
    }


    const getProgressData = (): [string, Progressdata][] | [] => {
        return Object.entries(TrackUnitDeviceTelemetry.flat(telematicsData)).filter((item: [string, TelemetryProperties]) => isProgressData(item[1]));
    }

    const isProgressData = (item: TelemetryProperties): boolean => {
        if (!item || typeof item == "string" || typeof item == "number" || Utility.isDateType(item)) {
            return false;
        }

        return (item as Progressdata).isInternalPower || false;
    }

    const getGridDataModel = (data: TrackUnitDeviceTelemetry): GridData[] => {
        const items: GridData[] = [];
        const flatData = TrackUnitDeviceTelemetry.flat(data);

        Object.entries(flatData)
            .filter((item: [string, TelemetryProperties]) => !isProgressData(item[1]))
            .forEach(item => {
                let dataitem: GridData = {name: getLocalizedName(item[0]), value: item[1]}
                items.push(dataitem);
            });

        return items;
    }
    

    const getEnumKey = (item: string): string => {
        item = item[0].toUpperCase() + item.substring(1);

        return `Enum.DeviceTelematicsData.${item}`;
    }

    const getLocalizedName = (item: string): string => {
        const value = getName(item);
        return Localizer.get(getEnumKey(value));
    }

    const getName = (item: string): string => {
        if (item == nameof<Transmissions>().toLowerCase()) {
            return "LastReceptionTime"
        }

        if (item == nameof<External>().toLowerCase()) {
            return "BatteryPotential"
        }

        if (item == nameof<Internal>().toLowerCase()) {
            return "TelematicsBatteryLevel"
        }

        return item;
    }

    const getValue = (data: Progressdata): number => {

        if ((data as Internal).isInternalPower) {
            return (data as Internal).percentage;
        }

        return 0;
    }


    const getProgressStyles = (item: Progressdata) => {

        const value: number = getValue(item) as number;

        if (value > 69) {
            return styles.progressBar;
        }

        if (value > 49) {
            return styles.progressBarYellow;
        }

        return styles.progressBarRed;
    }

    const getDataType = (key: string): TelematicsDataType => {

        if (key.toLowerCase().includes("battery")) {
            return TelematicsDataType.Voltage
        }

        if (key.toLowerCase().includes("percentage")) {
            return TelematicsDataType.Percentage
        }

        if (key.toLowerCase().includes("location")) {
            return TelematicsDataType.Distance
        }

        return TelematicsDataType.Count
    }
    

    return (
        <>
            <ArsenalPageRow className={styles.container}>
                
                <Grid responsive readonly
                      id={"telematics_grid"}
                      className={styles.telematicsDataTable}
                      ref={_telematicsGridRef}
                      hovering={GridHoveringType.None}
                      odd={GridOddType.None}
                      columns={_telematicsColumns}
                      data={gridData}
                />

            </ArsenalPageRow>
            {
                progressData &&
                progressData.map((item: [string, Progressdata]) =>

                    <ArsenalPageRow className={styles.progressContainer} key={item[0]}>
                        <p id={"telematics_progresslabel"} className={styles.progresslabel}>{getLocalizedName(item[0])}</p>
                        <p className={styles.progresslabel}>{(getValue(item[1]) ?? "").toString() + " %"}</p>

                        <progress id={item[0]} max={100} value={getValue(item[1]) as number} className={getProgressStyles(item[1])}/>
                    </ArsenalPageRow>
                )
            }
        </>
    );
};

export default TelematicsDataPanel;