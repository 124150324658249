export class LocationMetadata {
    constructor(bp?: LocationMetadata | null) {
        this.accuracyRadius = bp?.accuracyRadius ?? 0;
        this.highAccuracy = bp?.highAccuracy ?? false;
    }

    public accuracyRadius: number = 0;
    
    public highAccuracy: boolean = false;

}