import React from "react";
import {Button, Dropdown, Form, PageContainer, PageHeader, Tab, TabContainer, TextAreaInput, ThreeColumns, TwoColumns} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import GetDeviceDailyInfoChartResponse from "@/models/server/responses/GetDeviceDailyInfoChartResponse";
import Depo from "@/models/server/Depo";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import GetDeviceDailyInfoChartRequest from "@/models/server/requests/GetDeviceDailyInfoChartRequest";
import {Cell, Line, LineChart, ResponsiveContainer} from "recharts";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import styles from "./DeviceDailyInfoPage.module.scss";

interface IDeviceDailyInfoPageProps {
}

interface IDeviceDailyInfoPageState {
    depos: Depo[];
    deviceTypes: string[];
    dailyTypeData: GetDeviceDailyInfoChartResponse | null,
    dailyTypeDeviceType: string | null;
    commandInput: string;
}

export default class DeviceDailyInfoPage extends AuthorizedPage<IDeviceDailyInfoPageProps, IDeviceDailyInfoPageState> {

    state: IDeviceDailyInfoPageState = {
        depos: [],
        deviceTypes: [],
        commandInput: "",
        dailyTypeData: null,
        dailyTypeDeviceType: null,
    };
    
    private async setCommandInputAsync(commandInput: string): Promise<void> {
        await this.setState({commandInput});
    }
    
    private async setDailyTypeDeviceTypeAsync(deviceType: string): Promise<void> {
        await this.setState({ dailyTypeDeviceType: deviceType });
        await this.loadDataAsync();
    }
    
    private async deleteDatasetsAsync(): Promise<void> {
        await RentaToolsController.deleteDatasetsAsync(this.state.commandInput);
        
        await this.alertMessageAsync("EN: The specified datasets are successfully removed.");
    }
    
    private async deleteImportJobsAsync(): Promise<void> {
        await RentaToolsController.deleteImportJobsAsync(this.state.commandInput);
        
        await this.alertMessageAsync("EN: The specified imports jobs are successfully removed.");
    }
    
    private async loadDataAsync(): Promise<void> {
        if (this.state.dailyTypeDeviceType) {
            const request = new GetDeviceDailyInfoChartRequest();
            request.deviceType = this.state.dailyTypeDeviceType;
            request.days = 30;

            const response: GetDeviceDailyInfoChartResponse = await this.postAsync("api/adminConsole/getDeviceDailyInfoChart", request);
            
            await this.setState({ dailyTypeData: response  });
        }
    }
    

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const depos: Depo[] = await RentaToolsController.getUserDeposAsync();
        
        const deviceTypes: string[] = await RentaToolsController.getDeviceTypesAsync();
        
        await this.setState({depos, deviceTypes});
    }

    public render(): React.ReactNode {
        
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(styles.deviceDailyInfoPage, rentaToolsStyles.pageContainer, newStyles.pageContainer)}>

                <PageHeader title={Localizer.reportingPagePageTitle} subtitle="" />

                <ArsenalPageRow>
                    
                    <TabContainer>

                        <Tab id="DeviceTypeDailyInfo" title={"EN: Device types"}>

                            <ThreeColumns>

                                <Dropdown required
                                          name={"deviceTypeDailyInfo_deviceTypes"}
                                          label={"EN: Device types"}
                                          items={this.state.deviceTypes}
                                          selectedItem={this.state.dailyTypeDeviceType}
                                          onChange={(_, item) => this.setDailyTypeDeviceTypeAsync(item!)}
                                />

                            </ThreeColumns>

                            {
                                (this.state.dailyTypeData) &&
                                (
                                    <ArsenalPageRow>

                                        <h5>{Localizer.reportingPageMachineryInInspectionTitle}</h5>
                                        
                                        <ResponsiveContainer width="100%" height={400}>
                                            <LineChart>
                                                
                                                <text
                                                    x="50%"
                                                    y="50%"
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                >
                                                    Text
                                                    %
                                                </text>
                                                
                                                <Line dataKey="forecast"
                                                      data={this.state.dailyTypeData.items}
                                                      isAnimationActive={true}
                                                >
                                                    <Cell fill="lightblue"/>
                                                    <Cell fill="#eaeaea"/>
                                                </Line>
                                                
                                            </LineChart>
                                        </ResponsiveContainer>

                                    </ArsenalPageRow>
                                )
                            }
                            
                        </Tab>

                        <Tab id="DeviceTypeDepoDaily" title={"EN: Depo device types "}>

                            <ThreeColumns>

                                <Dropdown required
                                          name={"deviceTypeDailyInfo_depots"}
                                          label={"EN: Depots"}
                                          items={this.state.depos}
                                />

                                <Dropdown required
                                          name={"deviceTypeDailyInfo_deviceTypes"}
                                          label={"EN: Device types"}
                                          items={this.state.deviceTypes}
                                />

                            </ThreeColumns>
                            
                        </Tab>

                        {
                            (this.isAdmin) &&
                            (
                                <Tab id="Management" title={"EN: Management"}>

                                    <Form>

                                        <TwoColumns>

                                            <TextAreaInput id={"commandInput"}
                                                           value={this.state.commandInput}
                                                           onChange={(_, value) => this.setCommandInputAsync(value) }
                                            />

                                        </TwoColumns>

                                        <Button label={"EN: Delete dataset(s)"}
                                                disabled={!this.state.commandInput}
                                                onClick={() => this.deleteDatasetsAsync()}
                                        />

                                        <Button label={"EN: Delete dataset jobs(s)"}
                                                disabled={!this.state.commandInput}
                                                onClick={() => this.deleteImportJobsAsync()}
                                        />
                                        
                                    </Form>

                                </Tab>
                            )
                        }

                    </TabContainer>

                </ArsenalPageRow>


            </PageContainer>
        );
    }
}