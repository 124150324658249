import BaseListDevicesRequest from "./BaseListDevicesRequest";
import {SortDirection} from "@renta-apps/athenaeum-toolkit";

export default class ListDevicesRequest extends BaseListDevicesRequest {
    public pageNumber: number = 0;

    public pageSize: number = 0;

    public searchTerm: string = "";

    public sortColumnName: string = "";

    public reportDefinitionId: string | null = null;

    public serviceReportDefinitionId: string | null = null;

    public sortDirection: SortDirection | null = null;
}