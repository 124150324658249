export default class Category {

    public name: string = "";

    public productGroupId: string = "";

    public productType: string | null  = null;

    public productGroupName: string = "";

    public parentProductGroupId: string  = "";

    public isProductGroup: boolean = true;

    // Used when cast to SelectListItem
    public isCategory: boolean = true;
}