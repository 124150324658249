import {executeWithIntercept, onRedirect, RouteData} from "@/helpers/CypressHelper";
import {TestReturnInspectionAdditionalExpense} from "@/tests/models/TestReturnInspectionAdditionalExpense";
import Localizer from "@/localization/Localizer";

class ReturnInspectionPage {
  public elements = {
    nextButton: () => cy.get("[id=ri_nextButton]"),
    stepOk: () => cy.get("[id=step_ok]"),
    stepTitle: () => cy.get("[data-cy=item_title]", {timeout: 10000}),
    stepNeedRepair: () => cy.get("[id=step_need_repairs]"),
    stepComment: () => cy.get("[id=step_comment]"),
    commentInput: () => cy.get("[id=input_comment]"),
    commentValidation: () => cy.get("[data-cy=comment_validation_message]"),
    partlyCompleteButton: () => cy.get("[id=partyCompleteButton]"),
    numberWidget: () => cy.get("[id=number-widget-body_numberWidget]"),
    shareButton: () => cy.get("[id=share_button]"),
    telematicsTable: () => cy.get("[id=table_telematics_grid]"),
    telematicsProgressBar: () => cy.get("progress[id=internal]"),
    telematicsProgressBarLabel: () => cy.get("[id=telematics_progresslabel]"),

    additionalExpenses: {
      additionalExpensesContainer: () => cy.get("#additionalExpensesContainer"),
      additionalExpenseForm: (index: number) => cy.get(`#additionalExpenseForm_${index}`),
      addAdditionalExpenseButton: () => cy.get("[id=ri_add_additional_expense_button]"),
      removeAdditionalExpenseButton: (index: number) => cy.get(`[id=removeAdditionalExpenses_${index}`),
      additionalExpensesDropdown: (index: number) => cy.get(`#additionalExpenses_${index}`),
      amountInput: (index: number) => cy.get(`#number-widget-body_additionalExpenseAmount_${index}`),
      invoicedCheckbox: (index: number) => cy.get(`#additionalExpenseInvoiced_${index} i`),
      customExpenseNameInput: (index: number) => cy.get(`#input_additionalExpenseName_${index}`),
      customExpenseUnitDropdown: (index: number) => cy.get(`#additionalExpenseUnit_${index}`),
      customExpensePriceInput: (index: number) => cy.get(`#number-widget-body_additionalExpensePrice_${index}`),
    },
  };

  public routes = {
    startReturnInspection: {
      path: "/api/ReturnInspection/StartReturnInspection",
      method: "POST",
    } as RouteData,
    saveReturnInspectionStep: {
      path: "/api/ReturnInspection/SaveReturnInspectionStep",
      method: "POST",
    } as RouteData,
    getFuelTypes: {
      path: "/api/classifier/GetFuelTypes",
      method: "POST",
    } as RouteData,
    getWashingTypes: {
      path: "/api/classifier/GetWashingTypes",
      method: "POST",
    } as RouteData,
    getAdBlues: {
      path: "/api/classifier/GetAdBlues",
      method: "POST",
    } as RouteData,
    getAdditionalExpenses: {
      path: "/api/classifier/GetAdditionalExpenses",
      method: "POST",
    } as RouteData,
  }

  public constants = {
    cypressTestDeviceExternalIdForAdditionalExpenses: "CypressTestDeviceExternalIdForAdditionalExpenses",
    cypressTestProductGroupNameForAdditionalExpenses: "Cypress Test ProductGroup for adding AdditionalExpenses in report"
  };

  public continueStep() {
    this.elements.nextButton().click();

    cy.wait(300);
  }

  public partlyCompleteReturnInspection() {
    this.elements.partlyCompleteButton().should('be.visible')
        .realClick();
  }

  public startReturnInspection() {
    executeWithIntercept(() => this.elements.nextButton().should("be.visible").click(), [this.routes.startReturnInspection])

    cy.wait(1000);
  }

  public continueReturnInspection(routes?: RouteData[]) {
    if (routes && routes.length > 0) {
      executeWithIntercept(() => this.elements.nextButton().should("be.visible").realClick(),
          [...routes])
    } else {
      onRedirect(() => this.elements.nextButton().should("be.visible").realClick())
    }

  }

  public completeQuestionStep(stepTitle: string) {
    this.markStepAsDone(stepTitle);

    this.elements.nextButton().click();
  }

  public markStepAsDone(stepTitle: string) {
    this.elements.stepTitle().contains(stepTitle);

    this.elements.stepOk().click().should("have.class", "btn-success");
  }

  public stepNeedRepair(stepTitle: string, comment: string) {
    this.elements.stepTitle().should("contain", stepTitle);

    this.elements.stepNeedRepair().click().should("have.class", "btn-danger");

    this.elements.stepComment().should("have.class", "btn-primary");

    this.elements.commentInput().should("be.visible").type(comment).blur();

    cy.wait(100);

    this.elements.nextButton().click();
  }

  public stepNeedRepairAndFixBeforeNextLease(stepTitle: string, comment: string) {
    this.elements.stepTitle().should("contain", stepTitle);

    this.elements.stepNeedRepair().click().should("have.class", "btn-danger");

    this.elements.stepComment().should("have.class", "btn-primary");

    this.elements.commentInput().should("be.visible").type(comment);

    cy.contains(Localizer.arsenalQuestionInputFaultLevel)
        .should("be.visible")
        .click();

    cy.wait(100);

    this.elements.nextButton().click();
  }

  public stepNeedRepairAndFixInReturnInspection(
      stepTitle: string,
      comment: string,
  ) {
    this.elements.stepTitle().should("contain", stepTitle);

    this.elements.stepNeedRepair().click().should("have.class", "btn-danger");

    this.elements.stepComment().should("have.class", "btn-primary");

    this.elements.commentInput().should("be.visible").type(comment);

    cy.contains(Localizer.arsenalQuestionInputFaultFixed)
        .should("be.visible")
        .click();

    cy.wait(100);

    this.elements.nextButton().click();
  }

  public stepNeedRepairAndPermanentFault(stepTitle: string, comment: string) {
    this.elements.stepTitle().should("contain", stepTitle);

    this.elements.stepNeedRepair().click().should("have.class", "btn-danger");

    this.elements.stepComment().should("have.class", "btn-primary");

    this.elements.commentInput().should("be.visible").type(comment);

    cy.contains(Localizer.arsenalQuestionInputPermanentFault)
        .should("be.visible")
        .click();

    cy.wait(100);

    this.elements.nextButton().click();
  }

  public increaseNumberInput(value: number) {
    for (let i = 0; i < value; i++) {
      this.elements.numberWidget().find("[class^=fa-plus-circle]").realClick();
    }
  }

  public addAdditionalExpensesToReturnInspectionStep(additionalExpenses: TestReturnInspectionAdditionalExpense[]): void {
    additionalExpenses.forEach((expense, index) => {
      this.elements.additionalExpenses.addAdditionalExpenseButton().click();

      this.elements.additionalExpenses.additionalExpensesDropdown(index)
          .selectDropdownValue(expense.name);

      this.elements.additionalExpenses.amountInput(index)
          .trigger('click')
          .within(() => {
            cy.get('input')
                .clear()
                .type(expense.amount + "{enter}");
          });

      if (expense.isCustom && expense.customExpenseName) {
        this.elements.additionalExpenses.customExpenseNameInput(index)
            .trigger('click')
            .clear()
            .type(expense.customExpenseName);

        this.elements.additionalExpenses.customExpenseUnitDropdown(index)
            .selectDropdownValue(expense.unit);

        this.elements.additionalExpenses.customExpensePriceInput(index)
            .trigger('click')
            .within(() => {
              cy.get('input')
                  .clear()
                  .type(expense.price + "{enter}");
            });
      }

      if (expense.isInvoiceable) {
        // Check that checkbox automatically selected.
        this.elements.additionalExpenses.invoicedCheckbox(index)
            .should('be.visible')
            .get('.athenaeum-checkbox-checkbox')
            .should('have.class', 'athenaeum-checkbox-checked');

        // Verify checkbox clickable.
        this.elements.additionalExpenses.invoicedCheckbox(index).trigger('dblclick');

        // If expense supposed to be invoiced - click Checkbox to be selected, otherwise - click to unselect.
        this.elements.additionalExpenses.invoicedCheckbox(index)
            .should('be.visible')
            .then(($el) => {
              const shallClick: boolean = (expense.invoiced === true && $el.hasClass('fa-square')) ||
                  (expense.invoiced === false && $el.hasClass('fa-check-square'));

              if (shallClick) {
                this.elements.additionalExpenses.invoicedCheckbox(index).click();
              }
            });
      } else if (!expense.isInvoiceable) {
        this.elements.additionalExpenses.invoicedCheckbox(index)
            .should('not.exist');

        this.elements.additionalExpenses.removeAdditionalExpenseButton(index).should('exist');

        if (expense.toDelete) {
          this.elements.additionalExpenses.removeAdditionalExpenseButton(index).click();
          this.elements.additionalExpenses.additionalExpenseForm(index).should('not.exist');
        }
      }
    });
  }

  public assertReturnInspectionStepAdditionalExpenses(additionalExpenses: TestReturnInspectionAdditionalExpense[]): void {
    additionalExpenses.forEach((expense, index) => {
      this.elements.additionalExpenses.additionalExpensesDropdown(index)
          .find('.athenaeum-form-inputContainer')
          .children('div')
          .should('have.class', 'athenaeum-dropdown-disabled');

      this.elements.additionalExpenses.additionalExpensesDropdown(index)
          .validateDropdownSelectedValue(expense.name);

      this.elements.additionalExpenses.amountInput(index)
          .should('have.class', 'athenaeum-widget-container-readonly')
          .find('div:nth-child(2)')
          .within(() => {
            cy.get('span')
                .should('have.text', expense.amount);
          });

      if (expense.isCustom) {
        this.elements.additionalExpenses.customExpenseNameInput(index)
            .should('have.value', expense.customExpenseName)
            .and('have.attr', 'readonly');

        this.elements.additionalExpenses.customExpenseUnitDropdown(index)
            .find('.athenaeum-form-inputContainer')
            .children('div')
            .should('have.class', 'athenaeum-dropdown-disabled');

        this.elements.additionalExpenses.customExpenseUnitDropdown(index)
            .validateDropdownSelectedValue(expense.unit);

        this.elements.additionalExpenses.customExpensePriceInput(index)
            .should('have.class', 'athenaeum-widget-container-readonly')
            .find('div:nth-child(2)')
            .within(() => {
              cy.get('span')
                  .should('have.text', (expense.price != null) ? expense.price : "0");
            });

        if (expense.isInvoiceable) {
          if (expense.invoiced === true) {
            this.elements.additionalExpenses.invoicedCheckbox(index)
                .should('be.visible')
                .parents('.athenaeum-checkbox-checkbox')
                .should('have.class', 'athenaeum-checkbox-checked')
                .and('have.class', 'athenaeum-checkbox-readonly');
          } else if (expense.invoiced === false) {
            this.elements.additionalExpenses.invoicedCheckbox(index)
                .should('be.visible')
                .parents('.athenaeum-checkbox-checkbox')
                .should('have.class', 'athenaeum-checkbox-unchecked')
                .and('have.class', 'athenaeum-checkbox-readonly');
          }
        } else {
          this.elements.additionalExpenses.invoicedCheckbox(index)
              .should('not.be.visible')
        }
      }
    });
  }

  public checkTelematics(): void {
    this.checkTelematicsTable();
    this.checkTelematicsProgressBar();
  }

  private checkTelematicsTable(): void {
    this.elements.telematicsTable().should('be.visible');
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataTelematicsDeviceSerial);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataTelematicsDeviceType);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataBatteryPotential);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataSatellites);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataGpsSignalQuality);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataLatestFixTime);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataGsmSignalQuality);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataNetworkTechnology);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataLastReceptionTime);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataInput1);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataInput2);
    this.elements.telematicsTable().should('contain', Localizer.enumDeviceTelematicsDataCumulativeOperatingHours);
  }

  private checkTelematicsProgressBar(): void {
    this.elements.telematicsProgressBar().should('be.visible');
    this.elements.telematicsProgressBar().should('have.value', 0);
    this.elements.telematicsProgressBarLabel().should('contain', Localizer.enumDeviceTelematicsDataTelematicsBatteryLevel);
  }
}

export default new ReturnInspectionPage();