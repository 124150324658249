import React from "react";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Icon, IconSize, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./InstructionPage.module.scss";

interface IInstructionPageProps {
}

interface IInstructionPageState {
    info: boolean;
}

export default class InstructionPage extends AuthorizedPage<IInstructionPageProps, IInstructionPageState> {

    state: IInstructionPageState = {
        info: true
    };

    private get title(): string {
        return RentaToolsController.device ? RentaToolsController.device.name : "";
    }

    private async backAsync(): Promise<void> {
        if (RentaToolsController.instructionBackRoute != null) {
            await PageRouteProvider.redirectAsync(RentaToolsController.instructionBackRoute!);
        } else {
            await PageRouteProvider.back();
        }
    }

    public getManual(): string {
        return Localizer.instructionPageManual;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.instruction)}>

                <PageHeader title={this.title} className={rentaToolsStyles.leftPageHeader}/>

                <span className={rentaToolsStyles.wizardHeader}>Vaiheen X ohjeet. Saat kuvan isommaksi napauttalla.</span>

                <ArsenalPageRow>

                    <div className={styles.photoContainer}>

                        <div className="col-4">
                            <Icon name="fas camera" size={IconSize.X3}/>
                        </div>

                        <div className="col-8">
                            <span>Lorem ipsum dolor sit amet, consecterur adipisining elit, sed do</span>
                        </div>

                    </div>

                    <div className={styles.photoContainer}>

                        <div className="col-4">
                            <Icon name="fas camera" size={IconSize.X3}/>
                        </div>

                        <div className="col-8">
                            <span>Lorem ipsum dolor sit amet, consecterur adipisining elit, sed do</span>
                        </div>

                    </div>

                    <div className={styles.photoContainer}>

                        <div className="col-4">
                            <Icon name="fas camera" size={IconSize.X3}/>
                        </div>

                        <div className="col-8">
                            <span>Lorem ipsum dolor sit amet, consecterur adipisining elit, sed do</span>
                        </div>

                    </div>

                    <div className={styles.photoContainer}>

                        <div className="col-4">
                            <Icon name="fas camera" size={IconSize.X3}/>
                        </div>

                        <div className="col-8">
                            <span>Lorem ipsum dolor sit amet, consecterur adipisining elit, sed do</span>
                        </div>

                    </div>

                </ArsenalPageRow>

                <ArsenalPageRow>
                    <ArsenalButton big
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   icon={{name: "fas chevron-left"}}
                                   onClick={async () => await this.backAsync()}/>
                </ArsenalPageRow>

            </PageContainer>
        );
    }
}