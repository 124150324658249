import AdminPage from "../../../tests/pages/AdminPage";
import AnnualInspectionVendorsPage from "../../../tests/pages/AnnualInspectionVendorsPage";
import {executeWithIntercept} from "@/helpers/CypressHelper";

describe('Annual inspection vendors page tests', () => {
    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
    });

    it('Opens annual inspection vendors page and checks elements load correctly', () => {
        cy.goToAdminPage();

        executeWithIntercept(() => {
            AdminPage.elements.annualInspectionVendors().click();
        }, [AnnualInspectionVendorsPage.routes.listVendors])

        AnnualInspectionVendorsPage.elements.vendorsGrid();

        AnnualInspectionVendorsPage.assertVendors();

        executeWithIntercept(() => {
            AnnualInspectionVendorsPage.expandVendor();
        }, [AnnualInspectionVendorsPage.routes.listVendorInspectors])

        AnnualInspectionVendorsPage.assertInspectors();
        AnnualInspectionVendorsPage.elements.inspectionsGrid()

        AnnualInspectionVendorsPage.elements.inspectionsTab().realClick();

        AnnualInspectionVendorsPage.elements.inspectionsGrid();

        AnnualInspectionVendorsPage.elements.inspectorTab().click();
    });
});