import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import {ButtonType} from "@renta-apps/athenaeum-react-components";
import React from "react";
import styles from './NavigationMenu.module.scss';
import {Utility} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
export interface INavigationMenuProps {
    saveAsync: () => Promise<void>;
    exitAsync: () => Promise<void>;
}


const NavigationMenu = ({ saveAsync, exitAsync } : INavigationMenuProps) => {
    
    return (
        <div className={styles.navigationMenu}>
            <ArsenalButton action
                           fullWidth
                           id={"exitButton"}
                           type={ButtonType.Light}
                           label={Localizer.genericExit}
                           icon={{name: 'fal fa-sign-out', className: rentaToolsStyles.buttonIcon}}
                           onClick={async () => await exitAsync()}
                           className={Utility.css(rentaToolsStyles.navigationButton,  rentaToolsStyles.bigNavButton)}
            />
                <ArsenalButton action
                               fullWidth
                               id={"saveButton"}
                               type={ButtonType.Orange}
                               label={Localizer.genericSave}
                               icon={{name: "fas fa-save", className: rentaToolsStyles.buttonIcon}}
                               onClick={async () => await saveAsync()}
                               className={Utility.css(rentaToolsStyles.navigationButton,  rentaToolsStyles.bigNavButton)}
                />
        </div>
    )
}

export default NavigationMenu;