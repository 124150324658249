import React from 'react';
import UnleashHelper from "@/helpers/UnleashHelper";
interface IFeatureSwitchProps {
    /**
     * Name of the flag.
     * @see FeatureFlags
     */
    flagName: string;
    children: React.ReactNode;
}
/**
 * Render's component's children if the given flag is configured and enabled.
 * @param props
 * @constructor
 */
export const FeatureSwitch: React.FC<IFeatureSwitchProps> = props => {
    
    const enabled: boolean = UnleashHelper.isEnabled(props.flagName);

    return (
        <React.Fragment>
            {(enabled) && props.children}
        </React.Fragment>
    );
}