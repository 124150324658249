import {ch, ILayoutPage} from "@renta-apps/athenaeum-react-common";

export default class BaseController {
    public async postAsync<TResponse>(endpoint: string, request: any | null = null): Promise<TResponse> {
        const layout: ILayoutPage = ch.getLayout();
        return await layout.postAsync(endpoint, request);
    }

    public async getAsync<TResponse>(endpoint: string): Promise<TResponse> {
        const layout: ILayoutPage = ch.getLayout();
        return await layout.getAsync(endpoint);
    }
}