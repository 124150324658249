import DeviceInfo from "@/models/server/DeviceInfo";
import DeviceContract from "@/models/server/DeviceContract";
import User from "@/models/server/User";
import AdBlue from "@/models/server/AdBlue";
import FuelType from "@/models/server/FuelType";
import WashingType from "@/models/server/WashingType";
import InvoiceAdditionalExpense from "@/models/server/InvoiceAdditionalExpense";

export default class Invoice {

    public id: string = "";

    public deviceId: string | null = null;

    public device: DeviceInfo | null = null;

    public deviceContract: DeviceContract | null = null;

    public deviceContractExternalId: string | null = null;

    public adBlueAmount: number | null = null;

    public fuelAmount: number | null = null;

    public washingTime: number | null = null;

    public approved: boolean | null = null;

    public approvedAt: Date | null = null;

    public approvedById: string | null = null;

    public approvedBy: User | null = null;

    public declined: boolean | null = null;

    public declinedAt: Date | null = null;

    public declinedById: string | null = null;

    public declinedBy: User | null = null;

    public comment: string | null = null;

    public createdAt: Date = new Date();

    public adBlueId: string | null = null;

    public adBlue: AdBlue | null = null;

    public fuelTypeId: string | null = null;

    public fuelType: FuelType | null = null;

    public washingTypeId: string | null = null;

    public washingType: WashingType | null = null;

    public additionalExpenses: InvoiceAdditionalExpense[] = [];

    public additionalExpensesCount: number = 0;

    public deleted: boolean = true;

    public static isValid(invoice: Invoice): boolean {

        if (invoice.adBlueId && invoice.adBlueAmount) {
            return true;
        }

        if (invoice.fuelTypeId && invoice.fuelAmount) {
            return true;
        }

        if (invoice.washingTypeId && invoice.washingTime) {
            return true;
        }

        return false;
    }
}