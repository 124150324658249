import Depo from "@/models/server/Depo";
import EndpointPaths from "@/common/EndpointPaths";
import HttpClient from "@/common/HttpClient";

class DepoService {
    
    public async getUserDeposAsync(): Promise<Depo[]> {
        return await HttpClient.postAsyncWithoutErrorHandling<Depo[]>(EndpointPaths.DepoPaths.GetUserDepos);
    }
}

export default new DepoService();