import {FileModel} from "@renta-apps/athenaeum-toolkit";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";

/**
 * Files helper.
 */
class FileHelper {

    /**
     * Check if an image size is acceptable.
     *
     * @param file PDF size to check for being an acceptable.
     * @return true if the PDF {@link file} size is acceptable; false otherwise.
     */
    public isSupportedPdfFileSize(file: Readonly<FileModel>): boolean {
        return (file.size <= RentaToolsConstants.fileMaxSize);
    }

    /**
     * Check if an image size is acceptable.
     *
     * @param image Image size to check for being an acceptable.
     * @return true if the {@link image} size is acceptable; false otherwise.
     */
    public isSupportedImageSize(image: Readonly<FileModel>): boolean {
        return (image.size <= RentaToolsConstants.imageMaxSize);
    }

    /**
     * Check if a file is an image with a supported format.
     *
     * @param image File to check for being a supported image.
     * @return true if the {@link image} is a supported image; false otherwise.
     */
    public isSupportedImageType(image: Readonly<FileModel>): boolean {
        return RentaToolsConstants.supportedImageFileTypes.some(extension => image.name.toLowerCase().endsWith(extension));
    }
}

/** {@link FileHelper} singleton. */
export default new FileHelper();