import {Utility} from "@renta-apps/athenaeum-toolkit";
import {PageRoute} from "@renta-apps/athenaeum-react-common";
import Device from "./Device";
import Report from "./Report";
import QuestionPicturesReportItem from "./QuestionPicturesReportItem";
import BaseReportDefinition from "@/pages/Models/BaseReportDefinition";
import ReportItemInfo from "./ReportItemInfo";
import ServiceReport from "@/pages/Models/ServiceReport";
import DevicePicture from "@/models/server/DevicePicture";

export default class Context {

    public device: Device | null = null;

    public report: Report | null = null;

    public service: ServiceReport | null = null;

    public ongoingService: ServiceReport | null = null;

    public ongoingServicePreview: ServiceReport | null = null;

    public isManualService: boolean = false;

    public reportDefinition: BaseReportDefinition | null = null;

    public preview: boolean = false;

    public instructionBackRoute: PageRoute | null = null;

    public picturePreviewBackRoute: PageRoute | null = null;

    public picturePreviewReportItem: QuestionPicturesReportItem | null = null;

    public picturePreviewReport: Report | null = null;

    public failedReportItems: ReportItemInfo[] | null = null;

    public devicePicture: DevicePicture | null = null;

    public static parse(json: string): Context {
        const context: Context = JSON.parse(json);
        Utility.restoreDate(context);
        return context;
    }
}