import React from "react";
import {ButtonType, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Device from "../Models/Device";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./PictureEventsPage.module.scss";
import DeviceObservation from "@/models/server/DeviceObservation";
import ObservationsList from "@/pages/ObservationsList/ObservationsList";
import PageDefinitions from "@/providers/PageDefinitions";

interface IPictureEventsPageProps {
}

interface IPictureEventsPageState {
}

export default class PicturesPreviewPage extends AuthorizedPage<IPictureEventsPageProps, IPictureEventsPageState> {

    state: IPictureEventsPageState = {
    };


    private get title(): string {
        return `${this.device.externalId} ${this.device.name}`;
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private async onOpenPictureListItemAsync(observation: DeviceObservation): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.observationPreviewRoute({
            observationId: observation.observationId,
            deviceId: this.device.id,
        }));
    }

    public getManual(): string {
        return Localizer.picturesPreviewPageManual;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(rentaToolsStyles.pageContainer, styles.picturesEvents)}>

                <PageHeader title={this.title}
                            className={rentaToolsStyles.leftPageHeader}>

                    <span className={rentaToolsStyles.rightPageHeader}/>

                </PageHeader>

                <ArsenalPageRow>

                    <ObservationsList deviceId={this.device.id}
                                      onOpenPictureListItem={async (item) => await this.onOpenPictureListItemAsync(item)}
                    />

                </ArsenalPageRow>

                <ArsenalPageRow>
                    <ArsenalButton big block
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                </ArsenalPageRow>

            </PageContainer>
        );
    }
}