import React from "react";
import {BasePageParameters, ch, IBasePage, IManualProps, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, Icon, IconSize, IconStyle, IWizardStep, Modal, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import ReturnInspectionButtons from "@/components/ReturnInspectionButtons/ReturnInspectionButtons";
import WizardPage from "@/models/base/WizardPage";
import ReportDefinitionItem from "@/pages/Models/ReportDefinitionItem";
import Comparator from "@/helpers/Comparator";
import PageDefinitions from "@/providers/PageDefinitions";
import Report from "@/pages/Models/Report";
import DeviceFault from "@/pages/Models/DeviceFault";
import QuestionReportItem from "@/pages/Models/QuestionReportItem";
import {ReportItemType} from "@/models/Enums";
import ArsenalNavigation from "@/components/ArsenalNavigation/ArsenalNavigation";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import ObjectComparer from "@/helpers/ObjectComparer";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import QuestionPicturesReportItem from "@/pages/Models/QuestionPicturesReportItem";
import ChecksReportItem from "@/pages/Models/ChecksReportItem";
import MeasuringReportItem from "@/pages/Models/MeasuringReportItem";
import QuestionResourceReportItem from "@/pages/Models/QuestionResourceReportItem";
import PicturesReportItem from "@/pages/Models/PicturesReportItem";
import ResourceReportItem from "@/pages/Models/ResourceReportItem";
import UserContext from "@/models/server/UserContext";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import AdvancedChecksReportItem from "@/pages/Models/AdvancedChecksReportItem";
import AdditionalExpenseValue from "@/models/server/AdditionalExpenseValue";
import AdditionalExpensesPanel from "@/components/AdditionalExpensesPanel/AdditionalExpensesPanel";
import AdditionalExpense from "@/models/server/AdditionalExpense";
import TelematicsDataPanel from "@/components/TelematicsDataPanel/TelematicsDataPanel";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import RentaToolsController from "@/pages/RentaToolsController";
import TransformProvider from "@/providers/TransformProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "./RentaTools.module.scss";
import newStyles from "./NewUI.module.scss";
import TrackUnitDeviceTelemetry from "@/pages/Models/TrackUnitDeviceTelemetry";

export default abstract class ReturnInspectionWizardPage<TParams extends BasePageParameters = {}, TState extends IReturnInspectionWizardPageState = {}>
    extends WizardPage<TParams, TState> {

    protected _needToProcessOnLeave: boolean = true;

    private _correctProcessed: boolean = false;
    private _currentIndex: number = -1;
    private _pollIntervalId: NodeJS.Timeout | number | undefined = undefined;

    private readonly _extraInfoModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _additionalExpensesPanelRef: React.RefObject<AdditionalExpensesPanel> = React.createRef();

    private initialStep: ReportDefinitionItem | null = null;

    // Private
    private get steps(): IWizardStep[] {
        const steps = ReturnInspectionController.getSteps();

        return steps ? steps!.steps : [];
    }

    private get currentIndex(): number {
        const page: IBasePage = this.getPage();
        const route: PageRoute = page.route;

        return this.steps.findIndex(step => Comparator.isEqual(step.route, route));
    }

    private get isTelematics(): boolean {
        return this.getReportItem().isTelematicsStep;
    }

    private get telematicsData(): TrackUnitDeviceTelemetry | null {
        return this.state.deviceTelematics ?? null;
    }

    private get additionalExpenses(): AdditionalExpense[] {
        return this.state.additionalExpenses || [];
    }

    private get additionalExpensesCanBeAdded(): boolean {
        return this.getReportItem().additionalExpensesCanBeAdded;
    }

    private get additionalExpenseValues(): AdditionalExpenseValue[] {
        const reportItem: ReportDefinitionItem = this.getReportItem();
        return reportItem.additionalExpenseValues ?? (reportItem.additionalExpenseValues = []);
    }

    private async navigateAsync(step: IWizardStep): Promise<void> {
        await this.redirectNextAsync(step.route);
    }

    private async openExtraInfoAsync(): Promise<void> {
        if (this._extraInfoModalRef.current) {
            await this._extraInfoModalRef.current.openAsync();
        }
    }

    private get isLastStep(): boolean {
        return this.currentIndex === (this.report.items.length - 1);
    }

    private isPhaseEnding(): boolean {
        if (!UnleashHelper.isEnabled(RentaToolsConstants.featureFlagShowPhasesEnabled)) {
            return false;
        }

        const currentStep = this.report.items[this.currentIndex];

        if (currentStep.phase) {
            const phase = currentStep.phase;
            const phaseItems = this.report.items.filter(p => p.phase?.id === phase.id);
            const index = phaseItems.indexOf(currentStep);

            return phaseItems.length === index + 1;
        }

        return false;
    }

    private async getAdditionalExpensesAsync(additionalExpenseTypeIds: string[], includeDeleted: boolean = false): Promise<void> {
        const additionalExpenses: AdditionalExpense[] = await RentaToolsController.getAdditionalExpensesByIdAsync(additionalExpenseTypeIds, includeDeleted);

        await this.setState({additionalExpenses});
    }

    private async onChangeAdditionalExpenseValuesAsync(): Promise<void> {
        RentaToolsController.saveContext();

        await this.reRenderAsync();
    }

    // Protected
    protected get initialStepItem(): ReportDefinitionItem | null {
        return this.initialStep;
    }

    protected get showExitButton(): boolean {
        return true;
    }

    protected get report(): Report {
        const report: Report | null = RentaToolsController.report;

        if (report == null)
            return new Report();

        return report;
    }

    protected get preview(): boolean {
        return RentaToolsController?.preview === true;
    }

    protected findReportItem(): ReportDefinitionItem | null {
        return ReturnInspectionController.reportItem;
    }

    protected getReportItem<TReportItem extends ReportDefinitionItem>(): TReportItem {

        const reportItem: ReportDefinitionItem | null = this.findReportItem();

        if (reportItem == null)
            return new ReportDefinitionItem() as TReportItem;

        return reportItem as TReportItem;
    }

    protected findPermanentFaults(): DeviceFault[] {

        if ((RentaToolsController.device) && (RentaToolsController.device.permanentFaults)) {
            return RentaToolsController.device.permanentFaults.filter(fault => !fault.fixed);
        }

        return [];
    }

    protected findPreviousFaults(): DeviceFault[] {

        if ((RentaToolsController.device) && (RentaToolsController.device.activeFaults)) {
            const reportItem: ReportDefinitionItem | null = this.findReportItem();

            if (reportItem) {
                return RentaToolsController.findActiveFaults(RentaToolsController.device, reportItem.id);
            }
        }

        return [];
    }

    protected showNextButton(): boolean {
        return true;
    }

    protected isNextButtonDisabled(): boolean {
        return false;
    }

    protected async openSummaryAsync(): Promise<void> {
        if (!this.preview) {
            const route: PageRoute = PageDefinitions.summaryRoute;
            await PageRouteProvider.redirectAsync(route, true);
        }
    }

    protected async exit(): Promise<void> {
        const currentStep = this.report.items[this.currentIndex];

        await ch.getLayout().setSpinnerAsync(true);

        if (this.initialStep != null) {

            if (ObjectComparer.detectChanges(this.getReportItem(), this.initialStep)) {
                currentStep.completed = this.isItemValid(currentStep);
                this.getReportItem().isTouched = true;
            }
        }

        this._correctProcessed = true;

        await ReturnInspectionController.stopReturnInspectionAsync(this.deviceId, this.report.items);

        await ch.getLayout().setSpinnerAsync(false);

        await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true);
    }

    // Public
    public get canEdit(): boolean {
        const context: UserContext = ch.getContext() as UserContext;

        return context.isAdmin || context.isDepoManager;
    }

    public get deviceId(): string {
        return (RentaToolsController.device) ? RentaToolsController.device.externalId : "";
    }

    public get canThrowFault(): boolean {
        const reportItem: ReportDefinitionItem | null = this.findReportItem();

        return (reportItem != null) && ((reportItem.minFaultLevel != null));
    }

    public isItemValid(item: ReportDefinitionItem): boolean {
        switch (item.type) {
            case ReportItemType.QuestionPictures:
                return QuestionPicturesReportItem.isValid(item as QuestionPicturesReportItem);
            case ReportItemType.Question:
                return QuestionReportItem.isValid(item as QuestionReportItem);
            case ReportItemType.Checks:
                return ChecksReportItem.isValid(item as ChecksReportItem);
            case ReportItemType.MeasuringResource:
                return MeasuringReportItem.isValid(item as MeasuringReportItem);
            case ReportItemType.QuestionResource:
                return QuestionResourceReportItem.isValid(item as QuestionResourceReportItem);
            case ReportItemType.Pictures:
                return PicturesReportItem.isValid(item as PicturesReportItem);
            case ReportItemType.Resource:
                return ResourceReportItem.isValid(item as ResourceReportItem);
            case ReportItemType.ErrorsCodes:
                return true;
            case ReportItemType.AdvancedChecks:
                return AdvancedChecksReportItem.isValid(item as AdvancedChecksReportItem);
            default:
                throw Error("Unknown report definition");
        }
    }

    public isAdditionalExpensesValid(item: ReportDefinitionItem | ReportDefinitionItem[]): boolean {
        if (Array.isArray(item)) {
            return item.every(item => this.isAdditionalExpensesValid(item));
        }

        if (item.additionalExpenseValues == null || item.additionalExpenseValues.length == 0) {
            return true;
        }

        if (this.hasDuplicatedAdditionalExpenses(item)) {
            return false;
        }

        return item.additionalExpenseValues
            .every(expense => AdditionalExpenseValue.isValid(expense))
    }

    public hasDuplicatedAdditionalExpenses(item: ReportDefinitionItem): boolean {
        const productNames: string[] = item.additionalExpenseValues
            .map(expense => expense.name.toLowerCase().trim());

        const productNameDuplicates: string[] = productNames
            .filter((expenseName, index) => productNames.indexOf(expenseName) !== index);

        return (productNameDuplicates.length > 0);
    }

    public getManual(): string {
        const reportItem: ReportDefinitionItem | null = this.findReportItem();

        return (reportItem != null) ?
            reportItem.manual || ""
            : "";
    }

    public getTitle(): string {
        return (RentaToolsController.device) ? RentaToolsController.device.name : "";
    }

    public getRightPageHeader(): string {
        return `RENTA ID ${this.deviceId}`;
    }

    public getWizardDescription(): string {
        return "";
    }

    public getManualProps(): IManualProps {
        return {
            manual: this.getManual(),
            icon: "fal info"
        }
    }

    public async prevAsync(): Promise<void> {
        const prevRoute: PageRoute = ReturnInspectionController.getPrevStep(this.route);

        await this.redirectPrevAsync(prevRoute);
    }

    public async nextAsync(): Promise<void> {
        if (!this.isAdditionalExpensesValid(this.getReportItem())) {
            if (this._additionalExpensesPanelRef.current) {
                await this._additionalExpensesPanelRef.current!.initializeAsync();
            }

            await ch.flyoutErrorAsync(Localizer.returnInspectionWizardPageFlyoutErrorInvalidAdditionalExpenses);
            return;
        }

        if (this.initialStep != null && !this.preview) {
            this.getReportItem().completed = true;
            this.getReportItem().isTouched = true;

            if (ObjectComparer.detectChanges(this.getReportItem(), this.initialStep)) {

                if (this.getReportItem().isTelematicsStep) {
                    this.getReportItem().telematicsData = this.telematicsData;
                }

                await ReturnInspectionController.saveReturnInspectionStep(this.getReportItem());

                this._correctProcessed = true;
            }
        }

        if (this.preview && this.isLastStep) {
            await PageRouteProvider.redirectAsync(PageDefinitions.reportPreviewRoute);
            return;
        }

        if (this.isPhaseEnding()) {
            await this.openSummaryAsync();
            return;
        }

        const nextRoute: PageRoute = ReturnInspectionController.getNextStep(this.route);
        await this.redirectNextAsync(nextRoute);
    }

    public async backAsync(): Promise<void> {
        const nextRoute: PageRoute = ReturnInspectionController.getPrevStep(this.route);
        await this.redirectNextAsync(nextRoute);
    }

    updateTelematicsData = async (): Promise<void> => {
        const data = (RentaToolsController.device)
            ? await ReturnInspectionController.getTelematicsDataAsync(RentaToolsController.device?.externalId)
            : new TrackUnitDeviceTelemetry();

        await this.setState({deviceTelematics: data})
    }

    public async initializeAsync(): Promise<void> {
        this._currentIndex = this.currentIndex;

        if ((!RentaToolsController.device) || (!RentaToolsController.report)) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true, true);
            return;
        }

        const reportItem: ReportDefinitionItem = this.getReportItem();

        const canUpdateTelematicsData: boolean = (reportItem.isTelematicsStep && !this.preview && !this._pollIntervalId);

        if (canUpdateTelematicsData) {
            this._pollIntervalId = setInterval(this.updateTelematicsData, 2000);
            await this.updateTelematicsData();
        }

        this.initialStep = {...ReturnInspectionController.reportItem} as ReportDefinitionItem;
        this.initialStep.valueTypeId = (this.initialStep.valueTypeIds && this.initialStep.valueTypeIds.length > 0) ? "" : null;
        this.initialStep.value = (this.initialStep.valueTypeIds && this.initialStep.valueTypeIds.length > 0) ? 0 : null;

        await super.initializeAsync();

        const canUpdateAdditionalExpenses: boolean = (
            (!this._pollIntervalId) &&
            (
                this.additionalExpensesCanBeAdded &&
                reportItem.additionalExpenseTypeIds != null &&
                reportItem.additionalExpenseTypeIds!.length > 0
            ));

        if (canUpdateAdditionalExpenses) {
            await this.getAdditionalExpensesAsync(reportItem.additionalExpenseTypeIds!, this.preview);
        }
    }

    public override async componentWillUnmount(): Promise<void> {
        if (this.preview) {
            await super.componentWillUnmount();
            return;
        }

        clearInterval(this._pollIntervalId);
        this._pollIntervalId = undefined;

        if (!this._correctProcessed && this._needToProcessOnLeave) {

            const currentStep: ReportDefinitionItem = this.report.items[this._currentIndex];

            if (this.initialStep != null) {

                if (ObjectComparer.detectChanges(currentStep, this.initialStep) && this.isItemValid(currentStep)) {

                    currentStep.completed = true;
                    currentStep.isTouched = true;

                    await ch.getLayout().setSpinnerAsync(true);

                    await ReturnInspectionController.saveReturnInspectionStep(currentStep);

                    await ch.getLayout().setSpinnerAsync(false);
                }
            }

            await super.componentWillUnmount();
        }
    }

    public abstract renderContent(): React.ReactNode;

    private renderFaultInfo(fault: DeviceFault, index: number): React.ReactNode {
        const questionItem: QuestionReportItem | null = ((fault.step != null) &&
            (
                (fault.step.type == ReportItemType.Question) ||
                (fault.step.type == ReportItemType.QuestionPictures) ||
                (fault.step.type == ReportItemType.QuestionResource)
            ))
            ? fault.step as QuestionReportItem
            : null;

        return (
            <div key={index} className={rentaToolsStyles.info}>
                <p>{Localizer.wizardPageExtraInfoReported.format(fault.createdAt, TransformProvider.toString(fault.createdBy))}</p>
                <p>{Localizer.wizardPageExtraInfoStepNumber.format(fault.stepNumber)}</p>
                {
                    (questionItem) &&
                    (
                        <React.Fragment>
                            <p>{Localizer.wizardPageExtraInfoFaultLevel.format(questionItem.faultLevel!)}</p>
                            <p>{Localizer.wizardPageExtraInfoComment.format(questionItem.comment)}</p>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }

    private renderExitButton(): React.ReactNode {

        if (this.showExitButton && UnleashHelper.isEnabled(RentaToolsConstants.featureFlagRiMultiUser)) {

            return (
                <ArsenalButton fullWidth
                               id={"partyCompleteButton"}
                               className={rentaToolsStyles.navigationButton}
                               type={ButtonType.Light}
                               label={Localizer.returnInspectionExitInspection}
                               block={ch.getLayout().isSpinning()}
                               onClick={async () => this.exit()}
                               icon={{name: "fal fa-sign-out", className: rentaToolsStyles.exitIcon}}
                />
            )
        }

        return null;
    }

    public render(): React.ReactNode {
        const previousFaults: DeviceFault[] = this.findPreviousFaults();
        const permanentFaults: DeviceFault[] = this.findPermanentFaults();

        return (
            <PageContainer alertClassName={rentaToolsStyles.alert}
                           className={this.css(rentaToolsStyles.pageContainer, newStyles.pageContainer)}
            >

                <PageHeader title={this.getTitle()}
                            className={this.css(rentaToolsStyles.leftPageHeader, newStyles.pageHeader)}
                            onClick={async () => await this.openExtraInfoAsync()}>

                    <span className={rentaToolsStyles.leftPageHeader}>

                    {
                        (this.findPermanentFaults().length > 0) &&
                        (
                            <Icon name="fal exclamation-triangle" className="danger"
                                  onClick={async () => await this.openExtraInfoAsync()}
                                  size={IconSize.X3}
                                  style={IconStyle.Regular}/>
                        )
                    }

                    </span>

                    <span className={rentaToolsStyles.rightPageHeader}>
                        {this.getRightPageHeader()}
                    </span>

                </PageHeader>

                {
                    (this.steps.length > 0) && (this.currentIndex !== -1) && (!this.preview) &&
                    (
                        <ArsenalNavigation currentIndex={this.currentIndex}
                                           steps={this.steps}
                                           onNavigate={async (step) => await this.navigateAsync(step)}
                                           onClick={async () => await this.openSummaryAsync()}
                        />
                    )
                }

                {(<span className={rentaToolsStyles.wizardDescription}>{this.getWizardDescription()}</span>)}

                {
                    (this.isTelematics && this.telematicsData) &&
                    (
                        <TelematicsDataPanel telematicsData={this.telematicsData}/>
                    )
                }

                {this.renderContent()}

                <AdditionalExpensesPanel ref={this._additionalExpensesPanelRef}
                                         readonly={this.preview}
                                         returnInspectionStepNumber={this.currentIndex}
                                         additionalExpenseTypes={this.additionalExpenses}
                                         additionalExpenseValues={this.additionalExpenseValues}
                                         additionalExpensesCanBeAdded={this.additionalExpensesCanBeAdded}
                                         onChange={() => this.onChangeAdditionalExpenseValuesAsync()}
                />

                {
                    (!this.preview) &&
                    (
                        <React.Fragment>
                            <div className={rentaToolsStyles.buttonContainer}>
                                <ReturnInspectionButtons
                                    backAsync={async () => await this.backAsync()}
                                    continueAsync={async () => await this.nextAsync()}
                                    steps={this.report.items}
                                    showNextButton={this.showNextButton()}
                                    continueTitle={Localizer.genericContinue.toUpperCase()}
                                    backTitle={Localizer.genericReturnCaps}
                                    nextButtonDisabled={this.isNextButtonDisabled()}
                                    renderExitButton={this.renderExitButton()}
                                />
                            </div>
                        </React.Fragment>
                    )
                }

                {
                    (this.canThrowFault) &&
                    (
                        <Modal ref={this._extraInfoModalRef}
                               className={rentaToolsStyles.extraInfoModal}
                               contentClassName={rentaToolsStyles.contentModal}
                               title={Localizer.wizardPageExtraInfoTitle}
                               subtitle={`${this.deviceId} ${this.getTitle()}`}>
                            {
                                <div>
                                    <div>

                                        {
                                            (previousFaults.length == 0) &&
                                            (
                                                <p>{Localizer.wizardPageExtraInfoNoFaults}</p>
                                            )
                                            ||
                                            (
                                                previousFaults!.map(this.renderFaultInfo)
                                            )
                                        }
                                    </div>
                                    <div>
                                        {
                                            (permanentFaults.length != 0) &&
                                            (
                                                <div>
                                                    <p>{Localizer.wizardPageExtraInfoPermanentFaults}</p>
                                                    {
                                                        permanentFaults!.map(this.renderFaultInfo)
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            }

                        </Modal>
                    )
                }

            </PageContainer>
        );
    }
}