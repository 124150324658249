import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {ButtonType, Form, PageContainer, PageHeader, PageRow, TextInput} from "@renta-apps/athenaeum-react-components";
import newStyles from "@/pages/NewUI.module.scss";
import styles from "@/components/ReturnInspectionDevicesList/ReturnInspectionDevicesList.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import SearchItemResponse from "@/models/server/responses/SearchItemResponse";
import SearchItemViewModel from "@/models/server/SearchItemViewModel";
import Localizer from "@/localization/Localizer";
import pageStyles from "./DataManagementPage.module.scss";
import {SearchItemType} from "@/models/Enums";
import ArsenalButton from "@/components/ArsenalButton/ArsenalButton";
import DeleteItemRequest from "@/models/server/requests/DeleteItemRequest";
import SearchItemRelationsRequest from "@/models/server/requests/SearchItemRelationsRequest";
import SearchItemRelationsResponse from "@/models/server/responses/SearchItemRelationsResponse";
import DeleteItemResponse from "@/models/server/responses/DeleteItemResponse";
import {ch} from "@renta-apps/athenaeum-react-common";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import EnumProvider from "@/providers/EnumProvider";

interface IDataManagementPageProps {
}

interface IDataManagementPageState {
    items: SearchItemViewModel[];
    expandedItem: SearchItemViewModel | null;
    relationItems: SearchItemViewModel[] | null;
}

export default class DataManagementPage extends AuthorizedPage<IDataManagementPageProps, IDataManagementPageState> {
    state: IDataManagementPageState = {
        items: [],
        expandedItem: null,
        relationItems: null,
    };

    private getItemType(type: SearchItemType | null): string {
        return type ? EnumProvider.getSearchItemTypeText(type) : "-";
    }

    private async expandItemAsync(item: SearchItemViewModel, event: React.MouseEvent): Promise<void> {
        if (item.identifier === this.state.expandedItem?.identifier) {
            if (event.currentTarget != event.target) {
                return;
            }

            this.setState({expandedItem: null});
            await this.reRenderAsync();
            return;
        }

        // Load relations
        let request: SearchItemRelationsRequest = new SearchItemRelationsRequest();
        request.identifier = item.identifier;
        request.type = item.type;

        const response: SearchItemRelationsResponse = await this.postAsync("api/DataManagement/SearchItemRelations", request);

        this.setState({expandedItem: item, relationItems: response.items});
        await this.reRenderAsync();
    }

    private async deleteItem(item: SearchItemViewModel, markAsDeleted: boolean) : Promise<void> {
        let request: DeleteItemRequest = new DeleteItemRequest();
        request.identifier = item.identifier;
        request.type = item.type;
        request.markAsDeleted = markAsDeleted;

        const message: string = markAsDeleted
            ? Localizer.genericMarkAsDeletedConfirm
            : Localizer.genericDeletePermanentlyConfirm

        const confirmation: boolean = await this.confirmAsync(message);

        let response: DeleteItemResponse | null = null;
        if (confirmation){
            response = await this.postAsync("api/DataManagement/DeleteItem", request);
        }

        if (response?.success){
            await this.alertMessageAsync(Localizer.genericDeleteSuccess);

            const items: SearchItemViewModel[] = this.state.items;
            items.remove(item);

            this.setState({ items: items, expandedItem: null, relationItems: null});

            await this.reRenderAsync();
        }
        else if (!response || response?.success == false) {
            await this.alertErrorAsync(Localizer.genericDeleteFailed)
        }
    }

    private async exportItemAsync(item: SearchItemViewModel) : Promise<void> {
        let request = new SearchItemRelationsRequest();
        request.type = item.type;
        request.identifier = item.identifier;

        const data: FileModel = await this.postAsync("api/DataManagement/ExportItem", request);

        ch.download(data);
    }

    private async searchItem(data: Dictionary<string, any>): Promise<void> {
        const searchTerm: string = data.getValue("searchTerm") || "";


        const response: SearchItemResponse = await this.postAsync("api/DataManagement/SearchItems", searchTerm);

        this.setState({
            items: response.items ?? [],
        })
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.dashboard, rentaToolsStyles.pageContainer, newStyles.pageContainer, pageStyles.searchResults)}>

                <PageHeader title={Localizer.adminDataManagement}/>

                <PageRow className={newStyles.row}>

                    <div className="col">
                        <Form onSubmit={async (sender: Form, data) => await this.searchItem(data)}>
                            <TextInput required
                                       id="searchTerm"
                                       className={newStyles.input}
                                       placeholder={Localizer.genericSearchItemPlaceholder}

                            />
                        </Form>

                    </div>
                </PageRow>

                <PageRow className={newStyles.row}>

                    <div className={this.css(pageStyles.listItemHeader, "row")}>

                        <div className={this.css(pageStyles.headerItem, "col-md-3")}>
                            <span>{Localizer.genericIdentifier}</span>
                        </div>

                        <div className={this.css(pageStyles.headerItem, "col-md-3")}>
                            <span>{Localizer.genericName}</span>
                        </div>

                        <div className={this.css(pageStyles.headerItem, "col-md-3")}>
                            <span>{Localizer.genericType}</span>
                        </div>

                        <div className={this.css(pageStyles.headerItem, "col-md-2")}>
                            <span>{Localizer.genericDeleted}</span>
                        </div>
                    </div>

                    <div className={pageStyles.divider} />

                    <div data-cy={"item_list"} className={pageStyles.itemList}>
                        {
                            this.state.items.map((item, index) =>

                                <div key={`list-item-${index}`} className={this.css(pageStyles.listItem, "row")} onClick={async (event) => await this.expandItemAsync(item, event)}>

                                    <div className={"col-md-3"}>
                                        <span>{item.identifier}</span>
                                    </div>

                                    <div  className={"col-md-3"}>
                                        <span className={pageStyles.nameColumn}>
                                            {item.name}
                                        </span>
                                    </div>

                                    <div  className={"col-md-3"}>
                                        <span>{this.getItemType(item.type)}</span>
                                    </div>

                                    <div  className={"col-md-3"}>
                                        <span>{item.deleted ? Localizer.yes : Localizer.no}</span>
                                    </div>

                                    {
                                        (this.state.expandedItem?.identifier === item.identifier) &&
                                        <>
                                            <PageRow>
                                                <div className={pageStyles.divider} />
                                                <PageRow className={pageStyles.itemList}>
                                                    <div className={"row"}>
                                                        <h3> {Localizer.genericActions} </h3>
                                                    </div>

                                                    <div className={"row"}>

                                                        <ArsenalButton id={"export-btn"}
                                                                       className={pageStyles.actionButton}
                                                                       label={Localizer.genericExport}
                                                                       onClick={async () => await this.exportItemAsync(item) }
                                                                       confirm={Localizer.adminExportConfirm}/>

                                                        <ArsenalButton id={"mark-deleted-btn"}
                                                                       className={pageStyles.actionButton}
                                                                       label={Localizer.genericMarkAsDeleted}
                                                                       type={ButtonType.Warning}
                                                                       onClick={async () => await this.deleteItem(item, true) }/>

                                                        <ArsenalButton id={"permanent-delete-btn"}
                                                                       className={pageStyles.actionButton}
                                                                       label={Localizer.genericDeletePermanently}
                                                                       type={ButtonType.Danger}
                                                                       confirm={Localizer.genericDeletePermanentlyFirstConfirmation}
                                                                       onClick={async () => await this.deleteItem(item, false) }/>
                                                    </div>
                                                </PageRow>

                                                <PageRow className={pageStyles.itemList}>
                                                    <div className={"row"}>
                                                        <h3> {Localizer.genericDataRelations} </h3>
                                                    </div>

                                                    {
                                                        (this.state.relationItems) &&
                                                        <>
                                                            {
                                                                this.state.relationItems.map((relation, relationIndex) => {
                                                                    return (
                                                                        <>
                                                                            <div key={`list-item-${index}-relations-item-${relationIndex}`} className={this.css("row", pageStyles.relationItem)}>
                                                                                <div className={"col-md-3"}>
                                                                                    <span>{relation.identifier}</span>
                                                                                </div>

                                                                                <div  className={"col-md-3"}>
                                                                                    <span className={pageStyles.nameColumn}>
                                                                                        {relation.name}
                                                                                    </span>
                                                                                </div>

                                                                                <div  className={"col-md-3"}>
                                                                                    <span>{this.getItemType(relation.type)}</span>
                                                                                </div>

                                                                                <div  className={"col-md-3"}>
                                                                                    <span>{relation.deleted ? Localizer.yes : Localizer.no}</span>
                                                                                </div>

                                                                                <div className={pageStyles.divider}/>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        (!this.state.relationItems || this.state.relationItems.length == 0) &&
                                                        <>
                                                            <div className={"row"}>
                                                                <span> { Localizer.genericNoDataRelations } </span>
                                                            </div>
                                                        </>
                                                    }

                                                </PageRow>
                                            </PageRow>
                                        </>
                                    }

                                </div>

                            )
                        }
                    </div>
                </PageRow>


            </PageContainer>
        )
    }
}