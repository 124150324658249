import React from "react";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonType, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Report from "../Models/Report";
import QuestionPicturesReportItem from "../Models/QuestionPicturesReportItem";
import ArsenalPicturesCarousel from "../../components/ArsenalPicturesCarousel/ArsenalPicturesCarousel";
import ArsenalButton from "../../components/ArsenalButton/ArsenalButton";
import Device from "../Models/Device";
import DevicePicture from "@/models/server/DevicePicture";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";
import styles from "./PicturesPreviewPage.module.scss";
import ToolsUtility from "@/helpers/ToolsUtility";

interface IPicturesPreviewPageProps {
}

interface IPicturesPreviewPageState {
}

export default class PicturesPreviewPage extends AuthorizedPage<IPicturesPreviewPageProps, IPicturesPreviewPageState> {

    state: IPicturesPreviewPageState = {};

    private get title(): string {
        return `${this.device.externalId} ${this.device.name}\n
        ${(this.report != null)
            ? "{0} {1}".format(Localizer.reportReport, ToolsUtility.toDateString(this.report.completedAt))
            : (this.devicePictureItem != null)
                ? "{0} {1}".format(Localizer.enumDevicePictureTypeManual, ToolsUtility.toDateString(this.devicePictureItem.createdAt))
                : ""}`;
    }

    private get device(): Device {
        return RentaToolsController.device!;
    }

    private get report(): Report | null {
        return RentaToolsController.context.picturePreviewReport;
    }

    private get devicePictureItem(): DevicePicture | null {
        return RentaToolsController.context.devicePicture;
    }

    private get reportItem(): QuestionPicturesReportItem {
        let reportItem: QuestionPicturesReportItem | null = RentaToolsController.context.picturePreviewReportItem;

        if (reportItem == null)
            throw Error(Localizer.picturesPreviewPageErrorPicturesCannotBeFound);

        return reportItem;
    }

    private get reportPictures(): FileModel[] {
        const pictures: FileModel[] = [];

        pictures.push(...this.reportItem.pictures || [])

        if (this.reportItem.picture) {
            pictures.push(this.reportItem.picture);
        }

        return pictures;
    }

    private get pictureSubtitle(): string {
        return ((this.report != null) && (this.report.name != null))
            ? Localizer.get(Localizer.picturesPreviewPageArsenalPicturesCarouselSubtitle, this.report.name)
            : Localizer.enumDevicePictureTypeManual;
    }


    public getManual(): string {
        return Localizer.picturesPreviewPageManual;
    }

    async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.devicePictureItem) {
            return
        }
    }

    public render(): React.ReactNode {
        return (
            <PageContainer alertClassName={rentaToolsStyles.alert} className={this.css(rentaToolsStyles.pageContainer, styles.picturesPreview)}>

                <PageHeader title={this.title} className={rentaToolsStyles.leftPageHeader}>

                    <span className={rentaToolsStyles.rightPageHeader}/>

                </PageHeader>

                <span className={rentaToolsStyles.wizardDescription}>{this.reportItem.name}</span>

                <ArsenalPageRow className={styles.pictureContainer}>

                    <ArsenalPicturesCarousel subtitle={this.pictureSubtitle}
                                             items={this.reportPictures}
                    />


                    {
                        (this.reportItem.commented) &&
                        (
                            <ArsenalPageRow>
                                <span className={styles.comment}>{this.reportItem.comment}</span>
                            </ArsenalPageRow>
                        )
                    }

                </ArsenalPageRow>


                <ArsenalPageRow>
                    <ArsenalButton big block
                                   type={ButtonType.Orange}
                                   label={Localizer.genericReturnCaps}
                                   icon={{name: "fas chevron-left"}}
                                   onClick={async () => PageRouteProvider.back()}
                    />
                </ArsenalPageRow>

            </PageContainer>
        );
    }
}