import {executeWithIntercept, RouteData} from "@/helpers/CypressHelper";
import InspectionReportsPage from "@/tests/pages/InspectionReportsPage";

class ReturnInspectionPreviewPage {
    public elements = {};

    public routes = {
        getReport: {
            path: "api/ReturnInspection/GetReport",
            method: "GET",
        } as RouteData,
        getFuelTypes: {
            path: "api/classifier/GetFuelTypes",
            method: "POST",
        } as RouteData,
    };

    public openReport(reportIndex: number) {
        executeWithIntercept(() =>
                InspectionReportsPage.elements.inspectionReportInfo(reportIndex).click(),
            [this.routes.getFuelTypes]);
    }
}


export default new ReturnInspectionPreviewPage();
