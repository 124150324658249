import Dictionary from "typescript-collections/dist/lib/Dictionary";
import Localizer from "@/localization/Localizer";

export default class ErrorResponseDictionary {
    _errorDictionary = new Dictionary<string, string>();
    
    constructor() {
        this._errorDictionary.setValue("device_not_found_in_erp",  Localizer.eRPDeviceNotFound);
        this._errorDictionary.setValue("device_not_found_in_the_lease", Localizer.eRPDeviceNotFoundInLease);
        this._errorDictionary.setValue("erp_unknown", Localizer.eRPUnknownError);
        this._errorDictionary.setValue("invoicing_failed", Localizer.eRPInvoiceSaveFailed);
        this._errorDictionary.setValue("update_device_status_failed", Localizer.eRPDeviceStatusFailed);
        this._errorDictionary.setValue("save_service_failed", Localizer.eRPSaveServiceFailed);
        this._errorDictionary.setValue("RI_ONLY_CURRENT_USER_CAN_WORK", Localizer.returnInspectionNotCurrentUser);
    }
    
    public canHandleError(error: string): boolean {
        return this._errorDictionary.containsKey(error);
    }
    
    public getError(error: string): string {
        const value = this._errorDictionary.getValue(error);
        return value ? value : "";
    }
}