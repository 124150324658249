import DeviceManagementPage from "@/tests/pages/DeviceManagementPage";

describe('Admin device management tests', () => {

    beforeEach(() => {
        cy.login();
        cy.goToDashboard();
        cy.goToAdminPage();
    });

    it('Device management loads devices, fuel and adBlue types', () => {

        DeviceManagementPage.open();

        DeviceManagementPage.assertFuelTypes();

        DeviceManagementPage.assertAdBlueTypes();
    });

    it('Device management loads annual inspections tab', () => {

        DeviceManagementPage.open();

        DeviceManagementPage.assertAnnualInspections();
    });


    it('Device management has device faults tab and contains row', () => {

        DeviceManagementPage.open();

        DeviceManagementPage.searchDevice("92252")

        DeviceManagementPage.assertFaults();
    });
});