import React from "react";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {ImageInput} from "@renta-apps/athenaeum-react-components";
import ArsenalPageRow from "../../components/ArsenalPageRow/ArsenalPageRow";
import ArsenalQuestionInput from "../../components/ArsenalQuestionInput/ArsenalQuestionInput";
import QuestionPicturesReportItem from "../Models/QuestionPicturesReportItem";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import styles from "./QuestionPicturesPage.module.scss";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import ImageProvider from "@/providers/ImageProvider";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import FileService from "@/services/FileService";

export interface IQuestionPicturesPageProps {
}

interface IQuestionPicturesPageState extends IReturnInspectionWizardPageState {
    isLoading: boolean;

}

export default class QuestionPicturesPage extends ReturnInspectionWizardPage<IQuestionPicturesPageProps, IQuestionPicturesPageState> {

    state: IQuestionPicturesPageState = {
        isLoading: false,
    };

    private readonly _questionInputRef: React.RefObject<ArsenalQuestionInput> = React.createRef();

    private get pictures(): FileModel[] {
        return this.reportItem.pictures || (this.reportItem.pictures = []);
    }

    private get reportItem(): QuestionPicturesReportItem {
        return this.getReportItem<QuestionPicturesReportItem>();
    }

    protected isNextButtonDisabled(): boolean {
        return this.state.isLoading;
    }

    public getWizardDescription(): string {
        return this.reportItem.title ?? Localizer.picturePagePhotographDevice;
    }

    public async startImageUpload(image: FileModel): Promise<FileModel | null> {
        this.setState({
            isLoading: true,
        });

        const response =  await FileService.uploadFullImageAsync(image);

        this.setState({
            isLoading: false,
        });

        if(!response) {
            return null;
        }

        return response.fileModel;
    }

    public async onImageEditorChange(sender: ImageInput, pictures: FileModel[]) {

        this.reportItem.pictures = (!pictures || pictures.length <= 0)
            ? null
            : pictures;

        RentaToolsController.saveContext()
        await this.reRenderAsync()

        this.setState({
            isLoading: false,
        })
    }

    public async nextAsync(): Promise<void> {
        await this._questionInputRef.current!.validateAsync();

        if (QuestionPicturesReportItem.isValid(this.reportItem)) {
            await this.hideAlertAsync();
            await super.nextAsync();
            return;
        }

        const pictureUploaded: boolean = QuestionPicturesReportItem.hasPicture(this.reportItem);

        if (!pictureUploaded) {
            await this.alertErrorAsync(Localizer.picturePageAlertUploadPicture, false, true);
        } else if (this.reportItem.ok == null) {
            await this.alertErrorAsync(Localizer.questionPageAlertSelectPassOrFail, false, true);
        }
    }

    public renderContent(): React.ReactNode {
        return (
            <React.Fragment>

                <ArsenalQuestionInput hideTitle noImageInput
                                      ref={this._questionInputRef}
                                      model={this.reportItem}
                                      preview={this.preview}
                                      convertImage={async (file) => await FileService.uploadFullImageAsync(file)}
                />

                <ArsenalPageRow>
                    <div className={styles.picture}>
                        <div className={styles.pictureLabel}>
                            {Localizer.returnInspectionAddPictures}
                        </div>
                            <ImageInput multi
                                        noAutoSelect
                                        readonly={this.preview}
                                        pictures={this.pictures}
                                        className="flex-1"
                                        onChange={async (sender: ImageInput, pictures: FileModel[]) => this.onImageEditorChange(sender, pictures)}
                                        maxImageRequestSizeInBytes={RentaToolsConstants.originalImageMaxSize}
                                        convertImage={async (image: FileModel) => await this.startImageUpload(image)}
                                        fileTypes={[...RentaToolsConstants.supportedImageFileTypes]}
                                        imageUrl={src =>  ImageProvider.getImageUrl(src)}
                            />
                    </div>
                </ArsenalPageRow>
            </React.Fragment>
        );
    }
}