import Localizer from "../../../localization/Localizer";
import DashboardPage from "@/tests/pages/DashboardPage";
import DeviceAnnualInspectionsPage from "@/tests/pages/DeviceAnnualInspectionsPage";
import DeviceAnnualInspectionHistoryPage from "@/tests/pages/DeviceAnnualInspectionHistoryPage";
import DevicePage from "@/tests/pages/DevicePage";

describe('Device annual inspection details page tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.viewport(1480, 768);

        // CypressFeatureFlagService.enable(RentaToolsConstants.featureFlagAdminAiDeleteEnabled);

    })

    it('Area manager can not edit inspections', () => {
        cy.loginAsAreaManager()

        cy.goToDashboard();

        DashboardPage.openDevice("90740");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn().click();

        DeviceAnnualInspectionHistoryPage.elements.editBtn().should('not.exist');
    })

    it('Depo manager can edit inspections with no fixed remarks', () => {
        cy.loginAsDepoManager()
        cy.goToDashboard();

        DashboardPage.openDevice("90740");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn().click();

        DeviceAnnualInspectionHistoryPage.elements.editBtn().click();

        DeviceAnnualInspectionHistoryPage.elements.editPen().should('have.length', 3);
    })

    it ('Depo manager can edit inspections that have remarks done, with no pdf editing available', () => {
        cy.loginAsDepoManager()
        cy.goToDashboard();

        DashboardPage.openDevice("90749");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn().click();

        DeviceAnnualInspectionHistoryPage.testEditingRemarksFixed("777", "77", true);
    })

    it ('Technician cannot edit inspections that have remarks done', () => {
        cy.loginAsTechnician()
        cy.goToDashboard();

        DashboardPage.openDevice("90749");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn().click();

        DeviceAnnualInspectionHistoryPage.testTechnicianEditRemarksFixed();
    })

    it ('Admin can edit inspections that have remarks done, with pdf editing available', () => {
        cy.login();
        cy.goToDashboard();

        DashboardPage.openDevice("90749");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn().click();

        DeviceAnnualInspectionHistoryPage.testEditingRemarksFixed("999", "99", false);
    })

    it('Table is visible and has correct info', () => {
        cy.login();
        cy.goToDashboard();

        DashboardPage.openDevice("90740");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn().click();

        DeviceAnnualInspectionHistoryPage.assertTableRows();
    })

    it('ArsenalButtons have correct labels', () => {
        cy.login();
        cy.goToDashboard();

        DashboardPage.openDevice("90740");

        DevicePage.elements.annualInspectionBtn().click();

        DeviceAnnualInspectionsPage.elements.previousInspectionsBtn().click();

        DeviceAnnualInspectionHistoryPage.elements.backBtn().should('have.text', Localizer.devicePageReturnBack);
        DeviceAnnualInspectionHistoryPage.elements.editBtn().should('have.text', Localizer.annualInspectionHistoryPageEditAnnualInspectionRecords);
    })
});