import User from "@/models/server/User";

export default class AnnualInspectionVendor {

    public id: string = "";

    public name: string | null = null;

    public parentId: string | null = null;

    public userId: string | null = null;

    public user: User | null = null;

    public isRenta: boolean = false;

    public isDeleted: boolean = false;
    
    public isAnnualInspectionVendor: true = true;
}