import React, {useState} from "react";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import {ch} from "@renta-apps/athenaeum-react-common";
import {Icon, ImageModal, ModalSize} from "@renta-apps/athenaeum-react-components";
import ArsenalPagination from "../ArsenalPagination/ArsenalPagination";
import ImageProvider from "../../providers/ImageProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ArsenalPicturesCarousel.module.scss";

export interface IArsenalPicturesCarouselProps {
    title?: string;
    subtitle?: string;
    items: (FileModel | null)[];
    className?: string;
}


const ArsenalPicturesCarousel = (props: IArsenalPicturesCarouselProps) => {
    
    const [pictureNumber, setPictureNumber] = useState<number>(0);
    const [picture, setPicture] = useState<FileModel|null>(props.items[pictureNumber]);
    
    const modalId: string = "imageModal";
    
    const switchPictureAsync = async (pageNumber: number): Promise<void> => {
        const newPictureNumber: number = pageNumber - 1;
        setPictureNumber(newPictureNumber);
        setPicture(props.items[newPictureNumber]);
    }
    
    const getTitle = (): string => {
        return props.title || Localizer.arsenalPicturesInputModalTitle;
    }

    const getSubtitle = (): string => {
        return props.subtitle || "";
    }
    
    
    return (
        <div className={Utility.css(props.className, styles.arsenalPicturesCarousel)}>
                <div className={styles.pictures}>
                    <Icon name="fas camera" />
                    {
                        (picture) &&
                        (
                            <React.Fragment>

                                <div id={`id${modalId}`} 
                                     className={styles.picture}
                                     style={ImageProvider.getImageStyle(picture)}
                                     data-toggle="modal"
                                     data-target={`#${modalId}`}
                                />
                                
                                <ImageModal download
                                            id={modalId}
                                            title={getTitle()} 
                                            subtitle={getSubtitle()} 
                                            picture={picture}
                                            size={ch.desktop ? ModalSize.Large : undefined}
                                            downloadOriginal={true}
                                            imageUrl={image => ImageProvider.getImageUrl(image)}
                                />

                            </React.Fragment>
                        )
                    }
                </div>

            <ArsenalPagination loop
                               pageNumber={pictureNumber + 1} 
                               pageCount={props.items.length} 
                               className={styles.pagination}
                               onChange={async (pageNumber) => await switchPictureAsync(pageNumber)} 
            />
            
        </div>
    );

};

export default ArsenalPicturesCarousel;