import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {ch} from "@renta-apps/athenaeum-react-common";
import Context from "./Models/Context";
import RentaToolsStorage, {RentaToolsStorageTable} from "./RentaToolsStorage";

class RentaToolsFileStorage {

    private readonly Table: RentaToolsStorageTable = RentaToolsStorageTable.Files;

    public async setFileDataAsync(key: string, fileSrc: string): Promise<void> {
        if (key) {
            await RentaToolsStorage.setDataAsync(this.Table, key, fileSrc);
        }
    }

    public async getFileDataAsync(key: string): Promise<string | null> {
        return (key)
            ? await RentaToolsStorage.getDataAsync<string>(this.Table, key)
            : null;
    }

    private invokeWrapModel(model: any, wrap: boolean, path: string): (() => Promise<void>)[] {
        let callbacks: (() => Promise<void>)[] = [];
        if ((model != null) && (typeof model === "object")) {
            const isFileModel: boolean = ((model.constructor.name === FileModel.name) || (model.isFileModel));
            if (isFileModel) {
                const fileModel = model as FileModel;
                if (wrap) {
                    const key: string = fileModel.id || ch.getComponentId();
                    const src: string = fileModel.src;
                    if (src) {
                        callbacks.push(async () => await this.setFileDataAsync(key, src));
                        fileModel.src = key;
                        //console.log("_replace: " + path + " " + (src ? "[src]" : "NULL") + " to " + key);
                    }
                } else {
                    const key: string = fileModel.src;
                    if (key) {
                        callbacks.push(async () => {
                            const src: string | null = await this.getFileDataAsync(key);
                            fileModel.src = src || "";
                            //console.log("_restore: " + path + " " + key + " to " + (src ? "[src]" : "NULL"));
                        });
                    }
                }
            } else if (Array.isArray(model)) {
                const length = model.length;
                for (let i: number = 0; i < length; i++) {
                    const value: any = model[i];
                    const innerCallbacks: (() => Promise<void>)[] = this.invokeWrapModel(value, wrap, path + "[" + i + "]");
                    callbacks.push(...innerCallbacks);
                }
            } else {
                for (let property in model) {
                    if (model.hasOwnProperty(property)) {
                        const value: any = model[property];
                        const innerCallbacks: (() => Promise<void>)[] = this.invokeWrapModel(value, wrap, path + "." + property);
                        callbacks.push(...innerCallbacks);
                    }
                }
            }
        }

        return callbacks;
    }

    public async processModelAsync(model: any, wrap: boolean, callback: (() => Promise<void>) | null = null): Promise<void> {
        const callbacks: (() => Promise<void>)[] = this.invokeWrapModel(model, wrap, "");
        const length: number = callbacks.length;

        for (let i: number = 0; i < length; i++) {
            const callback = callbacks[i];
            await callback();
        }

        if (callback) {
            await callback();
        }
    }

    public serialize(model: any): string {

        let json: string = JSON.stringify(model);
        let copy: any = Context.parse(json);

        //await is not needed, do not wait
        this.processModelAsync(copy, true);

        json = JSON.stringify(copy);

        return json;
    }
}

//Singleton
export default new RentaToolsFileStorage();