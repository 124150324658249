import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Icon, IconSize, IWizardStep} from "@renta-apps/athenaeum-react-components";

import Localizer from "../../localization/Localizer";

import styles from "./ArsenalNavigation.module.scss";
import newStyles from "../../pages/NewUI.module.scss";

interface IArsenalNavigationProps {
    steps: IWizardStep[];
    currentIndex: number;

    onNavigate?(step: IWizardStep): Promise<void>;

    onClick?(): Promise<void>;
}

interface IArsenalNavigationState {
    edit: boolean;
    value: string
}

export default class ArsenalNavigation extends BaseComponent<IArsenalNavigationProps, IArsenalNavigationState> {

    state: IArsenalNavigationState = {
        edit: false,
        value: ""
    };

    private readonly _ref: React.RefObject<HTMLInputElement> = React.createRef();

    private get lastIgnoredStepsCount(): number {
        return 1;
    }

    private get steps(): IWizardStep[] {
        return this.props.steps;
    }

    private get currentIndex(): number {
        return this.props.currentIndex;
    }

    private get stepIndexVisible(): boolean {
        return (this.currentIndex < this.steps.length - this.lastIgnoredStepsCount);
    }

    private get currentStep(): IWizardStep {
        return this.steps[this.currentIndex];
    }

    private async onEditIndexAsync(value: string): Promise<void> {
        if ((value.length === 0) || ((value.length <= this.lastIgnoredStepsCount) && (/^\d+$/.test(value)))) {
            if (this.state.value !== value) {
                await this.setState({value});
            }
        }
    }

    private async onEditKeyUpAsync(keyCode: number): Promise<void> {
        if (keyCode === 27) {
            await this.setState({edit: false});
        } else if (keyCode === 13) {

            let index: number = (parseInt(this.state.value));

            await this.setState({value: "", edit: false});

            if (!isNaN(index)) {
                index -= 1;
                if ((index >= 0) && (index <= this.steps.length - 1) && (index !== this.currentIndex) && (this.props.onNavigate)) {
                    const step: IWizardStep = this.steps[index];
                    await this.props.onNavigate(step);
                }
            }
        }
    }

    private async onEditBlurAsync(): Promise<void> {
        await this.setState({edit: false});
    }

    private async onClickHandlerAsync(): Promise<void> {
        if ((!this.state.edit) && (this.props.onClick)) {
            await this.props.onClick();
        }
    }

    render(): React.ReactNode {
        return (
            <div className={this.css(styles.arsenalNavigation, newStyles.navigation)}>
                <div onClick={async () => await this.onClickHandlerAsync()}>
                    <div className={this.css(styles.title, newStyles.title)}>
                        <span id={"item_title"} data-cy={"item_title"}>{this.currentStep.title}</span>
                    </div>

                    <div className={this.css(styles.icon)}>
                        {(this.currentStep.icon) && (this.stepIndexVisible) && (<Icon name={this.currentStep.icon.name}/>)}
                    </div>

                    {
                        (this.stepIndexVisible) &&
                        (
                            <span className={this.css(styles.index, newStyles.index)}>
                                {
                                    (this.state.edit) &&
                                    (<input ref={this._ref} size={1} maxLength={2}
                                            value={this.state.value}
                                            onChange={async (e: React.FormEvent<HTMLInputElement>) => await this.onEditIndexAsync(e.currentTarget.value)}
                                            onKeyUp={async (e: React.KeyboardEvent<HTMLInputElement>) => await this.onEditKeyUpAsync(e.keyCode)}
                                            onBlur={async () => this.onEditBlurAsync()}
                                        />
                                    )
                                }
                                {
                                    (!this.state.edit) &&
                                    (
                                        <span>{this.currentIndex + 1}</span>
                                    )
                                }

                                &nbsp;/ {this.steps.length - this.lastIgnoredStepsCount}

                            </span>
                        )
                    }

                    {(!this.stepIndexVisible) && (this.currentStep.icon) && (<Icon className={styles.icon} name={this.currentStep.icon.name} size={IconSize.X2}/>)}
                    {(!this.stepIndexVisible) && (
                        <div className={this.css(styles.title, newStyles.title, newStyles.marginTop)}>
                            <span>{Localizer.returnInspectionSteps}</span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};