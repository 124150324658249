import {FileModel} from "@renta-apps/athenaeum-toolkit";
import {Fault} from "@/models/server/DeviceInfo";

export default class RecordObservationRequest {
    public deviceId: string = "";

    public comment: string = "";

    public pictures: FileModel[] = [];

    public depoId: string | null = null;

    public faultComment: string = "";

    public observationDate: Date | null = null;

    public latitude: number | null = null;

    public longitude: number | null = null;

    public contractId: string = "";

    public faults: Fault[] = [];
}