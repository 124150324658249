import React from "react";
import {Checkbox, ICheckboxProps} from "@renta-apps/athenaeum-react-components";
import User from "@/models/server/User";
import Localizer from "@/localization/Localizer";

import styles from "./FaultCheck.module.scss";

interface IFaultCheckProps extends ICheckboxProps {
    title?: string;
    comment?: string;
    createdBy?: User | null;
    createdAt?: Date | null;
    fixedBy?: User | null;
    fixedAt?: Date | null;
}

export default class FaultCheck extends Checkbox<IFaultCheckProps> {
    protected getContainerClassname(): string {
        return styles.faultCheckContainer;
    }

    public async toggleAsync(event: React.MouseEvent<HTMLDivElement> | null): Promise<void> {
        if (event) {
            event.stopPropagation();
        }

        return super.toggleAsync();
    }

    public renderInput(): React.ReactNode {
        return (
            <div data-cy={"faultCheck"} className={this.css(styles.faultCheck, this.readonly && styles.readonly)} onClick={async (event) => await this.toggleAsync(event)}>

                <div className="d-flex justify-content-between">

                    {
                        (this.props.title) &&
                        (
                            <span>{this.props.title}</span>
                        )
                    }

                    {
                        super.renderInput()
                    }

                </div>

                {
                    (this.props.createdBy) &&
                    (
                        <span className="d-block">{Localizer.deviceServicePageFaultsFoundBy.format(this.props.createdBy)}</span>
                    )
                }

                {
                    (this.props.createdAt) &&
                    (
                        <span className="d-block">{Localizer.deviceServicePageFaultsFoundAt.format(this.props.createdAt)}</span>
                    )
                }

                {
                    (this.props.fixedBy) &&
                    (
                        <span className="d-block">{Localizer.deviceServicePageFaultsFixedBy.format(this.props.fixedBy)}</span>
                    )
                }

                {
                    (this.props.fixedAt) &&
                    (
                        <span className="d-block">{Localizer.deviceServicePageFaultsFixedAt.format(this.props.fixedAt)}</span>
                    )
                }

                {
                    (this.props.comment) &&
                    (
                        <span>{Localizer.deviceServicePagePlaceholderComment} {this.props.comment}</span>
                    )
                }

            </div>
        );
    }
}