import React, {useState} from "react";
import {Modal} from "@renta-apps/athenaeum-react-components";
import ToolsUtility from "@/helpers/ToolsUtility";
import DeviceFailedItemViewModel from "@/models/server/DeviceFailedItemViewModel";
import {FailedItemType} from "@/models/Enums";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

import rentaToolsStyles from "@/pages/RentaTools.module.scss";

interface IFailedItemsModalProps{
    subTitle: string;
    modalRef: React.RefObject<Modal>;
    deviceExternalId: string;
    openReportAsync : (reportId: string) => Promise<void>;
}


const FailedItemsModal = (props: IFailedItemsModalProps) => {

    const [failedItems, setFailedItems] = useState<DeviceFailedItemViewModel[]>([]);

    const hasExtraInfo = (): boolean => {
        return failedItems.length > 0;
    }

    const getFailedItems = (): DeviceFailedItemViewModel[] => {
        return failedItems;
    }

    const openReportAsync = async (reportId: string | null): Promise<void> => {
        if (!reportId) {
            return;
        }

        await props.openReportAsync(reportId);
    }

    const onOpen = async (): Promise<void> => {
        const result = await RentaToolsController.getFailedReportItemsAsync(props.deviceExternalId);
        setFailedItems(result);
    }

    const getTitle = (item: FailedItemType): React.ReactNode => {
        switch (item) {
            case FailedItemType.DeviceFault:
                return <p>{Localizer.genericFaultGeneratedFromInspection}</p>;
            case FailedItemType.ManualFault:
                return <p>{Localizer.genericFaultGeneratedManually}</p>;
            case FailedItemType.ReturnInspectionStep:
                return <p>{Localizer.genericFailedReturnInspectionStep}</p>;
            default:
                return <p>{Localizer.genericUnknown}</p>;
        }
    }

    const getFaultDate = (item: DeviceFailedItemViewModel): string => {
        return `${Localizer.genericFoundAt} ${ToolsUtility.toDateString(item.completedAt)}`;
    }

    const getContentForItem = (item: DeviceFailedItemViewModel): React.ReactNode => {

        const faultdate: string = getFaultDate(item);

        switch (item.itemType) {
            case FailedItemType.DeviceFault:
                return (
                    <>
                        {
                            (item.completedAt) &&
                            (
                                <p>{faultdate}, {item.inspectedByName}, {item.depoCostPool}</p>
                            )
                        }
                        <p>{Localizer.devicePageExtraDeviceInfoTextState} {item.stepNumber}/{item.stepsCount} {item.title}</p>
                        <p>{Localizer.wizardPageExtraInfoFaultLevel.format(item.faultLevel!)}</p>
                        <p>{Localizer.devicePageExtraDeviceInfoTextComment}: {item.comment}</p>
                    </>
                )
            case FailedItemType.ManualFault:
                return (
                    <>
                        {
                            (item.completedAt) &&
                            (
                                <p>{faultdate}, {item.inspectedByName}, {item.depoCostPool}</p>
                            )
                        }
                        <p>{Localizer.wizardPageExtraInfoFaultLevel.format(item.faultLevel!)}</p>
                        <p>{Localizer.devicePageExtraDeviceInfoTextComment}: {item.comment}</p>
                    </>
                )
            case FailedItemType.ReturnInspectionStep:
                return (
                    <>
                        {
                            (item.completedAt) &&
                            (
                                <p>{faultdate}, {item.inspectedByName}, {item.depoCostPool}</p>
                            )
                        }
                        <p>{Localizer.devicePageExtraDeviceInfoTextState} {item.stepNumber}/{item.stepsCount} {item.title}</p>
                        <p>{Localizer.devicePageExtraDeviceInfoTextComment}: {item.comment}</p>
                    </>
                )
            default:
                return null;
        }
    }

    return (
        <Modal ref={props.modalRef}
               onOpen={async () => await onOpen()}
               className={rentaToolsStyles.extraInfoModal}
               contentClassName={rentaToolsStyles.contentModal}
               title={Localizer.devicePageExtraDeviceInfo} subtitle={props.subTitle}>
            {
                (!hasExtraInfo) &&
                (
                    <p>{Localizer.devicePageExtraDeviceInfoTextNoFailedSteps}</p>
                )
                ||
                (
                    <div>
                        {
                            (failedItems) && (
                                failedItems.map((item, index) => (
                                    <div id={`failed_item_${index}`} key={index} className={rentaToolsStyles.info} data-cy="failed_item"
                                         onClick={async () => await openReportAsync(item.reportId)}>

                                        {
                                            getTitle(item.itemType)
                                        }

                                        {
                                            getContentForItem(item)
                                        }

                                    </div>
                                )))
                        }
                    </div>
                )
            }
        </Modal>
    );
}

export default FailedItemsModal;