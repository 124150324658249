import ReturnedDeviceList from "@/tests/pages/ReturnedDeviceList";
import DeviceOrderPage from "@/tests/pages/DeviceOrderPage";
import DepoSelectionPage from "@/tests/pages/DepoSelectionPage";

describe("Depo selection page tests", () => {
    it("User with one depo should be forwarded to the order edit page", () => {
        cy.loginAsDepoManager();

        cy.visit("Dashboard");

        cy.goToReturnedDevicesPage();

        DeviceOrderPage.interceptRequest();

        ReturnedDeviceList.openEditOrder();

        DeviceOrderPage.waitForRequest();

        DeviceOrderPage.validateDeviceOrder();
    });

    it("User with multiple depos should be able to pick depo", () => {
        cy.login();

        cy.visit("Dashboard");

        cy.goToReturnedDevicesPage();

        ReturnedDeviceList.openEditOrder();

        cy.wait(1000);

        DepoSelectionPage.depoShouldBeVisible('101');
        DepoSelectionPage.depoShouldBeVisible('103');
    });
});