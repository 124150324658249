import React from "react";
import { CheckStatus, FaultLevel } from "@/models/Enums";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import ChecksReportItem from "@/pages/Models/ChecksReportItem";
import CheckItem from "@/pages/Models/CheckItem";
import AdvancedChecksReportItem from "@/pages/Models/AdvancedChecksReportItem";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import AdvanceCheck from "@/components/AdvenceCheck/AdvanceCheck";
import RentaToolsController from "@/pages/RentaToolsController";
import Localizer from "@/localization/Localizer";

export interface ICheckState {
  status: CheckStatus;
  id: string;
  isValid: boolean;
  validationText?: string;
  isRequired: boolean;
  commentText: string;
  text: string;
  ref: React.MutableRefObject<HTMLDivElement>;
  faultLevel: FaultLevel | null;
}

export interface IAdvancedChecksPageState extends IReturnInspectionWizardPageState{
  checks: ICheckState[];
  isTouched: boolean;
}

export default class AdvancedChecksPage extends ReturnInspectionWizardPage<
  {}, IAdvancedChecksPageState> {
  state: IAdvancedChecksPageState = {
    checks: [],
    isTouched: false,
  };

  private get reportItem(): ChecksReportItem {
    return this.getReportItem<ChecksReportItem>();
  }

  private getStatus(item: CheckItem) {
    if (item.ok === true) {
      return CheckStatus.Yes;
    }

    if (item.ok === false) {
      return CheckStatus.No;
    }

    if (item.isSkipped) {
      return CheckStatus.Skip;
    }

    return CheckStatus.Unselected;
  }

  async initializeAsync(): Promise<void> {
    await super.initializeAsync();

    const item = this.getReportItem();

    const checks = item.checks?.map(
      (p) =>
        ({
          status: this.getStatus(p),
          id: p.id,
          isValid: true,
          validationText: "",
          isRequired: p.isRequired,
          commentText: p.comment,
          text: p.name,
          ref: React.createRef<HTMLDivElement>(),
          faultLevel: p.faultLevel,
        }) as ICheckState,
    );

    if (checks) {
      for (let i = 0; i < checks.length; i++) {
        this.validateCheck(checks[i]);
      }

      this.setState({ checks: checks });
    }
  }

  private getOkStatus(status: CheckStatus) {
    switch (status) {
      case CheckStatus.Yes:
        return true;
      case CheckStatus.No:
        return false;
      default:
        return null;
    }
  }

  private onChange = (id: string, checkStatus: CheckStatus) => {
    const check = this.state.checks.find((p) => p.id === id);

    if (check != null) {
      const checks = [...this.state.checks];
      const index = this.state.checks.indexOf(check);
      checks[index].status = checkStatus;

      this.validateCheck(checks[index]);

      this.reportItem.checks = checks.map(
        (p) =>
          ({
            id: p.id,
            ok: this.getOkStatus(p.status),
            comment: p.commentText,
            isRequired: p.isRequired,
            isSkipped: p.status === CheckStatus.Skip,
            name: p.text,
            faultLevel: p.faultLevel,
          }) as CheckItem,
      );

      this.reportItem.ok = AdvancedChecksReportItem.isOk(this.reportItem);

      RentaToolsController.saveContext();

      this.setState({ checks: checks });
    }
  };

  private onCommentChange(id: string, value: string) {
    const check = this.state.checks.find((p) => p.id === id);
    if (check != null) {
      const checks = [...this.state.checks];
      const index = this.state.checks.indexOf(check);
      checks[index].commentText = value;

      this.validateCheck(checks[index]);

      this.reportItem.checks = checks.map(
        (p) =>
          ({
            id: p.id,
            ok: this.getOkStatus(p.status),
            comment: p.commentText,
            isRequired: p.isRequired,
            isSkipped: p.status === CheckStatus.Skip,
            name: p.text,
            faultLevel: p.faultLevel,
          }) as CheckItem,
      );

      RentaToolsController.saveContext();

      this.setState({ checks: checks });
    }
  }

  private validateCheck(check: ICheckState) {
    if (check.isRequired && check.status === CheckStatus.Unselected) {
      check.isValid = false;
      return;
    }

    if (!check.commentText && check.status === CheckStatus.No) {
      check.isValid = false;
      check.validationText = Localizer.advancedChecksPageCommentValidationText;
      return;
    }

    check.isValid = true;
  }

  public async nextAsync(): Promise<void> {
    this.setState({ isTouched: true });

    const validationResult = this.state.checks.firstOrDefault(
      (p) => !p.isValid,
    );

    if (validationResult) {
      window.requestAnimationFrame(
        () =>
          validationResult.ref.current?.scrollIntoView({
            block: "end",
            behavior: "smooth",
          }),
      );
    } else {
      return super.nextAsync();
    }
  }

  renderContent(): React.ReactNode {
    return (
      <div>
        {this.state.checks.map((check) => (
          <AdvanceCheck
            id={check.id}
            status={check.status}
            canBeSkipped={!check.isRequired}
            onChange={this.onChange}
            key={check.id}
            isValid={this.state.isTouched && !check.isValid}
            validationText={check.validationText}
            commentText={check.commentText}
            onCommentChange={(id, value) => this.onCommentChange(id, value)}
            text={check.text}
            ref={check.ref}
            readonly={this.preview}
          />
        ))}
      </div>
    );
  }
}
