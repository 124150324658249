import React from "react";
import ChecksReportItem from "../Models/ChecksReportItem";
import CheckItem from "@/pages/Models/CheckItem";
import ArsenalPageRow from "@/components/ArsenalPageRow/ArsenalPageRow";
import {SwitchNullable} from "@renta-apps/athenaeum-react-components";
import ReturnInspectionWizardPage from "@/pages/ReturnInspectionWizardPage";
import IReturnInspectionWizardPageState from "@/models/base/IReturnInspectionWizardPageState";
import RentaToolsController from "../RentaToolsController";
import Localizer from "../../localization/Localizer";

import rentaToolsStyles from "../RentaTools.module.scss";

export interface IChecksPageProps {
}

interface IChecksPageState extends IReturnInspectionWizardPageState {
}

export default class ChecksPage extends ReturnInspectionWizardPage<IChecksPageProps, IChecksPageState> {

    state: IChecksPageState = {
    };
    
    private async setValueAsync(checkItem: CheckItem, value: boolean | null): Promise<void> {
        checkItem.ok = value;
        this.reportItem.ok = ChecksReportItem.isOk(this.reportItem)
        RentaToolsController.saveContext();
    }

    private get reportItem(): ChecksReportItem {
        return this.getReportItem<ChecksReportItem>();
    }
    
    private get checks(): CheckItem[] {
        return this.reportItem.checks || [];
    }

    public getWizardDescription(): string {
        return "";
    }

    public async nextAsync(): Promise<void> {
        if (ChecksReportItem.isValid(this.reportItem)) {
            await super.nextAsync();
        }
    }
    
    public renderCheckbox(checkItem: CheckItem, index: number): React.ReactNode {
        
        return (
            <ArsenalPageRow key={index}>
                <SwitchNullable inline excludeNull className={this.css(rentaToolsStyles.arsenalSwitchWidget)}
                                label={Localizer.get(checkItem.name)}
                                readonly={this.preview}
                                value={checkItem.ok}
                                onChange={async (sender, value) => await this.setValueAsync(checkItem, value)}
                />
            </ArsenalPageRow>
        );
    }

    public renderContent(): React.ReactNode {

        return (
            <React.Fragment>
                
                {
                    this.checks.map((checkItem, index) => this.renderCheckbox(checkItem, index))
                }
                
            </React.Fragment>
        );
    }
}