import React, { useEffect, useRef, useState } from "react";
import useAutosizeTextArea from "@/hooks/useAutosizeTextArea";
import { Utility } from "@renta-apps/athenaeum-toolkit";

import styles from "./ExpedableTextInput.module.scss";

export interface IExpendableTextInput {
  inputName: string;
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  validationError?: boolean;
  validationText?: string;
  disabled?: boolean;
}

const ExpandableTextInput = ({
  inputName,
  label,
  value,
  onChange,
  validationError,
  validationText,
  disabled,
}: IExpendableTextInput) => {
  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, []);

  const [currentValue, setValue] = useState("");

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, currentValue);
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;

    setValue(val);

    onChange && onChange(val);
  };

  return (
    <div className={styles.textBoxWrapper}>
      {label && (
      <label className={Utility.css(validationError && styles.errorLabel)}>
        {label}
      </label>
      )}
      <textarea
        id={inputName}
        onChange={handleChange}
        ref={textAreaRef}
        rows={1}
        value={value}
        className={Utility.css(
          styles.textBox,
          validationError && styles.validationError,
        )}
        disabled={disabled}
      />
      {validationError && (
        <p className={styles.errorMessage} data-cy={"error_description"}>{validationText}</p>
      )}
    </div>
  );
};

export default ExpandableTextInput;
