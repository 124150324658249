import React from "react";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Button, ButtonContainer, ButtonType, Dropdown, EmailInput, Form, PageContainer, PageHeader, PageRow, PhoneInput, Spinner, Tab, TabContainer, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import SaveUserRequest from "../../models/server/requests/SaveUserRequest";
import PageDefinitions from "../../providers/PageDefinitions";
import {AuthType, NotificationType} from "@/models/Enums";
import SaveUserResponse from "../../models/server/responses/SaveUserResponse";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import NotificationSettings from "@/components/NotificationSettings/NotificationSettings";
import TransformProvider from "../../providers/TransformProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "../../localization/Localizer";

interface IAccountPageProps  {
}

interface IAccountPageState {
    availableNotificationTypes: NotificationType[];
    isLoading: boolean;
}

export default class AccountPage extends AuthorizedPage<IAccountPageProps, IAccountPageState> {

    state: IAccountPageState = {
        availableNotificationTypes: [],
        isLoading: false
    };

    public get user(): User {
        return this.getUser();
    }

    public get isLoading(): boolean {
        return this.state.isLoading;
    }

    public get availableNotificationTypes(): NotificationType[] {
        return this.state.availableNotificationTypes;
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {

        const request = new SaveUserRequest();
        request.id = this.user.id;
        request.authType = this.user.authType;
        request.canInvoiceFuelingAndWashing = this.user.canInvoiceFuelingAndWashing;
        request.canInvoiceAdditionalExpenses = this.user.canInvoiceAdditionalExpenses;

        this.copyTo(data, request, this.user);

        const response: SaveUserResponse = await this.postAsync("api/account/saveUserAccount", request);

        if (response.userAlreadyExists) {
            await this.alertErrorAsync(Localizer.accountPageAlertErrorUserAlreadyExists, true);
            return;
        }

        if (response.invalidPhoneNumber) {
            await this.alertErrorAsync(Localizer.accountPageAlertErrorInvalidPhoneNumber, true);
            return;
        }

        const message: string = Utility.format(Localizer.userManagementPageAlertMessageAsyncAccountSaved, TransformProvider.userToString(this.user));
        await this.alertMessageAsync(message, true);
    }

    public isSpinning(): boolean {
        return (this.isLoading || super.isSpinning());
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        await this.setState({isLoading: true});

        const isNotificationSettingsEnabled: boolean = UnleashHelper.isEnabled(RentaToolsConstants.featureFlagNotificationSettingsEnabled);

        if (isNotificationSettingsEnabled) {
            const availableNotificationTypes: NotificationType[] = await this.postAsync("/api/account/getUserAvailableNotificationTypes");

            await this.setState({availableNotificationTypes});
        }

        await this.setState({isLoading: false});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer>

                {
                    (this.isSpinning()) && <Spinner/>
                }

                <PageHeader title={Localizer.myAccountPageTitle}/>

                <PageRow>
                    {
                        (!this.isLoading) &&
                        (
                            <div className="col">
                                <TabContainer id="accountTabs">
                                    <Tab id="personal"
                                         title={Localizer.myAccountPageMyInfoTabTitle}
                                    >
                                        {
                                            (this.user) &&
                                            (
                                                <Form id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(data)}>

                                                    <TwoColumns>

                                                        <EmailInput id="email" label={Localizer.componentFormEmail}
                                                                    value={this.user.email}
                                                                    required={(this.user.authType == AuthType.Email)}
                                                                    readonly={!!this.user.email && (this.user.authType == AuthType.Email)}/>

                                                        <PhoneInput id="phone" label={Localizer.componentFormPhone}
                                                                    value={this.user.phone}
                                                                    required={(this.user.authType == AuthType.Phone)}
                                                                    readonly={!!this.user.phone && (this.user.authType == AuthType.Phone)}
                                                        />

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <TextInput id="firstName" label={Localizer.componentFormFirstName} value={this.user.firstName} required/>

                                                        <TextInput id="lastName" label={Localizer.componentFormLastname} value={this.user.lastName} required/>

                                                    </TwoColumns>

                                                    <TwoColumns>

                                                        <TextInput id="middleName" label={Localizer.componentFormMiddleName} value={this.user.middleName}/>

                                                        <Dropdown id="language" label={Localizer.componentFormLanguage} required
                                                                  items={Localizer.supportedLanguages}
                                                                  selectedItem={Localizer.findLanguage(this.user.language)}
                                                        />

                                                    </TwoColumns>

                                                    <ButtonContainer>

                                                        <Button type={ButtonType.Blue} route={PageDefinitions.changePasswordRoute} label={Localizer.changePasswordButton}/>

                                                        <Button type={ButtonType.Orange} label={Localizer.componentFormSave} submit/>

                                                    </ButtonContainer>

                                                </Form>
                                            )
                                        }
                                    </Tab>

                                    {
                                        (this.isAdmin && this.availableNotificationTypes.length > 0 && UnleashHelper.isEnabled(RentaToolsConstants.featureFlagNotificationSettingsEnabled)) &&
                                        (
                                            <Tab id="userNotificationsSettings"
                                                 title={Localizer.myAccountPageNotificationTabTitle}
                                            >
                                                <NotificationSettings isGlobal={false}
                                                                      availableNotificationTypes={this.availableNotificationTypes}
                                                />

                                            </Tab>
                                        )
                                    }

                                </TabContainer>

                            </div>
                        )
                    }

                </PageRow>

            </PageContainer>
        );
    }
}