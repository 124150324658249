import React from "react";
import {
    BaseAsyncComponent,
    ch,
    IBaseAsyncComponentState,
    IBaseComponent,
    PageRouteProvider
} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, IconSize} from "@renta-apps/athenaeum-react-components";
import DeviceInfo, {ReturnInspectionSteps} from "@/models/server/DeviceInfo";
import DevicesFiltersModal from "../DevicesFiltersModal/DevicesFiltersModal";
import {
    DeviceListOrderType,
    DeviceStatus,
    MaintenanceStatus,
    ResourceItemType,
    ReturnInspectionStatus
} from "@/models/Enums";
import ToolsPageHeader from "@/components/ToolsPageHeader/ToolsPageHeader";
import ListUnInspectedDevicesRequest from "@/models/server/requests/ListUnInspectedDevicesRequest";
import ToolsUtility from "@/helpers/ToolsUtility";
import RentaToolsConstants from "@/helpers/RentaToolsConstants";
import IActivePhase from "@/models/IActivePhase";
import BaseListDevicesRequest from "@/models/server/requests/BaseListDevicesRequest";
import FiltersData from "@/pages/Models/FiltersData";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import ExpandableMenu from "@/components/ExpandableMenu/ExpandableMenu";
import ReturnInspectionController from "@/pages/ReturnInspectionController";
import UnleashHelper from "@/helpers/UnleashHelper";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ReturnInspectionDevicesList.module.scss";
import newStyles from "@/pages/NewUI.module.scss";
import rentaToolsStyles from "@/pages/RentaTools.module.scss";

interface IReturnInspectionDevicesListProps {
    sticky?: boolean
    columnHeader?: boolean
    title: string;
    noDataText: string;
    request: BaseListDevicesRequest;
    filtersData: FiltersData;
    fetchData(sender: IBaseComponent, request: BaseListDevicesRequest, scrollTrigger : boolean): Promise<DeviceInfo[]>;
    onDeviceOpen(sender: IBaseComponent, device: DeviceInfo): Promise<void>;
    returnInspectionList?: boolean;
    renderTitle?(): React.ReactNode;
}

export interface IReturnInspectionDevicesListState extends IBaseAsyncComponentState<DeviceInfo[]> {
    request: BaseListDevicesRequest;
    isFetching: boolean;
    shortView : boolean;
}

export default class ReturnInspectionDevicesList extends BaseAsyncComponent<IReturnInspectionDevicesListProps, IReturnInspectionDevicesListState, DeviceInfo[]> {

    state: IReturnInspectionDevicesListState = {
        data: null,
        isLoading: false,
        request: this.props.request,
        isFetching: false,
        shortView : ReturnInspectionDevicesList.initializeViewSettings(),
    };


    private static initializeViewSettings = () : boolean => {
        const item = localStorage.getItem(RentaToolsConstants.returnInspectionListViewSetting);

        if(item){
            return item.toLowerCase() == 'true'
        }

        return true;
    }

    private readonly _filtersModalRef: React.RefObject<DevicesFiltersModal> = React.createRef();
    private readonly _lastElement: React.RefObject<HTMLDivElement> = React.createRef();

    private getDate(device: DeviceInfo): string | null {
        const date: Date | null = this.props.request.getDate(device);
        return (date)
            ? ToolsUtility.toDateString(date)
            : "";
    }

    private async openFiltersAsync(): Promise<void> {
        await this._filtersModalRef.current!.openAsync();
    }

    private async submitFiltersAsync(request: BaseListDevicesRequest): Promise<void> {
        if (request instanceof ListUnInspectedDevicesRequest) {
            request.modified = true;
            request.resetPage();
        }

        this.setState({request});

        await this.reloadAsync();
    }

    private async onItemClickAsync(device: DeviceInfo): Promise<void> {
        await this.props.onDeviceOpen(this, device);
    }

    protected async fetchDataAsync(): Promise<DeviceInfo[]> {
        return this.props.fetchData(this, this.state.request, false);
    }

    protected getEndpoint(): string {
        return "";
    }

    protected getIconName(): string {
        return (this.state.request.isEmpty())
            ? "far filter"
            : "fas filter";
    }

    public isAsync(): boolean {
        return true;
    }

    public get haveMoreDataToLoad(): boolean {
        return this.state.request instanceof ListUnInspectedDevicesRequest &&
            (this.state.request.pageSize * (this.state.request.pageNumber === 0 ? 1 : this.state.request.pageNumber + 1) === this.state.data?.length!);
    }

    private _intersectionObserver: IntersectionObserver | null = null;

    public async componentDidUpdate() {
        this._intersectionObserver = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting && !this.state.isFetching && this.haveMoreDataToLoad) {

                const request = this.state.request;

                if (request instanceof ListUnInspectedDevicesRequest) {
                    request.nextPage();
                }

                await this.setState({...this.state, isFetching: true})

                const result = await this.props.fetchData(this, request, true);

                const currentState = this.state.data;

                currentState?.push(...result)

                await this.setState({...this.state, data: currentState, isFetching: false, request: request})
            }
        });

        if (this._intersectionObserver && this._lastElement.current) {
            this._intersectionObserver.observe(this._lastElement.current);
        }
    }

    private getUserName(device: DeviceInfo): string {
        if (device.returnInspectionStatus === ReturnInspectionStatus.PartlyCompleted || device.returnInspectionStatus === ReturnInspectionStatus.Completed) {
            return (device.modifiedBy)
                ? TransformProvider.userToString(device.modifiedBy, true)
                : "";
        }

        return (device.currentInspectionUser)
            ? TransformProvider.userToString(device.currentInspectionUser, true)
            : "";
    }

    private getActivePhase(items: ReturnInspectionSteps): IActivePhase {
        if (!UnleashHelper.isEnabled(RentaToolsConstants.featureFlagShowPhasesEnabled)) {
            return {phases: [items.steps], activePhase: items.steps};
        }

        const phases = items.steps.groupBy(p => p.phaseId);
        let activePhase = phases.filter(p => p.firstOrDefault(p => !p.isCompleted) != null)[0];
        if (activePhase == null) {
            activePhase = phases[phases.length - 1];
        }

        return {phases: phases, activePhase}
    }

    private getPhaseInfo(device: DeviceInfo): React.ReactNode {
        if (device.returnInspectionSteps != null && this.props.returnInspectionList && !this.hasWaitingInspection(device) && this.state.shortView ) {
            const item = device.returnInspectionSteps!;
            const phasesResult = this.getActivePhase(item);
            const phases = phasesResult.phases;
            const activePhase = phasesResult.activePhase;
            const stepCompletedCount = activePhase.filter(p => p.isCompleted).length;
            const hasMorePhases = phases.length > 1;

            return (
                <React.Fragment>
                    {UnleashHelper.isEnabled(RentaToolsConstants.featureFlagShowPhasesEnabled) && (
                        <>
                            {hasMorePhases && (
                                <>
                                    <p>{Localizer.genericPhase}</p>
                                    <p className={this.css(styles.fontBold)}>{activePhase[0].phaseName}</p>
                                </>
                            )}
                            {!hasMorePhases && (
                                <p>{Localizer.returnInspectionHasNoPhases}</p>
                            )}
                        </>
                    )}
                    <p>{Localizer.returnInspectionSteps} {stepCompletedCount}/{activePhase.length}</p>
                    <p className={this.css(styles.fontBold, styles.mechanicName)}>{this.getUserName(device)}</p>
                </React.Fragment>
            );
        }

        return null;

    }

    private hasWaitingInspection(device: DeviceInfo) : boolean{
        return ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection) && !device.underInspection;
    }

    private changeView = async () => {
        const nextView = !this.state.shortView;

        await this.setState({...this.state, shortView: nextView});

        localStorage.setItem(RentaToolsConstants.returnInspectionListViewSetting, nextView.toString());
    }

    private getStatusName = (status: ReturnInspectionStatus, maintenanceStatus: MaintenanceStatus, underInspection : boolean, deviceStatus : DeviceStatus) => {
        
        if(status === ReturnInspectionStatus.NotCompleted && deviceStatus !== DeviceStatus.ReturnInspectionIsNeeded) {
            return Localizer.returnInspectionNotCompleted;
        }
        
        if(status !== null) {
            switch (status) {
                case ReturnInspectionStatus.PartlyCompleted:
                    return Localizer.returnInspectionPartlyCompleted;
                case ReturnInspectionStatus.InProgress:
                    return Localizer.returnInspectionInProgress;
            }
        }


        if(!ToolsUtility.hasFlag(maintenanceStatus, MaintenanceStatus.RequiresReturnInspection)){
            return Localizer.returnInspectionCompleted;
        }

        if(!underInspection){
            return Localizer.returnInspectionWaitingForReturnInspection;
        }

        if(status === ReturnInspectionStatus.Completed){
             return Localizer.returnInspectionCompleted;
        }

        return Localizer.returnInspectionWaitingForReturnInspection;
    }

    private getStatusStyles(item: DeviceInfo): string {
        if(item.returnInspectionStatus === ReturnInspectionStatus.NotCompleted && item.status !== DeviceStatus.ReturnInspectionIsNeeded) {
            return styles.notCompleted
        }
        
        switch (item.returnInspectionStatus){
            case ReturnInspectionStatus.PartlyCompleted:
                return styles.partlyCompleted;
            case ReturnInspectionStatus.InProgress:
                return styles.inProgress
        }

        if (this.isInspectionCompleted(item)) {
            return styles.completed;
        }

        return "";
    }

    private async openIconStep(event: React.MouseEvent, deviceExternalId: string, underInspection : boolean, startingPoint: ResourceItemType): Promise<void> {
        event.stopPropagation();

        await ReturnInspectionController.startReturnInspectionFromStep(deviceExternalId, underInspection, startingPoint)

    }

    private isInspectionCompleted(device: DeviceInfo): boolean {
        return !ToolsUtility.hasFlag(device.maintenanceStatus, MaintenanceStatus.RequiresReturnInspection);
    }

    private getDateText(item: DeviceInfo): string {
        if (this.isInspectionCompleted(item)) {
            return Localizer.returnInspectionListLastInspection;
        }

        return Localizer.returnInspectionListReturnedDate;
    }

    public getTitle(): React.ReactNode {
        if (this.props.renderTitle) {
            return this.props.renderTitle();
        }

        return (
            <span className={this.css(newStyles.subTitle)}>
                { this.toMultiLines(this.props.title) }
            </span>
        );
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.devicesList, newStyles.deviceList)} data-cy={"devices_list"}>

                <div className={this.props.sticky ? this.css(rentaToolsStyles.stickyDeviceListHeader, styles.stickyDeviceListHeader) : ""}>

                    <ToolsPageHeader stickyHeader title={Localizer.returnedDevicesPageTitle.toUpperCase()}/>

                    <div className={newStyles.col}>

                        <div className={this.css(styles.header, newStyles.header)}>

                            { this.getTitle() }

                            <div className={this.css(styles.filterButton)}>
                                <Button id="device_list_filter"
                                        small
                                        icon={{name: this.getIconName(), size: IconSize.X2}}
                                        type={ButtonType.Blue}
                                        className={this.css(styles.filter, newStyles.filter)}
                                        onClick={async () => await this.openFiltersAsync()}
                                />
                            </div>
                            <div className={this.css(styles.filterButtonSettings)}>
                                <ExpandableMenu onClick={this.changeView} buttonDescription={Localizer.genericExpandedMenu} buttonState={this.state.shortView}>
                                    {ch.getUser<User>().canEditDeviceOrder &&
                                        <Button id="device_edit_order"
                                                small
                                                type={ButtonType.Orange}
                                                className={this.css(styles.filter, newStyles.filter)}
                                                label={Localizer.genericEditOrder}
                                                onClick={async () => {
                                                    await PageRouteProvider.redirectAsync(PageDefinitions.depoSelectionRoute(DeviceListOrderType.ReturnedDevices))
                                                }}
                                        />
                                    }
                                </ExpandableMenu>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    (!this.isLoading) && ((this.state.data == null) || (this.state.data.length == 0)) &&
                    (
                        <div className={this.css(styles.deviceListItem, styles.noItems, newStyles.deviceListItem)}>
                            {this.props.noDataText}
                        </div>
                    )
                }

                {
                    (this.state.data) &&
                    (
                        this.state.data.map((item, index) =>
                            <div key={index}
                                 ref={index === this.state.data?.length! - 1 ? this._lastElement : undefined}
                                 className={this.css(styles.deviceListItem,
                                     "cursor-pointer",
                                     newStyles.deviceListItem
                                 )}
                                 onClick={async () => await this.onItemClickAsync(item)} 
                                 id={`device_list_element_${item.externalId}`}
                                 data-cy={'device_list_element'}>

                                <div data-cy={`device_status_bar_${item.externalId}`} className={this.css(styles.priorityBar,
                                    this.props.returnInspectionList && styles.waitingForReturnInspection,
                                    this.getStatusStyles(item)
                                )}/>

                                <div className={this.css(styles.leftCell)}>
                                    <p className={this.css(styles.fontBold)}>{item.externalId} <span id={`depo_costpool`}>{item.depoCostPool}</span></p>

                                    {
                                        (!!item.serialNumber) && (
                                            <p className={this.css(styles.fontBold)}> {item.serialNumber} </p>
                                        )
                                    }
                                    <p className={this.css(styles.fontBold)}>{item.name}</p>

                                    {
                                        this.state.shortView &&
                                        (
                                            <p>{item.secondaryInformation}</p>
                                        )
                                    }
                                </div>

                                <div>
                                    {this.state.shortView && (
                                        <div className={this.css(styles.leftCell)}>
                                            {this.props.returnInspectionList && (
                                                <React.Fragment>
                                                    <div className={this.css(styles.centerCell)}>
                                                        <p className={this.css(styles.fontBold, styles.mobileTopMargin)} id={"inspection_status_text"}>{this.getStatusName(item.returnInspectionStatus!, item.maintenanceStatus, item.underInspection, item.status)}</p>
                                                        {this.getPhaseInfo(item)}
                                                    </div>
                                                    {this.state.shortView && (
                                                        <div className={this.css(styles.icons, styles.marginTop)} id={"ri_progress"}>
                                                            {item.returnInspectionDetails?.fueling === true && (
                                                                <i className={this.css("fas fa-gas-pump", DeviceInfo.fuelingDone(item) && styles.done)}
                                                                   onClick={async (event) => await this.openIconStep(event, item.externalId, item.underInspection, ResourceItemType.Fueling)}></i>
                                                            )}
                                                            {item.returnInspectionDetails?.washing === true && (
                                                                <i className={this.css("fas fa-shower", DeviceInfo.washingDone(item) && styles.done)}
                                                                   onClick={async (event) => await this.openIconStep(event, item.externalId, item.underInspection, ResourceItemType.Washing)}></i>
                                                            )}
                                                        </div>
                                                    )
                                                    }
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                </div>


                                <div className={this.css(styles.rightCell)}>
                                    <span className={this.css(styles.dateStyles)}>{this.getDate(item)}</span>
                                    <span className={styles.dateText}>{this.getDateText(item)}</span>
                                    <div className={styles.iconsWrapper}/>
                                </div>

                            </div>
                        )
                    )
                }

                <DevicesFiltersModal priorityOrder={UnleashHelper.isEnabled(RentaToolsConstants.productGroupUtilizationUI)}
                                     returnInspectionDetailsSelection={this.props.returnInspectionList}
                                     ref={this._filtersModalRef}
                                     title={this.props.title}
                                     request={this.state.request}
                                     filtersData={this.props.filtersData}
                                     onSubmit={async (sender, request) => await this.submitFiltersAsync(request)}
                />

            </div>
        );
    }
};