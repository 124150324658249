import { ApplicationContext, ch } from "@renta-apps/athenaeum-react-common";
import { UnleashClient } from "@unleash/proxy-client-react";
import ReactGA from "react-ga4";
import UserContext from "@/models/server/UserContext";
import LocalFeatureToggleClient from "@/helpers/LocalFeatureToggleClient";

class UnleashHelper {
  private static instance: UnleashHelper;

  private _initialized: boolean = false;

  private readonly localTrackingCode: string = "UA-XXXXX-Y";

  private _client: UnleashClient = new UnleashClient({
    // Add anything formally valid to make constructor happy.
    url: "https://renta.com",
    appName: "name",
    clientKey: "key",
  });

  private _localClient: LocalFeatureToggleClient = new LocalFeatureToggleClient();

  public static getInstance(): UnleashHelper {
    if (!UnleashHelper.instance) {
      UnleashHelper.instance = new UnleashHelper();
    }

    return UnleashHelper.instance;
  }

  public async initialize(): Promise<void> {
    UnleashHelper.instance = new UnleashHelper();

    const context: ApplicationContext = ch.getContext();

    const userContext: UserContext = context as UserContext;

    if (!UnleashHelper.getInstance()._initialized) {
      if (userContext.isLocalDevelopment || userContext.IsAutomatedTestEnv) {
        await UnleashHelper.getInstance()._localClient.start();
      } else {
        // Parse settings from key
        const parts: string[] =
          context.settings.unleashKey.match("(.*)[:](.*)[.].*");
        const appName: string = parts[1];
        const environment: string = parts[2];

        UnleashHelper.getInstance()._client = new UnleashClient({
          clientKey: context.settings.unleashKey,
          url: context.settings.unleashUrl,
          appName: appName,
          environment: environment,
          context: {
            userId: userContext?.user?.username ?? undefined,
          },
        });

        UnleashHelper.getInstance().updateClientUserId();

        await UnleashHelper.getInstance()._client.start();

        if (context.settings.isGoogleAnalyticsEnabled) {
          ReactGA.initialize(context.settings.googleAnalyticsInstrumentalKey);
        }
      }
    }
  }

  /**
   * Set the {@link UnleashClient}s userId to the currently logged-in users {@link User.username}.
   */
  public updateClientUserId(): void {
    UnleashHelper.getInstance()._client.setContextField("userId", ch.findUser()?.username!);
  }

  /**
   * Set the `userId` used when fetching feature flags.
   */
  public async setUserNameAsUserId(
    userName: string | undefined,
  ): Promise<void> {
    await UnleashHelper.getInstance().client.updateContext({
      userId: userName,
    });
  }

  public get client(): UnleashClient {
    return UnleashHelper.getInstance()._client;
  }

  /** Returns true if the flag is configured and enable for the current environment and country. */
  public isEnabled(flagName: string): boolean {
    const context = ch.getContext() as UserContext;

    if (context.isLocalDevelopment || context.IsAutomatedTestEnv) {
      return UnleashHelper.getInstance()._localClient.isEnabled(flagName);
    }

    const getCountry = function (): string {
      if (ch.isSweden) {
        return "SE";
      }
      if (ch.isFinland) {
        return "FI";
      }
      if (ch.isDenmark) {
        return "DK";
      }
      if (ch.isPoland) {
        return "PL";
      }
      if (ch.isNorway) {
        return "NO";
      }

      throw new Error("Not supported country: " + ch.country);
    };

    //To make sure NOR turns into => NO
    const fullFlagName = `TOOLS_${flagName}_${getCountry()}`;
    return UnleashHelper.getInstance()._client.isEnabled(fullFlagName);
  }
}

/** {@link UnleashHelper} singleton. */
export default new UnleashHelper();