import ReportDefinitionItem from "./ReportDefinitionItem";
import User from "../../models/server/User";

export default class ReportItemInfo extends ReportDefinitionItem {
    public reportId: string = "";

    public deviceExternalId: string = "";

    public inspectedBy: User | null = null;

    public startedAt: Date = new Date();

    public completedAt: Date | null = null;

    public depoCostPool: string = "";

    public stepNumber: number = 0;

    public stepsCount: number = 0;

    public comment: string = "";
}