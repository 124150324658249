import React from "react";
import {IPageRowProps, PageRow} from "@renta-apps/athenaeum-react-components";

import styles from "./ArsenalPageRow.module.scss";
import {Utility} from "@renta-apps/athenaeum-toolkit";

interface IArsenalPageRowProps extends IPageRowProps {
    className?: string;
}

const ArsenalPageRow = (props: IArsenalPageRowProps) => {
   
        return (
            <PageRow className={Utility.css(props.className, styles.arsenalPageRow)}>
                {props.children}
            </PageRow>
        );
};

export default ArsenalPageRow;