import React, { ReactNode } from 'react';
interface ShowProps {
    children: ReactNode;
}
interface WhenProps {
    isTrue: boolean;
    children: ReactNode;
}
interface ElseProps {
    render?: ReactNode;
    children: ReactNode;
}
const Show: React.FC<ShowProps> & { When: React.FC<WhenProps>; Else: React.FC<ElseProps> } = (props) => {
    let when : ReactNode = null;
    let otherwise : ReactNode = null;
    React.Children.forEach(props.children, (child: any) => {
        if (child.props.isTrue === undefined) {
            otherwise = child;
        } else if (!when && child.props.isTrue) {
            when = child;
        }
    });
    return when || otherwise;
};
Show.When = ({ isTrue, children }: WhenProps) => (isTrue ? <>{children}</> : null);
Show.Else = ({ render, children }: ElseProps) => (render ? <>{render}</> : <>{children}</>);
export default Show;