class InvoicesFilterModal {
    public elements = {
        inputDeviceExternalId: () => cy.get('[id*="invoices_filter_deviceExternalId_"]'),
        inputStatus: () => cy.get('[id*="invoices_filter_status_"]'),
        inputDepo: () => cy.get('[id*="invoices_filter_depot_"]'),
        inputContractId: () => cy.get('[id*="invoices_filter_contractId_"]'),
        inputInspectorId: () => cy.get('[id*="invoices_filter_inspectorId_"]'),
        inputCustomerId: () => cy.get('[id*="invoices_filter_customerId_"]'),
        inputCustomerName: () => cy.get('[id*="invoices_filter_customerName_"]'),
        inputConstructionSiteExternalId: () => cy.get('[id*="invoices_filter_constructionSiteExternalId_"]'),
        inputConstructionSiteName: () => cy.get('[id*="invoices_filter_constructionSiteName_"]'),
        inputFrom: () => cy.get('[id*="invoices_filter_from_"]'),
        form: () => cy.get('form'),
    }
}

export default new InvoicesFilterModal();